import {
    Button,
    ChevronDownIcon,
    ChevronUpIcon, CustomSwitch,
    EditFilledIcon,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    Typography,
} from 'convertupleads-theme';
import React from 'react';

interface ICustomSettingHeader {
    title: string;
    subTitle: string;
    icon: React.ReactNode;
    isAccordionOpen: boolean;
    isShowButton: boolean;
    handleToggle: () => void;
}

const CustomSettingHeader: React.FC<ICustomSettingHeader> = ({ title, subTitle, icon, isAccordionOpen, handleToggle, isShowButton }) => {
    return (
        <Stack direction={'row'} justifyContent={'space-between'} spacing={1} sx={{ cursor: 'pointer' }} paddingY={2}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <IconButton variant={'rounded'} size={'medium'} color={'primary'} sx={{ backgroundColor: 'primary.light' }}>
                    {icon}
                </IconButton>
                <Stack flex={1}>
                    <Typography variant={'subtitle1'} color={'text.secondary'}>
                        <b>{title} </b>
                    </Typography>
                    <Typography variant={'caption'} color={'text.secondary'}>
                        {subTitle}
                    </Typography>
                </Stack>
            </Stack>
            {isShowButton ? (
                <>
                    {' '}
                    <Button variant={'outlined'} color={'primary'} startIcon={<EditFilledIcon />} sx={{':focus': {background: 'transparent'}}} onClick={handleToggle}>
                        Edit Trigger
                    </Button>
                </>
            ) : (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <FormControlLabel
                        control={
                            <CustomSwitch
                                color={"primary.main"}
                                checked={isAccordionOpen}
                                activeText={"On"}
                                inactiveText={"Off"}
                                onChange={handleToggle}
                            />
                        }
                        label=''
                    />

                    {isAccordionOpen ? (
                        <ChevronUpIcon fontSize='small' color={'text.tertiary'} onClick={handleToggle} />
                    ) : (
                        <ChevronDownIcon fontSize='small' color={'text.tertiary'} onClick={handleToggle} />
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default CustomSettingHeader;
