import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFolder, IGetFoldersPayload } from './campaignFolder.interface';

const initialState = {
    campaignFolders: null as IFolder[] | null,
    isLoading: false,
    isError: false,
    error: '',
    count: 0,

    page: 1, // currentPage
    perPage: 10, // limit

    nextPage: 1,
    totalPages: 1,
    hasMore: true,

    folder: {} as IFolder,

    selectedFolder: 'allCampaigns',
    updatingFolder: {} as IFolder,

    isFetching: false,
    isFetchingSuccess: false,

    isAdding: false,
    isAddCampaignFolderSuccess: false as boolean,

    isDeleting: false,
    isDeleteCampaignFolderSuccess: false,

    isUpdating: false,
    isUpdateCampaignFolderSuccess: false,
};

const campaignFolderSlice = createSlice({
    name: 'campaignFolder',
    initialState,
    reducers: {
        getCampaignFolders: (state, _action: PayloadAction<IGetFoldersPayload>) => {
            state.isLoading = true;
            state.isError = false;
            state.error = '';
        },
        getCampaignFoldersSuccess: (state, action) => {
            state.campaignFolders = action.payload.data;
            // state.page = action.payload.currentPage;
            // state.perPage = action.payload.limit;
            state.count = action.payload.totalCount;
            state.isLoading = false;
            state.hasMore = action.payload.totalCount >= state.perPage;
        },
        getCampaignFoldersFailed: (state, action) => {
            state.campaignFolders = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        addCampaignFolder: (state, _action: PayloadAction<IFolder | undefined>) => {
            state.isAdding = true;
            state.isError = false;
            state.error = '';
        },
        addCampaignFolderSuccess: (state, action: PayloadAction<IFolder>) => {
            //to insert in 0 index
            if (state.campaignFolders !== null) {
                state.campaignFolders.unshift(action.payload);
            }
            state.isAdding = false;
            state.isAddCampaignFolderSuccess = true;
        },
        addCampaignFolderFailed: (state, action) => {
            state.isAdding = false;
            state.isError = true;
            state.error = action.payload;
        },
        resetAddCampaignFolder: (state) => {
            state.isAddCampaignFolderSuccess = false;
        },

        updateCampaignFolder: (state, _action: PayloadAction<IFolder | undefined>) => {
            state.isUpdating = true;
            state.isError = false;
            state.error = '';
        },
        updateCampaignFolderSuccess: (state, action: PayloadAction<IFolder>) => {

            if (action.payload.uid && state.campaignFolders !== null) {

                state.campaignFolders = state.campaignFolders.map((item: IFolder) => {
                    if (item.uid === action.payload.uid) {
                        return action.payload
                    }
                    return item
                })
            }
            state.isUpdating = false;
            state.isUpdateCampaignFolderSuccess = true;
        },
        updateCampaignFolderFailed: (state, action) => {
            state.isUpdating = false;
            state.isError = true;
            state.error = action.payload;
        },
        resetUpdateFolderSuccess: (state) => {
            state.isUpdateCampaignFolderSuccess = false;
        },

        deleteCampaignFolder: (state, _action: PayloadAction<string | undefined>) => {
            state.isDeleting = true;
        },
        deleteCampaignFolderSuccess: (state, action: PayloadAction<string>) => {
            if (state.campaignFolders !== null) {
                state.campaignFolders = state.campaignFolders.filter((folder: IFolder) => folder.uid !== action.payload);
            }
            state.isDeleting = false;
            state.isDeleteCampaignFolderSuccess = true;
        },
        deleteCampaignFolderFailed: (state, action) => {
            state.isDeleting = false;
            state.isError = true;
            state.error = action.payload;
        },
        resetDeleteFolderSuccess: (state) => {
            state.isDeleteCampaignFolderSuccess = false;
        },

        onSelectCampaignFolder: (state, action: PayloadAction<string>) => {
            state.selectedFolder = action.payload;
        },
    },
});

export const {
    getCampaignFolders,
    getCampaignFoldersFailed,
    getCampaignFoldersSuccess,

    addCampaignFolder,
    addCampaignFolderSuccess,
    addCampaignFolderFailed,
    resetAddCampaignFolder,

    updateCampaignFolder,
    updateCampaignFolderSuccess,
    updateCampaignFolderFailed,
    resetUpdateFolderSuccess,

    deleteCampaignFolder,
    deleteCampaignFolderSuccess,
    deleteCampaignFolderFailed,
    resetDeleteFolderSuccess,

    onSelectCampaignFolder,
} = campaignFolderSlice.actions;

export default campaignFolderSlice.reducer;
