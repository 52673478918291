import React, {useEffect, useState} from 'react';
import { Checkbox, FormControlLabel, FormGroup, Stack, Typography, useTheme } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { deselectAllCampaign, selectedAllCampaign } from '../../../../state/features/emailCampaign/emailCampaignSlice';

const CampaignContentListHeader: React.FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isAllCampaignSelected, setIsAllCampaignSelected] = useState(false);
    const { campaignList, selectedCampaigns } = useSelector(emailCampaignState);

    const handleToggleSelected = () =>{
        isAllCampaignSelected ? dispatch(deselectAllCampaign(undefined)) : dispatch(selectedAllCampaign(undefined));
    }

    useEffect(() => {
        setIsAllCampaignSelected(campaignList?.every((campaign) => selectedCampaigns.includes(campaign.uid as string)));
    }, [campaignList, selectedCampaigns]);

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ background: theme.palette.other.outlinedBorder, paddingX: 2, paddingY: 0.5, mt: 2 }}
        >
            <FormGroup>
                <FormControlLabel
                    checked={isAllCampaignSelected}
                    control={<Checkbox size='small' />}
                    label='Campaign Name'
                    onClick={handleToggleSelected}
                />
            </FormGroup>
            <Stack>
                <Typography width={200} textAlign={'center'}>
                    Actions
                </Typography>
            </Stack>
        </Stack>
    );
};

export default CampaignContentListHeader;
