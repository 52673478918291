import React, { useEffect, useState } from "react";
import ActivityLogCard from "./ActivityLogCard";
import { Box, Stack, Typography } from "convertupleads-theme";
import { getActivityLogs } from "../../../../services/activityLogs.service";
import EmptyComponent from "../../emptyComponent/EmptyComponent";
import {useParams} from "react-router-dom";

interface ActivityLog {
  note: string;
  userName: string;
  createdAt: string;
  updatedAt: string | null;
}

const timelineStyle: React.CSSProperties = {
  position: "relative",
};

const connectorStyle: React.CSSProperties = {
  position: "absolute",
  height: "100%",
  borderLeft: "2px solid #949DB21F",
  marginLeft: "6px",
};

const dotStyle: React.CSSProperties = {
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  background: "#949DB2",
  position: "absolute",
  top: "30%",
  transform: "translateY(-50%)",
  border: "2px solid #FFF",
  zIndex: 1,
  cursor: "pointer",
};

const contentStyle: React.CSSProperties = {
  marginLeft: "16px",
  padding: "16px",
  position: "relative",
};

const CustomTimeLine: React.FC = () => {
  const { campaignUid } = useParams();
  const [activityLogData, setActivityLogData] = useState<ActivityLog[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [apiCallStatus, setApiCallStatus] = useState(true);

  const getActivityLogData = (page: number) => {
    setIsLoading(true);
    getActivityLogs({ page: page, campaignUid: campaignUid ? campaignUid as string : null })
      .then((res) => {
        setActivityLogData([...activityLogData, ...res.data]);
        setIsLoading(false);
        if (res.data.length < 40) {
          setApiCallStatus(false);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
        setInitialLoading(false);
      });
  };

  useEffect(() => {
    if (apiCallStatus) {
      getActivityLogData(page);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePage = (uPage: number) => {
    if (apiCallStatus) {
      setPage(uPage);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight -
        e.currentTarget.scrollTop -
        e.currentTarget.clientHeight <
      40;
    if (bottom && !isLoading) {
      if (!isLoading) {
        setTimeout(() => {
          let uPage = page + 1;
          handlePage(uPage);
        }, 500);
      }
    }
  };

  return (
    <Stack
      maxHeight={700}
      overflow={"auto"}
      className="test"
      onScroll={handleScroll}
    >
      {isLoading && initialLoading ? (
        <Typography>Getting Data...</Typography>
      ) : (
        <>
          {activityLogData?.map((activity: ActivityLog, index: number) => (
            <Stack key={index} sx={timelineStyle}>
              <Box sx={dotStyle} />
              <Box sx={connectorStyle} />
              <Stack sx={contentStyle}>
                <ActivityLogCard activity={activity} />
              </Stack>
            </Stack>
          ))}
          {activityLogData?.length > 0 && apiCallStatus && (
            <p>Loading More...</p>
          )}
        </>
      )}
      {activityLogData?.length === 0 && !isLoading && (
        <EmptyComponent description="No data found" />
      )}
    </Stack>
  );
};

export default CustomTimeLine;
