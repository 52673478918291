import React, { useEffect, useState } from 'react';
import { Button, Divider, FormControl, InputLabel, ModalWithHeader, MultiSelect, Stack } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { campaignFolderState } from '../../../../state/features/campaignFolder/campaignFolderSelector';
import { IEmailCampaign } from '../../../../state/features/emailCampaign/emailCampaign.interface';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { bulkCampaignAddToFolder, singleCampaignAddToFolder } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { IFolder } from '../../../../state/features/campaignFolder/campaignFolder.interface';

interface IAddFolderModalProps {
    open: boolean;
    campaign?: IEmailCampaign;
    onClose: () => void;
}

const AddToFolderModal: React.FC<IAddFolderModalProps> = ({ open, campaign, onClose }) => {
    const { campaignFolders } = useSelector(campaignFolderState);
    const { selectedCampaigns } = useSelector(emailCampaignState);
    const { isAdding, isBulkUpdateCampaignSuccess } = useSelector(emailCampaignState);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isBulkUpdateCampaignSuccess) {
            handleClose();
        }
    }, [isBulkUpdateCampaignSuccess]);

    const initialSelectedFolders = campaign?.campaignFolders?.map((folder) => {
        return { value: folder.uid, label: folder.title };
    });

    const [selectedFolders, setSelectedFolders] = useState(initialSelectedFolders || []);

    const multiSelectOptions = campaignFolders !== null ? campaignFolders.map((folder: IFolder) => {
        return { value: folder.uid, label: folder.title };
    }) : []

    const selectedFolderUids = selectedFolders?.map((folder) => folder.value);

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = () => {

        if (campaign) {
            dispatch(singleCampaignAddToFolder({ campaignUid: campaign.uid, campaignFolderUids: selectedFolderUids }));
        } else {
            dispatch(bulkCampaignAddToFolder({ campaignUids: selectedCampaigns, campaignFolderUids: selectedFolderUids }));
        }
    };

    return (
        <ModalWithHeader open={open} onClose={handleClose} title='Add to folder'>
            <Stack>
                <Stack direction={'row'} padding={3}>
                    {' '}
                    <FormControl sx={{ "& input": { height: "unset !important" }}} fullWidth>
                        <InputLabel>Select</InputLabel>
                        <MultiSelect
                            width='100%'
                            maxWidth='100%'
                            defaultValue={initialSelectedFolders}
                            // value={initialSelectedFolders}
                            onChange={(value) => setSelectedFolders(value as { value: string | undefined; label: string }[])}
                            // isMulti={true}
                            name='colors'
                            options={multiSelectOptions}
                            className='rounded'
                        />
                    </FormControl>
                </Stack>
                <Divider light />
                <Stack direction={'row'} justifyContent={'end'} spacing={1} paddingX={3} paddingY={2}>
                    <Button variant={'tonal'} color={'inherit'} onClick={handleClose} size='large'>
                        Cancel
                    </Button>

                    <Button variant={'contained'} disabled={isAdding} size='large' onClick={handleSubmit}>
                        Save
                    </Button>
                </Stack>
            </Stack>
        </ModalWithHeader>
    );
};

export default AddToFolderModal;
