import React from 'react';
import { ArrowLeftIcon, Box, Button, Stack, Tab, Tabs, Typography, useTheme } from 'convertupleads-theme';
import { useNavigate } from 'react-router-dom';
import ActivityLog from './ActivityLog';
import Analytics from './analyticsTabContent/Analytics';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Index() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const goBack = () => {
        navigate('/bulk-email', { replace: true }); // Navigates back one step in history
    };

    return (
        <Box>
            <Box sx={{ background: theme.palette.common.white, p: 1.5 }}>
                <Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', pl: 3 }}>
                        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                            <Tab label='Analytics' {...a11yProps(0)} sx={{ "&:focus": { background: 'none' } }} />
                            <Tab label='Activity Log' {...a11yProps(1)} sx={{ "&:focus": { background: 'none' } }} />

                            <Stack justifyContent={'center'} sx={{ ml: 'auto' }}>
                                <Button variant='outlined' color='primary' size='small' onClick={goBack} startIcon={<ArrowLeftIcon color='primary' />}>
                                    Back
                                </Button>
                            </Stack>
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Analytics />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <ActivityLog />
                    </CustomTabPanel>
                </Box>
            </Box>
        </Box>
    );
}
