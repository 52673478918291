import React, {useState} from 'react';
import {
    Button,
    Chip,
    DeleteModal,
    Divider,
    MenuItem,
    Modal,
    Select,
    Stack,
} from 'convertupleads-theme';
import {useDispatch, useSelector} from "react-redux";
import {emailCampaignState} from "../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import {addCampaignEmail, deleteCampaignEmail} from "../../../../state/features/emailCampaign/emailCampaignSlice.ts";

interface IProps {
    open: boolean;
    onClose: () => void;
}

interface ICampaignConnectedEmail {
    uid: string;
    userConnectedEmail: string;
}

interface IUserConnectedEmail {
    uid: string;
    email: string;
}

interface ISelectedEmail {
    label: string;
    value: string;
}

const AddMoreSenderModal: React.FC<IProps> = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const { connectedEmails, selectedCampaign } = useSelector(emailCampaignState);
    const selectedEmails = connectedEmails?.campaignConnectedEmails?.map((email: ICampaignConnectedEmail) => ({ value: email.uid, label: email.userConnectedEmail }));
    const userEmails = connectedEmails.userConnectedEmails.map((email: IUserConnectedEmail) => ({ value: email.uid, label: email.email }));
    const [deletingItemValue, setDeletingItemValue] = useState("");
    const [selectedEmail,setSelectedEmail] = useState(0);

    const handleEmailSelect = (selectedOptions: any) => {
        if (selectedCampaign) {
            dispatch(addCampaignEmail({ campaignUid: selectedCampaign.uid as string, userConnectedEmailUid: selectedOptions.target.value }));
        }
    };

    const handleDelete = (uid: string) => {
        if (selectedCampaign) {
            dispatch(deleteCampaignEmail({ campaignUid: selectedCampaign.uid as string, connectedEmailUid: uid }));
        }
        setDeletingItemValue("");
        setSelectedEmail(0);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Stack padding={2} paddingBottom={0}>
                <Stack direction={'row'} gap={1} flexWrap={'wrap'} my={2}>
                    {selectedEmails.map((item: ISelectedEmail, _, arr: ISelectedEmail[]) => (
                        <Chip key={item.value} label={item.label} onDelete={arr.length > 1 ? () => setDeletingItemValue(item.value)  : undefined } size='small' />
                    ))}
                </Stack>

                <Select onChange={handleEmailSelect} defaultValue={0} value={selectedEmail} fullWidth>
                    <MenuItem value={0} disabled>
                        Select Email
                    </MenuItem>
                    {userEmails
                        .filter((item: ISelectedEmail) => !selectedEmails.find((selected: ISelectedEmail) => selected.label === item.label))
                        ?.map((item: ISelectedEmail) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                </Select>
                <Stack width={'100%'} mt={3}>
                    <Divider />
                    <Stack padding={2} direction={'row'} justifyContent={'flex-end'} gap={1}>
                        <Button variant={'tonal'} color={'inherit'} size='large' onClick={onClose}>
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            <DeleteModal
                open={deletingItemValue !== ''}
                title='Are you sure, You want to delete this Email?'
                warningContent='Removing this sender, will impact the contacts already connected with this email'
                onConfirm={() => handleDelete(deletingItemValue)}
                sx={{
                    '& .MuiPaper-root.MuiPaper-elevation': {width: 400},
                    '& .MuiButton-tonalInherit:focus': { bgcolor: 'other.outlinedBorder' },
                    '& .MuiButton-colorError:focus': { bgcolor: 'error.main' }
                }}

            />
        </Modal>
    );
};

export default AddMoreSenderModal;