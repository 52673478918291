export const STATUS = {
    PENDING: 0,
    PROCESSING: 1,
    COMPLETE: 2,
    FAILED: 3,
};

export const GLOBAL_DELAY = {
    DEBOUNCE: 1000,
    RESPONSE: 1000,
};

export const EDITOR_TYPE = {
    RICH_TEXT: 1,
    DRAG_DROP: 2,
    CUSTOM_HTML: 3,
};

export const EMAIL_SCHEDULE_TABS = {
    SAME_DAY: 1,
    LATER_DAY: 2,
    RECURRING: 3,
};

export const EMAIL_SCHEDULE_TYPE = {
    IMMEDIATELY: "IMMEDIATE",
    SCHEDULE: "SCHEDULE",
    RECURRING: "RECURRING",
    FIXED_DATE: "FIXED_DATE",
    BIRTHDAY: "BIRTHDAY",
    ANNIVERSARY: "ANNIVERSARY"
};

export enum STEPS {
    STEP_SEQUENCE = 1,
    STEP_SCHEDULE = 2,
    STEP_LEADS = 3,
    STEP_SETTINGS = 4
}

export const STEPS_SUB = {
    [STEPS.STEP_LEADS]: {
        SPREADSHEET: 1,
        CSV: 2,
    }
}

export enum CAMPAIGN_SEQUENCE_MESSAGE_TYPE {
    EMAIL = "EMAIL"
}

export const CAMPAIGN_TRIGGER_SETTINGS = {
    ADD_TO_ANOTHER_CAMPAIGN_SETTINGS: 'ADD_TO_ANOTHER_CAMPAIGN_SETTINGS',
    ADD_TO_POWER_DIALER_LIST_SETTINGS: 'ADD_TO_POWER_DIALER_LIST_SETTINGS',
    ADD_TAG_SETTINGS: 'ADD_TAG_SETTINGS',
    SEND_VOICE_MESSAGE: 'SEND_VOICE_MESSAGE',
    BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS: 'BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS',
    DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS: 'DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS',
    OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS: 'OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS',
    CONTACT_REPLY_WISE_CAMPAIGN_PAUSE_SETTINGS: 'CONTACT_REPLY_WISE_CAMPAIGN_PAUSE_SETTINGS',
    SWITCH_EMAIL_SENEDER_SETTINGS: 'SWITCH_EMAIL_SENEDER_SETTINGS'
};

export const CAMPAIGN_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
}

export const CAMPAIGN_SETTINGS = {
    GLOBAL_SCHEDULE_STATUS: 'global-schedule',
    GLOBAL_SCHEDULE_START_DATE: 'global-schedule-start-date',
    GLOBAL_SCHEDULE_END_DATE: 'global-schedule-end-date',
    START_TIMING: 'start-timing',
    END_TIMING: 'end-timing',
    OPERATE_INDEPENDENTLY: 'operate-independently',
    TIMEZONE: 'timezone',
    INTERVAL_TIME_OPTION: 'interval-time-option',
    INTERVAL_TIME: 'interval-time',
    DAYS_SCHEDULE: 'days-schedule'
}

export const LOOKUP__ACTION_RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL: string = 'RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL';
export const LOOKUP__ACTION_SEND_UNDELIVERABLE: string = 'SEND_UNDELIVERABLE';
export const LOOKUP__ACTION_SEND_DELIVERABLE: string = 'SEND_DELIVERABLE';
export const LOOKUP__ACTION_SEND_RISKY: string = 'SEND_RISKY';

export const TIMEZONE_LIST = {
    'America/New_York': 'Eastern Time',
    'America/Chicago': 'Central Time',
    'America/Denver': 'Mountain Time',
    'America/Phoenix': 'Arizona Time',
    'America/Los_Angeles': 'Pacific Time',
    'America/Anchorage': 'Alaska Time',
    'Pacific/Honolulu': 'Hawaii Time',
};

export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_RUNNING = 'RUNNING';
export const STATUS_STARTING = 'STARTING';
export const STATUS_PAUSE = 'PAUSE';
export const STATUS_PAUSING = 'PAUSING';
export const STATUS_TRASH = 'TRASH';
export const STATUS_ARCHIVE = 'ARCHIVE';
export const STATUS_RESUME = 'RESUME';

export const STATUS_LABEL: { [key: string]: string } = {
    [STATUS_ACTIVE]: 'Pause',
    [STATUS_RUNNING]: 'Running',
    [STATUS_STARTING]: 'Starting',
    [STATUS_RESUME]: 'Starting',
    [STATUS_PAUSE]: 'Pause',
    [STATUS_PAUSING]: 'Pausing',
};

export const STATUS_UPDATE: { [key: string]: "RESUME" | "STARTING" | "PAUSE" | "PAUSING" | "ARCHIVE" } = {
    [STATUS_ACTIVE]: STATUS_RESUME,
    [STATUS_RUNNING]: STATUS_PAUSE,
    [STATUS_STARTING]: STATUS_STARTING,
    [STATUS_PAUSE]: STATUS_RESUME,
    [STATUS_PAUSING]: STATUS_PAUSING
};

export const EMAIL_THREAD = {
    SINGLE_THREAD: 'SINGLE_THREAD',
    MULTI_THREAD: 'MULTI_THREAD'
};

export const EMAIL_PROVIDER = {
    DEFAULT: 0,
    NYLAS_V1: 1,
    NYLAS_V3: 2
};

export const CONTACT_STATUS = {
    RUNNING: 'RUNNING',
    PAUSE: 'PAUSE',
    UNSUBSCRIBED: 'UNSUB',
    COMPLETE:'COMPLETE'
}

export const CAMPAIGN_SETTING_KEYS = {
    UNSUBSCRIBE_LIST: 'UNSUBSCRIBE_LIST'
}

export const TRACK_SETTINGS_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
}

export const DATABASE_TIMEZONE = 'UTC';

export const LEAD_ITEM = {
    CAMPAIGN_CONTACT_LIST: 'CAMPAIGN_CONTACT_LIST',
    ADD_FROM_CONTACT_LIST: 'ADD_FROM_CONTACT_LIST',
    IMPORT_CSV: 'IMPORT_CSV',
    SMART_LIST:'SMART_LIST',
    BULK_EMAIL_CAMPAIGN_CONTACT_LIST:'BULK_EMAIL_CAMPAIGN_CONTACT_LIST',
    MARKETING_CAMPAIGN_CONTACT_LIST:'MARKETING_CAMPAIGN_CONTACT_LIST',
    IMPORT_GOOGLE_SHEET:'IMPORT_GOOGLE_SHEET',

}

export const STATUS_TYPE = {
    OPEN: "OPEN",
    QUEUE: "QUEUE",
    FAILED: "FAILED",
    SEND: 'SEND',
    RESPOND: 'RESPOND',
    BOUNCE: 'BOUNCE',
    CLICK: 'CLICK',
    SPAM: 'SPAM',
    UNSUBSCRIBE: 'UNSUBSCRIBE',
    UNDELIVERED: 'UNDELIVERED',
    DROP: 'DROP',
};

export const EVENT_TYPE = {
    SEQUENCE: "SEQUENCE",
    CAMPAIGN: "CAMPAIGN",
    EMAIL: "EMAIL",
};

export const SPREADSHEET_IMPORT_STEPS = {
    CONNECT: 0,
    CONFIGURE: 1,
    MAPPING: 2,
    DETAILS: 3,
    COMPLETE: 4,
};

export const CONNECTED_FILE_STEPS = {
    CONNECTED_FILE_LIST: 0,
    CONNECT_FILE: 1,
    SYNC_FILE: 3,
};

export const CSV_FILE_STEPS = {
    CSV_FILE_LIST: 0,
    CONNECT_FILE: 1,
};

export const CSV_IMPORT_STEPS = {
    UPLOAD: 0,
    MAPPING: 1,
    DETAILS: 2,
    COMPLETE: 3,
};

export const SPREADSHEET_PROCESSING_STATUS = {
    PROCESSED: "PROCESSED",
    NOT_PROCESSED: "NOT_PROCESSED",
};

export const CAMPAIGN_SETTINGS_TITLE = {
    LOOKUP_EMAIL: "LOOKUP_EMAIL"
}

export const USER_ROLE_ADMIN = 'ADMIN';
export const USER_ROLE_AGENT = 'AGENT';
export const USER_ROLE_OWNER = 'OWNER';


export const SORTING={
    ALPHABATIC_ASC:"A_TO_Z(ASC)",
    ALPHABATIC_DESC:"A_TO_Z(DESC)",
    DATE_TIME_ASC:"DATE_ADDED(ASC)",
    DATE_TIME_DESC:"DATE_ADDED(DESC)",
    ALPHABATIC:"ALPHABATIC",
    DATE_TIME:"DATE_TIME",
    DESC:'DESC',
    ASC:'ASC'


}