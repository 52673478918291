import {
  Checkbox,
  Chip,
  CloseBorderedIcon,
  DeleteIcon,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  styled,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  VisibilityOnIcon,
} from "convertupleads-theme";
import React, { Dispatch, SetStateAction, useState } from "react";
import ViewTimeLineDrawer from "../drawer/ViewTimeLineDrawer";
import emailCampaignService from "../../../../../services/emailCampaign.service";
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../../state/features/emailCampaign/emailCampaignSelector";
import { CONTACT_STATUS } from "../../../../../helpers/constant/coreConstants";
import { getAccountData } from "../../../../../helpers/util/functions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "10px 16px",
}));

interface RenderEachTableItemProps {
  item: any;
  selectedContacts: number[];
  handleContactCheckboxChange: (contactId: number) => void;
  // isChecked: Boolean;
}

const EachContactTableItem: React.FC<RenderEachTableItemProps> = ({
  selectedContacts,
  item,
  handleContactCheckboxChange,
  // isChecked,
}) => {
  const [openViewTimeLine, setOpenViewTimeLine] = useState(false);
  const { selectedCampaign, sequences } = useSelector(emailCampaignState);

  const handleUnsubscribeFromList = async () => {
    try {
      // setIsUnsubscribing(true);
      let resp = await emailCampaignService.unsubscribeContact({
        contactIds: [item?.contactId],
        campaignUid: selectedCampaign?.uid as string,
      });

      if (resp && resp.success) {
        // setSelectedContacts([])
        // setAllChecked(false);
        // setSubmittedUnsubscribeIds(prevState => [...prevState, ...selectedContacts])

        if ((window as any).showNotification) {
          (window as any).showNotification("success", resp.message);
        }
      } else {
        if ((window as any).showNotification) {
          (window as any).showNotification("error", resp.message);
        }
      }
    } catch (err) {
      if ((window as any).showNotification) {
        (window as any).showNotification(
          "error",
          "Something went wrong! Try again later"
        );
      }
    } finally {
      // setIsUnsubscribing(false);
    }
  };

  const fulname = (firstName: string, lastName: string) => {
    let name = "";
    if (firstName) {
      name = name + firstName + " ";
      if (lastName) {
        name = name + lastName;
      }
    }
    return name;
  };
  const sequenceIndex: number | undefined = sequences?.indexOf(item.priority);
  const value = sequenceIndex !== undefined && sequences?.length
    ? (sequenceIndex / sequences.length) * 100
    : 0;
  const dateConverter = (data: any) => {
    const date = (window as any).globalTimezoneConversionToDifferentTimezone(data)
    return date
  }
  const renderProgressBar =
    <>
      {item.priority === -1 && item.status === CONTACT_STATUS.UNSUBSCRIBED ? (
        <Stack spacing={0.5}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="body2" fontSize={13}>
              N/A
            </Typography>
          </Stack>
        </Stack>
      ) : item.priority === -1 && item.status === CONTACT_STATUS.COMPLETE ? (
        <Stack spacing={0.5}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="body2" fontSize={13}>
              Complete
            </Typography>
            <Typography variant="body2" fontSize={13} fontWeight={600}>
              {sequences?.length}/{sequences?.length}
            </Typography>
          </Stack>
          <LinearProgress variant="determinate" value={100} />
        </Stack>
      ) : item.priority > 0 ? (
        <Stack spacing={0.5}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="body2" fontSize={13}>
              ongoing
            </Typography>
            <Typography variant="body2" fontSize={13} fontWeight={600}>
              {sequenceIndex}/{sequences?.length}
            </Typography>
          </Stack>
          <LinearProgress variant="determinate" value={value} />
        </Stack>
      ) : (
        <Stack spacing={0.5}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="body2" fontSize={13}>
              N/A
            </Typography>
          </Stack>
        </Stack>
      )}
    </>


  return (
    <TableRow>
      <StyledTableCell>
        <Checkbox
          size={"small"}
          checked={selectedContacts.includes(item.contactId)}
          onClick={() => handleContactCheckboxChange(item.contactId)}
        />
        <Link sx={{ textDecoration: 'none', color: '#1D2939' }} href={`/contacts/${item.contactId}`}> {item.firstName ? fulname(item.firstName, item.lastName) : 'N/A'}</Link>
      </StyledTableCell>
      <StyledTableCell align="center">
        <Chip
          size="xSmall"
          color={
            item.status === "UNSUB"
              ? "error"
              : item.status === CONTACT_STATUS.RUNNING
                ? "success"
                : item.status === CONTACT_STATUS.COMPLETE ? "default" : "warning"
          }
          label={item.status === "UNSUB" ? "UNSUBSCRIBE" : item.status}
        />
      </StyledTableCell>
      <StyledTableCell>{dateConverter(item.entryDate)}</StyledTableCell>
      <StyledTableCell>
        {item.status === CONTACT_STATUS.COMPLETE ||
          item.status === CONTACT_STATUS.UNSUBSCRIBED || !item.scheduleTime
          ? "N/A"
          : dateConverter(item.scheduleTime)}
      </StyledTableCell>
      {(getAccountData('userId') === 1 || getAccountData('userId') === 446) &&
        <StyledTableCell>
          {renderProgressBar}
        </StyledTableCell>
      }
      <StyledTableCell>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
        >
          <IconButton
            size={"small"}
            sx={{ ":focus": { bgcolor: "transparent" } }}
            onClick={() => setOpenViewTimeLine(true)}
          >
            <VisibilityOnIcon />
          </IconButton>
          {
            item.status !== CONTACT_STATUS.UNSUBSCRIBED &&
            <Tooltip title={"Unsubscribe"}>
              <IconButton
                size={"small"}
                sx={{ ":focus": { bgcolor: "transparent" } }}
                onClick={handleUnsubscribeFromList}
              >
                <CloseBorderedIcon />
              </IconButton>
            </Tooltip>
          }

          {/* <IconButton
            size={"small"}
            color="error"
            sx={{ ":focus": { bgcolor: "transparent" } }}
          >
            <DeleteIcon />
          </IconButton> */}
        </Stack>
      </StyledTableCell>

      {openViewTimeLine && (
        <ViewTimeLineDrawer
          open={openViewTimeLine}
          contactId={item.contactId}
          onClose={() => setOpenViewTimeLine(false)}
          drawerTitle={item.firstName}
        />
      )}
      {/* <FailedListDrawer open={openFailedList} onClose={() => setOpenFailedList(false)} /> */}
    </TableRow>
  );
};

export default EachContactTableItem;
