import React from "react";
import { Stack, Typography } from "convertupleads-theme";
import { useNavigate, useParams } from "react-router-dom";
import emailCampaignService from "../../../../services/emailCampaign.service";

const ShareCampaignLink: React.FC = () => {
  const { campaignUid } = useParams();

  const navigate = useNavigate();

  const campaignCopyApi = async () => {
    const result = await emailCampaignService.shareCampaign({
      uid: campaignUid as string,
    });

    if (result.success) {
      navigate("/bulk-email", { replace: true });
    }
  };

  if (campaignUid) {
    campaignCopyApi();
  }

  return (
    <Stack
      height={"calc(100vh - 100px)"}
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="h3" textAlign={"center"}>
        Cloning...
      </Typography>
    </Stack>
  );
};

export default ShareCampaignLink;
