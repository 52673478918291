import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal, Stack } from "convertupleads-theme";
import TemplateLibraryLeftSide from "./TemplateLibraryLeftSide";
import TemplateLibraryRightSide from "./TemplateLibraryRightSide";
import emailCampaignService from "../../../../services/emailCampaign.service";
import EmptyComponent from "../../../bulkEmail/emptyComponent/EmptyComponent";
import TemplateLibrarySkeleton from "./TemplateLibrarySkeleton";

interface ITemplateLibraryModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const TemplateLibraryModal: React.FC<ITemplateLibraryModalProps> = ({
  open,
  setOpen,
}) => {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getEmailTemplates();
  }, []);

  const getEmailTemplates = async () => {
    setIsLoading(true);
    try {
      let resp = await emailCampaignService.getEmailTemplate();

      if (resp && resp.success) {
        setEmailTemplates(resp.data);
        setSelectedTemplate(resp.data[0]?.templates[0]);
      } else {
        if ((window as any).showNotification) {
          (window as any).showNotification("error", resp.message);
        }
      }
    } catch (err) {
      if ((window as any).showNotification) {
        (window as any).showNotification(
          "error",
          "Something went wrong! Try again later"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal width={960} open={open} onClose={handleClose}>
        <>
          {emailTemplates?.length > 0 && !isLoading && (
            <Stack direction={"row"} sx={{ height: "720px" }}>
              <TemplateLibraryLeftSide
                emailTemplates={emailTemplates}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
              />
              <TemplateLibraryRightSide
                selectedTemplate={selectedTemplate}
                handleClose={handleClose}
              />
            </Stack>
          )}
          {isLoading && (
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <TemplateLibrarySkeleton />
            </Stack>
          )}

          {emailTemplates?.length === 0 && !isLoading && (
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
            >
              <EmptyComponent description="No Email Templates" />
            </Stack>
          )}
        </>
      </Modal>
    </div>
  );
};

export default TemplateLibraryModal;
