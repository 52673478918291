/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "convertupleads-theme";
import emailCampaignService from "../../../../services/emailCampaign.service";
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector";
import {
  EVENT_TYPE,
  STATUS_TYPE,
} from "../../../../helpers/constant/coreConstants";
import EmptyComponent from "../../../bulkEmail/emptyComponent/EmptyComponent";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "10px 16px",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  tbody: {
    display: "block",
    maxHeight: "calc(100vh - 196px)",
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#888 transparent", // Change color as needed
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // Change color as needed
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555", // Change color as needed
    },
  },
  "thead, tbody tr": {
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  thead: {
    width: "calc(100% - 10px)", // To account for scrollbar width
  },
  table: {
    width: "100%",
  },
}));

interface DataItem {
  contactId: number;
  email: string;
  number: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  failedReason: string | null;
  drawerTitle?: string;
  type?: string;
  scheduleTime?: string;
  status?: string;
}

interface IProps {
  type: {
    statusType: string;
    eventType: string;
    campaignUid?: string;
    campaignConnectedEmailId?: number;
  };
  filterData: any;
}

const RenderEachTableItem: React.FC<DataItem> = ({
  contactId,
  email,
  number = "",
  firstName = "",
  lastName = "",
  createdAt = "",
  failedReason = "",
  type,
  scheduleTime = "",
  status = "",
}) => {
  const createdAtDateTime = (
    window as any
  ).globalTimezoneConversionToDifferentTimezone(
    createdAt,
    "UTC",
    "",
    "MM/DD/YYYY hh:mm a"
  );

  const dateConverter=(data:any)=>{
    const date=(window as any).globalTimezoneConversionToDifferentTimezone(data)
    return date
  }

  const generateName = () => {
    let name = "";

    if (firstName) {
      name += firstName;
    }

    if (lastName) {
      if (name) {
        name += " ";
      }
      name += lastName;
    }

    return name;
  };

  return (
    <TableRow sx={{ bgcolor: !contactId ? "error.light" : "" }}>
      <StyledTableCell width={"25%"}>{generateName()}</StyledTableCell>
      <StyledTableCell width={"35%"}>{email}</StyledTableCell>
      <StyledTableCell width={"20%"}>{number}</StyledTableCell>
      {(type === STATUS_TYPE.OPEN ||
        type === STATUS_TYPE.SEND ||
        type === STATUS_TYPE.FAILED) && (
        <StyledTableCell width={"20%"}>{createdAtDateTime}</StyledTableCell>
      )}

      {type === STATUS_TYPE.FAILED && (
        <StyledTableCell width={"20%"}>
          {failedReason === null
            ? ""
            : failedReason}
        </StyledTableCell>
      )}
      {type === STATUS_TYPE.QUEUE && (
        <StyledTableCell width={"20%"}>
          {scheduleTime === null ? "" : dateConverter(scheduleTime)}
        </StyledTableCell>
      )}
      {type === STATUS_TYPE.QUEUE && (
        <StyledTableCell width={"20%"}>
          {status === null ? "" : status}
          </StyledTableCell>
      )}
    </TableRow>
  );
};

const StatusListTable: React.FC<IProps> = ({ type, filterData }) => {
  const [emailEventList, setEmailEventList] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [isDataFetchComplete, setIsDataFetchComplete] = useState(false);

  const { selectedSequence } = useSelector(emailCampaignState);

  const getEmailEventsAnalytics = async () => {
    try {
      setLoading(true);
      let response: any;

      switch (type.eventType) {
        case EVENT_TYPE.SEQUENCE:
          if (type.statusType === STATUS_TYPE.QUEUE) { 
            response = await emailCampaignService.getQueueData({
              campaignSequenceUid: selectedSequence?.uid as string,
              page,
              limit: 20,
            });

            break;
          }
       
          response = await emailCampaignService.getEmailEventsAnalyticsCampaignSequence({
            campaignSequenceUid: selectedSequence?.uid as string,
            type: type.statusType,
            page,
            limit: 20,
          });
        
          break;
        case EVENT_TYPE.CAMPAIGN:
          response = await emailCampaignService.getEmailEventsAnalyticsCampaign(
            {
              campaignUid: type.campaignUid,
              type: type.statusType,
              page,
              limit: 20,
            }
          );
          break;
        case EVENT_TYPE.EMAIL:
          let payload = {
            campaignConnectedEmailId: type.campaignConnectedEmailId,
            campaignUid: type.campaignUid,
            type: type.statusType,
            page,
            limit: 20,
          };

          if (filterData) {
            if (filterData.filterType === "ALL") {
              filterData.startDate = null;
              filterData.endDate = null;
              delete filterData.filterType;
            }
            payload = { ...payload, ...filterData };
          }
          response =
            await emailCampaignService.getEmailEventsAnalyticsCampaignEmail(
              payload
            );
          break;
      }

      if (response?.success && Array.isArray(response.data)) {
        if (loadingMore && response?.data?.length > 0) {
          setEmailEventList((prevList) => [...prevList, ...response.data]);
        } else {
          if (response?.data?.length > 0) {
            setEmailEventList(response.data);
          } else {
            setIsDataFetchComplete(true);
          }
        }
      } else {
        setEmailEventList([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setInitialLoading(false);
      setLoadingMore(false);
    }
  };
  useEffect(()=>{
    console.log(emailEventList);
  },[emailEventList]);
  useEffect(() => {
    getEmailEventsAnalytics().then();
  }, [page]);
  return (
    <>
      {initialLoading && (
        <Stack alignItems={"center"} justifyContent={"center"} height={"80vh"}>
          <CircularProgress />
        </Stack>
      )}
      {emailEventList.length > 0 && (
        <TableContainer>
          <StyledTable variant={"bordered"} size="small">
            <TableHead>
              <TableRow>
                <TableCell width={"25%"}>
                  <Stack direction="row" alignItems="center">
                    Name
                  </Stack>
                </TableCell>
                <TableCell width={"35%"}>Email</TableCell>
                <TableCell width={"20%"}>Number</TableCell>
                {(type.statusType === STATUS_TYPE.OPEN ||
                  type.statusType === STATUS_TYPE.SEND ||
                  type.statusType === STATUS_TYPE.FAILED) && (
                  <TableCell width={"20%"}>Date</TableCell>
                )}
                {type.statusType === STATUS_TYPE.FAILED && (
                  <TableCell width={"20%"}>Failed Reason</TableCell>
                )}
                {type.statusType === STATUS_TYPE.QUEUE && (
                  <TableCell width={"20%"}>Schedule Date</TableCell>
                )}
                {type.statusType === STATUS_TYPE.QUEUE && (
                  <TableCell width={"20%"}>Status</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody
              onScroll={(e) => {
                const bottom =
                  e.currentTarget.scrollHeight -
                    e.currentTarget.scrollTop -
                    e.currentTarget.clientHeight <
                  50;
                if (bottom && !loading && !isDataFetchComplete) {
                  setLoadingMore(true);
                  if (!loadingMore) {
                    setTimeout(() => {
                      setPage((prevPage) => prevPage + 1);
                    }, 500);
                  }
                }
              }}
            >
              {emailEventList.map((item, index) => (
                <RenderEachTableItem
                  type={type.statusType}
                  key={index}
                  {...item}
                />
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      )}
      {emailEventList.length === 0 && !initialLoading && (
        <EmptyComponent description="" />
      )}
    </>
  );
};

export default StatusListTable;
