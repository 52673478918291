import React, { useEffect, useState } from 'react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { CloseIcon, IconButton, InputAdornment, SearchIcon, Stack } from 'convertupleads-theme';
import { CustomTextField } from '../bulkEmail/BulkEmailStyle';
import useDebounce from '../../hooks/useDebounce';
import { CustomScrollbar } from './CustomScrollBarStyle';
import { Grid } from '@giphy/react-components'

interface GiphySearchProps {
    onSelect: (gifUrl: string) => void;
    onClose: () => void;
}

const GiphySearch: React.FC<GiphySearchProps> = ({ onSelect, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [loadingDataCount, setLoadingDataCount] = useState(1);
    const LIMIT = 20;

    useEffect(() => {
        setLoadingDataCount(loadingDataCount+1);
    }, [debouncedSearchTerm]);

    const handleSelect = (gifUrl: string) => {
        onSelect(gifUrl);
        onClose();
    };

    const giphyFetch = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY ?? '');
    const fetchGifs = (offset: number)=> (searchTerm === '' )
                ? giphyFetch.trending({ limit: LIMIT, offset}) 
                : giphyFetch.search(searchTerm, { limit: LIMIT, offset: offset});

    const onGifClick = (gif: any, e: React.SyntheticEvent<HTMLElement, Event>) => {
        e.preventDefault(); 
        if(gif.images) {
             handleSelect(gif.images.original.url)
        }
       
    }

    return (
        <Stack sx={{ padding: '18px'}}>
            <Stack direction={'row'} justifyContent={'space-between'} spacing={1} sx={{paddingBottom: '16px'}}>
                <CustomTextField
                    fullWidth
                    id='input-with-icon-textfield'
                    placeholder={'Search GIFs'}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon sx={{ height: '16px', width: '16px' }} />
                            </InputAdornment>
                        ),
                    }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Stack >
                    <IconButton
                        variant={'outlined'}
                        size={'small'}
                        color={'inherit'}
                        onClick={(e) => onClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <CustomScrollbar sx={{ height: '50vh' }}>
                <Grid width={590} columns={3} fetchGifs={fetchGifs} key={loadingDataCount} onGifClick={onGifClick}/>
            </CustomScrollbar>
            
            <div style={{textAlign: "right"}}>
                <img style={{ marginBottom: '-16px'}} src="https://s3.us-east-1.amazonaws.com/pypepro/user/1/6VjSeGclapKlu5siMSU9DdhLA3SIQ3eeu9PLTNBK.png" alt="Powered by GIPHY" />
            </div>
        </Stack>
    );
};

export default GiphySearch;
