import React, { useState } from 'react';
import { Button, Stack, useTheme } from 'convertupleads-theme';
import { CustomScrollbar } from '../../../common/CustomScrollBarStyle';
import ABTesting from './ABTesting';
import CampaignName from './CampaignName';
import EmailSender from './EmailSender';
import LookupEmail from './LookupEmail';
import NotificationSetting from './NotificationSetting';
import ReportSettings from './ReportSettings';
import Triggers from './Triggers';
import Unsubscribe from './Unsubscribe';
import EmailThread from "./EmailThread.tsx";
import { getAccountData } from '../../../../helpers/util/functions.tsx';
import HardBounceStop from "./HardBounceStop.tsx";

interface ISettingProps {
    onClose: () => void;
}

const Settings: React.FC<ISettingProps> = ({ onClose }) => {
    const theme = useTheme();

    const [selectedReportSettings, setSelectedReportSettings] = useState<{ [key: string]: boolean }>({});
    const [selectedABtesting, setSelectedABtesting] = useState<{ [key: string]: boolean }>({});
    const [selectedLookupEmail, setSelectedLookupEmail] = useState<{ [key: string]: boolean }>({});
    const [notificationSettings, setNotificationSettings] = useState<{ [key: string]: boolean }>({});
    const [notificationOption, setNotificationOption] = useState<number>(10);

    const handleSaveReportSettings = (settings: { [key: string]: boolean }) => {
        setSelectedReportSettings(settings);
    };
    const handleSaveABtesting = (abTesting: { [key: string]: boolean }) => {
        setSelectedABtesting(abTesting);
    };
    const handleSaveLookupEmail = (lookUpEmail: { [key: string]: boolean }) => {
        setSelectedLookupEmail(lookUpEmail);
    };

    const handleSaveNotificationSettings = (settings: { [key: string]: boolean }) => {
        setNotificationSettings(settings);
    };
    const handleSaveNotificationOption = (option: number) => {
        setNotificationOption(option);
    };

    const handleSaveChanges = () => {
        onClose();
    };

    return (
        <CustomScrollbar sx={{ background: theme.palette.common.white }}>
            <Stack
                height={'calc(100vh - 200px)'}
                alignItems={'center'}
                sx={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                }}
            >
                <Stack paddingRight={1} marginTop={3}>
                    <CampaignName />
                    <EmailSender />
                    <EmailThread />
                    <Triggers />
                    <ReportSettings onSaveChanges={handleSaveReportSettings} />
                    <ABTesting onSaveChanges={handleSaveABtesting} />

                    <HardBounceStop />

                    <NotificationSetting onSaveSettings={handleSaveNotificationSettings} onSaveOption={handleSaveNotificationOption} />
                    {
                        getAccountData('carrierLookup') &&
                        <LookupEmail onSaveChanges={handleSaveLookupEmail} />
                    }
                    <Unsubscribe />
                </Stack>
                <Stack paddingY={2.5} width={'1000px'} direction={'row'} justifyContent={'flex-end'}>
                    {/*<Button variant={'contained'} size={'large'} color={'primary'} onClick={handleSaveChanges}>*/}
                    {/*    Save Changes*/}
                    {/*</Button>*/}
                </Stack>
            </Stack>
        </CustomScrollbar>
    );
};

export default Settings;
