import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  CloseIcon,
  DateRangePicker,
  DateRangeValue,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LoadingButton,
  Paper,
  Popover,
  Select,
  Skeleton,
  Stack,
  styled,
  Typography,
  useTheme,
} from "convertupleads-theme";
import { CustomMenuItem } from "../../../BulkEmailStyle";
import emailCampaignService from "../../../../../services/emailCampaign.service";
import {
  ILeadUserContactFetchedFilterData,
  ILeadUserContactSelectedFilters,
} from "./AddContactFilter";
import { getAccountData } from "../../../../../helpers/util/functions";

const MenuProps = {
  PaperProps: {
    style: {
      paddingLeft: "8px",

      "& .MuiMenuItem-root": {
        color: "gold !important",
      },
    },
  },
};

const renderSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Stack direction={"row"} spacing={1}>
        <Skeleton
          variant="rounded"
          width={"324px"}
          height={"40px"}
          animation="wave"
        />
        <Skeleton
          variant="rounded"
          width={"324px"}
          height={"40px"}
          animation="wave"
        />
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Skeleton
          variant="rounded"
          width={"324px"}
          height={"40px"}
          animation="wave"
        />
        <Skeleton
          variant="rounded"
          width={"324px"}
          height={"40px"}
          animation="wave"
        />
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Skeleton
          variant="rounded"
          width={"324px"}
          height={"40px"}
          animation="wave"
        />
        <Skeleton
          variant="rounded"
          width={"324px"}
          height={"40px"}
          animation="wave"
        />
      </Stack>
    </Stack>
  );
};

interface IProps {
  open: any;
  onClose: () => void;
  onFilter: (data: any) => void;
  fetchedFilterData: ILeadUserContactFetchedFilterData | null;
  setFetchedFilterData: Dispatch<
    SetStateAction<ILeadUserContactFetchedFilterData | null>
  >;
  selectedFilters: ILeadUserContactSelectedFilters;
  setSelectedFilters: Dispatch<SetStateAction<ILeadUserContactSelectedFilters>>;
}

function formatDate(dateString: Date) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const FormWrapper = styled(Grid)(({ theme }) => ({
  "& .filterDatePicker": {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      borderBottom: "none",
      paddingInline: "12px",
      height: "40px",
      margin: 0,
      fontSize: "14px",

      "&::placeholder": {
        color: theme.palette.text.primary,
        opacity: 1,
      },
    },
  },
}));

const dateTimeConversion = (date: string) =>
  (window as any).globalTimezoneConversionToDifferentTimezone(
    date,
    getAccountData("userTimezoneRaw"),
    "UTC",
    "YYYY-MM-DD hh:mm:ss"
  );

const AddContactFilterPopover: React.FC<IProps> = ({
  open,
  onClose,
  onFilter,
  fetchedFilterData,
  setFetchedFilterData,
  selectedFilters,
  setSelectedFilters,
}) => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [resetStateBackup, setResetStateBackup] =
    useState<ILeadUserContactSelectedFilters | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { marketingCampaigns, tags, contactSourceTypes, fileLists, stages } =
    fetchedFilterData || {};

  useEffect(() => {
    if (!fetchedFilterData) {
      fetchFilterData();
    }
  }, []);

  const isAnyItemSelectedInFilter = Object.values(selectedFilters).some(
    (value) => value !== null
  );

  const fetchFilterData = async () => {
    setIsLoading(true);
    try {
      const res = await emailCampaignService.getUserContactListFilterData();

      if (res && res.success) {
        setFetchedFilterData(res.data);
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterSelect = (event: {
    target: { name: string; value: unknown };
  }) => {
    const { name, value } = event.target;
    setSelectedFilters({ ...selectedFilters, [name]: value });
  };

  const handleDateRange = (date: DateRangeValue) => {
    setError(null);

    const startDate = date?.from ? formatDate(date?.from) : null;
    const endDate = date?.to ? formatDate(date?.to) : null;

    if (startDate && endDate) {
      setSelectedFilters({
        ...selectedFilters,
        startDate: dateTimeConversion(startDate + " 00:00:01"),
        endDate: dateTimeConversion(endDate + " 23:59:59"),
      });
    }
  };

  const handleFilterSubmit = () => {
    if (selectedFilters?.startDate) {
      const today = formatDate(new Date());

      if (selectedFilters?.startDate > today) {
        setError("Start date cannot be later than today's date.");
        return;
      } else {
        setError(null);
      }
    }
    onFilter(selectedFilters);
    setResetStateBackup(null);
    onClose();
  };

  const handleReset = () => {
    const resetData = {
      marketingCampaignId: null,
      tagId: null,
      fileListId: null,
      stageId: null,
      leadSourceId: null,
      startDate: null,
      endDate: null,
    };

    setResetStateBackup(selectedFilters);
    setSelectedFilters(resetData);
    setError(null);

    // onFilter(resetData);
  };

  const handlePopupClose = () => {
    if (resetStateBackup) {
      setSelectedFilters(resetStateBackup);
    }

    onClose();
  };

  return (
    <>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handlePopupClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper sx={{ width: "fit-content" }}>
          <Stack width={703}>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              justifyContent={"space-between"}
              py={2}
              px={2.5}
              borderBottom={`1px solid ${theme.palette.other.outlinedBorder}`}
            >
              <Typography variant={"h6"}>Filters</Typography>
              <IconButton
                sx={{
                  border: `1px solid ${theme.palette.action.focus}`,
                  borderRadius: 1,
                  padding: 0.5,
                }}
                onClick={handlePopupClose}
              >
                <CloseIcon sx={{ fontSize: 24 }} />
              </IconButton>
            </Stack>

            <Stack>
              <Stack p={3} pb={0}>
                {isLoading ? (
                  renderSkeleton()
                ) : (
                  <FormWrapper container spacing={1}>
                    <Grid item xs={6}>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            name="marketingCampaignId"
                            value={
                              selectedFilters?.marketingCampaignId || "default"
                            }
                            width={"100%"}
                            placeholder="Filter by campaign"
                            onChange={handleFilterSelect}
                            MenuProps={MenuProps}
                          >
                            <CustomMenuItem
                              color="text.primary"
                              value={"default"}
                              disabled
                            >
                              Filter by campaign
                            </CustomMenuItem>

                            {marketingCampaigns?.map((item, index) => (
                              <CustomMenuItem
                                color="text.primary"
                                value={item?.marketingCampaignId}
                                key={index}
                              >
                                {item?.title}
                              </CustomMenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <IconButton
                          onClick={() =>
                            setSelectedFilters({
                              ...selectedFilters,
                              marketingCampaignId: null,
                            })
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            name="tagId"
                            value={selectedFilters?.tagId || "default"}
                            width={"100%"}
                            onChange={handleFilterSelect}
                            //   IconComponent={ArrowDropDownFilledIcon}
                            MenuProps={MenuProps}
                          >
                            <CustomMenuItem
                              color="text.primary"
                              value={"default"}
                              disabled
                            >
                              Filter by tag
                            </CustomMenuItem>
                            {tags?.map((item, index) => (
                              <CustomMenuItem
                                color="text.primary"
                                value={item?.tagId}
                                key={index}
                              >
                                {item?.title}
                              </CustomMenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <IconButton
                          onClick={() =>
                            setSelectedFilters({
                              ...selectedFilters,
                              tagId: null,
                            })
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            name="stageId"
                            value={selectedFilters?.stageId || "default"}
                            width={"100%"}
                            onChange={handleFilterSelect}
                            MenuProps={MenuProps}
                          >
                            <CustomMenuItem
                              color="text.primary"
                              value={"default"}
                              disabled
                            >
                              Filter by pipeline/stage
                            </CustomMenuItem>
                            {stages?.map((item, index) => (
                              <CustomMenuItem
                                color="text.primary"
                                value={item?.stageId}
                                key={index}
                              >
                                {item?.title}
                              </CustomMenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <IconButton
                          onClick={() =>
                            setSelectedFilters({
                              ...selectedFilters,
                              stageId: null,
                            })
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            name="fileListId"
                            value={selectedFilters?.fileListId || "default"}
                            width={"100%"}
                            onChange={handleFilterSelect}
                            //   IconComponent={ArrowDropDownFilledIcon}
                            MenuProps={MenuProps}
                          >
                            <CustomMenuItem
                              color="text.primary"
                              value={"default"}
                              disabled
                            >
                              Filter by imported list
                            </CustomMenuItem>
                            {fileLists?.map((item, index) => (
                              <CustomMenuItem
                                color="text.primary"
                                value={item?.fileListId}
                                key={index}
                              >
                                {item?.title}
                              </CustomMenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <IconButton
                          onClick={() =>
                            setSelectedFilters({
                              ...selectedFilters,
                              fileListId: null,
                            })
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            name="leadSourceId"
                            value={selectedFilters?.leadSourceId || "default"}
                            width={"100%"}
                            onChange={handleFilterSelect}
                            //   IconComponent={ArrowDropDownFilledIcon}
                            MenuProps={MenuProps}
                          >
                            <CustomMenuItem
                              color="text.primary"
                              value={"default"}
                              disabled
                            >
                              Filter by lead source
                            </CustomMenuItem>
                            {contactSourceTypes?.map((item, index) => (
                              <CustomMenuItem
                                color="text.primary"
                                value={item?.leadSourceId}
                                key={index}
                              >
                                {item?.title}
                              </CustomMenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <IconButton
                          onClick={() =>
                            setSelectedFilters({
                              ...selectedFilters,
                              leadSourceId: null,
                            })
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </Grid>

                    <Grid className="filterDatePicker" item xs={6}>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <DateRangePicker
                          // value={{ from: new Date(), to: new Date() }}
                          value={{
                            from: selectedFilters?.startDate
                              ? new Date(selectedFilters?.startDate)
                              : undefined,
                            to: selectedFilters?.endDate
                              ? new Date(selectedFilters?.endDate)
                              : undefined,
                          }}
                          placeholder="Filter by date range"
                          dualMonth={true}
                          onChange={(value) => handleDateRange(value)}
                        />

                        <IconButton
                          onClick={() => {
                            setSelectedFilters({
                              ...selectedFilters,
                              startDate: null,
                              endDate: null,
                            });
                            setError(null);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>

                      {error && (
                        <Typography variant="caption" color="error">
                          {error}
                        </Typography>
                      )}
                    </Grid>
                    {/* <Grid item xs={6}>
                <FormControl sx={{ width: "100%" }}>
                <Select
                  // value={selectedSmartListContact}
                  width={"100%"}
                  // onChange={(e) => setSelectedSmartListContact(e.target.value as number)}
                  //   IconComponent={ArrowDropDownFilledIcon}
                  MenuProps={MenuProps}
                >
                  <CustomMenuItem color="text.primary" value={10} disabled>
                    Filter by date range
                  </CustomMenuItem>
                  <CustomMenuItem color="text.primary" value={20}>
                
                  </CustomMenuItem>
                
                </Select>
              </FormControl>
              </Grid> */}
                  </FormWrapper>
                )}
              </Stack>
            </Stack>

            <Divider sx={{ mt: 3 }} />
            <Stack
              padding={2}
              direction={"row"}
              justifyContent={"space-between"}
              gap={1}
            >
              <Button
                variant="outlined"
                color="error"
                size="small"
                sx={{ ":focus": { bgcolor: "transparent" } }}
                disabled={!isAnyItemSelectedInFilter}
                onClick={handleReset}
              >
                Reset
              </Button>
              <Stack direction={"row"} spacing={1}>
                <Button
                  variant={"tonal"}
                  color={"inherit"}
                  size="small"
                  sx={{ ":focus": { bgcolor: "transparent" } }}
                  onClick={handlePopupClose}
                >
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  disabled={!!error}
                  // startIcon={<SaveIcon />}
                  position="start"
                  size="small"
                  sx={{
                    "& .MuiButton-startIcon": { height: 20, width: 20 },
                    "&:focus": { bgcolor: "primary.main" },
                  }}
                  onClick={handleFilterSubmit}
                >
                  <span>Confirm</span>
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Popover>
    </>
  );
};

export default AddContactFilterPopover;
