import React, { useEffect, useState } from "react";
import {
  AddIcon,
  Button,
  Stack,
  Typography,
  useTheme,
} from "convertupleads-theme";
import EachMultipleEmailVariation from "./EachMultipleEmailVariation";
import AddOrEditVariationModal from "./AddOrEditVariationModal";
import { useDispatch, useSelector } from "react-redux";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector";
import { getSequenceVariations } from "../../../../state/features/emailCampaign/emailCampaignSlice";

const MultipleEmailVariation: React.FC = () => {
  const theme = useTheme();
  const [openAddVariationModal, setOpenAddVariationModal] = useState(false);

  const { selectedSequence, sequenceVariations, isSequenceVariationsLoading } =
    useSelector(emailCampaignState);

  const handleAddVariation = () => {
    setOpenAddVariationModal(true);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedSequence?.uid) {
      dispatch(getSequenceVariations({ uid: selectedSequence?.uid }));
    }
  }, [selectedSequence?.uid]);

  const renderSequenceVariationList = () => {
    if (isSequenceVariationsLoading) {
      return (
        <Typography variant="h5" mt={2}>
          Loading...
        </Typography>
      );
    } else if (
      sequenceVariations.length === 0 &&
      !isSequenceVariationsLoading
    ) {
      return (
        <Stack pt={3} gap={1}>
          <Typography variant="button" color={"text.primary"} textTransform={'none'}>
            What is an email variation and why is it important?
          </Typography>

          <Typography variant="body2" color={"text.primary"}>
            An email variation, also known as A/B testing, is a marketing
            strategy that involves sending different versions of an email to
            different subsets of a subscriber list, and measuring which one
            generates the best results. The goal is to find out what captures
            your audience’s attention and drives them to take action.
          </Typography>
        </Stack>
      );
    } else if (sequenceVariations.length > 0 && !isSequenceVariationsLoading) {
      return sequenceVariations.map((variant) => (
        <EachMultipleEmailVariation key={variant.uid} variation={variant} />
      ));
    }
  };

  return (
    <Stack
      padding={3}
      borderRadius={1}
      sx={{ border: `1px solid ${theme.palette.other.divider}` }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingBottom={2}
        sx={{ borderBottom: `1px solid ${theme.palette.other.outlinedBorder}` }}
      >
        <Stack>
          <Typography variant="h5" color={"text.primary"}>
            Email Variations
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Button
            variant={"outlined"}
            startIcon={<AddIcon />}
            size="large"
            onClick={handleAddVariation}
            sx={{ ":focus": { bgcolor: "transparent" } }}
          >
            Add Variation
          </Button>
        </Stack>
      </Stack>

      {renderSequenceVariationList()}

      <AddOrEditVariationModal
        open={openAddVariationModal}
        onClose={() => setOpenAddVariationModal(false)}
      />
    </Stack>
  );
};

export default MultipleEmailVariation;
