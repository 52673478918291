import React, {useEffect, useState} from "react";
import {
  AddIcon,
  Button,
  IconButton,
  ModalWithHeader,
  SettingsIcon,
  Stack,
  Typography,
} from "convertupleads-theme";
import AddOrEditFolderModal from "./AddOrEditFolderModal";
import CampaignHeaderSettingsModal from "./CampaignHeaderSettingsModal";
import NewEmailCampaignModal from "./campaigns/campaignContent/NewEmailCampaignModal";
import { getAccountData } from "../../helpers/util/functions";
import {useSearchParams} from "react-router-dom";

const CampaignHeader: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const [openEmailCampaignModal, setEmailCampaignModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  useEffect(() => {
    if (searchParams.has('userConnectedEmailUid')) {
      setOpenSettingsModal(true);
      setSearchParams(searchParams);
    }
  }, []);

  const isSpecificUser =
    getAccountData("userId") === 1 || getAccountData("userId") === 446;

  const handleModal = (): void => {
    setOpenAddFolderModal((prevState) => !prevState);
  };

  const handleEmailCampaignModal = (): void => {
    setEmailCampaignModal((prevState) => !prevState);
  };
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flex={1}
    >
      <Stack>
        <Typography variant="h6">Bulk Email Campaigns</Typography>
        <Typography variant="body2" color="text.secondary">
          Manage all your email campaigns from this dashboard.
        </Typography>
      </Stack>

      <Stack direction={"row"} alignItems="center" spacing={2}>
        {isSpecificUser && (
          <IconButton
            variant="outlined"
            sx={{ bgcolor: "common.white", "&:focus": { bgcolor: "common.white" } }}
            onClick={() => setOpenSettingsModal(true)}
          >
            <SettingsIcon />
          </IconButton>
        )}

        <Button
          variant={"contained"}
          startIcon={<AddIcon />}
          size="large"
          onClick={handleEmailCampaignModal}
          sx={{ "&:focus": { bgcolor: "primary.main" } }}
        >
          New Email Campaign
        </Button>
      </Stack>

      <ModalWithHeader
        open={openAddFolderModal}
        onClose={handleModal}
        title="New Folder"
      >
        <AddOrEditFolderModal onClose={handleModal} />
      </ModalWithHeader>
      <ModalWithHeader
        open={openEmailCampaignModal}
        onClose={handleEmailCampaignModal}
        title="New Email Campaign"
      >
        <NewEmailCampaignModal
          closeCampaignCreateModal={() => setEmailCampaignModal(false)}
        />
      </ModalWithHeader>

        {
          openSettingsModal && (
            <CampaignHeaderSettingsModal
              open={openSettingsModal}
              onClose={() => setOpenSettingsModal(false)}
            />
          )
        }
      
    </Stack>
  );
};

export default CampaignHeader;
