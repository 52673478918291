import React, { useState } from "react";
import {
  CheckCircleIcon,
  CollaboratorsIcon,
  IconButton,
  MailFilledIcon,
  Stack,
  Typography,
  useTheme,
} from "convertupleads-theme";
import {
  ContactMailIcon,
  EmailCheckedIcon,
  EmailMarkUnreadIcon,
  UnsubscribeEmailIcon,
} from "../../../../assets/Icons";
import { IEmailCampaign } from "../../../../state/features/emailCampaign/emailCampaign.interface";
import StatusListDrawer from "../../../common/drawer/statusList/StatusListDrawer";
import {
  EVENT_TYPE,
  STATUS_TYPE,
} from "../../../../helpers/constant/coreConstants";

interface Props {
  campaign: IEmailCampaign;
}

const CampaignStatus: React.FC<Props> = ({ campaign }) => {
  const theme = useTheme();

  const {
    uid,
    totalContact,
    totalSend,
    totalOpen,
    totalClick,
    totalRespond,
    totalBounce,
    totalUnsubscribe,
  } = campaign || {};

  const calculatePercentageAverage = (number: number) => {
    const result = (number / totalSend) * 100 || 0;
    return parseFloat(result.toFixed(2));
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const [type, setType] = useState({
    statusType: "",
    eventType: "",
    campaignUid: uid,
  });
  const handleStatus = async (type: string) => {
    setOpenDrawer(true);
    setType({
      statusType: type,
      eventType: EVENT_TYPE.CAMPAIGN,
      campaignUid: uid,
    });
  };

  const drawerTitle =
    type.statusType === STATUS_TYPE.OPEN
      ? "Open List"
      : type.statusType === STATUS_TYPE.QUEUE
      ? "Queue List"
      : type.statusType === STATUS_TYPE.SEND
      ? "Sent List"
      : type.statusType === STATUS_TYPE.CLICK
      ? "Click List"
      : type.statusType === STATUS_TYPE.RESPOND
      ? "Respond List"
      : type.statusType === STATUS_TYPE.RESPOND
      ? "Respond List"
      : type.statusType === STATUS_TYPE.UNSUBSCRIBE
      ? "Unsubscribe List"
      : type.statusType === STATUS_TYPE.BOUNCE
      ? "Bounce List"
      : "Failed List";

  return (
    <Stack direction={"row"} spacing={3} mt={1} mb={0.4}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        sx={{ cursor: "pointer" }}
      >
        <IconButton
          sx={{
            background: theme.palette.primary.light,
            ":focus": {
              background: theme.palette.primary.light,
            },
          }}
          size="small"
        >
          <CollaboratorsIcon color={"primary"} />
        </IconButton>

        <Typography fontSize={13} color="text.secondary">
          <b>{totalContact}</b> Contacts
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={() => handleStatus(STATUS_TYPE.SEND)}
      >
        <IconButton
          sx={{
            background: theme.palette.primary.light,
            ":focus": {
              background: theme.palette.primary.light,
            },
          }}
          size="small"
        >
          <MailFilledIcon color={"primary"} />
        </IconButton>

        <Typography fontSize={13} color="text.secondary">
          <b>{totalSend}</b> Emails Sent
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={() => handleStatus(STATUS_TYPE.OPEN)}
      >
        <IconButton
          sx={{
            background: theme.palette.primary.light,
            ":focus": {
              background: theme.palette.primary.light,
            },
          }}
          size="small"
        >
          <EmailMarkUnreadIcon
            height={20}
            width={20}
            color={theme.palette.primary.main}
          />
        </IconButton>

        <Typography fontSize={13} color="text.secondary">
          <b>{calculatePercentageAverage(totalOpen)}%</b> Open Rate ({totalOpen}
          )
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={() => handleStatus(STATUS_TYPE.CLICK)}
      >
        <IconButton
          sx={{
            background: theme.palette.primary.light,
            ":focus": {
              background: theme.palette.primary.light,
            },
          }}
          size="small"
        >
          <EmailCheckedIcon
            height={20}
            width={20}
            color={theme.palette.primary.main}
          />
        </IconButton>
        <Typography fontSize={13} color="text.secondary">
          <b>{calculatePercentageAverage(totalClick)}%</b> Click Rate (
          {totalClick})
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={() => handleStatus(STATUS_TYPE.RESPOND)}
      >
        <IconButton
          sx={{
            background: theme.palette.primary.light,
            ":focus": {
              background: theme.palette.primary.light,
            },
          }}
          size="small"
        >
          <CheckCircleIcon color={"primary"} />
        </IconButton>

        <Typography fontSize={13} color="text.secondary">
          <b>{calculatePercentageAverage(totalRespond)}%</b> Reply Rate (
          {totalRespond})
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={() => handleStatus(STATUS_TYPE.BOUNCE)}
      >
        <IconButton
          sx={{
            background: theme.palette.error.light,
            ":focus": {
              background: theme.palette.primary.light,
            },
          }}
          size="small"
        >
          <ContactMailIcon
            height={20}
            width={20}
            color={theme.palette.error.main}
            opacity={1}
          />
        </IconButton>
        <Typography fontSize={13} color="text.secondary">
          <b>{totalBounce}</b> Bounces
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={() => handleStatus(STATUS_TYPE.UNSUBSCRIBE)}
      >
        <IconButton
          sx={{
            background: theme.palette.error.light,
            ":focus": {
              background: theme.palette.primary.light,
            },
          }}
          size="small"
        >
          <UnsubscribeEmailIcon
            height={20}
            width={20}
            color={theme.palette.error.main}
            opacity={1}
          />
        </IconButton>
        <Typography fontSize={13} color="text.secondary">
          <b>{totalUnsubscribe}</b> Unsubscribes
        </Typography>
      </Stack>

      <StatusListDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        drawerTitle={drawerTitle}
        type={type}
        width={1200}
      />
    </Stack>
  );
};

export default CampaignStatus;
