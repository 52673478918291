import React, { useState } from 'react';
import { FolderFilledIcon, ListItemButton, ListItemIcon, ListItemText, Typography } from 'convertupleads-theme';

import { IEmailCampaign } from '../../../../../state/features/emailCampaign/emailCampaign.interface';
import AddToFolderModal from '../AddToFolderModal';

interface IAddFolderComponent {
    onClose: () => void;
    campaign?: IEmailCampaign;
}

const AddFolderComponent: React.FC<IAddFolderComponent> = ({ campaign, onClose }) => {
    const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
    const handleAddFolderModal = () => {
        setOpenAddFolderModal(true);
    };
    return (
        <div>
            <ListItemButton onClick={handleAddFolderModal}>
                <ListItemIcon>
                    <FolderFilledIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText>
                    <Typography color={'text.primary'} variant='body2' fontWeight={400}>
                        Add to folder
                    </Typography>
                </ListItemText>
            </ListItemButton>
            <AddToFolderModal campaign={campaign} open={openAddFolderModal} onClose={onClose} />
        </div>
    );
};

export default AddFolderComponent;
