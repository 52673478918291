import React from "react";
import { DrawerWithHeader, Stack } from "convertupleads-theme";
import StatusListTable from "./StatusListTable";


interface IProps {
  open: boolean;
  onClose: () => void;
  drawerTitle?: string;
  width?: number;
  type: {statusType: string, eventType: string, campaignUid?: string, userConnectedEmailId?: number};
  filterData?: any;
}

const StatusListDrawer: React.FC<IProps> = ({
  open,
  onClose,
  drawerTitle = "Failed List",
  width = 1414,
  type,
    filterData
}) => {
  return (
    <DrawerWithHeader
      open={open}
      anchor={"right"}
      header={drawerTitle}
      width={width}
      onClose={onClose}
    >
      <Stack px={1} mt={1}>
          <StatusListTable type={type} filterData={filterData} />
      </Stack>
    </DrawerWithHeader>
  );
};

export default StatusListDrawer;
