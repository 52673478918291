import { Button, Stack, useTheme } from 'convertupleads-theme';
import React from 'react';

interface Props {
    onClose: () => void;
    onSubmit: () => void;
    submitText?: string;
    closeText?: string;
}

const CustomFooter: React.FC<Props> = ({ onClose, onSubmit, submitText, closeText }) => {
    const theme = useTheme();
    return (
        <Stack
            direction={'row'}
            justifyContent={'end'}
            mt={2}
            spacing={2}
            paddingX={3}
            paddingY={2}
            sx={{ borderTop: `1px solid ${theme.palette.other.outlinedBorder}` }}
        >
            <Button variant={'tonal'} color='inherit' onClick={onClose} size='large'>
                {closeText || 'Cancel'}
            </Button>

            <Button variant={'contained'} size='large' onClick={onSubmit}>
                {submitText || 'Save Changes'}
            </Button>
        </Stack>
    );
};

export default CustomFooter;
