import React, { useEffect, useState } from "react";
import emailCampaignService from "../../../../../../services/emailCampaign.service";
import {
    Button,
  Checkbox,
  DrawerWithHeader,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  LoadingButton,
  SearchIcon,
  Stack,
} from "convertupleads-theme";
import { CustomTextField } from "../../../../BulkEmailStyle";
import { CustomScrollbar } from "../../../../../common/CustomScrollBarStyle";
import useDebounce from "../../../../../../hooks/useDebounce";
import { IContact } from "../../../../../../state/features/emailCampaign/emailCampaign.interface";

interface ITag {
  id: string;
  name: string;
}

interface IProps {
  open: boolean;
  onClose: () => void;
  item: IContact | null;
}
const tagsLimit = 20;
const AddTagsDrawer: React.FC<IProps> = ({ item, open, onClose }) => {
  const [tagsSearchValue, setTagsSearchValue] = useState("");
  const [tags, setTags] = useState<ITag[]>([]);
  const [page, setPage] = useState(1);
  const debouncedSearchTerm = useDebounce(tagsSearchValue, 500);

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isDataFetchComplete, setIsDataFetchComplete] = useState(false);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [debouncedSearchTerm, open, page]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await emailCampaignService.getLeadsEmailTags({
        page,
        limit: tagsLimit,
        search: debouncedSearchTerm,
      });

      if (response?.success && Array.isArray(response.data)) {
        if (loadingMore && response?.data?.length > 0) {
          setTags((prevList) => [...prevList, ...response.data]);
        } else {
          if (response?.data?.length > 0) {
            setTags(response.data);
          } else {
            setIsDataFetchComplete(true);
          }
        }
      } else {
        setTags([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setInitialLoading(false);
      setLoadingMore(false);
    }
  };


  const handleAddTags = (id: string) => {
    if (selectedTags.includes(id)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== id));
    } else {
      setSelectedTags([...selectedTags, id]);
    }
  };

  const handleSubmit = () => {
    const data = {
        contactId: item?.id || item?.contactId,    
        tagsId: selectedTags
    }
    console.log(data);
  };

  return (
    <DrawerWithHeader
      open={open}
      anchor={"right"}
      header={"Add Tags"}
      width={500}
      onClose={onClose}
    >
      <Stack p={2}>
        <CustomTextField
          fullWidth
          placeholder={"Search Tags"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ height: "16px", width: "16px" }} />
              </InputAdornment>
            ),
          }}
          value={tagsSearchValue}
          onChange={(e) => setTagsSearchValue(e.target.value)}
        />

        <CustomScrollbar
          sx={{ height: "calc(100vh - 225px)", mt: 2 }}
          onScroll={(e) => {
            const bottom =
              e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                e.currentTarget.clientHeight <
              50;
            if (bottom && !loading && !isDataFetchComplete) {
              setLoadingMore(true);
              if (!loadingMore) {
                setTimeout(() => {
                  setPage((prevPage) => prevPage + 1);
                }, 500);
              }
            }
          }}
        >
          <FormGroup sx={{ "& .MuiFormControlLabel-root": { ml: 0 } }}>
            {tags?.map((tag) => (
              <FormControlLabel
                key={tag.id}
                control={<Checkbox size="small" />}
                label={tag.name}
                onChange={() => handleAddTags(tag.id)}
              />
            ))}
          </FormGroup>
        </CustomScrollbar>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="flex-end" p={2} sx={{ borderTop: "1px solid", borderColor: "divider" }}>
        <Button color="inherit" variant="tonal" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
        //   loading={isAdding || isUpdating}
          // startIcon={<SaveIcon />}
          position="start"
          sx={{
            "& .MuiButton-startIcon": { height: 20, width: 20 },
            "&:focus": { bgcolor: "primary.main" },
          }}
        >
          Add Tags
        </LoadingButton>
      </Stack>
    </DrawerWithHeader>
  );
};

export default AddTagsDrawer;
