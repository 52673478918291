import {
  Button,
  PauseCircleIcon,
  Stack,
  TablePagination,
  Typography,
  useTheme,
  VideoCircleIcon,
} from "convertupleads-theme";
import React, { Dispatch, SetStateAction } from "react";
import { UnsubscribeEmailIcon } from "../../../../../assets/Icons";
import emailCampaignService from "../../../../../services/emailCampaign.service";
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../../state/features/emailCampaign/emailCampaignSelector";
import { CONTACT_STATUS } from "../../../../../helpers/constant/coreConstants";

interface IProps {
  totalContactsCount: number;
  perPage: number;
  currentPage: number;
  setPerPage: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  selectedContacts: number[];
  setSelectedContacts:Dispatch<SetStateAction<number[]>>
}

const LeadContactListPagination: React.FC<IProps> = ({
  selectedContacts,
  totalContactsCount,
  perPage,
  setPerPage,
  setCurrentPage,
  currentPage,
  setSelectedContacts
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const {selectedCampaign}=useSelector(emailCampaignState)

  const count = totalContactsCount;
  const theme = useTheme();
  const handleUnsubscribeFromList = async () => {
    try {
        // setIsUnsubscribing(true);
        let resp = await emailCampaignService.unsubscribeContact({ contactIds: selectedContacts, campaignUid: selectedCampaign?.uid as string });

        if (resp && resp.success) {
          setSelectedContacts([])
            // setAllChecked(false);
            // setSubmittedUnsubscribeIds(prevState => [...prevState, ...selectedContacts])

            if ((window as any).showNotification) {
                (window as any).showNotification('success', resp.message);
            }
        } else {
            if ((window as any).showNotification) {
                (window as any).showNotification('error', resp.message);
            }
        }
    } catch (err) {
        if ((window as any).showNotification) {
            (window as any).showNotification('error', 'Something went wrong! Try again later');
        }
    } finally {
        // setIsUnsubscribing(false);
    }
};

const handleContactStatus = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, status: string) => {
  event.stopPropagation();

  const statusPayload = status === CONTACT_STATUS.PAUSE ? CONTACT_STATUS.PAUSE : CONTACT_STATUS.RUNNING;
  try {
      let resp = await emailCampaignService.pauseOrResumeContact({ contactIds:selectedContacts, campaignUid: selectedCampaign?.uid as string, status: statusPayload as 'RUNNING' | 'PAUSE'  });

      if (resp && resp.success) {
          setSelectedContacts([]);
          // onSubmitId(status, contact.id);
          if ((window as any).showNotification) {
              (window as any).showNotification('success', resp.message);
          }
      } else {
          if ((window as any).showNotification) {
              (window as any).showNotification('error', resp.message);
          }
      }
  } catch (err) {
      if ((window as any).showNotification) {
          (window as any).showNotification('error', 'Something went wrong! Try again later');
      }
  } finally {
      // setIsUnsubscribing(false);
  }
}

  return (
    <Stack direction={"row"} justifyContent={"space-between"} px={2}>
      <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
        <Typography variant={"caption"} color={"text.secondary"}>
          Total {count} Leads
        </Typography>
      </Stack>

      <Stack direction={"row"} spacing={2}>
        {selectedContacts.length > 0 && (
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Button
              variant="contained"
              color="error"
              sx={{
                "&:focus": {
                  backgroundColor: theme.palette.error.main,
                },
              }}
              size="small"
              startIcon={<PauseCircleIcon />}
              onClick={(e)=>handleContactStatus(e,CONTACT_STATUS.PAUSE)}
            >
              Pause
            </Button>
            <Button
              sx={{
                "&:focus": {
                  backgroundColor: "#346FEF",
                },
              }}
              variant="contained"
              color="primary"
              size="small"
              startIcon={<VideoCircleIcon />}
              onClick={(e)=>handleContactStatus(e,CONTACT_STATUS.RUNNING)}
            >
              Resume
            </Button>
            <Button
              sx={{
                "&:focus": {
                  backgroundColor: "#346FEF",
                },
              }}
              onClick={handleUnsubscribeFromList}
              variant="contained"
              color="primary"
              size="small"
              startIcon={
                <UnsubscribeEmailIcon
                  color={theme.palette.common.white}
                  opacity={1}
                />
              }
            >
              Unsubscribe
            </Button>
          </Stack>
        )}
        <TablePagination
          component="div"
          count={count}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          rowsPerPage={perPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </Stack>
  );
};

export default LeadContactListPagination;
