import { Stack, useTheme } from "convertupleads-theme";
import React, { useEffect, useState } from "react";
import AnalyticsLeftSide from "./AnalyticsLeftSide";
import AnalyticsRightSide from "./AnalyticsRightSide";
import { useParams } from "react-router-dom";
import reportService from "../../../../../services/report.service";
import { IEmailStates } from "../../../../../state/features/emailCampaign/emailCampaign.interface";

export interface IAnalyticsCampaign {
  title: string;
  isFavourite: boolean | null;
  apiKey: string | null;
  startTime: string | null;
  pauseTime: string | null;
  settingInfos: any[] | null;
  createdAt: string;
  updatedAt: string | null;
}

const Analytics: React.FC = () => {
  const theme = useTheme();
  const { campaignUid } = useParams();
  const [campaignStatus, setCampaignStatus] = useState({});
  const [emailStatus, setEmailStatus] = useState<IEmailStates[]>();
  const [campaign, setCampaign] = useState({});

  const analyticsDataFetching = async () => {
    try {
      const result = await reportService.getReportStatus({ campaignUid: campaignUid ? campaignUid as string : null });
  
      if(result.success){
        setCampaignStatus(result?.data?.campaignStats);
        setEmailStatus(result?.data?.emailStats);
  
        if(result?.data?.campaign){
          setCampaign(result?.data?.campaign);
        }
      }
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
    
  };

  useEffect(() => {
    analyticsDataFetching().then();
  }, []);


  return (
    <Stack
      direction={"row"}
      width={"100%"}
      sx={{
        background: theme.palette.other.outlinedBorder,
        borderRadius: "4px",
      }}
    >
      <AnalyticsLeftSide campaignStatus={campaignStatus} />
      <AnalyticsRightSide campaign={campaign as IAnalyticsCampaign}  campaignUid={campaignUid as string} />
    </Stack>
  );
};

export default Analytics;
