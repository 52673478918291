import React from "react";
import { Skeleton, Stack, useTheme } from "convertupleads-theme";

const CampaignSequenceSidebarSkeleton: React.FC = () => {
    const theme = useTheme()
  return (
    <Stack  margin={3} borderRadius={2} p={2} width={"35%"} sx={{ background: theme.palette.other.outlinedBorder, mt:'30px' }} spacing={2}>
     <Skeleton variant="rounded" width={'100%'} height={'145px'}/>
     <Skeleton variant="rounded" width={'100%'} height={'145px'}/>
     <Skeleton variant="rounded" width={'100%'} height={'145px'}/>
     <Skeleton variant="rounded" width={'100%'} height={'145px'}/>
     <Skeleton variant="rounded" width={'100%'} height={'145px'}/>
    </Stack>
  );
};

export default CampaignSequenceSidebarSkeleton;