import React, { ChangeEvent, useState } from "react";
import {
    Button,
    Divider,
    Modal,
    Paper,
    Stack,
    Typography,
    useTheme,
} from "convertupleads-theme";
import emailCampaignService from "../../../../../../../services/emailCampaign.service.ts";
import {showNotification} from "../../../../../../../helpers/util/functions.tsx";
import {CustomTextField} from "../../../../../BulkEmailStyle.tsx";
import {useSelector} from "react-redux";
import {emailCampaignState} from "../../../../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import Loading from "./Loading.tsx";
import {useImportSpreadsheetContext} from "../../importSpreadsheetReducer.tsx";

interface IProps {
    email: string;
    open: boolean;
    onClose: () => void;
    resetOnClose: boolean;
    info?: {
        uid: string;
    };
}

const Reconnection: React.FC<IProps> = ({ email, open, onClose, resetOnClose, info }) => {
    const {
        handleGoPreviousStep,
        resetImportData
    } = useImportSpreadsheetContext();
    const theme = useTheme();
    const { selectedCampaign } = useSelector(emailCampaignState);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleGoogleAuthUrl = async () => {
        setIsSubmitting(true);
        try{
            let state = info ? window.btoa(`${info.uid}:${selectedCampaign?.uid}`) : selectedCampaign?.uid;
            const resp = await emailCampaignService.googleAuthUrl({ email: email, state: state, reconnect: true });

            if (resp?.success && resp?.data){
                if (resp?.data?.permission === false){
                    setIsLoading(true);
                    showNotification("success", "Please wait while redirecting...");
                    setTimeout(()=>{
                        window.open(resp.data.url, "_self");
                    },2000);
                }else {
                    showNotification("info", resp?.message);
                }
            }else {
                showNotification("error", resp?.message);
                setIsLoading(false);
            }
        }catch (err){
            console.log(err);
            showNotification("error", "Something went wrong!");
            setIsLoading(false);
        }finally {
            setIsSubmitting(false);
        }
    }

    return (
        <Modal open={open} onClose={()=>{}}>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    width: "600px",
                    borderRadius: "12px",
                }}
            >
                {
                    isLoading ?
                        <Stack>
                            <Loading loaderText={"Please wait while redirecting..."}/>
                        </Stack> :
                        <Stack>
                            <Stack direction={"row"} justifyContent={"center"} px={3} py={2}>
                                <Stack direction={"column"} alignItems={"center"}>
                                    <Typography variant={"h5"} textAlign={"center"}>
                                        Reconnect email
                                    </Typography>
                                    <Typography variant={"p"} mt={1} textAlign={"center"}>
                                        Permission expired please reconnect your email
                                    </Typography>
                                    <Stack
                                        direction={"column"}
                                        alignItems={"center"}
                                        mt={2}
                                        width={"100%"}
                                    >
                                        <CustomTextField
                                            fullWidth
                                            onChange={(event: ChangeEvent) => {

                                            }}
                                            value={email}
                                            disabled
                                            sx={{
                                                "& input": {
                                                    textAlign: "center"
                                                }
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack width={"100%"}>
                                <Divider />
                                <Stack
                                    padding={2}
                                    direction={"row"}
                                    justifyContent={"flex-end"}
                                    gap={1}
                                >
                                    <Button
                                        variant={"tonal"}
                                        color={"inherit"}
                                        size={"large"}
                                        onClick={()=>{
                                            onClose();
                                            if (resetOnClose) {
                                                resetImportData();
                                                handleGoPreviousStep();
                                            }
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button disabled={isSubmitting} size={"large"} onClick={handleGoogleAuthUrl}>
                                        Reconnect
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                }
            </Paper>
        </Modal>
    );
};

export default Reconnection;