import React, { useEffect, useState } from 'react';
import {
    Button,
    Stack,
    Typography,
    Divider,
    InputAdornment,
    SearchIcon,
    FormControl,
    Select,
    FormGroup,
    FormControlLabel,
    Checkbox,
    styled,
    ArrowDropDownFilledIcon,
    Pagination,
    CircularProgress,
    LoadingButton,
} from 'convertupleads-theme';
import Contact from './Contact';

import { CustomScrollbar } from '../../../common/CustomScrollBarStyle';
import { CustomMenuItem, CustomTextField } from "../../BulkEmailStyle.tsx";
import emailCampaignService from "../../../../services/emailCampaign.service.ts";
import useDebounce from "../../../../hooks/useDebounce.ts";
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import { PersonAddIcon } from "../../../../assets/Icons.tsx";
import { IContact } from '../../../../state/features/emailCampaign/emailCampaign.interface.ts';
import EmptyComponent from '../../emptyComponent/EmptyComponent.tsx';
import EachElasticContact from './EachElasticContact.tsx';
import { getAccountData } from '../../../../helpers/util/functions.tsx';
import { USER_ROLE_ADMIN, USER_ROLE_AGENT, USER_ROLE_OWNER } from '../../../../helpers/constant/coreConstants.ts';

const StyledButton = styled(Button)(({ theme }) => ({
    background: theme.palette.other.outlinedBorder,
    color: theme.palette.text.secondary,
    '&:hover, :focus': {
        background: theme.palette.other.outlinedBorder,
        color: theme.palette.text.secondary,
    },
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    width: 'unset',
    boxShadow: 'none',
    height: 'unset !important',
    lineHeight: 'unset !important'
}));

interface IProps {
    handleClose: () => void;
}


const ContactList: React.FC<IProps> = ({ handleClose }) => {
    const { selectedCampaign } = useSelector(emailCampaignState);
    const [allChecked, setAllChecked] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [totalContacts, setTotalContacts] = useState(0);
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [elasticContacts, setElasticContacts] = useState<IContact[]>([]);
    const debouncedSearch = useDebounce(searchValue, 1000);
    const [isAssigning, setIsAssigning] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [submittedContacts, setSubmittedContacts] = useState<number[]>([]);

    const ELASTIC_STATUS = process.env.REACT_APP_BULK_EMAIL_ADD_CONTACT_LIST_ELASTIC_STATUS === 'TRUE' ? true : false;

    useEffect(() => {


        ELASTIC_STATUS ? fetchElasticData() : fetchData();

    }, [currentPage, perPage, debouncedSearch]);

    const fetchData = async () => {
        try {
            const res = await emailCampaignService.getContactList({
                search: debouncedSearch,
                currentPage,
                perPage,
                requiredField: 'EMAIL',
                campaignUid: selectedCampaign?.uid as string,
                role: getAccountData('userIsOwner') ? USER_ROLE_OWNER : getAccountData('userIsAdmin') ? USER_ROLE_ADMIN : USER_ROLE_AGENT
            });

            if (res && res.success) {
                setContacts(res.data);
                if (currentPage === 1) {
                    setTotalContacts(res.totalCount);
                    setTotalPage(res.totalPage);
                }
            }
        } catch (error) {
            // Handle error
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false)
        }
    };

    const fetchElasticData = async () => {
        try {
            const res = await emailCampaignService.getContactListElastic({
                search: debouncedSearch,
                currentPage,
                perPage,
                campaignUid: selectedCampaign?.uid as string,
                role: getAccountData('userIsOwner') ? USER_ROLE_OWNER : getAccountData('userIsAdmin') ? USER_ROLE_ADMIN : USER_ROLE_AGENT
            });

            if (res && res.success) {
                setElasticContacts(res.data);
                if (currentPage === 1) {
                    setTotalContacts(res.totalCount);
                    setTotalPage(res.totalPage);
                }
            }
        } catch (error) {
            // Handle error
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false)
        }
    };

    const handleAllChecked = () => {
        setAllChecked(!allChecked);
        if (!allChecked) {
            const allContactNames = contacts
                .filter(contact => !submittedContacts.includes(contact.contactId as number))
                .map((contact) => contact.contactId)
                .filter((id): id is number => id !== undefined);

            const allElasticContactNames = elasticContacts
                .filter(contact => !submittedContacts.includes(contact.id as number))
                .map((contact) => contact.id)
                .filter((id): id is number => id !== undefined);

            setSelectedContacts(ELASTIC_STATUS ? allElasticContactNames : allContactNames);
        } else {
            setSelectedContacts([]);
        }
    };

    const handleContactCheckboxChange = (contactId: number) => {
        let updatedContacts: number[];

        if (selectedContacts.includes(contactId)) {
            updatedContacts = selectedContacts.filter((contact) => contact !== contactId);
        } else {
            updatedContacts = [...selectedContacts, contactId];
        }

        setSelectedContacts(updatedContacts);
        setAllChecked(ELASTIC_STATUS ? updatedContacts.length === elasticContacts.length : updatedContacts.length === contacts.length);
    };

    const handleSubmit = async () => {
        try {
            setIsAssigning(true);

            let resp = await emailCampaignService.assignContact({ contactIds: selectedContacts, campaignUid: selectedCampaign?.uid });
            if (resp && resp.success) {
                setSubmittedContacts(prevContacts => [...prevContacts, ...selectedContacts]);
                setAllChecked(false);
                setSelectedContacts([]);
                if ((window as any).showNotification) {
                    (window as any).showNotification('success', resp.message);
                }
            } else {
                if ((window as any).showNotification) {
                    (window as any).showNotification('error', resp.message);
                }
            }
        } catch (err) {
            if ((window as any).showNotification) {
                (window as any).showNotification('error', 'Something went wrong! Try again later');
            }
        } finally {
            setIsAssigning(false);
        }
    };

    const MenuProps = {
        PaperProps: {
            style: {
                paddingLeft: '8px'
            },
        },
    };

    return (
        <Stack height={'100%'} overflow={'hidden'}>
            <Stack spacing={2} p={3}>
                <CustomTextField
                    fullWidth
                    id='input-with-icon-textfield'
                    placeholder={'Search Contacts'}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon sx={{ height: '16px', width: '16px' }} />
                            </InputAdornment>
                        ),
                    }}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </Stack>
            <Divider variant={'fullWidth'} />
            <Stack>
                {
                    (contacts.length > 0 || elasticContacts.length > 0) && !isLoading && (
                        <Stack p={'12px 24px 0px 24px'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant={'body2'} color={'text.secondary'}>
                                {totalContacts} contacts
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox color={'primary'} size={'small'} checked={allChecked} onChange={handleAllChecked} />}
                                    label=''
                                    sx={{ mr: "25px" }}
                                />
                            </FormGroup>
                        </Stack>
                    )
                }

                <Stack>
                    <CustomScrollbar>
                        {
                            isLoading && (
                                <Stack alignItems={'center'} justifyContent={'center'} height={573}>
                                    <CircularProgress />
                                </Stack>
                            )
                        }

                        {
                            !ELASTIC_STATUS && contacts && contacts[0] && !isLoading && (
                                <Stack spacing={1} p={'6px 24px 12px 24px'} height={'calc(100vh - 430px)'}>
                                    {contacts.map((contact, index) => (
                                        <Contact
                                            key={index}
                                            contact={contact}
                                            isChecked={selectedContacts.includes(contact.contactId as number)}
                                            onCheckboxChange={handleContactCheckboxChange}
                                            submittedContacts={submittedContacts}
                                        />
                                    ))}
                                </Stack>
                            )
                        }

                        {
                            ELASTIC_STATUS && elasticContacts && elasticContacts[0] && !isLoading && (
                                <Stack spacing={1} p={'6px 24px 12px 24px'} height={'calc(100vh - 430px)'}>
                                    {elasticContacts.map((contact, index) => (
                                        <EachElasticContact
                                            key={index}
                                            contact={contact}
                                            isChecked={selectedContacts.includes(contact.id as number)}
                                            onCheckboxChange={handleContactCheckboxChange}
                                            submittedContacts={submittedContacts}
                                        />
                                    ))}
                                </Stack>
                            )
                        }

                        {
                            !isLoading && contacts.length === 0 && elasticContacts.length === 0 && (
                                <Stack alignItems={'center'} justifyContent={'center'} height={573}>
                                    <EmptyComponent />
                                </Stack>
                            )
                        }



                    </CustomScrollbar>
                    {
                        totalPage ?
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={1} px={2}>
                                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                    <Typography variant='body2' color={'text.secondary'}>
                                        Rows Per Page:
                                    </Typography>
                                    <FormControl>
                                        <Select
                                            value={perPage}
                                            width={64}
                                            sx={{ height: "32px" }}
                                            onChange={(event) => {
                                                setPerPage(event.target.value as number);
                                                setCurrentPage(1);
                                            }}
                                            IconComponent={ArrowDropDownFilledIcon}
                                            MenuProps={MenuProps}
                                        >
                                            <CustomMenuItem value={10}>10</CustomMenuItem>
                                            <CustomMenuItem value={20}>20</CustomMenuItem>
                                            <CustomMenuItem value={30}>30</CustomMenuItem>
                                            <CustomMenuItem value={40}>40</CustomMenuItem>
                                            <CustomMenuItem value={50}>50</CustomMenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <StyledPagination
                                    count={totalPage}
                                    page={currentPage}
                                    onChange={(_event: React.ChangeEvent<unknown>, newPage: number) => { setCurrentPage(newPage) }}
                                    variant={'outlined'}
                                    shape={'rounded'}
                                />
                            </Stack>
                            : <Stack sx={{ height: '48px' }}></Stack>
                    }
                </Stack>
            </Stack>

            {
                (contacts[0] || elasticContacts[0]) && (
                    <>
                        <Divider sx={{ mt: '6px' }} variant={'fullWidth'} light />

                        <Stack direction={'row'} spacing={1} p={'16px 20px'} justifyContent={'space-between'}>
                            <StyledButton variant={'tonal'} size={'large'} onClick={handleClose}>
                                Cancel
                            </StyledButton>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography> {selectedContacts.length} selected</Typography>
                                <LoadingButton
                                    variant={'contained'}
                                    startIcon={<PersonAddIcon />}
                                    size={'large'}
                                    sx={{ '& .MuiButton-startIcon': { height: 20, width: 20 }, '&:focus': { bgcolor: 'primary.main' } }}
                                    loading={isAssigning}
                                    onClick={handleSubmit}
                                    disabled={isAssigning || selectedContacts.length === 0}
                                >
                                    Add to the List
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </>
                )
            }

        </Stack>
    );
};

export default ContactList;

