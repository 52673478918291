import React, { ReactElement, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ChevronDownIcon,
    Popover,
    Stack,
    Typography,
    List,
    ListItemButton,
    ListItemText,
    Divider,
} from 'convertupleads-theme';
import { CustomScrollbar } from './CustomScrollBarStyle';
import emailCampaignService from '../../services/emailCampaign.service';

interface IPersonalizedPopover {
    id: string | undefined;
    open: boolean;
    anchorEl: HTMLSpanElement | SVGSVGElement | null;
    onClose: () => void;
    onSelect: (value: string | null) => void;
}

interface ICustomField {
    group_name: string;
    list: { field_label: string; value: string | null }[];
    total: number;
    group_value: string;
}

interface IPersonalizedField {
    field_label: string;
    value: string | null;
    order?: number;
}

const PersonalizedPopover: React.FC<IPersonalizedPopover> = ({ id, open, onClose, anchorEl, onSelect }) => {
    const [userPersonalizedFields, setUserPersonalizedFields] = useState<IPersonalizedField[]>([]);
    const [customField, setCustomField] = useState<ICustomField[]>([]);

    const userPersonalizedApi = async () => {
        try {

            const result = await emailCampaignService.personalizedTags();
          
            if (result.success) {
                setUserPersonalizedFields(result?.data?.personalized_field)

                const groupedData = result?.data?.custom_field?.reduce((acc: any, item: any) => {
                    if (!acc[item.group_name]) {
                        acc[item.group_name] = [];
                    }
                    acc[item.group_name].push(item);
                    return acc;
                }, {});

                const groupResult = Object.entries(groupedData).map(([group_name, list]) => ({
                    group_name,
                    list: list as ICustomField['list'],
                    total: (list as ICustomField['list']).length,
                    group_value: 'custom_field'
                }));
            
                setCustomField(groupResult)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        userPersonalizedApi();
    }, [])


    

    const personalizedTags = [
        {
            "group_name": "Contact",
            "list": [
                {
                    "field_label": "First Name",
                    "value": "[[first_name]]",
                    "order": 1
                },
                {
                    "field_label": "Last Name",
                    "value": "[[last_name]]",
                    "order": 2
                },
                {
                    "field_label": "Contact Email",
                    "value": "[[email]]",
                    "order": 3
                },
                {
                    "field_label": "Contact Phone Number",
                    "value": "[[contact_phone_number]]",
                    "order": 4
                },
                {
                    "field_label": "Property Country",
                    "value": "[[contact_country]]",
                    "order": 5
                },
                {
                    "field_label": "Property State",
                    "value": "[[contact_state]]",
                    "order": 6
                },
                {
                    "field_label": "Contact City",
                    "value": "[[contact_city]]",
                    "order": 7
                },
                {
                    "field_label": "Property Street Address",
                    "value": "[[contact_street_address]]",
                    "order": 8
                },
                {
                    "field_label": "Contact Company Name",
                    "value": "[[contact_company_name]]",
                    "order": 9
                },
                {
                    "field_label": "Property URL",
                    "value": "[[contact_url]]",
                    "order": 10
                },
                {
                    "field_label": "Property Zip Code",
                    "value": "[[contact_zip_code]]",
                    "order": 11
                },
                {
                    "field_label": "Contact Deal Value",
                    "value": "[[contact_deal_value]]",
                    "order": 12
                },
                {
                    "field_label": "Contact Birth Date",
                    "value": "[[contact_birth_date]]",
                    "order": 13
                },
                {
                    "field_label": "Contact Anniversary Date",
                    "value": "[[contact_anniversary_date]]",
                    "order": 14
                }
            ],
            "total": 14,
            "group_value": "pype_default_contact"
        },
        {
            "group_name": "User",
            "list": [
                {
                    "field_label": "My Virtual Number",
                    "value": "[[virtual_number]]",
                    "order": 1
                },
                {
                    "field_label": "My Company",
                    "value": "[[company]]",
                    "order": 2
                },
                {
                    "field_label": "My Name",
                    "value": "[[my_name]]",
                    "order": 3
                },
                {
                    "field_label": "My First Name",
                    "value": "[[my_first_name]]",
                    "order": 4
                },
                {
                    "field_label": "My Last Name",
                    "value": "[[my_last_name]]",
                    "order": 5
                },
                {
                    "field_label": "My Phone",
                    "value": "[[my_phone]]",
                    "order": 6
                },
                {
                    "field_label": "My Signature",
                    "value": "[[my_signature]]",
                    "order": 7
                },
                {
                    "field_label": "My Appointment URL",
                    "value": "[[appointment_url]]",
                    "order": 8
                },
                {
                    "field_label": "My Email",
                    "value": "[[my_email]]",
                    "order": 9
                }
            ],
            "total": 9,
            "group_value": "pype_default_user"
        },
        {
            "group_name": "Date",
            "list": [
                {
                    "field_label": "Today Date",
                    "value": "[[today]]",
                    "order": 1
                },
                {
                    "field_label": "Tomorrow Date",
                    "value": "[[tomorrow]]",
                    "order": 2
                }
            ],
            "total": 2,
            "group_value": "pype_date"
        },
        {
            "group_name": "Agency",
            "list": [
                {
                    "field_label": "Agency Full Name",
                    "value": "[[agency_full_name]]",
                    "order": 1
                }
            ],
            "total": 1,
            "group_value": "pype_agency"
        },
        ...customField,
        {
            "group_name": "User Personalized Fields",
            "list": userPersonalizedFields || [],
            "total": userPersonalizedFields?.length || 0,
            "group_value": "user_cutomize_personalized_fields"
        }
    ];

    return (
        <Popover
            id={id}
            sx={{ marginTop: '10px' }}
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <CustomScrollbar>
                <Stack sx={{ width: 'fit-content', p: 1, maxWidth: '359px', maxHeight: '355px' }}>
                    <div>
                        {
                            (() => {
                                const prItem: ReactElement[] = [];

                                personalizedTags.forEach((eachTag: { group_name: string; total: number; list: { field_label: string; value: string | null; order?: number }[] }) => {
                                    const psbItem: ReactElement[] = [];

                                    psbItem.push(
                                        <Accordion key={eachTag?.group_name} sx={{  bgcolor: 'rgba(123,36,255,0.1)',  mb: 1,border:0,borderRadius:'4px'}}>
                                            <AccordionSummary expandIcon={<ChevronDownIcon sx={{color:'#1D2939'}}/>} sx={{minHeight: 35}}>
                                                <Stack direction='row' alignItems='center' spacing={1}>
                                                    <Typography /* variant='overline' */ color='text.primary' fontWeight={600}>
                                                        {eachTag?.group_name} ({eachTag?.list?.length})
                                                    </Typography>

                                                    {/* <Badge badgeContent={eachTag.total} sx={{ '& .MuiBadge-badge': { position: 'relative', transform: 'none' } }} /> */}
                                                </Stack>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{pt: 0}}>
                                                <List dense sx={{py: 0}}>
                                                    {eachTag.list.map((eachItem: { field_label: string; value: string | null }, index: number, arr) => (
                                                        <>
                                                            <ListItemButton sx={{':hover': {bgcolor: 'primary.light'}}} key={index} onClick={() => { onSelect(eachItem?.value) }}>
                                                                <ListItemText sx={{ color: 'text.primary' }}>
                                                                    {eachItem?.field_label}
                                                                </ListItemText>
                                                            </ListItemButton>
                                                            {arr.length - 1 !== index && <Divider light />}
                                                        </>
                                                    ))}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                    );

                                    prItem.push(...psbItem);
                                });

                                return prItem;
                            })()
                        }
                    </div>
                </Stack>
            </CustomScrollbar>
        </Popover>
    );
};

export default PersonalizedPopover;
