import React, { Dispatch, SetStateAction, useState } from "react";

import {
  AddIcon,
  Button,
  Checkbox,
  Chip,
  Stack,
  TableCell,
  TableRow,
} from "convertupleads-theme";
import { IContact } from "../../../../../state/features/emailCampaign/emailCampaign.interface";

interface RenderEachTableItemProps {
  selectedContacts: string[];
  onCheckboxChange: (id: string) => void;
  item: IContact;
  isChecked: boolean;
  setTagsDrawer: Dispatch<SetStateAction<IContact | null>>
}

const generateName = (contact: IContact) => {
  let name = '';

  if (contact.firstName) {
      name += contact.firstName;
  }

  if (contact.lastName) {
      if (name) {
          name += " ";
      }
      name += contact.lastName;
  }

  return name;
};

const EachTableItem: React.FC<RenderEachTableItemProps> = (props) => {
  const { selectedContacts, onCheckboxChange, isChecked, item, setTagsDrawer } = props;
  const { id, contactId, email, tags, fullName, number } = item;

  const [checked, setChecked] = useState(isChecked);

  let name = generateName(item);

  const isSelected = selectedContacts.includes(id || contactId as string);

  const handleCheckboxChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onCheckboxChange(id as string || contactId as string);
};

if (isChecked !== checked) {
    setChecked(isChecked);
}

  const isNumberInvalid =
    number === "invalidnumber" ||
    number === null ||
    number === undefined ||
    number === "";
  const isEmailInvalid = email === null || email === undefined || email === "";

  return (
    <TableRow>
      <TableCell sx={{ "&:hover .moreButton": { visibility: "visible" } }}>
        {/* <IconButton
                className="moreButton"
                size="small"
                sx={{ visibility: "hidden", ":focus": { bgcolor: "transparent" } }}
              >
                <MoreVerticalIcon />
              </IconButton> */}
        <Checkbox
          size="small"
          checked={checked}
          onClick={handleCheckboxChange}
        />
        {fullName || name || "N/A"}
      </TableCell>
      <TableCell>{!isEmailInvalid ? email : "N/A"}</TableCell>
      <TableCell>{!isNumberInvalid ? number : "N/A"}</TableCell>
      <TableCell width={"20%"}>
        <Stack direction="row" gap={1} flexWrap={"wrap"}>
          {/* <Button
            variant="outlined"
            rounded
            endIcon={<AddIcon />}
            size="small"
            sx={{ height: 24, ":focus": { bgcolor: "transparent" } }}
            onClick={() => setTagsDrawer(item)}
          >
            Add
          </Button> */}
          {tags && tags.map((tag) => <Chip label={tag.name} size="xSmall" />)}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default EachTableItem;
