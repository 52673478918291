import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import GiphySearch from './GiphySearch';
import { Modal, Stack } from 'convertupleads-theme';
import { getAccountData } from '../../helpers/util/functions';
import emailCampaignService from "../../services/emailCampaign.service.ts";
import OpenAIApiKeyPopup from "./OpenAIApiKeyPopup/OpenAIApiKeyPopup.tsx";

interface Props {
    body: string;
    onChangeBody: (value: string) => void;
    placeholder?: string;
}

const BasicEditorFormWithGif: React.ForwardRefRenderFunction<Editor, Props> = (
    { body, onChangeBody, placeholder = '', ...rest },
    ref
) => {
    const [giphyModalOpen, setGiphyModalOpen] = useState(false);
    const [openAIApiKeyModalOpen, setOpenAIApiKeyModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const handleGiphySelect = (gifUrl: string) => {
        onChangeBody(body + `<img src="${gifUrl}" alt="Giphy GIF" />`);
        setGiphyModalOpen(false);
    };

    const ai_request = async (request: any, respondWith: any) => {

        respondWith.string(async () => await emailCampaignService.getOpenAiApiKey({
            content: request.prompt
        }).then((resp) => {
            if (resp) {
                if (resp.success && resp.data && resp.data.message) {
                    return resp.data.message;
                }
                else {
                    if (resp.data.responseCode && resp.data.responseCode === 12401) {
                        setOpenAIApiKeyModalOpen(true);
                        if (isUpdate) {
                            setIsUpdate(false);
                        }
                        throw new Error('Please add your OpenAi api-key');
                    } else if (resp.data.responseCode && resp.data.responseCode === 12403) {
                        setOpenAIApiKeyModalOpen(true);
                        setIsUpdate(true);
                        throw new Error('Your OpenAI Api key is Invalid');
                    }
                    else if (resp.data.message) {
                        throw new Error(resp.data.message);
                    } else {
                        alert('48')
                        throw new Error('Failed to communicate with the ChatGPT API');
                    }

                }
            }
            else {
                throw new Error('Failed to communicate with the ChatGPT API');
            }
        })
        )
    }

    // const openAiOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${api_key}`
    //     },
    //     body: JSON.stringify({
    //         model: 'gpt-3.5-turbo',
    //         temperature: 0.7,
    //         max_tokens: 256,
    //         messages: [{ role: 'user', content: request.prompt }],
    //     })
    // };
    // respondWith.string((signal) => window.fetch('https://api.openai.com/v1/chat/completions', { signal, ...openAiOptions })
    //     .then(async (response) => {
    //         if (response) {
    //             const data = await response.json();
    //             if (data.error) {
    //                 throw new Error(`${data.error.type}: ${data.error.message}`);
    //             } else if (response.ok) {
    //                 // Extract the response content from the data returned by the API
    //                 return data?.choices[0]?.message?.content?.trim();
    //             }
    //         } else {
    //             throw new Error('Failed to communicate with the ChatGPT API');
    //         }
    //     })
    // );


    return (
        <div>


            <Editor
                ref={ref}
                apiKey={`f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x`}
                value={body}
                init={{
                    height: 300,
                    auto_focus: undefined,
                    menubar: false,
                    menu: {
                        favs: {
                            title: 'My Favourites',
                            items: 'code visualaid | searchreplace | emoticons',
                        },
                    },
                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tableofcontents footnotes mergetags autocorrect typography inlinecss markdown preview',
                    ai_request,
                    convert_urls: false,
                    toolbar: 'insertfile undo redo | styleselect | blocks fontfamily fontsize | bold italic backcolor | alignleft aligncenter alignright alignjustify | link image | bullist numlist outdent indent | removeformat | preview fullpage | media | emoticons | giphy | aidialog aishortcuts | checklist | footnotes',
                    automatic_uploads: true,
                    target_list: [
                        { title: 'Same page', value: '_self' },
                        { title: 'New page', value: '_blank' },
                    ],
                    content_style: 'p { margin: 0px; }',
                    placeholder: placeholder,
                    forced_root_block: false,
                    indent: false,
                    ai_shortcuts: [
                        { title: 'Screenplay', prompt: 'Convert this to screenplay format.', selection: true },
                        { title: 'Stage play', prompt: 'Convert this to stage play format.', selection: true },
                        {
                            title: 'Classical', subprompts:
                                [
                                    { title: 'Dialogue', prompt: 'Convert this to a Socratic dialogue.', selection: true },
                                    { title: 'Homeric', prompt: 'Convert this to a Classical Epic.', selection: true }
                                ]
                        },
                        {
                            title: 'Celtic', subprompts:
                                [
                                    { title: 'Bardic', prompt: 'Convert this to Bardic verse.', selection: true },
                                    { title: 'Filí', prompt: 'Convert this to Filí-an verse.', selection: true }
                                ]
                        },
                    ],
                    setup: (editor) => {
                        editor.ui.registry.addButton('giphy', {
                            text: 'Giphy',
                            icon: 'image',
                            tooltip: 'Insert GIF',
                            onAction: () => {
                                setGiphyModalOpen(true);
                            }
                        });
                    },
                    images_reuse_filename: true,
                    images_file_types: 'jpeg,jpg,png,gif,svg',
                    images_upload_handler: async (blobInfo: any) => {

                        return new Promise((resolve, reject) => {
                            const formData = new FormData();
                            formData.append('imageFile', blobInfo.blob(), blobInfo.filename());
                            formData.append('campaignUid', 'tiny-image');

                            emailCampaignService.uploadGraphImage(formData)
                                .then((resp: any) => {
                                    if (resp?.success) {
                                        resolve(resp.data.imageUrl);
                                    } else {
                                        if (resp.message === "Validation-Error") {
                                            reject(resp.data.imageFile[0]);
                                        } else {
                                            reject(resp.message);
                                        }
                                    }
                                });
                        })
                    }
                }
                }
                onEditorChange={onChangeBody}
                {...rest}
            />
            <Modal
                open={giphyModalOpen}
                onClose={() => setGiphyModalOpen(false)}
                sx={{
                    '& .MuiPaper-root': {
                        width: '640px'
                    },
                }}
            >
                <Stack>
                    <GiphySearch onSelect={handleGiphySelect} onClose={() => setGiphyModalOpen(false)} />
                </Stack>
            </Modal>

            <OpenAIApiKeyPopup
                open={openAIApiKeyModalOpen}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                onClose={() => {
                    setOpenAIApiKeyModalOpen(false);
                    setIsUpdate(false);
                }}
            />
        </div>
    );
};

export default BasicEditorFormWithGif;
