import React, { useEffect, useState } from 'react';
import { CalenderIcon, Divider, InputAdornment, Stack } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { ICampaignScheduleDay } from '../../state/features/emailCampaign/emailCampaign.interface.ts';
import { emailCampaignState } from '../../state/features/emailCampaign/emailCampaignSelector.ts';
import { updateCampaignSchedules } from '../../state/features/emailCampaign/emailCampaignSlice.ts';
import { CustomTextField } from "../bulkEmail/BulkEmailStyle.tsx";
import CustomScheduleComponent from './CustomScheduleComponent';
import { CAMPAIGN_SETTINGS, DATABASE_TIMEZONE } from '../../helpers/constant/coreConstants.ts';
import { showNotification } from '../../helpers/util/functions.tsx';

const GlobalScheduleComponent: React.FC = () => {
    const dispatch = useDispatch();
    const [toggleState, setToggleState] = useState(false);
    const { selectedCampaign, schedules } = useSelector(emailCampaignState);

    useEffect(() => {
        if (schedules && schedules[CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_STATUS]) {
            setToggleState(schedules[CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_STATUS] === "ON");
        }
    }, [schedules]);

    const handleUpdate = (key: string, value: string | ICampaignScheduleDay[], oldValue: string | ICampaignScheduleDay[]) => {
        if (selectedCampaign) {
            if (key === CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_START_DATE || key === CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_END_DATE) {

                if (key === CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_START_DATE && value > getValue(CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_END_DATE)) {
                    showNotification('error', 'Start date/time can\'t be greater date of End date')
                }
                else if (key === CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_END_DATE && value < getValue(CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_START_DATE)) {
                    showNotification('error', 'End date/time can\'t be previous date of Start date')
                } else {
                    const convertedDateTime = (window as any).globalTimezoneConversionToDifferentTimezone(value, getValue(CAMPAIGN_SETTINGS.TIMEZONE), DATABASE_TIMEZONE);
                    dispatch(updateCampaignSchedules({ campaignUid: selectedCampaign.uid, keyName: key, value: convertedDateTime, oldValue: oldValue }));
                }
            }
            else {
                if (key === CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_STATUS) {
                    setToggleState(prev => !prev)
                }
                dispatch(updateCampaignSchedules({ campaignUid: selectedCampaign.uid, keyName: key, value: value, oldValue: oldValue }));
            }
        }

    };
    const getValue = (key: string): string => {

        if (schedules && schedules[key]) {
            if (key === CAMPAIGN_SETTINGS.TIMEZONE) {
                return schedules[CAMPAIGN_SETTINGS.TIMEZONE];
            }
            let campaignTimezone = schedules[CAMPAIGN_SETTINGS.TIMEZONE];
            const convertedDateTime = (window as any).globalTimezoneConversionToDifferentTimezone(schedules[key], DATABASE_TIMEZONE, campaignTimezone, 'yyyy-MM-DDTHH:mm');
            return convertedDateTime;
        }
        return "";
    };


    return (
        <CustomScheduleComponent state={toggleState} onToggle={() => {
            handleUpdate(CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_STATUS, toggleState ? "OFF" : "ON", toggleState ? "ON" : "OFF");
        }} title={'Default Sending Schedule'} subtitle={'Set the default sending schedule for your campaign'} icon={<CalenderIcon />}>
            <Divider light sx={{ marginY: '12px' }} />
            <Stack mt={2} sx={{ marginLeft: '56px' }} spacing={2}>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        fullWidth
                        label={'Start Date'}
                        type={'datetime-local'}
                        value={getValue(CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_START_DATE)}
                        onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                            handleUpdate(CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_START_DATE, _e.target.value, getValue(CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_START_DATE));
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <CalenderIcon fontSize={'small'} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& input::-webkit-calendar-picker-indicator': {
                                width: '82%',
                                position: 'absolute',
                                cursor: 'pointer',
                                opacity: 0,
                                zIndex: 99,
                            },
                        }}
                    />
                    <CustomTextField
                        fullWidth
                        label={'End Date'}
                        type={'datetime-local'}
                        value={getValue(CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_END_DATE)}
                        onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                            handleUpdate(CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_END_DATE, _e.target.value, getValue(CAMPAIGN_SETTINGS.GLOBAL_SCHEDULE_END_DATE));
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <CalenderIcon fontSize={'small'} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& input::-webkit-calendar-picker-indicator': {
                                width: '82%',
                                position: 'absolute',
                                cursor: 'pointer',
                                opacity: 0,
                                zIndex: 99,
                            },
                        }}
                    />
                </Stack>
                {/* <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        fullWidth
                        label={'Between'}
                        type={'time'}
                        value={getValue("global-schedule-start-time")}
                        onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                            handleUpdate("global-schedule-start-time", _e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <ClockIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& input::-webkit-calendar-picker-indicator': {
                                width: '82%',
                                position: 'absolute',
                                cursor: 'pointer',
                                opacity: 0,
                                zIndex: 99,
                            },
                        }}
                    />
                    <CustomTextField
                        label={'And'}
                        type={'time'}
                        fullWidth
                        value={getValue("global-schedule-end-time")}
                        onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                            handleUpdate("global-schedule-end-time", _e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <ClockIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& input::-webkit-calendar-picker-indicator': {
                                width: '82%',
                                position: 'absolute',
                                cursor: 'pointer',
                                opacity: 0,
                                zIndex: 99,
                            },
                        }}
                    />
                </Stack> */}
            </Stack>
        </CustomScheduleComponent>
    );
};

export default GlobalScheduleComponent;
