/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Stack, Typography } from 'convertupleads-theme';

interface IEmptyComponent {
    title?: string;
    description?: string;
}
const EmptyComponent: React.FC<IEmptyComponent> = ({title = 'Looks like the list is empty', description = 'You can start by adding a new campaign'}) => {
    return (
        <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ background: 'white', borderRadius: '8px', height: 'calc(100vh - 224px)' }}
        >
            <img width={401} height={286} src={'https://d24gujj67p1uc1.cloudfront.net/assets/bulk-emails/images/releases/1.0.0/Empty Campaign.png'} alt='image' />
            <Stack>
                <Typography textAlign={'center'} fontFamily={'24px'} fontWeight={600} color={'text.primary'}>
                    {title}
                </Typography>
                <Typography textAlign={'center'} fontFamily={'16px'} fontWeight={500} color={'text.secondary'}>
                    {description}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default EmptyComponent;
