import React from 'react';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    Collapse,
    CustomSwitch,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from 'convertupleads-theme';
import { CustomMenuItem, CustomTextField } from "../../BulkEmailStyle.tsx";

interface CampaignPauseProps {
    icon: React.ReactNode;
    triggerName: string;
    title: string;
    description: string;
    open: boolean;
    handleToggle: () => void;
    selectedValueLabel: string;
    selectedValue: any;
    handleChangeSelectedValue: (event: SelectChangeEvent<unknown>) => void;
    selectedRateLimit: any;
    handleChangeSelectedRateLimit: (event: any) => void;
    needCollapseOptions: boolean;
}

const CampaignPause: React.FC<CampaignPauseProps> = ({ icon, title, description, open, handleToggle, selectedValue, handleChangeSelectedValue, selectedValueLabel, selectedRateLimit, handleChangeSelectedRateLimit, needCollapseOptions }) => {

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: 2.5 }}>
            <Stack direction={'row'} justifyContent={'space-between'} spacing={1} sx={{ cursor: 'pointer' }}>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <IconButton variant={'rounded'} color={'primary'} sx={{ backgroundColor: 'primary.light', m: 2 }}>
                        {icon}
                    </IconButton>
                    <Stack flex={1}>
                        <Typography variant={'body2'} color={'text.primary'}>
                            <b>{title}</b>
                        </Typography>
                        <Typography variant={'caption'} color={'text.secondary'}>
                            {description}
                        </Typography>
                    </Stack>
                </Stack>

                {needCollapseOptions ?
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    color={"primary.main"}
                                    checked={open}
                                    activeText={"On"}
                                    inactiveText={"Off"}
                                    onChange={handleToggle}
                                />
                            }
                            label=''
                        />

                        {open ? (
                            <ChevronUpIcon fontSize='small' color={'text.tertiary'} onClick={handleToggle} />
                        ) : (
                            <ChevronDownIcon fontSize='small' color={'text.tertiary'} onClick={handleToggle} />
                        )}
                    </Stack>
                    :

                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    color={"primary.main"}
                                    checked={open}
                                    activeText={"On"}
                                    inactiveText={"Off"}
                                    onChange={handleToggle}
                                />
                            }
                            label=''
                        />

                        <Stack height={18.5} width={18.5} />
                    </Stack>
                }
            </Stack>
            {needCollapseOptions &&
                <Collapse in={open}>
                    <Divider light sx={{ marginY: '16px' }} />
                    <Stack direction={'row'} alignItems={'center'} mt={1} ml={'58px'} spacing={'20px'}>
                        <Typography variant={'body2'} color={'text.secondary'}>
                            {selectedValueLabel}
                        </Typography>
                        <FormControl sx={{ width: '100px' }}>
                            <Select value={selectedValue || 20} onChange={handleChangeSelectedValue} fullWidth IconComponent={ChevronDownIcon}>
                                <CustomMenuItem value={20}>20%</CustomMenuItem>
                                <CustomMenuItem value={30}>30%</CustomMenuItem>
                                <CustomMenuItem value={40}>40%</CustomMenuItem>
                                <CustomMenuItem value={50}>50%</CustomMenuItem>
                                <CustomMenuItem value={60}>60%</CustomMenuItem>
                            </Select>
                        </FormControl>
                        <Typography variant={'body2'} color={'text.secondary'}>
                            Check limit
                        </Typography>
                        <CustomTextField
                            value={selectedRateLimit}
                            onChange={handleChangeSelectedRateLimit}
                            id='outlined-basic' size={'small'}
                            placeholder='200'
                            sx={{ width: '20%' }}
                        />
                    </Stack>
                </Collapse>
            }
        </Paper>
    );
};

export default CampaignPause;
