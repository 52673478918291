import React, { useRef } from 'react';
import { Button, ChevronDownIcon, PersonalizedIcon, Stack, Typography, useTheme } from 'convertupleads-theme';
import { insertPersonalizedTag } from '../../../../helpers/util/functions';
import PersonalizedPopover from '../../../common/PersonalizedPopover';
import { CustomTextField } from "../../../bulkEmail/BulkEmailStyle.tsx";

interface Props {
    messageSubject: string;
    setMessageSubject: (value: string) => void;
}

const SingleThreadEmailSubject: React.FC<Props> = ({ messageSubject, setMessageSubject }) => {
    const theme = useTheme();
    const subjectRef = useRef(null);
    const [openPersonalizedPopover, setOpenPersonalizedPopover] = React.useState<HTMLButtonElement | null>(null);

    const handleEmailSubjectChange = (text: string) => {
        setMessageSubject(text);
    };

    const handlePersonalizePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenPersonalizedPopover(event.currentTarget);
    };
    const openPopover = Boolean(openPersonalizedPopover);
    const handleClose = () => {
        setOpenPersonalizedPopover(null);
    };

    const errorText = messageSubject === "" && (
        <Typography variant='caption'>
            Email Subject is Required
        </Typography>
    );

    return (
        <Stack mt={1}>
            <Typography color={'text.secondary'} variant={'body2'}>
                Subject
            </Typography>
            <Stack direction={'row'} spacing={2} mt={1}>
                <CustomTextField
                    inputRef={subjectRef}
                    value={messageSubject}
                    size={'small'}
                    placeholder={'Add your email subject line here'}
                    fullWidth
                    onChange={(e) => handleEmailSubjectChange(e.target.value)}
                    error={messageSubject === "" ? true : false}
                    helperText={errorText}
                    sx={{ '& .MuiInputBase-input': { paddingBlock: '9px !important' } }}
                />
                <Button
                    onClick={handlePersonalizePopover}
                    sx={{ background: theme.palette.other.divider, alignSelf: 'baseline', ':focus': { bgcolor: theme.palette.other.divider }, }}
                    variant={'tonal'}
                    color={'inherit'}
                    startIcon={<PersonalizedIcon />}
                    endIcon={<ChevronDownIcon />}
                >
                    Personalize
                </Button>
            </Stack>
            <PersonalizedPopover
                open={openPopover}
                id={'simple-popover'}
                onClose={handleClose}
                anchorEl={openPersonalizedPopover}
                onSelect={(value: string | null) => {
                    if (value) {
                        insertPersonalizedTag(value, subjectRef, (value) => {
                            handleEmailSubjectChange(value);
                        });
                    }
                }}
            />
        </Stack>
    );
};

export default SingleThreadEmailSubject;
