import {useEffect, useState} from "react";
import { Box, Modal, Stack } from 'convertupleads-theme';
import Campaigns from './campaigns/Campaigns';
import CampaignHeader from './CampaignHeader';
import {useSelector} from 'react-redux';
import { emailCampaignState } from '../../state/features/emailCampaign/emailCampaignSelector';
import { SelectedCampaignInfo } from './newEmailCampaign/SelectedCampaignInfo';
import EmailConnectPopup from "../common/EmailConnectPopup";
import {EMAIL_PROVIDER} from "../../helpers/constant/coreConstants.ts";

const CampaignIndex = () => {
    const {
        selectedCampaign,
        connectedEmails,
        isUserConnectedEmailsLoading,
        isUserConnectedEmailsSuccess,
        isUserEmailProviderLoading,
        isUserEmailProviderSuccess,
        userEmailProvider
    } = useSelector(emailCampaignState);
    const [hideEmailConnectPopup, setHideEmailConnectPopup] = useState(true);
    const [isCloseable, setIsCloseable] = useState(true);

    useEffect(() => {
        if ((window as any).setActiveSidebar){
            (window as any).setActiveSidebar("marketing");
        }
    }, []);

    useEffect(() => {
        if (!isUserEmailProviderLoading && isUserEmailProviderSuccess){
            if (userEmailProvider && userEmailProvider.emailProvider === EMAIL_PROVIDER.DEFAULT){
                setHideEmailConnectPopup(false);
            }else if (userEmailProvider && userEmailProvider.emailProvider === EMAIL_PROVIDER.NYLAS_V1 && !isUserConnectedEmailsLoading && isUserConnectedEmailsSuccess && connectedEmails.userConnectedEmails.length === 0){
                setHideEmailConnectPopup(false);
                setIsCloseable(false);
            }
        }
    }, [
        connectedEmails.userConnectedEmails,
        isUserConnectedEmailsLoading,
        isUserConnectedEmailsSuccess,
        isUserEmailProviderLoading,
        isUserEmailProviderSuccess,
        userEmailProvider
    ]);

    const getNylasEmail = () => {
        try{
            if (!isUserEmailProviderLoading && isUserEmailProviderSuccess && userEmailProvider){
                let nylasEmailProviderInfo = userEmailProvider.nylasEmailProviderInfo;

                return nylasEmailProviderInfo?.email || "";
            }
        } catch (err) {
            console.log(err);
        }
        return "";
    };

    return (
        <Box>
            <Stack spacing={2}>
                <CampaignHeader />
                <Campaigns />
                {selectedCampaign !== null &&
                    <Modal disableEnforceFocus width={'98%'} 
                    sx={{ height: '96%', zIndex: '1200', marginTop: '20px', 
                        
                    '.MuiStack-root' : {
                            ':focus-visible': {
                            outline: 'unset !important'
                            }
                        } 
                    }} open={true}>
                        <SelectedCampaignInfo />
                    </Modal>
                }
            </Stack>
            <EmailConnectPopup
                open={!hideEmailConnectPopup}
                email={getNylasEmail()}
                isCloseable={isCloseable}
                onClose={()=>{ setHideEmailConnectPopup(true); }}
                state={"bulk-email"}
            />
        </Box>
    );
};

export default CampaignIndex;
