import React, { useState } from 'react';

import { Avatar, Checkbox, IconButton, PauseCircleIcon, PlayCircleOutlineFilledIcon, Stack, Tooltip, Typography, UserRemoveIcon, styled, useTheme } from 'convertupleads-theme';
import { useSelector } from 'react-redux';
import emailCampaignService from '../../../../services/emailCampaign.service';
import { IContact } from '../../../../state/features/emailCampaign/emailCampaign.interface';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { CONTACT_STATUS } from '../../../../helpers/constant/coreConstants';

interface StyledCheckboxWrapperProps {
    checked: boolean;
}

const StyledCheckboxWrapper = styled(Stack)<StyledCheckboxWrapperProps>(({ theme, checked }) => ({
    padding: 8,
    cursor: 'pointer',
    '&:hover': {
        // background: theme.palette.other.outlinedBorder,
        color: theme.palette.text.secondary,
        padding: 8,
        borderRadius: '4px',
    },
    ...(checked && {
        background: theme.palette.other.outlinedBorder,
        color: theme.palette.text.secondary,
    }),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    background: theme.palette.secondary.light,
    color: theme.palette.primary.main,
    height: '42px',
    width: '42px',
    fontSize: '16px'
}))

const renderStatusName = (status: string) => {
    if (status === CONTACT_STATUS.RUNNING) {
        return 'Running';
    } else if (status === CONTACT_STATUS.PAUSE) {
        return 'Paused';
    } else if (status === CONTACT_STATUS.UNSUBSCRIBED) {
        return 'Unsubscribed';
    }
}

interface Props {
    isChecked: boolean;
    contact: IContact;
    onCheckboxChange: (id: number) => void;
    onSubmitId: (status: string, submittedId: number) => void;
    submittedIds: {
        submittedResumeIds: number[];
        submittedPauseIds: number[];
        submittedUnsubscribeIds: number[];
    };
}
const CampaignContactElasticItem: React.FC<Props> = ({ isChecked, contact, onCheckboxChange, onSubmitId, submittedIds }) => {
    const [checked, setChecked] = useState(isChecked);
    const [isUnsubscribing, setIsUnsubscribing] = useState(false);
    const theme = useTheme()
    const { selectedCampaign } = useSelector(emailCampaignState);

    const { submittedResumeIds, submittedPauseIds, submittedUnsubscribeIds } = submittedIds || {};

    const isContactResumed = contact.status !== CONTACT_STATUS.RUNNING && submittedResumeIds.includes(contact.id);
    const isContactPaused = contact.status !== CONTACT_STATUS.PAUSE && submittedPauseIds.includes(contact.id);
    const isContactUnsubscribed = contact.status !== CONTACT_STATUS.UNSUBSCRIBED && submittedUnsubscribeIds.includes(contact.id);

    const contactNotSubmitted = !isContactResumed && !isContactPaused && !isContactUnsubscribed;
    console.log('in elatic ');

    const handleCheckboxChange = () => {
        const newChecked = !checked;
        setChecked(newChecked);
        onCheckboxChange(contact.id);
    };

    if (isChecked !== checked) {
        setChecked(isChecked);
    }

    const generateName = (contact: IContact) => {
        let name = '';

        if (contact.firstName) {
            name += contact.firstName;
        }

        if (contact.lastName) {
            if (name) {
                name += " ";
            }
            name += contact.lastName;
        }

        return name;
    };

    const getInitial = (name: string) => {

        let fullNameInitial: string = ''
        try {
            if (typeof name != 'undefined') {
                if (name === 'null null' || name === '' || name === ' ') {
                } else if (name !== 'undefined' || name !== null) {
                    fullNameInitial = name.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");
                }
            }
        } catch (error) {
        }
        return fullNameInitial;

    }

    const contactInformation = () => {

        let name = generateName(contact);
        let initial = getInitial(name)

        return (
            <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                <StyledAvatar size='large'>{initial}</StyledAvatar>
                <Stack>
                    <Typography variant={'body2'} color={'text.primary'}>
                        {name}{" "}
                        {contact?.status && (
                            <Typography
                                component={'span'}
                                variant={"caption"}
                                color={"text.secondary"}
                            >
                                ({renderStatusName(contact?.status)})
                            </Typography>
                        )}
                    </Typography>
                    <Typography variant={'caption'} color={'text.secondary"}'}>
                        {contact.email}
                    </Typography>


                </Stack>
            </Stack>
        )
    }

    const handleContactStatus = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, status: string) => {
        event.stopPropagation();

        const statusPayload = status === CONTACT_STATUS.PAUSE ? CONTACT_STATUS.PAUSE : CONTACT_STATUS.RUNNING;
        try {
            let resp = await emailCampaignService.pauseOrResumeContact({ contactIds: [contact.id], campaignUid: selectedCampaign?.uid as string, status: statusPayload as 'RUNNING' | 'PAUSE' });

            if (resp && resp.success) {
                // setSelectedContacts([]);
                onSubmitId(status, contact.id);
                if ((window as any).showNotification) {
                    (window as any).showNotification('success', resp.message);
                }
            } else {
                if ((window as any).showNotification) {
                    (window as any).showNotification('error', resp.message);
                }
            }
        } catch (err) {
            if ((window as any).showNotification) {
                (window as any).showNotification('error', 'Something went wrong! Try again later');
            }
        } finally {
            // setIsUnsubscribing(false);
        }
    }

    const handleUnsubscribe = async (event: any) => {
        event.stopPropagation();

        console.log({ contactIds: [contact.id], campaignUid: selectedCampaign?.uid });
        try {
            setIsUnsubscribing(true);
            let resp = await emailCampaignService.unsubscribeContact({ contactIds: [contact.id], campaignUid: selectedCampaign?.uid });

            if (resp && resp.success) {
                // setSelectedContacts([]);
                onSubmitId(CONTACT_STATUS.UNSUBSCRIBED, contact.id);
                if ((window as any).showNotification) {
                    (window as any).showNotification('success', resp.message);
                }
            } else {
                if ((window as any).showNotification) {
                    (window as any).showNotification('error', resp.message);
                }
            }
        } catch (err) {
            if ((window as any).showNotification) {
                (window as any).showNotification('error', 'Something went wrong! Try again later');
            }
        } finally {
            setIsUnsubscribing(false);
        }
    }

    const renderContactStatusAction = () => {
        if (contact.status === CONTACT_STATUS.PAUSE) {
            return (
                <Tooltip title="Resume">
                    <IconButton
                        size="small"
                        onClick={(event) => handleContactStatus(event, CONTACT_STATUS.RUNNING)}
                        sx={{ ":focus": { bgcolor: "transparent" } }}
                    >
                        <PlayCircleOutlineFilledIcon color="primary" />
                    </IconButton>
                </Tooltip>
            )
        }
        if (contact.status === CONTACT_STATUS.RUNNING) {
            return (
                <Tooltip title="Pause">
                    <IconButton
                        size="small"
                        onClick={(event) => handleContactStatus(event, CONTACT_STATUS.PAUSE)}
                        sx={{ ":focus": { bgcolor: "transparent" } }}
                    >
                        <PauseCircleIcon color="primary" />
                    </IconButton>
                </Tooltip>
            )
        }
    }

    const submittedContactText = () => {
        if (isContactResumed) {
            return (
                <Typography variant={'subtitle2'} color={'primary.main'} mr={'8px !important'}>
                    Resume request submitted
                </Typography>
            );
        } else if (isContactPaused) {
            return (
                <Typography variant={'subtitle2'} color={'primary.main'} mr={'8px !important'}>
                    Pause request submitted
                </Typography>
            );
        } else if (isContactUnsubscribed) {
            return (
                <Typography variant={'subtitle2'} color={'error.main'} mr={'8px !important'}>
                    Unsubscribe request submitted
                </Typography>
            );
        } else {
            return null;
        }
    };

    return (
        <StyledCheckboxWrapper
            checked={checked}
            onClick={contact?.status !== CONTACT_STATUS.UNSUBSCRIBED ? handleCheckboxChange : () => { }}
            sx={{
                bgcolor:
                    contact?.status === CONTACT_STATUS.RUNNING
                        ? theme.palette.success.light
                        : contact?.status === CONTACT_STATUS.PAUSE
                            ? theme.palette.warning.light
                            : contact?.status === CONTACT_STATUS.UNSUBSCRIBED
                                ? theme.palette.error.light
                                : 'transparent'
            }}
        >
            <Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    {contact?.status !== CONTACT_STATUS.UNSUBSCRIBED && contactNotSubmitted ? (
                        <Checkbox
                            color={"primary"}
                            checked={checked}
                            onChange={handleCheckboxChange}
                            size={"small"}
                            sx={{
                                "&.MuiCheckbox-root": {
                                    marginRight: 0,
                                },
                            }}
                        />
                    ) : (
                        <Stack height={34} width={34}></Stack>
                    )}

                    {contactInformation()}
                </Stack>

                <Stack direction={'row'} alignItems={'center'} spacing={1}>

                    {contactNotSubmitted && renderContactStatusAction()}

                    {submittedContactText()}

                    {
                        contact?.status !== CONTACT_STATUS.UNSUBSCRIBED && contactNotSubmitted &&
                        (
                            <Tooltip title="Unsubscribe">
                                <IconButton
                                    size="small"
                                    onClick={handleUnsubscribe}
                                    sx={{ ":focus": { bgcolor: "transparent" } }}
                                    disabled={isUnsubscribing}
                                >
                                    <UserRemoveIcon color="error" />
                                </IconButton>
                            </Tooltip>
                        )
                    }

                </Stack>
            </Stack>
        </StyledCheckboxWrapper>
    );
};

export default CampaignContactElasticItem;
