import React, {useState} from "react";
import {Button, CheckCircleIcon, InfoCircleIcon, Stack, Tab, Tabs, Typography,} from "convertupleads-theme";
import {MapFieldTable, TabLabel} from "./MappingStyles.tsx";
import {CustomScrollbar} from "../../../../../../common/CustomScrollBarStyle.tsx";
import {useImportSpreadsheetContext} from "../../importSpreadsheetReducer.tsx";
import SelectWithSearchBox from "./SelectWithSearchBox.tsx";
import {showNotification} from "../../../../../../../helpers/util/functions.tsx";

export type IMapping = {
    index: number;
    header: string;
    fields: Array<string>;
};

export type IMapData = {
    type: string;
    map: string;
    data: string | null;
};

interface IProps {

}

const Mapping: React.FC<IProps> = () => {
    const {
        handleGoNextStep,
        handleGoPreviousStep,
        mappedRows, unMappedRows,
        handleMapColumn,
        findSelectedValue,
        mappingColumns,
        valuesForSubmit
    } = useImportSpreadsheetContext();
    const [activeMappingStep, setActiveMappingStep] = useState<number>(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setActiveMappingStep(newValue);
    };

    return (
        <Stack spacing={2}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant={"h5"} color={"text.primary"}>
                    Mapping
                </Typography>
                <Stack direction={'row'} spacing={1.5}>
                    <Button variant={'tonal'} onClick={handleGoPreviousStep}>
                        Previous
                    </Button>
                    <Button onClick={()=>{
                        const hasEmail = valuesForSubmit.some((item: IMapData) => (item.data === "email"));
                        if (!hasEmail){
                            showNotification("error", "Must select one email column");
                            return;
                        }

                        handleGoNextStep();
                    }}>
                        Next
                    </Button>
                </Stack>
            </Stack>
            <Tabs sx={{ marginTop: "10px !important" }} value={activeMappingStep} onChange={handleChange}>
                <Tab sx={{ "&:focus": { background: "transparent" }}} label={<TabLabel title={"All"} value={mappedRows?.length + unMappedRows?.length} isSelected={activeMappingStep === 0} />} />
                <Tab sx={{ "&:focus": { background: "transparent" }}} label={<TabLabel title={"Mapped"} value={mappedRows?.length} isSelected={activeMappingStep === 1} />} />
                <Tab sx={{ "&:focus": { background: "transparent" }}} label={<TabLabel title={"Unmapped"} value={unMappedRows?.length} isSelected={activeMappingStep === 2} />} />
            </Tabs>

            <CustomScrollbar style={{ maxHeight: 460, overflow: "auto" }}>
                <MapFieldTable>
                    <thead>
                        <tr>
                            <th>
                                <Typography variant={"overline"} color={"text.tertiary"}>
                                    mapped
                                </Typography>
                            </th>
                            <th>
                                <Typography variant={"overline"} color={"text.tertiary"}>
                                    column data
                                </Typography>
                            </th>
                            <th width={"280px"}>
                                <Typography variant={"overline"} color={"text.tertiary"}>
                                    list field
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(activeMappingStep == 0 || activeMappingStep == 1) &&
                            mappedRows?.map((item, index) => (
                                <tr key={index} style={{ padding: "10px 10px" }}>
                                    <td style={{ padding: "20px 0px" }}>
                                        <CheckCircleIcon color={"success"} />
                                    </td>
                                    <td style={{ padding: "20px 0px" }}>
                                        <Stack>
                                            {
                                                item?.fields.map((field: string)=>(
                                                    <Typography variant={"body2"} fontWeight={"bold"}>
                                                        {field}
                                                    </Typography>
                                                ))
                                            }
                                        </Stack>
                                    </td>
                                    <td style={{ padding: "20px 0px" }}>
                                        <SelectWithSearchBox
                                            options={mappingColumns}
                                            value={findSelectedValue(item?.index)}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                                                handleMapColumn(event.target.value, item?.index);
                                            }}
                                            emptyLabel={'Don\'t import'}
                                        />
                                    </td>
                                </tr>
                            ))}

                        {(activeMappingStep == 0 || activeMappingStep == 2) &&
                            unMappedRows?.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ padding: "20px 0px" }}>
                                        <InfoCircleIcon color={"warning"} />
                                    </td>
                                    <td style={{ padding: "20px 0px" }}>
                                        <Stack>
                                            {
                                                item?.fields.map((field: string)=>(
                                                    <Typography variant={"body2"} fontWeight={"bold"}>
                                                        {field}
                                                    </Typography>
                                                ))
                                            }
                                        </Stack>
                                    </td>
                                    <td style={{ padding: "20px 0px" }}>
                                        <SelectWithSearchBox
                                            options={mappingColumns}
                                            value={findSelectedValue(item?.index)}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                                                handleMapColumn(event.target.value, item?.index);
                                            }}
                                            emptyLabel={'Don\'t import'}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </MapFieldTable>
            </CustomScrollbar>
        </Stack>
    );
};

export default Mapping;