import React from 'react';
import { Stack } from 'convertupleads-theme';
import EmptyComponent from '../emptyComponent/EmptyComponent';
import CampaignContent from './campaignContent/CampaignContent';
import { useSelector } from 'react-redux';
import { emailCampaignState } from '../../../state/features/emailCampaign/emailCampaignSelector';
import CampaignsBodySkeleton from './CampaignsBodySkeleton';
import CampaignFilter from './campaignContent/CampaignFilter';

const CampaignsBody: React.FC = () => {
    const { campaignList, isLoading, isUserConnectedEmailsLoading } = useSelector(emailCampaignState);

    const loadCampaigns = () => {

        if (isLoading || isUserConnectedEmailsLoading) return <CampaignsBodySkeleton />;
        else
            if (campaignList?.length === 0 && !isLoading) return <EmptyComponent />;
            else if (campaignList?.length > 0 && !isLoading) return <CampaignContent />;
    }

    return (
        <Stack flex={1} spacing={2}>
            {/* <CampaignTopBar /> */}
            <Stack
                px={2}
                pt={2}
                sx={{ mt: "0px !important", bgcolor: "common.white", borderRadius: "8px" }}
            >
                <CampaignFilter />
                <Stack>{loadCampaigns()}</Stack>
            </Stack>
        </Stack>
    );
};

export default CampaignsBody;
