import React, { Dispatch, SetStateAction } from 'react';
import { IconButton, useTheme } from 'convertupleads-theme';
import { renderFolderIcon } from '../../helpers/util/functions';

const folderIconList = [
    'FolderFilledIcon',
    'WebsiteIcon',
    'MonitorIcon',
    'MailIcon',
    'FilterIcon',
    'DollarIcon',
    'StarIcon',
    'LockIcon',
    'RadioIcon',
    'SizeIcon',
    'BrushOutlinedIcon',
];

interface FolderIconListProps {
    selectedIcon: string;
    onSetIconName: Dispatch<SetStateAction<string>>;
}
const FolderIconList: React.FC<FolderIconListProps> = ({ selectedIcon, onSetIconName }) => {
    const theme = useTheme();

    // Function to render the icon based on the icon name

    return (
        <>
            {folderIconList.map((icon, i) => (
                <IconButton
                    key={i}
                    sx={{
                        background: icon === selectedIcon ? theme.palette.common.white : 'transparent',
                        '&:hover, :focus': {
                            background: theme.palette.common.white,
                        },
                    }}
                    variant={'rounded'}
                    onClick={() => onSetIconName(icon)}
                >
                    {renderFolderIcon(icon)}
                </IconButton>
            ))}
        </>
    );
};

export default FolderIconList;
