import React from 'react';
import { AddIcon, Divider, IconButton, Stack, useTheme } from 'convertupleads-theme';

interface Props {
    onClick: () => void;
}

const DividerWithAddIcon: React.FC<Props> = ({ onClick }) => {
    const theme = useTheme();
    return (
        <Stack justifyContent={'center'} padding={1}>
            <Divider
                light
                orientation='vertical'
                sx={{
                    height: '70px',
                    '& .MuiDivider-wrapper ': {
                        padding: '0',
                    },
                }}
                textAlign='center'
            >
                <IconButton
                    variant={'roundedBorder'}
                    sx={{ padding: '4px', background: 'white', border: `1px solid ${theme.palette.action.focus}`, '&:focus': {background: 'transparent'} }}
                    color={'primary'}
                    onClick={onClick}
                >
                    <AddIcon />
                </IconButton>
            </Divider>
        </Stack>
    );
};

export default DividerWithAddIcon;
