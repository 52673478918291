import React, {Fragment, useEffect, useState} from "react";
import {
    AddIcon, ArrowLeftIcon,
    Button,
    ListIcon,
    Paper,
    Stack,
    Typography,
    useTheme
} from "convertupleads-theme";
import ConnectedFileList from "./ConnectedFileList.tsx";
import Connect from "./connect/Connect.tsx";
import {useImportSpreadsheetContext, withImportSpreadsheetContext} from "./importSpreadsheetReducer.tsx";
import {
    CONNECTED_FILE_STEPS,
    SPREADSHEET_IMPORT_STEPS,
    STEPS,
    STEPS_SUB
} from "../../../../../helpers/constant/coreConstants.ts";
import {useSearchParams} from "react-router-dom";
import Sync from "./sync/Sync.tsx";

interface IProps {
    goSubStep: (step: number, subStep: number | null) => void;
}

const SpreadSheet: React.FC<IProps> = ({ goSubStep }) => {
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const { screen, changeScreen, goToStep, setEmail, resetImportData } = useImportSpreadsheetContext();
    const [isRefreshed,setIsRefreshed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (searchParams.has('spreadsheet') && !searchParams.has('state')){
            changeScreen(CONNECTED_FILE_STEPS.CONNECT_FILE);
            goToStep(SPREADSHEET_IMPORT_STEPS.CONFIGURE);

            try {
                let email = searchParams.get('spreadsheet');
                setEmail(email);
            }catch (err){

            }
        }
    }, []);

    return(
        <Stack
            alignItems='center'
            sx={{
                background: theme.palette.common.white,
                height: 'calc(100vh - 200px)',
                overflowY: 'hidden',
            }}
        >
            <Stack width={'100%'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} py={1} px={2}>
                <Stack mb={2} mt={1}>
                    <Typography variant={'h5'} color={'text.primary'}>
                        Import From Google Sheets
                    </Typography>
                    <Typography variant={'caption'}>
                        Add contact using google sheets
                    </Typography>
                </Stack>
                {
                    !isLoading &&
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Button variant={'outlined'} startIcon={<ArrowLeftIcon/>} onClick={() => { goSubStep(STEPS.STEP_LEADS, null) }}>Back to Leads</Button>
                        {
                            (()=>{
                                switch (screen){
                                    case CONNECTED_FILE_STEPS.CONNECTED_FILE_LIST:
                                        return <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                                            <Button  variant="contained" sx={{cursor:'pointer',"&:focus": {bgcolor:'primary.main'},}} onClick={()=>setIsRefreshed(true)}
                                                     disabled={isRefreshed}>Refresh</Button>
                                            <Button variant={'outlined'} startIcon={<AddIcon/>} onClick={() => { changeScreen(CONNECTED_FILE_STEPS.CONNECT_FILE); goToStep(SPREADSHEET_IMPORT_STEPS.CONNECT); resetImportData(); }}>Import New Spreadsheet</Button>
                                        </Stack>;
                                    case CONNECTED_FILE_STEPS.CONNECT_FILE:
                                        return <Button variant={'outlined'} startIcon={<ListIcon/>} onClick={() => { changeScreen(CONNECTED_FILE_STEPS.CONNECTED_FILE_LIST) }}>View Past Imports</Button>;
                                    case CONNECTED_FILE_STEPS.SYNC_FILE:
                                        return <Button variant={'outlined'} startIcon={<ListIcon/>} onClick={() => { changeScreen(CONNECTED_FILE_STEPS.CONNECTED_FILE_LIST) }}>Back to Past Imports</Button>;
                                }
                            })()
                        }
                    </Stack>
                }
            </Stack>

            <Stack px={1}>
                <Paper elevation={0} variant='outlined' sx={{ borderRadius: '8px' }}>
                    {
                        (()=>{
                            switch (screen){
                                case CONNECTED_FILE_STEPS.CONNECTED_FILE_LIST:
                                    return <ConnectedFileList
                                        isRefreshed={isRefreshed}
                                        setIsRefreshed={setIsRefreshed}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                    />;
                                case CONNECTED_FILE_STEPS.CONNECT_FILE:
                                    return <Connect/>;
                                case CONNECTED_FILE_STEPS.SYNC_FILE:
                                    return <Sync/>;
                            }
                        })()
                    }
                </Paper>
            </Stack>
          </Stack>
        </Stack>
    );
}

export default withImportSpreadsheetContext(SpreadSheet);