import React, {useState} from "react";
import {AddIcon, ArrowLeftIcon, Button, ListIcon, Paper, Stack, Typography, useTheme} from "convertupleads-theme";
import {
    CSV_FILE_STEPS,
    SPREADSHEET_IMPORT_STEPS,
    STEPS
} from "../../../../../helpers/constant/coreConstants.ts";
import {useImportCsvContext, withImportCsvContext} from "./importCsvReducer.tsx";
import Connect from "./connect";
import ConnectedFileList from "./ConnectedFileList.tsx";

interface IProps {
    goSubStep: (step: number, subStep: number | null) => void;
}

const Csv: React.FC<IProps> = ({ goSubStep }) => {
    const theme = useTheme();
    const { screen, changeScreen, goToStep, resetImportData } = useImportCsvContext();
    const [isRefreshed,setIsRefreshed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDataFound, setIsDataFound] = useState(false);

    return(
        <Stack
            alignItems='center'
            sx={{
                background: theme.palette.common.white,
                height: 'calc(100vh - 200px)',
                overflowY: 'hidden',
            }}
        >
            <Stack width={'100%'}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} py={1} px={2}>
                    <Stack mb={2} mt={1}>
                        <Typography variant={'h5'} color={'text.primary'}>
                        Import From CSV File
                        </Typography>
                        <Typography variant={'caption'}>
                        Add your contacts by uploading a csv file
                        </Typography>
                    </Stack>

                    {
                        (!isLoading || isDataFound) && (
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Button variant={'outlined'} startIcon={<ArrowLeftIcon/>} onClick={() => { goSubStep(STEPS.STEP_LEADS, null) }}>Back to Leads</Button>
                                {
                                    (()=>{
                                        switch (screen){
                                            case CSV_FILE_STEPS.CSV_FILE_LIST:
                                                return <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                                                    <Button  variant="contained" sx={{cursor:'pointer',"&:focus": {bgcolor:'primary.main'},}} onClick={()=>setIsRefreshed(true)}
                                                        disabled={isRefreshed}>Refresh</Button>
                                                    
                                                    <Button variant={'outlined'} startIcon={<AddIcon/>} onClick={() => { changeScreen(CSV_FILE_STEPS.CONNECT_FILE); goToStep(SPREADSHEET_IMPORT_STEPS.CONNECT); resetImportData(); }}>Import CSV File</Button>
                                                </Stack>;
                                            case CSV_FILE_STEPS.CONNECT_FILE:
                                                return <Button variant={'outlined'} startIcon={<ListIcon/>} onClick={() => { changeScreen(CSV_FILE_STEPS.CSV_FILE_LIST) }}>View Past Imports</Button>;
                                        }
                                    })()
                                }
                            </Stack>
                        )
                    }
                    
                </Stack>

                <Stack px={1}>
                    <Paper elevation={0} variant='outlined' sx={{ borderRadius: '8px' }}>
                        {
                            (()=>{
                                switch (screen){
                                    case CSV_FILE_STEPS.CONNECT_FILE:
                                        return <Connect/>;
                                    case CSV_FILE_STEPS.CSV_FILE_LIST:
                                        return <ConnectedFileList
                                            isRefreshed={isRefreshed}
                                            setIsRefreshed={setIsRefreshed}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            setIsDataFound={setIsDataFound}
                                        />;
                                }
                            })()
                        }
                    </Paper>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default withImportCsvContext(Csv);