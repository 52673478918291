import {ICSVFile, IMapping, IMapData} from "../../../../../../../state/features/emailCampaign/emailCampaign.interface.ts";

const mapData = (data: ICSVFile<string | number>[][]) => {
    const rowsArr: IMapping[] = [] as IMapping[];
    const maxRow = 5;
    data.slice(0, 5)?.forEach((rowData, rowIndex) => {
        if (rowIndex >= maxRow) {
            return;
        }
        if (rowIndex == 0) {
            rowData?.forEach((eachData: any, dataIndex) => {
                rowsArr.push({
                    index: dataIndex,
                    fields: [eachData],
                });
            });
        } else {
            rowData.forEach((eachData: any, dataIndex) => {
                if (rowsArr[dataIndex] !== undefined && rowsArr[dataIndex] != null) {
                    rowsArr[dataIndex].fields.push(eachData);
                }
            });
        }
    });
    const mappedData = getMappedData(rowsArr);
    return {
        mappedRows: mappedData.mappedRows,
        actionNeededRows: mappedData.actionNeededRows,
        submitData: mappedData.submitData,
    };
};

const getMappedData = (allRows: IMapping[]) => {
    const mappedRows: IMapping[] = [];
    const actionNeededRows: IMapping[] = [];
    const submitData: IMapData[] = [] as IMapData[];
    const selectedRows: { [key: number]: string } = {};

    allRows.forEach((row: IMapping) => {
        let has = false;
        for (const data of row.fields) {
            if (isPhoneNumber(data)) {
                mappedRows.push(row);
                selectedRows[row.index] = 'number';
                has = true;
                break;
            } else if (isEmail(data)) {
                mappedRows.push(row);
                selectedRows[row.index] = 'email';
                has = true;
                break;
            } else if (isZip(data)) {
                mappedRows.push(row);
                selectedRows[row.index] = 'zip';
                has = true;
                break;
            }
        }
        if (!has) {
            actionNeededRows.push(row);
            submitData[row.index] = {
                type: 'contact',
                map: row.index,
                data: null,
            };
        } else {
            submitData[row.index] = {
                type: 'contact',
                map: row.index,
                data: selectedRows[row.index],
            };
        }
    });

    return {
        mappedRows: mappedRows,
        actionNeededRows: actionNeededRows,
        selectedRows: selectedRows,
        submitData: submitData,
    };
};

const isPhoneNumber = (phone: string) => {
    return phone.match(/^\+?[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/);
};

const isEmail = (email: string) => {
    const re = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
};

const isZip = (zip: string) => {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
};

const mapCSVImportHelper = {
    mapData,
};

export default mapCSVImportHelper;