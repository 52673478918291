import React, { Fragment, useEffect, useState } from "react";
import {
    Button,
    CircularProgress,
    OpenInNewFilledIcon,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "convertupleads-theme";
import emailCampaignService from "../../../../../services/emailCampaign.service";
import { emailCampaignState } from "../../../../../state/features/emailCampaign/emailCampaignSelector";
import { useSelector } from "react-redux";
import StatusChip from "./StatusChip.tsx";
import {useImportCsvContext} from "./importCsvReducer.tsx";
import {CSV_FILE_STEPS} from "../../../../../helpers/constant/coreConstants.ts";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "10px 16px",
}));

interface DataItem {
  email: string;
  providerFileName: string;
  status: string;
  processingStatus: string;
  lastProcessedDate: string;
  worksheetInfo: {
      sheetId: number;
      title: string;
  };
  providerFileId: string;

}

interface RenderEachTableItemProps {
  selectedContact: string[];
  onSelectContact: (uid: string) => void;
  item: DataItem;
}

const toHuman = (str: number) => {
    if (str === 0){
      return 'Pending';
    }
    else if (str === 1){
        return 'Queued';
    }else if (str === 2){
        return 'Processing';
    }else if (str === 3){
        return 'Processed';
    }else if (str === 4){
        return 'Failed';
    }else if (str === 5){
        return 'Deleting';
    }
    else {
        return 'N/A';
    }
};

const parseSummary = (summary: string | undefined, field: string) => {
  try{
      if (summary){
          let parsedSummary = JSON.parse(summary);
          return parsedSummary[field] || 0;
      }

      return 0;
  }catch (err){
      return 0;
  }
};

const RenderEachTableItem: React.FC<RenderEachTableItemProps> = ({ item }) => {
    const handleUrl = (url: string) => {
        window.open(url);
    }

  return (
    <TableRow>
      <StyledTableCell >
         <Stack direction={'row'} gap={'8px'}>
         <Typography>{item?.nameOriginal || " "}</Typography>
         <OpenInNewFilledIcon onClick={()=>handleUrl(item?.aws3Info?.fileUrl)} cursor={'pointer'} fontSize="12px"/>
         </Stack>
      </StyledTableCell>
      <StyledTableCell align="center">
          <Typography variant="p">{item?.totalContactUploaded || 0} / {parseSummary(item?.summary, "totalContact")}</Typography>
      </StyledTableCell>
        <StyledTableCell align="center">
            {parseSummary(item?.summary, "emailDuplicateCount")}
        </StyledTableCell>
        <StyledTableCell align="center">
            {parseSummary(item?.summary, "numberDuplicateCount")}
        </StyledTableCell>
        <StyledTableCell align="center">
            {parseSummary(item?.summary, "invalidCount")}
        </StyledTableCell>
        <StyledTableCell align="center">
            <StatusChip status={toHuman(item?.status)}/>
      </StyledTableCell>
    </TableRow>
  );
};

interface IProps {
    isRefreshed: boolean;
    setIsRefreshed: (state: boolean) => void;
    isLoading: boolean;
    setIsLoading: (state: boolean) => void;
    setIsDataFound: (state: boolean) => void;
}

const ConnectedFileList: React.FC<IProps> = ({ isRefreshed,setIsRefreshed, isLoading, setIsLoading, setIsDataFound }) => {
    const { changeScreen } = useImportCsvContext();
    const { selectedCampaign } = useSelector(emailCampaignState);
    const [fileList, setFileList] = useState([]);

    const fetchFileListData = (async () => {
        try {
            setIsLoading(true);
            const res = await emailCampaignService.getCampaignCsvFiles({
                campaignUid: selectedCampaign?.uid
            });

            if (res && res.success) {
                setFileList(res.data);
                setIsDataFound(true);
                if (res.data.length === 0){
                    changeScreen(CSV_FILE_STEPS.CONNECT_FILE);
                    setIsDataFound(false);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    });
    useEffect(() => {
       fetchFileListData().then();
    }, []);

    useEffect(() => {
        if (isRefreshed) {
             fetchFileListData().then();
             setIsRefreshed(false);
            }
    }, [isRefreshed]);


    if (isLoading){
        return (
            <Stack alignItems={"center"} justifyContent={"center"} height={655}>
                <CircularProgress />
            </Stack>
        );
    }

  return (
    <Fragment>
      <TableContainer>
        <Table variant={"bordered"} size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Stack direction="row" alignItems="center">
                   File Name
                </Stack>
                </TableCell>
              <TableCell align="center" width="15%">
                  Imported / Total
              </TableCell>
              <TableCell align="center">
              DUPLICATE EMAILS
              </TableCell>
              <TableCell align="center">
              DUPLICATE NUMBERS
              </TableCell>
              <TableCell align="center">
              INVALID CONTACTS
              </TableCell>
              <TableCell align="center" width="10%">
                  Status
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {fileList.map((item, index) => (
              <RenderEachTableItem
                key={`${index}--table-item`}
                item ={item}
                selectedContact={[]}
                onSelectContact={() => {}}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default ConnectedFileList;
