import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Divider, LoadingButton, ModalWithHeader, Stack } from 'convertupleads-theme';
import { useSelector } from 'react-redux';
import { showNotification } from '../../../helpers/util/functions';
import emailCampaignService from '../../../services/emailCampaign.service';
import { emailCampaignState } from '../../../state/features/emailCampaign/emailCampaignSelector';
import { CustomTextField } from '../../bulkEmail/BulkEmailStyle';

interface ISendEmailModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const SendEmailModal: React.FC<ISendEmailModalProps> = ({ open, setOpen }) => {
    const [emailAddress, setEmailAddress] = useState('')
    const [emailAddressError, setEmailAddressError] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    
    const { selectedSequence } = useSelector(emailCampaignState);


  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    const sendTestEmail = async () => {
        if (selectedSequence && selectedSequence.uid) {
            setIsLoading(true);
      
            try {
              let response = await emailCampaignService.testEmailCampaignSequence({
                campaignSequenceUid: selectedSequence.uid,
                toEmail: emailAddress
              });
      
              await delay(1000); 
              if (response.success) {
                setIsLoading(false);
                setOpen(false);
                showNotification(
                  "success",
                  response.message ?? "Send test email Successful"
                );
              } else {
                showNotification("error", response.message);
              }
            } catch (error: unknown) {
                if (error instanceof Error) {
                    showNotification("error", error.message);
                } 
                else {
                    showNotification("error", "An unknown error occurred.");
                }
            } finally {
              setIsLoading(false);
            }
          }
    };
    const handleSubmit = () => {
        const trimmedEmailAddress = emailAddress.trim();
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (!trimmedEmailAddress || !regex.test(trimmedEmailAddress)) {
            setEmailAddressError(
            !trimmedEmailAddress
              ? "Email address is required"
              : "Email address is invalid"
          );
          return;
        }

        sendTestEmail();
    };
    return (
        <ModalWithHeader open={open} onClose={() => setOpen(false)} title='Send a test email'>
            <>
                <Stack padding={3}>
                    <CustomTextField
                        label="Type Recipient"
                        size="small"
                        value={emailAddress}
                        fullWidth
                        onChange={(e) => {
                            setEmailAddress(e.target.value);
                            setEmailAddressError('');
                        }}
                        error={!!emailAddressError}
                        helperText={emailAddressError}
                    />
                </Stack>
                <Divider light />
                <Stack direction={'row'} justifyContent={'end'} spacing={1} paddingX={3} paddingY={2}>
                    <Button
                        onClick={() => setOpen(false)}
                        variant={'tonal'} 
                        color={'inherit'} 
                        size='large'
                    >
                        Cancel
                    </Button>

                    <LoadingButton 
                        loading={isLoading} 
                        variant={'contained'} 
                        size='large' 
                        position="start"
                        sx={{'& .MuiButton-startIcon': {height: 20, width: 20}, '&:focus': {bgcolor: 'primary.main'}}}
                        onClick={handleSubmit}
                    >
                        Send
                    </LoadingButton>
                </Stack>
            </>
        </ModalWithHeader>
    );
};

export default SendEmailModal;
