import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormControlLabel,
    Select,
    Stack,
    Typography
} from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_SCHEDULE_TYPE } from '../../../../helpers/constant/coreConstants';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector.ts';
import { updateCampaignSequenceSchedule } from '../../../../state/features/emailCampaign/emailCampaignSlice.ts';
import { CustomMenuItem, CustomRadio } from "../../../bulkEmail/BulkEmailStyle.tsx";
import CustomFooter from '../../../common/CustomFooter';

interface Props {
    onClose: () => void;
}

const HOURS = Array.from({ length: 24 }, (_, index) => {
    const hour = index.toString().padStart(2, '0');
    return { label: hour, value: hour };
});

const MINUTES = Array.from({ length: 60 }, (_, index) => ({ label: index.toString().padStart(2, '0'), value: index.toString().padStart(2, '0') }));

const SameDayElement: React.FC<Props> = ({ onClose }) => {
    const dispatch = useDispatch();
    const { selectedSequence } = useSelector(emailCampaignState);
    const [sendOption, setSendOption] = useState(EMAIL_SCHEDULE_TYPE.IMMEDIATELY);
    const [time, setTime] = useState<string | undefined>('00:00');
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');

    useEffect(() => {
        if (selectedSequence) {
            if (selectedSequence.day === 0 && selectedSequence.type === EMAIL_SCHEDULE_TYPE.SCHEDULE && selectedSequence.hour) {
                setTime(selectedSequence.hour);
                const [hour, minute] = selectedSequence.hour.split(':');
                setHours(hour);
                setMinutes(minute);
                setSendOption(selectedSequence.type);
            }
        }
    }, []);

    const handleTimes = (time: string) => {
        setTime(time);
    };


    const handleSubmit = () => {
        const sequenceTimePayload = `${String(hours || 0).padStart(2, '0')}:${String(minutes || 0).padStart(2, '0')}`;

        if (selectedSequence) {
            dispatch(updateCampaignSequenceSchedule({ type: sendOption, day: 0, hour: sequenceTimePayload, index: selectedSequence.index, uid: selectedSequence.uid }));
            onClose();
        }
    };
    // const handleHour = () => {}

    return (
        <>
            <Stack paddingX={3}>
                <Stack direction={'row'}>
                    <FormControlLabel
                        value='small'
                        checked={sendOption === EMAIL_SCHEDULE_TYPE.IMMEDIATELY}
                        onClick={() => setSendOption(EMAIL_SCHEDULE_TYPE.IMMEDIATELY)}
                        control={<CustomRadio color='primary' size='small' />}
                        label={
                            <Typography variant='body2' color={'text.primary'}>
                                Immediately
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value='small'
                        checked={sendOption === EMAIL_SCHEDULE_TYPE.SCHEDULE}
                        onClick={() => setSendOption(EMAIL_SCHEDULE_TYPE.SCHEDULE)}
                        control={<CustomRadio color='primary' size='small' />}
                        label={
                            <Typography variant='body2' color={'text.primary'}>
                                Later on the same day
                            </Typography>
                        }
                    />
                </Stack>

                {sendOption === EMAIL_SCHEDULE_TYPE.SCHEDULE && (
                    <Stack /* width={'286px'} */ direction={'row'} alignItems={'center'} spacing={2} mt={1} /* justifyContent={'flex-end'} */>
                        <Typography variant='body2' whiteSpace={'nowrap'} color={'text.primary'} fontWeight={600}>
                            Send After
                        </Typography>

                        <FormControl sx={{ "& input": { height: "unset !important" }, minWidth: 100 }} fullWidth>
                            <Select
                                width='100%'
                                maxWidth='100%'
                                value={hours}
                                onChange={(event: any) => setHours(event.target.value)}
                                name='hours'
                                className='rounded'
                            >
                                {HOURS.map(item => <CustomMenuItem value={item.value}>{item.label}</CustomMenuItem>)}
                            </Select>
                        </FormControl>

                        <Typography variant='body2' whiteSpace={'nowrap'} color={'text.primary'} fontWeight={600}>
                            Hour
                        </Typography>

                        <FormControl sx={{ "& input": { height: "unset !important" } }} fullWidth>
                            <Select
                                width='100%'
                                maxWidth='100%'
                                value={minutes}
                                onChange={(event: any) => setMinutes(event.target.value)}
                                // options={MINUTES}
                                name='colors'
                                className='rounded'
                            >
                                {MINUTES.map(item => <CustomMenuItem value={item.value}>{item.label}</CustomMenuItem>)}
                            </Select>

                        </FormControl>

                        <Typography variant='body2' whiteSpace={'nowrap'} color={'text.primary'} fontWeight={600}>
                            Minutes
                        </Typography>

                        {/* <CustomTextField
                            fullWidth
                            value={time}
                            onChange={(e) => handleTimes(e.target.value)}
                            type={'time'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <ClockIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& input::-webkit-calendar-picker-indicator': {
                                    width: '200px',
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    opacity: 0,
                                    zIndex: 99,
                                },
                            }}
                        />
                        <Typography variant='body2' whiteSpace={'nowrap'} color={'text.primary'} fontWeight={600}>
                            later
                        </Typography> */}
                    </Stack>
                )}
            </Stack>

            <CustomFooter onSubmit={handleSubmit} onClose={onClose} />
        </>
    );
};

export default SameDayElement;
