import React from 'react';
import {
    AIFilledIcon,
    Button,
    IconButton,
    Paper,
    PersonalizedIcon,
    SmileEmojiIcon,
    Stack,
    TextField,
    Typography,
    useTheme,
} from 'convertupleads-theme';

interface ReWriteWithAiPopoverProps {
    toggleDrawer: () => void;
    handleClose: () => void;
}

const ReWriteWithAiPopover: React.FC<ReWriteWithAiPopoverProps> = ({ toggleDrawer, handleClose }) => {
    const handleCloseButton = () => {
        toggleDrawer();
        handleClose();
    };
    const theme = useTheme();
    return (
        <Paper sx={{ width: '428px', padding: '24px' }}>
            <Typography variant='subtitle2' fontWeight={700}>
                Generate Variations
            </Typography>
            <Typography variant='body2' mt={2} color={'text.secondary'}>
                Type variation Number
            </Typography>
            <TextField sx={{ marginTop: '8px' }} fullWidth id='outlined-basic' />

            <Stack padding={2} width={395} borderRadius={2} mt={2} sx={{ background: theme.palette.other.outlinedBorder }}>
                <Stack
                    direction={'row'}
                    paddingBottom={0.5}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    borderBottom={`1px solid ${theme.palette.other.divider}`}
                >
                    <Stack direction={'row'}>
                        {' '}
                        <Typography variant='body2' color={'text.disabled'}>
                            Subject:
                        </Typography>
                        <Typography variant='body2' color={'text.primary'}>
                            &nbsp;Grow Your Reach with Email
                        </Typography>
                    </Stack>
                    <Stack direction={'row'}>
                        <IconButton>
                            <SmileEmojiIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                        <IconButton>
                            <PersonalizedIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography color={'text.secondary'} variant='body2' mt={1}>
                        Dear [Client{"'"}s Name],
                    </Typography>
                    <Typography color={'text.secondary'} variant='body2' mt={2}>
                        I hope this email finds you well. My name is [Your Name], and I represent [Your Company Name], a specialized team of
                        UX/UI designers dedicated to enhancing user experiences and driving business success.
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction={'row'} spacing={1} justifyContent={'end'} mt={2}>
                <Button color='secondary' variant={'contained'} startIcon={<AIFilledIcon />} size='large' onClick={handleCloseButton}>
                    Generate
                </Button>
            </Stack>
        </Paper>
    );
};

export default ReWriteWithAiPopover;
