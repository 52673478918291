import React, { useState } from "react";
import {
    Select,
    MenuItem,
    ListSubheader,
    FormControl,
    ChevronDownIcon
} from "convertupleads-theme";
import {
    CustomTextField
} from "../../../../../BulkEmailStyle.tsx";

const StyledMenuProps = {
    PaperProps: {
        sx: {
            maxHeight: 300,
            '& .MuiListSubheader-root': {
              paddingTop: '12px',
            },
            '& .MuiMenuItem-root': {
                padding: '10px 20px',
            },
            '& .MuiList-root': {
                padding: 0,
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 transparent',
            '&::-webkit-scrollbar': {
                width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '6px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
            },
        },
    },
};

interface IOption {
    label: string;
    value: string;
    [key: string]: string;
}

interface ISelectWithSearchBoxProps {
    options: IOption[];
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    fieldMap?: { label: string; value: string; };
    emptyLabel?: string;
}

const SelectWithSearchBox: React.FC<ISelectWithSearchBoxProps> = ({ options, value, onChange, disabled, fieldMap, emptyLabel }) => {
    const [search, setSearch] = useState<string>("");

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const filteredOptions = options.filter((option: IOption) => {
        if (fieldMap){
            return option[fieldMap.label].toLowerCase().includes(search.toLowerCase());
        }else {
            return option.label.toLowerCase().includes(search.toLowerCase());
        }
    });

    return (
        <FormControl fullWidth>
            <Select
                fullWidth
                value={value}
                defaultValue={''}
                onChange={onChange}
                IconComponent={ChevronDownIcon}
                MenuProps={StyledMenuProps}
                disabled={disabled}
                displayEmpty
            >
                <ListSubheader>
                    <CustomTextField
                        size="small"
                        placeholder="Search..."
                        fullWidth
                        value={search}
                        onChange={handleSearchChange}
                    />
                </ListSubheader>
                <MenuItem value={value === null ? null : ''}>{emptyLabel || 'Select option'}</MenuItem>
                {
                    filteredOptions.length === 0 ? (
                        <MenuItem disabled>No options</MenuItem>
                    ) : (
                    filteredOptions.map((option) => (
                        <MenuItem key={fieldMap ? option[fieldMap.value] : option.value} value={fieldMap ? option[fieldMap.value] : option.value}>
                            {fieldMap ? option[fieldMap.label] : option.label}
                        </MenuItem>
                    )))
                }
            </Select>
        </FormControl>
    );
};

export default SelectWithSearchBox;