import React, { useEffect } from 'react';
import { Stack } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { IEmailCampaign } from '../../../../state/features/emailCampaign/emailCampaign.interface';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { CustomScrollbar } from '../../../common/CustomScrollBarStyle';
import CampaignLIstSkeleton from './CampaignLIstSkeleton';
import CampaignListItem from './CampaignListItem';
import { useSearchParams } from "react-router-dom";
import { selectCampaign } from "../../../../state/features/emailCampaign/emailCampaignSlice.ts";

const CampaignList: React.FC = () => {
    const dispatch = useDispatch();
    const { campaignList, isLoading } = useSelector(emailCampaignState);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.has('campaignUid')) {
            const selectedCampaign = campaignList.find((campaign: IEmailCampaign) => (campaign.uid === searchParams.get('campaignUid')));

            if (selectedCampaign) {
                dispatch(selectCampaign(selectedCampaign));
            } else {
                searchParams.delete('campaignUid');
                setSearchParams(searchParams);
            }
        }

        if (searchParams.has('error') && searchParams.has('campaignUid')) {
            searchParams.delete('error');
            searchParams.delete('campaignUid');

            setSearchParams(searchParams);
        }
    }, []);

    const Campaigns = () => {
        if (isLoading) return <CampaignLIstSkeleton />;
        else if (campaignList?.length > 0) return campaignList?.map((campaign: IEmailCampaign, index: number) => <CampaignListItem key={campaign.uid} campaign={campaign} />)
    }

    return (
        <Stack>
            <CustomScrollbar sx={{ height: 'calc(100vh - 380px)', paddingRight: '5px' }}>{Campaigns()}</CustomScrollbar>
        </Stack>
    );
};

export default CampaignList;
