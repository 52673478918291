import React, { useEffect, useState } from 'react';
import {
    AddIcon,
    ClockIcon,
    Divider,
    IconButton,
    InputAdornment,
    RemoveFilledIcon,
    Stack,
    TextField,
    Typography,
    useTheme,
} from 'convertupleads-theme';
import CustomFooter from '../../../common/CustomFooter';
import { CAMPAIGN_SETTINGS, DATABASE_TIMEZONE, EMAIL_SCHEDULE_TYPE } from '../../../../helpers/constant/coreConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateCampaignSequenceSchedule,
} from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector.ts';
import { ICampaignSequenceSchedulePayload } from '../../../../state/features/emailCampaign/emailCampaign.interface.ts';
import { CustomTextField } from "../../../bulkEmail/BulkEmailStyle.tsx";

interface Props {
    onClose: () => void;
}

const RecuringElement: React.FC<Props> = ({ onClose }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { selectedSequence, schedules } = useSelector(emailCampaignState);
    const [days, setDays] = useState(1);
    const [time, setTime] = useState('00:00');

    const currentDate = new Date();

    useEffect(() => {
        if (selectedSequence) {
            if (selectedSequence.type === EMAIL_SCHEDULE_TYPE.RECURRING) {
                setDays(selectedSequence.day);
                if (selectedSequence.hour) {
                    setTime(convertTimeToCampaignTimezone(selectedSequence.hour));
                }
            }
        }
    }, []);

    const handleTime = (time: string) => {
        setTime(time);
    };

    const getValue = (key: string): string => {
        if (schedules) {
            return String(schedules[key]);
        }
        return "";
    };

    const convertTimeToCampaignTimezone = (value: string): string => {

        try {
            const dateString = currentDate.toISOString().split('T')[0];
            const concatenatedDateTime = dateString + " " + value;
            let convertedTime = (window as any).globalTimezoneConversionToDifferentTimezone(concatenatedDateTime, DATABASE_TIMEZONE, getValue(CAMPAIGN_SETTINGS.TIMEZONE), 'HH:mm')
            return convertedTime;
        } catch (e) {
            return value
        }
    };

    const convertTimeToUtc = (value: string): string => {

        try {
            const dateString = currentDate.toISOString().split('T')[0];
            const concatenatedDateTime = dateString + " " + value;
            let convertedTime = (window as any).globalTimezoneConversionToDifferentTimezone(concatenatedDateTime, getValue(CAMPAIGN_SETTINGS.TIMEZONE), DATABASE_TIMEZONE, 'HH:mm')
            return convertedTime;
        } catch (e) {
            return value
        }
    };

    const handleSubmit = () => {
        if (selectedSequence) {

            const payload: ICampaignSequenceSchedulePayload = {
                type: EMAIL_SCHEDULE_TYPE.RECURRING,
                index: selectedSequence.index,
                uid: selectedSequence.uid,
                hour: convertTimeToUtc(time),
                day: days
            };

            dispatch(updateCampaignSequenceSchedule(payload));
            onClose();
        }
    };

    return (
        <>
            <Stack direction={'row'} spacing={2} alignItems={'center'} mt={2} paddingX={3}>
                <Typography variant='body2' whiteSpace={'nowrap'} color={'text.primary'} fontWeight={600}>
                    Repeat every{' '}
                </Typography>
                <Stack direction={'row'} borderRadius={1} border={`2px solid ${theme.palette.other.divider}`} width={140} height={40}>
                    <IconButton
                        variant={'rounded'}
                        sx={{ width: '40px', '&:focus': { background: 'transparent' } }}
                        onClick={() => {
                            if (days > 1) setDays(days - 1);
                        }}
                    >
                        <RemoveFilledIcon sx={{ fontSize: '16px' }} />
                    </IconButton>

                    <Divider orientation='vertical' />

                    <CustomTextField
                        fullWidth
                        value={days}
                        sx={{
                            borderRadius: 0,
                            textAlign: 'center',
                            '& .MuiFilledInput-root ': {
                                borderRadius: 0,
                            },
                            '&.MuiTextField-root input': {
                                textAlign: 'center',
                            },
                            '& .MuiInputBase-input': {
                                paddingBlock: '8px !important',
                                paddingLeft: '0 !important',
                            }
                        }}
                        variant='filled'
                    />
                    <Divider orientation='vertical' />

                    <IconButton
                        variant={'rounded'}
                        sx={{ width: '40px', '&:focus': { background: 'transparent' } }}
                        onClick={() => setDays(days + 1)}
                    >
                        <AddIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                </Stack>
                <Typography variant='body2' whiteSpace={'nowrap'} color={'text.primary'} fontWeight={600}>
                    day(s) at{' '}
                </Typography>
                <CustomTextField
                    value={time}
                    onChange={(e) => handleTime(e.target.value)}
                    type={'time'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <ClockIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& input::-webkit-calendar-picker-indicator': {
                            width: '200px',
                            position: 'absolute',
                            cursor: 'pointer',
                            opacity: 0,
                            zIndex: 99,
                        },
                        '& .MuiInputBase-input': {
                            marginRight: '10px !important',
                        }
                    }}
                />
            </Stack>

            <CustomFooter onSubmit={handleSubmit} onClose={onClose} />
        </>
    );
};

export default RecuringElement;
