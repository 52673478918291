import { combineReducers } from '@reduxjs/toolkit';
import campaignFolderSlice from './features/campaignFolder/campaignFolderSlice';
import emailCampaignSlice from './features/emailCampaign/emailCampaignSlice';

export const bulkEmailReducers = {
    campaignFolder: campaignFolderSlice,
    emailCampaign: emailCampaignSlice,
};

export const rootReducer = combineReducers(bulkEmailReducers);

export default bulkEmailReducers;
