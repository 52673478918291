import React, { Dispatch, SetStateAction } from 'react';
import { Button, InputAdornment, SearchIcon, SortIcon, Stack } from 'convertupleads-theme';
import { CustomTextField } from '../../../BulkEmailStyle';
import SortByPopOver from '../popover/SortByPopOver';

interface IProps{
    setSearchContent:Dispatch<SetStateAction<string>>;
    searchContent:string;
    setSortType:Dispatch<SetStateAction<string>>;
    setSortContentBy:Dispatch<SetStateAction<string>>;
    totalCount:number|undefined;
}

const LeadContactListFilter: React.FC<IProps> = ({setSearchContent,searchContent,setSortType,setSortContentBy,totalCount}) => {

    const [sortBy, setSortBy] = React.useState<HTMLSpanElement | SVGSVGElement | null>(null);


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchContent(event.target.value);
    };

    const handleSortBy = (event: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        setSortBy(event.currentTarget);
    };

    const openSortBy = Boolean(sortBy);
    const sortById = openSortBy ? 'simple-popover' : undefined;

    return (
        <Stack direction={'row'} justifyContent={'space-between'} p={1}>
            <CustomTextField
                type='search'
                placeholder={'Search Contacts'}
                onChange={handleSearch}
                value={searchContent}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon sx={{ fontSize: 20 }} />
                        </InputAdornment>
                    ),
                }}
            />

            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                {(totalCount !== undefined && totalCount>0) && <Button variant={'outlined'} color={'inherit'} startIcon={<SortIcon />} onClick={handleSortBy} sx={{':focus': { bgcolor: 'transparent' }}}>
                    Sort By
                </Button>}
                

                {/* <Button variant={'tonal'} color={'inherit'} endIcon={<ChevronDownIcon />} onClick={handleSortBy} sx={{':focus': { bgcolor: 'transparent' }}}>
                    More
                </Button> */}

            </Stack>

            <SortByPopOver sortById={sortById} openSortBy={openSortBy} setSortType={setSortType} setSortContentBy={setSortContentBy} sortBy={sortBy} handleClose={() => setSortBy(null)} />
        </Stack>
    );
};

export default LeadContactListFilter;