import React, { useEffect, useState } from 'react';
import { DeleteFilledIcon, DeleteModal, ListItemButton, ListItemIcon, ListItemText, Typography } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../../state/features/emailCampaign/emailCampaignSelector';
import { deleteCampaign } from '../../../../../state/features/emailCampaign/emailCampaignSlice';
import { IEmailCampaign } from '../../../../../state/features/emailCampaign/emailCampaign.interface';

interface IDeleteCampaign {
    onClose: () => void;
    campaign: IEmailCampaign;
}

const DeleteCampaign: React.FC<IDeleteCampaign> = ({ onClose, campaign }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const { isDeleteCampaignSuccess } = useSelector(emailCampaignState);
    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpenDeleteModal(true);
    };

    const handleOnConfirmForDeleteModal = () => {
        dispatch(deleteCampaign({ campaignUids: [campaign.uid as string] }));
    };

    useEffect(() => {
        if (isDeleteCampaignSuccess) {
            setOpenDeleteModal(false);
            onClose();
        }
    }, [isDeleteCampaignSuccess]);
    return (
        <div>
            <ListItemButton onClick={handleDeleteModal}>
                <ListItemIcon>
                    <DeleteFilledIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText>
                    <Typography color={'text.primary'} variant='body2' fontWeight={400}>
                        Delete Campaign
                    </Typography>
                </ListItemText>
            </ListItemButton>

            <DeleteModal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                title='Are you sure, You want to delete this campaign?'
                warningContent='You are able to leave this page without saving. All changes will be lost.'
                onConfirm={handleOnConfirmForDeleteModal}
                sx={{
                    '& .MuiButton-tonalInherit:focus': { bgcolor: 'other.outlinedBorder' }, 
                    '& .MuiButton-colorError:focus': { bgcolor: 'error.main' }
                }}
            />
        </div>
    );
};

export default DeleteCampaign;
