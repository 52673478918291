import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Stack,
  styled,
  Typography,
} from "convertupleads-theme";
import emailCampaignService from "../../../../../services/emailCampaign.service";
import { CustomScrollbar } from "../../../../common/CustomScrollBarStyle";

const ActivityLogList = styled("ul")(({ theme }) => ({
  padding: 0,
  "& li": {
    listStyle: "none",
    position: "relative",
    padding: theme.spacing(0, 3, 3),
    "&:before": {
      content: '""',
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: theme.palette.grey[400],
      position: "absolute",
      left: 0,
      top: 6,
    },
    "&:after": {
      content: '""',
      width: 2,
      height: "100%",
      backgroundColor: theme.palette.grey[300],
      position: "absolute",
      left: 3,
      top: 13,
    },
  },
}));

interface IProps {
  contactId: number;
}
interface TimelineItem {
  message: string;
  color?: string;
  userInfo?: {
    full_name: string;
  };
  createdAt: string;
}

const BasicActivityLog: React.FC<IProps> = ({ contactId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [timelineData, setTimelineData] = useState<TimelineItem[]>([]);
  const [apiCallStatus, setApiCallStatus] = useState(true);

  useEffect(() => {
    if (apiCallStatus) {
      fetchTimelineData();
    }
  }, [page]);
  const fetchTimelineData = async () => {
    setIsLoading(true);
    try {
      const res = await emailCampaignService.getContactTimeLines({
        page,
        contactId,
        limit,
      });

      if (res && res.success) {
        setTimelineData([...timelineData, ...res.data]);
        if (!res.data[0]) {
          setApiCallStatus(false);
        }
        // if (page === 1) {
        //   setContacts(res.data);
        //   setTotalContactsCount(res.totalCount);
        // }
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePage = (uPage: number) => {
    if (apiCallStatus) {
      setPage(uPage);
    }
  };
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight -
        e.currentTarget.scrollTop -
        e.currentTarget.clientHeight <
      40;
    if (bottom && !isLoading) {
      if (!isLoading) {
        setTimeout(() => {
          let uPage = page + 1;
          handlePage(uPage);
        }, 500);
      }
    }
  };
  return (
    <ActivityLogList>
      {isLoading && (
        <Stack mt={4} alignItems={"center"}>
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && !timelineData[0] && (
        <Stack mt={4} alignItems={"center"}>
          <Typography>No data found</Typography>
        </Stack>
      )}
      <CustomScrollbar
        onScroll={handleScroll}
        sx={{ height: `calc(100vh - 190px)` }}
      >
        {timelineData?.map((timeline, index) => (
          <li key={index}>
            <Typography variant={"subtitle2"} color={"text.primary"} mb={1}>
              {timeline?.message}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ minWidth: 0 }}
              spacing={2}
            >
              <Badge
                // color={timeline?.color}
                color={"primary"}
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                // badgeContent={timeline?.icon}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 9 } }}
              >
                <Avatar size={"large"} alt="Remy Sharp">
                  {timeline?.userInfo?.full_name[0]}
                </Avatar>
              </Badge>

              <Box>
                <Typography variant="body2" color={"text.secondary"}>
                  {timeline?.userInfo ? timeline?.userInfo.full_name : "N/A"}
                </Typography>
                <Typography variant="caption" color={"text.tertiary"}>
                  {timeline?.createdAt}
                </Typography>
              </Box>
            </Stack>
          </li>
        ))}
      </CustomScrollbar>
    </ActivityLogList>
  );
};

export default BasicActivityLog;
