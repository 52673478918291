import React from "react";
import {Chip} from "convertupleads-theme";

const STATUS: { [key: string]: string; } = {
    'Queued': "#6c757d",
    'Processing': "#007bff",
    'Processed': "#28a745",
    'Pending': "#fd7e14",
    'Failed': "#dc3545",
    'Deleting': "#b21f2d",
    'N/A': "#007bff"
};

interface IProps {
    status: string;
}

const StatusChip: React.FC<IProps> = ({ status }) => {
    return(
        <Chip
            size="xSmall"
            sx={{ backgroundColor: STATUS[status], color: "#fff", ':hover': { backgroundColor: STATUS[status], color: "#fff"} }}
            label={status}
        />
    );
};

export default StatusChip;