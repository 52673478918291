import React from 'react';
import { Stack, IconButton, Typography, useTheme, Paper, Box, useMediaQuery, Theme } from 'convertupleads-theme';
interface AnalyticsLeftSideItemProps {
    icon: React.ReactNode;
    value: string;
    label: string;
    iconColor?: string;
}

const EachLeftSideItem: React.FC<AnalyticsLeftSideItemProps> = ({ icon, value, label, iconColor }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Paper elevation={0} sx={{ p: 1.5, borderRadius: '4px' }}>
            <Box width={isSmallScreen ? '100%' : 312} sx={{ background: theme.palette.common.white }}>
                <Stack
                    direction={isSmallScreen ? 'column' : 'row'}
                    alignItems={isSmallScreen ? 'flex-start' : 'center'}
                    spacing={isSmallScreen ? 2 : 2}
                >
                    <IconButton
                        variant={'rounded'}
                        size={isSmallScreen ? 'medium' : 'large'}
                        sx={{ color: iconColor || theme.palette.primary.main, background: label !== 'Total Cost' ?  theme.palette.primary.light : theme.palette.success.light }}
                    >
                        {icon}
                    </IconButton>
                    <Stack spacing={1}>
                        <Typography variant={'h5'} color={'text.primary'}>
                            {value || 0}
                        </Typography>
                        <Typography variant={'body2'} color={'text.secondary'}>
                            {label}
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </Paper>
    );
};

export default EachLeftSideItem;
