import { PayloadAction } from '@reduxjs/toolkit';
import { delay, takeEvery } from 'redux-saga/effects';
import { all, call, put, takeLatest } from 'typed-redux-saga';

import {
    addCampaignFolderFailed,
    addCampaignFolderSuccess,
    deleteCampaignFolderFailed,
    deleteCampaignFolderSuccess,
    getCampaignFoldersFailed,
    getCampaignFoldersSuccess,
    updateCampaignFolderFailed,
    updateCampaignFolderSuccess,
} from './campaignFolderSlice.ts';
import campaignFolderService from '../../../services/campaignFolder.service.ts';
import { IFolder, IGetFoldersPayload } from './campaignFolder.interface.ts';

function* campaignFolderWatcher() {
    yield takeEvery('campaignFolder/getCampaignFolders', getCampaignFoldersSaga);
    yield takeEvery('campaignFolder/addCampaignFolder', addCampaignFolderSaga);
    yield takeEvery('campaignFolder/deleteCampaignFolder', deleteCampaignFolderSaga);
    yield takeLatest('campaignFolder/updateCampaignFolder', updateCampaignFolderSaga);
}

function* getCampaignFoldersSaga(action: PayloadAction<IGetFoldersPayload>) {
    try {
        const response = yield* call(campaignFolderService.getCampaignFolders, action.payload);
        if (response.success) {
            yield put(getCampaignFoldersSuccess(response));
        } else {
            yield put(getCampaignFoldersFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getCampaignFoldersFailed('Network or server error'));
    }
}

function* addCampaignFolderSaga(action: PayloadAction<IFolder>) {
    try {
        const response = yield* call(campaignFolderService.addCampaignFolder, action.payload);

        yield delay(1000);

        if (response.success) {
            yield put(addCampaignFolderSuccess(response.data));
        } else {
            yield put(addCampaignFolderFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getCampaignFoldersFailed('Network or server error'));
    }
}

function* updateCampaignFolderSaga(action: PayloadAction<IFolder>) {
    try {
        const response = yield* call(campaignFolderService.updateCampaignFolder, action.payload);

        yield delay(1000);

        if (response.success) {
            yield put(updateCampaignFolderSuccess(action.payload));
        } else {
            yield put(updateCampaignFolderFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getCampaignFoldersFailed('Network or server error'));
    }
}

function* deleteCampaignFolderSaga(action: PayloadAction<string>) {
    try {
        const response = yield* call(campaignFolderService.deleteCampaignFolder, action.payload);
        response.success = true;
        if (response.success) {
            yield put(deleteCampaignFolderSuccess(action.payload));
        } else {
            yield put(deleteCampaignFolderFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getCampaignFoldersFailed('Network or server error'));
    }
}

export default function* campaignFolderSaga() {
    yield all([campaignFolderWatcher()]);
}
