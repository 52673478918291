import React, {useEffect, useState} from 'react';
import {Box, IconButton, Paper, Stack, Typography, Divider, Chip, useTheme} from 'convertupleads-theme';
import {useDispatch, useSelector} from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import {CustomTextField} from "../../BulkEmailStyle.tsx";
import {updateCampaignSettingDetails} from "../../../../state/features/emailCampaign/emailCampaignSlice.ts";
import {CAMPAIGN_SETTING_KEYS} from "../../../../helpers/constant/coreConstants.ts";
import {checkDomainOrEmail, showNotification} from "../../../../helpers/util/functions.tsx";
import { UnsubscribeEmailIcon } from '../../../../assets/Icons.tsx';

const Unsubscribe: React.FC = () => {
    const dispatch = useDispatch();
    const [inputData, setInputData] = useState('');
    const [unsubscribeList, setUnsubscribeList] = useState([]);
    const { selectedCampaign, campaignSettings } = useSelector(emailCampaignState);

    const theme = useTheme()

    useEffect(() => {
        if (campaignSettings && campaignSettings.UNSUBSCRIBE_LIST && campaignSettings.UNSUBSCRIBE_LIST[0] && campaignSettings.UNSUBSCRIBE_LIST[0].settingsDetails) {
            setUnsubscribeList(campaignSettings.UNSUBSCRIBE_LIST[0].settingsDetails);
        }
    }, [campaignSettings]);

    const handleKeyPress = (event: any) => {
        const { key, target } = event;
        const inputData = target.value.trim();
        if (key === 'Enter' || key === ' ' || key === ',' || key === 'Spacebar') {
            event.preventDefault();
            if (checkDomainOrEmail(inputData)) {
                if (unsubscribeList.includes(inputData)) {
                    showNotification("error", "Already exists in list");
                    return;
                }
                dispatch(updateCampaignSettingDetails({ campaignUid: selectedCampaign.uid, settingsTitle: CAMPAIGN_SETTING_KEYS.UNSUBSCRIBE_LIST, settingsDetails: [...unsubscribeList, inputData] }));
                setInputData('');
            } else {
                showNotification("error", "Only email or domain allowed");
            }
        }
    };


    const handleDelete = (index: number) => {
        let tempUnsubscribeList = [...unsubscribeList];
        tempUnsubscribeList.splice(index, 1);
        dispatch(updateCampaignSettingDetails({ campaignUid: selectedCampaign.uid, settingsTitle: CAMPAIGN_SETTING_KEYS.UNSUBSCRIBE_LIST, settingsDetails: tempUnsubscribeList }));
    };

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: '18px 20px', mt: 2.5 }}>
            <Box width={960}>
                <Stack direction={'row'} justifyContent={'space-between'} spacing={1} sx={{ cursor: 'pointer' }}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <IconButton variant={'rounded'} size={'medium'} color={'primary'} sx={{ backgroundColor: 'primary.light' }}>
                            <UnsubscribeEmailIcon height={22.5} width={22.5} color={theme.palette.primary.main} opacity={1} />
                        </IconButton>
                        <Stack flex={1}>
                            <Typography variant={'subtitle1'} color={'text.secondary'}>
                                <b>Unsubscribe List {unsubscribeList && unsubscribeList.length > 0 && `(${unsubscribeList.length})`} </b>
                            </Typography>
                            <Typography variant={'caption'} color={'text.secondary'}>
                                Add specific emails or entire domains to your global unsubscribe list
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Divider sx={{ pt: 2 }} light />
                <Stack pl={'57px'} pt={'20px'}>
                    <Stack direction={'row'} gap={1} flexWrap={'wrap'} mb={2}>
                        {unsubscribeList?.map((item: string, index: number ) => (
                            <Chip key={item} label={item} onDelete={() => { handleDelete(index) }} size='small' />
                        ))}
                    </Stack>
                    <CustomTextField
                        placeholder={"Type a domain or email, then press space, comma, or enter."}
                        onChange={(event: any)=>{ setInputData(event.target.value); }}
                        onKeyDown={handleKeyPress}
                        value={inputData}
                    />
                </Stack>
            </Box>
        </Paper>
    );
};

export default Unsubscribe;
