import React, { useState } from 'react';
import { useTheme } from 'convertupleads-theme';
import ReWriteDrawer from './ReWriteDrawer';

import CampaignSequenceBodyTop from './emailBodyTopSection/CampaignSequenceBodyTop';
import CampaignSquenceEmailBodyTextEditor from './CampaignSquenceEmailBodyTextEditor';
import CampaignSquenceEmailBodyFooter from './CampaignSquenceEmailBodyFooter';
import { emailCampaignState } from '../../../state/features/emailCampaign/emailCampaignSelector';
import { useSelector } from 'react-redux';
import { CAMPAIGN_SEQUENCE_MESSAGE_TYPE } from '../../../helpers/constant/coreConstants';
import MultipleEmailVariation from './multipleEmailVariation/MultipleEmailVariation';
import { CustomScrollbar } from '../../common/CustomScrollBarStyle';
import CampaignSequenceBodySkeleton from './CampaignSequenceBodySkeleton';

interface Props {
    showError: boolean;
    goStep: (step: number) => void;
}

const CampaignSequenceBody: React.FC<Props> = ({
    showError,
    goStep
}) => {
    const theme = useTheme();
    const [openModal, setOpenModal] = useState(false);
    const [state, setState] = React.useState(false);

    const { selectedSequence, isCampaignSequenceLoading } = useSelector(emailCampaignState);

    const toggleDrawer = () => {
        setState((prev) => !prev);
    };

    if(isCampaignSequenceLoading){
        return <CampaignSequenceBodySkeleton />
    }
    
    return (
        <CustomScrollbar
            sx={{ 
                border: `1px solid ${
                    selectedSequence ? theme.palette.other.outlinedBorder : "transparent"
                }`,
                p: 3,
                pb: 3.5,
                width: "100%",
                height: "calc(100vh - 232px)",
                borderRadius: 1,
             }}
        >
            {selectedSequence !== null && selectedSequence.messageType === CAMPAIGN_SEQUENCE_MESSAGE_TYPE.EMAIL &&
                <>
                    <CampaignSequenceBodyTop
                        toggleDrawer={toggleDrawer}
                        showError={showError}
                        goStep={goStep}
                    />
                    <CampaignSquenceEmailBodyTextEditor />
                    <CampaignSquenceEmailBodyFooter openModal={openModal} setOpenModal={setOpenModal} />

                    <ReWriteDrawer state={state} toggleDrawer={toggleDrawer} />
                    <MultipleEmailVariation />
                </>
            }
        </CustomScrollbar>
    );
};

export default CampaignSequenceBody;
