import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAddCampaign,
  IAddCampaignEmail,
  IAddSequenceVariation,
  IBulkCampaignAddToFolder,
  IBulkUpdateCampaign,
  ICampaignSchedule,
  ICampaignSchedulesPayload,
  ICampaignSequence,
  ICampaignSequencePayload,
  ICampaignSequenceSchedulePayload,
  ICloneCampaign,
  IDeleteCampaign,
  IDeleteCampaignEmail,
  IEmailCampaign,
  IEmailCampaignState, IEmailThreadPayload,
  IGetCampaignSettings,
  IGetEmailCampaigns,
  IEmailLookupPrice,
  ISequenceStatusItem,
  ISequenceUploadedFile,
  ISequenceUploadedFileUpdatePayload,
  ISingleCampaignAddToFolder, ITag, ITagPayload,
  IUpdateCampaign,
  IUpdateCampaignSettingDetails,
  IUpdateCampaignSettings,
  IUpdateEmailSchedule,
  IUpdateSequenceVariation, ICampaignEventAnalytics,
} from "./emailCampaign.interface";
import {
  CAMPAIGN_SETTINGS_TITLE,
  CAMPAIGN_TRIGGER_SETTINGS,
  EMAIL_THREAD,
  STATUS_PAUSE,
  STATUS_PAUSING,
  STATUS_RESUME,
  STATUS_STARTING
} from "../../../helpers/constant/coreConstants.ts";

const initialState: IEmailCampaignState = {
  campaignList: [] as IEmailCampaign[],

  isLoading: false,

  isAdding: false,
  isAddCampaignSuccess: false,

  isUpdating: false,
  isUpdateCampaignSuccess: false,
  isBulkUpdateCampaignSuccess: false,

  isDeleting: false,
  isDeleteCampaignSuccess: false,

  isCloning: false,
  isCloneCampaignSuccess: false,

  isError: false,

  error: "",

  selectedCampaigns: [],

  selectedCampaign: null,

  sequences: null,
  selectedSequence: null,
  pagination: { page: 1, perPage: 10 },

  schedules: null,

  isCampaignSequenceLoading: false,
  isCampaignSequenceAdding: false,
  isCampaignSequenceUpdating: false,
  isCampaignSequenceScheduleUpdating: false,
  isCampaignSequenceScheduleUpdatingSuccess: false,
  isCampaignSequenceDeleting: false,

  campaignSettings: null,
  isCampaignSchedulesLoading: false,
  isCampaignSchedulesUpdating: false,
  isCampaignSchedulesUpdated: false,

  isCampaignSettingsUpdating: false,

  query: undefined,

  connectedEmails: {
    userConnectedEmails: [],
    campaignConnectedEmails: [],
  },
  isUserConnectedEmailsLoading: false,
  isUserConnectedEmailsSuccess: false,
  isConnectedEmailsLoading: false,
  isAddCampaignEmailLoading: false,

  sequenceVariations: [],
  isSequenceVariationsLoading: false,
  isAddSequenceVariationsLoading: false,
  isAddSequenceVariationsSuccess: false,
  isUpdateSequenceVariationsLoading: false,
  isUpdateSequenceVariationsSuccess: false,
  isDeleteSequenceVariationsLoading: false,
  isDeleteSequenceVariationsSuccess: false,
  totalCount: 0,
  campaignTrigger: {
    campaignList: [],
    powerDialerList: [],
    tagList: [],
    virtualNumbers: [],
  },
  isCampaignTriggerAssetLoading: false,
  isCampaignTriggerUpdating: false,
  isCampaignTriggerUpdatingSuccess: false,
  isUpdateEmailThreadLoading: false,
  isUpdateEmailThreadSuccess: false,

  lastUpdatedTime: '',

  userEmailProvider: null,
  isUserEmailProviderLoading: false,
  isUserEmailProviderSuccess: false,
  isCreateTagSuccess: false,
  isCreatingTag: false,

  isLoadingCampaignSequenceStats: false,
  isLoadingEmailLookupPrice: false,

  emailLookupPrice: null,

  isLoadingCampaignEventAnalytics: false,
  campaignEventAnalytics: []
};

const emailCampaignSlice = createSlice({
  name: "emailCampaign",
  initialState,
  reducers: {
    getEmailCampaigns: (state, action: PayloadAction<IGetEmailCampaigns>) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
      state.pagination = {
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    },
    getEmailCampaignsSuccess: (state, action) => {
      state.isLoading = false;
      state.campaignList = action.payload.data;
      state.totalCount = action.payload.totalCount;
    },
    getEmailCampaignsFailed: (state, action) => {
      state.campaignList = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    getEmailCampaignsByFolder: (
      state,
      _action: PayloadAction<IGetEmailCampaigns>
    ) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
      state.pagination = {
        page: _action.payload.page,
        perPage: _action.payload.perPage,
      };
    },
    getEmailCampaignsByFolderSuccess: (state, action) => {
      state.isLoading = false;
      state.campaignList = action.payload.data;
      state.totalCount = action.payload.totalCount;
    },
    getEmailCampaignsByFolderFailed: (state, action) => {
      state.campaignList = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    // Create campaign
    addCampaign: (state, _action: PayloadAction<IAddCampaign>) => {
      state.isAdding = true;
      state.isError = false;
      state.error = "";
    },
    addCampaignSuccess: (state, action) => {
      state.isAdding = false;
      state.isAddCampaignSuccess = true;
      state.campaignList.unshift(action.payload);
      state.selectedCampaign = action.payload;
    },
    addCampaignFailed: (state, action) => {
      state.isAdding = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetAddCampaign: (state) => {
      state.isAddCampaignSuccess = false;
    },

    // Update campaign
    updateCampaign: (state, _action: PayloadAction<IUpdateCampaign>) => {
      state.isUpdating = true;
      state.isError = false;
      state.error = "";
      let actionPayload = { ..._action.payload };

      if (actionPayload.status && actionPayload.status === STATUS_PAUSE) {
        actionPayload.status = STATUS_PAUSING;
      }
      if (actionPayload.status && actionPayload.status === STATUS_RESUME) {
        actionPayload.status = STATUS_STARTING;
      }

      state.campaignList.forEach((item: IEmailCampaign, i: number) => {
        if (item.uid === _action.payload.uid) {
          state.campaignList[i] = {
            ...state.campaignList[i],
            ...actionPayload,
          };
          return;
        }
      });
    },
    updateCampaignSuccess: (state, _action: PayloadAction<IUpdateCampaign>) => {
      state.isUpdating = false;
      state.isUpdateCampaignSuccess = true;
      state.lastUpdatedTime = new Date();
    },
    updateCampaignFailed: (state, _action: PayloadAction<IUpdateCampaign>) => {
      state.isUpdating = false;
      state.isError = true;
      state.error = _action.payload.message ?? '';
      let actionPayload = {} as { status?: string, isFavourite?: string, title?: string };
      if (_action.payload.status) {
        actionPayload.status = _action.payload.oldValue
      } else if (_action.payload.title) {
        actionPayload.title = _action.payload.oldValue
      } else if (_action.payload.isFavourite) {
        actionPayload.isFavourite = _action.payload.oldValue
      }
      state.campaignList.forEach((item: IEmailCampaign, i: number) => {
        if (item.uid === _action.payload.uid) {
          state.campaignList[i] = {
            ...state.campaignList[i],
            ...actionPayload,
          };
          return;
        }
      });
    },
    resetUpdateCampaign: (state) => {
      state.isUpdateCampaignSuccess = false;
    },

    // Delete Campaign
    deleteCampaign: (state, _action: PayloadAction<IDeleteCampaign>) => {
      state.isDeleting = true;
      state.isError = false;
      state.error = "";
    },
    deleteCampaignSuccess: (state) => {
      state.isDeleting = false;
      state.isDeleteCampaignSuccess = true;
      state.selectedCampaigns = [];
    },
    deleteCampaignFailed: (state, action) => {
      state.isDeleting = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetDeleteCampaign: (state) => {
      state.isDeleteCampaignSuccess = false;
    },

    // Update campaign
    bulkUpdateCampaign: (
      state,
      _action: PayloadAction<IBulkUpdateCampaign>
    ) => {
      state.isUpdating = true;
      state.isError = false;
      state.error = "";
    },
    bulkUpdateCampaignSuccess: (state, _action: PayloadAction<IBulkUpdateCampaign>) => {
      state.isUpdating = false;
      state.isBulkUpdateCampaignSuccess = true;
    },
    bulkUpdateCampaignFailed: (state, action) => {
      state.isUpdating = false;
      state.isError = true;
      state.error = action.payload;
    },

    bulkUnarchiveCampaign: (
      state,
      _action: PayloadAction<IBulkUpdateCampaign>
    ) => {
      state.isUpdating = true;
      state.isError = false;
      state.error = "";
    },
    bulkUnarchiveCampaignSuccess: (state) => {
      state.isUpdating = false;
      state.isBulkUpdateCampaignSuccess = true;
      state.selectedCampaigns = [];
    },

    bulkUnarchiveCampaignFailed: (state, action) => {
      state.isUpdating = false;
      state.isError = true;
      state.error = action.payload;
    },

    resetAllSelectedCampaigns: (state) => {
      state.selectedCampaigns = [];
      if (state.isBulkUpdateCampaignSuccess === true) {
        state.isBulkUpdateCampaignSuccess = false;
      }
      if (state.isDeleteCampaignSuccess === true) {
        state.isDeleteCampaignSuccess = false;
      }
      if (state.isCloneCampaignSuccess === true) {
        state.isCloneCampaignSuccess = false;
      }
    },

    // Clone campaign
    cloneCampaign: (state, _action: PayloadAction<IEmailCampaign>) => {
      state.isCloning = true;
      state.isError = false;
      state.error = "";
    },
    cloneCampaignSuccess: (state, action: PayloadAction<IEmailCampaign>) => {
      state.isCloning = false;
      state.campaignList.unshift(action.payload);
      state.isCloneCampaignSuccess = true;
    },
    cloneCampaignFailed: (state, action) => {
      state.isCloning = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetCloneCampaign: (state) => {
      state.isCloneCampaignSuccess = false;
    },

    updateCampaignBulkStatus: (
      state,
      _action: PayloadAction<ICloneCampaign>
    ) => {
      state.isUpdating = true;
      state.isError = false;
      state.error = "";
    },
    updateCampaignBulkStatusSuccess: (state) => {
      state.isUpdating = false;
      state.isUpdateCampaignSuccess = true;
    },
    updateCampaignBulkStatusFailed: (state, action) => {
      state.isUpdating = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetUpdateCampaignBulkStatus: (state) => {
      state.isUpdateCampaignSuccess = false;
    },

    // Search Campaign
    updateSearchFieldValue: (state, action) => {
      state.query = action.payload;
    },

    // Add To Folder
    singleCampaignAddToFolder: (
      state,
      _action: PayloadAction<ISingleCampaignAddToFolder>
    ) => {
      state.isAdding = true;
      state.isError = false;
      state.error = "";
    },
    singleCampaignAddToFolderSuccess: (
      state,
      _action: PayloadAction<ISingleCampaignAddToFolder>
    ) => {
      state.isAdding = false;
      state.isBulkUpdateCampaignSuccess = true;
      state.lastUpdatedTime = new Date();
    },
    singleCampaignAddToFolderFailed: (state, action) => {
      // state.data = [];
      state.isAdding = false;
      state.isError = true;
      state.error = action.payload;
    },

    bulkCampaignAddToFolder: (
      state,
      _action: PayloadAction<IBulkCampaignAddToFolder>
    ) => {
      state.isAdding = true;
      state.isError = false;
      state.error = "";
    },
    bulkCampaignAddToFolderSuccess: (state) => {
      state.isAdding = false;
      state.isBulkUpdateCampaignSuccess = true;
    },
    bulkCampaignAddToFolderFailed: (state, action) => {
      // state.data = [];
      state.isAdding = false;
      state.isError = true;
      state.error = action.payload;
    },
    toggleSelectedSingleCampaign: (state, action) => {
      const index = state.selectedCampaigns.indexOf(action.payload);

      index > -1
        ? state.selectedCampaigns.splice(index, 1)
        : state.selectedCampaigns.push(action.payload);
    },
    selectedAllCampaign: (state, _action) => {
      const allCampaignIds = state?.campaignList?.map(
        (campaign) => campaign.uid
      );
      state.selectedCampaigns = allCampaignIds.filter(
        (id): id is string => id !== undefined
      );
    },
    deselectAllCampaign: (state, _action) => {
      state.selectedCampaigns = [];
    },

    selectCampaign: (state, action: PayloadAction<IEmailCampaign>) => {
      state.selectedCampaign = action.payload;
      state.lastUpdatedTime = '';
    },

    deselectCampaign: (state) => {
      state.sequences = null;
      state.selectedSequence = null;
      state.selectedCampaign = null;
      state.lastUpdatedTime = '';
    },

    addSequences: (_state, _action) => {
      // state.sequences.push([]);
    },
    selectSequence: (state, action) => {
      state.selectedSequence = action.payload;
    },
    deleteSequence: (state, action) => {
      if (state.sequences) {
        state.sequences.splice(action.payload, 1);
      }
    },
    updateEmailSubject: (state, action) => {
      if (state.sequences && state.sequences[action.payload.index]) {
        state.sequences[action.payload.index].messageSubject =
          action.payload.messageSubject;
      }
    },
    addSequenceBetween: (state, action) => {
      if (state.sequences) {
        state.sequences.splice(action.payload + 1, 0);
      }
    },
    updateEmailSchedule: (
      state,
      action: PayloadAction<IUpdateEmailSchedule>
    ) => {
      if (state.sequences) {
        const { payload } = action;

        state.sequences[state.selectedSequence?.index || 0] = {
          ...state.sequences[state.selectedSequence?.index || 0],
          ...payload,
        };
      }
    },

    addCampaignSequence: (
      state,
      _action: PayloadAction<ICampaignSequencePayload>
    ) => {
      state.isCampaignSequenceAdding = true;
    },

    addCampaignSequenceSuccess: (
      state,
      action: PayloadAction<ICampaignSequence>
    ) => {
      let data = { ...action.payload };

      if (state.selectedCampaign && state.selectedCampaign.emailThreads === EMAIL_THREAD.SINGLE_THREAD) {
        data.messageSubject = state.selectedCampaign.commonEmailSubject ?? '';
      }

      state.isCampaignSequenceAdding = false;
      if (state.sequences) {
        if (action.payload.index) {
          state.sequences.splice(action.payload.index, 0, data);
        } else {
          state.sequences.push(data);
        }
      } else {
        state.sequences = [data];
      }
      state.selectedSequence = data;
      state.lastUpdatedTime = new Date();
    },

    addCampaignSequenceFailed: (state, action) => {
      state.isCampaignSequenceAdding = false;
      state.isError = action.payload;
    },

    getCampaignSequences: (
      state,
      _action: PayloadAction<ICampaignSequencePayload>
    ) => {
      if (state.selectedSequence === null) {
        state.isCampaignSequenceLoading = true;
      }
    },
    getCampaignSequencesSuccess: (
      state,
      action: PayloadAction<ICampaignSequence[]>
    ) => {
      state.isCampaignSequenceLoading = false;
      state.sequences = action.payload;

      let found = false;
      if (state.selectedSequence && Array.isArray(state.sequences)) {
        state.sequences.forEach((item, index) => {
          if (item.uid === state.selectedSequence?.uid) {
            state.selectedSequence.index = index;
            found = true;
          }
        })
      }
      if (!found) {
        if (action.payload && action.payload[0]) {
          let data = { ...action.payload[0], index: 0 };
          if (state.selectedCampaign?.emailThreads === EMAIL_THREAD.SINGLE_THREAD) {
            data.messageSubject = state.selectedCampaign.commonEmailSubject ?? '';
          }
          state.selectedSequence = data;
        }
      }
    },
    getCampaignSequencesFailed: (state, action) => {
      state.isCampaignSequenceLoading = false;
      state.sequences = [];
      state.error = action.payload;
    },

    updateCampaignSequence: (
      state,
      action: PayloadAction<ICampaignSequencePayload>
    ) => {
      state.isCampaignSequenceUpdating = true;

      const tempSequences = state.sequences ? [...state.sequences] : [];

      if (action.payload.index !== undefined && action.payload.index !== null) {
        tempSequences[action.payload.index] = {
          ...tempSequences[action.payload.index],
          ...action.payload,
        };
        state.selectedSequence = tempSequences[action.payload.index];
      }
      state.sequences = tempSequences;
      if (state.selectedCampaign && state.selectedCampaign.emailThreads === EMAIL_THREAD.SINGLE_THREAD && action.payload.messageSubject) {
        state.selectedCampaign.commonEmailSubject = action.payload.messageSubject ?? '';
        if (state.campaignList) {
          let dt = state.campaignList;
          state.campaignList = dt.map((item) => {
            if (item.uid === state.selectedCampaign?.uid) {
              if (action.payload.messageSubject)
                item.commonEmailSubject = action.payload.messageSubject
            }
            return item;
          })
        }
      }

    },

    updateCampaignSequenceSuccess: (
      state,
      _action: PayloadAction<ICampaignSequencePayload>
    ) => {
      state.isCampaignSequenceUpdating = false;
      state.lastUpdatedTime = new Date();
    },

    updateCampaignSequenceFailed: (state, action) => {
      state.isCampaignSequenceUpdating = false;
      state.isError = action.payload;
    },

    updateCampaignSequenceSchedule: (
      state,
      action: PayloadAction<ICampaignSequenceSchedulePayload>
    ) => {
      state.isCampaignSequenceScheduleUpdating = true;
      const tempSequences = state.sequences ? [...state.sequences] : [];
      if (action.payload.index !== undefined && action.payload.index !== null) {
        tempSequences[action.payload.index] = {
          ...tempSequences[action.payload.index],
          ...action.payload,
        };
        state.selectedSequence = tempSequences[action.payload.index];
      }
      state.sequences = tempSequences;
    },

    updateCampaignSequenceScheduleSuccess: (
      state,
      _action: PayloadAction<ICampaignSequenceSchedulePayload>
    ) => {
      state.isCampaignSequenceScheduleUpdating = false;
      state.isCampaignSequenceScheduleUpdatingSuccess = true
      state.lastUpdatedTime = new Date();
    },

    updateCampaignSequenceScheduleFailed: (state, action) => {
      state.isCampaignSequenceScheduleUpdating = false;
      state.isError = action.payload;
    },

    resetUpdateCampaignSequenceSchedule: (state) => {
      state.isCampaignSequenceScheduleUpdatingSuccess = false;
    },

    deleteCampaignSequence: (
      state,
      _action: PayloadAction<ICampaignSequencePayload>
    ) => {
      state.isCampaignSequenceDeleting = true;
    },

    deleteCampaignSequenceSuccess: (
      state,
      action: PayloadAction<ICampaignSequencePayload>
    ) => {
      state.isCampaignSequenceDeleting = false;
      if (action.payload.index !== null && action.payload.index !== undefined) {
        const tempSequences = state.sequences ? [...state.sequences] : [];
        tempSequences.splice(action.payload.index, 1);
        state.sequences = tempSequences;
        if (
          state.selectedSequence &&
          action.payload.uid === state.selectedSequence.uid
        ) {
          let newIndex: number = 0;
          if (tempSequences && tempSequences[newIndex]) {
            let data = { ...tempSequences[newIndex], index: newIndex };
            if (state.selectedCampaign?.emailThreads === EMAIL_THREAD.SINGLE_THREAD) {
              data.messageSubject = state.selectedCampaign.commonEmailSubject ?? '';
            }
            state.selectedSequence = data;
          } else {
            state.selectedSequence = null;
          }
        }
      }
      state.lastUpdatedTime = new Date();
    },

    deleteCampaignSequenceFailed: (state, action) => {
      state.isCampaignSequenceDeleting = false;
      state.isError = action.payload;
    },

    // Campaign Settings Start here
    getCampaignSettings: (
      state,
      _action: PayloadAction<IGetCampaignSettings>
    ) => {
      state.isError = false;
      state.error = "";
    },
    getCampaignSettingsSuccess: (state, action) => {
      state.campaignSettings = action.payload.data;
    },
    getCampaignSettingsFailed: (state, action) => {
      state.campaignSettings = null;
      state.isError = true;
      state.error = action.payload;
    },

    updateCampaignSettings: (
      state,
      action: PayloadAction<IUpdateCampaignSettings>
    ) => {
      state.isCampaignSettingsUpdating = true;
      if (state.campaignSettings) {
        const campaignSettingObj = state.campaignSettings[
          action.payload.settingsTitle
        ].find(
          (campaignSetting) =>
            campaignSetting.settingsTitle === action.payload.settingsTitle
        );

        if (campaignSettingObj && action.payload.settingsDetails && action.payload.status === undefined) {
          campaignSettingObj.settingsDetails = action.payload.settingsDetails;
        } else if (campaignSettingObj && action.payload.status) {
          campaignSettingObj.status = action.payload.status;
          if (action.payload.settingsTitle === CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL) {
            campaignSettingObj.settingsDetails = action.payload.settingsDetails;
          }
        }
      }
    },
    updateCampaignSettingsSuccess: (
      state
    ) => {
      state.isCampaignSettingsUpdating = false;
      state.lastUpdatedTime = new Date();
    },
    updateCampaignSettingsFailed: (state, action) => {
      state.isCampaignSettingsUpdating = false;
      state.isError = true;
      state.error = action.payload.message;
      if (state.campaignSettings) {
        const campaignSettingObj = state.campaignSettings[
          action.payload.settingsTitle
        ].find(
          (campaignSetting) =>
            campaignSetting.settingsTitle === action.payload.settingsTitle
        );
        console.log(action.payload)
        if (campaignSettingObj && action.payload.settingsDetails) {
          campaignSettingObj.settingsDetails = action.payload.oldValue;
        } else if (campaignSettingObj && action.payload.status) {
          campaignSettingObj.status = action.payload.oldValue;
        }
      }
    },

    updateCampaignSettingDetails: (state, action: PayloadAction<IUpdateCampaignSettingDetails>) => {
      state.isCampaignSettingsUpdating = true;
      if (state.campaignSettings) {
        const campaignSettingObj = state.campaignSettings[action.payload.settingsTitle].find((campaignSetting) => campaignSetting.settingsTitle === action.payload.settingsTitle);

        if (campaignSettingObj && action.payload.settingsDetails) {
          campaignSettingObj.settingsDetails = action.payload.settingsDetails;
        }
      }
    },
    updateCampaignSettingDetailsSuccess: (state) => {
      state.isCampaignSettingsUpdating = false;
      state.lastUpdatedTime = new Date();
    },
    updateCampaignSettingDetailsFailed: (state, action) => {
      state.isCampaignSettingsUpdating = false;
      state.isError = true;
      state.error = action.payload.message;
      if (state.campaignSettings) {
        const campaignSettingObj = state.campaignSettings[action.payload.settingsTitle].find((campaignSetting) => campaignSetting.settingsTitle === action.payload.settingsTitle);
        console.log(action.payload)
        if (campaignSettingObj && action.payload.settingsDetails) {
          campaignSettingObj.settingsDetails = action.payload.oldValue;
        } else if (campaignSettingObj && action.payload.status) {
          campaignSettingObj.status = action.payload.oldValue;
        }
      }
    },

    // Campaign Settings ends here
    getCampaignSchedules: (
      state,
      _action: PayloadAction<ICampaignSchedulesPayload>
    ) => {
      state.isCampaignSchedulesLoading = true;
    },
    getCampaignSchedulesSuccess: (
      state,
      action: PayloadAction<ICampaignSchedule>
    ) => {
      state.isCampaignSchedulesLoading = false;
      state.schedules = action.payload;
    },
    getCampaignSchedulesFailed: (state, action) => {
      state.isCampaignSchedulesLoading = false;
      state.error = action.payload;
    },

    updateCampaignSchedules: (
      state,
      _action: PayloadAction<ICampaignSchedulesPayload>
    ) => {
      state.schedules = { ...state.schedules, ...{ [_action.payload.keyName as string]: _action.payload.value } };
      state.isCampaignSchedulesUpdating = true;
      state.isCampaignSchedulesUpdated = false;
    },
    updateCampaignSchedulesSuccess: (
      state,
      action: PayloadAction<ICampaignSchedule>
    ) => {
      state.isCampaignSchedulesUpdating = false;
      state.isCampaignSchedulesUpdated = true;
      state.lastUpdatedTime = new Date();
    },
    updateCampaignSchedulesFailed: (state, action) => {

      state.isCampaignSchedulesUpdating = false;
      state.error = action.payload.message;
      state.isCampaignSchedulesUpdated = false;
      state.schedules = { ...state.schedules, ...{ [action.payload.keyName as string]: action.payload.oldValue } };

    },
    cleanUpdateCampaignSchedules: (state, _action) => {
      state.isCampaignSchedulesUpdating = false;
      state.isCampaignSchedulesUpdated = false;
    },

    getCampaignEmails: (state, _action) => {
      state.isConnectedEmailsLoading = true;
    },
    /* : PayloadAction<ICampaignConnectedEmails> */
    getCampaignEmailsSuccess: (state, action) => {
      state.connectedEmails.campaignConnectedEmails = action.payload;
      state.isConnectedEmailsLoading = false;
    },
    getCampaignEmailsFailed: (state, action) => {
      state.isConnectedEmailsLoading = false;
      state.error = action.payload;
    },

    getUserConnectedEmails: (state) => {
      state.isUserConnectedEmailsLoading = true;
    },

    getUserConnectedEmailsSuccess: (state, action) => {
      state.connectedEmails.userConnectedEmails = action.payload;
      state.isUserConnectedEmailsLoading = false;
      state.isUserConnectedEmailsSuccess = true;
    },

    getUserConnectedEmailsFailed: (state, action) => {
      state.isUserConnectedEmailsLoading = false;
      state.isUserConnectedEmailsSuccess = true;
      state.error = action.payload;
    },

    getUserEmailProvider: (state) => {
      state.isUserEmailProviderLoading = true;
    },

    getUserEmailProviderSuccess: (state, action) => {
      state.userEmailProvider = action.payload;
      state.isUserEmailProviderLoading = false;
      state.isUserEmailProviderSuccess = true;
    },

    getUserEmailProviderFailed: (state, action) => {
      state.isUserEmailProviderLoading = false;
      state.isUserEmailProviderSuccess = true;
      state.error = action.payload;
    },

    addCampaignEmail: (state, _action: PayloadAction<IAddCampaignEmail>) => {
      state.isAddCampaignEmailLoading = true;
    },
    addCampaignEmailSuccess: (
      state,
      action: PayloadAction<IAddCampaignEmail>
    ) => {
      state.connectedEmails.userConnectedEmails.filter(
        (connectedEmail) =>
          connectedEmail.email !== action.payload.userConnectedEmail
      );
      state.connectedEmails.campaignConnectedEmails.push(action.payload);
      state.isAddCampaignEmailLoading = false;
      state.lastUpdatedTime = new Date();
    },
    addCampaignEmailFailed: (state, _action) => {
      state.isAddCampaignEmailLoading = false;
    },

    deleteCampaignEmail: (
      state,
      _action: PayloadAction<IDeleteCampaignEmail>
    ) => {
      state.isAddCampaignEmailLoading = true;
    },
    deleteCampaignEmailSuccess: (
      state,
      action: PayloadAction<IDeleteCampaignEmail>
    ) => {
      const indexToRemove =
        state.connectedEmails.campaignConnectedEmails.findIndex(
          (email) => email.uid === action.payload.connectedEmailUid
        );
      if (indexToRemove !== -1) {
        state.connectedEmails.campaignConnectedEmails.splice(indexToRemove, 1);
      }
      state.isAddCampaignEmailLoading = false;
      state.lastUpdatedTime = new Date();
    },
    deleteCampaignEmailFailed: (state, _action) => {
      state.isAddCampaignEmailLoading = false;
    },

    getSequenceVariations: (state, _action: PayloadAction<{ uid: string }>) => {
      state.isSequenceVariationsLoading = true;
    },
    getSequenceVariationsSuccess: (state, action) => {
      state.sequenceVariations = action.payload;
      state.isSequenceVariationsLoading = false;
    },
    getSequenceVariationsFailed: (state, action) => {
      state.isSequenceVariationsLoading = false;
      state.sequenceVariations = [];
      state.error = action.payload;
    },

    addSequenceVariation: (
      state,
      _action: PayloadAction<IAddSequenceVariation>
    ) => {
      state.isAddSequenceVariationsLoading = true;
    },
    addSequenceVariationSuccess: (state, action) => {
      state.sequenceVariations.unshift(action.payload);
      state.isAddSequenceVariationsLoading = false;
      state.isAddSequenceVariationsSuccess = true;
      state.lastUpdatedTime = new Date();
    },
    addSequenceVariationFailed: (state, action) => {
      state.isAddSequenceVariationsLoading = false;
      state.error = action.payload;
    },
    resetAddSequenceVariation: (state, _action) => {
      state.isAddSequenceVariationsSuccess = false;
    },

    updateSequenceVariation: (
      state,
      _action: PayloadAction<IUpdateSequenceVariation>
    ) => {
      state.isUpdateSequenceVariationsLoading = true;
    },
    updateSequenceVariationSuccess: (state, action) => {
      if (action.payload.uid && state.sequenceVariations !== null) {
        state.sequenceVariations = state.sequenceVariations.map((item) => {
          if (item.uid === action.payload.uid) {
            return action.payload;
          }
          return item;
        });
      }
      state.isUpdateSequenceVariationsLoading = false;
      state.isUpdateSequenceVariationsSuccess = true;
      state.lastUpdatedTime = new Date();
    },
    updateSequenceVariationFailed: (state, action) => {
      state.isUpdateSequenceVariationsLoading = false;
      state.error = action.payload;
    },
    resetUpdateSequenceVariation: (state, _action) => {
      state.isUpdateSequenceVariationsSuccess = false;
    },

    deleteSequenceVariation: (
      state,
      _action: PayloadAction<{ uid: string }>
    ) => {
      state.isDeleteSequenceVariationsLoading = true;
    },
    deleteSequenceVariationSuccess: (
      state,
      action: PayloadAction<{ uid: string }>
    ) => {
      state.sequenceVariations = state.sequenceVariations.filter(
        (item) => item.uid !== action.payload.uid
      );
      state.isDeleteSequenceVariationsLoading = false;
      state.isDeleteSequenceVariationsSuccess = true;
      state.lastUpdatedTime = new Date();
    },
    deleteSequenceVariationFailed: (state, _action) => {
      state.isDeleteSequenceVariationsLoading = false;
    },
    resetDeleteSequenceVariation: (state, _action) => {
      state.isDeleteSequenceVariationsSuccess = false;
    },
    getTriggerCampaignList: (state, action) => {
      state.isCampaignTriggerAssetLoading = true;
    },
    getTriggerCampaignListSuccess: (state, action) => {
      state.isCampaignTriggerAssetLoading = false;
      state.campaignTrigger.campaignList = action.payload;
    },
    getTriggerCampaignListFailed: (state) => {
      state.isCampaignTriggerAssetLoading = false;
      state.campaignTrigger.campaignList = [];
    },
    getTriggerPowerDialerList: (state) => {
      state.isCampaignTriggerAssetLoading = true;
    },
    getTriggerPowerDialerListSuccess: (state, action) => {
      state.isCampaignTriggerAssetLoading = false;
      state.campaignTrigger.powerDialerList = action.payload;
    },
    getTriggerPowerDialerListFailed: (state) => {
      state.isCampaignTriggerAssetLoading = false;
      state.campaignTrigger.powerDialerList = [];
    },
    getTriggerTagList: (state, action) => {
      state.isCampaignTriggerAssetLoading = true;
    },
    getTriggerTagListSuccess: (state, action) => {
      state.isCampaignTriggerAssetLoading = false;
      state.campaignTrigger.tagList = action.payload;
    },
    getTriggerTagListFailed: (state) => {
      state.isCampaignTriggerAssetLoading = false;
      state.campaignTrigger.tagList = [];
    },
    getTriggerVirtualNumberList: (state, action) => {
      state.isCampaignTriggerAssetLoading = true;
    },
    getTriggerVirtualNumberListSuccess: (state, action) => {
      state.isCampaignTriggerAssetLoading = false;
      state.campaignTrigger.virtualNumbers = action.payload;
    },
    getTriggerVirtualNumberListFailed: (state) => {
      state.isCampaignTriggerAssetLoading = false;
      state.campaignTrigger.virtualNumbers = [];
    },
    updateCampaignBulkSettings: (state, action) => {
      state.isCampaignTriggerUpdating = true;
    },
    updateCampaignBulkSettingsSuccess: (state, action) => {
      state.isCampaignTriggerUpdating = false;
      state.isCampaignTriggerUpdatingSuccess = true;
      let settings = Object.values(CAMPAIGN_TRIGGER_SETTINGS);

      action.payload.forEach((setting) => {
        if (settings.includes(setting.settingsTitle)) {
          state.campaignSettings[setting.settingsTitle][0] = setting;
        }
      });
    },
    updateCampaignBulkSettingsFailed: (state) => {
      state.isCampaignTriggerUpdating = false;
      state.isCampaignTriggerUpdatingSuccess = false;
    },
    cleanUpdateCampaignBulkSettings: (state) => {
      state.isCampaignTriggerUpdating = false;
      state.isCampaignTriggerUpdatingSuccess = false;
    },
    updateEmailThread: (state: IEmailCampaignState, _action: PayloadAction<IEmailThreadPayload>) => {
      state.isUpdateEmailThreadLoading = true;
    },
    updateEmailThreadSuccess: (state: IEmailCampaignState, action: PayloadAction<IEmailThreadPayload>) => {
      state.isUpdateEmailThreadLoading = false;
      state.isUpdateEmailThreadSuccess = true;
      let actionPayload = { ...action.payload };
      state.campaignList.forEach((item: IEmailCampaign, i: number) => {
        if (item.uid === action.payload.campaignUid) {
          state.campaignList[i] = {
            ...state.campaignList[i],
            emailThreads: actionPayload.emailThreads,
            commonEmailSubject: actionPayload.commonEmailSubject !== undefined ? actionPayload.commonEmailSubject : null,
          };
        }
      });

      if (state.selectedCampaign) {
        state.selectedCampaign.emailThreads = actionPayload.emailThreads;
        if (actionPayload.commonEmailSubject) {
          state.selectedCampaign.commonEmailSubject = actionPayload.commonEmailSubject;

        }
      }
      state.lastUpdatedTime = new Date();
    },
    updateEmailThreadFailed: (state: IEmailCampaignState, action: PayloadAction<IEmailThreadPayload>) => {
      state.isUpdateEmailThreadLoading = false;
      state.isUpdateEmailThreadSuccess = false;
    },
    resetUpdateEmailThread: (state: IEmailCampaignState) => {
      state.isUpdateEmailThreadLoading = false;
      state.isUpdateEmailThreadSuccess = false;
    },

    updateCampaignSequenceUploadedFiles: (_state: IEmailCampaignState, _action: PayloadAction<ISequenceUploadedFileUpdatePayload>) => {

    },
    updateCampaignSequenceUploadedFilesSuccess: (state: IEmailCampaignState, action: PayloadAction<ISequenceUploadedFile[]>) => {
      const tempSequences = state.sequences ? [...state.sequences] : [];
      if (state.selectedSequence) {
        state.selectedSequence.uploadedFiles = action.payload;

        if (state.selectedSequence.index !== undefined && state.selectedSequence.index !== null) {
          tempSequences[state.selectedSequence.index] = {
            ...tempSequences[state.selectedSequence.index],
            uploadedFiles: action.payload,
          };
        }

        state.sequences = tempSequences;
      }
    },
    updateCampaignSequenceUploadedFilesFailed: (state: IEmailCampaignState, action: PayloadAction<ISequenceUploadedFile[]>) => {
      if (state.selectedSequence) {
        state.selectedSequence.uploadedFiles = action.payload.uploadedFiles;
      }
    },
    createTag: (state: IEmailCampaignState, _action: PayloadAction<ITagPayload>) => {
      state.isCreatingTag = true;
    },
    createTagSuccess: (state: IEmailCampaignState, action: PayloadAction<ITag>) => {
      state.isCreatingTag = false;
      state.isCreateTagSuccess = true;
      if (state.campaignTrigger && state.campaignTrigger.tagList) {
        state.campaignTrigger.tagList.push(action.payload);
      }
    },
    createTagFailed: (state: IEmailCampaignState) => {
      state.isCreatingTag = false;
      state.isCreateTagSuccess = false;
    },
    clearCreateTagStates: (state: IEmailCampaignState) => {
      state.isCreateTagSuccess = false;
      state.isCreatingTag = false;
    },

    getCampaignSequenceStats: (state: IEmailCampaignState, _action: PayloadAction<{ campaignSequenceUid: string; }>) => {
      state.isLoadingCampaignSequenceStats = true;
    },
    getCampaignSequenceStatsSuccess: (state: IEmailCampaignState, action: PayloadAction<ISequenceStatusItem>) => {
      state.isLoadingCampaignSequenceStats = false;
      if (state.selectedSequence) {
        state.selectedSequence.sequenceStats = action.payload;
      }
    },
    getCampaignSequenceStatsFailed: (state: IEmailCampaignState) => {
      state.isLoadingCampaignSequenceStats = false;
    },

    getEmailLookupPrice: (state: IEmailCampaignState) => {
      state.isLoadingEmailLookupPrice = true;
    },
    getEmailLookupPriceSuccess: (state: IEmailCampaignState, action: PayloadAction<IEmailLookupPrice>) => {
      state.isLoadingEmailLookupPrice = false;
      state.emailLookupPrice = action.payload;
    },
    getEmailLookupPriceFailed: (state: IEmailCampaignState) => {
      state.isLoadingEmailLookupPrice = false;
    },

    getCampaignEventAnalytics: (state: IEmailCampaignState, _action: PayloadAction<{ campaignUid: string; startDate: string; endDate: string; filterType:string; }>) => {
      state.isLoadingCampaignEventAnalytics = true;
    },
    getCampaignEventAnalyticsSuccess: (state: IEmailCampaignState, action: PayloadAction<ICampaignEventAnalytics[]>) => {
      state.isLoadingCampaignEventAnalytics = false;
      state.campaignEventAnalytics = action.payload;
    },
    getCampaignEventAnalyticsFailed: (state: IEmailCampaignState) => {
      state.isLoadingCampaignEventAnalytics = false;
    }
  },
});

export const {
  createTag,
  createTagSuccess,
  createTagFailed,
  clearCreateTagStates,

  updateCampaignBulkSettings,
  updateCampaignBulkSettingsSuccess,
  updateCampaignBulkSettingsFailed,
  cleanUpdateCampaignBulkSettings,

  getEmailCampaigns,
  getEmailCampaignsSuccess,
  getEmailCampaignsFailed,

  getEmailCampaignsByFolder,
  getEmailCampaignsByFolderSuccess,
  getEmailCampaignsByFolderFailed,

  addCampaign,
  addCampaignSuccess,
  addCampaignFailed,

  resetAddCampaign,

  updateCampaign,
  updateCampaignSuccess,
  updateCampaignFailed,
  resetUpdateCampaign,

  bulkUpdateCampaign,
  bulkUpdateCampaignSuccess,
  bulkUpdateCampaignFailed,

  bulkUnarchiveCampaign,
  bulkUnarchiveCampaignSuccess,
  bulkUnarchiveCampaignFailed,

  resetAllSelectedCampaigns,

  deleteCampaign,
  deleteCampaignSuccess,
  deleteCampaignFailed,
  resetDeleteCampaign,

  cloneCampaign,
  cloneCampaignSuccess,
  cloneCampaignFailed,
  resetCloneCampaign,

  updateCampaignBulkStatus,
  updateCampaignBulkStatusSuccess,
  updateCampaignBulkStatusFailed,
  resetUpdateCampaignBulkStatus,

  updateSearchFieldValue,

  toggleSelectedSingleCampaign,
  selectedAllCampaign,
  deselectAllCampaign,

  singleCampaignAddToFolder,
  singleCampaignAddToFolderSuccess,
  singleCampaignAddToFolderFailed,

  bulkCampaignAddToFolder,
  bulkCampaignAddToFolderSuccess,
  bulkCampaignAddToFolderFailed,

  addSequences,
  selectSequence,
  deleteSequence,
  updateEmailSubject,
  addSequenceBetween,
  updateEmailSchedule,

  getCampaignSequences,
  getCampaignSequencesSuccess,
  getCampaignSequencesFailed,

  selectCampaign,
  deselectCampaign,

  addCampaignSequence,
  addCampaignSequenceSuccess,
  addCampaignSequenceFailed,

  updateCampaignSequence,
  updateCampaignSequenceSuccess,
  updateCampaignSequenceFailed,

  updateCampaignSequenceSchedule,
  updateCampaignSequenceScheduleSuccess,
  updateCampaignSequenceScheduleFailed,
  resetUpdateCampaignSequenceSchedule,

  deleteCampaignSequence,
  deleteCampaignSequenceSuccess,
  deleteCampaignSequenceFailed,

  getCampaignSettings,
  getCampaignSettingsSuccess,
  getCampaignSettingsFailed,

  updateCampaignSettings,
  updateCampaignSettingsSuccess,
  updateCampaignSettingsFailed,
  updateCampaignSettingDetails,
  updateCampaignSettingDetailsSuccess,
  updateCampaignSettingDetailsFailed,
  getCampaignSchedules,
  getCampaignSchedulesSuccess,
  getCampaignSchedulesFailed,

  updateCampaignSchedules,
  updateCampaignSchedulesSuccess,
  updateCampaignSchedulesFailed,
  cleanUpdateCampaignSchedules,

  getCampaignEmails,
  getCampaignEmailsSuccess,
  getCampaignEmailsFailed,

  getUserConnectedEmails,
  getUserConnectedEmailsSuccess,
  getUserConnectedEmailsFailed,

  getUserEmailProvider,
  getUserEmailProviderSuccess,
  getUserEmailProviderFailed,

  addCampaignEmail,
  addCampaignEmailSuccess,
  addCampaignEmailFailed,

  deleteCampaignEmail,
  deleteCampaignEmailSuccess,
  deleteCampaignEmailFailed,

  getSequenceVariations,
  getSequenceVariationsSuccess,
  getSequenceVariationsFailed,

  addSequenceVariation,
  addSequenceVariationSuccess,
  addSequenceVariationFailed,
  resetAddSequenceVariation,

  updateSequenceVariation,
  updateSequenceVariationSuccess,
  updateSequenceVariationFailed,
  resetUpdateSequenceVariation,

  deleteSequenceVariation,
  deleteSequenceVariationSuccess,
  deleteSequenceVariationFailed,
  resetDeleteSequenceVariation,

  getTriggerCampaignList,
  getTriggerCampaignListSuccess,
  getTriggerCampaignListFailed,
  getTriggerPowerDialerList,
  getTriggerPowerDialerListSuccess,
  getTriggerPowerDialerListFailed,
  getTriggerTagList,
  getTriggerTagListSuccess,
  getTriggerTagListFailed,
  getTriggerVirtualNumberList,
  getTriggerVirtualNumberListSuccess,
  getTriggerVirtualNumberListFailed,
  updateEmailThread,
  updateEmailThreadSuccess,
  updateEmailThreadFailed,

  updateCampaignSequenceUploadedFiles,
  updateCampaignSequenceUploadedFilesSuccess,
  updateCampaignSequenceUploadedFilesFailed,
  resetUpdateEmailThread,

  getCampaignSequenceStats,
  getCampaignSequenceStatsSuccess,
  getCampaignSequenceStatsFailed,

  getEmailLookupPrice,
  getEmailLookupPriceSuccess,
  getEmailLookupPriceFailed,

  getCampaignEventAnalytics,
  getCampaignEventAnalyticsSuccess,
  getCampaignEventAnalyticsFailed
} = emailCampaignSlice.actions;

export default emailCampaignSlice.reducer;
