import {
  Button,
  Chip,
  Stack,
  TablePagination,
  Typography,
} from "convertupleads-theme";
import React, { Dispatch, SetStateAction } from "react";
import { getAccountData } from "../../../../../helpers/util/functions";

interface IProps {
  currentPage: number;
  perPage: number;
  totalPage: number;
  setPerPage: Dispatch<SetStateAction<number>>;
  setTotalPage: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalContacts: number;
  isSelectAllContact: boolean;
  setIsSelectAllContact: Dispatch<SetStateAction<boolean>>;
  isContactAvailable: boolean;
}

const AddContactPagination: React.FC<IProps> = ({
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  totalPage,
  setTotalPage,
  totalContacts,
  isSelectAllContact,
  setIsSelectAllContact,
  isContactAvailable,
}) => {
  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPerPage(+event.target.value);
  };

  return (
    <Stack direction={"row"} justifyContent={"space-between"} px={2}>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Typography variant={"caption"} color={"text.secondary"}>
          Total {totalContacts} Contacts
        </Typography>

        {(getAccountData("userId") === 1 || getAccountData("userId") === 446) &&
          !isSelectAllContact && (
            <Chip
              color="primary"
              disabled={!isContactAvailable}
              size="xSmall"
              label="Select All Contacts"
              onClick={() => setIsSelectAllContact(true)}
            />
          )}
      </Stack>

      <TablePagination
        component="div"
        count={totalContacts}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};

export default AddContactPagination;
