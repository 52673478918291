import React from 'react';
import { EditFilledIcon, ListItemButton, ListItemIcon, ListItemText, Typography } from 'convertupleads-theme';
interface IEditCampaignComponent {
    handleNewEmailCampaignModal: () => void;
}
const EditCampaignComponent: React.FC<IEditCampaignComponent> = ({ handleNewEmailCampaignModal }) => {
    return (
        <div>
            <ListItemButton onClick={handleNewEmailCampaignModal}>
                <ListItemIcon>
                    <EditFilledIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText>
                    <Typography color={'text.primary'} variant='body2' fontWeight={400}>
                        Edit Campaign
                    </Typography>
                </ListItemText>
            </ListItemButton>
        </div>
    );
};

export default EditCampaignComponent;
