import React, { useEffect, useState } from 'react';

import {
    Checkbox,
    ChevronDownIcon,
    ClockIcon,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
    useTheme,
} from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../state/features/emailCampaign/emailCampaignSelector.ts';
import { ICampaignScheduleDay } from '../../state/features/emailCampaign/emailCampaign.interface.ts';
import { updateCampaignSchedules } from '../../state/features/emailCampaign/emailCampaignSlice.ts';
import { CustomMenuItem, CustomTextField } from "../bulkEmail/BulkEmailStyle.tsx";
import { CAMPAIGN_SETTINGS, DATABASE_TIMEZONE, TIMEZONE_LIST } from '../../helpers/constant/coreConstants.ts';
import { showNotification } from '../../helpers/util/functions.tsx';

const TimingScheduleComponent: React.FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { selectedCampaign, schedules } = useSelector(emailCampaignState);
    const [startTime, setStartTime] = useState<string>('')
    const [endTime, setEndTime] = useState<string>('')

    let currentDate = new Date();

    useEffect(() => {

        if (schedules && schedules['start-timing']) {

            const time = schedules['start-timing'];
            const dateString = currentDate.toISOString().split('T')[0];
            const concatenatedDateTime = dateString + " " + time;
            let convertedTime = (window as any).globalTimezoneConversionToDifferentTimezone(concatenatedDateTime, DATABASE_TIMEZONE, getValue(CAMPAIGN_SETTINGS.TIMEZONE), 'HH:mm')
            setStartTime(convertedTime);
        }
        if (schedules && schedules['end-timing']) {

            const time = schedules['end-timing'];
            const dateString = currentDate.toISOString().split('T')[0];
            const concatenatedDateTime = dateString + " " + time;
            let convertedTime = (window as any).globalTimezoneConversionToDifferentTimezone(concatenatedDateTime, DATABASE_TIMEZONE, getValue(CAMPAIGN_SETTINGS.TIMEZONE), 'HH:mm')
            setEndTime(convertedTime);
        }
    }, [])

    const handleUpdate = (key: string, value: string | ICampaignScheduleDay[], oldValue: string | ICampaignScheduleDay[]) => {

        if (selectedCampaign) {
            try {
                if ([CAMPAIGN_SETTINGS.START_TIMING, CAMPAIGN_SETTINGS.END_TIMING].includes(key)) {
                    let currentDate = new Date();
                    let time = value;
                    let dateString = currentDate.toISOString().split('T')[0];
                    let concatenatedDateTime = dateString + " " + time;

                    if (key === CAMPAIGN_SETTINGS.START_TIMING) {
                        let endDate = dateString + " " + endTime;

                        if (endDate < concatenatedDateTime && typeof (oldValue) === 'string') {

                            let concatenatedDateTime = dateString + " " + oldValue;
                            let convertedTime = (window as any).globalTimezoneConversionToDifferentTimezone(concatenatedDateTime, DATABASE_TIMEZONE, getValue(CAMPAIGN_SETTINGS.TIMEZONE), 'HH:mm')
                            setStartTime(convertedTime);
                            showNotification('error', 'Start time can\'t be greater time of End timing')
                            return;
                        }
                    } else if (key === CAMPAIGN_SETTINGS.END_TIMING) {
                        let startDate = dateString + " " + startTime;

                        if (startDate > concatenatedDateTime && typeof (oldValue) === 'string') {
                            let concatenatedDateTime = dateString + " " + oldValue;
                            let convertedTime = (window as any).globalTimezoneConversionToDifferentTimezone(concatenatedDateTime, DATABASE_TIMEZONE, getValue(CAMPAIGN_SETTINGS.TIMEZONE), 'HH:mm')
                            setEndTime(convertedTime);
                            showNotification('error', 'End time can\'t be previous time than Start timing')
                            return;
                        }
                    }
                    let Ctime: any = (window as any).globalTimezoneConversionToDifferentTimezone(concatenatedDateTime, getValue(CAMPAIGN_SETTINGS.TIMEZONE), DATABASE_TIMEZONE, 'HH:mm');
                    value = String(Ctime);
                }
            } catch (err) {
                console.log(err);
            }

            dispatch(updateCampaignSchedules({ campaignUid: selectedCampaign.uid, keyName: key, value: value, oldValue: oldValue }));
        }
    };

    const getValue = (key: string): string => {
        if (schedules) {
            return String(schedules[key]);
        }
        return "";
    };

    const timezoneList = () => {
        const items: JSX.Element[] = [];
        for (const property in TIMEZONE_LIST) {
            if (TIMEZONE_LIST.hasOwnProperty(property)) {
                items.push(
                    <CustomMenuItem key={property} value={property}>
                        {TIMEZONE_LIST[property as keyof typeof TIMEZONE_LIST]}
                    </CustomMenuItem>
                );
            }
        }
        return items;
    };

    return (
        <Stack direction={'row'} padding={2} borderRadius={1} sx={{ border: `1px solid ${theme.palette.other.divider}` }}>
            <Stack sx={{ width: '100%' }}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <IconButton
                            sx={{
                                background: theme.palette.primary.light,
                                border: 'none',
                                '&:hover': {
                                    border: 0,
                                },
                            }}
                            variant={'roundedBorder'}
                            color={'primary'}
                        >
                            <ClockIcon />
                        </IconButton>
                        <Stack>
                            <Typography variant={'subtitle1'} color={'text.primary'} fontWeight={600}>
                                Timing
                            </Typography>
                            <Typography variant={'body2'} color={'text.secondary'}>
                                Set your desired time range for emails to be sent
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack>
                    <Divider light sx={{ marginY: '16px' }} />

                    <Stack ml={7} direction={'row'} spacing={2} width={500}>
                        <CustomTextField
                            fullWidth
                            label={'From'}
                            type={'time'}
                            value={startTime}
                            onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                                setStartTime(_e.target.value);
                                if (schedules && schedules['start-timing']) {
                                    handleUpdate(CAMPAIGN_SETTINGS.START_TIMING, _e.target.value, schedules['start-timing'])
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <ClockIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& input::-webkit-calendar-picker-indicator': {
                                    width: '82%',
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    opacity: 0,
                                    zIndex: 99,
                                },
                            }}
                        />
                        <CustomTextField
                            label={'To'}
                            type={'time'}
                            fullWidth
                            value={endTime}
                            onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                                setEndTime(_e.target.value);
                                if (schedules && schedules['end-timing']) {
                                    handleUpdate(CAMPAIGN_SETTINGS.END_TIMING, _e.target.value, schedules['end-timing'])
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <ClockIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& input::-webkit-calendar-picker-indicator': {
                                    width: '82%',
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    opacity: 0,
                                    zIndex: 99,
                                },
                            }}
                        />
                        <Stack spacing={.6}>
                            <Typography variant='body2' color={'text.secondary'} fontWeight={500}>Time Zone</Typography>
                            <FormControl sx={{ width: '160px' }}>
                                <Select
                                    value={getValue(CAMPAIGN_SETTINGS.TIMEZONE)}
                                    onChange={(_e: SelectChangeEvent<unknown>) => {
                                        handleUpdate(CAMPAIGN_SETTINGS.TIMEZONE, String(_e.target.value), getValue(CAMPAIGN_SETTINGS.TIMEZONE));
                                    }}
                                    fullWidth
                                    IconComponent={ChevronDownIcon}
                                    displayEmpty
                                >

                                    {timezoneList()}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Stack>
                    <Stack ml={7} spacing={2} mt={2}>
                        <Stack spacing={1}>
                            {/* <Typography variant={'subtitle1'} color={'text.primary'}>
                                Campaign Time Zone
                            </Typography> */}
                            <Typography variant={'body2'} color={'text.secondary'}>
                                You won’t be able to change the timezone after a contact has been added
                            </Typography>
                        </Stack>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={getValue('operate-independently') === "ON"}
                                    size='small'
                                    color='primary'
                                    sx={{ padding: 0 }}
                                />
                            }
                            label={
                                <Typography variant='body1' ml={1} color={'text.primary'}>
                                    Disregard time range above and send emails at anytime.
                                </Typography>
                            }
                            onChange={(_event, checked) => {
                                handleUpdate(CAMPAIGN_SETTINGS.OPERATE_INDEPENDENTLY, checked ? "ON" : "OFF", checked ? "OFF" : "ON");
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default TimingScheduleComponent;
