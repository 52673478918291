import React from "react";
import {Chip} from "convertupleads-theme";

const STATUS: { [key: string]: string; } = {
    ACTIVE: "#28a745",
    INACTIVE: "#6c757d",
    DISCONNECTED: "#fd7e14",
    TRASH: "#dc3545",
    FAILED: "#b21f2d",
    NOT_PROCESSED: "#e0e0e0",
    QUEUE: "#007bff",
    PROCESSING: "#17a2b8",
    PROCESSED: "#28a745",
};

interface IProps {
    status: string;
}

const StatusChip: React.FC<IProps> = ({ status }) => {
    return(
        <Chip
            size="xSmall"
            sx={{ backgroundColor: STATUS[status], color: "#fff" }}
            label={status}
        />
    );
};

export default StatusChip;