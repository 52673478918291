import {
    Badge,
    Paper,
    Stack,
    styled
} from "convertupleads-theme";

interface AlertBoxInterface {
    mapped?: boolean;
}

export const AlertBox = styled(Paper)<AlertBoxInterface>(({ theme, mapped }) => ({
    backgroundColor: mapped ? theme.palette.success.light : theme.palette.warning.light,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),
    boxShadow: "none",
}));

export const MapFieldTable = styled("table")(({ theme }) => ({
    textAlign: "left",
    maxHeight: 460,
    "& th": {
        padding: theme.spacing(2, 0),
        "& span": {
            fontSize: 12,
        },
    },
}));

interface TabLabelProps {
    title: string;
    value: number;
    isSelected: boolean;
}

export function TabLabel(props: TabLabelProps) {
    const { title, value, isSelected } = props;

    return (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <span>{title}</span>
            <Badge
                color={isSelected ? "primary" : "default"}
                badgeContent={value}
                sx={{ "& .MuiBadge-badge": { position: "relative", transform: "none" } }}
            />
        </Stack>
    );
}