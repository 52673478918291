import React, { useEffect, useState } from 'react';
import {
    AddIcon,
    Alert,
    ArchiveIcon,
    Box,
    DeleteFilledIcon,
    Divider,
    FolderFilledIcon,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ModalWithHeader,
    SidebarLeftIcon,
    SidebarRightIcon,
    Snackbar,
    Stack,
    StarFilledIcon,
    Tooltip,
    Typography,
} from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { campaignFolderState } from '../../../../state/features/campaignFolder/campaignFolderSelector';
import { getCampaignFolders, onSelectCampaignFolder } from '../../../../state/features/campaignFolder/campaignFolderSlice';
import AddOrEditFolderModal from '../../AddOrEditFolderModal';
import EachFolderItem from './EachFolderItem';
import { CustomScrollbar } from '../../../common/CustomScrollBarStyle';
import FolderSkeleton from './FolderSkeleton';
import { deselectAllCampaign, getEmailCampaigns } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { IFolder } from '../../../../state/features/campaignFolder/campaignFolder.interface';
import { CAMPAIGN_FOLDER_ORDER_DESC } from '../../../../helpers/constant/folderConstant';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';

const SideBar: React.FC = () => {
    const { campaignFolders, isLoading, isAddCampaignFolderSuccess, selectedFolder } = useSelector(campaignFolderState);
    const { query, isUserConnectedEmailsLoading } = useSelector(emailCampaignState);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [openAddFolderModal, setOpenAddFolderModal] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCampaignFolders({ page: 1, perPage: 20, order: CAMPAIGN_FOLDER_ORDER_DESC, query }));
    }, []);

    const handleSelectedFolder = (id: string) => {
        dispatch(onSelectCampaignFolder(id));
    };

    const handleModal = () => {
        setOpenAddFolderModal((prevState) => !prevState);
    };

    const handleCollapsed = () => {
        setIsSidebarCollapsed((prevState) => !prevState);
    };

    const handleShowAllCampaign = () => {
        dispatch(getEmailCampaigns({ page: 1, perPage: 10, query }));
        dispatch(deselectAllCampaign(undefined));
        handleSelectedFolder('allCampaigns');
    };

    const handleFavourite = () => {
        dispatch(getEmailCampaigns({ page: 1, perPage: 10, isFavourite: 'YES', query }));
        dispatch(deselectAllCampaign(undefined));
        handleSelectedFolder('favourite');
    };

    const handleArchive = () => {
        dispatch(getEmailCampaigns({ page: 1, perPage: 10, status: 'ARCHIVE', query }));
        dispatch(deselectAllCampaign(undefined));
        handleSelectedFolder('archive');
    };

    const handleTrash = () => {
        dispatch(getEmailCampaigns({ page: 1, perPage: 10, status: 'TRASH', query }));
        dispatch(deselectAllCampaign(undefined));
        handleSelectedFolder('trash');
    };

    const showContent = isSidebarCollapsed ? 'none' : 'block';

    const campiagnFolderData = () => {
        if (isLoading || campaignFolders === null || isUserConnectedEmailsLoading) return <FolderSkeleton />;
        else {
            return campaignFolders.map((folder: IFolder) => (
                <EachFolderItem showContent={showContent} key={folder.uid} folder={folder} selectedFolder={selectedFolder} />
            ));
        }
    }

    return (
        <Box
            width={isSidebarCollapsed ? 56 : 268}
            padding={'16px 10px'}
            position={'relative'}
            borderRadius={2}
            sx={{ height: 'calc(100vh - 180px)', transition: ' 350ms ease', background: 'white', overflow: 'hidden' }}
        >
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} padding={'0 8px'} mb={1}>
                <Typography display={showContent} variant='body2' color={'text.secondary'} fontWeight={600} minWidth={170}>
                    My Campaigns
                </Typography>
                <IconButton sx={{ padding: 0, '&:focus': { background: 'transparent'} }} color='primary' onClick={handleCollapsed}>
                    {isSidebarCollapsed ? <SidebarRightIcon /> : <SidebarLeftIcon />}
                </IconButton>
            </Stack>

            <List
                sx={{
                    '&.MuiList-root': {
                        padding: 0,
                    },
                    '& .MuiListItemButton-root ': {
                        paddingX: '8px',
                    },
                }}
                component='div'
                aria-label='main mailbox folders'
                dense
            >
                {/* All Campaigns */}
                <ListItemButton selected={selectedFolder === 'allCampaigns'} onClick={handleShowAllCampaign}>
                    <ListItemIcon>
                        <FolderFilledIcon fontSize='small' sx={{ color: 'primary.main' }} />
                    </ListItemIcon>
                    <ListItemText sx={{ display: showContent }}>
                        <Typography color={'text.primary'} variant='body2' fontWeight={600} minWidth={170}>
                            All Campaigns
                        </Typography>
                    </ListItemText>
                </ListItemButton>
                {/* Rest of the folders */}
                <CustomScrollbar sx={{ maxHeight: 'calc(100vh - 450px)', overflowX: 'hidden' }}>{campiagnFolderData()}</CustomScrollbar>

                <ListItemButton
                    onClick={handleModal}
                    sx={{
                        '&.MuiListItemButton-root ': {
                            paddingLeft: '8px !important',
                        },
                    }}
                >
                    <ListItemIcon>
                        <Tooltip title={showContent === 'none' ? ' Create New Folder' : ''}>
                            <AddIcon fontSize='small' />
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText sx={{ display: showContent }}>
                        <Typography color={'text.secondary'} variant='body2' fontWeight={600} minWidth={170}>
                            Create New Folder
                        </Typography>
                    </ListItemText>
                </ListItemButton>

                <Divider light sx={{ my: '16px' }} />

                <ListItemButton
                    onClick={handleFavourite}
                    selected={selectedFolder === 'favourite'}
                    sx={{
                        '&.MuiListItemButton-root ': {
                            paddingLeft: '8px !important',
                        },
                    }}
                >
                    <ListItemIcon>
                        <Tooltip title={showContent === 'none' ? 'Favorite' : ''}>
                            <Stack>
                                <StarFilledIcon fontSize='small' color='warning' />
                            </Stack>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText sx={{ display: showContent }}>
                        <Typography color={'text.primary'} variant='body2' fontWeight={500}>
                            Favorite
                        </Typography>
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    onClick={handleArchive}
                    selected={selectedFolder === 'archive'}
                    sx={{
                        '&.MuiListItemButton-root ': {
                            paddingLeft: '8px !important',
                        },
                    }}
                >
                    <ListItemIcon>
                        <Tooltip title={showContent === 'none' ? 'Archive' : ''}>
                            <Stack>
                                <ArchiveIcon fontSize='small' color='primary' />
                            </Stack>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText sx={{ display: showContent }}>
                        <Typography color={'text.primary'} variant='body2' fontWeight={500}>
                            Archive
                        </Typography>
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    onClick={handleTrash}
                    selected={selectedFolder === 'trash'}
                    sx={{
                        '&.MuiListItemButton-root ': {
                            paddingLeft: '8px !important',
                        },
                    }}
                >
                    <ListItemIcon>
                        <Tooltip title={showContent === 'none' ? 'Trash' : ''}>
                            <Stack>
                                <DeleteFilledIcon fontSize='small' color='error' />
                            </Stack>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText sx={{ display: showContent }}>
                        <Typography color={'text.primary'} variant='body2' fontWeight={500}>
                            Trash
                        </Typography>
                    </ListItemText>
                </ListItemButton>
            </List>

            <ModalWithHeader open={openAddFolderModal} onClose={handleModal} title='New Folder'>
                <AddOrEditFolderModal onClose={handleModal} />
            </ModalWithHeader>

            <Snackbar open={isAddCampaignFolderSuccess} autoHideDuration={6000}>
                <Alert variant='filled' severity='success' sx={{ width: '100%' }}>
                    Successfully Done !
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SideBar;

