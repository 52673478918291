import React, {useEffect, useState} from 'react';
import {Stack} from 'convertupleads-theme';
import Chart from 'react-apexcharts';
import {useSelector} from "react-redux";
import {emailCampaignState} from "../../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import {ICampaignEventAnalytics} from "../../../../../state/features/emailCampaign/emailCampaign.interface.ts";
import { ApexOptions } from 'apexcharts'; 

interface IProps {
  legendPosition: "top" | "right" | "bottom" | "left";
}



const formatDate = (dateString: string): string => {
    const months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const parts: string[] = dateString.split("-");
    const year: number = parseInt(parts[0], 10);
    const month: number = parseInt(parts[1], 10);
    const day: number = parseInt(parts[2], 10);

    return `${day < 10 ? '0' + day : day} ${months[month - 1]}`;
};

const AnalyticsGraph: React.FC<IProps> = ({ legendPosition }) => {
    const { campaignEventAnalytics } = useSelector(emailCampaignState);
    const [categories, setCategories] = useState([]);
    const [emailOpenCount, setEmailOpenCount] = useState([]);
    const [emailSentCount, setEmailSentCount] = useState([]);
    const [emailFailedCount, setEmailFailedCount] = useState([]);

    useEffect(() => {
        if (campaignEventAnalytics){
            setCategories(campaignEventAnalytics.map((item: ICampaignEventAnalytics)=>(formatDate(item.date))));
            setEmailOpenCount(campaignEventAnalytics.map((item: ICampaignEventAnalytics)=>(item.totalOpen)));
            setEmailSentCount(campaignEventAnalytics.map((item: ICampaignEventAnalytics)=>(item.totalSend)));
            setEmailFailedCount(campaignEventAnalytics.map((item: ICampaignEventAnalytics)=>(item.totalFailed)));
        }
    }, [campaignEventAnalytics]);

    const options:ApexOptions = {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          }
        },
        tooltip: {
            enabled: false,
          },
          legend: {
            show: true,
            position: legendPosition,
        },
        dataLabels: {
            enabled: false,
          },
          fill: {
            type: 'gradient'
        },
        colors: ['rgba(114, 57, 234, 0.5)', 'rgba(249, 204, 164, 0.8)', 'rgba(229, 55, 55, 0.7)'],
        xaxis: {
             categories: categories
          }
      };

    const series = [
        {
          name: 'Email Open',
          data: emailOpenCount
        },
        {
          name: 'Email Sent',
            data: emailSentCount
        },
        {
          name: 'Email Failed',
          data: emailFailedCount
        },
      ];
  return (
    <Stack p={3}>
        <Chart
            options={options}
            series={series}
            type="area"
            width="100%"
            height={'300px'}
        />
    </Stack>
  );
};

export default AnalyticsGraph;