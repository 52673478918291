import {Checkbox, MenuItem, Radio, TextField, styled, keyframes, RefreshIcon} from "convertupleads-theme";

export const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        // gap: 15,
        "&.MuiInputBase-adornedStart .MuiInputBase-input": {
            marginLeft: "15px !important",
        },

        "&.MuiInputBase-inputAdornedEnd .MuiInputBase-input": {
            marginRight: "15px !important",
        },
    },
    "& .MuiInputBase-input": {
        height: "unset !important",
        margin: "unset !important",
        borderBottom: "unset !important",
        paddingBlock: "10px !important",
        paddingLeft: "10px !important",
        "&:focus": {
            boxShadow: "none !important",
        },
    },
});

export const CustomRadio = styled(Radio)({
    "& .PrivateSwitchBase-input": {
        position: "static",
        opacity: "unset",
        pointerEvents: "auto",
        "& +span": { display: "none" },
    },
});
export const CustomCheckbox = styled(Checkbox)({
    "& .PrivateSwitchBase-input": {
        position: "static",
        opacity: "unset",
        pointerEvents: "auto",
        "& +span": { content: "unset !important" },
    },
});

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    '&.Mui-selected, &.Mui-selected:hover': {
    //   backgroundColor: '#cbd4ff!important',
      backgroundColor: theme.palette.primary.light,
    },
    ':hover': {
        backgroundColor: theme.palette.primary.light,
    }
}));

const MenuProps = {
PaperProps: {
    style: {
        paddingLeft: '8px',

        '& .MuiMenuItem-root': {
            color: 'gold !important'
        }
    },
},
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RotatingIcon = styled(RefreshIcon)(({ theme }) => ({
    animation: `${rotate} 2s linear infinite`,
}));