import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  CopyIcon,
  Divider,
  InputAdornment,
  LoadingButton,
  ModalWithHeader,
  Stack,
} from "convertupleads-theme";
import { CustomTextField } from "../../BulkEmailStyle";
import { IEmailCampaign } from "../../../../state/features/emailCampaign/emailCampaign.interface";
import emailCampaignService from "../../../../services/emailCampaign.service";
import { showNotification } from "../../../../helpers/util/functions";

interface IShareModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  campaign: IEmailCampaign;

  onClose: () => void;
}

const ShareModal: React.FC<IShareModal> = ({
  campaign,
  open,
  setOpen,
  onClose,
}) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const shareUrl = `${window.location.origin}/bulk-email/copy/${campaign?.uid}`;

  const handleCopy = () => {
    const copyText = document.getElementById(
      "linkTextField"
    ) as HTMLInputElement;

    if (copyText) {
      copyText.select();
      document.execCommand("copy");
    }
  };

  const handleText = (type: string, value: string) => {
    if (type === "emailAddress") {
      setEmailAddress(value);
      setEmailAddressError("");
    } else {
      setEmailBody(value);
    }
  };

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const shareEmailCampaign = async () => {
    if (campaign && campaign.uid) {
      setIsLoading(true);

      try {
        let response = await emailCampaignService.shareEmailCampaign({
          campaignUid: campaign.uid,
          email: emailAddress,
          body: emailBody,
          url: shareUrl,
        });

        await delay(1000); 
        if (response.success) {
          setOpen(false);
          setIsLoading(false);
          showNotification(
            "success",
            response.message ?? "Campaign Shared Successfully"
          );
          onClose();
        } else {
          if (response.message === "Validation-Error" && response.data) {
            if (response.data.url && response.data.url[0]) {
              showNotification("error", response.data.url[0]);
            }
            if (response.data.email && response.data.email[0]) {
              showNotification("error", response.data.email[0]);
            }
            if (response.data.body && response.data.body[0]) {
              showNotification("error", response.data.body[0]);
            }
            if (response.data.campaignUid && response.data.campaignuid[0]) {
              showNotification("error", "Something went wrong!");
            }
          } else {
            showNotification("error", response.message);
          }
        }
      } catch (error) {
        console.error("Error occurred:", error);
        showNotification(
          "error",
          "An error occurred while sharing the campaign."
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = () => {
    // setOpen(false);
    // onClose();
    const trimmedEmailAddress = emailAddress.trim();
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!trimmedEmailAddress || !regex.test(trimmedEmailAddress)) {
      setEmailAddressError(
        !trimmedEmailAddress
          ? "Email address is required"
          : "Email address is invalid"
      );
      return;
    }

    shareEmailCampaign();
  };

  return (
    <ModalWithHeader open={open} onClose={handleClose} title="Share Campaign">
      <Stack sx={{ overflowY: "visible" }}>
        <Stack padding={3} spacing={5}>
          <CustomTextField
            id="linkTextField"
            value={shareUrl}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    "& svg": { m: 0, mr: 0.5 },
                    cursor: "pointer",
                  }}
                  onClick={handleCopy}
                >
                  <p style={{ display: "inline-flex" }}>
                    <CopyIcon fontSize={"small"} />
                    Copy
                  </p>
                </InputAdornment>
              ),
            }}
            label={"Option 1: Share the link below with another user"}
          />

          <Stack spacing={2}>
            <CustomTextField
              value={emailAddress}
              label={
                "Option 2: Share your campaign via email. Add the recipients email address"
              }
              placeholder={"Email"}
              onChange={(event) =>
                handleText("emailAddress", event?.target.value as string)
              }
              type="email"
              required
              error={!!emailAddressError}
              helperText={emailAddressError}
            />

            <CustomTextField
              sx={{ "& .MuiInputBase-input": { padding: "0 !important" } }}
              value={emailBody}
              placeholder={
                "Type a message to include with your email here (optional)"
              }
              multiline
              rows={4}
              onChange={(event) =>
                handleText("emailBody", event?.target.value as string)
              }
            />
          </Stack>
        </Stack>

        <Divider light />
        <Stack
          direction={"row"}
          justifyContent={"end"}
          spacing={1}
          paddingX={3}
          paddingY={2}
        >
          <Button
            variant={"tonal"}
            onClick={handleClose}
            color={"inherit"}
            size="large"
            sx={{ ":focus": { bgcolor: "transparent" } }}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={isLoading}
            variant={"contained"}
            size="large"
            onClick={handleSubmit}
            sx={{
              "& .MuiButton-startIcon": { height: 20, width: 20 },
              "&:focus": { bgcolor: "primary.main" },
            }}
          >
            Share Campaign
          </LoadingButton>
        </Stack>
      </Stack>
    </ModalWithHeader>
  );
};

export default ShareModal;
