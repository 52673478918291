import { ListItemButton, ListItemIcon, ListItemText, StarFilledIcon, Typography } from 'convertupleads-theme';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateCampaign } from '../../../../../state/features/emailCampaign/emailCampaignSlice';
import { IEmailCampaign } from '../../../../../state/features/emailCampaign/emailCampaign.interface';

interface IFavouriteComponent {
    onClose?: () => void;
    campaign: IEmailCampaign;
}

const FavouriteComponent: React.FC<IFavouriteComponent> = ({ campaign }) => {
    const dispatch = useDispatch();

    const handleFavourite = () => {
        dispatch(updateCampaign({
            uid: campaign.uid, isFavourite: campaign?.isFavourite === 'YES' ? 'NO' : 'YES',
            oldValue: campaign?.isFavourite
        }));
    };

    return (
        <div>
            <ListItemButton onClick={handleFavourite}>
                <ListItemIcon>
                    <StarFilledIcon sx={{ fontSize: '20px' }} color={campaign?.isFavourite === 'YES' ? 'warning' : ''} />
                </ListItemIcon>
                <ListItemText>
                    <Typography color={'text.primary'} variant='body2' fontWeight={400}>
                        {campaign?.isFavourite === 'YES' ? 'Remove Favorite' : 'Favorite'}
                    </Typography>
                </ListItemText>
            </ListItemButton>
        </div>
    );
};

export default FavouriteComponent;
