import React, { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { IMapData, IMapping } from "./connect/mapping/Mapping.tsx";
import { CONNECTED_FILE_STEPS } from "../../../../../helpers/constant/coreConstants.ts";
import { showNotification } from "../../../../../helpers/util/functions.tsx";
import emailCampaignService from "../../../../../services/emailCampaign.service.ts";

interface ImportSpreadsheetContextType {
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const ImportSpreadsheetContext = createContext<ImportSpreadsheetContextType | undefined>(undefined);

interface ImportSpreadsheetContextProviderProps {
    children: ReactNode;
}

interface IAdditionalInfos {
    tag_ids?: number[];
    stage_id?: number;
    pipeline_id?: number;
    source_id?: number;
    lookup_type?: any[];
    search_category?: any[];
    ignore_first_row: boolean;
}

const ImportSpreadsheetContextProvider: React.FC<ImportSpreadsheetContextProviderProps> = ({ children }) => {
    const [screen, setScreen] = useState<number>(CONNECTED_FILE_STEPS.CONNECTED_FILE_LIST);
    const [activeImportStep, setActiveImportStep] = useState<number>(0);
    const [valuesForSubmit, setValuesForSubmit] = useState<IMapData[]>([] as IMapData[]);
    const [valuesForSelect, setValuesForSelect] = useState<IMapData[]>([] as IMapData[]);
    const [mappedRows, setMappedRows] = useState<IMapping[]>([] as IMapping[]);
    const [unMappedRows, setUnMappedRows] = useState<IMapping[]>([] as IMapping[]);
    const [email, setEmail] = useState('');
    const [link, setLink] = useState('');
    const [fileName, setFileName] = useState('');
    const [worksheet, setWorksheet] = useState('');
    const [worksheets, setWorksheets] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [stages, setStages] = useState([]);
    const [tags, setTags] = useState([]);
    const [leadSources, setLeadSources] = useState([]);
    const [selectedConnectFile, setSelectedConnectFile] = useState(null);
    const [additionalInfos, setAdditionalInfos] = useState<IAdditionalInfos>({
        ignore_first_row: false,
    });
    const [mappingColumns, setMappingColumns] = useState([
        {
            value: "first_name",
            label: "First Name",

        },
        {
            value: "last_name",
            label: "Last Name",
        },
        {
            value: "number",
            label: "Number",
        },
        {
            value: "email",
            label: "Email",
        },
        {
            value: "address",
            label: "Address",
        },
        {
            value: "city",
            label: "City",
        },
        {
            value: "state",
            label: "State",
        },
        {
            value: "zip",
            label: "Zip",
        },
        {
            value: "country",
            label: "Country",
        },
        {
            value: "url",
            label: "URL",
        },
        {
            value: "company_name",
            label: "Company Name",
        },
        {
            value: "birth_date",
            label: "Birth Date",
        },
        {
            value: "anniversary_date",
            label: "Anniversary Date",
        },
    ]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const customFieldsResp = await emailCampaignService.getContactCustomFields();
            if (customFieldsResp?.success && customFieldsResp?.data && Array.isArray(customFieldsResp.data)) {
                let data = customFieldsResp.data;

                let newCFields = [...mappingColumns];
                data.forEach((item: any) => {
                    newCFields.push({
                        label: item.title,
                        value: `custom_${item.id}`
                    });
                });
                setMappingColumns(newCFields);
            }
        } catch (err) {
            console.log(err)
        }

        try {
            const tagsResp = await emailCampaignService.getTriggerTagList();
            if (tagsResp?.success) {
                setTags(tagsResp.data);
            }
        } catch (err) {
            console.log(err);
        }

        try {
            const sourcesResp = await emailCampaignService.getContactSources();
            if (sourcesResp?.success) {
                setLeadSources(sourcesResp.data);
            }
        } catch (err) {
            console.log(err);
        }

        try {
            const pipelinesResp = await emailCampaignService.getContactPipelines();
            if (pipelinesResp?.success) {
                setPipelines(pipelinesResp.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const stepList = [
        {
            label: "Connect",
        },
        {
            label: "Configure",
        },
        {
            label: "Mapping",
        },
        {
            label: "Details",
        },
    ];

    const findSelectedValue = (index: number): string => {
        return valuesForSelect[index]?.data as string;
    };

    const getLabel = (value: string) => {
        try {
            return mappingColumns.find((mappingColumn) => (mappingColumn.value === value))?.label || 'this field';
        } catch (err) {
            return 'this field';
        }
    };

    const handleMapColumn = (value: string, index: number) => {
        let currentValue = valuesForSelect[index].data;

        if (value !== currentValue) {
            const oldValuesForSubmit = [...valuesForSubmit];
            const oldValuesForSelect = [...valuesForSelect];

            if (value && value.split('_')[0] === "custom") {
                oldValuesForSelect[index] = { type: 'custom', map: index, data: value as string };
            } else {
                oldValuesForSelect[index] = { type: 'contact', map: index, data: value as string };
            }

            oldValuesForSelect[index] = { data: value as string };
            const selectionCount = oldValuesForSelect.filter((field) => (field.data === value));

            if (selectionCount.length > 1 && value !== "email" && value !== "number" && value !== null && value !== undefined && value !== "") {
                showNotification("error", `Duplicate entry for ${getLabel(value)} is not allowed.`);
                return;
            }

            if (valuesForSubmit[index].data == null) {
                const filterUnmap = unMappedRows?.filter((item) => item.index != index);
                const filterMap = unMappedRows?.find((item) => item.index === index);
                setUnMappedRows(filterUnmap);
                setMappedRows((prevData) => [...prevData, filterMap as IMapping]);
            }

            if (value && value.split('_')[0] === "custom") {
                oldValuesForSubmit[index] = { type: 'custom', map: index, data: value.replace("custom_", "") as string };
            } else {
                oldValuesForSubmit[index] = { type: 'contact', map: index, data: value as string };
            }

            setValuesForSubmit(oldValuesForSubmit);
            setValuesForSelect(oldValuesForSelect);
        }
    };

    const handleGoNextStep = () => {
        setActiveImportStep((prevActiveImportStep) => prevActiveImportStep + 1);
    };

    const handleGoPreviousStep = () => {
        setActiveImportStep((prevActiveImportStep) => prevActiveImportStep - 1);
    };

    const goToStep = (step: number) => {
        setActiveImportStep(step);
    };

    const changeScreen = (mScreen: number) => {
        setScreen(mScreen);
    };

    const extractFileId = (url: string) => {
        const regex = /\/d\/([a-zA-Z0-9_-]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const resetImportData = () => {
        setLink('');
        setEmail('');
        setFileName('');
        setWorksheet('');
        setWorksheets([]);
        setMappedRows([]);
        setUnMappedRows([]);
        setValuesForSubmit([]);
        setAdditionalInfos({
            ignore_first_row: false,
        });
    };

    const changeAdditionalInfos = (newState: IAdditionalInfos) => {
        setAdditionalInfos((prevState) => ({ ...prevState, ...newState }));
    };

    const handleSelectPipeline = async (pipeline_id: number) => {
        setAdditionalInfos((prevState) => ({ ...prevState, pipeline_id: pipeline_id, stage_id: undefined }));
        setStages([]);
        try {
            const pipelineResp = await emailCampaignService.getContactPipelineStages({ pipelineId: pipeline_id });
            if (pipelineResp?.success) {
                setStages(pipelineResp.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const reFormat = (data: any, type: string) => {
        const result = {} as any;

        for (const key in data) {
            const dataType = data[key].data;
            const keyType = data[key].type;

            if (dataType && keyType === type) {
                if (dataType === 'email' || dataType === 'number') {
                    if (!result[dataType]) {
                        result[dataType] = [];
                    }
                    result[dataType].push(parseInt(key));
                } else {
                    result[dataType] = parseInt(key);
                }
            }
        }

        return result;
    };

    return (
        <ImportSpreadsheetContext.Provider
            value={{
                fileName,
                setFileName,
                stepList,
                activeImportStep,
                handleGoNextStep,
                handleGoPreviousStep,
                screen,
                changeScreen,
                mappedRows,
                unMappedRows,
                valuesForSubmit,
                setMappedRows,
                setUnMappedRows,
                setValuesForSubmit,
                findSelectedValue,
                handleMapColumn,
                email,
                setEmail,
                link,
                setLink,
                extractFileId,
                worksheet,
                setWorksheet,
                worksheets,
                setWorksheets,
                goToStep,
                resetImportData,
                mappingColumns,
                setMappingColumns,
                setValuesForSelect,
                additionalInfos,
                changeAdditionalInfos,
                tags,
                setTags,
                leadSources,
                pipelines,
                stages,
                handleSelectPipeline,
                selectedConnectFile,
                setSelectedConnectFile,
                reFormat
            }}
        >
            {children}
        </ImportSpreadsheetContext.Provider>
    );
};

export const useImportSpreadsheetContext = (): ImportSpreadsheetContextType => {
    const context = useContext(ImportSpreadsheetContext);

    if (!context) {
        throw new Error("useImportSpreadsheetContext must be used within a ImportSpreadsheetContextProvider");
    }

    return context;
};

export const withImportSpreadsheetContext = <P extends object>(Component: React.ComponentType<P>) => {
    const WithImportSpreadsheetContext: React.FC<P> = (props) => (
        <ImportSpreadsheetContextProvider>
            <Component {...props} />
        </ImportSpreadsheetContextProvider>
    );

    return WithImportSpreadsheetContext;
};