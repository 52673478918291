import React from 'react';
import { Stack } from 'convertupleads-theme';
import CustomTimeline from './CustomTimeLine';

const ActivityLog: React.FC = () => {
    return (
        <Stack spacing={5}>
            <Stack spacing={1}>
                <CustomTimeline  />
            </Stack>
        </Stack>
       
    );
};

export default ActivityLog;
