import { Box, Button, ContentPasteOutlinedIcon, Divider, DrawerWithHeader, Modal, Paper, Stack, Typography, WarningFilledIcon } from 'convertupleads-theme';
import React, { useState } from 'react';
import TriggersModal from './TriggersModal';
import CustomSettingHeader from './CustomSettingHeader';
import {useSelector} from "react-redux";
import {emailCampaignState} from "../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import { TRACK_SETTINGS_STATUS } from '../../../../helpers/constant/coreConstants.ts';
import { PendingActionsIcon } from '../../../../assets/Icons.tsx';

const Triggers: React.FC = () => {
    const { selectedCampaign, campaignSettings } = useSelector(emailCampaignState);
    const [openTrigger, setOpenTrigger] = useState(false);
    const [modal, setModal] = useState(false);

    const handleToggle = () => {
        setOpenTrigger((prevState) => !prevState);
    };

    const trackSettings = campaignSettings?.['TRACKING_SETTINGS'][0]?.settingsDetails;

    const isTrackLinkClickActive = trackSettings?.TRACK_LINK_CLICK === TRACK_SETTINGS_STATUS.ACTIVE;
    const isTrackOpenEmailActive = trackSettings?.TRACK_OPEN_EMAIL === TRACK_SETTINGS_STATUS.ACTIVE;
    const isTrackReplyEmailActive = trackSettings?.TRACK_REPLY_EMAIL === TRACK_SETTINGS_STATUS.ACTIVE;
    
    const triggerHandler = () => {
        if (isTrackLinkClickActive && isTrackOpenEmailActive && isTrackReplyEmailActive) {
            setOpenTrigger(true);
        } else {
            setModal(true);
        }
    }
    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: '0px 20px', mt: 2.5 }}>
            <Box width={960}>
                <CustomSettingHeader
                    title={'Triggers'}
                    subTitle={'Set triggers to automate specific actions'}
                    icon={<PendingActionsIcon width={22.5} height={22.5} />}
                    isAccordionOpen={false}
                    handleToggle={triggerHandler}
                    isShowButton={true}
                />
            </Box>
            <DrawerWithHeader
                anchor={'right'}
                width={850}
                open={openTrigger}
                onClose={() => setOpenTrigger(false)}
                header={'Trigger Actions'}
                subheader={selectedCampaign?.title}
            >
                <TriggersModal handleToggle={handleToggle} />
            </DrawerWithHeader>

            <Modal width={560} open={modal} onClose={() => setModal(false)}>
                <Stack>
                    <Stack direction={"column"} alignItems={"center"}  px={3} py={2}>
                        <Stack direction={"row"} justifyContent={"center"} padding={2} paddingBottom={0}>
                            <WarningFilledIcon sx={{ fontSize: "60px", color: "orange" }} />
                        </Stack>
                        <Typography variant={"h5"} textAlign={"center"}>
                            Action Required
                        </Typography>

                        <Typography mt={1} mb={1} textAlign={"center"}>
                            Please setup Tracking Settings
                        </Typography>
                    </Stack>

                    <Stack width={"100%"} mt={3}>
                        <Divider light />
                        <Stack padding={2} direction={"row"} justifyContent={"flex-end"} gap={1}>
                            <Button variant={"tonal"} color={"inherit"} size={"large"} onClick={() => setModal(false)}>
                                    Cancel
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Modal>
        </Paper>
    );
};

export default Triggers;
