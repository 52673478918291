import React, { useEffect, useState } from 'react';
import {
    AddIcon,
    CalenderIcon,
    ClockIcon,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    RemoveFilledIcon,
    Stack,
    Typography,
    useTheme
} from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { CAMPAIGN_SETTINGS, DATABASE_TIMEZONE, EMAIL_SCHEDULE_TYPE } from '../../../../helpers/constant/coreConstants';
import { ICampaignSequenceSchedulePayload } from '../../../../state/features/emailCampaign/emailCampaign.interface.ts';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector.ts';
import { updateCampaignSequenceSchedule } from '../../../../state/features/emailCampaign/emailCampaignSlice.ts';
import { CustomRadio, CustomTextField } from "../../../bulkEmail/BulkEmailStyle.tsx";
import CustomFooter from '../../../common/CustomFooter';

interface Props {
    onClose: () => void;
}

const LaterDayElement: React.FC<Props> = ({ onClose }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { selectedSequence, schedules } = useSelector(emailCampaignState);
    const [sendOption, setSendOption] = useState(EMAIL_SCHEDULE_TYPE.SCHEDULE);
    const [days, setDays] = useState(1);
    const [time, setTime] = useState<string | undefined>('00:00');

    const currentDate = new Date();
    const dateString = currentDate.toISOString().split('T')[0];
    const [date, setDate] = useState<string | undefined>(dateString);

    const getValue = (key: string): string => {
        if (schedules) {
            return String(schedules[key]);
        }
        return "";
    };

    useEffect(() => {
        if (selectedSequence) {
            if (selectedSequence.type === EMAIL_SCHEDULE_TYPE.SCHEDULE) {
                if (selectedSequence.day === 0) {
                    setDays(1);
                } else {
                    setDays(selectedSequence.day);
                }
                if (selectedSequence.hour) {
                    let fullDate = dateString + " " + selectedSequence.hour;
                    let convertedTime = (window as any).globalTimezoneConversionToDifferentTimezone(fullDate, DATABASE_TIMEZONE, getValue(CAMPAIGN_SETTINGS.TIMEZONE), 'HH:mm')

                    setTime(convertedTime);
                }
                setSendOption(selectedSequence.type);
            } else if (selectedSequence.type === EMAIL_SCHEDULE_TYPE.FIXED_DATE) {
                if (selectedSequence.fixedDate) {

                    let date = (window as any).globalTimezoneConversionToDifferentTimezone(selectedSequence.fixedDate, DATABASE_TIMEZONE, getValue(CAMPAIGN_SETTINGS.TIMEZONE), 'yyyy-MM-DD');
                    let time = (window as any).globalTimezoneConversionToDifferentTimezone(selectedSequence.fixedDate, DATABASE_TIMEZONE, getValue(CAMPAIGN_SETTINGS.TIMEZONE), 'HH:mm');

                    setDate(date);
                    setTime(time);
                }
                setSendOption(selectedSequence.type);
            }
        }
    }, []);

    const handleSendOption = (option: string) => {
        setSendOption(option);
    };

    const handleTimes = (time: string) => {
        setTime(time);
    };

    const handleCustomDate = (date: string) => {
        setDate(date);
    };

    const handleSubmit = () => {
        if (selectedSequence) {
            const payload: ICampaignSequenceSchedulePayload = {
                type: sendOption,
                index: selectedSequence.index,
                uid: selectedSequence.uid
            };

            if (sendOption === EMAIL_SCHEDULE_TYPE.FIXED_DATE) {
                let fixed = date + " " + time;
                payload.fixedDate = (window as any).globalTimezoneConversionToDifferentTimezone(fixed, getValue(CAMPAIGN_SETTINGS.TIMEZONE), DATABASE_TIMEZONE, 'yyyy-MM-DD HH:mm');
                payload.hour = (window as any).globalTimezoneConversionToDifferentTimezone(fixed, getValue(CAMPAIGN_SETTINGS.TIMEZONE), DATABASE_TIMEZONE, 'HH:mm');;

                payload.day = 0;
            } else {
                let fullDate = dateString + " " + time;
                let convertedTime = (window as any).globalTimezoneConversionToDifferentTimezone(fullDate, getValue(CAMPAIGN_SETTINGS.TIMEZONE), DATABASE_TIMEZONE, 'HH:mm')

                payload.hour = convertedTime;
                payload.day = days;
            }

            dispatch(updateCampaignSequenceSchedule(payload));
            onClose();
        }
    };

    return (
        <>
            <Stack paddingX={3}>
                <Stack direction={'row'} mb={1}>
                    <FormControlLabel
                        value='small'
                        checked={sendOption === EMAIL_SCHEDULE_TYPE.SCHEDULE}
                        onClick={() => handleSendOption(EMAIL_SCHEDULE_TYPE.SCHEDULE)}
                        control={<CustomRadio color='primary' size='small' />}
                        label={
                            <Typography variant='body2' color={'text.primary'}>
                                A few days later
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value='small'
                        checked={sendOption === EMAIL_SCHEDULE_TYPE.FIXED_DATE}
                        onClick={() => handleSendOption(EMAIL_SCHEDULE_TYPE.FIXED_DATE)}
                        control={<CustomRadio color='primary' size='small' />}
                        label={
                            <Typography variant='body2' color={'text.primary'}>
                                Custom Date
                            </Typography>
                        }
                    />
                </Stack>

                {sendOption === EMAIL_SCHEDULE_TYPE.SCHEDULE && (
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Stack
                            direction={'row'}
                            borderRadius={1}
                            border={`2px solid ${theme.palette.other.divider}`}
                            width={'100%'}
                            height={40}
                        >
                            <IconButton
                                variant={'rounded'}
                                sx={{ width: '40px', '&:focus': { background: 'transparent' } }}
                                onClick={() => {
                                    if (days > 1) setDays(days - 1);
                                }}
                            >
                                <RemoveFilledIcon sx={{ fontSize: '16px' }} />
                            </IconButton>

                            <Divider orientation='vertical' />

                            <CustomTextField
                                id='filled-basic'
                                value={days}
                                sx={{
                                    borderRadius: 0,
                                    textAlign: 'center',
                                    '& .MuiFilledInput-root ': {
                                        borderRadius: 0,
                                    },
                                    '&.MuiTextField-root input': {
                                        textAlign: 'center',
                                    },
                                    '& .MuiInputBase-input': {
                                        paddingBlock: '8px !important',
                                        paddingLeft: '0 !important',
                                    }
                                }}
                                variant='filled'
                            />

                            <Divider orientation='vertical' />

                            <IconButton
                                variant={'rounded'}
                                sx={{ width: '40px', '&:focus': { background: 'transparent' } }}
                                onClick={() => setDays(days + 1)}
                            >
                                <AddIcon sx={{ fontSize: '16px' }} />
                            </IconButton>
                        </Stack>

                        <Typography variant='body2' whiteSpace={'nowrap'} color={'text.primary'} fontWeight={600}>
                            day(s) later at{' '}
                        </Typography>

                        <CustomTextField
                            fullWidth
                            value={time}
                            onChange={(e) => handleTimes(e.target.value)}
                            type={'time'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <ClockIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& input::-webkit-calendar-picker-indicator': {
                                    width: '200px',
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    opacity: 0,
                                    zIndex: 99,
                                },
                            }}
                        />
                    </Stack>
                )}
                {sendOption === EMAIL_SCHEDULE_TYPE.FIXED_DATE && (
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <CustomTextField
                            fullWidth
                            value={date}
                            onChange={(e) => handleCustomDate(e.target.value)}
                            label={'Select Date'}
                            type={'date'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <CalenderIcon fontSize={'small'} />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& input::-webkit-calendar-picker-indicator': {
                                    width: '82%',
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    opacity: 0,
                                    zIndex: 99,
                                },
                            }}
                        />

                        <CustomTextField
                            fullWidth
                            value={time}
                            onChange={(e) => handleTimes(e.target.value)}
                            label='Select Time'
                            type={'time'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <ClockIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& input::-webkit-calendar-picker-indicator': {
                                    width: '100%',
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    opacity: 0,
                                    zIndex: 99,
                                },
                            }}
                        />
                    </Stack>
                )}
            </Stack>
            <CustomFooter onSubmit={handleSubmit} onClose={onClose} />
        </>
    );
};

export default LaterDayElement;
