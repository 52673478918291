import React, { ReactNode } from 'react';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    Collapse, CustomSwitch,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    Typography,
    useTheme,
} from 'convertupleads-theme';

interface CustomSchedule {
    title?: string;
    subtitle?: string;
    icon?: React.ReactNode;
    children?: ReactNode;
    onToggle: () => void;
    state: boolean;
}

const CustomScheduleComponent: React.FC<CustomSchedule> = ({ title, subtitle, icon, children, onToggle, state }) => {
    const theme = useTheme();

    return (
        <div>
            <Stack
                direction={"row"}
                padding={2}
                borderRadius={1}
                sx={{ border: `1px solid ${theme.palette.other.divider}` }}
            >
                <Stack sx={{ width: "100%" }}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                            <IconButton
                                sx={{
                                    background: theme.palette.primary.light,
                                    border: "none",
                                    "&:hover": {
                                        border: 0,
                                    },
                                }}
                                variant={"roundedBorder"}
                                color={"primary"}
                            >
                                {icon}
                            </IconButton>
                            <Stack>
                                <Typography variant={"subtitle1"} color={"text.primary"} fontWeight={600}>
                                    {title}
                                </Typography>
                                <Typography variant={"body2"} color={"text.secondary"} width={'85%'}>
                                    {subtitle}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                            {/* <FormControlLabel
                control={
                  <Switch
                    variant={"onOff"}
                    color={"primary"}
                    checked={state}
                    onClick={onToggle}
                  />
                }
                label=""
              /> */}

                            <FormControlLabel
                                control={
                                    <CustomSwitch
                                        color={"primary.main"}
                                        checked={state}
                                        activeText={"On"}
                                        inactiveText={"Off"}
                                        onChange={onToggle}
                                    />
                                }
                                label=""
                            />

                            {state ? (
                                <ChevronUpIcon cursor={"pointer"} onClick={onToggle} />
                            ) : (
                                <ChevronDownIcon cursor={"pointer"} onClick={onToggle} />
                            )}
                        </Stack>
                    </Stack>

                    <Collapse orientation="vertical" in={state}>
                        {children}
                    </Collapse>
                </Stack>
            </Stack>
        </div>
    );
};

export default CustomScheduleComponent;
