import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "convertupleads-theme";
import { useSelector } from "react-redux";
import {
  USER_ROLE_ADMIN,
  USER_ROLE_AGENT,
  USER_ROLE_OWNER,
} from "../../../../../helpers/constant/coreConstants";
import { getAccountData, showNotification } from "../../../../../helpers/util/functions";
import useDebounce from "../../../../../hooks/useDebounce";
import emailCampaignService from "../../../../../services/emailCampaign.service";
import { IContact } from "../../../../../state/features/emailCampaign/emailCampaign.interface";
import { emailCampaignState } from "../../../../../state/features/emailCampaign/emailCampaignSelector";
import { CustomScrollbar } from "../../../../common/CustomScrollBarStyle";
import EmptyComponent from "../../../emptyComponent/EmptyComponent";
import AddContactTableTableSkeleton from "./AddContactTableTableSkeleton";
import AddTagsDrawer from "./drawer/AddTagsDrawer";
import EachTableItem from "./EachTableItem";

interface IProps {
  currentPage: number;
  perPage: number;
  searchValue: string;
  setTotalPage: Dispatch<SetStateAction<number>>;
  setTotalContacts: Dispatch<SetStateAction<number>>;
  selectedContacts: string[];
  onSelectContacts: Dispatch<SetStateAction<string[]>>;
  filteredValue: any;
  isSelectAllContact: boolean;
  setIsSelectAllContact: Dispatch<SetStateAction<boolean>>;
  setIsContactAvailable: Dispatch<SetStateAction<boolean>>;
}

const ELASTIC_STATUS = 
  process.env.REACT_APP_BULK_EMAIL_ADD_CONTACT_LIST_ELASTIC_STATUS === "TRUE"
    ? true
    : false;

const AddContactTable: React.FC<IProps> = ({
  currentPage,
  perPage,
  setTotalPage,
  searchValue,
  setTotalContacts,
  selectedContacts,
  onSelectContacts,
  filteredValue,
  isSelectAllContact,
  setIsSelectAllContact,
  setIsContactAvailable
}) => {
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [elasticContacts, setElasticContacts] = useState<IContact[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tagsDrawer, setTagsDrawer] = useState<IContact | null>(null);

  const debouncedSearch = useDebounce(searchValue, 1000);
  const { selectedCampaign } = useSelector(emailCampaignState);

  useEffect(() => {
    ELASTIC_STATUS ? fetchElasticData() : fetchData();
  }, [currentPage, perPage, debouncedSearch, filteredValue]);

  useEffect(() => {
    if (isSelectAllContact) {
      setAllChecked(true);
    }
  }, [isSelectAllContact, allChecked, currentPage]);

  useEffect(() => {
    if(ELASTIC_STATUS){
      if (elasticContacts && elasticContacts[0]) {
        const filteredContacts = elasticContacts.every((contact) =>
          selectedContacts.includes(contact.id as string)
        );
  
        if (!isSelectAllContact) {
          if (filteredContacts) {
            setAllChecked(true);
          } else {
            setAllChecked(false);
          }
        }
      }
    }else{
      if (contacts && contacts[0]) {
        const filteredContacts = contacts.every((contact) =>
          selectedContacts.includes(contact.contactId as string)
        );
  
        if (!isSelectAllContact) {
          if (filteredContacts) {
            setAllChecked(true);
          } else {
            setAllChecked(false);
          }
        }
      }
    }

  }, [elasticContacts, contacts]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await emailCampaignService.getContactList({
        search: debouncedSearch,
        currentPage,
        perPage,
        requiredField: "EMAIL",
        campaignUid: selectedCampaign?.uid as string,
        role: getAccountData("userIsOwner")
          ? USER_ROLE_OWNER
          : getAccountData("userIsAdmin")
          ? USER_ROLE_ADMIN
          : USER_ROLE_AGENT,
        ...filteredValue,
      });

      if (res && res.success) {
        setContacts(res.data);
        setIsContactAvailable(res.data.length > 0 ? true : false);
        if (currentPage === 1) {
          setTotalContacts(res.totalCount);
          setTotalPage(res.totalPage);
        }
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchElasticData = async () => {
    setIsLoading(true);
    try {
      const res = await emailCampaignService.getContactListElastic({
        search: debouncedSearch,
        currentPage,
        perPage,
        campaignUid: selectedCampaign?.uid as string,
        role: getAccountData("userIsOwner")
          ? USER_ROLE_OWNER
          : getAccountData("userIsAdmin")
          ? USER_ROLE_ADMIN
          : USER_ROLE_AGENT,
        ...filteredValue
      });

      if (res && res.success) {
        setElasticContacts(res.data);
        setIsContactAvailable(res.data.length > 0 ? true : false);
        if (currentPage === 1) {
          setTotalContacts(res.totalCount);
          setTotalPage(res.totalPage);
        }
      }else{
        showNotification("error", res.message);
        setElasticContacts([]);
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAllChecked = () => {
    setAllChecked(!allChecked);
    if (!allChecked) {
      const allContactNames = contacts
        .filter(
          (contact) => !selectedContacts.includes(contact.contactId as string)
        )
        .map((contact) => contact.contactId)
        .filter((id): id is string => id !== undefined);

      const allElasticContactNames = elasticContacts
        .filter((contact) => !selectedContacts.includes(contact.id as string))
        .map((contact) => contact.id)
        .filter((id): id is string => id !== undefined);

      onSelectContacts((prevState) =>
        ELASTIC_STATUS
          ? [...prevState, ...allElasticContactNames]
          : [...prevState, ...allContactNames]
      );
    } else {
      onSelectContacts([]);
    }

    if(isSelectAllContact){
      setAllChecked(false);
      setIsSelectAllContact(false);
    }
  };

  const handleContactCheckboxChange = (contactId: string) => {
    let updatedContacts: string[];

    if (selectedContacts.includes(contactId)) {
      updatedContacts = selectedContacts.filter(
        (contact) => contact !== contactId
      );
    } else {
      updatedContacts = [...selectedContacts, contactId];
    }

    onSelectContacts(updatedContacts);
    setAllChecked(
      ELASTIC_STATUS
        ? updatedContacts.length === elasticContacts.length
        : updatedContacts.length === contacts.length
    );

    if(isSelectAllContact){
      setIsSelectAllContact(false);
    }
  };

  if (isLoading) {
    return <AddContactTableTableSkeleton />;
  } else if (
    (elasticContacts && elasticContacts[0]) ||
    (contacts && contacts[0])
  ) {
    return (
      <>
        <TableContainer>
          <CustomScrollbar
            sx={{ height: "calc(100vh - 380px)", paddingRight: "2px" }}
          >
            <Table variant="bordered" size="small">
              <TableHead
                sx={{
                  zIndex: 3,
                  position: "sticky",
                  top: -1,
                }}
              >
                <TableRow>
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      {/* <Stack height={30} width={30} /> */}
                      <Checkbox
                        checked={allChecked}
                        size="small"
                        onClick={handleAllChecked}
                      />
                      Name
                    </Stack>
                  </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span>Phone</span>
                    </Stack>
                  </TableCell>
                  <TableCell>Tags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {elasticContacts &&
                  elasticContacts[0] &&
                  elasticContacts?.map((item: IContact) => (
                    <EachTableItem
                      key={item.id}
                      item={item}
                      isChecked={
                        isSelectAllContact ||
                        selectedContacts.includes(item.id as string)
                      }
                      selectedContacts={selectedContacts}
                      onCheckboxChange={handleContactCheckboxChange}
                      setTagsDrawer={setTagsDrawer}
                    />
                  ))}

                {contacts &&
                  contacts[0] &&
                  contacts?.map((item: IContact) => (
                    <EachTableItem
                      key={item.contactId}
                      item={item}
                      isChecked={selectedContacts.includes(
                        item.contactId as string
                      )}
                      selectedContacts={selectedContacts}
                      onCheckboxChange={handleContactCheckboxChange}
                      setTagsDrawer={setTagsDrawer}
                    />
                  ))}
              </TableBody>
            </Table>
          </CustomScrollbar>
        </TableContainer>

        <AddTagsDrawer
          open={!!tagsDrawer}
          item={tagsDrawer}
          onClose={() => setTagsDrawer(null)}
        />
      </>
    );
  } else if (!isLoading) {
    return <EmptyComponent />;
  }
};

export default AddContactTable;
