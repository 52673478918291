import React from "react";
import {
    Box,
    DeleteFilledIcon,
    IconButton,
    LinearProgress,
    Stack,
    Typography,
    UploadFileFilledIcon,
    useTheme
} from "convertupleads-theme";
import {formatFileSize} from "../../../helpers/util/functions.tsx";
import {ISequenceUploadedFile} from "../../../state/features/emailCampaign/emailCampaign.interface.ts";

interface IProps {
    file: ISequenceUploadedFile;
    onDeleteUploadedFile: (index: number) => void;
    index: number;
}

const EachAttachedFile: React.FC<IProps> = ({ file, onDeleteUploadedFile, index }) => {
    const theme = useTheme();

    return(
        <Box
            padding={2}
            overflow={'hidden'}
            position={'relative'}
            borderRadius={2}
            mb={1}
            sx={{ background: theme.palette.other.outlinedBorder, minWidth: "350px" }}
        >
            <Stack width={"100%"}>
                <Stack direction={"row"} alignItems={"center"} width={"100%"}>
                    <UploadFileFilledIcon sx={{ fontSize: "36px", color: "text.tertiary" }}/>
                    <Stack ml={1} width={"100%"}>
                        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                            <Typography color={"text.tertiary"}  variant={"subtitle1"}>{file.file_name}</Typography>
                            <IconButton
                                sx={{
                                    padding: 0,
                                    border: "none",
                                    "&:focus": {
                                        background: "transparent",
                                    },
                                }}
                                onClick={()=>{
                                    onDeleteUploadedFile(index);
                                }}
                            >
                                <DeleteFilledIcon sx={{ color: "text.tertiary" }}/>
                            </IconButton>
                        </Stack>
                        <Typography color={"text.tertiary"}  variant={"caption"}>{formatFileSize(file.file_size)}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
};

export default EachAttachedFile;