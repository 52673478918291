import React, { Dispatch, SetStateAction } from 'react';
import { Button, Divider, ModalWithHeader, Stack, TextField, Typography, useTheme } from 'convertupleads-theme';
import {CustomTextField} from "../../../bulkEmail/BulkEmailStyle.tsx";

interface PersonalizedVideoModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const PersonalizedVideoModal: React.FC<PersonalizedVideoModalProps> = ({ open, setOpen }) => {
    const handleClose = () => {
        setOpen(false);
    };
    const theme = useTheme();
    return (
        <Stack>
            <ModalWithHeader open={open} onClose={handleClose} title='Add Personalized Video'>
                <>
                    <Stack padding={3}>
                        <Typography color={'text.secondary'} variant='body2'>
                            Video Url Link
                        </Typography>
                        <CustomTextField sx={{ marginTop: '8px' }}></CustomTextField>
                    </Stack>
                    <Divider />
                    <Stack direction={'row'} justifyContent={'end'} spacing={1} paddingX={3} paddingY={2}>
                        <Button
                            variant={'contained'}
                            onClick={handleClose}
                            sx={{
                                color: 'gray',
                                background: theme.palette.other.divider,
                                '&:hover': {
                                    background: theme.palette.other.divider,
                                },
                            }}
                            size='large'
                        >
                            Cancel
                        </Button>

                        <Button variant={'contained'} size='large'>
                            Send
                        </Button>
                    </Stack>
                </>
            </ModalWithHeader>
        </Stack>
    );
};

export default PersonalizedVideoModal;
