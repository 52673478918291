import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Stack,
    ChevronDownIcon,
    FormControlLabel,
    FormGroup,
    Checkbox,
    FormControl,
    Select,
    Collapse,
    Divider, SelectChangeEvent,
    Modal,
    WarningFilledIcon,
    Typography,
    Button,
} from 'convertupleads-theme';
import CustomSettingHeader from './CustomSettingHeader';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector.ts';
import {
    ISettingDetail,
    IUpdateCampaignSettings,
} from '../../../../state/features/emailCampaign/emailCampaign.interface.ts';
import { updateCampaignSettings } from '../../../../state/features/emailCampaign/emailCampaignSlice.ts';
import { TRACK_SETTINGS_STATUS } from '../../../../helpers/constant/coreConstants.ts';
import { CustomMenuItem } from '../../BulkEmailStyle.tsx';
import { NotificationActiveIcon } from '../../../../assets/Icons.tsx';

interface NotificationSettingProps {
    onSaveSettings: (settings: { [key: string]: boolean }) => void;
    onSaveOption: (option: number) => void;
}

const NotificationSetting: React.FC<NotificationSettingProps> = () => {
    const dispatch = useDispatch();
    const [toggleState, setToggleState] = useState(false);
    const [modal, setModal] = useState(false);
    const { selectedCampaign, campaignSettings } = useSelector(emailCampaignState);

    const getStatus = (key: string) => {
        if (campaignSettings && campaignSettings["NOTIFICATION_SETTINGS"]) {
            const settingsDetails: { [key: string]: any } = campaignSettings["NOTIFICATION_SETTINGS"][0].settingsDetails;
            return settingsDetails[key] === "ACTIVE";
        }
        return false;
    };

    const getValue = (key: string) => {
        if (campaignSettings && campaignSettings["NOTIFICATION_SETTINGS"]) {
            const settingsDetails: { [key: string]: any } = campaignSettings["NOTIFICATION_SETTINGS"][0].settingsDetails;
            return settingsDetails[key];
        }
        return '';
    };

    const handleUpdate = (key: string, value: boolean | string, oldValue: boolean | string) => {
        if (selectedCampaign && selectedCampaign.uid && campaignSettings && campaignSettings["NOTIFICATION_SETTINGS"]) {
            const payload: IUpdateCampaignSettings = { campaignUid: selectedCampaign.uid, settingsTitle: "NOTIFICATION_SETTINGS" };

            if (key === "status") {
                setToggleState(String(value) === "ACTIVE");
                payload.status = String(value);
                payload.oldValue = String(oldValue);
                setToggleState((prev) => !prev)
            } else {
                if (key === 'NOTIFICATION_CHANNEL') {
                    payload.settingsDetails = { ...campaignSettings['NOTIFICATION_SETTINGS'][0].settingsDetails, [key]: value };
                    payload.oldValue = { ...campaignSettings['NOTIFICATION_SETTINGS'][0].settingsDetails, [key]: oldValue };
                } else {
                    payload.settingsDetails = { ...campaignSettings['NOTIFICATION_SETTINGS'][0].settingsDetails, [key]: value ? 'ACTIVE' : 'INACTIVE' };
                    payload.oldValue = { ...campaignSettings['NOTIFICATION_SETTINGS'][0].settingsDetails, [key]: value ? 'INACTIVE' : 'ACTIVE' };
                }
            }
            dispatch(updateCampaignSettings(payload));
        }
    };

    useEffect(() => {
        if (campaignSettings && campaignSettings["NOTIFICATION_SETTINGS"]) {
            const status = campaignSettings["NOTIFICATION_SETTINGS"][0].status;
            setToggleState(status === "ACTIVE");
        }
    }, [campaignSettings]);

    const trackSettings: ISettingDetail[] | undefined = campaignSettings?.['TRACKING_SETTINGS'][0]?.settingsDetails;

    const isTrackLinkClickActive = trackSettings?.TRACK_LINK_CLICK === TRACK_SETTINGS_STATUS.ACTIVE;
    const isTrackOpenEmailActive = trackSettings?.TRACK_OPEN_EMAIL === TRACK_SETTINGS_STATUS.ACTIVE;
    const isTrackReplyEmailActive = trackSettings?.TRACK_REPLY_EMAIL === TRACK_SETTINGS_STATUS.ACTIVE;

    const triggerHandler = () => {
        if (isTrackLinkClickActive && isTrackOpenEmailActive && isTrackReplyEmailActive) {
            handleUpdate("status", toggleState ? TRACK_SETTINGS_STATUS.INACTIVE : TRACK_SETTINGS_STATUS.ACTIVE, toggleState ? TRACK_SETTINGS_STATUS.ACTIVE : TRACK_SETTINGS_STATUS.INACTIVE);
        } else {
            setModal(true);
        }
    }

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: '0px 20px', mt: 2.5 }}>
            <Box width={960}>
                <CustomSettingHeader
                    title={'Notification Settings'}
                    subTitle={' Get notified when something of value happens.'}
                    icon={<NotificationActiveIcon width={22.5} height={22.5} />}
                    isAccordionOpen={toggleState}
                    handleToggle={triggerHandler}
                    isShowButton={false}
                />
                <Collapse in={toggleState}>
                    <Divider light />
                    <Stack pl={'50px'} paddingY={'16px'}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Stack direction={'row'} spacing={1} gap={1}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color={'primary'}
                                                size={'small'}
                                                checked={getStatus('TRACK_OPEN_EMAIL')}
                                                onChange={(_event, checked) => {
                                                    handleUpdate('TRACK_OPEN_EMAIL', checked, getStatus('TRACK_OPEN_EMAIL'))
                                                }}
                                            />
                                        }
                                        sx={{ color: 'text.secondary' }}
                                        label='Email Opens'
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color={'primary'}
                                                size={'small'}
                                                checked={getStatus('TRACK_LINK_CLICK')}
                                                onChange={(_event, checked) => {
                                                    handleUpdate('TRACK_LINK_CLICK', checked, getStatus('TRACK_LINK_CLICK'))
                                                }}
                                            />
                                        }
                                        sx={{ color: 'text.secondary' }}
                                        label='Click on Link'
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color={'primary'}
                                                size={'small'}
                                                checked={getStatus('REPLY_TO_EMAIL')}
                                                onChange={(_event, checked) => {
                                                    handleUpdate('REPLY_TO_EMAIL', checked, getStatus('REPLY_TO_EMAIL'))
                                                }}
                                            />
                                        }
                                        sx={{ color: 'text.secondary' }}
                                        label='Reply to Email'
                                    />
                                </FormGroup>
                            </Stack>
                            <FormControl sx={{ width: '30%' }}>
                                <Select
                                    value={getValue('NOTIFICATION_CHANNEL')}
                                    onChange={(event: SelectChangeEvent) => {
                                        handleUpdate('NOTIFICATION_CHANNEL', event.target.value, getValue('NOTIFICATION_CHANNEL'));
                                    }}
                                    fullWidth
                                    IconComponent={ChevronDownIcon}
                                    displayEmpty
                                >
                                    <CustomMenuItem value={''} disabled>Select notification Option</CustomMenuItem>
                                    <CustomMenuItem value={'EMAIL_NOTIFICATION'}>Email notification</CustomMenuItem>
                                    <CustomMenuItem value={'TEXT_NOTIFICATION'}>Text notification</CustomMenuItem>
                                    <CustomMenuItem value={'PUSH_NOTIFICATION'}>Push notification</CustomMenuItem>
                                    {/*<MenuItem value={'POP_UP_MESSAGE'}>Welcome Back! Pop-up Message</MenuItem>*/}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Stack>
                </Collapse>
            </Box>

            <Modal width={560} open={modal} onClose={() => setModal(false)}>
                <Stack>
                    <Stack direction={"column"} alignItems={"center"} px={3} py={2}>
                        <Stack direction={"row"} justifyContent={"center"} padding={2} paddingBottom={0}>
                            <WarningFilledIcon sx={{ fontSize: "60px", color: "orange" }} />
                        </Stack>
                        <Typography variant={"h5"} textAlign={"center"}>
                            Action Required
                        </Typography>

                        <Typography mt={1} mb={1} textAlign={"center"}>
                            Please setup Tracking Settings
                        </Typography>
                    </Stack>

                    <Stack width={"100%"} mt={3}>
                        <Divider light />
                        <Stack padding={2} direction={"row"} justifyContent={"flex-end"} gap={1}>
                            <Button variant={"tonal"} color={"inherit"} size={"large"} onClick={() => setModal(false)}>
                                Cancel
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Modal>
        </Paper>
    );
};

export default NotificationSetting;
