import React, {useState} from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, Paper, Stack, TasksIcon, Typography } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { updateCampaignSettings } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import PopupAlert from "../../../common/PopupAlert";

interface ReportSettingsProps {
    onSaveChanges: (selectedSettings: { [key: string]: boolean }) => void;
}

const ReportSettings: React.FC<ReportSettingsProps> = () => {
    const dispatch = useDispatch();
    const { campaignSettings, selectedCampaign } = useSelector(emailCampaignState);
    const [isShowReportDisable, showIsShowReportDisable] = useState(false);
    const [disablingSetting, setDisablingSetting] = useState<string>('');

    const getStatus = (key: string) => {
        if (campaignSettings && campaignSettings.TRACKING_SETTINGS) {
            const settingsDetails: { [key: string]: any } = campaignSettings.TRACKING_SETTINGS[0].settingsDetails;
            return settingsDetails && settingsDetails[key] === "ACTIVE";
        }
        return false;
    };

    const showDisableAlert = (key: string) => {
        showIsShowReportDisable(true);
        setDisablingSetting(key);
    };

    const handleUpdate = (key: string, value: boolean) => {
        if (selectedCampaign && selectedCampaign.uid && campaignSettings && campaignSettings.TRACKING_SETTINGS) {
            const payload = { ...campaignSettings["TRACKING_SETTINGS"][0].settingsDetails, [key]: value ? 'ACTIVE' : 'INACTIVE', ['old' + key]: value ? 'INACTIVE' : 'ACTIVE' };

            dispatch(updateCampaignSettings({ campaignUid: selectedCampaign.uid, settingsTitle: "TRACKING_SETTINGS", settingsDetails: payload }));
        }
    };

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: '18px 20px', mt: 2.5 }}>
            <Box width={960}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <IconButton variant={'rounded'} size={'medium'} color={'primary'} sx={{ backgroundColor: 'primary.light' }}>
                        <TasksIcon />
                    </IconButton>
                    <Stack flex={1}>
                        <Typography variant={'subtitle1'} color={'text.secondary'}>
                            <b>Report Settings</b>
                        </Typography>
                        <Typography variant={'caption'} color={'text.secondary'}>
                            Custom Tracking
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={1} gap={1}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='TRACK_OPEN_EMAIL'
                                        size={'small'}
                                        color={'primary'}
                                        checked={getStatus('TRACK_OPEN_EMAIL')}
                                        onChange={(_event, checked) => {
                                            if (!checked){
                                                showDisableAlert('TRACK_OPEN_EMAIL');
                                            }else {
                                                handleUpdate("TRACK_OPEN_EMAIL", checked);
                                            }
                                        }}
                                    />
                                }
                                sx={{ color: 'text.secondary' }}
                                label='Track Email Opens'
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='TRACK_LINK_CLICK'
                                        size={'small'}
                                        color={'primary'}
                                        checked={getStatus('TRACK_LINK_CLICK')}
                                        onChange={(_event, checked) => {
                                            if (!checked){
                                                showDisableAlert('TRACK_LINK_CLICK');
                                            }else {
                                                handleUpdate("TRACK_LINK_CLICK", checked);
                                            }
                                        }}
                                    />
                                }
                                sx={{ color: 'text.secondary' }}
                                label='Track Link Clicks'
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='TRACK_REPLY_EMAIL'
                                        size={'small'}
                                        color={'primary'}
                                        checked={getStatus('TRACK_REPLY_EMAIL')}
                                        onChange={(_event, checked) => {
                                            if (!checked){
                                                showDisableAlert('TRACK_REPLY_EMAIL');
                                            }else {
                                                handleUpdate("TRACK_REPLY_EMAIL", checked);
                                            }
                                        }}
                                    />
                                }
                                sx={{ color: 'text.secondary' }}
                                label='Track Reply Email'
                            />
                        </FormGroup>
                    </Stack>
                </Stack>
            </Box>

            <PopupAlert
                title={"Are you sure?"}
                description={"If you turn these features off, we won't be able to provide the related trigger and reporting on the campaign."}
                open={isShowReportDisable}
                onSubmit={() => {
                    showIsShowReportDisable(false);
                    handleUpdate(disablingSetting, false);
                }}
                onCancel={() => {
                    showIsShowReportDisable(false);
                }}
            />
        </Paper>
    );
};

export default ReportSettings;
