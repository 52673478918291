import React from 'react';
import { Paper, Step, StepButton, Stepper } from 'convertupleads-theme';
import { STEPS } from '../../../helpers/constant/coreConstants';


interface StepperProps {
    activeStep: STEPS;
    goStep: (step: number) => void;
}

const StepperComponent: React.FC<StepperProps> = ({ goStep, activeStep }) => {
    const steps = ['Sequence', 'Schedule', 'Leads', 'Settings'];

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ m: 2, p: 2, justifyContent: 'space-between' }}>
            <Stepper nonLinear activeStep={activeStep - 1} sx={{height: 24}}>
                {steps.map((label, index) => (
                    <Step key={label} completed={index < activeStep - 1}>
                        <StepButton color="inherit" onClick={() => goStep(index + 1)} sx={{py: '17px', '&:focus': { background: 'transparent' }}}>
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Paper>
    );
};

export default StepperComponent;
