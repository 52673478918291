import { Skeleton, Stack } from 'convertupleads-theme';
import React from 'react';

const FolderSkeleton: React.FC = () => {
    return (
        <Stack spacing={2} mt={2}>
            <Skeleton variant='rounded' height={35} />
            <Skeleton variant='rounded' height={35} />
            <Skeleton variant='rounded' height={35} />
            <Skeleton variant='rounded' height={35} />
        </Stack>
    );
};

export default FolderSkeleton;
