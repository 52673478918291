import { InputAdornment, SearchIcon, Stack } from 'convertupleads-theme';
import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { campaignFolderState } from '../../../../../state/features/campaignFolder/campaignFolderSelector';
import CampaignStatusFilter from './CampaignStatusFilter';
import {CustomTextField} from "../../../BulkEmailStyle.tsx";

interface ICampaignSearch {
    search: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
}

const CampaignSearch: React.FC<ICampaignSearch> = ({ search, setSearchValue }) => {
    const { selectedFolder } = useSelector(campaignFolderState);


    const renderStatusFilter = selectedFolder !== 'trash' && selectedFolder !== 'archive' && <CampaignStatusFilter />;

    return (
        <Stack direction={'row'} justifyContent={'space-between'} spacing={2} flex={1}>
            <CustomTextField
                fullWidth
                sx={{ maxWidth: 700 }}
                value={search}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder={'Search'}
                size='small'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon sx={{ height: '16px', width: '16px' }} />
                        </InputAdornment>
                    ),
                }}
            />

            {renderStatusFilter}
        </Stack>
    );
};

export default CampaignSearch;
