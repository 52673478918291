import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Backdrop,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Paper,
    Stack,
    Typography,
    useTheme
} from "convertupleads-theme";
import {CustomTextField} from "../../../../../BulkEmailStyle.tsx";
import {useImportSpreadsheetContext} from "../../importSpreadsheetReducer.tsx";
import emailCampaignService from "../../../../../../../services/emailCampaign.service.ts";
import mapCSVImportHelper from "../mapping/mapCSVImportHelper.ts";
import {ICSVFile,IMapData} from "../../../../../../../state/features/emailCampaign/emailCampaign.interface.ts";
import SelectWithSearchBox from "../mapping/SelectWithSearchBox.tsx";
import useDebounce from "../../../../../../../hooks/useDebounce.ts";
import {showNotification} from "../../../../../../../helpers/util/functions.tsx";
import {useSearchParams} from "react-router-dom";
import useDidUpdateEffect from "../../../../../../../hooks/useDidUpdateEffect.ts";
import Reconnection from "../reconnection/Reconnection.tsx";

const Configure: React.FC = () => {
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const [submitting, setSubmitting] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [showReconnect, setShowReconnect] = useState(false);
    const {
        email,
        link,
        setLink,
        handleGoNextStep,
        handleGoPreviousStep,
        setMappedRows,
        setUnMappedRows,
        setValuesForSubmit,
        extractFileId,
        setWorksheets,
        worksheets,
        setWorksheet,
        worksheet,
        setFileName,
        setValuesForSelect,
        additionalInfos,
        changeAdditionalInfos,
    } = useImportSpreadsheetContext();
    const debouncedLink = useDebounce(link, 500);

    useDidUpdateEffect(() => {
        loadData();
    }, [debouncedLink]);

    const loadData = async ()=>{
        try{
            if (extractFileId(debouncedLink)){
                setFetching(true);
                const tokenResp = await emailCampaignService.processGoogleRefreshToken({ email: email });

                if (tokenResp?.success){
                    const sheetResp = await emailCampaignService.googleWorksheetInfo({ email: email, spreadsheetId: extractFileId(debouncedLink) });

                    if (sheetResp?.success && sheetResp?.data?.sheets){
                        setWorksheets(sheetResp?.data?.sheets.map((sheet: any)=>({ sheetId: sheet?.properties?.sheetId, title: sheet?.properties?.title })));
                        setFileName(sheetResp?.data?.properties?.title);
                        setWorksheet('');
                    }else {
                        setWorksheets([]);
                        setWorksheet('');
                        showNotification("error", "Failed to get sheet data");
                    }
                }else {
                    setShowReconnect(true);
                    showNotification("error", tokenResp.message || "Failed to get sheet data");
                }
            }else {
                if (worksheets){
                    setWorksheets([]);
                    setWorksheet('');
                }
            }
        }catch (err){
            console.log(err);
            setWorksheets([]);
            setWorksheet('');
            showNotification("error", "Failed to get sheet data");
        }finally {
            setFetching(false);
        }
    }

    const processGoogleRefreshToken = async () => {
        try{
            if (!link){
                showNotification("error", "No spreadsheet link. Please provide one");
                return;
            }

            if (!worksheet){
                showNotification("error", "No worksheet select. Please select one");
                return;
            }
            setSubmitting(true);
            const detailsResp = await emailCampaignService.googleReadSpreadsheet({ email: email, spreadsheetId: extractFileId(link), worksheetName: worksheet });
            if (detailsResp?.success && detailsResp?.data && detailsResp?.data[0]){
                const { mappedRows, actionNeededRows, submitData } = mapCSVImportHelper.mapData(detailsResp.data as unknown as ICSVFile<string | number>[][]);
                const data: IMapData[] = Object.values(submitData);

                setMappedRows(mappedRows);
                setUnMappedRows(actionNeededRows);
                setValuesForSubmit(data);
                setValuesForSelect(data);
                handleGoNextStep();
                if (searchParams.has('spreadsheet')) {
                    searchParams.delete('spreadsheet');
                }

                if (searchParams.has('campaignUid')) {
                    searchParams.delete('campaignUid');
                }

                setSearchParams(searchParams);
            }else {
                showNotification("error", "Empty spreadsheet. Try with different one");
            }
        }catch (err){
            console.log(err);
            showNotification("error", "Unable to read spreadsheet. Try with different one");
        } finally {
            setSubmitting(false);
        }
    };

    return(
        <Stack spacing={4}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant={'h5'} color={'text.primary'}>
                    Configure
                </Typography>
                <Stack direction={'row'} spacing={1.5}>
                    <Button variant={'tonal'} onClick={handleGoPreviousStep}>
                        Previous
                    </Button>
                    <Button disabled={submitting} onClick={processGoogleRefreshToken}>
                        Next
                    </Button>
                </Stack>
            </Stack>

            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    width: "100%",
                    height: "220px",
                    borderRadius: "12px"
                }}
            >
                <Stack direction={"row"} justifyContent={"center"} px={3} py={2}>
                    <Stack direction={"column"} alignItems={"center"} width={"100%"} position={"relative"}>
                        <Stack direction={"column"} alignItems={"center"} mt={2} width={"100%"}>
                            <Typography variant={"p"} mt={1} mb={1} textAlign={"center"}>Provide Your Google Spreadsheet Link</Typography>
                            <CustomTextField placeholder={"Type spreadsheet link"} fullWidth onChange={(event: ChangeEvent)=>{ setLink(event.target.value); }} value={link}/>
                        </Stack>
                        <Stack mt={2} width={"100%"}>
                            <SelectWithSearchBox
                                disabled={!worksheets[0]}
                                options={worksheets}
                                value={worksheet}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                                    setWorksheet(event.target.value);
                                }}
                                fieldMap={{ label: 'title', value: 'title' }}
                                emptyLabel={'Select a worksheet'}
                            />
                        </Stack>
                        <Stack mt={2} ml={4} width={"100%"}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size='small'
                                        color='primary'
                                        sx={{ padding: 0 }}
                                        checked={additionalInfos.ignore_first_row}
                                    />
                                }
                                label={
                                    <Typography variant='subtitle2' ml={1} color={'text.primary'} fontWeight={'bold'}>
                                        Ignore first row
                                    </Typography>
                                }
                                onChange={(_event, checked: boolean) => {
                                    changeAdditionalInfos({ ignore_first_row: checked });
                                }}
                            />
                        </Stack>
                        <Backdrop open={fetching} sx={{ position: "absolute", borderRadius: "12px" }}>
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    </Stack>
                </Stack>
            </Paper>

            <Reconnection
                email={email}
                open={showReconnect}
                onClose={()=>{ setShowReconnect(false); }}
                resetOnClose={true}
            />
        </Stack>
    );
};

export default Configure;
