import React, { useState } from 'react';
import { Edit1Icon, ListItemButton, ListItemIcon, ListItemText, Typography } from 'convertupleads-theme';
import RenameModalComponent from '../RenameModalComponent';
import { IEmailCampaign } from '../../../../../state/features/emailCampaign/emailCampaign.interface';

interface IRenameComponent {
    onClose: () => void;
    campaign: IEmailCampaign;
}
const RenameComponent: React.FC<IRenameComponent> = ({ onClose, campaign }) => {
    const [openRenameModal, setOpenRenameModal] = useState(false);
    const handleRenameModal = () => {
        setOpenRenameModal(true);
    };
    return (
        <div>
            <ListItemButton onClick={handleRenameModal}>
                <ListItemIcon>
                    <Edit1Icon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText>
                    <Typography color={'text.primary'} variant='body2' fontWeight={400}>
                        Rename
                    </Typography>
                </ListItemText>
            </ListItemButton>
            <RenameModalComponent campaign={campaign} open={openRenameModal} setOpen={setOpenRenameModal} onClose={onClose} />
        </div>
    );
};

export default RenameComponent;
