import React, { useState } from 'react';
import { Stack, Typography, Avatar, Checkbox, styled } from 'convertupleads-theme';
import { IContact } from '../../../../state/features/emailCampaign/emailCampaign.interface';

interface StyledCheckboxWrapperProps {
    checked: boolean;
}

const StyledCheckboxWrapper = styled(Stack)<StyledCheckboxWrapperProps>(({ theme, checked }) => ({
    padding: '8px 15px 8px 12px',
    cursor: 'pointer',
    '&:hover': {
        background: theme.palette.other.outlinedBorder,
        color: theme.palette.text.secondary,
        padding: '8px 15px 8px 12px',
        borderRadius: '4px',
    },
    ...(checked && {
        background: theme.palette.other.outlinedBorder,
        color: theme.palette.text.secondary,
    }),
}));


const StyledAvatar = styled(Avatar)(({ theme }) => ({
    background: theme.palette.secondary.light,
    color: theme.palette.primary.main,
    height: '42px',
    width: '42px',
    fontSize: '16px'
}))

const EachElasticContact: React.FC<{
    isChecked: boolean;
    contact: IContact;
    onCheckboxChange: (id: number) => void;
    submittedContacts: number[];
}> = ({ isChecked, contact, onCheckboxChange, submittedContacts }) => {
    const [checked, setChecked] = useState(isChecked);

    const isSubmitted = submittedContacts.includes(contact.id);

    const handleCheckboxChange = () => {
        const newChecked = !checked;
        setChecked(newChecked);
        onCheckboxChange(contact.id);
    };

    if (isChecked !== checked) {
        setChecked(isChecked);
    }

    const generateName = (contact: IContact) => {
        let name = '';

        if (contact.firstName) {
            name += contact.firstName;
        }

        if (contact.lastName) {
            if (name) {
                name += " ";
            }
            name += contact.lastName;
        }

        return name;
    };

    const getInitial = (name: string) => {

        let fullNameInitial: string = ''
        try {
            if (typeof name != 'undefined') {
                if (name === 'null null' || name === '' || name === ' ') {
                } else if (name !== 'undefined' || name !== null) {
                    fullNameInitial = name.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");
                }
            }
        } catch (error) {
        }
        return fullNameInitial;

    }

    const contactInformation = () => {

        let name = generateName(contact);
        let initial = getInitial(name)

        return (
            <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                <StyledAvatar size='large'>{initial}</StyledAvatar>
                <Stack>
                    <Typography variant={'body2'} color={'text.primary'}>
                        {name}
                    </Typography>
                    <Typography variant={'caption'} color={'action.active'}>
                        {contact.email}
                    </Typography>
                </Stack>
            </Stack>
        )
    }


    return (
        <StyledCheckboxWrapper
            checked={checked}
            onClick={handleCheckboxChange}
        >
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {contactInformation()}
                
                {
                    !isSubmitted ? (
                        <Checkbox
                            color={'primary'}
                            checked={checked}
                            onChange={handleCheckboxChange}
                            size={'small'}
                            sx={{
                                '&.MuiCheckbox-root': {
                                    marginRight: 0,
                                },
                            }}
                        />
                    ) : (
                        <Typography variant={'subtitle2'} color={'primary'} fontWeight={'bold'} mr={'8px !important'}>Contact Added</Typography>
                    )
                }

            </Stack>
        </StyledCheckboxWrapper>
    );
};

export default EachElasticContact;
