import { PayloadAction } from "@reduxjs/toolkit";
import { delay, takeEvery } from "redux-saga/effects";
import { all, call, put } from "typed-redux-saga";
import { showNotification } from "../../../helpers/util/functions.tsx";
import emailCampaignService from "../../../services/emailCampaign.service";
import {
  IAddCampaign,
  IAddCampaignEmail,
  IAddSequenceVariation,
  IBulkCampaignAddToFolder,
  IBulkUpdateCampaign,
  ICampaignSchedulesPayload,
  ICampaignSequencePayload,
  ICampaignSequenceSchedulePayload,
  IDeleteCampaign,
  IDeleteCampaignEmail,
  IEmailCampaign,
  IGetCampaignSettings,
  ISingleCampaignAddToFolder,
  IUpdateCampaign,
  IUpdateCampaignSettings,
  IUpdateSequenceVariation,
  IEmailThreadPayload,
  ISequenceUploadedFileUpdatePayload,
  IUpdateCampaignSettingDetails, ITagPayload,
} from "./emailCampaign.interface";
import {
  addCampaignEmailFailed,
  addCampaignEmailSuccess,
  addCampaignFailed,
  addCampaignSequenceFailed,
  addCampaignSequenceSuccess,
  addCampaignSuccess,
  addSequenceVariationFailed,
  addSequenceVariationSuccess,
  bulkCampaignAddToFolderFailed,
  bulkCampaignAddToFolderSuccess,
  bulkUnarchiveCampaignFailed,
  bulkUnarchiveCampaignSuccess,
  bulkUpdateCampaignSuccess,
  cloneCampaignFailed,
  cloneCampaignSuccess, createTagFailed, createTagSuccess,
  deleteCampaignEmailFailed,
  deleteCampaignEmailSuccess,
  deleteCampaignFailed,
  deleteCampaignSequenceFailed,
  deleteCampaignSequenceSuccess,
  deleteCampaignSuccess,
  deleteSequenceVariationFailed,
  deleteSequenceVariationSuccess,
  getCampaignEmailsFailed,
  getCampaignEmailsSuccess, getCampaignEventAnalyticsFailed, getCampaignEventAnalyticsSuccess,
  getCampaignSchedulesFailed,
  getCampaignSchedulesSuccess,
  getCampaignSequencesFailed,
  getCampaignSequencesSuccess,
  getCampaignSequenceStats,
  getCampaignSequenceStatsFailed,
  getCampaignSequenceStatsSuccess,
  getCampaignSettingsFailed,
  getCampaignSettingsSuccess,
  getEmailCampaigns,
  getEmailCampaignsByFolderFailed,
  getEmailCampaignsByFolderSuccess,
  getEmailCampaignsFailed,
  getEmailCampaignsSuccess,
  getEmailLookupPriceFailed,
  getEmailLookupPriceSuccess,
  getSequenceVariationsFailed,
  getSequenceVariationsSuccess,
  getTriggerCampaignListFailed,
  getTriggerCampaignListSuccess,
  getTriggerPowerDialerListFailed,
  getTriggerPowerDialerListSuccess,
  getTriggerTagListFailed,
  getTriggerTagListSuccess,
  getTriggerVirtualNumberListFailed,
  getTriggerVirtualNumberListSuccess,
  getUserConnectedEmailsFailed,
  getUserConnectedEmailsSuccess,
  getUserEmailProviderFailed,
  getUserEmailProviderSuccess,
  singleCampaignAddToFolderFailed,
  singleCampaignAddToFolderSuccess,
  updateCampaignBulkSettingsFailed,
  updateCampaignBulkSettingsSuccess,
  updateCampaignFailed,
  updateCampaignSchedulesFailed,
  updateCampaignSchedulesSuccess,
  updateCampaignSequenceFailed,
  updateCampaignSequenceScheduleFailed,
  updateCampaignSequenceScheduleSuccess,
  updateCampaignSequenceSuccess,
  updateCampaignSequenceUploadedFilesFailed,
  updateCampaignSequenceUploadedFilesSuccess,
  updateCampaignSettingDetailsFailed,
  updateCampaignSettingDetailsSuccess,
  updateCampaignSettingsFailed,
  updateCampaignSettingsSuccess,
  updateCampaignSuccess,
  updateEmailThreadFailed,
  updateEmailThreadSuccess,
  updateSequenceVariationFailed,
  updateSequenceVariationSuccess
} from "./emailCampaignSlice";

function* emailCampaignWatcher() {
  yield takeEvery("emailCampaign/getEmailCampaigns", getEmailCampaignsSaga);
  yield takeEvery(
    "emailCampaign/getEmailCampaignsByFolder",
    getEmailCampaignsByFolderSaga
  );
  yield takeEvery(
    "emailCampaign/singleCampaignAddToFolder",
    singleCampaignAddToFolderSaga
  );
  yield takeEvery(
    "emailCampaign/bulkCampaignAddToFolder",
    bulkCampaignAddToFolderSaga
  );
  yield takeEvery("emailCampaign/addCampaign", addCampaignSaga);
  yield takeEvery("emailCampaign/updateCampaign", updateCampaignSaga);
  yield takeEvery("emailCampaign/bulkUpdateCampaign", bulkUpdateCampaignSaga);
  yield takeEvery("emailCampaign/deleteCampaign", deleteCampaignSaga);
  yield takeEvery("emailCampaign/cloneCampaign", cloneCampaignSaga);
  yield takeEvery(
    "emailCampaign/getCampaignSequences",
    getCampaignSequencesSaga
  );
  yield takeEvery("emailCampaign/addCampaignSequence", addCampaignSequenceSaga);
  yield takeEvery(
    "emailCampaign/updateCampaignSequence",
    updateCampaignSequenceSaga
  );
  yield takeEvery(
    "emailCampaign/updateCampaignSequenceSchedule",
    updateCampaignSequenceScheduleSaga
  );
  yield takeEvery(
    "emailCampaign/deleteCampaignSequence",
    deleteCampaignSequenceSaga
  );
  yield takeEvery("emailCampaign/getCampaignSettings", getCampaignSettingsSaga);
  yield takeEvery(
    "emailCampaign/updateCampaignSettings",
    updateCampaignSettingsSaga
  );
  yield takeEvery("emailCampaign/updateCampaignSettingDetails", updateCampaignSettingDetailsSaga);
  yield takeEvery(
    "emailCampaign/getCampaignSchedules",
    getCampaignSchedulesSaga
  );
  yield takeEvery(
    "emailCampaign/updateCampaignSchedules",
    updateCampaignSchedulesSaga
  );
  yield takeEvery("emailCampaign/getCampaignEmails", getCampaignEmailsSaga);
  yield takeEvery("emailCampaign/getUserConnectedEmails", getUserConnectedEmailsSaga);
  yield takeEvery("emailCampaign/getUserEmailProvider", getUserEmailProviderSaga);
  yield takeEvery("emailCampaign/addCampaignEmail", addCampaignEmailSaga);
  yield takeEvery("emailCampaign/deleteCampaignEmail", deleteCampaignEmailSaga);
  yield takeEvery(
    "emailCampaign/getSequenceVariations",
    getSequenceVariationsSaga
  );
  yield takeEvery(
    "emailCampaign/addSequenceVariation",
    addSequenceVariationSaga
  );
  yield takeEvery(
    "emailCampaign/updateSequenceVariation",
    updateSequenceVariationSaga
  );
  yield takeEvery(
    "emailCampaign/deleteSequenceVariation",
    deleteSequenceVariationSaga
  );
  yield takeEvery(
    "emailCampaign/getTriggerCampaignList",
    getTriggerCampaignListSaga
  );
  yield takeEvery(
    "emailCampaign/getTriggerPowerDialerList",
    getTriggerPowerDialerListSaga
  );
  yield takeEvery("emailCampaign/getTriggerTagList", getTriggerTagListSaga);
  yield takeEvery("emailCampaign/getTriggerVirtualNumberList", getTriggerVirtualNumberListSaga);
  yield takeEvery(
    "emailCampaign/updateCampaignBulkSettings",
    updateCampaignBulkSettingsSaga
  );
  yield takeEvery("emailCampaign/updateEmailThread", updateEmailThreadSaga);

  yield takeEvery("emailCampaign/bulkUnarchiveCampaign", bulkUnarchiveCampaignSaga);
  yield takeEvery("emailCampaign/updateCampaignSequenceUploadedFiles", updateCampaignSequenceUploadedFilesSaga);
  yield takeEvery("emailCampaign/createTag", createTagSaga);
  yield takeEvery("emailCampaign/getCampaignSequenceStats", getCampaignSequenceStatsSaga);
  yield takeEvery("emailCampaign/getEmailLookupPrice", getEmailLookupPriceSaga);
  yield takeEvery("emailCampaign/getCampaignEventAnalytics", getCampaignEventAnalyticsSaga);
}

function* getEmailCampaignsSaga(action: PayloadAction<number>) {
  try {
    const response = yield* call(
      emailCampaignService.getEmailCampaigns,
      action.payload
    );
    if (response.success) {
      yield put(getEmailCampaignsSuccess(response));
    } else {
      yield put(getEmailCampaignsFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}

function* getEmailCampaignsByFolderSaga(action: PayloadAction<number>) {
  try {
    const response = yield* call(
      emailCampaignService.getEmailCampaigns,
      action.payload
    );

    if (response.success) {
      yield put(getEmailCampaignsByFolderSuccess(response));
    } else {
      yield put(getEmailCampaignsByFolderFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}

function* addCampaignSaga(action: PayloadAction<IAddCampaign>) {
  try {
    const response = yield* call(
      emailCampaignService.addCampaign,
      action.payload
    );

    if (response.success) {
      yield put(addCampaignSuccess(response.data));
      yield put(getEmailCampaigns({ page: 1, perPage: 10 }));
    } else {
      yield put(addCampaignFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(addCampaignFailed("Network or server error"));
  }
}

function* updateCampaignSaga(action: PayloadAction<IUpdateCampaign>) {
  try {
    if (action.payload.uid) {
      const response = yield* call(
        emailCampaignService.updateCampaign,
        action.payload
      );

      if (response.success) {
        yield put(updateCampaignSuccess(action.payload));
        showNotification('success', response.message || 'Success');
      } else {

        let message = response.message || 'Something went wrong! Try again later';
        showNotification('error', message);
        yield put(updateCampaignFailed({ ...{ message: message }, ...action.payload }));

      }
    }

  } catch (err) {
    console.log("Error: ", err);
    showNotification('error', 'Something went wrong! Try again later');
    yield put(updateCampaignFailed("Network or server error"));
  }
}

function* bulkUpdateCampaignSaga(action: PayloadAction<IBulkUpdateCampaign>) {
  try {
    const status = action.payload.status;

    if (status === "RESUME" || status === "PAUSE" || status === "ARCHIVE") {
      const response = yield* call(
        emailCampaignService.updateBulkCampaignStatus,
        action.payload
      );

      if (response.success) {
        yield put(bulkUpdateCampaignSuccess(action.payload));
        showNotification('success', response.message || 'Success');

      } else {
        let message = response.message || 'Something went wrong! Try again later';
        yield put(updateCampaignFailed({ ...{ message: message }, ...action.payload }));
        showNotification('error', message);

      }
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(updateCampaignFailed("Network or server error"));
  }
}

function* deleteCampaignSaga(action: PayloadAction<IDeleteCampaign>) {
  try {
    const deleteForeverStatus = action.payload.deleteForever;
    if (deleteForeverStatus) {
      const response = yield* call(emailCampaignService.deleteCampaignForever, {
        campaignUids: action.payload.campaignUids,
      });

      if (response.success) {
        yield put(deleteCampaignSuccess());
        showNotification('success', response.message || 'Success');

      } else {
        yield put(deleteCampaignFailed(response.message));
        showNotification('error', response.message || 'Something went wrong! Try again later');

      }
    } else {
      const response = yield* call(
        emailCampaignService.deleteCampaign,
        action.payload
      );

      if (response.success) {
        yield put(deleteCampaignSuccess());
        showNotification('success', response.message || 'Success');

      } else {
        yield put(deleteCampaignFailed(response.message));
        showNotification('error', response.message || 'Something went wrong! Try again later');

      }
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(deleteCampaignFailed("Network or server error"));
  }
}

function* cloneCampaignSaga(action: PayloadAction<IEmailCampaign>) {
  const originalCampaign = action.payload;
  try {
    const response = yield* call(
      emailCampaignService.cloneCampaign,
      action.payload
    );
    if (response.success) {
      yield put(
        cloneCampaignSuccess({
          ...originalCampaign,
          ...response.data,
        })
      );
    } else {
      yield put(cloneCampaignFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(cloneCampaignFailed("Network or server error"));
  }
}

function* singleCampaignAddToFolderSaga(
  action: PayloadAction<ISingleCampaignAddToFolder>
) {
  try {
    const response = yield* call(
      emailCampaignService.addToFolderSingle,
      action.payload
    );

    if (response.success) {
      yield put(singleCampaignAddToFolderSuccess(action.payload));
    } else {
      yield put(singleCampaignAddToFolderFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(singleCampaignAddToFolderFailed("Network or server error"));
  }
}

function* bulkCampaignAddToFolderSaga(
  action: PayloadAction<IBulkCampaignAddToFolder>
) {
  try {
    const response = yield* call(
      emailCampaignService.addToFolderBulk,
      action.payload
    );

    if (response.success) {
      yield put(bulkCampaignAddToFolderSuccess(response.data));
    } else {
      yield put(bulkCampaignAddToFolderFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(bulkCampaignAddToFolderFailed("Network or server error"));
  }
}

function* getCampaignSequencesSaga(
  action: PayloadAction<ICampaignSequencePayload>
) {
  try {
    const response = yield* call(
      emailCampaignService.getCampaignSequences,
      action.payload
    );

    if (response.success) {
      yield put(getCampaignSequencesSuccess(response.data));
      if (response.data && Array.isArray(response.data) && response.data[0].uid) {
        yield put(getCampaignSequenceStats({ campaignSequenceUid: response.data[0].uid }));
      }
    } else {
      yield put(getCampaignSequencesFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}

function* addCampaignSequenceSaga(
  action: PayloadAction<ICampaignSequencePayload>
) {
  try {
    const response = yield* call(
      emailCampaignService.addCampaignSequence,
      action.payload
    );

    if (response.success) {
      const data = { ...response.data };

      if (action.payload.index) {
        data.index = action.payload.index;
      }

      yield put(addCampaignSequenceSuccess(data));
    } else {
      yield put(addCampaignSequenceFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}

function* updateCampaignSequenceSaga(
  action: PayloadAction<ICampaignSequencePayload>
) {
  try {
    const response = yield* call(
      emailCampaignService.updateCampaignSequence,
      action.payload
    );

    if (response.success) {
      yield put(updateCampaignSequenceSuccess(response.data));
    } else {
      yield put(updateCampaignSequenceFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}

function* updateCampaignSequenceScheduleSaga(
  action: PayloadAction<ICampaignSequenceSchedulePayload>
) {
  try {
    const response = yield* call(
      emailCampaignService.updateCampaignSequenceSchedule,
      action.payload
    );

    if (response.success) {
      yield put(updateCampaignSequenceScheduleSuccess(response.data));
    } else {
      yield put(updateCampaignSequenceScheduleFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}

function* deleteCampaignSequenceSaga(
  action: PayloadAction<ICampaignSequencePayload>
) {
  try {
    const response = yield* call(
      emailCampaignService.deleteCampaignSequence,
      action.payload
    );

    if (response.success) {
      yield put(deleteCampaignSequenceSuccess(action.payload));
    } else {
      yield put(deleteCampaignSequenceFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}

function* getCampaignSettingsSaga(action: PayloadAction<IGetCampaignSettings>) {
  try {
    const response = yield* call(
      emailCampaignService.getCampaignSettings,
      action.payload
    );
    if (response.success) {
      yield put(getCampaignSettingsSuccess(response));
    } else {
      yield put(getCampaignSettingsFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}

function* updateCampaignSettingsSaga(
  action: PayloadAction<IUpdateCampaignSettings>
) {
  try {
    const response = yield* call(
      emailCampaignService.updateCampaignSettings,
      action.payload
    );

    if (response.success) {
      yield put(updateCampaignSettingsSuccess());
      // yield put(updateCampaignSettingsFailed({ ...{ message: response.message }, ...action.payload }));
    } else {
      yield put(updateCampaignSettingsFailed({ ...{ message: response.message }, ...action.payload }));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(updateCampaignSettingsFailed("Network or server error"));
  }
}

function* updateCampaignSettingDetailsSaga(action: PayloadAction<IUpdateCampaignSettingDetails>) {
  try {
    const response = yield* call(emailCampaignService.updateCampaignSettingDetails, action.payload);

    if (response.success) {
      yield put(updateCampaignSettingDetailsSuccess());
    } else {
      yield put(updateCampaignSettingDetailsFailed({ ...{ message: response.message }, ...action.payload }));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(updateCampaignSettingDetailsFailed("Network or server error"));
  }
}

function* getCampaignSchedulesSaga(
  action: PayloadAction<ICampaignSchedulesPayload>
) {
  try {
    const response = yield* call(
      emailCampaignService.getCampaignSchedules,
      action.payload
    );

    if (response.success) {
      yield put(getCampaignSchedulesSuccess(response.data));
    } else {
      yield put(getCampaignSchedulesFailed(response.message));
    }
  } catch (err) {
    yield put(getCampaignSchedulesFailed("Network or server error"));
  }
}

function* updateCampaignSchedulesSaga(
  action: PayloadAction<ICampaignSchedulesPayload>
) {
  try {
    if (action.payload.keyName === "days") {
      const response = yield* call(
        emailCampaignService.updateCampaignSequenceScheduleDays,
        action.payload
      );

      if (response.success) {
        yield put(
          updateCampaignSchedulesSuccess({
            [action.payload.keyName as string]: action.payload.value,
          })
        );
      } else {
        yield put(updateCampaignSchedulesFailed({ ...{ message: response.message }, ...action.payload }));
      }
    } else {
      const response = yield* call(
        emailCampaignService.updateCampaignSchedules,
        action.payload
      );

      if (response.success) {
        yield put(
          updateCampaignSchedulesSuccess({
            [action.payload.keyName as string]: action.payload.value,
          })
        );
      } else {
        yield put(updateCampaignSchedulesFailed({ ...{ message: response.message }, ...action.payload }));
      }
    }
  } catch (err) {
    yield put(updateCampaignSchedulesFailed({ ...{ message: "Network or server error" }, ...action.payload }));
  }
}

function* getCampaignEmailsSaga(action: PayloadAction<{ uid: string }>) {
  try {
    const campaignConnectedEmailsResponse = yield* call(
      emailCampaignService.getCampaignConnectedEmails,
      action.payload
    );

    if (campaignConnectedEmailsResponse.success) {
      yield put(getCampaignEmailsSuccess(campaignConnectedEmailsResponse.data));
    } else {
      yield put(getCampaignEmailsFailed({ ...{ message: campaignConnectedEmailsResponse.message }, ...action.payload }));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getCampaignEmailsFailed("Network or server error"));
  }
}

function* getUserConnectedEmailsSaga(action: PayloadAction<{ uid: string }>) {
  try {
    const userConnectedEmailsResponse = yield* call(
      emailCampaignService.getUserConnectedEmails,
      action.payload
    );

    if (userConnectedEmailsResponse.success) {
      yield put(getUserConnectedEmailsSuccess(userConnectedEmailsResponse.data));
    } else {
      yield put(getUserConnectedEmailsFailed({ ...{ message: userConnectedEmailsResponse.message }, ...action.payload }));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getUserConnectedEmailsFailed("Network or server error"));
  }
}

function* getUserEmailProviderSaga(action: PayloadAction<{ uid: string }>) {
  try {
    const userEmailProviderResponse = yield* call(emailCampaignService.getUserEmailProvider);

    if (userEmailProviderResponse.success) {
      yield put(getUserEmailProviderSuccess(userEmailProviderResponse.data));
    } else {
      yield put(getUserEmailProviderFailed({ ...{ message: userEmailProviderResponse.message }, ...action.payload }));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getUserEmailProviderFailed("Network or server error"));
  }
}

function* addCampaignEmailSaga(action: PayloadAction<IAddCampaignEmail>) {
  try {
    const response = yield* call(
      emailCampaignService.addCampaignEmail,
      action.payload
    );

    if (response.success) {
      yield put(addCampaignEmailSuccess(response.data));
    } else {
      yield put(addCampaignEmailFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(addCampaignEmailFailed("Network or server error"));
  }
}

function* deleteCampaignEmailSaga(action: PayloadAction<IDeleteCampaignEmail>) {
  try {
    const response = yield* call(
      emailCampaignService.deleteCampaignEmail,
      action.payload
    );

    if (response.success) {
      yield put(deleteCampaignEmailSuccess(action.payload));
    } else {
      yield put(deleteCampaignEmailFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(deleteCampaignEmailFailed("Network or server error"));
  }
}

function* getSequenceVariationsSaga(action: PayloadAction<{ uid: string }>) {
  try {
    const response = yield* call(
      emailCampaignService.getSequenceVariations,
      action.payload
    );

    if (response.success) {
      yield put(getSequenceVariationsSuccess(response.data));
    } else {
      yield put(getSequenceVariationsFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}
function* addSequenceVariationSaga(
  action: PayloadAction<IAddSequenceVariation>
) {
  try {
    const response = yield* call(
      emailCampaignService.addSequenceVariation,
      action.payload
    );

    yield delay(1000);


    if (response.success) {
      yield put(addSequenceVariationSuccess(response.data));
      showNotification("success", response.message);
    } else {
      yield put(addSequenceVariationFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}

function* updateSequenceVariationSaga(
  action: PayloadAction<IUpdateSequenceVariation>
) {
  try {

    const response = yield* call(
      emailCampaignService.updateSequenceVariation,
      action.payload
    );

    yield delay(1000);

    if (response.success) {
      yield put(updateSequenceVariationSuccess(action.payload));
      showNotification("success", response.message);

    } else {
      yield put(updateSequenceVariationFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}

function* deleteSequenceVariationSaga(action: PayloadAction<{ uid: string }>) {
  try {
    const response = yield* call(
      emailCampaignService.deleteSequenceVariation,
      action.payload
    );

    if (response.success) {
      yield put(deleteSequenceVariationSuccess(action.payload));
      showNotification("success", response.message);
    } else {
      yield put(deleteSequenceVariationFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
    yield put(getEmailCampaignsFailed("Network or server error"));
  }
}
function* getTriggerCampaignListSaga(action) {
  try {
    const response = yield* call(
      emailCampaignService.getTriggerCampaignList,
      action.payload
    );

    if (response.success) {
      yield put(getTriggerCampaignListSuccess(response.data));
    } else {
      yield put(getTriggerCampaignListFailed());
    }
  } catch (err) {
    yield put(getTriggerCampaignListFailed());
  }
}

function* getTriggerPowerDialerListSaga(action) {
  try {
    const response = yield* call(
      emailCampaignService.getTriggerPowerDialerList,
      action.payload
    );

    if (response.success) {
      yield put(getTriggerPowerDialerListSuccess(response.data));
    } else {
      yield put(getTriggerPowerDialerListFailed());
    }
  } catch (err) {
    yield put(getTriggerPowerDialerListFailed());
  }
}

function* getTriggerTagListSaga(action) {
  try {
    const response = yield* call(
      emailCampaignService.getTriggerTagList,
      action.payload
    );

    if (response.success) {
      yield put(getTriggerTagListSuccess(response.data));
    } else {
      yield put(getTriggerTagListFailed());
    }
  } catch (err) {
    yield put(getTriggerTagListFailed());
  }
}

function* getTriggerVirtualNumberListSaga() {
  try {
    const response = yield* call(emailCampaignService.getUserVirtualNumbers);

    if (response.success) {
      yield put(getTriggerVirtualNumberListSuccess(response.data));
    } else {
      yield put(getTriggerVirtualNumberListFailed());
    }
  } catch (err) {
    yield put(getTriggerVirtualNumberListFailed());
  }
}

function* updateCampaignBulkSettingsSaga(action) {
  try {
    const response = yield* call(
      emailCampaignService.updateCampaignBulkSettings,
      action.payload
    );

    if (response.success) {
      yield put(updateCampaignBulkSettingsSuccess(action.payload.settings));
    } else {
      yield put(updateCampaignBulkSettingsFailed());
    }
  } catch (err) {
    console.log(err);
    yield put(updateCampaignBulkSettingsFailed());
  }
}

function* updateEmailThreadSaga(action: PayloadAction<IEmailThreadPayload>) {
  try {
    const response = yield* call(emailCampaignService.updateEmailThread, action.payload);

    if (response.success) {
      showNotification("success", response.message);
      yield put(updateEmailThreadSuccess(action.payload));
    } else {
      showNotification("error", response.message);
      yield put(updateEmailThreadFailed());
    }
  } catch (err) {
    console.log(err);
    yield put(updateEmailThreadFailed());
  }
}

function* bulkUnarchiveCampaignSaga(action: PayloadAction<IBulkUpdateCampaign>) {
  try {
    const response = yield* call(
      emailCampaignService.bulkUnarchiveCampaign,
      action.payload
    );

    yield delay(500);

    if (response.success) {
      yield put(bulkUnarchiveCampaignSuccess());
      showNotification("success", response.message);
    } else {
      yield put(bulkUnarchiveCampaignFailed(response.message));
      showNotification("error", response.message || 'Error while unarchiving');
    }
  } catch (err) {
    console.log(err);
    yield put(bulkUnarchiveCampaignFailed(err));
  }
}

function* updateCampaignSequenceUploadedFilesSaga(action: PayloadAction<ISequenceUploadedFileUpdatePayload>) {
  try {
    const response = yield* call(emailCampaignService.updateCampaignSequenceUploadedFiles, action.payload);

    if (response.success) {
      showNotification("success", response.message);
      yield put(updateCampaignSequenceUploadedFilesSuccess(action.payload.uploadedFiles));
    } else {
      showNotification("error", response.message);
      yield put(updateCampaignSequenceUploadedFilesFailed(action.payload.uploadedFiles));
    }
  } catch (err) {
    console.log(err);
    yield put(updateCampaignSequenceUploadedFilesFailed(action.payload));
  }
}

function* createTagSaga(action: PayloadAction<ITagPayload>) {
  try {
    const resp = yield* call(emailCampaignService.createNewTag, action.payload);

    if (resp.success) {
      yield put(createTagSuccess(resp.data));
    } else {
      yield put(createTagFailed());
    }
  } catch (err) {
    yield put(createTagFailed());
  }
}

function* getCampaignSequenceStatsSaga(action: PayloadAction<{ campaignSequenceUid: string; }>) {
  try {
    const campaignSequenceStatsResponse = yield* call(emailCampaignService.getCampaignSequenceStats, action.payload);

    if (campaignSequenceStatsResponse.success) {
      yield put(getCampaignSequenceStatsSuccess(campaignSequenceStatsResponse.data));
    } else {
      yield put(getCampaignSequenceStatsFailed());
    }
  } catch (err) {
    yield put(getCampaignSequenceStatsFailed());
  }
}

function* getEmailLookupPriceSaga() {
  try {
    const campaignSequenceStatsResponse = yield* call(emailCampaignService.getEmailLookupPrice);

    if (campaignSequenceStatsResponse.success) {
      yield put(getEmailLookupPriceSuccess(campaignSequenceStatsResponse.data));
    } else {
      yield put(getEmailLookupPriceFailed());
    }
  } catch (err) {
    yield put(getEmailLookupPriceFailed());
  }
}

function* getCampaignEventAnalyticsSaga(action: PayloadAction<{ campaignUid: string; startDate: string; endDate: string; }>) {
  try {
    const campaignSequenceStatsResponse = yield* call(emailCampaignService.getCampaignEventAnalytics, action.payload);

    if (campaignSequenceStatsResponse.success) {
      yield put(getCampaignEventAnalyticsSuccess(campaignSequenceStatsResponse.data));
    } else {
      yield put(getCampaignEventAnalyticsFailed());
    }
  } catch (err) {
    yield put(getCampaignEventAnalyticsFailed());
  }
}

export default function* emailCampaignSaga() {
  yield all([emailCampaignWatcher()]);
}

