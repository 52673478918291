import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CallIcon,
    Divider,
    HashTagIcon,
    MarketingIcon,
    ModalWithHeader,
    SelectChangeEvent,
    SmsIcon,
    SmsNotificationIcon,
    SmsTrackingIcon,
    Stack,
    Typography,
    VoiceIcon,
} from 'convertupleads-theme';
import AddToTrigger from './AddToTrigger';
import CampaignPause from './CampaignPause';
import SwitchEmailSender from './SwitchEmailSender';
import { CustomScrollbar } from '../../../common/CustomScrollBarStyle';
import { useDispatch, useSelector } from "react-redux";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import {
    cleanUpdateCampaignBulkSettings,
    getTriggerCampaignList,
    getTriggerPowerDialerList,
    getTriggerTagList,
    getTriggerVirtualNumberList,
    updateCampaignBulkSettings,
} from "../../../../state/features/emailCampaign/emailCampaignSlice.ts";
import { CAMPAIGN_TRIGGER_SETTINGS } from "../../../../helpers/constant/coreConstants.ts";
import AddToTriggerVoiceMessage from "./TriggerVoiceMessage/AddToTriggerVoiceMessage.tsx";
import { getAccountData, showNotification } from "../../../../helpers/util/functions.tsx";
import AddMoreTagModal from "./AddMoreTagModal.tsx";

interface ITriggerModal {
    handleToggle: () => void;
}

const TriggersModal: React.FC<ITriggerModal> = ({ handleToggle }) => {
    const { campaignTrigger, campaignSettings, selectedCampaign, isCampaignTriggerUpdatingSuccess, connectedEmails } = useSelector(emailCampaignState);
    const dispatch = useDispatch();
    const [triggerSettingStatus, setTriggerSettingStatus] = useState({
        [CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_ANOTHER_CAMPAIGN_SETTINGS]: false,
        [CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_POWER_DIALER_LIST_SETTINGS]: false,
        [CAMPAIGN_TRIGGER_SETTINGS.ADD_TAG_SETTINGS]: false,
        [CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE]: false,
        [CAMPAIGN_TRIGGER_SETTINGS.BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS]: false,
        [CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS]: false,
        [CAMPAIGN_TRIGGER_SETTINGS.OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS]: false,
        [CAMPAIGN_TRIGGER_SETTINGS.SWITCH_EMAIL_SENEDER_SETTINGS]: false,
    });
    const [triggerSettingDetails, setTriggerSettingDetails] = useState({
        [CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_ANOTHER_CAMPAIGN_SETTINGS]: {
            uid: "",
            remove_from_current_campaign: false,
        },
        [CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_POWER_DIALER_LIST_SETTINGS]: {
            uid: "",
        },
        [CAMPAIGN_TRIGGER_SETTINGS.ADD_TAG_SETTINGS]: {
            uid: "",
        },
        [CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE]: {
            voice_uri: "",
            virtual_number_id: "",
            file_type: "",
        },
        [CAMPAIGN_TRIGGER_SETTINGS.BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS]: {
            BOUNCE_RATE: 20,
            BOUNCE_RATE_CHECK_LIMIT: 1000,
        },
        [CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS]: {
            DELIVERY_RATE: 20,
            DELIVERY_RATE_CHECK_LIMIT: 1000,
        },
        [CAMPAIGN_TRIGGER_SETTINGS.OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS]: {
            OPEN_RATE: 20,
            OPEN_RATE_CHECK_LIMIT: 1000,
        },
        [CAMPAIGN_TRIGGER_SETTINGS.SWITCH_EMAIL_SENEDER_SETTINGS]: [],
    });
    const [uploadingServer, setUploadingServer] = useState(false);
    const [addMoreTagModal, setAddMoreTagModal] = useState(false);

    useEffect(() => {
        if (campaignSettings) {
            let settings = Object.values(CAMPAIGN_TRIGGER_SETTINGS);
            let tempSettings = { ...triggerSettingStatus };
            let tempDetails = { ...triggerSettingDetails };

            Object.values(campaignSettings).forEach((campaignSetting) => {
                if (settings.includes(campaignSetting[0].settingsTitle)) {
                    tempSettings[campaignSetting[0].settingsTitle] = campaignSetting[0].status === 'ACTIVE';
                    tempDetails[campaignSetting[0].settingsTitle] = campaignSetting[0].settingsDetails || tempDetails[campaignSetting[0].settingsTitle];

                    if (campaignSetting[0].settingsTitle === CAMPAIGN_TRIGGER_SETTINGS.SWITCH_EMAIL_SENEDER_SETTINGS && !campaignSetting[0].settingsDetails) {
                        if (connectedEmails?.campaignConnectedEmails && Array.isArray(connectedEmails?.campaignConnectedEmails)) {
                            tempDetails[campaignSetting[0].settingsTitle] = connectedEmails?.campaignConnectedEmails.map((campaignConnectedEmail) => ({
                                FROM_EMAIL: campaignConnectedEmail.userConnectedEmail,
                                TO_EMAIL: "",
                                BOUNCE_RATE: 20,
                                BOUNCE_RATE_CHECK_LIMIT: 1000
                            }));
                        }
                    }
                }
            });
            setTriggerSettingDetails(tempDetails);
            setTriggerSettingStatus(tempSettings);
        }
    }, [campaignSettings]);

    useEffect(() => {
        if (isCampaignTriggerUpdatingSuccess) {
            handleToggle();
            dispatch(cleanUpdateCampaignBulkSettings());
        }
    }, [isCampaignTriggerUpdatingSuccess]);

    useEffect(() => {
        dispatch(getTriggerCampaignList({ campaignUid: selectedCampaign?.uid }));
        dispatch(getTriggerPowerDialerList());
        dispatch(getTriggerTagList());
        dispatch(getTriggerVirtualNumberList());
    }, []);

    const getSettingsDetails = (setting: string, key: string | null) => {
        if (triggerSettingDetails && triggerSettingDetails[setting]) {
            if (key) {
                const settingsDetails: { [key: string]: any } = triggerSettingDetails[setting];
                return settingsDetails ? settingsDetails[key] : null;
            } else {
                return triggerSettingDetails[setting];
            }
        }
        return null;
    };

    const getStatus = (setting: string) => {
        if (triggerSettingStatus && triggerSettingStatus[setting]) {
            return triggerSettingStatus[setting];
        }
        return false;
    };

    const handleToggleStatus = (setting: string) => {
        setTriggerSettingStatus((prevState) => ({ ...prevState, [setting]: !prevState[setting] }))
    };

    const updateTriggerSettingDetails = (value: string, setting: string, key: string, extraData: any) => {
        setTriggerSettingDetails((prevState) => ({ ...prevState, [setting]: { ...prevState[setting], [key]: value } }));
    };

    const handleChangeSelect = (event: SelectChangeEvent<unknown>, setting: string, key: string) => {
        let value = event.target.value;
        updateTriggerSettingDetails(value, setting, key);
    };

    const handleSubmit = () => {
        if (!validateSettings()) {
            showNotification("error", "Please fill out required fields");
            return;
        }
        let payload = [];
        let settings = Object.values(CAMPAIGN_TRIGGER_SETTINGS);

        settings.forEach((setting) => {
            payload.push({
                status: triggerSettingStatus[setting] ? 'ACTIVE' : 'INACTIVE',
                settingsTitle: setting,
                settingsDetails: triggerSettingDetails[setting]
            });
        });

        dispatch(updateCampaignBulkSettings({ campaignUid: selectedCampaign.uid, settings: payload }));
    };

    const validateSettings = () => {
        let isValid = true;
        Object.keys(triggerSettingStatus).forEach((setting: string) => {
            if (triggerSettingStatus[setting]) {
                const details = triggerSettingDetails[setting];
                switch (setting) {
                    case CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_ANOTHER_CAMPAIGN_SETTINGS:
                        if (!details.uid) isValid = false;
                        break;
                    case CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_POWER_DIALER_LIST_SETTINGS:
                        if (!details.uid) isValid = false;
                        break;
                    case CAMPAIGN_TRIGGER_SETTINGS.ADD_TAG_SETTINGS:
                        if (!details.uid) isValid = false;
                        break;
                    case CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE:
                        if (!details.voice_uri || !details.virtual_number_id || !details.file_type) isValid = false;
                        break;
                    case CAMPAIGN_TRIGGER_SETTINGS.SWITCH_EMAIL_SENEDER_SETTINGS:
                        if (details.length === 0) isValid = false;
                        break;
                    default:
                        break;
                }
            }
        });
        return isValid;
    };

    return (
        <CustomScrollbar>
            <Stack spacing={1} sx={{ position: 'relative' }}>
                <Stack paddingY={2} paddingX={3}>
                    <Box pb={3}>
                        <Typography variant={'body2'} color={'text.primary'}>
                            <b>When an email is opened or clicked…</b>
                        </Typography>
                        <Typography variant={'caption'} color={'text.primary'}>
                            To trigger a specific action when an email is opened or clicked, select an option below
                        </Typography>
                    </Box>
                    <Stack spacing={2.5}>
                        <AddToTrigger
                            icon={<MarketingIcon />}
                            title='Add contact to another campaign'
                            description='This trigger will automatically add the contact to another campaign'
                            open={getStatus(CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_ANOTHER_CAMPAIGN_SETTINGS)}
                            handleToggle={() => {
                                handleToggleStatus(CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_ANOTHER_CAMPAIGN_SETTINGS);
                            }}
                            selectedValueLabel={'Select a Campaign'}
                            selectedValue={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_ANOTHER_CAMPAIGN_SETTINGS, "uid")}
                            handleChangeSelectedValue={(event) => { handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_ANOTHER_CAMPAIGN_SETTINGS, "uid") }}
                            selectFieldItems={campaignTrigger.campaignList}
                            selectFieldItemLabelKey={'title'}
                            selectFieldItemValueKey={'uid'}
                            checkboxFieldLabel={'Remove contact from the existing campaign'}
                            checkboxFieldValue={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_ANOTHER_CAMPAIGN_SETTINGS, "remove_from_current_campaign")}
                            handleChangeCheckboxField={(event) => { setTriggerSettingDetails((prevState) => ({ ...prevState, [CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_ANOTHER_CAMPAIGN_SETTINGS]: { ...prevState[CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_ANOTHER_CAMPAIGN_SETTINGS], "remove_from_current_campaign": event } })); }}
                        />
                        <AddToTrigger
                            icon={<CallIcon />}
                            title='Add contact to a power dialer list'
                            description='This trigger will automatically add the contact to a power dialer list'
                            open={getStatus(CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_POWER_DIALER_LIST_SETTINGS)}
                            handleToggle={() => {
                                handleToggleStatus(CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_POWER_DIALER_LIST_SETTINGS);
                            }}
                            selectedValueLabel={'Select a Power Dialer List'}
                            selectedValue={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_POWER_DIALER_LIST_SETTINGS, "uid")}
                            handleChangeSelectedValue={(event) => { handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.ADD_TO_POWER_DIALER_LIST_SETTINGS, "uid") }}
                            selectFieldItems={campaignTrigger.powerDialerList}
                            selectFieldItemLabelKey={'title'}
                            selectFieldItemValueKey={'id'}
                        />
                        {
                            [1, 446, 6991].includes(getAccountData('userId')) &&
                            <AddToTriggerVoiceMessage
                                icon={<VoiceIcon />}
                                title='Send a voice message'
                                description='You can send a voice message to contact as trigger'
                                open={getStatus(CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE)}
                                handleToggle={() => {
                                    handleToggleStatus(CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE);
                                }}
                                selectedValueLabel={'Select a Virtual Number'}
                                selectedValue={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE, "virtual_number_id")}
                                handleChangeSelectedValue={(event) => { handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE, "virtual_number_id") }}
                                selectFieldItems={campaignTrigger.virtualNumbers}
                                selectFieldItemLabelKey={'virtualNumber'}
                                selectFieldItemValueKey={'virtualNumberId'}
                                uploadingServer={uploadingServer}
                                setUploadingServer={setUploadingServer}
                                selectedAudioFileUri={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE, "voice_uri")}
                                handleSelectAudioFileUri={(uri: string) => { setTriggerSettingDetails((prevState) => ({ ...prevState, [CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE]: { ...prevState[CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE], "voice_uri": uri } })); }}
                                handleSelectAudioFileType={(fileType: string) => { setTriggerSettingDetails((prevState) => ({ ...prevState, [CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE]: { ...prevState[CAMPAIGN_TRIGGER_SETTINGS.SEND_VOICE_MESSAGE], "file_type": fileType } })); }}
                            />
                        }
                        <AddToTrigger
                            icon={<HashTagIcon />}
                            title='Add a tag'
                            description='This trigger will automatically add a tag to the contact record'
                            open={getStatus(CAMPAIGN_TRIGGER_SETTINGS.ADD_TAG_SETTINGS)}
                            handleToggle={() => {
                                handleToggleStatus(CAMPAIGN_TRIGGER_SETTINGS.ADD_TAG_SETTINGS);
                            }}
                            selectedValueLabel={'Select a Tag'}
                            selectedValue={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.ADD_TAG_SETTINGS, "uid")}
                            handleChangeSelectedValue={(event) => { handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.ADD_TAG_SETTINGS, "uid") }}
                            selectFieldItems={campaignTrigger.tagList}
                            selectFieldItemLabelKey={'name'}
                            selectFieldItemValueKey={'uuid'}
                            handleCreateMore={() => { setAddMoreTagModal(true); }}
                        />
                    </Stack>
                </Stack>

                <Divider light variant={'fullWidth'} sx={{ width: '100%', marginTop: '20px' }} />
                <Stack paddingY={2} paddingX={3}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Box pb={2.5}>
                            <Typography variant={'body2'} color={'text.primary'}>
                                <b>Pause my email campaign if...</b>
                            </Typography>
                            <Typography variant={'caption'} color={'text.primary'}>
                                To automatically pause your campaign if a specific condition is met, select an option below
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack spacing={2.5}>
                        <CampaignPause
                            icon={<SmsTrackingIcon />}
                            triggerName={CAMPAIGN_TRIGGER_SETTINGS.BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS}
                            needCollapseOptions={true}

                            title='Pause campaign due to high email bounce rate'
                            description='This trigger will pause your campaign if your bounce rate exceeds a certain level'
                            open={getStatus(CAMPAIGN_TRIGGER_SETTINGS.BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS)}
                            handleToggle={() => {
                                handleToggleStatus(CAMPAIGN_TRIGGER_SETTINGS.BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS);
                            }}
                            selectedValueLabel='Bounce rate more than'
                            selectedValue={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "BOUNCE_RATE")}
                            handleChangeSelectedValue={(event) => { handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "BOUNCE_RATE") }}
                            selectedRateLimit={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "BOUNCE_RATE_CHECK_LIMIT")}
                            handleChangeSelectedRateLimit={(event) => { handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "BOUNCE_RATE_CHECK_LIMIT") }}
                        />
                        <CampaignPause
                            icon={<SmsNotificationIcon />}
                            triggerName={CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS}
                            needCollapseOptions={true}
                            title='Pause on email delivery rate '
                            description='This trigger will pause your campaign if your delivery rate is below a certain level'
                            open={getStatus(CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS)}
                            handleToggle={() => {
                                handleToggleStatus(CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS);
                            }}
                            selectedValueLabel='Delivery rate less than'
                            selectedValue={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "DELIVERY_RATE")}
                            handleChangeSelectedValue={(event) => { handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "DELIVERY_RATE") }}
                            selectedRateLimit={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "DELIVERY_RATE_CHECK_LIMIT")}
                            handleChangeSelectedRateLimit={(event) => { handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "DELIVERY_RATE_CHECK_LIMIT") }}
                        />
                        <CampaignPause
                            icon={<SmsNotificationIcon />}
                            triggerName={CAMPAIGN_TRIGGER_SETTINGS.CONTACT_REPLY_WISE_CAMPAIGN_PAUSE_SETTINGS}
                            needCollapseOptions={false}
                            title='Pause campaign if contact replies'
                            description='This trigger will pause your campaign if contact replies'
                            open={getStatus(CAMPAIGN_TRIGGER_SETTINGS.CONTACT_REPLY_WISE_CAMPAIGN_PAUSE_SETTINGS)}
                            handleToggle={() => {
                                handleToggleStatus(CAMPAIGN_TRIGGER_SETTINGS.CONTACT_REPLY_WISE_CAMPAIGN_PAUSE_SETTINGS);
                            }}
                            selectedValueLabel='Delivery rate less than'
                            selectedValue={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "DELIVERY_RATE")}
                            handleChangeSelectedValue={(event) => { handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "DELIVERY_RATE") }}
                            selectedRateLimit={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "DELIVERY_RATE_CHECK_LIMIT")}
                            handleChangeSelectedRateLimit={(event) => { handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "DELIVERY_RATE_CHECK_LIMIT") }}
                        />
                        {/* <CampaignPause
                            icon={<SmsIcon />}
                            triggerName={CAMPAIGN_TRIGGER_SETTINGS.OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS}
                            needCollapseOptions={true}

                            title='Pause on email open rate'
                            description='Campaign will pause after the condition'
                            open={getStatus(CAMPAIGN_TRIGGER_SETTINGS.OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS)}
                            handleToggle={()=>{
                                handleToggleStatus(CAMPAIGN_TRIGGER_SETTINGS.OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS);
                            }}
                            selectedValueLabel='Open rate less than'
                            selectedValue={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "OPEN_RATE")}
                            handleChangeSelectedValue={(event)=>{ handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS,"OPEN_RATE") }}
                            selectedRateLimit={getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS, "OPEN_RATE_CHECK_LIMIT")}
                            handleChangeSelectedRateLimit={(event)=>{ handleChangeSelect(event, CAMPAIGN_TRIGGER_SETTINGS.OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS,"OPEN_RATE_CHECK_LIMIT") }}
                        /> */}
                    </Stack>
                </Stack>
                <Divider light variant={'fullWidth'} sx={{ width: '100%', marginTop: '20px' }} />

                <Stack paddingY={2} paddingX={3}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Box pb={2.5}>
                            <Typography variant={'body2'} color={'text.primary'}>
                                <b>Switch my email account if…</b>
                            </Typography>
                            <Typography variant={'caption'} color={'text.primary'}>
                                This trigger will automatically update the email address used to send emails if specific conditions are met
                            </Typography>
                        </Box>
                    </Stack>
                    <SwitchEmailSender
                        icon={<SmsIcon />}
                        title='Update my email account due to a high bounce/failure rate'
                        description='This trigger will automatically update the email address used to send emails if your bounce/failure rate exceeds a certain level'
                        open={getStatus(CAMPAIGN_TRIGGER_SETTINGS.SWITCH_EMAIL_SENEDER_SETTINGS)}
                        handleToggle={() => {
                            handleToggleStatus(CAMPAIGN_TRIGGER_SETTINGS.SWITCH_EMAIL_SENEDER_SETTINGS);
                        }}
                        getSettingsDetails={getSettingsDetails}
                        handleChangeSelect={(data) => { setTriggerSettingDetails((prevState) => ({ ...prevState, [CAMPAIGN_TRIGGER_SETTINGS.SWITCH_EMAIL_SENEDER_SETTINGS]: data })) }}
                    />
                </Stack>

                <Stack bgcolor={'common.white'} position={'sticky'} bottom={0}>
                    <Divider light variant={'fullWidth'} sx={{ width: '100%', marginTop: '20px' }} />
                    <Stack direction={'row'} justifyContent={'flex-end'} p='16px 20px' gap={2} sx={{ background: 'common.white' }}>
                        <Button variant={'tonal'} color={'inherit'} size={'large'} onClick={handleToggle}>
                            Cancel
                        </Button>
                        <Button disabled={uploadingServer} variant={'contained'} size={'large'} color={'primary'} onClick={handleSubmit}>
                            Save Changes
                        </Button>
                    </Stack>
                </Stack>
            </Stack>

            <ModalWithHeader open={addMoreTagModal} onClose={() => { setAddMoreTagModal(false); }} title='Create New Tag'>
                <AddMoreTagModal
                    onClose={() => { setAddMoreTagModal(false); }}
                    updateTriggerSettingDetails={updateTriggerSettingDetails}
                />
            </ModalWithHeader>
        </CustomScrollbar>
    );
};

export default TriggersModal;
