import React, { useEffect, useState } from 'react';

import { Box, IconButton, MarketingIcon, Paper, Stack, TextField, Typography } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import useDebounce from '../../../../hooks/useDebounce';
import { updateCampaign } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { CustomTextField } from "../../BulkEmailStyle.tsx";
import { CampaignIcon } from '../../../../assets/Icons.tsx';

const CampaignName: React.FC = () => {
    const dispatch = useDispatch();
    const { selectedCampaign } = useSelector(emailCampaignState);

    const [campaignName, setCampaignName] = useState(selectedCampaign?.title);

    const debouncedTitle = useDebounce(campaignName, 500);

    const handleCampaignNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCampaignName(event.target.value);
    };

    useEffect(() => {
        if (selectedCampaign?.title !== campaignName) dispatch(updateCampaign({ uid: selectedCampaign?.uid, title: campaignName, oldValue: selectedCampaign?.title }));
    }, [debouncedTitle]);

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: '18px 20px' }}>
            <Box width={960}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <IconButton variant={'rounded'} size={'medium'} color={'primary'} sx={{ backgroundColor: 'primary.light' }}>
                        <CampaignIcon height={22.5} width={22.5} />
                    </IconButton>
                    <Stack flex={1} spacing={2}>
                        <Typography variant={'subtitle1'} color={'text.secondary'}>
                            <b>Campaign Name</b>
                        </Typography>
                    </Stack>
                </Stack>
                <Stack flex={1} spacing={2} paddingLeft={'48px'} paddingTop={2}>
                    <CustomTextField
                        id='outlined-basic'
                        fullWidth
                        placeholder='Bulk Email Campaign 2'
                        value={campaignName}
                        onChange={handleCampaignNameChange}
                    />
                </Stack>
            </Box>
        </Paper>
    );
};

export default CampaignName;
