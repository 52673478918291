import React, { useEffect, useState } from "react";
import {
    Box, Button,
    Divider,
    IconButton,
    LayerOutlinedIcon,
    Paper,
    Select,
    Stack,
    Typography
} from "convertupleads-theme";
import { EMAIL_THREAD } from "../../../../helpers/constant/coreConstants.ts";
import SingleThreadEmailSubject from "./SingleThreadEmailSubject.tsx";
import { useDispatch, useSelector } from "react-redux";
import { updateEmailThread } from "../../../../state/features/emailCampaign/emailCampaignSlice.ts";
import { IEmailThreadPayload } from "../../../../state/features/emailCampaign/emailCampaign.interface.ts";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import PopupAlert from "../../../common/PopupAlert";
import { CustomMenuItem } from "../../BulkEmailStyle.tsx";

const MenuProps = {
    PaperProps: {
        style: {
            paddingLeft: '8px',

            '& .MuiMenuItem-root': {
                color: 'gold !important'
            }
        },
    },
};

const EmailThread:React.FC = () => {
    const dispatch = useDispatch();
    const { selectedCampaign, isUpdateEmailThreadLoading } = useSelector(emailCampaignState);
    const [thread, setThread] = useState(EMAIL_THREAD.SINGLE_THREAD);
    const [messageSubject, setMessageSubject] = useState("");
    const [isCampaignEmailThreadChangeAlertModal, setIsCampaignEmailThreadChangeAlertModal] = useState(false);

    useEffect(() => {
        if (selectedCampaign) {
            setMessageSubject(selectedCampaign.commonEmailSubject || "");
            setThread(selectedCampaign.emailThreads);
        }
    }, [selectedCampaign]);

    const handleSubmit = () => {
        if (selectedCampaign) {
            let payload: IEmailThreadPayload = {
                campaignUid: selectedCampaign?.uid as string,
                emailThreads: thread,
            };

            if (thread === EMAIL_THREAD.SINGLE_THREAD) {
                payload.commonEmailSubject = messageSubject
            }

            dispatch(updateEmailThread(payload));
        }

    };

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: '0px 20px', mt: 2.5, '& .MMuiList-root .Mui-selected': { backgroundColor: 'gold !important', color: 'gold !important', } }}>
            <Box width={960}>
                <Stack direction={'row'} justifyContent={'space-between'} spacing={1} sx={{ cursor: 'pointer', my: 2 }}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <IconButton variant={'rounded'} size={'medium'} color={'primary'} sx={{ backgroundColor: 'primary.light' }}>
                            <LayerOutlinedIcon />
                        </IconButton>
                        <Stack flex={1}>
                            <Typography variant={'subtitle1'} color={'text.secondary'}>
                                <b>Email Thread</b>
                            </Typography>
                            <Typography variant={'caption'} color={'text.secondary'}>
                                Select your desired email thread style
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>

                <Divider light />

                <Stack spacing={1} pt={2} pl={'48px'} pb={'18px'}>
                    <Typography color={'text.secondary'} variant={'body2'}>
                        Email Thread
                    </Typography>
                    <Select MenuProps={MenuProps} displayEmpty onChange={(event) => { setThread(event.target.value as string) }} value={thread} fullWidth>
                        <CustomMenuItem value={''} disabled>
                            Select Email Thread
                        </CustomMenuItem>
                        <CustomMenuItem value={EMAIL_THREAD.SINGLE_THREAD}>
                            Single Thread
                        </CustomMenuItem>
                        <CustomMenuItem value={EMAIL_THREAD.MULTI_THREAD}>
                            Multi Thread
                        </CustomMenuItem>
                    </Select>

                    {
                        thread === EMAIL_THREAD.SINGLE_THREAD &&
                        <SingleThreadEmailSubject
                            messageSubject={messageSubject}
                            setMessageSubject={setMessageSubject}
                        />
                    }
                </Stack>
                <Stack pb={2.5} direction={'row'} justifyContent={'flex-end'}>
                    <Button disabled={isUpdateEmailThreadLoading} variant={'contained'} size={'large'} color={'primary'} onClick={() => {
                        if (thread === EMAIL_THREAD.SINGLE_THREAD && (!messageSubject || !messageSubject.trim())) {
                            return;
                        }

                        if (selectedCampaign?.emailThreads === EMAIL_THREAD.MULTI_THREAD) {
                            setIsCampaignEmailThreadChangeAlertModal(true);
                        } else {
                            handleSubmit();
                        }
                    }}>
                        Save Changes
                    </Button>
                </Stack>
            </Box>

            <PopupAlert
                title={"Do you want to make this campaign single thread?"}
                description={"If you make single thread all of your sequence subject will be replaced by this subject!"}
                open={isCampaignEmailThreadChangeAlertModal}
                onSubmit={() => {
                    handleSubmit();
                    setIsCampaignEmailThreadChangeAlertModal(false);
                }}
                onCancel={() => {
                    setIsCampaignEmailThreadChangeAlertModal(false);
                }}
            />
        </Paper>
    );
};

export default EmailThread;