import React, { useState } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, SharedIcon, Typography } from 'convertupleads-theme';
import ShareModal from '../ShareModal';
import { IEmailCampaign } from '../../../../../state/features/emailCampaign/emailCampaign.interface';

interface IShareCampaign {
    onClose: () => void;
    campaign: IEmailCampaign;
}

const ShareCampaign: React.FC<IShareCampaign> = ({ campaign, onClose }) => {
    const [openShareModal, setOpenShareModal] = useState(false);

    const handleShareModal = () => {
        setOpenShareModal(true);
    };

    return (
        <>
            <ListItemButton onClick={handleShareModal}>
                <ListItemIcon>
                    <SharedIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText>
                    <Typography color={'text.primary'} variant='body2' fontWeight={400}>
                        Share Campaign
                    </Typography>
                </ListItemText>
            </ListItemButton>
            <ShareModal campaign={campaign} open={openShareModal} setOpen={setOpenShareModal} onClose={onClose} />
        </>
    );
};

export default ShareCampaign;
