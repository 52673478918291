import React from "react";
import { Stack } from "convertupleads-theme";
import CampaignEmailBodyHeader from "./CampaignEmailBodyHeader";
import CampaignSequenceStats from "./CampaignSequenceStats.tsx";
import EmailSender from "./EmailSender";
import EmailSubject from "./EmailSubject";
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector";
import { CAMPAIGN_STATUS } from "../../../../helpers/constant/coreConstants";
interface IEmailBodyTopSection {
  toggleDrawer: () => void;
  showError: boolean;
  goStep: (step: number) => void;
}
const CampaignSequenceBodyTop: React.FC<IEmailBodyTopSection> = ({
  toggleDrawer,
  showError,
    goStep
}) => {
  const { selectedCampaign } = useSelector(emailCampaignState);

  const renderCampaignSequenceStats = () =>
    selectedCampaign?.status !== CAMPAIGN_STATUS.ACTIVE && <CampaignSequenceStats />;

  return (
    <Stack>
      <CampaignEmailBodyHeader toggleDrawer={toggleDrawer} />

      {renderCampaignSequenceStats()}

      <EmailSender />

      <EmailSubject goStep={goStep} showError={showError} />
    </Stack>
  );
};

export default CampaignSequenceBodyTop;
