import React, { useState } from "react";
import {
  AIFilledIcon,
  IconButton,
  List,
  MoreHorizontalIcon,
  Popover,
  Stack,
  Typography,
  useTheme,
  ListItemButton,
  ListItemIcon,
  EditFilledIcon,
  ListItemText,
  DeleteIcon,
  DeleteModal,
} from "convertupleads-theme";
import AddOrEditVariationModal from "./AddOrEditVariationModal";
import { ISequenceVariation } from "../../../../state/features/emailCampaign/emailCampaign.interface";
import { deleteSequenceVariation } from "../../../../state/features/emailCampaign/emailCampaignSlice";
import { useDispatch } from "react-redux";
import { CustomScrollbar } from "../../../common/CustomScrollBarStyle";

interface Props {
  variation?: ISequenceVariation;
}
const EachMultipleEmailVariation: React.FC<Props> = ({ variation }) => {
  const [addOrEditModal, setAddOrEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch()

  const { title, subject, body } = variation || {};

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const openPopover = Boolean(anchorEl);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    // handleModal();
    setAddOrEditModal(true);
    handlePopoverClose();
  };

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSequenceVariation({uid: variation?.uid as string}));
    setDeleteModal(false);
    handlePopoverClose();
  };

  const theme = useTheme();

  return (
    <>
      <Stack
        borderRadius={1}
        mt={"20px"}
        padding={"20px"}
        sx={{ border: `1px solid ${theme.palette.other.divider}` }}
        spacing={1}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <AIFilledIcon color="secondary" sx={{ fontSize: "18px" }} />
            <Typography variant="body2" color={"secondary"}>
              {title}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              color={"secondary"}
              onClick={handleOpenPopover}
              sx={{ ":focus": { bgcolor: "transparent !important" } }}
            >
              <MoreHorizontalIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </Stack>
        </Stack>

        <Stack spacing={0.5}>
          <Typography variant="body1" color={"text.primary"}>
            {subject}
          </Typography>

          <CustomScrollbar sx={{ maxHeight: 100 }}>
            <Typography
              variant="body2"
              component={'span'}
              color={"text.secondary"}
              sx={{
                display: "-webkit-box",
                // WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textWrap: "wrap",
                // maxWidth: '90%',
                '> p': {
                  margin: `0 !important`
                }
              }}
              dangerouslySetInnerHTML={{__html: body || ''}}
            >
            </Typography>
          </CustomScrollbar>
        </Stack>
      </Stack>

      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            style: { borderRadius: "12px" },
          },
        }}
      >
        <List component="div" aria-label="main mailbox folders" dense>
          <ListItemButton onClick={handleEdit}>
            <ListItemIcon>
              <EditFilledIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                color={"text.primary"}
                variant="body2"
                fontWeight={600}
              >
                Edit
              </Typography>
            </ListItemText>
          </ListItemButton>

          <ListItemButton onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                color={"text.primary"}
                variant="body2"
                fontWeight={600}
              >
                Delete
              </Typography>
            </ListItemText>
          </ListItemButton>
        </List>
      </Popover>

      <AddOrEditVariationModal
        open={addOrEditModal}
        onClose={() => setAddOrEditModal(false)}
        edit={variation}
      />

      <DeleteModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        title="Are you sure, You want to delete this variation?"
        // warningContent='You are able to leave this page without saving. All changes will be lost.'
        onConfirm={handleConfirmDelete}
        sx={{
          '& .MuiButton-tonalInherit:focus': { bgcolor: 'other.outlinedBorder' }, 
          '& .MuiButton-colorError:focus': { bgcolor: 'error.main' }
        }}
      />
    </>
  );
};

export default EachMultipleEmailVariation;
