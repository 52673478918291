import React, { useEffect, useState } from 'react';
import {
    Box,
    Checkbox,
    Collapse,
    Divider,
    FormControlLabel,
    FormGroup,
    Paper,
    Stack,
    Typography,
} from 'convertupleads-theme';
import CustomSettingHeader from './CustomSettingHeader';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector.ts';
import { updateCampaignSettings } from '../../../../state/features/emailCampaign/emailCampaignSlice.ts';
import {
    IUpdateCampaignSettings,
} from '../../../../state/features/emailCampaign/emailCampaign.interface.ts';
import { AbTestingIcon } from '../../../../assets/Icons.tsx';

interface ABTestingProps {
    onSaveChanges: (selectedSettings: { [key: string]: boolean }) => void;
}

const ABTesting: React.FC<ABTestingProps> = () => {
    const dispatch = useDispatch();
    const [toggleState, setToggleState] = useState(false);
    const { selectedCampaign, campaignSettings } = useSelector(emailCampaignState);



    useEffect(() => {
        if (campaignSettings && campaignSettings["A/B_TESTING"]) {
            const status = campaignSettings["A/B_TESTING"][0].status;
            setToggleState(status === "ACTIVE");
        }
    }, [campaignSettings]);

    const getStatus = (key: string) => {
        if (campaignSettings && campaignSettings["A/B_TESTING"]) {
            const settingsDetails: { [key: string]: any } = campaignSettings["A/B_TESTING"][0].settingsDetails;
            return settingsDetails[key] === "ACTIVE";
        }
        return false;
    };

    const handleUpdate = (key: string, value: boolean | string, oldValue: boolean | string) => {
        if (selectedCampaign && selectedCampaign.uid && campaignSettings && campaignSettings["A/B_TESTING"]) {
            const payload: IUpdateCampaignSettings = { campaignUid: selectedCampaign.uid, settingsTitle: "A/B_TESTING" };

            if (key === "status") {
                setToggleState(String(value) === "ACTIVE");
                payload.status = String(value);
                payload.oldValue = String(oldValue);
                setToggleState((prev) => !prev)
            } else {
                payload.settingsDetails = { ...campaignSettings["A/B_TESTING"][0].settingsDetails, [key]: value ? 'ACTIVE' : 'INACTIVE' };
                payload.oldValue = { ...campaignSettings["A/B_TESTING"][0].settingsDetails, [key]: value ? 'INACTIVE' : 'ACTIVE' };
            }

            dispatch(updateCampaignSettings(payload));
        }
    };

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: '0px 20px', mt: 2.5 }}>
            <Box width={960}>
                <CustomSettingHeader
                    title={'A/B Testing'}
                    subTitle={'This setting will track the performance of multiple email variations. After 25% of the campaign has finished we will evaluate which variation is working best. The winning email variation will then be used for the remainder of the campaign.'}
                    icon={<AbTestingIcon />}
                    isAccordionOpen={toggleState}
                    handleToggle={() => {
                        handleUpdate("status", toggleState ? "INACTIVE" : "ACTIVE", toggleState ? "ACTIVE" : "INACTIVE");
                    }}
                    isShowButton={false}
                />

                <Collapse in={toggleState}>
                    <Divider light />
                    <Stack pl='50px' paddingY={'16px'}>
                        <Stack direction={'row'} spacing={1} gap={1}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            size={'small'}
                                            checked={getStatus('TRACK_OPEN_EMAIL')}
                                            onChange={(_event, checked) => {
                                                handleUpdate('TRACK_OPEN_EMAIL', checked, getStatus('TRACK_OPEN_EMAIL'))
                                            }}
                                        />
                                    }
                                    sx={{ color: 'text.secondary' }}
                                    label='Track Email Opens'
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            size={'small'}
                                            checked={getStatus('TRACK_LINK_CLICK')}
                                            onChange={(_event, checked) => {
                                                handleUpdate('TRACK_LINK_CLICK', checked, getStatus('TRACK_LINK_CLICK'))
                                            }}
                                        />
                                    }
                                    sx={{ color: 'text.secondary' }}
                                    label='Track Link Clicks'
                                />
                            </FormGroup>
                        </Stack>
                        <Typography variant={'caption'} color={'text.secondary'}>
                            This action will be executed when the campaign is 25% complete.
                        </Typography>
                    </Stack>
                </Collapse>
            </Box>
        </Paper>
    );
};

export default ABTesting;
