import { ApiCodeIcon, FacebookIcon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popover, useTheme } from 'convertupleads-theme';

type IProps = {
    connectId: string | undefined;
    openConnect: boolean;
    connect: HTMLSpanElement | SVGSVGElement | null;
    handleClose: () => void;
};
const ConnectPopOver = ({ connectId, openConnect, connect, handleClose }: IProps) => {
    const theme = useTheme();
    return (
        <Popover
            id={connectId}
            open={openConnect}
            anchorEl={connect}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            slotProps={{
                paper: {
                    style: { borderRadius: '12px' },
                },
            }}
            sx={{ mt: 1 }}
        >
            <Paper sx={{ width: 'fit-content', p: 1, minWidth: '211px' }}>
                <List
                    sx={{ bgcolor: theme.palette.grey[50], borderRadius: 1.5 }}
                    component='div'
                    aria-label='main mailbox folders'
                    disablePadding
                    dense
                >
                    <ListItem
                        disablePadding
                        onClick={() => {}}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <ApiCodeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Connect Via API" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem
                        disablePadding
                        onClick={() => {}}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <FacebookIcon />
                            </ListItemIcon>
                            <ListItemText primary="Connect Via Facebook" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Paper>
        </Popover>
    );
};
export default ConnectPopOver;
