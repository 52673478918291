import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Stack,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Collapse,
    Divider,
    Typography
} from 'convertupleads-theme';
import CustomSettingHeader from './CustomSettingHeader';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector.ts';
import { IUpdateCampaignSettings } from '../../../../state/features/emailCampaign/emailCampaign.interface.ts';
import {
    deselectCampaign,
    getEmailLookupPrice,
    updateCampaignSettings
} from '../../../../state/features/emailCampaign/emailCampaignSlice.ts';
import {
    CAMPAIGN_SETTINGS_TITLE,
    CAMPAIGN_STATUS,
    LOOKUP__ACTION_RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL,
    LOOKUP__ACTION_SEND_DELIVERABLE,
    LOOKUP__ACTION_SEND_RISKY,
    LOOKUP__ACTION_SEND_UNDELIVERABLE
} from '../../../../helpers/constant/coreConstants.ts';
import {cleanNumber} from "../../../../helpers/util/functions.tsx";
import PopupAlert from "../../../common/PopupAlert";
import { ManageSearchIcon } from '../../../../assets/Icons.tsx';

interface LookupEmailProps {
    onSaveChanges: (selectedSettings: { [key: string]: boolean }) => void;
}

const LookupEmail: React.FC<LookupEmailProps> = () => {
    const dispatch = useDispatch();
    const [toggleState, setToggleState] = useState(false);
    const { selectedCampaign, campaignSettings, emailLookupPrice } = useSelector(emailCampaignState);
    const [showAlertForLookupPrice, setShowAlertForLookupPrice] = useState(false);

    useEffect(() => {
        if (!emailLookupPrice){
            dispatch(getEmailLookupPrice());
        }
    }, []);

    const getStatus = (key: string) => {
        if (campaignSettings && campaignSettings[CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL]) {
            const settingsDetails: { [key: string]: any } = campaignSettings[CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL][0].settingsDetails;
            return settingsDetails[key] === CAMPAIGN_STATUS.ACTIVE;
        }
        return false;
    };

    const handleUpdate = (key: string, value: boolean | string, oldValue: boolean | string) => {
        if (selectedCampaign && selectedCampaign.uid && campaignSettings && campaignSettings[CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL]) {
            const payload: IUpdateCampaignSettings = { campaignUid: selectedCampaign.uid, settingsTitle: CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL };

            if (key === "status") {
                setToggleState(String(value) === CAMPAIGN_STATUS.ACTIVE);
                payload.status = String(value);
                payload.oldValue = String(oldValue);
                if (String(value) === CAMPAIGN_STATUS.ACTIVE){
                    payload.settingsDetails = { ...campaignSettings[CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL][0].settingsDetails, [LOOKUP__ACTION_SEND_UNDELIVERABLE]: 'ACTIVE', [LOOKUP__ACTION_SEND_DELIVERABLE]: 'ACTIVE', [LOOKUP__ACTION_SEND_RISKY]: 'ACTIVE', [LOOKUP__ACTION_RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL]: 'ACTIVE' };
                }else {
                    payload.settingsDetails = { ...campaignSettings[CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL][0].settingsDetails, [LOOKUP__ACTION_SEND_UNDELIVERABLE]: 'INACTIVE', [LOOKUP__ACTION_SEND_DELIVERABLE]: 'INACTIVE', [LOOKUP__ACTION_SEND_RISKY]: 'INACTIVE', [LOOKUP__ACTION_RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL]: 'INACTIVE' };
                }
                setToggleState((prev) => !prev)
            } else {
                payload.settingsDetails = { ...campaignSettings[CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL][0].settingsDetails, [key]: value ? 'ACTIVE' : 'INACTIVE', [LOOKUP__ACTION_RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL]: 'ACTIVE' };
                payload.oldValue = { ...campaignSettings[CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL][0].settingsDetails, [key]: value ? 'INACTIVE' : 'ACTIVE' };
            }

            dispatch(updateCampaignSettings(payload));
        }
    };

    useEffect(() => {
        if (campaignSettings && campaignSettings[CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL]) {
            const status = campaignSettings[CAMPAIGN_SETTINGS_TITLE.LOOKUP_EMAIL][0].status;
            setToggleState(status === CAMPAIGN_STATUS.ACTIVE);
        }
    }, [campaignSettings]);

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: '0px 20px', mt: 2.5 }}>
            <Box width={960}>
                <CustomSettingHeader
                    title={'Validate Email Addresses'}
                    subTitle={'We recommend verifying your email list prior to starting a campaign. We’ll identify valid, invalid, and high risk email contacts.'}
                    icon={<ManageSearchIcon />}
                    isAccordionOpen={toggleState}
                    handleToggle={() => {
                        if (!toggleState){
                            setShowAlertForLookupPrice(true);
                        }else {
                            handleUpdate("status", toggleState ? CAMPAIGN_STATUS.INACTIVE : CAMPAIGN_STATUS.ACTIVE, toggleState ? CAMPAIGN_STATUS.ACTIVE : CAMPAIGN_STATUS.INACTIVE);
                        }
                    }}
                    isShowButton={false}
                />

                <Collapse in={toggleState}>
                    <Divider light />
                    <Stack pl='50px' paddingY={2}>
                    <Stack direction={'row'} spacing={1} gap={1}>
                            {/* <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            size={'small'}
                                            checked={getStatus(LOOKUP__ACTION_RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL)}
                                            onChange={(_event, checked) => {
                                                handleUpdate(LOOKUP__ACTION_RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL, checked, getStatus(LOOKUP__ACTION_RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL))
                                            }}
                                        />
                                    }
                                    sx={{ color: 'text.secondary' }}
                                    label='Runtime Lookup Validation For Email'
                                />
                            </FormGroup> */}
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            size={'small'}
                                            checked={getStatus(LOOKUP__ACTION_SEND_UNDELIVERABLE)}
                                            onChange={(_event, checked) => {
                                                handleUpdate(LOOKUP__ACTION_SEND_UNDELIVERABLE, checked, getStatus(LOOKUP__ACTION_SEND_UNDELIVERABLE))
                                            }}
                                        />
                                    }
                                    sx={{ color: 'text.secondary' }}
                                    label='Remove Invalid Emails'
                                />
                            </FormGroup>
                            {/*<FormGroup>*/}
                            {/*    <FormControlLabel*/}
                            {/*        control={*/}
                            {/*            <Checkbox*/}
                            {/*                color={'primary'}*/}
                            {/*                size={'small'}*/}
                            {/*                checked={getStatus(LOOKUP__ACTION_SEND_DELIVERABLE)}*/}
                            {/*                onChange={(_event, checked) => {*/}
                            {/*                    handleUpdate(LOOKUP__ACTION_SEND_DELIVERABLE, checked, getStatus(LOOKUP__ACTION_SEND_DELIVERABLE))*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*        sx={{ color: 'text.secondary' }}*/}
                            {/*        label='Send Deliverable'*/}
                            {/*    />*/}
                            {/*</FormGroup>*/}
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            size={'small'}
                                            checked={getStatus(LOOKUP__ACTION_SEND_RISKY)}
                                            onChange={(_event, checked) => {
                                                handleUpdate(LOOKUP__ACTION_SEND_RISKY, checked, getStatus(LOOKUP__ACTION_SEND_RISKY))
                                            }}
                                        />
                                    }
                                    sx={{ color: 'text.secondary' }}
                                    label='Remove Risky Email Addresses'
                                />
                            </FormGroup>
                        </Stack>
                        {
                            emailLookupPrice &&
                            <Typography variant='caption' color={'error'} py={1} fontStyle={'italic'}>Validating each email will cost: <b>${cleanNumber(emailLookupPrice.value)}</b></Typography>
                        }
                    </Stack>
                </Collapse>
            </Box>

            <PopupAlert
                title={`Validating each email will cost: $${cleanNumber(emailLookupPrice?.value as number | string)}`}
                description={"These credits will be deducted from your balance"}
                open={showAlertForLookupPrice}
                onSubmit={() => {
                    handleUpdate("status", CAMPAIGN_STATUS.ACTIVE, CAMPAIGN_STATUS.INACTIVE);
                    setShowAlertForLookupPrice(false);
                }}
                onCancel={() => {
                    setShowAlertForLookupPrice(false);
                }}
            />
        </Paper>
    );
};

export default LookupEmail;
