import { Stack, TablePagination, Typography } from 'convertupleads-theme';
import React from 'react';

const ConnectedFileListPagination = ({setPage,page,count,limit,setLimit}) => {
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0); 
    };
    
    

    return (
        <Stack direction={'row'} justifyContent={'space-between'} px={2}>
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <Typography variant={'caption'} color={'text.secondary'}>
                    Total {count} Sheets
                </Typography>
            </Stack>

            <TablePagination
            component='div'
            count={count}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
        </Stack>
    );
};

export default ConnectedFileListPagination;
