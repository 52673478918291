import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
    ArrowDropDownFilledIcon,
    Button,
    ButtonGroup,
    CalenderIcon,
    Checkbox,
    ChevronDownIcon,
    ChevronUpIcon,
    Collapse,
    CustomSwitch,
    Divider, FormControlLabel,
    List,
    MoreVerticalIcon,
    Popover,
    Stack,
    StarFilledIcon,
    Typography,
    useTheme,
} from 'convertupleads-theme';
import { useNavigate } from 'react-router-dom';
import CampaignStatus from './CampaignStatus';

import { useDispatch, useSelector } from 'react-redux';
import { renderFolderIcon, showNotification } from '../../../../helpers/util/functions';
import { campaignFolderState } from '../../../../state/features/campaignFolder/campaignFolderSelector';
import { IEmailCampaign } from '../../../../state/features/emailCampaign/emailCampaign.interface';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { selectCampaign, toggleSelectedSingleCampaign, updateCampaign } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import ViewReportComponent from './ViewReportComponent';
import AddFolderComponent from './popoverItems/AddFolderComponent';
import CloneComponent from './popoverItems/CloneComponent';
import DeleteCampaign from './popoverItems/DeleteCampaign';
import DeleteCampaignForever from './popoverItems/DeleteCampaignForever';
import EditCampaignComponent from './popoverItems/EditCampaignComponent';
import FavouriteComponent from './popoverItems/FavouriteComponent';
import RenameComponent from './popoverItems/RenameComponent';
import RestoreFromTrash from './popoverItems/RestoreFromTrash';
import ShareCampaign from './popoverItems/ShareCampaign';
import {
    STATUS_ACTIVE, STATUS_LABEL,
    STATUS_PAUSE, STATUS_PAUSING, STATUS_RESUME,
    STATUS_RUNNING,
    STATUS_STARTING, STATUS_UPDATE
} from "../../../../helpers/constant/coreConstants.ts";
import PopupAlert from "../../../common/PopupAlert";

interface CampaignListItemProps {
    campaign: IEmailCampaign;
}

const statusToggleMapping: { [key: string]: boolean } = {
    [STATUS_ACTIVE]: false,
    [STATUS_RUNNING]: true,
    [STATUS_RESUME]: true,
    [STATUS_STARTING]: true,
    [STATUS_PAUSE]: false,
    [STATUS_PAUSING]: false,
};


const CampaignListItem: React.FC<CampaignListItemProps> = ({ campaign }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedCampaigns } = useSelector(emailCampaignState);
    const { selectedFolder } = useSelector(campaignFolderState);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [campaignStatus, setCampaignStatus] = useState<boolean>(false);
    const [campaignInactiveStatusLabel, setCampaignInactiveStatusLabel] = useState<string>(STATUS_LABEL[STATUS_PAUSE]);
    const [campaignActiveStatusLabel, setCampaignActiveStatusLabel] = useState<string>(STATUS_LABEL[STATUS_RUNNING]);
    const [isCampaignStatusCollapsed, setIsCampaignStatusCollapsed] = useState(true);
    const [isCampaignStatusStartAlertModal, setIsCampaignStatusStartAlertModal] = useState(false);
    const [isCampaignStatusPauseAlertModal, setIsCampaignStatusPauseAlertModal] = useState(false);

    useEffect(() => {
        let status = statusToggleMapping[campaign?.status];

        setCampaignStatus(statusToggleMapping[campaign.status]);

        if (status) {
            setCampaignActiveStatusLabel(STATUS_LABEL[campaign?.status]);
        } else {
            setCampaignInactiveStatusLabel(STATUS_LABEL[campaign?.status]);
        }
    }, [campaign]);

    const isSelected = selectedCampaigns.includes(campaign.uid as string);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleNewEmailCampaignModal = () => {
        dispatch(selectCampaign(campaign));
        handleClose();
    };
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    const handleCampaignStatusCollapsed = () => {
        setIsCampaignStatusCollapsed((prev) => !prev);
    };

    const handleCampaignStatus = () => {
        dispatch(updateCampaign({ uid: campaign?.uid as string, status: STATUS_UPDATE[campaign?.status], oldValue: campaign?.status }));
    };

    const handleToggleSelect = () => dispatch(toggleSelectedSingleCampaign(campaign.uid));

    const open = Boolean(anchorEl);
    const id = useMemo(() => {
        if (open) return 'simple-popover'
        else return undefined;
    }, [open])
    const borderColor = theme.palette.other.outlinedBorder;
    const folderDivider = campaign?.campaignFolders?.length > 0 && <Divider orientation='vertical' />;

    const campaignAction = () =>
        selectedFolder !== 'trash' ? (
            <>
                <FormControlLabel
                    sx={{ gap: 1 }}
                    control={
                        <CustomSwitch
                            // sx={{ minWidth: '90px' }}
                            color={"primary.main"}
                            checked={campaignStatus}
                            activeText={campaignActiveStatusLabel}
                            inactiveText={campaignInactiveStatusLabel}
                            onChange={() => {
                                if (campaign?.status === STATUS_STARTING || campaign?.status === STATUS_PAUSING) {
                                    showNotification('warning', 'You cannot update campaign status while a request is already pending');
                                    return;
                                }
                                if (campaign?.status === STATUS_PAUSE || campaign?.status === STATUS_ACTIVE) {
                                    setIsCampaignStatusStartAlertModal(true);
                                } else if (campaign?.status === STATUS_RUNNING) {
                                    setIsCampaignStatusPauseAlertModal(true);
                                }
                            }}
                        />
                    }
                    label=""
                />
                <ButtonGroup
                    sx={{
                        height: '30px',
                        borderRadius: '4px',
                    }}
                    aria-label='contained button group'
                >
                    <Button
                        onClick={() => navigate(`/bulk-email/report/${campaign?.uid}`, { replace: true })}
                        variant='tonal'
                        sx={{
                            background: theme.palette.other.outlinedBorder,

                            '&.MuiButtonGroup-firstButton': {
                                borderColor: 'white !important',
                                borderRight: '2px solid white',
                            },

                            '&:hover, &:focus': {
                                background: theme.palette.other.outlinedBorder,
                            },
                        }}
                    >
                        <Typography variant='body2' color={'text.secondary'}>
                            View Report
                        </Typography>
                    </Button>
                    <Button
                        variant='tonal'
                        color='inherit'
                        onClick={handleClick}
                        sx={{
                            background: theme.palette.other.outlinedBorder,
                            color: theme.palette.text.secondary,
                            '&:hover, &:focus': {
                                background: theme.palette.other.outlinedBorder,
                                color: theme.palette.text.secondary,
                            },
                        }}
                    >
                        <ArrowDropDownFilledIcon aria-describedby={id} />
                    </Button>
                </ButtonGroup>
            </>
        ) : (
            <Button variant='tonal' color='inherit' onClick={handleClick} sx={{
                '&:hover, &:focus': {
                    background: theme.palette.other.outlinedBorder,
                }
            }}>
                <MoreVerticalIcon />
            </Button>
        );


    const handleFavourite = () => {
        dispatch(updateCampaign({
            uid: campaign.uid, isFavourite: campaign?.isFavourite === 'YES' ? 'NO' : 'YES',
            oldValue: campaign?.isFavourite
        }));
    };

    return (
        <Stack mt={1} sx={{ border: `1px solid ${borderColor}`, padding: '8px 16px', borderRadius: '8px' }}>
            <Stack direction={'row'} spacing={2}>
                <Stack direction={'row'} spacing={1}>
                    {isCampaignStatusCollapsed ? (
                        <ChevronUpIcon
                            color={`${theme.palette.action.active}`}
                            cursor={'pointer'}
                            onClick={handleCampaignStatusCollapsed}
                        />
                    ) : (
                        <ChevronDownIcon cursor={'pointer'} onClick={handleCampaignStatusCollapsed} />
                    )}

                    <Checkbox checked={isSelected} size='small' sx={{ padding: 0, height: '24px' }} onClick={handleToggleSelect} />
                </Stack>

                <Stack mt={'1px'} direction={'row'} justifyContent={'space-between'} width={'100%'}>
                    <Stack spacing={1}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Typography
                                sx={{ cursor: 'pointer' }}
                                color='text.primary'
                                variant='body1'
                                fontWeight={500}
                                onClick={selectedFolder !== 'trash' ? handleNewEmailCampaignModal : undefined}
                            >
                                {campaign.title}
                            </Typography>

                            <StarFilledIcon sx={{ color: campaign?.isFavourite === 'YES' ? 'warning.main' : theme.palette.grey[400], fontSize: 12, cursor: 'pointer' }} onClick={handleFavourite} />
                        </Stack>

                        <Stack direction={'row'} alignItems={'center'} gap={2} mt={0.5}>
                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                <CalenderIcon color='text.secondary' sx={{ width: '16px', height: '16px' }} />
                                <Typography color='text.secondary' fontSize={14} mt={'2px'}>
                                    {campaign?.createdAt && (window as any).globalTimezoneConversionToDifferentTimezone(campaign?.createdAt)}

                                </Typography>
                            </Stack>

                            {folderDivider}

                            {campaign?.campaignFolders?.map((folder, index) => (
                                <Stack key={index} direction={'row'} alignItems={'center'} gap={1}>
                                    {renderFolderIcon(folder?.folderIcon as string, folder.folderColor)}

                                    <Typography color='text.secondary' fontSize={14} mt={'2px'}>
                                        {folder.title}
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>

                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        {campaignAction()}
                    </Stack>
                </Stack>
            </Stack>

            <Collapse orientation='vertical' in={isCampaignStatusCollapsed}>
                <Divider light sx={{ marginTop: '16px' }} />
                <CampaignStatus campaign={campaign} />
            </Collapse>

            <Popover
                id={id}
                open={open}
                sx={{ marginTop: '10px' }}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List component='div' aria-label='main mailbox folders' dense>
                    {selectedFolder !== 'trash' ? (
                        <>
                            <EditCampaignComponent handleNewEmailCampaignModal={handleNewEmailCampaignModal} />
                            <AddFolderComponent campaign={campaign} onClose={handleClose} />
                            <RenameComponent campaign={campaign} onClose={handleClose} />
                            <CloneComponent campaign={campaign} campaignUid={campaign.uid as string} onClose={handleClose} campaignTitle={campaign.title} />
                            <ShareCampaign campaign={campaign} onClose={handleClose} />
                            <FavouriteComponent campaign={campaign} />
                            <ViewReportComponent campaign={campaign} />
                            <DeleteCampaign campaign={campaign} onClose={handleClose} />
                        </>
                    ) : (
                        <>
                            <RestoreFromTrash campaign={campaign} onClose={handleClose} />
                            <DeleteCampaignForever campaign={campaign} onClose={handleClose} />
                        </>
                    )}
                </List>
            </Popover>

            <PopupAlert
                title={"Do you want to start this campaign?"}
                description={"After confirming it will take a few minutes for your campaign to launch."}
                open={isCampaignStatusStartAlertModal}
                onSubmit={() => {
                    handleCampaignStatus();
                    setIsCampaignStatusStartAlertModal(false);
                }}
                onCancel={() => {
                    setIsCampaignStatusStartAlertModal(false);
                }}
            />

            <PopupAlert
                title={"Do you want to pause this campaign?"}
                description={"It will take a few minutes for your campaign to pause. You can restart your campaign at any time."}
                confirmText={"PAUSE CAMPAIGN"}
                open={isCampaignStatusPauseAlertModal}
                onSubmit={() => {
                    handleCampaignStatus();
                    setIsCampaignStatusPauseAlertModal(false);
                }}
                onCancel={() => {
                    setIsCampaignStatusPauseAlertModal(false);
                }}
            />
        </Stack>
    );
};

export default CampaignListItem;
