import React, {useState} from 'react';
import {
    AddIcon,
    Avatar,
    Button,
    Chip,
    Stack,
    Typography,
    useTheme
} from 'convertupleads-theme';
import {useSelector} from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import AddMoreSenderModal from "./AddMoreSenderModal.tsx";

const EmailSender: React.FC = () => {
    const theme = useTheme();
    const { connectedEmails } = useSelector(emailCampaignState);
    const selectedEmails = connectedEmails?.campaignConnectedEmails?.map((email) => ({
        value: email.uid,
        label: email.userConnectedEmail,
    }));
    const [isAddMoreModalOpen, setIsAddMoreModalOpen] = useState(false);


    return (
        <Stack paddingY={1} sx={{ borderBottom: `1px solid ${theme.palette.other.outlinedBorder}` }}>
            <Typography color={'text.secondary'} variant='body2'>
                Email Sender
            </Typography>
            <Stack direction={'row'} spacing={1} mt={1} alignItems={"center"} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={1} mt={1} alignItems={"center"}>
                    {selectedEmails?.map((item) => (
                        <Chip
                            avatar={
                                <Avatar size='medium' sx={{ textTransform: 'uppercase' }}>
                                    {item.label[0]}
                                </Avatar>
                            }
                            key={item.value}
                            label={item.label}
                        />
                    ))}
                </Stack>
                
                <Button 
                    variant='noPadding' 
                    startIcon={<AddIcon color='primary'/>} 
                    onClick={()=>{ setIsAddMoreModalOpen(true); }} 
                    sx={{
                        fontSize: '12px', 
                        ':focus': { backgroundColor: 'transparent', }
                        }}
                >
                    Add email sender
                </Button>
            </Stack>

            <AddMoreSenderModal open={isAddMoreModalOpen} onClose={()=>{ setIsAddMoreModalOpen(false); }}/>
        </Stack>
    );
};

export default EmailSender;
