import React from 'react';
import { Stack, Typography, Paper, List, Divider, Button, styled } from 'convertupleads-theme';


const StyledButton=styled(Button)(({theme})=>({
    background:theme.palette.other.outlinedBorder,
    color:theme.palette.text.secondary,
    '&:hover, :focus':{
        background:theme.palette.other.outlinedBorder,
        color:theme.palette.text.secondary
    }
}))

const StyledPaper=styled(Paper)(({theme})=>({
    background:theme.palette.other.outlinedBorder,
    color:theme.palette.text.secondary,
    padding:3,
    overflow:'auto'
}))

const EmailList: React.FC = () => {
    return (
        <Stack height={787} justifyContent={'space-between'}>
            <Stack p={3}>
                <StyledPaper elevation={0} variant={'outlined'}>
                    <Stack direction={'row'}>
                        <List>
                            <Typography>1. email@email.com</Typography>
                            <Typography>2.</Typography>
                            <Typography>3. </Typography>
                            <Typography>4. </Typography>
                            <Typography>5. </Typography>
                            <Typography>6. </Typography>
                            <Typography>7. </Typography>
                            <Typography>8. </Typography>
                            <Typography>9. </Typography>
                            <Typography>10. </Typography>
                            <Typography>11. </Typography>
                            <Typography>12. </Typography>
                            <Typography>13. </Typography>
                            <Typography>14. </Typography>
                            <Typography>15. </Typography>
                            <Typography>16. </Typography>
                            <Typography>17. </Typography>
                            <Typography>18. </Typography>
                            <Typography>19. </Typography>
                            <Typography>20. </Typography>
                            <Typography>21. </Typography>
                            <Typography>22. </Typography>
                            <Typography>20. </Typography>
                            <Typography>21. </Typography>
                            <Typography>22. </Typography>
                        </List>
                    </Stack>
                </StyledPaper>
            </Stack>
            <Stack>
                <Divider light />
                <Stack direction={'row'} spacing={1} p={'16px 20px'} justifyContent={'space-between'}>
                    <StyledButton variant={'tonal'} size={'large'}>
                        Cancel
                    </StyledButton>

                    <Button variant={'contained'} size={'large'} sx={{ background: 'primary', color: 'primary' }}>
                        Continue
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EmailList;
