import React, { useState } from 'react';
import { ChevronDownIcon, DeleteFilledIcon, DeleteModal, Divider, IconButton, MailFilledIcon, Stack, Typography, useTheme } from 'convertupleads-theme';
import { useSelector } from 'react-redux';
import { emailCampaignState } from '../../state/features/emailCampaign/emailCampaignSelector';
import DividerWithAddIcon from '../common/DividerWithAddIcon';
import { ICampaignSequence } from '../../state/features/emailCampaign/emailCampaign.interface';
import { CAMPAIGN_SETTINGS, DATABASE_TIMEZONE, EMAIL_SCHEDULE_TYPE, EMAIL_THREAD } from '../../helpers/constant/coreConstants';

interface Props {
    item: ICampaignSequence;
    index: number;
    sequenceArray: ICampaignSequence[];
    handleCampaignScheduleModal: () => void;
    onSelectSequence: () => void;
    onAddSequenceBetween: () => void;
    onDelete: () => void;
}


const EachSequenceItem: React.FC<Props> = ({
    item,
    index,
    sequenceArray,
    handleCampaignScheduleModal,
    onSelectSequence,
    onAddSequenceBetween,
    onDelete,
}) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { selectedSequence, selectedCampaign, schedules } = useSelector(emailCampaignState);
    const theme = useTheme();

    const currentDate = new Date();
    const dateString = currentDate.toISOString().split('T')[0];

    const getValue = (key: string): string => {
        if (schedules) {
            return String(schedules[key]);
        }
        return "";
    };

    const convertTimeToCampaignTimezone = (value: string): string => {

        try {
            const concatenatedDateTime = dateString + " " + value;
            let convertedTime = (window as any).globalTimezoneConversionToDifferentTimezone(concatenatedDateTime, DATABASE_TIMEZONE, getValue(CAMPAIGN_SETTINGS.TIMEZONE), 'hh:mm a')
            return convertedTime;
        } catch (e) {
            return value
        }
    };

    const campaignScheduleText = (emailSequence: ICampaignSequence | null) => {
        let text = '';
        // for immediately
        if (emailSequence?.type === EMAIL_SCHEDULE_TYPE.IMMEDIATELY) {
            text = 'Immediately';
        }

        // for same day on hour
        else if (emailSequence?.type === EMAIL_SCHEDULE_TYPE.SCHEDULE && emailSequence.hour && emailSequence.day === 0) {
            const [hour, minute] = emailSequence.hour.split(":");
            text = `${hour} hours ${minute} min`;

            if (hour === "00" && minute === "00") {
                text = 'Immediately';
            }
        }

        // for another day on hour
        else if (emailSequence?.type === EMAIL_SCHEDULE_TYPE.SCHEDULE && emailSequence.hour && emailSequence.day) {

            let time = `at ${convertTimeToCampaignTimezone(emailSequence.hour)}`;
            // const [hour, minute] = emailSequence.hour.split(":");
            text = `Day ${emailSequence.day} ${time}`;
            // text = `At ${hour} hours ${minute} min`;
        }

        // for fixed date on hour
        else if (emailSequence?.type === EMAIL_SCHEDULE_TYPE.FIXED_DATE && emailSequence.fixedDate) {

            text = (window as any).globalTimezoneConversionToDifferentTimezone(emailSequence.fixedDate, DATABASE_TIMEZONE, getValue(CAMPAIGN_SETTINGS.TIMEZONE), 'MM/DD/YYYY hh:mm a');
        }

        // for recurring day on hour
        else if (emailSequence?.type === EMAIL_SCHEDULE_TYPE.RECURRING && emailSequence.hour && emailSequence.day) {
            text = `Every ${emailSequence.day} day(s) at ${convertTimeToCampaignTimezone(emailSequence.hour)}`;
        }

        return text;
    };

    const campaignScheduleTitle = (emailSequence: ICampaignSequence | null) => {
        let text = '';
        // for immediately
        if (emailSequence?.type === EMAIL_SCHEDULE_TYPE.IMMEDIATELY) {
            text = 'Present day';
        }

        // for same day on hour
        if (emailSequence?.type === EMAIL_SCHEDULE_TYPE.SCHEDULE && emailSequence.hour && emailSequence.day === 0) {
            text = 'Present day';
        }

        // for another day on hour
        if (emailSequence?.type === EMAIL_SCHEDULE_TYPE.SCHEDULE && emailSequence.hour && emailSequence.day) {
            text = `Day ${emailSequence.day}`;
        }

        // for fixed date on hour
        if (emailSequence?.type === EMAIL_SCHEDULE_TYPE.FIXED_DATE && emailSequence.fixedDate) {
            text = `Custom Date`;
        }

        // for recurring day on hour
        if (emailSequence?.type === EMAIL_SCHEDULE_TYPE.RECURRING && emailSequence.hour && emailSequence.day) {
            // const [hour, minute] = emailSequence.hour.split(":");
            text = `Recurring`;
        }

        return text;
    };

    const subjectShow = () => {

        if (selectedCampaign?.emailThreads && selectedCampaign?.emailThreads === EMAIL_THREAD.MULTI_THREAD) {
            if (item?.messageSubject) {
                return item?.messageSubject;
            }
            return '<Empty Subject>';
        } else {
            if (selectedCampaign?.commonEmailSubject) {
                if (index > 0) {
                    return 'Re: ' + selectedCampaign?.commonEmailSubject;
                }
                return selectedCampaign?.commonEmailSubject
            } else {
                return '<Empty Subject>';
            }
        }
    }

    return (
        <React.Fragment>
            <Stack
                // mt={1}
                sx={{
                    border: `1px solid ${(selectedSequence?.index || 0) === index ? theme.palette.primary.main : 'transparent'}`,
                    padding: '10px 20px',
                    borderRadius: '8px',
                    bgcolor: 'common.white',
                }}
                onClick={onSelectSequence}
            >
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography fontSize={13} fontWeight={600} color={'text.secondary'}>
                        Step {index + 1}
                        {/* {campaignScheduleTitle(item)} */}
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Stack direction={'row'} onClick={handleCampaignScheduleModal} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                            <Typography variant='body2' color={'primary'}>
                                {campaignScheduleText(item)}
                            </Typography>
                            <ChevronDownIcon color={'primary'} />
                        </Stack>
                        {/* <IconButton disabled={sequenceArray.length - 1 === 0} size='small' onClick={onDelete}> */}
                        <IconButton disabled={sequenceArray.length - 1 === 0} size='small' onClick={() => setIsDeleteModalOpen(true)} sx={{ '&:focus': { background: 'transparent' } }}>
                            <DeleteFilledIcon />
                        </IconButton>
                    </Stack>
                </Stack>

                <Divider light sx={{ marginY: '12px' }} />
                <Stack direction={'row'} spacing={2}>
                    <IconButton
                        color='primary'
                        variant={'rounded'}
                        sx={{
                            height: '49px',
                            width: '49px',
                            background: theme.palette.primary.light,
                            alignSelf: 'flex-start',
                        }}
                    >
                        <MailFilledIcon />
                    </IconButton>
                    <Stack width={'100%'}>
                        <Stack>
                            <Typography variant='body2' color={'text.primary'}>
                                Subject:
                            </Typography>
                        </Stack>
                        <Divider light sx={{ marginY: '4px' }} />
                        <Typography fontSize={12} color={'text.secondary'}>
                            {subjectShow()}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            {sequenceArray.length - 1 !== index && <DividerWithAddIcon onClick={onAddSequenceBetween} />}

            <DeleteModal
                open={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                title='Are you sure you want to delete this step?'
                // warningContent='You are able to leave this page without saving. All changes will be lost.'
                onConfirm={onDelete}
                sx={{
                    '& .MuiPaper-root.MuiPaper-elevation': { width: 400 },
                    '& .MuiButton-tonalInherit:focus': { bgcolor: 'other.outlinedBorder' },
                    '& .MuiButton-colorError:focus': { bgcolor: 'error.main' }
                }}
            />
        </React.Fragment>
    );
};

export default EachSequenceItem;
