import { all } from 'redux-saga/effects';
import campaignFolderSaga from './features/campaignFolder/campaignFolderSaga';
import emailCampaignSaga from './features/emailCampaign/emailCampaignSaga';

const bulkEmailSaga = [campaignFolderSaga(), emailCampaignSaga()];

export function* rootSaga() {
    yield all(bulkEmailSaga);
}

export default bulkEmailSaga;
