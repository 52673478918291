import React from 'react';
import { Skeleton, Stack, useTheme } from 'convertupleads-theme';

const CampaignsBodySkeleton: React.FC = () => {
    const theme = useTheme();
    return (
        <Stack gap={1} pt={2}>
            <Skeleton variant='rounded' height={40} />

            <Stack
                padding={1}
                maxWidth={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={2}
                height={40}
                border={`1px solid ${theme.palette.other.outlinedBorder}`}
                borderRadius={2}
            >
                <Stack direction={'row'} spacing={1}>
                    <Stack>
                        {' '}
                        <Skeleton variant='rounded' width={40} height={40} />
                    </Stack>
                    <Stack spacing={1}>
                        {' '}
                        <Skeleton variant='rounded' width={316} height={16} />
                        <Stack spacing={2} direction={'row'}>
                            <Skeleton variant='rounded' width={150} height={16} />
                            <Skeleton variant='rounded' width={150} height={16} />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <Skeleton sx={{ borderRadius: '30px' }} variant='rounded' width={100} height={30} />
                    <Skeleton variant='rounded' width={130} height={30} />
                </Stack>
            </Stack>
            <Stack
                padding={1}
                maxWidth={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={2}
                height={40}
                border={`1px solid ${theme.palette.other.outlinedBorder}`}
            >
                <Stack direction={'row'} spacing={1}>
                    <Stack>
                        {' '}
                        <Skeleton variant='rounded' width={40} height={40} />
                    </Stack>
                    <Stack spacing={1}>
                        {' '}
                        <Skeleton variant='rounded' width={316} height={16} />
                        <Stack spacing={2} direction={'row'}>
                            <Skeleton variant='rounded' width={150} height={16} />
                            <Skeleton variant='rounded' width={150} height={16} />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <Skeleton sx={{ borderRadius: '30px' }} variant='rounded' width={100} height={30} />
                    <Skeleton variant='rounded' width={130} height={30} />
                </Stack>
            </Stack>
            <Stack
                padding={1}
                maxWidth={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={2}
                height={40}
                border={`1px solid ${theme.palette.other.outlinedBorder}`}
            >
                <Stack direction={'row'} spacing={1}>
                    <Stack>
                        {' '}
                        <Skeleton variant='rounded' width={40} height={40} />
                    </Stack>
                    <Stack spacing={1}>
                        {' '}
                        <Skeleton variant='rounded' width={316} height={16} />
                        <Stack spacing={2} direction={'row'}>
                            <Skeleton variant='rounded' width={150} height={16} />
                            <Skeleton variant='rounded' width={150} height={16} />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <Skeleton sx={{ borderRadius: '30px' }} variant='rounded' width={100} height={30} />
                    <Skeleton variant='rounded' width={130} height={30} />
                </Stack>
            </Stack>
            <Stack
                padding={1}
                maxWidth={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={2}
                height={40}
                border={`1px solid ${theme.palette.other.outlinedBorder}`}
            >
                <Stack direction={'row'} spacing={1}>
                    <Stack>
                        {' '}
                        <Skeleton variant='rounded' width={40} height={40} />
                    </Stack>
                    <Stack spacing={1}>
                        {' '}
                        <Skeleton variant='rounded' width={316} height={16} />
                        <Stack spacing={2} direction={'row'}>
                            <Skeleton variant='rounded' width={150} height={16} />
                            <Skeleton variant='rounded' width={150} height={16} />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <Skeleton sx={{ borderRadius: '30px' }} variant='rounded' width={100} height={30} />
                    <Skeleton variant='rounded' width={130} height={30} />
                </Stack>
            </Stack>
            <Stack
                padding={1}
                maxWidth={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={2}
                height={40}
                border={`1px solid ${theme.palette.other.outlinedBorder}`}
            >
                <Stack direction={'row'} spacing={1}>
                    <Stack>
                        {' '}
                        <Skeleton variant='rounded' width={40} height={40} />
                    </Stack>
                    <Stack spacing={1}>
                        {' '}
                        <Skeleton variant='rounded' width={316} height={16} />
                        <Stack spacing={2} direction={'row'}>
                            <Skeleton variant='rounded' width={150} height={16} />
                            <Skeleton variant='rounded' width={150} height={16} />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <Skeleton sx={{ borderRadius: '30px' }} variant='rounded' width={100} height={30} />
                    <Skeleton variant='rounded' width={130} height={30} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default CampaignsBodySkeleton;
