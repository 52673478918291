import React, { useEffect, useState } from 'react';
import {
    ArchiveIcon,
    Button,
    DeleteFilledIcon,
    DeleteModal,
    FolderFilledIcon,
    IconButton,
    LoadingButton,
    PauseCircleIcon,
    PlayCircleOutlineFilledIcon,
    Stack,
    Tooltip,
} from 'convertupleads-theme';
import AddToFolderModal from './AddToFolderModal';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import {
    bulkUnarchiveCampaign,
    bulkUpdateCampaign,
    deleteCampaign,
} from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { campaignFolderState } from '../../../../state/features/campaignFolder/campaignFolderSelector';
import { STATUS_ARCHIVE, STATUS_PAUSE, STATUS_RESUME } from '../../../../helpers/constant/coreConstants';

const SelectedCampaignActions: React.FC = () => {
    const { selectedCampaigns, isDeleteCampaignSuccess, isUpdating } = useSelector(emailCampaignState);
    const { selectedFolder } = useSelector(campaignFolderState);
    const dispatch = useDispatch();
    const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        if (isDeleteCampaignSuccess === true) {
            setOpenDeleteModal(false);
        }
    }, [isDeleteCampaignSuccess]);

    const handleAddFolderModal = () => {
        setOpenAddFolderModal(true);
    };
    const handleDeleteModal = () => {
        setOpenDeleteModal(true);
    };
    const handleOnConfirmForDeleteModal = () => {
        dispatch(deleteCampaign({ campaignUids: selectedCampaigns, deleteForever: selectedFolder === 'trash' }));
    };
    const handleBulkStatus = (status: 'RESUME' | 'PAUSE' | 'ARCHIVE') => {
        dispatch(bulkUpdateCampaign({ campaignUids: selectedCampaigns, status: status }));
    };
    const handleUnarchive = () => {
        dispatch(bulkUnarchiveCampaign({ campaignUids: selectedCampaigns }));
    };


    return (
        <Stack direction={'row'} alignItems={'center'} gap={1} sx={{ height: 40 }}>
            {selectedFolder !== 'trash' && (

                <>
                    {selectedFolder !== 'archive' 
                        ? 
                            <>
                                <Button
                                    variant={'outlined'}
                                    startIcon={<FolderFilledIcon color='primary' />}
                                    size='medium'
                                    onClick={handleAddFolderModal}
                                >
                                    Add to folder
                                </Button>
                                <Tooltip title='Archive'>
                                    <IconButton size='small' onClick={() => handleBulkStatus(STATUS_ARCHIVE)} sx={{ ':focus': { bgcolor: 'transparent' } }}>
                                        {' '}
                                        <ArchiveIcon color='primary' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Resume'>
                                    <IconButton size='small' onClick={() => handleBulkStatus(STATUS_RESUME)} sx={{ ':focus': { bgcolor: 'transparent' } }}>
                                        {' '}
                                        <PlayCircleOutlineFilledIcon color='primary' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Pause'>
                                    <IconButton size='small' onClick={() => handleBulkStatus(STATUS_PAUSE)} sx={{ ':focus': { bgcolor: 'transparent' } }}>
                                        {' '}
                                        <PauseCircleIcon color='primary' />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title='Delete'>
                                    <IconButton size='small' onClick={handleDeleteModal} sx={{ ':focus': { bgcolor: 'transparent' } }}>
                                        {' '}
                                        <DeleteFilledIcon color='error' />
                                    </IconButton>
                                </Tooltip>
                            </> 
                        :
                            <>
                                <Button
                                    variant={'outlined'}
                                    startIcon={<FolderFilledIcon color='primary' />}
                                    size='medium'
                                    onClick={handleAddFolderModal}
                                    sx={{':focus': { bgcolor: 'transparent'}}}
                                >
                                    Add to folder
                                </Button>
                                <LoadingButton
                                    variant={'outlined'}
                                    // startIcon={<FolderFilledIcon color='primary' />}
                                    loading={isUpdating}
                                    color='secondary'
                                    size='medium'
                                    onClick={handleUnarchive}
                                    sx={{'& .MuiButton-startIcon': {height: 20, width: 20}, '&:focus': {bgcolor: 'transparent'}}}
                                >
                                    Unarchive
                                </LoadingButton>
                                <Tooltip title='Resume'>
                                    <IconButton size='small' onClick={() => handleBulkStatus(STATUS_RESUME)} sx={{ ':focus': { bgcolor: 'transparent' } }}>
                                        {' '}
                                        <PlayCircleOutlineFilledIcon color='primary' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Delete'>
                                    <IconButton size='small' onClick={handleDeleteModal} sx={{ ':focus': { bgcolor: 'transparent' } }}>
                                        {' '}
                                        <DeleteFilledIcon color='error' />
                                    </IconButton>
                                </Tooltip>    
                            </>
                    }

                </>
            )}

            {selectedFolder === 'trash' && (
                <Button
                    variant={'outlined'}
                    color='error'
                    startIcon={<DeleteFilledIcon color='error' />}
                    size='medium'
                    onClick={handleDeleteModal}
                    sx={{':focus': { bgcolor: 'transparent'}}}
                >
                    Delete Forever
                </Button>
            )}

            <AddToFolderModal open={openAddFolderModal} onClose={() => setOpenAddFolderModal(false)} />

            <DeleteModal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                title='Are you sure, You want to delete this campaign?'
                warningContent='You are able to leave this page without saving. All changes will be lost.'
                onConfirm={handleOnConfirmForDeleteModal}
                sx={{
                    '& .MuiButton-tonalInherit:focus': { bgcolor: 'other.outlinedBorder' }, 
                    '& .MuiButton-colorError:focus': { bgcolor: 'error.main' }
                }}
            />
        </Stack>
    );
};

export default SelectedCampaignActions;
