import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, FormControlLabel, Stack, Typography } from 'convertupleads-theme';
import CustomScheduleComponent from './CustomScheduleComponent';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../state/features/emailCampaign/emailCampaignSelector.ts';
import { ICampaignScheduleDay } from '../../state/features/emailCampaign/emailCampaign.interface.ts';
import { updateCampaignSchedules } from '../../state/features/emailCampaign/emailCampaignSlice.ts';
import { CAMPAIGN_SETTINGS } from '../../helpers/constant/coreConstants.ts';
import { DaysIcon } from '../../assets/Icons.tsx';

const DaysScheduleComponent: React.FC = () => {
    const dispatch = useDispatch();
    const [toggleState, setToggleState] = useState(false);
    const { selectedCampaign, schedules } = useSelector(emailCampaignState);

    const handleUpdate = (key: string, value: string | ICampaignScheduleDay[], oldValue: string | ICampaignScheduleDay[]) => {
        if (selectedCampaign) {
            if (key === CAMPAIGN_SETTINGS.DAYS_SCHEDULE) {
                setToggleState(prev => !prev)
            }
            dispatch(updateCampaignSchedules({ campaignUid: selectedCampaign.uid, keyName: key, value: value, oldValue: oldValue }));
        }
    };

    useEffect(() => {
        if (schedules && schedules[CAMPAIGN_SETTINGS.DAYS_SCHEDULE]) {
            setToggleState(schedules[CAMPAIGN_SETTINGS.DAYS_SCHEDULE] === "ON");
        }
    }, [schedules]);

    return (
        <CustomScheduleComponent state={toggleState} onToggle={() => {
            handleUpdate(CAMPAIGN_SETTINGS.DAYS_SCHEDULE, toggleState ? "OFF" : "ON", toggleState ? "ON" : "OFF");
        }} title={'Send Emails on These Days'} subtitle={'Select the days you want emails to go out'} icon={<DaysIcon />}>
            <Divider light sx={{ marginY: '12px' }} />
            <Stack direction={'row'} flex={'wrap'} display={'block'} spacing={3} sx={{ marginLeft: '56px' }}>
                {
                    schedules && schedules.days && schedules.days.map((day, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    size='small'
                                    color='primary'
                                    sx={{ padding: 0 }}
                                    checked={day.value === "ON"}
                                />
                            }
                            label={
                                <Typography variant='body2' ml={1} color={'text.primary'}>
                                    {day["friendly-name"]}
                                </Typography>
                            }
                            onChange={(_event, checked) => {
                                if (schedules.days) {
                                    const updatedDays = schedules.days.map((tDay) => ({
                                        ...tDay,
                                        value: tDay.title === day.title ? (checked ? "ON" : "OFF") : tDay.value
                                    }));
                                    handleUpdate("days", updatedDays, schedules.days);
                                }
                            }}
                        />
                    ))
                }
            </Stack>
        </CustomScheduleComponent>
    );
};

export default DaysScheduleComponent;
