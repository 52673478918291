import React from "react";
import {
  PeopleFilledIcon,
  Stack,
  useTheme
} from "convertupleads-theme";
import { ContactMailIcon, EmailFailedIcon, MarkEmailReadIcon, MonetizationOnIcon, ScheduleSendCancelIcon, ScheduleSendIcon, UnsubscribeEmailIcon } from "../../../../../assets/Icons";
import EachLeftSideItem from "./EachLeftSideItem";

interface AnalyticsLeftSideProps {
  campaignStatus: any;
}
const AnalyticsLeftSide: React.FC<AnalyticsLeftSideProps> = ({ campaignStatus }) => {
  const theme = useTheme();

  const {totalContact, totalQueue, totalUndeliverd, totalSend, totalFailed, totalBounce, totalUnsubscribe, totalCost} = campaignStatus || {};

  const STATUS_LIST = [
    {
      iconColor: theme.palette.primary.main,
      icon: <PeopleFilledIcon sx={{ fontSize: '24px !important'}} />,
      value: totalContact,
      label: "Total Contacts",
    },
    {
      iconColor: theme.palette.primary.main,
      icon: <MarkEmailReadIcon color={theme.palette.primary.main} opacity={1} />,
      value: totalSend,
      label: "Emails Sent",
    },
    {
      iconColor: theme.palette.primary.main,
      icon: <ScheduleSendIcon />,
      value: totalQueue,
      label: "Emails in Queue",
    },
    {
      iconColor: theme.palette.error.dark,
      icon: <ScheduleSendCancelIcon color={theme.palette.error.main} />,
      value: totalUndeliverd,
      label: "Undelivered",
    },
    {
      iconColor: theme.palette.error.dark,
      icon: <EmailFailedIcon color={ theme.palette.error.main} />,
      value: totalFailed,
      label: "Failed Emails",
    },
    {
      iconColor: theme.palette.error.dark,
      icon: <ContactMailIcon height={24} width={24} color={theme.palette.error.main} />,
      value: totalBounce,
      label: "Bounced Emails",
    },
    {
      iconColor: theme.palette.error.dark,
      icon: <UnsubscribeEmailIcon color={theme.palette.error.main} />,
      value: totalUnsubscribe,
      label: "Unsubscribed",
    },
    {
      iconColor: theme.palette.success.main,
      icon: <MonetizationOnIcon color={theme.palette.success.main} opacity={1} />,
      value: `$ ${totalCost || 0}`,
      label: "Total Cost",
    },
  ];

  return (
    <Stack flex={1} width={"100%"} p={1.5} spacing={1.5}>
      {STATUS_LIST.map((item, index) => (
        <EachLeftSideItem
          key={index}
          icon={item.icon}
          iconColor={item.iconColor}
          value={item.value}
          label={item.label}
        />
      ))}
    </Stack>
  );
};

export default AnalyticsLeftSide;
