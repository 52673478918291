import {
    FolderFilledIcon,
    WebsiteIcon,
    MonitorIcon,
    MailIcon,
    FilterIcon,
    DollarIcon,
    StarIcon,
    LockIcon,
    RadioIcon,
    SizeIcon,
    BrushOutlinedIcon,
} from 'convertupleads-theme';
import { ICampaignSequence } from '../../state/features/emailCampaign/emailCampaign.interface.ts';
import { getCookie, getCookieLogin } from "../Cookie.ts";
import { EMAIL_SCHEDULE_TYPE } from '../constant/coreConstants.ts';

/* eslint-disable @typescript-eslint/no-explicit-any */
type FuncType<T extends any[], R> = (...args: T) => R;

export const debounce = <T extends any[], R>(func: FuncType<T, R>, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: T) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function convertTimeTo12HourFormat(time24: string) {
    const [hours, minutes] = time24.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const time12 = `${hours12}:${formattedMinutes} ${period}`;
    return time12;
}



export const renderFolderIcon = (icon: string, color?: string, size: number = 20) => {
    switch (icon) {
        case 'FolderFilledIcon':
            return <FolderFilledIcon sx={{ color, fontSize: size }} />;
        case 'WebsiteIcon':
            return <WebsiteIcon sx={{ color, fontSize: size }} />;
        case 'MonitorIcon':
            return <MonitorIcon sx={{ color, fontSize: size }} />;
        case 'MailIcon':
            return <MailIcon sx={{ color, fontSize: size }} />;
        case 'FilterIcon':
            return <FilterIcon sx={{ color, fontSize: size }} />;
        case 'DollarIcon':
            return <DollarIcon sx={{ color, fontSize: size }} />;
        case 'StarIcon':
            return <StarIcon sx={{ color, fontSize: size }} />;
        case 'LockIcon':
            return <LockIcon sx={{ color, fontSize: size }} />;
        case 'RadioIcon':
            return <RadioIcon sx={{ color, fontSize: size }} />;
        case 'SizeIcon':
            return <SizeIcon sx={{ color, fontSize: size }} />;
        case 'BrushOutlinedIcon':
            return <BrushOutlinedIcon sx={{ color, fontSize: size }} />;

        default:
            return <FolderFilledIcon sx={{ color, fontSize: size }} />; // Handle unknown icons gracefully
    }
};

export const insertPersonalizedTag = (personalizedTag: string, inputRef: any, onChange: (value: string) => void) => {
    const input = inputRef.current;
    if (input.editor) {
        inputRef.current.editor.execCommand('mceInsertContent', false, personalizedTag);
    } else {
        const value = input.value;
        const selectionStart = input.selectionStart;
        console.log(value);
        onChange(value.substring(0, selectionStart) + personalizedTag + value.substring(selectionStart));
        setTimeout(() => {
            input.setSelectionRange(selectionStart + personalizedTag.length, selectionStart + personalizedTag.length);
        }, 100);
    }
};

export function capitalize(str: string) {
    return str.toLowerCase().replace(/^\w|\s\w/g, (char: string) => char.toUpperCase());
}

export const getAccountData = (needle: string) => {
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if (check_new !== undefined) {
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    } else {
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}

export const showNotification = (type: string, message: string) => {
    if ((window as any).showNotification) {
        //warning, error, success, info
        (window as any).showNotification(type, message);
    }
}

export const formatFileSize = (bytes: number) => {
    if (bytes >= 1000000000) {
        return (bytes / 1000000000).toFixed(2) + " GB";
    }
    if (bytes >= 1000000) {
        return (bytes / 1000000).toFixed(2) + " MB";
    }
    return (bytes / 1000).toFixed(2) + " KB";
};

export const isValidEmail = (emailAddress: string) => {
    try{
        const trimmedEmailAddress = emailAddress.trim();
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(trimmedEmailAddress);
    }catch (err){
        return false;
    }
}

export const checkDomainOrEmail = (text: string): boolean => {
    try{
        const domainPattern = /^([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return domainPattern.test(text) || emailPattern.test(text);
    }catch (err){
        return false;
    }
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const cleanNumber = (number: number | string ): string => {
    try {
        if (isNaN(Number(number))) {
            return "x.xx";
        }

        if (typeof number === "string") {
            number = parseFloat(number);
        }

        const parts = number.toString().split(".");
        if (parts.length === 1) {
            return number.toFixed(2).replace(/\.0*$/, "") + ".00";
        }

        const decimalPart = parts[1].length < 2 ? parts[1] + "0" : parts[1];
        return `${parts[0]}.${decimalPart}`;
    } catch (err) {
        return "x.xx";
    }
}

export const urlify = (text:string) => {
    try {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    }catch (err){
        return text;
    }
}
