import React from 'react';
import { Stack } from 'convertupleads-theme';
import CampaignList from './CampaignList';
import CampaignPagination from './CampaignPagination';
import CampaignContentListHeader from './CampaignContentListHeader';

const CampaignContent: React.FC = () => {
    return (
        <Stack>
            <CampaignContentListHeader />
            <CampaignList />
            <CampaignPagination />
        </Stack>
    );
};

export default CampaignContent;
