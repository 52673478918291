import React from "react";
import {Box, Paper, Stack} from "convertupleads-theme";
import StepperSidebar from "./StepperSidebar.tsx";
import {useImportCsvContext} from "../importCsvReducer.tsx";
import {CSV_IMPORT_STEPS} from "../../../../../../helpers/constant/coreConstants.ts";
import Mapping from "./mapping/Mapping.tsx";
import Details from "./Details.tsx";
import Upload from "./Upload.tsx";

const Connect = () => {
    const { activeImportStep } = useImportCsvContext();

    return(
        <Paper elevation={0} sx={{height: "calc(100vh - 312px)", m: 1, p: 2}}>
            <Stack direction={"row"} spacing={2} pt={0} pb={5} px={25}>
                <StepperSidebar/>
                <Box sx={{width: "60%"}}>
                    {
                        (()=>{
                           switch (activeImportStep){
                               case CSV_IMPORT_STEPS.UPLOAD:
                                   return <Upload/>;
                               case CSV_IMPORT_STEPS.MAPPING:
                                   return <Mapping/>;
                               case CSV_IMPORT_STEPS.DETAILS:
                                   return <Details/>;
                           }
                        })()
                    }
                </Box>
            </Stack>
        </Paper>
    );
}

export default Connect;