import {
  IAddCampaign,
  IAddCampaignEmail,
  IAddSequenceVariation,
  IAddToFolder,
  IBulkUpdateCampaign,
  ICampaignSchedulesPayload,
  ICampaignSequencePayload,
  ICampaignSequenceSchedulePayload, IConnectNylasPayload,
  IDeleteCampaign,
  IDeleteCampaignEmail,
  IEmailCampaign,
  IEmailEventsAnalytics,
  IGetCampaignSequenceStatsPayload,
  IGetCampaignSettings,
  IPauseResumeContactPayload,
  ISequenceUploadedFileUpdatePayload, ITagPayload,
  ITestEmailPayload,
  IUpdateCampaign, IUpdateCampaignSettingDetails,
  IUpdateCampaignSettings,
  IUpdateSequenceVariation,
  IConnectedFileListPayload, IOpenAISavePayload,
  IGetQueuePayload,
  IContactTimeline,
  ISmartListPayload,
  IBulkEmailContact,
  IEmailMarketingContact,
  IBulkEmailParams
} from "../state/features/emailCampaign/emailCampaign.interface";
import httpRequest from "./api/httpRequest";

class EmailCampaignService {
  getEmailCampaigns(params: number) {
    return httpRequest.get("/campaigns/main", params);
  }

  addCampaign(params: IAddCampaign) {
    return httpRequest.post("/campaigns/main", params);
  }

  addToFolderBulk(params: IAddToFolder) {
    return httpRequest.post("/campaigns/folder-campaigns/bulk-update", params);
  }

  addToFolderSingle(params: IAddToFolder) {
    return httpRequest.put(
      `/campaigns/folder-campaigns/${params.campaignUid}`,
      params
    );
  }

  updateCampaign(params: IUpdateCampaign) {
    return httpRequest.put(`/campaigns/main/${params.uid}`, params);
  }

  updateBulkCampaignStatus(params: IUpdateCampaign) {
    return httpRequest.put(`/campaigns/main/update/status`, params);
  }

  deleteCampaign(params: IDeleteCampaign) {
    return httpRequest.delete(`/campaigns/main/delete`, params);
  }
  deleteCampaignForever(params: IDeleteCampaign) {
    return httpRequest.delete(`/campaigns/main/delete-forever`, params);
  }

  cloneCampaign(params: IEmailCampaign) {
    return httpRequest.post(`/campaigns/main/clone/${params.uid as string}`);
  }

  addCampaignSequence(params: ICampaignSequencePayload) {
    return httpRequest.post(`/campaigns/sequences`, params);
  }

  getCampaignSequences(params: ICampaignSequencePayload) {
    return httpRequest.get(`/campaigns/sequences`, params);
  }

  updateCampaignSequence(params: ICampaignSequencePayload) {
    return httpRequest.put(`/campaigns/sequences/${params.uid}`, params);
  }

  updateCampaignSequenceSchedule(params: ICampaignSequenceSchedulePayload) {
    return httpRequest.put(
      `/campaigns/sequences/schedule/${params.uid}`,
      params
    );
  }

  deleteCampaignSequence(params: ICampaignSequencePayload) {
    return httpRequest.delete(`/campaigns/sequences/${params.uid}`);
  }

  getCampaignSettings(params: IGetCampaignSettings) {
    return httpRequest.get(`/campaigns/settings?campaignUid=${params.uid}`);
  }

  updateCampaignSettings(params: IUpdateCampaignSettings) {
    return httpRequest.put(`/campaigns/settings`, params);
  }

  updateCampaignSettingDetails(params: IUpdateCampaignSettingDetails) {
    return httpRequest.put(`/campaigns/settings/setting-details`, params);
  }

  getCampaignSchedules(params: ICampaignSchedulesPayload) {
    return httpRequest.get(`/campaigns/main/schedules/${params.campaignUid}`);
  }

  updateCampaignSchedules(params: ICampaignSchedulesPayload) {
    return httpRequest.put(
      `/campaigns/main/schedules/${params.campaignUid}`,
      params
    );
  }

  getUserConnectedEmails() {
    return httpRequest.get(`/campaigns/user-connected-emails`);
  }

  getCampaignConnectedEmails(params: { uid: string }) {
    return httpRequest.get(
      `/campaigns/connected-emails?campaignUid=${params.uid}`
    );
  }

  addCampaignEmail(params: IAddCampaignEmail) {
    return httpRequest.post(`/campaigns/connected-emails`, params);
  }

  deleteCampaignEmail(params: IDeleteCampaignEmail) {
    return httpRequest.delete(
      `/campaigns/connected-emails/${params.connectedEmailUid}`,
      params
    );
  }

  updateCampaignSequenceScheduleDays(params: ICampaignSchedulesPayload) {
    return httpRequest.put(
      `/campaigns/main/schedules-days/${params.campaignUid}`,
      params
    );
  }

  getTriggerCampaignList(params) {
    return httpRequest.get("campaigns/main/campaign-list", params);
  }
  getTriggerPowerDialerList(params) {
    return httpRequest.get("campaigns/power-dialer-lists", params);
  }
  getTriggerTagList(params) {
    return httpRequest.get("campaigns/tags", params);
  }
  updateCampaignBulkSettings(body) {
    return httpRequest.put("campaigns/settings/update", body);
  }

  getSequenceVariations(params: { uid: string }) {
    return httpRequest.get(
      `/campaigns/sequence-variations?campaignSequenceUid=${params.uid}`
    );
  }

  addSequenceVariation(params: IAddSequenceVariation) {
    return httpRequest.post(`/campaigns/sequence-variations`, params);
  }

  updateSequenceVariation(params: IUpdateSequenceVariation) {
    return httpRequest.put(
      `/campaigns/sequence-variations/${params.uid}`,
      params
    );
  }

  deleteSequenceVariation(params: { uid: string }) {
    return httpRequest.delete(`/campaigns/sequence-variations/${params.uid}`);
  }

  getContactList(params: any) {
    return httpRequest.get('/campaigns/contacts/user-contacts', params);
  }

  getCampaignContactList(params: any) {
    return httpRequest.get('/campaigns/campaign-contacts', params);
  }

  getContactListElastic(params: any) {
    return httpRequest.post('/campaigns/campaign-contacts/user-contacts', params);
  }

  getCampaignContactListElastic(params: any) {
    return httpRequest.get('/campaigns/campaign-contacts/list', params);
  }

  assignContact(body: any) {
    return httpRequest.post('/campaigns/contacts/assign', body);
  }

  assignAllContacts(params: any) {
    return httpRequest.post('campaigns/campaign-contacts/add-contact/all-filter-contact', params);
  }

  shareCampaign(params: { uid: string }) {
    return httpRequest.post(`/campaigns/main/share/${params.uid}`);
  }

  shareEmailCampaign(params: { campaignUid: string, email: string; body: string; url: string }) {
    return httpRequest.post(`/campaigns/main/share-campaign`, params);
  }

  unsubscribeContact(body: any) {
    return httpRequest.post('/campaigns/campaign-contacts/unsub-contacts', body);
  }

  pauseOrResumeContact(params: IPauseResumeContactPayload) {
    return httpRequest.put('/campaigns/campaign-contacts/pause-resume-contacts', params);
  }

  personalizedTags() {
    return httpRequest.get('/campaigns/user-custom-fields/personalized-fields?get_custom_data=true&get_personalized_data=true');
  }

  updateEmailThread(body: any) {
    return httpRequest.put('/campaigns/main/update/email-threads', body);
  }

  bulkUnarchiveCampaign(params: IBulkUpdateCampaign) {
    return httpRequest.put('/campaigns/main/update/archive', params);
  }

  testEmailCampaignSequence(params: ITestEmailPayload) {
    return httpRequest.post('/campaigns/main/sequences/send-test-email', params);
  }

  updateCampaignSequenceUploadedFiles(body: ISequenceUploadedFileUpdatePayload) {
    return httpRequest.put('/campaigns/sequences/upload-files/update', body);
  };

  getUserEmailProvider() {
    return httpRequest.get('/campaigns/users/get-email-provider');
  };

  nylasConnect(body: IConnectNylasPayload) {
    return httpRequest.post('/campaigns/third-party-credentials/nylas-connect-url', body);
  };

  getUserVirtualNumbers() {
    return httpRequest.get("campaigns/virtual-numbers");
  };

  uploadTriggerAudioFile(body: FormData) {
    return httpRequest.post("/campaigns/settings/upload-audio-file", body);
  };

  createNewTag(payload: ITagPayload) {
    return httpRequest.post("/campaigns/tags", payload);
  };

  getCampaignSequenceStats(payload: IGetCampaignSequenceStatsPayload) {
    return httpRequest.get(`/campaigns/sequences/stats/${payload.campaignSequenceUid}`);
  };

  getEmailLookupPrice() {
    return httpRequest.get('/campaigns/agency-credit-settings/lookup-verify-email');
  };

  getEmailEventsAnalyticsCampaign(params: IEmailEventsAnalytics) {
    return httpRequest.get('/campaigns/email-events-analytics-data/campaign', params);
  };

  getEmailEventsAnalyticsCampaignSequence(params: IEmailEventsAnalytics) {
    return httpRequest.get('/campaigns/email-events-analytics-data/campaign-sequence', params);
  };

  googleAuthUrl(params: { state: string; email: string; }) {
    return httpRequest.get('/campaigns/google/auth-url', params);
  };

  processGoogleRefreshToken(params: { email: string; }) {
    return httpRequest.post('/campaigns/google/process-refresh-token', params);
  };

  googleReadSpreadsheet(params: { spreadsheetId: string; email: string; worksheetName: string; }) {
    return httpRequest.get('/campaigns/google/read-spreadsheet', params);
  };

  googleWorksheetInfo(params: { spreadsheetId: string; email: string; }) {
    return httpRequest.get('/campaigns/google/get-worksheet-info', params);
  };

  googleUploadSpreadsheet(params: any) {
    return httpRequest.post('/campaigns/user-connected-files', params);
  };

  getEmailEventsAnalyticsCampaignEmail(params: IEmailEventsAnalytics) {
    return httpRequest.get('/campaigns/email-events-analytics-data/campaign-email', params);
  };

  getUserConnectedFiles(params: IConnectedFileListPayload) {
    return httpRequest.get('/campaigns/user-connected-files', params);
  }

  getCampaignEventAnalytics(params: { campaignUid: string; startDate: string; endDate: string; }) {
    return httpRequest.get('/campaigns/email-events-analytics-reports/graph', params);
  };

  getCampaignEventEmailAnalytics(params: { campaignUid: string; startDate: string; endDate: string; filterType:string }) {
    return httpRequest.get('/campaigns/email-events-analytics-reports/email', params);
  };

  uploadGraphImage(params: FormData) {
    return httpRequest.multipart(`/campaigns/main/upload-image/${params.get('campaignUid')}`, params);
  };

  getContactCustomFields() {
    return httpRequest.get(`/campaigns/user-custom-fields`);
  }

  getContactSources() {
    return httpRequest.get(`/campaigns/contact-source-types`);
  }

  getContactPipelines() {
    return httpRequest.get(`/campaigns/pipelines`);
  }

  getContactPipelineStages(params: { pipelineId: number }) {
    return httpRequest.get(`/campaigns/user-stages`, params);
  }

  updateUserConnectedFiles(uid: string, params: any) {
    return httpRequest.put(`/campaigns/user-connected-files/${uid}`, params);
  }

  getEmailTemplate() {
    return httpRequest.get('/campaigns/template-groups/email-templates');
  };

  getOpenAiApiKey(params: { content: string }) {
    return httpRequest.post('/campaigns/openai/chat-completions', params);
  };

  openAIKeySave(body: IOpenAISavePayload) {
    return httpRequest.post('/campaigns/third-party-credentials/openai-api-key', body);
  };

  uploadContactCsvFile(body: FormData) {
    return httpRequest.multipart('/campaigns/file-lists/upload-csv', body);
  };

  getCampaignCsvFiles(params: { campaignUid: string }) {
    return httpRequest.get('/campaigns/file-lists', params);
  };

  deleteCampaignCsvFiles(params: { fileListUuid: string }) {
    return httpRequest.delete('/campaigns/file-lists', params);
  };
  getQueueData(params: IGetQueuePayload) {
    return httpRequest.get('/campaigns/campaign-queue-contact/campaign-sequence', params);
  };
  getSmartContactList(){
    return httpRequest.get('/campaigns/contact-lists');
  }
  getContactTimeLines(params:IContactTimeline){
    return httpRequest.get('/campaigns/timelines/contact-timelines',params);
  }
  getUserConnectedEmailsLimits() {
    return httpRequest.get('/campaigns/user-connected-emails/limits');
  };
  updateUserConnectedEmailsLimits(params) {
    return httpRequest.put('/campaigns/user-connected-emails/limits', params);
  };
  getLeadsEmailTags(params) {
    return httpRequest.get('/campaigns/tags/list', params);
  };

  getUserContactListFilterData() {
    return httpRequest.get('/campaigns/contacts/filter-data');
  }

  addContactsFromSmartList(params: ISmartListPayload) {
    return httpRequest.post('/campaigns/campaign-contacts/add-contact-smart-list', params);
  };

  getMarketingCampaignList() {
    return httpRequest.get('/campaigns/marketing-campaigns/list');
  };
  getBulkEmailCampaignList(params:IBulkEmailParams) {
    return httpRequest.get('/campaigns/main/campaign-list',params);
  };
  importBulkEmailContact(params:IBulkEmailContact){
    return httpRequest.post('/campaigns/campaign-contacts/add-contact/bulk-email',params);
  }
  importMarketingCampaignContact(params:IEmailMarketingContact){
    return httpRequest.post('/campaigns/campaign-contacts/add-contact/marketing',params);
  }
}

export default new EmailCampaignService();
