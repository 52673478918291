import { Box, Divider, Skeleton, Stack, useTheme } from "convertupleads-theme";
import React from "react";

const TemplateLibrarySkeleton: React.FC = () => {
  const theme = useTheme();
  return (
    <Stack
      direction={"row"}
      padding={"24px"}
      height={720}
      sx={{ maxHeight: "720px" }}
    >
      <Box width={300} borderRight={`1px solid ${theme.palette.other.divider}`}>
        <Skeleton width={280} height={50} />
        <Divider />
        {Array.from({ length: 8 }).map((rowNum, i) => (
          <Skeleton width={280} height={50} key={i} />
        ))}
      </Box>
      <Box>
        <Box sx={{ paddingLeft: "16px" }}>
          <Skeleton width={350} height={50} />
        </Box>
        <Divider />
        <Box sx={{ paddingLeft: "16px" }}>
          {Array.from({ length: 2 }).map((rowNum, i) => (
            <Stack key={i}>
              <Skeleton width={500} height={50} />
              <Skeleton width={450} height={50} />
              <Skeleton width={500} height={50} />
              <Skeleton width={350} height={50} />
            </Stack>
          ))}
        </Box>
      </Box>
    </Stack>
  );
};

export default TemplateLibrarySkeleton;
