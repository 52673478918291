import React, {useRef, useState} from 'react';
import {
    Box,
    Button,
    CheckIcon,
    CopyIcon,
    Download2Icon,
    InputAdornment,
    ModalWithHeader,
    SettingsIcon,
    Stack,
    styled,
    Typography,
} from 'convertupleads-theme';
import {CustomTextField} from '../../../BulkEmailStyle';
import AnalyticsGraph from './AnalyticsGraph';
import {toJpeg} from 'html-to-image';
import {getAccountData, showNotification} from '../../../../../helpers/util/functions';
import emailCampaignService from "../../../../../services/emailCampaign.service.ts";

const EachColor = styled(Box)(({ theme }) => ({
    width: 40,
    height: 40,
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    '&:before': {
        content: 'none',
        position: 'absolute',
        width: 32,
        height: 32,
        border: `2px solid ${theme.palette.common.white}`,
    },
    '&.folderColorSelected': {
        '&:before': { content: "''" },
    },
}));


const gradient1 = 'linear-gradient(45deg, rgba(90, 56, 190, 1) 0%, rgba(226, 79, 100, 1) 25%, rgba(254, 179, 54, 1) 50%, rgba(185, 78, 223, 1) 75%, rgba(73, 182, 203, 1) 100%)';
const gradient2 = 'linear-gradient(45deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)';
const gradient3 = 'linear-gradient(45deg, rgba(0, 255, 164, 1) 0%, rgba(0, 191, 255, 1) 50%, rgba(147, 112, 219, 1) 100%)';
const gradient4 = 'linear-gradient(45deg, rgba(255, 204, 92, 1) 0%, rgba(84, 193, 171, 1) 50%, rgba(240, 98, 146, 1) 100%)';
const gradient5 = 'linear-gradient(45deg, rgba(255, 171, 145, 1) 0%, rgba(185, 94, 185, 1) 50%, rgba(141, 212, 217, 1) 100%)';
const gradient6 = 'linear-gradient(45deg, rgba(255, 167, 209, 1) 0%, rgba(156, 201, 61, 1) 100%)';
const gradient7 = 'linear-gradient(45deg, rgba(255, 128, 0, 1) 0%, rgba(128, 0, 255, 1) 50%, rgba(255, 0, 128, 1) 100%)';
const gradient8 = 'linear-gradient(45deg, rgba(0, 128, 255, 1) 0%, rgba(128, 255, 0, 1) 100%)';

const Colors = [gradient1, gradient2, gradient3,gradient4,gradient5,gradient6,gradient7,gradient8];

interface ChartModalProps {
    open: boolean;
    handleClose: () => void;
    campaignUid: string;
}

const ChartModal: React.FC<ChartModalProps> = ({ campaignUid, open, handleClose }) => {
    // const shareUrl = `${window.location.origin}/bulk-email/report/share/${campaignUid}`;
    const [selectedColor, setSelectedColor] = useState(Colors[0]);
    const chartBox = useRef<HTMLDivElement>(null);
    const [shareUrl, setShareUrl] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);

    const generateFileName = () => {
        const now = new Date();
        const formattedNow = now.toISOString().replace(/:/g, '-').split('.')[0];
        return `${campaignUid}_${formattedNow}.jpg`;
    };

    const handleCopy = () => {
        const copyText = document.getElementById('linkTextField') as HTMLInputElement;

        if (copyText) {
            copyText.select();
            document.execCommand('copy');
        }
    };

    const handleColorChange = (color: string) => {
        if (shareUrl){
            setShareUrl('');
        }
        setSelectedColor(color);
    };

    const handleDownload = async () => {
        const base64Data = await handleConvert();

        if (base64Data) {
            const downloadLink = document.createElement("a");
            downloadLink.href = base64Data;
            downloadLink.download = generateFileName();
            downloadLink.click();
        }
    };

    const handleConvert = async () => {
        if (chartBox.current) {
            try {
                return await toJpeg(chartBox.current, {
                    width: 800,
                    height: 550,
                    style: {
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "12px",
                        paddingTop: "40px",
                        paddingBottom: "28px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    },
                });
            } catch (error) {
                console.error("Oops, something went wrong!", error);
            }
        }
    };

    const base64ToBlob = (base64: string, mimeType: string) => {
        const binaryString = atob(base64.split(",")[1]);

        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return new Blob([bytes], { type: mimeType });
    }

    const handleShare = async () => {
        const dataUrl = await handleConvert();
        if (dataUrl) {
            try {
                setIsGenerating(true);
                const blob = base64ToBlob(dataUrl, "image/jpeg");
                const formData = new FormData();

                formData.append("imageFile", blob, generateFileName());
                formData.append("campaignUid", campaignUid);

                const resp = await emailCampaignService.uploadGraphImage(formData);
                if (resp?.success && resp.data){
                    setShareUrl(resp.data.imageUrl);
                }
                showNotification("success", "Share link generated");
            }catch (err){

            } finally {
                setIsGenerating(false);
            }
        }

        // const url = `${window.location.origin}/bulk-email/report/share/${campaignUid}`;
        // setShareUrl(url);
    };
    
    return (
        <ModalWithHeader open={open} onClose={handleClose} title='Download This Chart' width={'40%'}>
            <Stack spacing={2.5} p={2.5} maxHeight={700}>
                {
                    shareUrl &&
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                        <CustomTextField
                            id='linkTextField'
                            value={shareUrl}
                            onChange={()=>{}}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end' sx={{ '& svg': { m: 0, mr: 0.5 }, cursor: 'pointer' }} onClick={handleCopy}>
                                        <p style={{ display: 'inline-flex' }}>
                                            <CopyIcon fontSize={'small'} />
                                            Copy
                                        </p>
                                    </InputAdornment>
                                ),
                            }}
                            label="Share With a Link"
                        />
                    </Stack>
                }

                <Box>
                    <Stack direction={'row'} spacing={1}>
                        {Colors.map((color, index) => (
                            <EachColor
                                className={selectedColor === color ? 'folderColorSelected' : ''}
                                sx={{
                                    backgroundImage: color,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}
                                onClick={() => handleColorChange(color)}
                                key={index}
                            >
                                {selectedColor === color && <CheckIcon sx={{ color: 'common.white' }} />}
                            </EachColor>
                        ))}
                    </Stack>
                </Box>

                <Box sx={{backgroundImage: selectedColor, backgroundSize: 'cover',backgroundRepeat: 'no-repeat',}} borderRadius={4} ref={chartBox}>
                    <Box sx={{ bgcolor: 'white', borderRadius: '12px',mx:'24px',mt:'30px',mb:'23px' }}>
                        <AnalyticsGraph legendPosition={'top'} />
                    </Box>
                    <Typography sx={{color:'white', fontWeight:'500', textAlign:'end', mr:'24px',mb:'23px'}}>{`Powered by ${getAccountData("AuthUserAgencyName")}`}</Typography>
                </Box>
                <Stack direction={'row'} justifyContent={'end'} gap={2}>
                    <Button variant={'tonal'} color={'inherit'} size={'large'} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        startIcon={<SettingsIcon />}
                        disabled={isGenerating}
                        size={"large"}
                        sx={{
                            "&:focus": {
                                backgroundColor: "primary.main",
                            },
                        }}
                        onClick={handleShare}
                    >
                        Generate Share Link
                    </Button>
                    <Button
                      variant={'contained'} color={'primary'} startIcon={<Download2Icon />} size={'large'}
                      sx={{
                          "&:focus": {
                           backgroundColor: 'primary.main',
                         },
                      }}
                      onClick={handleDownload}
                    >
                        Download
                    </Button>
                </Stack>
            </Stack>
        </ModalWithHeader>
    );
};

export default ChartModal;
