import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  RefreshIcon,
  Stack,
  Typography,
  useTheme,
} from "convertupleads-theme";
import { useDispatch, useSelector } from "react-redux";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector";
import { RotatingIcon } from "../../../bulkEmail/BulkEmailStyle.tsx";
import { getCampaignSequenceStats } from "../../../../state/features/emailCampaign/emailCampaignSlice.ts";
import StatusListDrawer from "../../../common/drawer/statusList/StatusListDrawer.tsx";
import { EVENT_TYPE, STATUS_TYPE } from "../../../../helpers/constant/coreConstants.ts";


const CampaignSequenceStats: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [type, setType] = useState({statusType: '', eventType: ''});

  const { selectedSequence, isLoadingCampaignSequenceStats } =
    useSelector(emailCampaignState);

  const STATUS_DATA = [
    {
      label: STATUS_TYPE.OPEN,
      value: selectedSequence?.sequenceStats?.totalOpen,
      color: theme.palette.primary.main,
    },
    {
      label: STATUS_TYPE.QUEUE,
      value: selectedSequence?.sequenceStats?.totalQueue,
      color: theme.palette.warning.main,
    },
    {
      label: STATUS_TYPE.SEND,
      value: selectedSequence?.sequenceStats?.totalSent,
      color: theme.palette.success.main,
    },
    {
      label: STATUS_TYPE.FAILED,
      value: selectedSequence?.sequenceStats?.totalFailed,
      color: theme.palette.error.main,
    },
  ];

  const refreshSequenceStats = () => {
    dispatch(
      getCampaignSequenceStats({
        campaignSequenceUid: selectedSequence?.uid as string,
      })
    );
  };

  const handleStatus = async (type: string) => {
    // if(type === STATUS_TYPE.QUEUE) return;

    setOpenDrawer(true);
    setType({statusType: type, eventType: EVENT_TYPE.SEQUENCE,});
  };

  const drawerTitle = type.statusType === STATUS_TYPE.OPEN ? "Open List" : type.statusType === STATUS_TYPE.QUEUE ? "Queue List" : type.statusType === STATUS_TYPE.SEND ? "Sent List" : "Failed List"

  return (
    <Box sx={{ flexGrow: 1, pl: 2, py: 1, mt: 2.5 }}>
      <Grid
        container
        spacing={2}
        bgcolor={theme.palette.grey[100]}
        sx={{
          "--Grid-borderWidth": "1px",
          borderTop: "var(--Grid-borderWidth) solid",
          borderLeft: "var(--Grid-borderWidth) solid",
          borderColor: theme.palette.other.outlinedBorder,
          "& > div": {
            borderRight: "var(--Grid-borderWidth) solid",
            borderBottom: "var(--Grid-borderWidth) solid",
            borderColor: theme.palette.other.outlinedBorder,
          },
          position: "relative",
        }}
      >
      {isLoadingCampaignSequenceStats ? (
          <Button
          onClick={refreshSequenceStats}
          sx={{
            position: "absolute",
            right: "0px",
            top: "0px",
            height:'54px',
            width:'70px',
            borderLeft: '1px solid rgba(148, 157, 178, 0.12)',
            borderRadius:'0px',
            backgroundColor: "transparent",
            "&:hover": { backgroundColor: "transparent" },
            "&:focus": { backgroundColor: "transparent" },
          }}
        >
          <RotatingIcon sx={{ color: "#006DF5" }} />
        </Button>
        ) : (
          <Button
            onClick={refreshSequenceStats}
            sx={{
              position: "absolute",
              right: "0px",
              top: "0px",
              height:'54px',
              width:'70px',
              borderLeft: '1px solid rgba(148, 157, 178, 0.12)',
              borderRadius:'0px',
              backgroundColor: "transparent",
              "&:hover": { backgroundColor: "transparent" },
              "&:focus": { backgroundColor: "transparent" },
            }}
          >
            <RefreshIcon sx={{ color: "#006DF5" }} />
          </Button>
        )}
        {STATUS_DATA.map((item, index) => (
          <Grid key={index} {...{ xs: 12, sm: 6, lg: 3 }} minHeight={55}>
            <Stack
              width={"100%"}
              height={"100%"}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
              sx={{ cursor:"pointer" }}
              onClick={() => handleStatus(item.label)}
            >
              <Typography variant="body2" color={item.color} fontWeight={500}>
                {item.label === STATUS_TYPE.OPEN
                  ? "Open"
                  : item.label === STATUS_TYPE.QUEUE
                  ? "Queue"
                  : item.label === STATUS_TYPE.SEND
                  ? "Sent"
                  : "Failed"}
              </Typography>
              <Typography variant="h4" color={item.color}>
                {item.value || 0}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>

      <StatusListDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        drawerTitle={drawerTitle}
        type={type}
        width={1200}
      />
    </Box>
  );
};

export default CampaignSequenceStats;
