import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Button, CopySuccessIcon, Divider, IconButton, Modal, Stack, Typography } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { cloneCampaign } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { IEmailCampaign } from '../../../../state/features/emailCampaign/emailCampaign.interface';

interface ICloneModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onClose: () => void;
    campaignUid: string;
    campaignTitle: string;
    campaign: IEmailCampaign;
}

const CloneModal: React.FC<ICloneModalProps> = ({ open, setOpen, onClose, campaign, campaignUid, campaignTitle }) => {
    const dispatch = useDispatch();
    const { isCloneCampaignSuccess } = useSelector(emailCampaignState);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };
    const handleCloneButton = () => {
        dispatch(cloneCampaign(campaign));
    };

    useEffect(() => {
        if (isCloneCampaignSuccess) {
            handleClose();
            onClose();
        }
    }, [isCloneCampaignSuccess]);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                '&.MuiPaper-root': {
                    width: '385px !important',
                },
            }}
        >
            <Stack>
                <Stack direction={'row'} justifyContent={'center'} padding={2} paddingBottom={0}>
                    <IconButton variant={'outlined'} color={'warning'} sx={{ width: '75px', height: '75px' }}>
                        <CopySuccessIcon sx={{ fontSize: '50px' }} />
                    </IconButton>
                </Stack>
                <Stack direction={'row'} justifyContent={'center'}>
                    <Stack direction={'row'} textAlign={'center'} width={300} padding={2}>
                        <Typography variant='h5'>Do you want to clone this campaign ?</Typography>
                    </Stack>
                </Stack>
                <Stack width={'100%'}>
                    <Divider />
                    <Stack padding={2} direction={'row'} justifyContent={'flex-end'} gap={1}>
                        <Button variant={'tonal'} color={'inherit'} size='large' onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button size='large' onClick={handleCloneButton}>
                            Clone
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Modal>
    );
};

export default CloneModal;
