import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  AddIcon,
  ArrowLeftIcon,
  Button,
  ChevronDownIcon,
  CloseBorderedIcon,
  DeleteIcon,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "convertupleads-theme";
import LeadContactListFilter from "./LeadContactListFilter";
import LeadContactListPagination from "./LeadContactListPagination";
import LeadContactListTable from "./LeadContactListTable";
import ConnectPopOver from "../popover/ConnectPopOver";
import { LEAD_ITEM } from "../../../../../helpers/constant/coreConstants";
import LeadContactListSelectedState from "./LeadContactListSelectedState";
import ImportContactPopOver from "../popover/ImportContactPopOver";
import AddMultipleContact from "../popover/AddMultipleContact";
import { getAccountData } from "../../../../../helpers/util/functions";


interface IProps {
  onActiveSection: Dispatch<SetStateAction<string>>;
  goSubStep: (step: number, subStep: number | null) => void;
  onEmptyList: Dispatch<SetStateAction<boolean>>
  setIsShownAlready: Dispatch<SetStateAction<boolean>>
  isShownAlready: boolean;
  onBack: Dispatch<SetStateAction<string>>;
}
const CampaignLeadContactList: React.FC<IProps> = ({ onActiveSection, goSubStep, onEmptyList, isShownAlready, setIsShownAlready,onBack }) => {
  const [connect, setConnect] = React.useState<HTMLSpanElement | SVGSVGElement | null>(null);
  const [importContact, setImportContact] = React.useState<HTMLSpanElement | SVGSVGElement | null>(null);
  const [addMultitpleContact, setAddMultitpleContact] = React.useState<HTMLSpanElement | SVGSVGElement | null>(null);
  const [searchContent, setSearchContent] = React.useState('');
  const [sortType, setSortType] = useState<string>('')
  const [sortContentBy, setSortContentBy] = useState<string>('')
  const [totalCount, setTotalCount] = useState<number>()




  const handleConnect = (
    event:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setConnect(event.currentTarget);
  };

  const handleOpenContactPopOver = (
    event:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setImportContact(event.currentTarget);
  };
  const handleOpenAddMultipleContactPopOver = (
    event:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setAddMultitpleContact(event.currentTarget);
  };
  const openAddContactModal = () => {
    // e.preventDefault()
    // // alert('hello')
    // // setViewAddContactModal(true)
    // setShowDateRange(false);
    (window as any).showAddContactModal();

  }
  const openConnect = Boolean(connect);
  const connectId = openConnect ? "simple-popover" : undefined;
  const openImportContact = Boolean(importContact);
  const importConnectId = openImportContact ? "simple-popover" : undefined;
  const openAddMultipleContact = Boolean(addMultitpleContact);
  const AddMultipleContactId = openAddMultipleContact ? "simple-popover" : undefined;


  const [selectedContact, setSelectedContact] = useState([]);

  const isSpecificUser = getAccountData("userId") === 1 || getAccountData("userId") === 446;
  return (
    <Stack width={"100%"}>
      {isShownAlready && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          p={1}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              sx={{ ":focus": { bgcolor: "transparent" } }}
              onClick={() => onBack("")}
            >
              <ArrowLeftIcon color="text.secondary" />
            </IconButton>
            <Typography variant={"h5"} color={"text.primary"}>
              Campaign Contact List
            </Typography>
          </Stack>

          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            {/* <Button
            sx={{ ":focus": { bgcolor: "primary.main" } }}
            onClick={() => onActiveSection(LEAD_ITEM.SMART_LIST)}
          >
            Add from List
          </Button> */}

            {/* <Button
            variant="outlined"
            color="inherit"
            sx={{ ":focus": { bgcolor: "transparent" } }}
            endIcon={<ChevronDownIcon />}
            onClick={handleConnect}
          >
            Connect
          </Button> */}
            {/* <Button
            onClick={openAddContactModal}
            variant="outlined"
            color="inherit"
            sx={{ ":focus": { bgcolor: "transparent" } }}
            startIcon={<AddIcon />}
          >
            Create
          </Button> */}
            <Button
              onClick={handleOpenContactPopOver}
              variant="outlined"
              endIcon={<ChevronDownIcon />}
              sx={{ ":focus": { bgcolor: "transparent" } }}
            >
              Import
            </Button>

            {isSpecificUser && (
              <Button
                variant="outlined"
                sx={{ ":focus": { bgcolor: "transparent" } }}
                endIcon={<ChevronDownIcon />}
                onClick={handleOpenAddMultipleContactPopOver}
              >
                Add Contact
              </Button>
            )}

            <Button
              sx={{ ":focus": { bgcolor: "primary.main" } }}
              onClick={() => onActiveSection(LEAD_ITEM.ADD_FROM_CONTACT_LIST)}
            >
              Add from contact List
            </Button>
          </Stack>
        </Stack>
      )}
      <Stack px={1}>
        <Paper elevation={0} variant="outlined" sx={{ borderRadius: "8px" }}>
          {isShownAlready && (
            <>
              {selectedContact.length === 0 ? (
                <LeadContactListFilter
                totalCount={totalCount}
                  setSearchContent={setSearchContent}
                  searchContent={searchContent}
                  setSortContentBy={setSortContentBy}
                  setSortType={setSortType}
                />
              ) : (
                <LeadContactListSelectedState
                  totalSelected={selectedContact.length}
                />
              )}

              <Divider light />
            </>
          )}
          {/* <LeadContactListPagination totalContactsCount={totalContactsCount} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} setCurrentPage={setCurrentPage}/> */}

          <LeadContactListTable
            searchContent={searchContent}
            sortContentBy={sortContentBy}
            onActiveSection={onActiveSection}
            onEmptyList={onEmptyList}
            sortType={sortType}
            setIsShownAlready={setIsShownAlready}
            setTotalCount={setTotalCount}
          />
        </Paper>
      </Stack>

      <ConnectPopOver
        connect={connect}
        connectId={connectId}
        openConnect={openConnect}
        handleClose={() => setConnect(null)}
      />
      <ImportContactPopOver
        connect={importContact}
        connectId={importConnectId}
        open={openImportContact}
        handleClose={() => setImportContact(null)}
        goSubStep={goSubStep}
      />
      <AddMultipleContact
        connect={addMultitpleContact}
        connectId={AddMultipleContactId}
        open={openAddMultipleContact}
        handleClose={() => setAddMultitpleContact(null)}
        goSubStep={goSubStep}
        onActiveSection={onActiveSection}
      />
    </Stack>
  );
};

export default CampaignLeadContactList;
