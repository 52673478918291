import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Button,
    ChevronDownIcon,
    PersonalizedIcon,
    Stack,
    Typography,
    useTheme,
} from 'convertupleads-theme';
import useDebounce from '../../../../hooks/useDebounce';
import TemplateLibraryModal from '../templateLibraryModal/TemplateLibraryModal';
import PersonalizedVideoModal from '../personilizedVideo/PersonalizedVideoModal';
import EditPersonalizedVideoModal from '../personilizedVideo/EditPersonalizedVideoModal';
import PersonalizedPopover from '../../../common/PersonalizedPopover';
import { insertPersonalizedTag } from '../../../../helpers/util/functions';
import BasicEditorWithGif from '../../../common/BasicEditorWithGif';

interface Props {
    showError?: boolean;
    onChange: Dispatch<SetStateAction<string>>;
    editBody?: string;
}
const MultipleEmailVariationTextEditor: React.FC<Props> = ({ showError, onChange, editBody }) => {
    const theme = useTheme();

    const bodyRef = useRef(null);
    const [openTemplateModal, setOpenTemplateModal] = React.useState(false);
    const [openPersonalizedVideoModal, setPersonalizedVideoModal] = React.useState(false);
    const [openEditPersonalizedVideoModal, setEditPersonalizedVideoModal] = React.useState(false);
    const [messageBody, setMessageBody] = useState(editBody || '');
    const debouncedBody = useDebounce(messageBody, 500);
    const handlePersonalizedVideoModal = () => setPersonalizedVideoModal(true);
    const [openPersonalizedPopover, setOpenPersonalizedPopover] = React.useState<HTMLButtonElement | null>(null);


    useEffect(() => {
        // if (selectedSequence && debouncedBody && selectedSequence.messageBody !== debouncedBody) {
        //     dispatch(updateCampaignSequence({ messageBody: debouncedBody, uid: selectedSequence.uid, index: selectedSequence.index }));
        // }

        onChange(debouncedBody);
    }, [debouncedBody]);

    const handlePersonalizePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenPersonalizedPopover(event.currentTarget);
    };
    const openPopover = Boolean(openPersonalizedPopover);
    const handleClose = () => {
        setOpenPersonalizedPopover(null);
    };

    const errorText = showError && (
        <Typography variant='caption' color={'error'} p={1}>
            Variant body is Required
        </Typography>
    );

    return (
        <>
            <Stack
                position={'relative'}
                sx={{
                    '& .tox-tinymce': {
                        border: `1px solid ${theme.palette.other.divider}`,
                        borderRadius: '8px',
                    },
                    '& .tox .tox-statusbar': {
                        borderTop: `1px solid ${theme.palette.other.divider}`
                    },
                    '& .tox .tox-toolbar__primary': {
                        background: 'unset',
                        borderBottom: `1px solid ${theme.palette.other.divider}`,
                    }


                }}
            >
                <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'space-between'} mb={1}>
                    <Typography variant='body2' color={'text.secondary'} fontWeight={500}>
                        Email Body
                    </Typography>
                    <Button
                        onClick={handlePersonalizePopover}
                        sx={{ background: theme.palette.other.divider, ':focus': { bgcolor: theme.palette.other.divider } }}
                        variant={'tonal'}
                        color={'inherit'}
                        startIcon={<PersonalizedIcon />}
                        endIcon={<ChevronDownIcon />}
                    >
                        Personalize
                    </Button>
                </Stack>

                <BasicEditorWithGif
                    ref={bodyRef}
                    body={messageBody}
                    onChangeBody={(text) => setMessageBody(text)}
                />

                {errorText}

                {/* <Stack
                    direction={'row'}
                    spacing={1}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    padding={2}
                    sx={{ background: 'white' }}
                >
                    <Button onClick={handlePersonalizedVideoModal} variant='outlined' startIcon={<PersonalizedIcon />}  sx={{':focus': {bgcolor: 'transparent'}}}>
                        Personalized Video
                    </Button>
                    
                </Stack> */}
            </Stack>

            {openTemplateModal &&
                <TemplateLibraryModal open={openTemplateModal} setOpen={setOpenTemplateModal} />
            }
            {PersonalizedVideoModal &&
                <PersonalizedVideoModal open={openPersonalizedVideoModal} setOpen={setPersonalizedVideoModal} />
            }
            {EditPersonalizedVideoModal &&
                <EditPersonalizedVideoModal open={openEditPersonalizedVideoModal} setOpen={setEditPersonalizedVideoModal} />
            }
            <PersonalizedPopover
                open={openPopover}
                id={'simple-popover'}
                onClose={handleClose}
                anchorEl={openPersonalizedPopover}
                onSelect={(value: string | null) => {
                    if (value) {
                        insertPersonalizedTag(value, bodyRef, (value) => {
                            setMessageBody(value);
                        });
                    }
                }}
            />
        </>
    );
};

export default MultipleEmailVariationTextEditor;
