import React from 'react';
import { Stack, FileUpload, Divider, Button, styled } from 'convertupleads-theme';

const StyledButton = styled(Button)(({theme})=>({
    background:  theme.palette.other.outlinedBorder,
    color: theme.palette.text.secondary,
    '&:hover, :focus': {
        background:theme.palette.other.outlinedBorder,
        color:theme.palette.text.secondary
    }
}))
    

const ContactCsv: React.FC = () => {
    const handleFileChange = (files: File[]) => {
        
        console.log('Selected files:', files);
    };
    return (
        <Stack height={787} justifyContent={'space-between'}>
            <Stack p={2}>
                <FileUpload onChange={handleFileChange} />
            </Stack>
            <Stack>
                <Divider light />
                <Stack direction={'row'} spacing={1} p={'16px 20px'} justifyContent={'space-between'}>
                    <StyledButton variant={'tonal'} size={'large'}>Cancel</StyledButton>
                    <Button variant={'contained'} size={'large'} sx={{ background: 'primary', color: 'primary' }}>
                        Continue
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
   
};

export default ContactCsv;
