import React from "react";
import {
    Box,
    LinearProgress,
    Stack,
    Typography,
    UploadFileFilledIcon,
    useTheme
} from "convertupleads-theme";
import {IUploadedFile} from "./CampaignSquenceEmailBodyTextEditor.tsx";
import {formatFileSize} from "../../../helpers/util/functions.tsx";

interface IProps {
    file: IUploadedFile;
}

const EachAttachedUploadingFile: React.FC<IProps> = ({ file }) => {
    const theme = useTheme();

    return(
        <Box
            padding={2}
            overflow={'hidden'}
            position={'relative'}
            borderRadius={2}
            mb={1}
            sx={{ background: theme.palette.other.outlinedBorder, minWidth: "350px" }}
        >
            <Stack width={"100%"}>
                <Stack direction={"row"} alignItems={"center"} width={"100%"}>
                    <UploadFileFilledIcon sx={{ fontSize: "36px", color: "text.tertiary" }}/>
                    <Stack ml={1} width={"100%"}>
                        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                            <Typography color={"text.tertiary"}  variant={"subtitle1"}>{file.file.name}</Typography>
                        </Stack>
                        <Typography color={"text.tertiary"}  variant={"caption"}>{formatFileSize(file.file.size)}</Typography>
                    </Stack>
                </Stack>
                <Stack sx={{ paddingInline: "5px", paddingTop: "10px" }}>
                    <LinearProgress variant="determinate" value={file.progress} />
                </Stack>
            </Stack>
        </Box>
    );
};

export default EachAttachedUploadingFile;