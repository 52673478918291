import {
  ArrowLeftIcon,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  Paper,
  Select,
  Stack,
  Typography,
  useTheme,
} from "convertupleads-theme";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CustomMenuItem } from "../../BulkEmailStyle";
import emailCampaignService from "../../../../services/emailCampaign.service";
import PopupAlert from "../../../common/PopupAlert";
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector";
import { LEAD_ITEM } from "../../../../helpers/constant/coreConstants";

interface IProps {
  onBack: Dispatch<SetStateAction<string>>;
}

interface IContact {
  uid: string;
  title: string;
}
const BulkEmailContactList: React.FC<IProps> = ({ onBack }) => {
  const [selectedCampaignUid, setSelectedCampaignUid] = useState<string | "default">("default");
  const [bulkEmailCampaignContacts, setBulkEmailCampaignContacts] = useState<IContact[]>();
  const [isChecked, setIsChecked] = useState(false);
  const [confirmAddContact, setConfirmAddContact] = useState(false);
  const { selectedCampaign } = useSelector(emailCampaignState);
  const theme = useTheme();

  useEffect(() => {
    fetchEmailMarketingData();
  }, []);

  const fetchEmailMarketingData = async () => {
    try {
      const res = await emailCampaignService.getBulkEmailCampaignList({campaignUid: selectedCampaign?.uid as string,});
      if (res && res.success) {
        setBulkEmailCampaignContacts(res.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await emailCampaignService.importBulkEmailContact({
        campaignUid: selectedCampaign?.uid as string,
        bulkEmailCampaignUid: selectedCampaignUid as string,
        addUnsub: isChecked,
      });
      if (res && res.success) {
        (window as any)?.showNotification("success", res.message);
        onBack(LEAD_ITEM.CAMPAIGN_CONTACT_LIST);
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  };
  
  return (
    <Stack p={2} width={"100%"}>
      <Stack mb={2} mt={1} direction={"row"} alignItems={"center"} spacing={1}>
        <IconButton
          sx={{ ":focus": { bgcolor: "transparent" } }}
          onClick={() => onBack("")}
        >
          <ArrowLeftIcon color="text.secondary" />
        </IconButton>
        <Stack>
          <Typography variant={"h5"} color={"text.primary"}>
            Add Contacts From a Bulk Email Campaign
          </Typography>
          <Typography variant={"caption"}>
            Add contact from bulk email campaign
          </Typography>
        </Stack>
      </Stack>
      <Stack
        border={`1px solid ${theme.palette.other.outlinedBorderDarker}`}
        height={`calc(100vh - 300px)`}
        borderRadius={2}
        padding={2}
        alignItems={"center"}
      >
        <Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h5">Bulk Email Campaign</Typography>
            <Button
              disabled={selectedCampaignUid === "default" ? true : false}
              onClick={() => setConfirmAddContact(true)}
              variant="contained"
              color="primary"
            >
              Import
            </Button>
          </Stack>
          <Paper
            sx={{ p: 4, display: "flex", justifyContent: "center", mt: 3 }}
          >
            <Stack>
              <Stack direction={"row"} spacing={"64px"}>
                <Stack>
                  <Typography variant="body1" color={"text.primary"}>
                    Add Contacts From a Bulk Email Campaign
                  </Typography>
                  <Typography variant="body2" color={"text.secondary"}>
                    This option allows you to add contacts from an existing bulk
                    email campaigns.
                  </Typography>
                </Stack>
                <FormControl sx={{ mt: 2, width: "250px" }}>
                  <Select
                    value={selectedCampaignUid}
                    width={"100%"}
                    onChange={(e) =>
                      setSelectedCampaignUid(e.target.value as string)
                    }
                    // IconComponent={ArrowDropDownFilledIcon}
                    // MenuProps={MenuProps}
                  >
                    <CustomMenuItem
                      color="text.primary"
                      disabled
                      value={"default"}
                    >
                      Select list
                    </CustomMenuItem>
                    {bulkEmailCampaignContacts?.map((contact) => (
                      <CustomMenuItem
                        color="text.primary"
                        value={contact?.uid}
                      >
                        {contact.title}
                      </CustomMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack mt={3} direction={"row"} alignItems={"center"} spacing={2}>
                <Checkbox
                  checked={isChecked}
                  onClick={() => setIsChecked((prev) => !prev)}
                  sx={{ padding: 0 }}
                  size="medium"
                />
                <Typography variant="body1" color={"text.primary"}>
                  I would like to add the unsubscribe contacts
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
      <PopupAlert
        title={" "}
        description={
          "Contact add to campaign will process! It might take 2-3 minutes to complete."
        }
        submitText={"Ok"}
        open={confirmAddContact}
        onSubmit={() => {
          handleSubmit();
          setConfirmAddContact(false);
        }}
        onCancel={() => {
          setConfirmAddContact(false);
        }}
      />
    </Stack>
  );
};

export default BulkEmailContactList;
