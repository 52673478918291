import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  AddIcon,
  ArrowLeftIcon,
  Button,
  Chip,
  CloseBorderedIcon,
  CloseCircleIcon,
  DeleteModal,
  Divider,
  FormControlLabel,
  IconButton,
  InfoCircleIcon,
  LinearProgress,
  Modal,
  RadioGroup,
  RefreshIcon,
  SettingsIcon,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "convertupleads-theme";
import { useSelector } from "react-redux";
import emailCampaignService from "../../services/emailCampaign.service";
import { emailCampaignState } from "../../state/features/emailCampaign/emailCampaignSelector";
import { CustomScrollbar } from "../common/CustomScrollBarStyle";
import EmailConnectPopup from "../common/EmailConnectPopup";
import PopupAlert from "../common/PopupAlert";
import { CustomRadio, CustomTextField } from "./BulkEmailStyle";
import CampaignsBodySkeleton from "./campaigns/CampaignsBodySkeleton";
import EmptyComponent from "./emptyComponent/EmptyComponent";
import {useSearchParams} from "react-router-dom";

interface IProps {
  open: boolean;
  onClose: () => void;
}
interface IEachEmailProps {
  data: IEmailAccount;
  setEditModal: Dispatch<SetStateAction<IEmailAccount | null>>;
  setDeleteModal: Dispatch<SetStateAction<string | null>>;
  onDisconnectOrReconnect: React.Dispatch<
    React.SetStateAction<IConfirmDisconnectOrReconnect | null>
  >;
  setFormData: Dispatch<
    SetStateAction<ISendingLimits>
  >;
  setIsSendingLimitEmpty: React.Dispatch<React.SetStateAction<boolean>>
}

interface SendingLimits {
  limit: number;
  total_send: number;
  time_limit_unit: string;
  maximum_interval: number;
  minimum_interval: number;
  email_limit_scope: string;
}

interface IEmailAccount {
  uid: string;
  email: string;
  count: number;
  isDefault: "YES" | "NO" | null;
  failReason: string | null;
  credentialInfo: string | null;
  sendingLimits: SendingLimits;
  status: "ACTIVE" | "INACTIVE" | "FAILED" | "TRASH";
  createdAt: Date | null;
  updatedAt: Date | null;
}

interface IConfirmDisconnectOrReconnect {
  type: "disconnect" | "reconnect";
  uid: string;
}

const ModalWrapper = styled(Modal)(({ theme }) => ({
  height: "96%",
  zIndex: "1200",
  marginTop: "20px",

  ".MuiStack-root": {
    ":focus-visible": {
      outline: "unset !important",
    },
  },

  "& .contentWrapper": {
    background: theme.palette.common.white,
    height: "calc(100vh - 200px)",
    borderRadius: "8px",
    overflow: "hidden",
  },
}));

const EMAIL_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  FAILED: "FAILED",
  TRASH: "TRASH",
};

const EachEmailAccount: React.FC<IEachEmailProps> = ({
  data,
  setEditModal,
  setDeleteModal,
  setFormData,
  onDisconnectOrReconnect,
  setIsSendingLimitEmpty,
}) => {
  const { sendingLimits } = data;

  let convertCampaignLimit;

  if (
    sendingLimits?.total_send == null ||
    sendingLimits?.limit == null ||
    sendingLimits?.limit === 0 ||
    sendingLimits?.total_send === 0
  ) {
    convertCampaignLimit = 0;
  } else {
    convertCampaignLimit =
      (sendingLimits.total_send / sendingLimits.limit) * 100;
  }

  const emailStatus = () => {
    switch (data.status) {
      case EMAIL_STATUS.ACTIVE:
        return <Chip color="success" size="xSmall" label={"Active"} />;
      case EMAIL_STATUS.FAILED:
        return <Chip color="error" size="xSmall" label={"Failed"} />;
      case EMAIL_STATUS.INACTIVE:
        return <Chip size="xSmall" label={"Inactive"} />;
      case EMAIL_STATUS.TRASH:
        return <Chip color="warning" size="xSmall" label={"Trash"} />;
      default:
        return <Chip size="xSmall" label={"N/A"} />;
    }
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row" width={"40%"}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1">{data?.email}</Typography>
        </Stack>
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {emailStatus()}
      </TableCell>
      <TableCell align="center">{data?.count || 0}</TableCell>
      <TableCell align="center">
        <LinearProgress
          variant="determinate"
          value={convertCampaignLimit}
          sx={{ borderRadius: 8 }}
        />
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Settings" placement="top" arrow>
          <IconButton
            size="small"
            color="inherit"
            sx={{
              mr: 0.5,
              "&:focus": { bgcolor: "transparent" },
            }}
            onClick={() => {
              setEditModal(data);
              setFormData({
                uid: data?.uid,
                limit: sendingLimits?.limit,
                minimum_interval: sendingLimits?.minimum_interval,
                maximum_interval: sendingLimits?.maximum_interval,
                email_limit_scope: sendingLimits?.email_limit_scope,
              });

              setIsSendingLimitEmpty(sendingLimits?.limit ? false : true);
            }}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>

        {/* {(data.status === EMAIL_STATUS.INACTIVE ||
          data.status === EMAIL_STATUS.FAILED) && (
          <Tooltip title="Reconnect" placement="top" arrow>
            <IconButton
              size="small"
              color="inherit"
              sx={{
                mr: 0.5,
                "&:focus": { bgcolor: "transparent" },
              }}
              onClick={() =>
                onDisconnectOrReconnect({
                  type: "reconnect",
                  uid: data?.uid,
                })
              }
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )} */}

        {/* {data?.status === EMAIL_STATUS.ACTIVE && (
          <Tooltip title="Disconnect" placement="top" arrow>
            <IconButton
              size="small"
              color="inherit"
              sx={{
                mr: 0.5,
                "&:focus": { bgcolor: "transparent" },
              }}
              onClick={() =>
                onDisconnectOrReconnect({
                  type: "disconnect",
                  uid: data?.uid,
                })
              }
            >
              <CloseBorderedIcon />
            </IconButton>
          </Tooltip>
        )} */}

        {/* <Tooltip title="Delete" placement="top" arrow>
          <IconButton
            size="small"
            color="error"
            sx={{ "&:focus": { bgcolor: "transparent" } }}
            onClick={() => {
              setDeleteModal(data.uid);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip> */}
      </TableCell>
    </TableRow>
  );
};

interface ISendingLimits {
  uid: string;
  limit: number | string;
  minimum_interval: number | string;
  maximum_interval: number | string;
  email_limit_scope: string;
  total_send?: number; // Optional
  time_limit_unit?: string; // Optional
}

const CampaignHeaderSettingsModal: React.FC<IProps> = ({ open, onClose }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userEmails, setUserEmails] = useState<IEmailAccount[]>([]);
  const [addEmailModal, setAddEmailModal] = useState(false);
  const [editModal, setEditModal] = useState<IEmailAccount | null>(null);
  const [deleteModal, setDeleteModal] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [minIntervalErrorMessage, setMinIntervalErrorMessage] = useState<
    string | null
  >(null);
  const [emailScopeErrorMessage, setEmailScopeErrorMessage] = useState<
    string | null
  >(null);
  const [confirmDisconnectOrReconnect, setConfirmDisconnectOrReconnect] =
    useState<IConfirmDisconnectOrReconnect | null>(null);
  const [formData, setFormData] = useState<ISendingLimits>({
    uid: "",
    limit: 100,
    minimum_interval: 30,
    maximum_interval: 300,
    email_limit_scope: "ALL",
  });

  const [isSendingLimitEmpty, setIsSendingLimitEmpty] = useState(false);

  const [limitWarningMessage, setLimitWarningMessage] = useState<string | null>(
    null
  );

  const theme = useTheme();

  const {
    isUserEmailProviderLoading,
    isUserEmailProviderSuccess,
    userEmailProvider,
  } = useSelector(emailCampaignState);

  useEffect(() => {
    getConnectedEmailApi();
  }, []);

  console.log(formData);

  useEffect(() => {
    if (formData?.uid) {
      if (+formData?.limit > 300) {
        setLimitWarningMessage(
          "Sending more than 300 emails per day is quite a lot. You might be blocked by your provider."
        );
      }
    }

    if (formData?.uid) {
      if (isSendingLimitEmpty && +formData?.limit >= 0) {
        setFormData({
          ...formData,
          minimum_interval: 30,
          maximum_interval: 60,
          email_limit_scope: "ALL",
        });
      }

      if (isSendingLimitEmpty && !formData?.limit) {
        setFormData({
          ...formData,
          minimum_interval: "",
          maximum_interval: "",
          email_limit_scope: "",
        });
      }
    }
  }, [formData.uid, formData.limit]);

  const getConnectedEmailApi = async () => {
    setIsLoading(true);
    try {
      const response =
        await emailCampaignService.getUserConnectedEmailsLimits();
      if (response.success) {
        setUserEmails(response.data);

        if (searchParams.has('userConnectedEmailUid')) {
          let emails = response.data.find((email: IEmailAccount)=>(email.uid === searchParams.get('userConnectedEmailUid')));
          if (emails){
            setEditModal(emails);
            setFormData({
              uid: emails.uid,
              limit: emails.sendingLimits?.limit,
              minimum_interval: emails.sendingLimits?.minimum_interval,
              maximum_interval: emails.sendingLimits?.maximum_interval,
              email_limit_scope: emails.sendingLimits?.email_limit_scope,
            });

            setIsSendingLimitEmpty(emails.sendingLimits?.limit ? false : true);
          }

          if (searchParams.has('userConnectedEmailUid')) {
            searchParams.delete('userConnectedEmailUid');
          }

          setSearchParams(searchParams);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const perHour = formData?.maximum_interval
    ? 3600 / +formData.maximum_interval
    : 0;

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "limit" && +value > 300) {
      setLimitWarningMessage(
        "Sending more than 300 emails per day is quite a lot. You might be blocked by your provider."
      );
    } else if (name === "limit" && +value < 300) {
      setLimitWarningMessage(null);
    }

    if (name === "limit" && +value < 0) {
      setLimitWarningMessage("Daily email limit cannot be less than 0.");
      return;
    }

    setLimitWarningMessage(null);

    // setFormData({
    //   ...formData,
    //   [name]: isNaN(value as any) ? value : +value,
    // });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (
      (!formData.email_limit_scope ||
        formData.email_limit_scope === "" ||
        formData.email_limit_scope === null ||
        formData.email_limit_scope === undefined) &&
      +formData.limit >= 0
    ) {
      setEmailScopeErrorMessage("Select an sending limit option.");
      return;
    } else {
      setEmailScopeErrorMessage(null);
    }

    if (+formData.minimum_interval < 30 && +formData.limit >= 0) {
      setMinIntervalErrorMessage(
        "Minimum time limit cannot be less than 30 seconds."
      );
      return;
    } else {
      setMinIntervalErrorMessage(null);
    }

    if (+formData.minimum_interval > +formData.maximum_interval) {
      setErrorMessage(
        "Minimum time limit cannot be greater than maximum time limit."
      );
      return;
    } else if (+formData.minimum_interval < 0) {
      setErrorMessage("Minimum time limit cannot be less than 0.");
      return;
    } else if (+formData.maximum_interval < 0) {
      setErrorMessage("Maximum time limit cannot be less than 0.");
      return;
    } else if (+formData.limit < 0) {
      setErrorMessage("Email limit cannot be less than 0.");
      return;
    }

    setErrorMessage(null);
    setMinIntervalErrorMessage(null);
    setEmailScopeErrorMessage(null);

    const data = {
      userConnectedEmailUid: formData?.uid,
      limit: +formData.limit,
      minimumInterval: +formData.minimum_interval,
      maximumInterval: +formData.maximum_interval,
      emailLimitScope: formData.email_limit_scope,
    };

    const emptyLimitData = {
      userConnectedEmailUid: formData?.uid,
      limit: "",
      minimumInterval: "",
      maximumInterval: "",
      emailLimitScope: "",
    };

    try {
      const response =
        await emailCampaignService.updateUserConnectedEmailsLimits(
          formData?.limit ? data : emptyLimitData
        );

      if (response.success) {
        (window as any).showNotification("success", response.message);
        // Update the state
        setUserEmails((prevState) =>
          prevState.map((email) => {
            if (email?.uid === formData?.uid) {
              return {
                ...email,
                sendingLimits: formData?.limit
                  ? {
                      ...email.sendingLimits,
                      limit: +formData.limit,
                      minimum_interval: +formData.minimum_interval,
                      maximum_interval: +formData.maximum_interval,
                      email_limit_scope: formData.email_limit_scope as string,
                    }
                  : {
                      ...email.sendingLimits,
                      limit: 0,
                      minimum_interval: 0,
                      maximum_interval: 0,
                      email_limit_scope: "",
                    },
              };
            } else {
              return email;
            }
          })
        );
        setEditModal(null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOnConfirmForDeleteModal = () => {
    alert(deleteModal);
    setDeleteModal(null);
  };

  const handleDisconnectOrDelete = () => {
    setConfirmDisconnectOrReconnect(null);
  };

  const renderContent = () => {
    if (userEmails && userEmails[0] && !isLoading) {
      return (
        <>
          <CustomScrollbar
            sx={{ height: "calc(100vh - 336px)", paddingRight: "5px" }}
          >
            <Table variant={"bordered"} sx={{}}>
              <TableHead
                sx={{
                  zIndex: 3,
                  position: "sticky",
                  top: -1,
                }}
              >
                <TableRow>
                  <TableCell>
                    <b>Email Accounts</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Status</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Running Campaigns</b>
                  </TableCell>
                  <TableCell align="center">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={"center"}
                      spacing={1}
                    >
                      <b>Sending Limit</b>
                      <InfoCircleIcon sx={{ fontSize: 14 }} />
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {userEmails?.map((email, index) => (
                  <EachEmailAccount
                    key={index}
                    data={email}
                    setEditModal={setEditModal}
                    setFormData={setFormData}
                    setDeleteModal={setDeleteModal}
                    onDisconnectOrReconnect={setConfirmDisconnectOrReconnect}
                    setIsSendingLimitEmpty={setIsSendingLimitEmpty}
                  />
                ))}
              </TableBody>
            </Table>
          </CustomScrollbar>

          {confirmDisconnectOrReconnect?.type === "disconnect" && (
            <PopupAlert
              title={" "}
              description={`Do you want to disconnect this email account?`}
              alertIcon={<CloseBorderedIcon sx={{ fontSize: 60 }} />}
              submitText={"Ok"}
              open={Boolean(confirmDisconnectOrReconnect)}
              onSubmit={() => {
                handleDisconnectOrDelete();
              }}
              onCancel={() => {
                setConfirmDisconnectOrReconnect(null);
              }}
            />
          )}

          {confirmDisconnectOrReconnect?.type === "reconnect" && (
            <PopupAlert
              title={" "}
              description={`Do you want to reconnect this email account?`}
              alertIcon={
                <RefreshIcon sx={{ fontSize: 60, color: "success.main" }} />
              }
              submitText={"Ok"}
              open={Boolean(confirmDisconnectOrReconnect)}
              onSubmit={() => {
                handleDisconnectOrDelete();
              }}
              onCancel={() => {
                setConfirmDisconnectOrReconnect(null);
              }}
            />
          )}

          {/* <TablePagination
            component="div"
            count={100}
            page={1}
            onPageChange={() => {}}
            rowsPerPage={10}
            onRowsPerPageChange={() => {}}
            sx={{
              "& .MuiButtonBase-root": {
                "&:focus": { bgcolor: "transparent" },
              },
            }}
          /> */}
        </>
      );
    } else if (isLoading) {
      return <CampaignsBodySkeleton />;
    } else if (!isLoading) {
      return <EmptyComponent description="No data found" />;
    }
  };

  const getNylasEmail = () => {
    try {
      if (
        !isUserEmailProviderLoading &&
        isUserEmailProviderSuccess &&
        userEmailProvider
      ) {
        let nylasEmailProviderInfo = userEmailProvider.nylasEmailProviderInfo;

        return nylasEmailProviderInfo?.email || "";
      }
    } catch (err) {
      console.log(err);
    }
    return "";
  };

  return (
    <ModalWrapper disableEnforceFocus width={"98%"} open={open}>
      <>
        <Stack
          sx={{ background: theme.palette.other.outlinedBorder }}
          spacing={2.5}
          p={2}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Button
              variant={"tonal"}
              size={"large"}
              color={"inherit"}
              startIcon={<ArrowLeftIcon />}
              onClick={onClose}
              sx={{
                ":focus": { bgcolor: "transparent" },
              }}
            >
              <b>Email Accounts</b>
            </Button>

            <Button
              startIcon={<AddIcon />}
              sx={{
                ":focus": { bgcolor: "primary.main" },
              }}
              onClick={() => {
                setAddEmailModal(true);
              }}
            >
              Add Email Account
            </Button>
          </Stack>

          <Stack alignItems="center" className="contentWrapper">
            <Stack sx={{ width: 1200, pt: 4 }}>{renderContent()}</Stack>
          </Stack>
        </Stack>

        {!!editModal && (
          <Modal
            width={600}
            open={!!editModal}
            onClose={() => setEditModal(null)}
          >
            <>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                  borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`,
                  p: 2,
                }}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Typography variant="body2" fontWeight={700}>
                    Account:
                  </Typography>

                  <Typography variant="body2">{editModal?.email}</Typography>
                </Stack>

                <IconButton
                  size="small"
                  sx={{ "&:focus": { bgcolor: "transparent" } }}
                  onClick={() => setEditModal(null)}
                >
                  <CloseCircleIcon />
                </IconButton>
              </Stack>

              <Stack sx={{ p: 2 }} spacing={2}>
                <Typography variant="h6" fontWeight={700}>
                  SENDING LIMITS
                </Typography>

                <Typography
                  variant="body2"
                  component={"div"}
                  lineHeight={"27px"}
                >
                  I want this mailbox to send no more than
                  <CustomTextField
                    value={formData?.limit}
                    size="small"
                    name="limit"
                    type="number"
                    onChange={handleFormChange}
                    sx={{
                      mx: 1,
                      width: 60,
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        paddingBlock: "4px !important",
                      },
                    }}
                  />
                  emails a day at random times, between every
                  <CustomTextField
                    value={formData?.minimum_interval}
                    size="small"
                    type="number"
                    name="minimum_interval"
                    onChange={handleFormChange}
                    disabled={!formData?.limit}
                    error={!!minIntervalErrorMessage}
                    sx={{
                      mx: 1,
                      width: 55,
                      "& .MuiInputBase-input": {
                        paddingBlock: "4px !important",
                        textAlign: "center",
                      },
                    }}
                  />
                  and
                  <CustomTextField
                    value={formData?.maximum_interval}
                    size="small"
                    type="number"
                    name="maximum_interval"
                    onChange={handleFormChange}
                    disabled={!formData?.limit}
                    sx={{
                      mx: 1,
                      width: 60,
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        paddingBlock: "4px !important",
                      },
                    }}
                  />
                  seconds <b>(up to {Math.round(perHour)} emails per hour).</b> The
                  number of emails sent will also depend on campaign settings.
                </Typography>

                {errorMessage && (
                  <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                    {errorMessage}
                  </Typography>
                )}
                {minIntervalErrorMessage && (
                  <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                    {minIntervalErrorMessage}
                  </Typography>
                )}
                {limitWarningMessage && (
                  <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                    {limitWarningMessage}
                  </Typography>
                )}
              </Stack>

              <Stack p={2}>
                <Typography variant="h6" fontWeight={700}>
                  HOW WOULD YOU LIKE TO APPLY THE SENDING LIMITS?
                </Typography>

                <Typography
                  variant="caption"
                  color={
                    !!emailScopeErrorMessage ? "error.main" : "text.secondary"
                  }
                  mb={1}
                >
                  (Select an option)
                </Typography>

                <RadioGroup
                  name="email_limit_scope"
                  value={formData?.email_limit_scope || ""}
                  onChange={handleFormChange}
                  color="primary"
                  className="limitScopeRadioGroup"
                >
                  <FormControlLabel
                    value="BULK_EMAIL"
                    control={
                      <CustomRadio
                        disabled={!formData?.limit}
                        color="primary"
                      />
                    }
                    label="Apply limits to bulk email campaigns only"
                    sx={{ color: "text.primary" }}
                  />
                  <FormControlLabel
                    value="ALL"
                    control={
                      <CustomRadio
                        disabled={!formData?.limit}
                        color="primary"
                      />
                    }
                    label="Apply limits system-wide"
                    sx={{ color: "text.primary" }}
                  />
                </RadioGroup>

                {emailScopeErrorMessage && (
                  <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                    {emailScopeErrorMessage}
                  </Typography>
                )}
              </Stack>

              <Divider light />
              <Stack
                direction={"row"}
                justifyContent={"end"}
                spacing={1}
                paddingX={3}
                paddingY={2}
              >
                <Button
                  variant={"tonal"}
                  color={"inherit"}
                  onClick={() => setEditModal(null)}
                  size="large"
                  sx={{ "&:focus": { bgcolor: "transparent" } }}
                >
                  Cancel
                </Button>

                <Button
                  variant={"contained"}
                  size="large"
                  onClick={handleSubmit}
                  sx={{ "&:focus": { bgcolor: "primary.main" } }}
                >
                  Save
                </Button>
              </Stack>
            </>
          </Modal>
        )}

        <DeleteModal
          open={!!deleteModal}
          onClose={() => setDeleteModal(null)}
          title="Are you sure, You want to delete this email?"
          warningContent="You are able to leave this page without saving. All changes will be lost."
          onConfirm={handleOnConfirmForDeleteModal}
          sx={{
            "& .MuiButton-tonalInherit:focus": {
              bgcolor: "other.outlinedBorder",
            },
            "& .MuiButton-colorError:focus": { bgcolor: "error.main" },
          }}
        />

        {addEmailModal && (
          <EmailConnectPopup
            open={addEmailModal}
            email={getNylasEmail()}
            isCloseable={true}
            onClose={() => {
              setAddEmailModal(false);
            }}
            state={"bulk-email-setting"}
          />
        )}
      </>
    </ModalWrapper>
  );
};

export default CampaignHeaderSettingsModal;
