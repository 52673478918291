import React, {ChangeEvent, Fragment, useState} from "react";
import {Button, Paper, Stack, Typography, useTheme} from "convertupleads-theme";
import {CustomTextField} from "../../../../../BulkEmailStyle.tsx";
import {useImportSpreadsheetContext} from "../../importSpreadsheetReducer.tsx";
import emailCampaignService from "../../../../../../../services/emailCampaign.service.ts";
import {useSelector} from "react-redux";
import {emailCampaignState} from "../../../../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import {isValidEmail, showNotification} from "../../../../../../../helpers/util/functions.tsx";
import Loading from "../reconnection/Loading.tsx";

const Connection: React.FC= () => {
    const theme = useTheme();
    const { selectedCampaign } = useSelector(emailCampaignState);
    const [submitting, setSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        email,
        setEmail,
        handleGoNextStep,
    } = useImportSpreadsheetContext();

    const handleGoogleAuthUrl = async () => {
        if (!email){
            showNotification("error", "No email address. Please provide one");
            return;
        }

        if (email && !isValidEmail(email)){
            showNotification("error", "Invalid email address. Please provide valid one");
            return;
        }
        setSubmitting(true);
        try{
            let state = selectedCampaign?.uid;
            const resp = await emailCampaignService.googleAuthUrl({ email: email, state: state });

            if (resp?.success && resp?.data){
                if (resp?.data?.permission === false){
                    setIsLoading(true);
                    showNotification("success", "Please wait while redirecting...");
                    setTimeout(()=>{
                        window.open(resp.data.url, "_self");
                    },2000);
                }else if (resp?.data?.permission === true){
                    handleGoNextStep();
                }
            }
        }catch (err){
            console.log(err);
            showNotification("error", "Something went wrong!");
            setIsLoading(false);
        }finally {
            setSubmitting(false);
        }
    }

    return(
        <Stack spacing={4}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant={'h5'} color={'text.primary'}>
                    Connect
                </Typography>
                <Stack direction={'row'} spacing={1.5}>
                    <Button disabled={submitting} onClick={handleGoogleAuthUrl}>
                        Next
                    </Button>
                </Stack>
            </Stack>

            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    width: "100%",
                    height: "200px",
                    borderRadius: "12px"
                }}
            >
                {
                    isLoading ?
                        <Loading loaderText={"Please wait while redirecting..."}/> :
                        <Fragment>
                            <Stack direction={"row"} justifyContent={"center"} px={3} py={2}>
                                <Stack direction={"column"} alignItems={"center"} width={"100%"}>
                                    <Stack direction={"column"} alignItems={"center"} mt={2} width={"100%"}>
                                        <Typography variant={"p"} mt={1} mb={1} textAlign={"center"}>Provide Your Google Email Address</Typography>
                                        <CustomTextField placeholder={"ex. example@gmail.com"} fullWidth onChange={(event: ChangeEvent)=>{ setEmail(event.target.value); }} value={email}/>
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack px={3} py={2}>
                                <Typography variant="subtitle2">
                                    <b>CRMConnection</b> use and transfer to any other app of information received from Google APIs will adhere to <br/> <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.
                                </Typography>
                            </Stack>
                        </Fragment>
                }
            </Paper>
        </Stack>
    );
};

export default Connection;