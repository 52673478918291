import {
    ArrowDropDownFilledIcon,
    Divider,
    FormControl,
    Pagination,
    Select,
    Stack,
    styled,
    Typography
} from 'convertupleads-theme';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailCampaigns } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { SelectChangeEvent } from 'convertupleads-theme';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { CustomMenuItem } from '../../BulkEmailStyle';

const StyledPagination = styled(Pagination)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    width: 'unset',
    boxShadow: 'none'
}));

const CampaignPagination = () => {
    const dispatch = useDispatch();
    const { pagination, totalCount } = useSelector(emailCampaignState);

    const handleChangePage = (_event: React.ChangeEvent<unknown>, newPage: number) => {
        dispatch(getEmailCampaigns({ ...pagination, page: newPage }));
    };

    const handleChangeRowsPerPage = (event: SelectChangeEvent<unknown>) => {
        const perPage = event.target.value as number;
        dispatch(getEmailCampaigns({ ...pagination, perPage }));
    };

    const MenuProps = {
        PaperProps: {
            style: {
                paddingLeft: '8px',

                '& .MuiMenuItem-root': {
                    color: 'gold !important'
                }
            },
        },
    };

    

    return (
        <>
            <Divider light sx={{ pt: '8px' }} />
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={1}>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <Typography variant='body2' color={'text.secondary'}>
                        Rows Per Page:
                    </Typography>
                    <FormControl>
                        <Select
                            value={pagination.perPage}
                            width={86}
                            onChange={handleChangeRowsPerPage}
                            IconComponent={ArrowDropDownFilledIcon}
                            MenuProps={MenuProps}
                        >
                            <CustomMenuItem color='text.primary' value={10}>
                                10
                            </CustomMenuItem>
                            <CustomMenuItem color='text.primary' value={20}>20</CustomMenuItem>
                            <CustomMenuItem color='text.primary' value={30}>30</CustomMenuItem>
                            <CustomMenuItem color='text.primary' value={40}>40</CustomMenuItem>
                            <CustomMenuItem color='text.primary' value={50}>50</CustomMenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <StyledPagination
                    count={Math.ceil((totalCount as number) / pagination.perPage)}
                    page={pagination.page}
                    onChange={handleChangePage}
                    variant={'outlined'}
                    shape={'rounded'}
                />
            </Stack>
        </>
    );
};

export default CampaignPagination;
