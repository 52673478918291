import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    Button,
    ChevronUpDownIcon,
    Divider,
    IconButton,
    ModalWithHeader,
    Stack,
    TextField,
    Typography,
    useTheme,
} from 'convertupleads-theme';

import img from '../../../../assets/images/Frame 11834.png';

interface IEditPersonalizedVideoModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}
const inputStyles = {
    border: 'none',
    background: 'transparent',
    height: 40,
    cursor: 'pointer',
};

const EditPersonalizedVideoModal: React.FC<IEditPersonalizedVideoModalProps> = ({ open, setOpen }) => {
    const [textValue, setTextValue] = useState('');
    const [textColor, setTextColor] = useState('#ffffff');
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [widthValue, setWidthValue] = useState('');
    const [heightValue, setHeightValue] = useState('');

    const handleClose = () => {
        setOpen(false);
    };
   
    const handleInputChange = (setStateFunction: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateFunction(e.target.value);
    };


    const handleSubmitTemplete = () => {
        console.log('Text:', textValue);
        console.log('Text Color:', textColor);
        console.log('Background Color:', backgroundColor);
        console.log('Width(px):', widthValue);
        console.log('Height(px):', heightValue);
    };

    const theme = useTheme();
    return (
        <ModalWithHeader open={open} onClose={handleClose} title='Updated Custom Image'>
            <>
                <Stack direction={'row'}>
                    <Stack sx={{ background: 'white' }} width={605} paddingLeft={3} borderRadius={1}>
                        <Stack direction={'row'} spacing={1} paddingY={1}>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography color={'text.secondary'}>Normal</Typography>
                                <IconButton>
                                    <ChevronUpDownIcon />
                                </IconButton>
                            </Stack>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography color={'text.secondary'}>Seilac Light</Typography>
                                <IconButton>
                                    <ChevronUpDownIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
                        <img width={'100%'} height={300} src={img} alt='Custom Image'></img>
                    </Stack>
                    <Stack width={300} sx={{ background: theme.palette.other.divider }}>
                        <Typography variant={'subtitle2'} fontWeight={700} color={'text.primary'} padding={2}>
                            Templetes
                        </Typography>
                        <Divider />
                        <Stack padding={2}>
                            <Typography variant={'body2'} color={'text.secondary'} mb={1}>
                                Text
                            </Typography>
                            <TextField
                                multiline
                                value={textValue}
                                onChange={handleInputChange(setTextValue)}
                                sx={{
                                    input: {
                                        borderRadius: '6px',
                                    },
                                    '& .MuiOutlinedInput-root ': {
                                        background: theme.palette.other.outlinedBorder,
                                    },
                                }}
                            ></TextField>
                        </Stack>
                        <Stack direction={'row'}>
                            <Stack paddingX={2} spacing={2} justifyContent={'space-between'}>
                                <Stack>
                                    <Typography variant={'body2'} color={'text.secondary'} mb={1}>
                                        Text Color
                                    </Typography>
                                    <input
                                        type='color'
                                        value={textColor}
                                        onChange={handleInputChange(setTextColor)}
                                        style={inputStyles}
                                    />
                                </Stack>
                                <Stack>
                                    <Typography variant={'body2'} color={'text.secondary'} mb={1}>
                                        Width(px)
                                    </Typography>
                                    <TextField
                                        value={widthValue}
                                        onChange={handleInputChange(setWidthValue)}
                                        sx={{
                                            input: {
                                                background: theme.palette.other.divider,
                                                borderRadius: '6px',
                                            },
                                        }}
                                    ></TextField>
                                </Stack>
                            </Stack>
                            <Stack paddingX={2} spacing={2}>
                                <Stack>
                                    <Typography variant={'body2'} color={'text.secondary'} mb={1}>
                                        Background Color
                                    </Typography>

                                    <input
                                        type='color'
                                        value={backgroundColor}
                                        onChange={handleInputChange(setBackgroundColor)}
                                        style={inputStyles}
                                    />
                                </Stack>
                                <Stack>
                                    <Typography variant={'body2'} color={'text.secondary'} mb={1}>
                                        Height(px)
                                    </Typography>
                                    <TextField
                                        value={heightValue}
                                        onChange={handleInputChange(setHeightValue)}
                                        sx={{
                                            input: {
                                                background: theme.palette.other.divider,
                                                borderRadius: '6px',
                                            },
                                        }}
                                    ></TextField>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={'row'} justifyContent={'end'} spacing={1} paddingX={3} paddingY={2}>
                    <Button
                        variant={'contained'}
                        onClick={handleClose}
                        sx={{
                            color: 'gray',
                            background: theme.palette.other.divider,
                            '&:hover': {
                                background: theme.palette.other.divider,
                            },
                        }}
                        size='large'
                    >
                        Cancel
                    </Button>

                    <Button variant={'contained'} size='large' onClick={handleSubmitTemplete}>
                        Use This Image
                    </Button>
                </Stack>
            </>
        </ModalWithHeader>
    );
};

export default EditPersonalizedVideoModal;
