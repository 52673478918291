import React from "react";
import {
    Box,
    CircularProgress,
    Typography
} from "convertupleads-theme";

interface IProps {
    loaderText: string | null;
}

const Loading: React.FC<IProps> = ({ loaderText=null }) => {
    return (
        <Box sx={{
            height: "336px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <CircularProgress />
            {
                loaderText &&
                <Typography
                    sx={{ marginTop: "100px" }}
                    component={"p"}
                >
                    {loaderText}
                </Typography>
            }
        </Box>
    );
}

export default Loading;