import React, {useEffect, useState} from 'react';
import {
    IconButton,
    Paper,
    Stack,
    Typography,
    ChevronDownIcon,
    ChevronUpIcon,
    Select,
    MenuItem,
    FormControl,
    Chip,
    Divider,
    Collapse,
    Tooltip, CustomSwitch, SelectChangeEvent,
} from 'convertupleads-theme';
import {CustomMenuItem, CustomTextField} from "../../BulkEmailStyle.tsx";
import {CAMPAIGN_TRIGGER_SETTINGS} from "../../../../helpers/constant/coreConstants.ts";
import {useSelector} from "react-redux";
import {emailCampaignState} from "../../../../state/features/emailCampaign/emailCampaignSelector.ts";

interface SwitchEmailSenderProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    open: boolean;
    handleToggle: () => void;
    getSettingsDetails: (setting: string, key: string|null) => any | null;
    handleChangeSelect: (data: any[]) => void;
}

const SwitchEmailSender: React.FC<SwitchEmailSenderProps> = ({ icon, title, description, open, handleToggle, getSettingsDetails, handleChangeSelect }) => {
    const { connectedEmails } = useSelector(emailCampaignState);
    const [campaignEmails, setCampaignEmails] = useState([]);

    useEffect(() => {
        if (getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.SWITCH_EMAIL_SENEDER_SETTINGS, null)){
            setCampaignEmails(getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.SWITCH_EMAIL_SENEDER_SETTINGS, null));
        }
    }, [getSettingsDetails(CAMPAIGN_TRIGGER_SETTINGS.SWITCH_EMAIL_SENEDER_SETTINGS, null)]);

    const getExpectThisEmail = (email: string) => {
        return connectedEmails?.campaignConnectedEmails?.filter((switchingEmail)=>(switchingEmail.userConnectedEmail !== email)) || [];
    };

    const handleChange = (key: string, value: any, index: number) => {
        const updatedEmails = [...campaignEmails];
        const editedEmail = { ...updatedEmails[index] };
        editedEmail[key] = value;
        updatedEmails[index] = editedEmail;
        handleChangeSelect(updatedEmails);
        setCampaignEmails(updatedEmails);
    };

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: 2.5 }}>
            <Stack direction={'row'} justifyContent={'space-between'} spacing={1} sx={{ cursor: 'pointer' }}>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <IconButton variant={'rounded'} color={'primary'} sx={{ backgroundColor: 'primary.light', m: 2 }}>
                        {icon}
                    </IconButton>
                    <Stack flex={1}>
                        <Typography variant={'body2'} color={'text.primary'}>
                            <b>{title}</b>
                        </Typography>
                        <Typography variant={'caption'} color={'text.secondary'}>
                            {description}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <CustomSwitch
                        color={"primary.main"}
                        checked={open}
                        activeText={"On"}
                        inactiveText={"Off"}
                        onChange={handleToggle}
                    />
                    {open ? (
                        <ChevronUpIcon fontSize='small' color={'text.tertiary'} onClick={handleToggle} />
                    ) : (
                        <ChevronDownIcon fontSize='small' color={'text.tertiary'} onClick={handleToggle} />
                    )}
                </Stack>
            </Stack>

            <Collapse in={open}>
                <Stack spacing={2} pt={2}>
                    <Divider light />
                    {
                        campaignEmails.map((switchingEmail, index)=>(
                            <Stack direction={'row'} alignItems={'center'} mt={2} justifyContent={'center'} spacing={2}>
                                <Tooltip title={switchingEmail.FROM_EMAIL}>
                                    <Chip
                                        sx={{ minWidth: '150px', maxWidth: '150px', justifyContent: 'space-between' }}
                                        label={switchingEmail.FROM_EMAIL}
                                        color='primary'
                                    />
                                </Tooltip>
                                <Typography whiteSpace={'nowrap'} variant={'caption'} color={'text.secondary'}>
                                    More than
                                </Typography>
                                <FormControl sx={{ width: '20%' }}>
                                    <Select size='small' value={switchingEmail.BOUNCE_RATE} onChange={(event)=>{ handleChange('BOUNCE_RATE', event.target.value, index) }} fullWidth IconComponent={ChevronDownIcon}>
                                        <CustomMenuItem value={20}>20%</CustomMenuItem>
                                        <CustomMenuItem value={30}>30%</CustomMenuItem>
                                        <CustomMenuItem value={40}>40%</CustomMenuItem>
                                        <CustomMenuItem value={50}>50%</CustomMenuItem>
                                        <CustomMenuItem value={60}>60%</CustomMenuItem>
                                    </Select>
                                </FormControl>
                                <Typography variant={'caption'} whiteSpace={'nowrap'} color={'text.secondary'}>
                                    switch to
                                </Typography>

                                <Tooltip title={switchingEmail.TO_EMAIL}>
                                    <FormControl sx={{ minWidth: '150px', maxWidth: '150px' }}>
                                        <Select displayEmpty value={switchingEmail.TO_EMAIL} onChange={(event)=>{ handleChange('TO_EMAIL', event.target.value, index) }} fullWidth IconComponent={ChevronDownIcon}>
                                            <CustomMenuItem value={''} disabled>Select Email</CustomMenuItem>
                                            {
                                                getExpectThisEmail(switchingEmail.FROM_EMAIL).map((email)=>(
                                                    <CustomMenuItem value={email.userConnectedEmail}>{email.userConnectedEmail}</CustomMenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Tooltip>

                                <Typography variant={'caption'} whiteSpace={'nowrap'} color={'text.secondary'}>
                                    check limit
                                </Typography>
                                <CustomTextField onChange={(event)=>{ handleChange('BOUNCE_RATE_CHECK_LIMIT', event.target.value, index) }} value={switchingEmail.BOUNCE_RATE_CHECK_LIMIT} id='outlined-basic' size={'small'} placeholder='200' sx={{ width: '20%' }} />
                            </Stack>
                        ))
                    }
                    {/* <Typography variant={'body1'} textAlign={'center'} color={'text.primary'}>
                        This trigger will be executed when the campaign is 25% complete.
                    </Typography> */}
                </Stack>
            </Collapse>
        </Paper>
    );
};

export default SwitchEmailSender;
