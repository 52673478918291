import React from "react";
import {AddIcon, Button, ListIcon, Paper, Stack, Typography, useTheme} from "convertupleads-theme";
import {CONNECTED_FILE_STEPS, SPREADSHEET_IMPORT_STEPS} from "../../../../../../../helpers/constant/coreConstants.ts";
import {useImportSpreadsheetContext} from "../../importSpreadsheetReducer.tsx";

const Complete = () => {
    const theme = useTheme();
    const { changeScreen, goToStep } = useImportSpreadsheetContext();

    return(
        <Stack mt={'68px'}>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    width: "100%",
                    height: "200px",
                    borderRadius: "12px"
                }}
            >
                <Stack direction={"row"} justifyContent={"center"} px={3} py={2}>
                    <Stack direction={"column"} alignItems={"center"} width={"100%"}>
                        <Stack direction={"column"} alignItems={"center"} mt={2} width={"100%"}>
                            <Typography variant={"body1"} mt={1} textAlign={"center"}>Google spreadsheet has been successfully submitted</Typography>
                            <Typography variant={"subtitle2"} mt={1} mb={1} textAlign={"center"}>Please allow some time for processing</Typography>
                        </Stack>
                        <Stack mt={2} width={"100%"} direction={'row'} spacing={2} justifyContent={'center'}>
                            <Button variant={'outlined'} startIcon={<AddIcon/>} onClick={() => { goToStep(SPREADSHEET_IMPORT_STEPS.CONNECT) }}>Import New Spreadsheet</Button>
                            <Button variant={'outlined'} startIcon={<ListIcon/>} onClick={() => { changeScreen(CONNECTED_FILE_STEPS.CONNECTED_FILE_LIST) }}>View Past Imports</Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    );
};

export default Complete;