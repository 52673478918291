import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal, Stack, Typography, useTheme } from 'convertupleads-theme';
import SameDayElement from './SameDayElement';
import LaterDayElement from './LaterDayElement';
import RecuringElement from './RecuringElement';
import { EMAIL_SCHEDULE_TABS, EMAIL_SCHEDULE_TYPE } from '../../../../helpers/constant/coreConstants';
import EmailScheduleTabs from './EmailScheduleTabs';
import { useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector.ts';

interface EmailScheduleModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const CampaignScheduleModal: React.FC<EmailScheduleModalProps> = ({ open, setOpen }) => {
    const theme = useTheme();
    const { selectedSequence } = useSelector(emailCampaignState);
    const [scheduleTab, setScheduleTab] = useState(EMAIL_SCHEDULE_TABS.SAME_DAY);

    useEffect(() => {
        if (selectedSequence) {
            if ((selectedSequence.type === EMAIL_SCHEDULE_TYPE.IMMEDIATELY || selectedSequence.type === EMAIL_SCHEDULE_TYPE.SCHEDULE) && selectedSequence.day === 0) {
                setScheduleTab(EMAIL_SCHEDULE_TABS.SAME_DAY);
            } else if (selectedSequence.type === EMAIL_SCHEDULE_TYPE.SCHEDULE && selectedSequence.day !== 0) {
                setScheduleTab(EMAIL_SCHEDULE_TABS.LATER_DAY);
            } else if (selectedSequence.type === EMAIL_SCHEDULE_TYPE.FIXED_DATE && selectedSequence.day === 0) {
                setScheduleTab(EMAIL_SCHEDULE_TABS.LATER_DAY);
            } else if (selectedSequence.type === EMAIL_SCHEDULE_TYPE.RECURRING) {
                setScheduleTab(EMAIL_SCHEDULE_TABS.RECURRING);
            } else {
                setScheduleTab(EMAIL_SCHEDULE_TABS.SAME_DAY);
            }
        }
    }, [selectedSequence]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <>
                    <Stack borderBottom={`1px solid ${theme.palette.other.outlinedBorder}`}>
                        <Typography variant='body2' fontWeight={700} padding={2}>
                            Email Schedule
                        </Typography>
                    </Stack>

                    <Stack padding={3}>
                        <Typography color={'text.secondary'} variant='body2'>
                            SELECT SCHEDULE
                        </Typography>

                        {/* Tabs */}
                        <EmailScheduleTabs scheduleTab={scheduleTab} setScheduleTab={setScheduleTab} />
                    </Stack>
                    <Stack paddingTop={0}>
                        <Typography color={'text.secondary'} variant='body2' mb={'12px'} paddingX={3}>
                            WHEN TO SEND
                        </Typography>

                        {/* Tabs Content */}
                        {scheduleTab === EMAIL_SCHEDULE_TABS.SAME_DAY && <SameDayElement onClose={handleClose} />}
                        {scheduleTab === EMAIL_SCHEDULE_TABS.LATER_DAY && <LaterDayElement onClose={handleClose} />}
                        {scheduleTab === EMAIL_SCHEDULE_TABS.RECURRING && <RecuringElement onClose={handleClose} />}
                    </Stack>
                </>
            </Modal>
        </div>
    );
};

export default CampaignScheduleModal;
