import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "convertupleads-theme";

import emailCampaignService from "../../../../../services/emailCampaign.service";
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../../state/features/emailCampaign/emailCampaignSelector";
import { CustomScrollbar } from "../../../../common/CustomScrollBarStyle";
import TableSkeleton from "./TableSkeleton";
import EmptyComponentV2 from "../EmptyComponentV2";
import EachContactTableItem from "./EachContactTableItem";
import LeadContactListPagination from "./LeadContactListPagination";
import useDebounce from "../../../../../hooks/useDebounce";
import { ICampaignContact } from "../../../../../interface/contact.interface";
import { getAccountData } from "../../../../../helpers/util/functions";

interface IProps {
  searchContent: string;
  sortContentBy?: string;
  sortType: string;
  onEmptyList: Dispatch<SetStateAction<boolean>>;
  onActiveSection: Dispatch<SetStateAction<string>>
  setIsShownAlready: Dispatch<SetStateAction<boolean>>
  setTotalCount: Dispatch<SetStateAction<number | undefined>>
}

const LeadContactListTable: React.FC<IProps> = ({ searchContent, sortContentBy, sortType, onActiveSection, onEmptyList, setIsShownAlready, setTotalCount }) => {
  const { selectedCampaign } = useSelector(emailCampaignState);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [contacts, setContacts] = useState<ICampaignContact[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [totalContactsCount, setTotalContactsCount] = useState(0);
  const debouncedSearch = useDebounce(searchContent, 500);

  useEffect(() => {
    fetchElasticData();
  }, [perPage, currentPage, debouncedSearch, sortType, sortContentBy]);

  useEffect(() => {
    if (selectedContacts.length === contacts.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedContacts, contacts.length]);


  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(contacts.map((contact) => contact.contactId));
    }
    setAllSelected(!allSelected);
  };
  const handleContactCheckboxChange = (contactId: number) => {
    let updatedContacts: number[] = [];
    if (selectedContacts.includes(contactId)) {
      updatedContacts = selectedContacts.filter(
        (contact) => contact !== contactId
      );
    } else {
      updatedContacts = [...selectedContacts, contactId];
    }
    setSelectedContacts(updatedContacts);
  };

  const fetchElasticData = async () => {
    setIsLoading(true);
    try {
      const res = await emailCampaignService.getCampaignContactListElastic({
        search: debouncedSearch,
        currentPage,
        perPage,
        campaignUid: selectedCampaign?.uid,
        sortType: sortType,
        order: sortContentBy
      });

      if (res && res.success) {
        if (!res.data[0]) {
          onEmptyList(true)
        } else {
          setIsShownAlready(true)
        }

        setContacts(res.data);
        if (currentPage === 1) {
          setTotalContactsCount(res.totalCount);
          setTotalCount(res.totalCount)
        }
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      {isLoading && <TableSkeleton />}
      {(!isLoading && !contacts[0]) && <Stack>
        <EmptyComponentV2 />
      </Stack>
      }
      {(!isLoading && contacts[0]) && (
        <Stack>
          <LeadContactListPagination
            selectedContacts={selectedContacts}
            setSelectedContacts={setSelectedContacts}
            totalContactsCount={totalContactsCount}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
          />

          <TableContainer>
            <CustomScrollbar sx={{ height: `calc(100vh - 380px) !important` }}>
              <Table variant={"bordered"} size="small">
                <TableHead
                  sx={{
                    // background: "#f3f3f3",
                    zIndex: 3,
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <TableRow>
                    <TableCell>
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          size={"small"}
                          checked={allSelected}
                          onClick={handleSelectAll}
                        />
                        Name
                      </Stack>
                    </TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell>Start date</TableCell>
                    <TableCell>Next execution</TableCell>
                    {(getAccountData('userId') === 1 || getAccountData('userId') === 446) && <TableCell>Progress</TableCell>}
                    <TableCell align="center">actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {contacts?.map((item, i) => (
                    <EachContactTableItem
                      key={i as number}
                      item={item}
                      selectedContacts={selectedContacts}
                      handleContactCheckboxChange={handleContactCheckboxChange}
                    />
                  ))}
                </TableBody>
              </Table>
            </CustomScrollbar>
          </TableContainer>
        </Stack>
      )}


    </Stack>
  );
};

export default LeadContactListTable;
