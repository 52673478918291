import { Grid, Skeleton, Stack } from "convertupleads-theme";
import React from "react";

const TableSkeleton = () => {
  const tableSkeleton = (
    <>
      {Array.from({ length: 7 }).map((rowNum, i) => (
        <Stack direction={"row"} spacing={2} p={2}>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
        </Stack>
      ))}
    </>
  );
  return <Stack>{tableSkeleton}</Stack>;
};

export default TableSkeleton;
