import React from 'react';
import {
    IconButton,
    Paper,
    Stack,
    Typography,
    ChevronDownIcon,
    ChevronUpIcon,
    FormControlLabel,
    Collapse,
    CustomSwitch,
    Divider,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent, Button, AddIcon, Checkbox, FormGroup,
} from 'convertupleads-theme';
import { CustomMenuItem } from '../../BulkEmailStyle';

interface AddToTriggerProps {
    open: boolean;
    handleToggle: () => {};
    icon: React.ReactNode;
    title: string;
    description: string;
    selectedValueLabel: string;
    selectedValue: any;
    handleChangeSelectedValue: (event: SelectChangeEvent<unknown>) => void;
    selectFieldItems: any[],
    selectFieldItemLabelKey: any;
    selectFieldItemValueKey: any;
    handleCreateMore?: () => void;
    checkboxFieldLabel?: string;
    checkboxFieldValue?: boolean;
    handleChangeCheckboxField?: (event: boolean) => void;
}

const AddToTrigger: React.FC<AddToTriggerProps> = ({ icon, title, description, open = false, handleToggle, selectedValueLabel, selectedValue, handleChangeSelectedValue, selectFieldItemValueKey, selectFieldItemLabelKey, selectFieldItems, handleCreateMore, checkboxFieldLabel, checkboxFieldValue, handleChangeCheckboxField }) => {

    return (
        <Paper variant={"outlined"} elevation={0} sx={{ p: 2.5 }}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                spacing={1}
                sx={{ cursor: "pointer" }}
            >
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <IconButton
                        variant={"rounded"}
                        color={"primary"}
                        sx={{ backgroundColor: "primary.light", m: 2 }}
                    >
                        {icon}
                    </IconButton>
                    <Stack flex={1}>
                        <Typography variant={"body2"} color={"text.primary"}>
                            <b>{title}</b>
                        </Typography>
                        <Typography variant={"caption"} color={"text.secondary"}>
                            {description}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <FormControlLabel
                        control={
                            <CustomSwitch
                                color={"primary.main"}
                                checked={open}
                                activeText={"On"}
                                inactiveText={"Off"}
                                onChange={handleToggle}
                            />
                        }
                        label=""
                    />
                    {open ? (
                        <ChevronUpIcon
                            fontSize="small"
                            color={"text.tertiary"}
                            onClick={handleToggle}
                        />
                    ) : (
                        <ChevronDownIcon
                            fontSize="small"
                            color={"text.tertiary"}
                            onClick={handleToggle}
                        />
                    )}
                </Stack>
            </Stack>
            <Collapse in={open}>
                <Stack>
                    <Divider light sx={{ marginY: '16px' }} />
                    <Stack direction={'row'} alignItems={'center'} ml={'58px'} spacing={'20px'}>
                        <FormControl fullWidth>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"}>
                                <InputLabel sx={{ fontWeight: 600 }}>{selectedValueLabel}</InputLabel>
                                {
                                    handleCreateMore &&
                                    <Button
                                        variant={"noPadding"}
                                        size={"small"}
                                        startIcon={<AddIcon />}
                                        sx={{ mb: 0.5, "& .MuiButton-startIcon": { mr: 0 }, '&:focus': { background: 'transparent' } }}
                                        onClick={handleCreateMore}
                                    >
                                        Add New
                                    </Button>
                                }
                            </Stack>
                            <Select
                                displayEmpty
                                value={selectedValue || ""}
                                fullWidth IconComponent={ChevronDownIcon}
                                onChange={handleChangeSelectedValue}>
                                <CustomMenuItem value={''}>{selectedValueLabel}</CustomMenuItem>
                                {
                                    selectFieldItems.map((selectFieldItem) => (
                                        <CustomMenuItem value={selectFieldItem[selectFieldItemValueKey]}>{selectFieldItem[selectFieldItemLabelKey]}</CustomMenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Stack>
                    {
                        checkboxFieldLabel && handleChangeCheckboxField &&
                        <Stack direction={'row'} alignItems={'center'} ml={'58px'} spacing={'20px'} mt={1}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            size={'small'}
                                            checked={checkboxFieldValue}
                                            onChange={(_event, checked) => {
                                                handleChangeCheckboxField(checked);
                                            }}
                                        />
                                    }
                                    sx={{ color: 'text.secondary' }}
                                    label={checkboxFieldLabel}
                                />
                            </FormGroup>
                        </Stack>
                    }
                </Stack>
            </Collapse>
        </Paper>
    );
};

export default AddToTrigger;
