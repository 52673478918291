import React, { useState } from "react";
import {
  CampaignIcon,
  Grid,
  ListIcon,
  MarketingIcon,
  PeopleFilledIcon,
  Stack,
  Typography,
  UploadFileFilledIcon,
  useTheme,
} from "convertupleads-theme";
import { GoogleSpreadsheetIcon, PersonAddIcon } from "../../../../assets/Icons.tsx";
import { LEAD_ITEM, STEPS, STEPS_SUB } from "../../../../helpers/constant/coreConstants.ts";
import EachLeadItem from "./EachLeadItem.tsx";
import AddFromContactList from "./addFromContact/index.tsx";
import CampaignLeadContactList from "./campaignLeadContactList/index.tsx";
import SmartList from "./SmartList.tsx";
import BulkEmailContactList from "./BulkEmailContactList.tsx";
import EmailMarketingContactList from "./EmailMarketingContactList.tsx";
import { getAccountData } from "../../../../helpers/util/functions.tsx";
interface IProps {
  goSubStep: (step: number, subStep: number | null) => void;
}

const isSpecificUser = getAccountData("userId") === 1 || getAccountData("userId") === 446;

const LeadsV2: React.FC<IProps> = ({ goSubStep }) => {
  const theme = useTheme();
  const [activeSection, setActiveSection] = React.useState<string>(
    LEAD_ITEM.CAMPAIGN_CONTACT_LIST
  );

  const handleOpen = (section: string) => {
    setActiveSection(section);
  };

  const [isShownAlready, setIsShownAlready] = useState(false);

  const onEmptyListShow = () => {
    if (!isShownAlready) {
      setActiveSection("")
      setIsShownAlready(true)
    }
  }

  return (
    <Stack
      alignItems="center"
      sx={{
        background: theme.palette.common.white,
        height: "calc(100vh - 200px)",
        overflowY: "hidden",
      }}
    >
      {activeSection === "" && (
        <Stack
          sx={{ /* m: 'auto', */ width: { xs: "80%", sm: "75%", md: "70%" } }}
        >
          <Stack pt={5} alignItems="center" mb={4}>
            <Typography variant={"h6"} color={"text.primary"}>
              Choose a method to import your leads
            </Typography>
            <Typography
              variant={"caption"}
              sx={{ fontSize: "13px", letterSpacing: "0.16px" }}
              color={"text.tertiary"}
            >
              {" "}
              You can perform several different imports in the same campaign
            </Typography>
          </Stack>

          <Grid container spacing={2}>

            <Grid item xs={6} lg={4}>
              <EachLeadItem
                icon={
                  <PeopleFilledIcon color="primary" sx={{ fontSize: 30 }} />
                }
                title="Campaign Contact List"
                description="Campaign contacts list"
                handleOpen={() => handleOpen(LEAD_ITEM.CAMPAIGN_CONTACT_LIST)}
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <EachLeadItem
                icon={<PersonAddIcon color="primary" sx={{ fontSize: 30 }} />}
                title="Add From Contact List"
                description="Add contacts from contact list"
                handleOpen={() => handleOpen(LEAD_ITEM.ADD_FROM_CONTACT_LIST)}
                buttonText="Add Leads"
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <EachLeadItem
                icon={
                  <UploadFileFilledIcon color="primary" sx={{ fontSize: 30 }} />
                }
                title="Import CSV File"
                description="Add contacts from contact csv list"
                // handleOpen={() => {
                //   handleOpen(LEAD_ITEM.IMPORT_CSV);
                //   window.open(
                //     `/bulk-email/campaign/import/${selectedCampaign?.uid}`,
                //     "_blank"
                //   );
                // }}
                handleOpen={() => goSubStep(STEPS.STEP_LEADS, STEPS_SUB[STEPS.STEP_LEADS].CSV)}
                buttonText="Import"
              />
            </Grid>
          

            <Grid item xs={6} lg={4}>
              <EachLeadItem
                icon={<GoogleSpreadsheetIcon height={30} width={20}/>}

                title="Import From Google Sheets"
                description="Add contact using google sheets"
                // handleOpen={() => {
                //   handleOpen(LEAD_ITEM.IMPORT_CSV);
                //   window.open(
                //     `/bulk-email/campaign/import/${selectedCampaign?.uid}`,
                //     "_blank"
                //   );
                // }}
                handleOpen={() => goSubStep(STEPS.STEP_LEADS, STEPS_SUB[STEPS.STEP_LEADS].SPREADSHEET)}
                buttonText="Import"
              />
            </Grid>
            {
              isSpecificUser && (
                <Grid item xs={6} lg={4}>
                  <EachLeadItem
                    icon={<ListIcon color="primary" sx={{ fontSize: 30 }} />}
                    title="Add From Smart List"
                    description="Add contacts from smart list"
                    handleOpen={() => handleOpen(LEAD_ITEM.SMART_LIST)}
                    buttonText="Import"
                  />
                </Grid>
              )
            }
            {
              isSpecificUser &&  <Grid item xs={6} lg={4}>
              <EachLeadItem
                  icon={<CampaignIcon color="primary" sx={{ fontSize: 30 }} />}
                  title="Add From Bulk Email campaign"
                  description="Add contacts from bulk email"
                  handleOpen={() =>
                    handleOpen(LEAD_ITEM.BULK_EMAIL_CAMPAIGN_CONTACT_LIST)
                  }
                  buttonText="Import"
                />
              </Grid>
            }
           {
              isSpecificUser && <Grid item xs={6} lg={4}>
               <EachLeadItem
                icon={<MarketingIcon color="primary" sx={{ fontSize: 30 }} />}
                title="Add From Marketing Campaign"
                description="Add contacts from marketing campaign"
                handleOpen={() =>
                  handleOpen(LEAD_ITEM.MARKETING_CAMPAIGN_CONTACT_LIST)
                }
                buttonText="Import"
              />
              </Grid>
            }
           
          
          </Grid>
        </Stack>
      )}

      {activeSection === LEAD_ITEM.CAMPAIGN_CONTACT_LIST && (
        <CampaignLeadContactList
          onActiveSection={setActiveSection}
          goSubStep={goSubStep}
          onEmptyList={onEmptyListShow}
          isShownAlready={isShownAlready}
          setIsShownAlready={setIsShownAlready}
          onBack={setActiveSection}
        />
      )}

      {activeSection === LEAD_ITEM.ADD_FROM_CONTACT_LIST && (
        <AddFromContactList onBack={setActiveSection} />
      )}
      {activeSection === LEAD_ITEM.SMART_LIST && (
        <SmartList onBack={setActiveSection} />
      )}
      {activeSection === LEAD_ITEM.MARKETING_CAMPAIGN_CONTACT_LIST && (
        <EmailMarketingContactList onBack={setActiveSection} />
      )}
      {activeSection === LEAD_ITEM.BULK_EMAIL_CAMPAIGN_CONTACT_LIST && (
        <BulkEmailContactList onBack={setActiveSection} />
      )}

    </Stack>
  );
};

export default LeadsV2;
