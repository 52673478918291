import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, ChevronDownIcon, PersonalizedIcon, Stack, Typography, useTheme } from 'convertupleads-theme';
import { insertPersonalizedTag } from '../../../../helpers/util/functions';
import useDebounce from '../../../../hooks/useDebounce.ts';
import { CustomTextField } from "../../../bulkEmail/BulkEmailStyle.tsx";
import PersonalizedPopover from '../../../common/PersonalizedPopover';

interface Props {
    showError?: boolean;
    onChange: Dispatch<SetStateAction<string>>;
    editSubject?: string;
}

const VariationEmailSubject: React.FC<Props> = ({ showError, onChange, editSubject }) => {
    const theme = useTheme();
    const subjectRef = useRef(null);
    const [openPersonalizedPopover, setOpenPersonalizedPopover] = React.useState<HTMLButtonElement | null>(null);
    const [messageSubject, setMessageSubject] = useState(editSubject || "");
    const debouncedSubject = useDebounce(messageSubject, 500);

    useEffect(() => {
        // if (selectedSequence && debouncedSubject && selectedSequence.messageSubject !== debouncedSubject) {
        //     dispatch(updateCampaignSequence({ messageSubject: debouncedSubject, uid: selectedSequence.uid, index: selectedSequence.index }));
        // }
        onChange(debouncedSubject)
    }, [debouncedSubject]);

    const handleEmailSubjectChange = (text: string) => {
        setMessageSubject(text);
    };

    const handlePersonalizePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenPersonalizedPopover(event.currentTarget);
    };
    const openPopover = Boolean(openPersonalizedPopover);
    const handleClose = () => {
        setOpenPersonalizedPopover(null);
    };

    const errorText = showError && (
        <Typography variant='caption'>
            Variation Subject is Required
        </Typography>
    );

    return (
        <Stack>
            <Stack direction={'row'} alignItems={showError ? 'center' : 'end'} spacing={2} mt={1}>
                <CustomTextField
                    label="Subject"
                    inputRef={subjectRef}
                    value={messageSubject}
                    size={'small'}
                    placeholder={'Write Email Subject'}
                    fullWidth
                    onChange={(e) => handleEmailSubjectChange(e.target.value)}
                    error={showError}
                    helperText={errorText}
                    sx={{ '& .MuiInputBase-input': { paddingBlock: '9px !important' } }}
                />

                <Button
                    onClick={handlePersonalizePopover}
                    sx={{ background: theme.palette.other.divider, ':focus': { bgcolor: theme.palette.other.divider }, mb: '2px !important' }}
                    variant={'tonal'}
                    color={'inherit'}
                    startIcon={<PersonalizedIcon />}
                    endIcon={<ChevronDownIcon />}
                >
                    Personalize
                </Button>
            </Stack>
            <PersonalizedPopover
                open={openPopover}
                id={'simple-popover'}
                onClose={handleClose}
                anchorEl={openPersonalizedPopover}
                onSelect={(value: string | null) => {
                    if (value) {
                        insertPersonalizedTag(value, subjectRef, (value) => {
                            handleEmailSubjectChange(value);
                        });
                    }
                }}
            />
        </Stack>
    );
};

export default VariationEmailSubject;
