import React, { useEffect, useState } from 'react';
import CustomScheduleComponent from './CustomScheduleComponent';
import {
    Divider,
    // ChevronDownIcon,
    // FormControl,
    // MenuItem,
    // Select,
    // SelectChangeEvent,
    Stack,
    Typography,
    styled,
    useTheme,
} from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../state/features/emailCampaign/emailCampaignSelector.ts';
import { ICampaignScheduleDay } from '../../state/features/emailCampaign/emailCampaign.interface.ts';
import { updateCampaignSchedules } from '../../state/features/emailCampaign/emailCampaignSlice.ts';
import { CAMPAIGN_SETTINGS } from '../../helpers/constant/coreConstants.ts';
import { IntervalTimeIcon } from '../../assets/Icons.tsx';
import { CustomTextField } from '../bulkEmail/BulkEmailStyle.tsx';

const StyledTypography = styled(Typography)(({ theme }) => ({
    border: `1px solid transparent`,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '8px',
    width: '100%',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '14px',
    '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
        borderRadius: '4px',
    },
}));

const IntervalTimeScheduleComponent: React.FC = () => {
    const theme = useTheme();
    const [toggleState, setToggleState] = useState(false);
    const dispatch = useDispatch();
    const { selectedCampaign, schedules } = useSelector(emailCampaignState);
    const [customError, setCustomError] = useState({error: false, errorText: ""});
    const [customInterval, setCustomInterval] = useState("50");
    const [isCustom, setIsCustom] = useState(false);
    const INTRVAL_ARRAY = ["30", "60", "90", "Randoms"];

    const handleUpdate = (key: string, value: string | ICampaignScheduleDay[], oldValue: string | ICampaignScheduleDay[]) => {
        if (selectedCampaign) {
            if (key === CAMPAIGN_SETTINGS.INTERVAL_TIME_OPTION) {
                setToggleState(prev => !prev)
            }
            dispatch(updateCampaignSchedules({ campaignUid: selectedCampaign.uid, keyName: key, value: value, oldValue: oldValue }));
        }
    };

    const handleCustom = (value: string) => {
        setCustomInterval(value);
        const tempValue = parseInt(value, 10);
        let errorData = { error: false, errorText: "" };

        if (isNaN(tempValue)) {
            errorData = { error: true, errorText: "Custom interval time is required" };
        } else if (tempValue < 30 || tempValue > 300) {
            errorData = { error: true, errorText: "Custom interval should be between 30 to 300 seconds" };
        } else {
            handleUpdate(CAMPAIGN_SETTINGS.INTERVAL_TIME, value, getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME));
        }

        setCustomError(errorData);
        if(!isCustom) {
            setIsCustom(true);
        }
    }

    const handleNotCustomInterval = (value: string) => {
        if(isCustom) {
            setIsCustom(false);
        }
        handleUpdate(CAMPAIGN_SETTINGS.INTERVAL_TIME, value, getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME));
    }

    const getValue = (key: string): string => {
        if (schedules) {
            return String(schedules[key]);
        }
        return "";
    };

    useEffect(() => {
        if (schedules && schedules[CAMPAIGN_SETTINGS.INTERVAL_TIME_OPTION]) {
            setToggleState(schedules[CAMPAIGN_SETTINGS.INTERVAL_TIME_OPTION] === "ON");
        }

        if( ! INTRVAL_ARRAY.includes(getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME)) ) {
            setCustomInterval(getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME)) 
            setIsCustom(true);
        }
    }, [schedules]);

    return (
        <CustomScheduleComponent
            title={'Interval Time Between Sending Emails'}
            subtitle={'To reduce the likelihood of being marked as spam by email providers it is recommended to space out your emails rather than sending all at once. Select a sending interval below.'}
            icon={<IntervalTimeIcon />}
            state={toggleState}
            onToggle={() => {
                handleUpdate(CAMPAIGN_SETTINGS.INTERVAL_TIME_OPTION, toggleState ? "OFF" : "ON", toggleState ? "ON" : "OFF");
            }}
        >
            <Divider light sx={{ marginY: '12px' }} />

            <Stack
                ml={7}
                my={2}
                direction={'row'}
                borderRadius={1}
                sx={{
                    alignItems: "center",
                    background: theme.palette.other.outlinedBorder,
                    border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
                }}
            >
                <StyledTypography
                    onClick={() => {
                        handleNotCustomInterval("30");
                    }}
                    sx={{
                        border: getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME) === '30' ? `1px solid ${theme.palette.primary.main}` : '',
                        background: getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME) === '30' ? theme.palette.primary.light : '',
                        padding: "18px 8px"
                    }}
                    color={'text.secondary'}
                >
                    30 seconds
                </StyledTypography>
                <StyledTypography
                    onClick={() => {
                        handleNotCustomInterval("60");
                    }}
                    sx={{
                        border: getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME) === '60' ? `1px solid ${theme.palette.primary.main}` : '',
                        background: getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME) === '60' ? theme.palette.primary.light : '',
                        padding: "18px 8px"
                    }}
                    color={'text.secondary'}
                >
                    60 seconds
                </StyledTypography>
                <StyledTypography
                    onClick={() => {
                        handleNotCustomInterval("90");
                    }}
                    sx={{
                        border: getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME) === '90' ? `1px solid ${theme.palette.primary.main}` : '',
                        background: getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME) === '90' ? theme.palette.primary.light : '',
                        padding: "18px 8px"
                    }}
                    color={'text.secondary'}
                >
                    90 seconds
                </StyledTypography>
                <StyledTypography
                    onClick={() => {
                        handleNotCustomInterval("Randoms");
                    }}
                    sx={{
                        border: getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME) === 'Randoms' ? `1px solid ${theme.palette.primary.main}` : '',
                        background: getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME) === 'Randoms' ? theme.palette.primary.light : '',
                    }}
                    color={'text.secondary'}
                >
                    Random <br />
                    <small>(30 to 300 seconds)</small>
                </StyledTypography>
                <StyledTypography
                    onClick={() => {
                        handleCustom("50");
                    }}
                    sx={{
                        border: ! INTRVAL_ARRAY.includes(getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME)) ? `1px solid ${theme.palette.primary.main}` : '',
                        background: ! INTRVAL_ARRAY.includes(getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME)) ? theme.palette.primary.light : '',
                        padding: "18px 8px"
                    }}
                    color={'text.secondary'}
                >
                    Custom
                </StyledTypography>
            </Stack>

            { (! INTRVAL_ARRAY.includes(getValue(CAMPAIGN_SETTINGS.INTERVAL_TIME)) || isCustom )&&
            <Stack ml={'auto'} width={340}>
                <CustomTextField
                    fullWidth
                    label={'Input Custom Interval (in seconds)'}
                    type={'number'}
                    error={customError.error}
                    helperText={customError.errorText}
                    value={customInterval}
                    onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                        handleCustom(_e.target.value);
                    }}
                    
                />
            </Stack>
            }
            {/*<Typography variant='body2' color={'text.secondary'} ml={7}>*/}
            {/*    Specify your random time duration*/}
            {/*</Typography>*/}
            {/*<Stack ml={7} direction={'row'} spacing={2} width={600} alignItems={'center'} mt={2}>*/}
            {/*    <Typography variant='body2' color={'text.secondary'}>*/}
            {/*        Between*/}
            {/*    </Typography>*/}
            {/*    <FormControl>*/}
            {/*        <Select*/}
            {/*            value={''}*/}
            {/*            size='small'*/}
            {/*            onChange={()=>{*/}

            {/*            }}*/}
            {/*            fullWidth*/}
            {/*            IconComponent={ChevronDownIcon}*/}
            {/*            width={'150px'}*/}
            {/*        >*/}
            {/*            <MenuItem value={10}>10</MenuItem>*/}
            {/*            <MenuItem value={20}>20</MenuItem>*/}
            {/*            <MenuItem value={30}>30</MenuItem>*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}
            {/*    <Typography whiteSpace={'nowrap'} variant='body2' color={'text.secondary'}>*/}
            {/*        sec to*/}
            {/*    </Typography>*/}
            {/*    <FormControl>*/}
            {/*        <Select*/}
            {/*            onChange={()=>{*/}

            {/*            }}*/}
            {/*            value={''}*/}
            {/*            size='small'*/}
            {/*            fullWidth*/}
            {/*            IconComponent={ChevronDownIcon}*/}
            {/*            width={150}*/}
            {/*        >*/}
            {/*            <MenuItem value={10}>10</MenuItem>*/}
            {/*            <MenuItem value={20}>20</MenuItem>*/}
            {/*            <MenuItem value={30}>30</MenuItem>*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}
            {/*</Stack>*/}
        </CustomScheduleComponent>
    );
};

export default IntervalTimeScheduleComponent;
