/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Stack } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import CampaignSearch from './campaignSearch/CampaignSearch';
import useDebounce from '../../../../hooks/useDebounce';
import { updateSearchFieldValue } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { campaignFolderState } from '../../../../state/features/campaignFolder/campaignFolderSelector';
import SelectedCampaignActions from './SelectedCampaignActions.tsx';

const CampaignFilter: React.FC = () => {
    const [search, setSearch] = useState('');
    const { selectedFolder } = useSelector(campaignFolderState);

    const { selectedCampaigns, query } = useSelector(emailCampaignState);
    const dispatch = useDispatch();

    const debouncedSearch = useDebounce(search, 500);

    let campaignStatus: null | object = null;

    switch (selectedFolder) {
        case 'allCampaigns':
            campaignStatus = null;
            break;
        case 'favourite':
            campaignStatus = { isFavourite: 'YES' };
            break;
        case 'archive':
            campaignStatus = { status: 'ARCHIVE' };
            break;
        case 'trash':
            campaignStatus = { status: 'TRASH' };
            break;

        default:
            campaignStatus = { campaignFolderUid: selectedFolder };
    }

    useEffect(() => {

        if (debouncedSearch !== '' || query) {

            dispatch(updateSearchFieldValue(debouncedSearch))
        }
        // dispatch(getEmailCampaigns({ page: 1, perPage: 10, query: debouncedSearch, ...campaignStatus }));
    }, [debouncedSearch]);

    const campaignTopActions =
        selectedCampaigns?.length > 0 ? <SelectedCampaignActions /> : <CampaignSearch search={search} setSearchValue={setSearch} />;

    return (
        <Stack flex={1} direction={'row'} spacing={3}>
            {campaignTopActions}
        </Stack>
    );
};

export default CampaignFilter;
