import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, ChevronDownIcon, PersonalizedIcon, Stack, TextField, Typography, useTheme } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { insertPersonalizedTag } from '../../../../helpers/util/functions';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import PersonalizedPopover from '../../../common/PersonalizedPopover';
import useDebounce from '../../../../hooks/useDebounce.ts';
import {
    selectCampaign,
    selectSequence,
    updateCampaignSequence
} from '../../../../state/features/emailCampaign/emailCampaignSlice.ts';
import { CustomTextField } from "../../../bulkEmail/BulkEmailStyle.tsx";
import { EMAIL_THREAD, STEPS } from "../../../../helpers/constant/coreConstants.ts";
import ThreadModal from '../../../common/ThreadModal/ThreadModal.tsx';

interface Props {
    showError: boolean;
    goStep: (step: number) => void;
}

const EmailSubject: React.FC<Props> = ({ showError, goStep }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const subjectRef = useRef(null);
    const { selectedSequence, selectedCampaign } = useSelector(emailCampaignState);
    const [openPersonalizedPopover, setOpenPersonalizedPopover] = React.useState<HTMLButtonElement | null>(null);
    const [messageSubject, setMessageSubject] = useState("");
    const debouncedSubject = useDebounce(messageSubject, 500);
    const [openTheardModal,setOpenTheardModal] = useState(false);

    useEffect(() => {
        if (selectedSequence && selectedCampaign) {
            setMessageSubject(selectedCampaign.emailThreads === EMAIL_THREAD.MULTI_THREAD ? selectedSequence.messageSubject || "" : selectedCampaign.commonEmailSubject || "");
        }
    }, [selectedSequence.messageSubject, selectedCampaign.commonEmailSubject]);

    useEffect(() => {
        if (selectedSequence && debouncedSubject && selectedSequence.messageSubject !== debouncedSubject) {

            dispatch(updateCampaignSequence({ messageSubject: debouncedSubject, uid: selectedSequence.uid, index: selectedSequence.index,sequenceStats:selectedSequence.sequenceStats }));

            // if (selectedSequence.messageBody && selectedSequence.messageBody !== '') {
            //     dispatch(updateCampaignSequence({ messageBody: selectedSequence.messageBody, messageSubject: debouncedSubject, uid: selectedSequence.uid, index: selectedSequence.index }));
            // } else {
            //     if (selectedCampaign && selectedCampaign.emailThreads === EMAIL_THREAD.SINGLE_THREAD) {
            //         dispatch(selectCampaign({ ...selectedCampaign, ...{ commonEmailSubject: debouncedSubject } }));
            //     }
            //     dispatch(selectSequence({ ...selectedSequence, ...{ messageSubject: debouncedSubject } }))
            // }
        }
    }, [debouncedSubject]);

    const handleEmailSubjectChange = (text: string) => {

        text = text.replace('Re: ', '');
        text = text.replace('Re:', '');
        setMessageSubject(text);
    };

    const handlePersonalizePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenPersonalizedPopover(event.currentTarget);
    };
    const openPopover = Boolean(openPersonalizedPopover);
    const handleClose = () => {
        setOpenPersonalizedPopover(null);
    };

    const isReadOnly = () => {
        // if (selectedSequence && selectedCampaign && selectedCampaign.emailThreads === EMAIL_THREAD.SINGLE_THREAD) {
        //     return selectedSequence.index !== 0;
        // }
        return false;
    };

    const errorText = messageSubject === "" && (
        <Typography variant='caption'>
            Email Subject is Required
        </Typography>
    );

    return (
        <Stack mt={1}>
            <Stack direction={'row'} gap={1}>
                <Typography color={'text.secondary'} variant={'body2'}>
                    Subject
                </Typography>
                {
                    selectedCampaign?.emailThreads === EMAIL_THREAD.SINGLE_THREAD ? (
                    <Typography fontSize={'small'} fontStyle={'italic'} color={'error.main'} variant={'body2'}>
                        <>** Please note that this campaign has been configured as a single-threaded campaign, allowing only one subject per campaign. To change subject or Thread system, click here  <Typography variant='caption' component={'span'} sx={{ fontWeight: 600, color: theme.palette.primary.main, cursor: "pointer", textDecoration: "underline" }} 
                        onClick={()=>setOpenTheardModal(true)}
                        >{" Settings"}</Typography></>
                    </Typography> ) : 
                    (
                        <Typography fontSize={'small'} fontStyle={'italic'} color={'error.main'} variant={'body2'}>
                            <>** Please note that this campaign has been configured as a Multi-threaded campaign, allowing to create unique subject lines for each email. To change subject or Thread system, click here  <Typography variant='caption' component={'span'} sx={{ fontWeight: 600, color: theme.palette.primary.main, cursor: "pointer", textDecoration: "underline" }} 
                            onClick={()=>setOpenTheardModal(true)}
                            >{" Settings"}</Typography></>
                        </Typography> ) 
                }
                {
                    openTheardModal && <ThreadModal open={openTheardModal} handleClose={()=>setOpenTheardModal(false)}/>
                }
            </Stack>
            <Stack direction={'row'} spacing={2} mt={1}>
                <CustomTextField
                    inputRef={subjectRef}
                    value={(selectedSequence?.index && selectedSequence?.index > 0 && selectedCampaign && selectedCampaign.emailThreads === EMAIL_THREAD.SINGLE_THREAD) ? 'Re: ' + messageSubject : messageSubject}
                    size={'small'}
                    placeholder={'Write Email Subject'}
                    fullWidth
                    onChange={(e) => handleEmailSubjectChange(e.target.value)}
                    error={messageSubject === "" ? true : false}
                    helperText={errorText}
                    sx={{ '& .MuiInputBase-input': { paddingBlock: '9px !important' } }}
                    disabled={isReadOnly()}
                />
                <Button
                    onClick={handlePersonalizePopover}
                    sx={{ background: theme.palette.other.divider, alignSelf: 'baseline', ':focus': { bgcolor: theme.palette.other.divider }, }}
                    variant={'tonal'}
                    color={'inherit'}
                    startIcon={<PersonalizedIcon />}
                    endIcon={<ChevronDownIcon />}
                    disabled={isReadOnly()}
                >
                    Personalize
                </Button>
            </Stack>
            <PersonalizedPopover
                open={openPopover}
                id={'simple-popover'}
                onClose={handleClose}
                anchorEl={openPersonalizedPopover}
                onSelect={(value: string | null) => {
                    if (value) {
                        insertPersonalizedTag(value, subjectRef, (value) => {
                            handleEmailSubjectChange(value);
                        });
                    }
                }}
            />
        </Stack>
    );
};

export default EmailSubject;
