import React, { useState } from 'react';
import { Button, Popover, Stack, Typography, useTheme } from 'convertupleads-theme';
import TemplateLibraryModal from '../templateLibraryModal/TemplateLibraryModal';
import ReWriteWithAiPopover from '../ReWriteWithAiPopover';
import { capitalize, getAccountData } from '../../../../helpers/util/functions';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { useSelector } from 'react-redux';
import CampaignScheduleModal from '../emaliSchedule/CampaignScheduleModal';
interface IEmailBodyHeader {
    toggleDrawer: () => void;
}

const CampaignEmailBodyHeader: React.FC<IEmailBodyHeader> = ({ toggleDrawer }) => {
    const [openCampaignScheduleModal, setCampaignScheduleModal] = useState(false);
    const [openTemplateModal, setOpenTemplateModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { selectedSequence } = useSelector(emailCampaignState);

    const theme = useTheme();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`, pb: 1 }}
            >
                <Stack>
                    <Typography variant='h5' color={'text.primary'}>
                        {selectedSequence && capitalize(selectedSequence.messageType)}
                    </Typography>
                    {/* <Stack direction={'row'} alignItems={'center'}>
                        <Typography color={'text.secondary'} fontSize={14} fontWeight={500}>
                            Schedule:
                        </Typography>

                        <Stack direction={'row'} alignItems={'center'} onClick={handleCampaignScheduleModal} sx={{ cursor: 'pointer' }}>
                            <Typography variant='body2' color={'primary'}>
                                &nbsp; {camapignScheduleText(selectedSequence)}
                            </Typography>
                            <IconButton sx={{':focus': {bgcolor: 'transparent'}}}>
                                <EditIcon sx={{ fontSize: '16px' }} color={'primary'} />
                            </IconButton>
                        </Stack>
                    </Stack> */}
                </Stack>

                <Stack direction={'row'}>

                    <Button variant={'outlined'} size='small' sx={{ ':focus': { bgcolor: 'transparent' } }} onClick={() => setOpenTemplateModal(true)}>
                        Template Library
                    </Button>




                    {/* <Button
                        color='secondary'
                        variant={'outlined'}
                        startIcon={<AIFilledIcon />}
                        size='large'
                        aria-describedby={id}
                        onClick={handleOpenPopover}
                    >
                        Rewrite with AI
                    </Button> */}
                </Stack>
            </Stack>
            <Popover
                id={id}
                open={open}
                sx={{ top: '10px' }}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ReWriteWithAiPopover toggleDrawer={toggleDrawer} handleClose={handleClose} />
            </Popover>
            {openTemplateModal &&
                <TemplateLibraryModal open={openTemplateModal} setOpen={setOpenTemplateModal} />
            }
            {openCampaignScheduleModal &&
                <CampaignScheduleModal open={openCampaignScheduleModal} setOpen={setCampaignScheduleModal} />
            }
        </>
    );
};

export default CampaignEmailBodyHeader;
