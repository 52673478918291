import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Divider, ModalWithHeader, Stack, TextField, Typography } from 'convertupleads-theme';
import { IEmailCampaign } from '../../../../state/features/emailCampaign/emailCampaign.interface';
import { useDispatch } from 'react-redux';
import { updateCampaign } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { CustomTextField } from "../../BulkEmailStyle.tsx";

interface IRenameModalComponentProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onClose: () => void;
    campaign: IEmailCampaign;
}

const RenameModalComponent: React.FC<IRenameModalComponentProps> = ({ open, setOpen, onClose, campaign }) => {
    const { uid, title } = campaign || {};
    const [renamedCampaign, setRenamedCampaign] = useState(title);
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = () => {
        dispatch(updateCampaign({ uid: uid as string, title: renamedCampaign, oldValue: title }));
        onClose();
        handleClose();
    };

    return (
        <ModalWithHeader open={open} onClose={handleClose} title='Edit Campaign Name'>
            <>
                <Stack padding={3}>
                    <Typography color={'text.secondary'} variant='body2'>
                        New Campaign Name
                    </Typography>
                    <CustomTextField
                        defaultValue={renamedCampaign}
                        onChange={(event) => setRenamedCampaign(event.target.value)}
                        sx={{ marginTop: '8px' }}
                    />
                </Stack>
                <Divider />
                <Stack direction={'row'} justifyContent={'end'} spacing={1} paddingX={3} paddingY={2}>
                    <Button variant={'tonal'} color={'inherit'} onClick={handleClose} size='large'>
                        Cancel
                    </Button>

                    <Button variant={'contained'} size='large' onClick={handleSubmit}>
                        Update
                    </Button>
                </Stack>
            </>
        </ModalWithHeader>
    );
};

export default RenameModalComponent;
