import React, { useEffect, useState } from 'react';
import {
    Button,
    Stack,
    Typography,
    Divider,
    FormControl,
    Select,
    FormGroup,
    FormControlLabel,
    Checkbox,
    styled,
    ArrowDropDownFilledIcon,
    Pagination,
    PauseCircleIcon,
    PlayCircleOutlineFilledIcon,
    LoadingButton,
    CircularProgress,
} from 'convertupleads-theme';
import { CustomScrollbar } from '../../../common/CustomScrollBarStyle';
import emailCampaignService from "../../../../services/emailCampaign.service.ts";
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import { MailUnsubscribeIcon } from "../../../../assets/Icons.tsx";
import { IContact } from '../../../../state/features/emailCampaign/emailCampaign.interface.ts';
import CampaignContactItem from './CampaignContactItem.tsx';
import { CONTACT_STATUS } from '../../../../helpers/constant/coreConstants.ts';
import EmptyComponent from '../../emptyComponent/EmptyComponent.tsx';
import { CustomMenuItem } from '../../BulkEmailStyle.tsx';
import CampaignContactElasticItem from './CampaignContactElasticItem.tsx';
import { getAccountData } from '../../../../helpers/util/functions.tsx';

const StyledButton = styled(Button)(({ theme }) => ({
    background: theme.palette.other.outlinedBorder,
    color: theme.palette.text.secondary,
    '&:hover, :focus': {
        background: theme.palette.other.outlinedBorder,
        color: theme.palette.text.secondary,
    },
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    width: 'unset',
    boxShadow: 'none',
    height: 'unset !important',
    lineHeight: 'unset !important'
}));

interface IProps {
    handleClose: () => void;
}


const CampaignContactList: React.FC<IProps> = ({ handleClose }) => {
    const { selectedCampaign } = useSelector(emailCampaignState);
    const [allChecked, setAllChecked] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [totalContacts, setTotalContacts] = useState(0);
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [elasticContacts, setElasticContacts] = useState<IContact[]>([]);
    const [isUnsubscribing, setIsUnsubscribing] = useState(false);
    const [isResuming, setIsResuming] = useState(false);
    const [isPausing, setIsPausing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [submittedResumeIds, setSubmittedResumeIds] = useState<number[]>([]);
    const [submittedPauseIds, setSubmittedPauseIds] = useState<number[]>([]);
    const [submittedUnsubscribeIds, setSubmittedUnsubscribeIds] = useState<number[]>([]);
    const [isRefresh, setIsRefresh] = useState<boolean>(false);

    const ELASTIC_STATUS = process.env.REACT_APP_BULK_EMAIL_CAMPAIGN_CONTACT_LIST_ELASTIC_STATUS === 'TRUE' ? true : false; // true / false

    useEffect(() => {
        ELASTIC_STATUS ? fetchElasticData() : fetchData();
    }, [currentPage, perPage]);

    useEffect(() => {
        if (isRefresh) {
            ELASTIC_STATUS ? fetchElasticData() : fetchData();
        }
    }, [isRefresh]);

    const fetchData = async () => {
        try {
            const res = await emailCampaignService.getCampaignContactList({
                currentPage,
                perPage,
                campaignUid: selectedCampaign?.uid
            });

            if (res && res.success) {
                setContacts(res.data);
                if (currentPage === 1) {
                    setTotalContacts(res.totalCount);
                    setTotalPage(res.totalPage);
                }

            }
        } catch (error) {
            // Handle error
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
            setIsRefresh(false)
        }
    };

    const fetchElasticData = async () => {
        try {
            const res = await emailCampaignService.getCampaignContactListElastic({
                currentPage,
                perPage,
                campaignUid: selectedCampaign?.uid
            });

            if (res && res.success) {
                setElasticContacts(res.data);
                if (currentPage === 1) {
                    setTotalContacts(res.totalCount);
                    setTotalPage(res.totalPage);
                }

            }
        } catch (error) {
            // Handle error
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
            setIsRefresh(false)
        }
    };



    const handleAllChecked = () => {
        if (!ELASTIC_STATUS) {
            const filteredContacts = contacts.filter(
                (contact) => contact.campaignContactStatus !== CONTACT_STATUS.UNSUBSCRIBED
            );

            const selectedContactIds = filteredContacts
                .filter(
                    (contact) =>
                        !submittedPauseIds.includes(contact.contactId as number) &&
                        !submittedResumeIds.includes(contact.contactId as number) &&
                        !submittedUnsubscribeIds.includes(contact.contactId as number)
                )
                .map((contact) => contact.contactId)
                .filter((contactId): contactId is number => contactId !== undefined);

            setAllChecked(!allChecked);
            setSelectedContacts(allChecked ? [] : selectedContactIds);
        } else {
            const filteredContacts = elasticContacts.filter(
                (contact) => contact.status !== CONTACT_STATUS.UNSUBSCRIBED
            );

            const selectedContactIds = filteredContacts
                .filter(
                    (contact) =>
                        !submittedPauseIds.includes(contact.id as number) &&
                        !submittedResumeIds.includes(contact.id as number) &&
                        !submittedUnsubscribeIds.includes(contact.id as number)
                )
                .map((contact) => contact.id)
                .filter((contactId): contactId is number => contactId !== undefined);

            setAllChecked(!allChecked);
            setSelectedContacts(allChecked ? [] : selectedContactIds);
        }
    };

    const handleContactCheckboxChange = (contactId: number) => {
        let updatedContacts: number[];

        if (selectedContacts.includes(contactId)) {
            updatedContacts = selectedContacts.filter((contact) => contact !== contactId);
        } else {
            updatedContacts = [...selectedContacts, contactId];
        }

        setSelectedContacts(updatedContacts);
        setAllChecked(ELASTIC_STATUS ? updatedContacts.length === elasticContacts.length : updatedContacts.length === contacts.length);
    };

    const handleContactStatus = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, status: string) => {
        event.stopPropagation();

        const statusPayload = status === CONTACT_STATUS.PAUSE ? CONTACT_STATUS.PAUSE : CONTACT_STATUS.RUNNING;
        try {
            let resp = await emailCampaignService.pauseOrResumeContact({ contactIds: selectedContacts, campaignUid: selectedCampaign?.uid as string, status: statusPayload as 'RUNNING' | 'PAUSE' });

            if (status === CONTACT_STATUS.PAUSE) {
                setIsPausing(true)
            } else if (status === CONTACT_STATUS.RUNNING) {
                setIsResuming(true)
            }

            if (resp && resp.success) {
                setSelectedContacts([])
                setAllChecked(false);

                if (status === CONTACT_STATUS.PAUSE) {
                    setSubmittedPauseIds(prevState => [...prevState, ...selectedContacts])
                } else if (status === CONTACT_STATUS.RUNNING) {
                    setSubmittedResumeIds(prevState => [...prevState, ...selectedContacts])
                }

                if ((window as any).showNotification) {
                    (window as any).showNotification('success', resp.message);
                }
            } else {
                if ((window as any).showNotification) {
                    (window as any).showNotification('error', resp.message);
                }
            }
        } catch (err) {
            if ((window as any).showNotification) {
                (window as any).showNotification('error', 'Something went wrong! Try again later');
            }
        } finally {
            // setIsUnsubscribing(false);
            setIsPausing(false)
            setIsResuming(false)
        }
    }

    const handleUnsubscribeFromList = async () => {
        try {
            setIsUnsubscribing(true);
            let resp = await emailCampaignService.unsubscribeContact({ contactIds: selectedContacts, campaignUid: selectedCampaign?.uid });

            if (resp && resp.success) {
                setSelectedContacts([])
                setAllChecked(false);
                setSubmittedUnsubscribeIds(prevState => [...prevState, ...selectedContacts])

                if ((window as any).showNotification) {
                    (window as any).showNotification('success', resp.message);
                }
            } else {
                if ((window as any).showNotification) {
                    (window as any).showNotification('error', resp.message);
                }
            }
        } catch (err) {
            if ((window as any).showNotification) {
                (window as any).showNotification('error', 'Something went wrong! Try again later');
            }
        } finally {
            setIsUnsubscribing(false);
        }
    };

    const MenuProps = {
        PaperProps: {
            style: {
                paddingLeft: '8px'
            },
        },
    };

    const handleSubmittedId = (status: string, submittedId: number) => {
        if (status === CONTACT_STATUS.PAUSE) {
            setSubmittedPauseIds(prevState => [...prevState, submittedId])
        } else if (status === CONTACT_STATUS.RUNNING) {
            setSubmittedResumeIds(prevState => [...prevState, submittedId])
        } else if (status === CONTACT_STATUS.UNSUBSCRIBED) {
            setSubmittedUnsubscribeIds(prevState => [...prevState, submittedId])
        }
    }

    const renderContent = () => {
        if (ELASTIC_STATUS && elasticContacts && elasticContacts[0] && !isLoading) {
            return (
                <Stack spacing={1} p={"12px 24px 12px 24px"} height={'calc(100vh - 350px)'}>

                    {elasticContacts.map((contact, index) => (
                        <CampaignContactElasticItem
                            key={index}
                            contact={contact}
                            isChecked={selectedContacts.includes(contact.id as number)}
                            onCheckboxChange={handleContactCheckboxChange}
                            onSubmitId={handleSubmittedId}
                            submittedIds={{
                                submittedResumeIds,
                                submittedPauseIds,
                                submittedUnsubscribeIds
                            }}
                        />
                    ))}
                </Stack>
            );
        } else if (!ELASTIC_STATUS && contacts && contacts[0] && !isLoading) {
            return (
                <Stack spacing={1} p={"12px 24px 12px 24px"} height={'calc(100vh - 350px)'}>
                    {contacts.map((contact, index) => (
                        <CampaignContactItem
                            key={index}
                            contact={contact}
                            isChecked={selectedContacts.includes(contact.contactId as number)}
                            onCheckboxChange={handleContactCheckboxChange}
                            onSubmitId={handleSubmittedId}
                            submittedIds={{
                                submittedResumeIds,
                                submittedPauseIds,
                                submittedUnsubscribeIds
                            }}
                        />
                    ))}
                </Stack>
            )
        }
        else if (isLoading) {
            return (
                <Stack alignItems={"center"} justifyContent={"center"} height={655}>
                    <CircularProgress />
                </Stack>
            );
        } else if (!isLoading) {
            return (
                <Stack alignItems={"center"} justifyContent={"center"} height={655}>
                    <EmptyComponent />
                </Stack>
            )
        }
    };

    return (
        <Stack>
            <Stack height={'100%'} overflow={'hidden'}>
                {(elasticContacts[0] || contacts[0]) &&
                    <Stack p={'12px 24px 0px 43px'} direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox color={'primary'} size={'small'} checked={allChecked} onChange={handleAllChecked} />}
                                    label=''
                                // sx={{ mr: "25px" }}
                                />
                            </FormGroup>
                            <Typography variant={'body2'} color={'text.secondary'}>
                                {totalContacts} contacts
                            </Typography>
                        </Stack>

                        <LoadingButton
                            onClick={() => setIsRefresh(true)}
                            variant="outlined"
                            size='small'
                            loading={isRefresh}
                            position="start"
                            sx={{ '& .MuiButton-startIcon': { height: 20, width: 20 }, '&:focus': { bgcolor: 'primary.main' } }}
                        >
                            Refresh
                        </LoadingButton>
                    </Stack>
                }

                <Stack>
                  
                    <CustomScrollbar>
                        {renderContent()}
                        {/* <Stack spacing={1} p={'6px 24px 12px 24px'} height={616}>
                            {contacts.map((contact, index) => (
                                <CampaignContactItem
                                    key={index}
                                    contact={contact}
                                    isChecked={selectedContacts.includes(contact.contactId)}
                                    onCheckboxChange={handleContactCheckboxChange}
                                />
                            ))}
                        </Stack> */}
                    </CustomScrollbar>
                    {
                        totalPage ?
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={1} px={2}>
                                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                    <Typography variant='body2' color={'text.secondary'}>
                                        Rows Per Page:
                                    </Typography>
                                    <FormControl>
                                        <Select
                                            value={perPage}
                                            width={64}
                                            sx={{ height: "32px" }}
                                            onChange={(event) => {
                                                setPerPage(event.target.value as number);
                                                setCurrentPage(1);
                                            }}
                                            IconComponent={ArrowDropDownFilledIcon}
                                            MenuProps={MenuProps}
                                        >
                                            <CustomMenuItem value={10}>10</CustomMenuItem>
                                            <CustomMenuItem value={20}>20</CustomMenuItem>
                                            <CustomMenuItem value={30}>30</CustomMenuItem>
                                            <CustomMenuItem value={40}>40</CustomMenuItem>
                                            <CustomMenuItem value={50}>50</CustomMenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <StyledPagination
                                    count={totalPage}
                                    page={currentPage}
                                    onChange={(_event: React.ChangeEvent<unknown>, newPage: number) => { setCurrentPage(newPage) }}
                                    variant={'outlined'}
                                    shape={'rounded'}
                                />
                            </Stack>
                            : <Stack sx={{ height: '48px' }}></Stack>
                    }
                </Stack>
            </Stack>

            {
                (elasticContacts[0] || contacts[0]) && (
                    <>
                        <Divider sx={{ mt: '6px' }} variant={'fullWidth'} light />

                        <Stack direction={'row'} spacing={1} p={'16px 20px'} justifyContent={'space-between'}>
                            <StyledButton variant={'tonal'} size={'large'} onClick={handleClose}>
                                Cancel
                            </StyledButton>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography> {selectedContacts.length} selected</Typography>

                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        variant={'contained'}
                                        startIcon={<PauseCircleIcon />}
                                        size={'large'}
                                        sx={{ background: 'error.light', '& .MuiButton-startIcon': { height: 20, width: 20 }, '&:focus': { bgcolor: 'error.light' } }}
                                        color='error'
                                        loading={isPausing}
                                        onClick={(event) => handleContactStatus(event, CONTACT_STATUS.PAUSE)}
                                        disabled={isUnsubscribing || isResuming || isPausing || selectedContacts.length === 0}
                                    >
                                        Pause
                                    </LoadingButton>
                                    <LoadingButton
                                        variant={'contained'}
                                        color='success'
                                        startIcon={<PlayCircleOutlineFilledIcon />}
                                        size={'large'}
                                        // sx={{ ':focus': { bgcolor: 'primary.main' } }}
                                        sx={{ background: 'success.light', '& .MuiButton-startIcon': { height: 20, width: 20 }, '&:focus': { bgcolor: 'success.light' } }}
                                        onClick={(event) => handleContactStatus(event, CONTACT_STATUS.RUNNING)}
                                        loading={isResuming}
                                        disabled={isUnsubscribing || isPausing || isResuming || selectedContacts.length === 0}
                                    >
                                        Resume
                                    </LoadingButton>

                                    <LoadingButton
                                        variant={'contained'}
                                        startIcon={<MailUnsubscribeIcon />}
                                        size={'large'}
                                        sx={{ background: 'primary', color: 'primary', '& .MuiButton-startIcon': { height: 20, width: 20 }, ':focus': { bgcolor: 'primary.main' } }}
                                        onClick={handleUnsubscribeFromList}
                                        loading={isUnsubscribing}
                                        disabled={isUnsubscribing || isPausing || isResuming || selectedContacts.length === 0}
                                    >
                                        Unsubscribe
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </>
                )
            }
        </Stack>
    );
};

export default CampaignContactList;

