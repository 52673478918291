import { ApiCodeIcon, FacebookIcon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popover, UploadFileFilledIcon, useTheme } from 'convertupleads-theme';
import { GoogleSpreadsheetIcon } from '../../../../../assets/Icons';
import { STEPS, STEPS_SUB } from '../../../../../helpers/constant/coreConstants';

type IProps = {
    connectId: string | undefined;
    open: boolean;
    connect: HTMLSpanElement | SVGSVGElement | null;
    handleClose: () => void;
    goSubStep: (step: number, subStep: number | null) => void;
};
const ImportContactPopOver = ({ connectId, open, connect, handleClose,goSubStep }: IProps) => {
    const theme = useTheme();
    return (
        <Popover
            id={connectId}
            open={open}
            anchorEl={connect}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    style: { borderRadius: '12px' },
                },
            }}
            sx={{ mt: 1 }}
        >
            <Paper sx={{ width: 'fit-content', p: 1, minWidth: '211px' }}>
                <List
                    sx={{ bgcolor: theme.palette.grey[50], borderRadius: 1.5 }}
                    component='div'
                    aria-label='main mailbox folders'
                    disablePadding
                    dense
                >
                    <ListItem
                        disablePadding
                        onClick={() => {goSubStep(STEPS.STEP_LEADS, STEPS_SUB[STEPS.STEP_LEADS].CSV);}}
                    >
                        <ListItemButton>
                        <ListItemIcon>
                                <UploadFileFilledIcon color='primary'/>
                            </ListItemIcon>
                            <ListItemText primary="Import CSV File" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem
                        disablePadding
                       onClick={()=> goSubStep(STEPS.STEP_LEADS, STEPS_SUB[STEPS.STEP_LEADS].SPREADSHEET)} 
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <GoogleSpreadsheetIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Import From Google Sheets" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Paper>
        </Popover>
    );
};
export default ImportContactPopOver;
