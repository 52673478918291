import React, { ChangeEvent, useState } from "react";
import {
    Alert,
    Button,
    Divider,
    IconButton,
    Modal,
    Paper,
    Stack,
    Typography,
    useTheme,
} from "convertupleads-theme";
import { ErrorIcon, OpenAiIcon } from "../../../assets/Icons";
import { CustomTextField } from "../../bulkEmail/BulkEmailStyle";
import emailCampaignService from "../../../services/emailCampaign.service.ts";
import {showNotification, urlify} from "../../../helpers/util/functions.tsx";

interface IProps {
    open: boolean;
    isUpdate: boolean;
    onClose: () => void;
    setIsUpdate: (value: boolean) => void;
}

const OpenAIApiKeyPopup: React.FC<IProps> = ({ open, onClose,isUpdate,setIsUpdate }) => {
    const theme = useTheme();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [apiKey, setApiKey] = useState("");
    const [errorMessage,setErrorMessage] =useState('');
    const [errorModalOpen,setErrorModalOpen] = useState(false);

    const handleSubmit = async () => {
        if (apiKey) {
            try {
                setIsSubmitting(true);
                const resp = await emailCampaignService.openAIKeySave({ apiKey: apiKey });
                if (resp?.success){
                    setApiKey("");
                    setIsUpdate(false);
                    onClose();
                    showNotification("success", `${resp.message}`);
                }else {
                    setErrorMessage(resp?.data.message);
                    setErrorModalOpen(true);
                    showNotification("error", resp.message);
                }
            } catch (error) {
                console.error(error);
                showNotification("error", "Failed to save api key");
            }finally {
                setIsSubmitting(false);
            }
        }else {
            showNotification("error", "Api key is required");
        }
    };
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsUpdate(false);
        setApiKey(event.target.value);
      };

    return (
       <>
        <Modal open={open} onClose={()=>{}}>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    width: "600px",
                    borderRadius: "12px",
                }}
            >
                <Stack>
                    <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        padding={2}
                        paddingBottom={0}
                    >
                        <IconButton
                            variant={"outlined"}
                            color={"warning"}
                            sx={{ width: "75px", height: "75px" }}
                        >
                            <OpenAiIcon width={50} height={50} color={"black"} />
                        </IconButton>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"center"} px={3} py={2}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <Typography variant={"h5"} textAlign={"center"}>
                                OpenAI Integration
                            </Typography>
                            <Typography variant={"body1"} mt={1} textAlign={"center"}>
                                Visit <a href="https://platform.openai.com/account/api-keys" target="_blank">platform.openai.com/account/api-keys</a> to get your API Key.
                            </Typography>
                                {
                                    isUpdate && 
                                    <Alert sx={{marginTop:'16px',padding:'0px 8px'}} severity="error">Your OpenAI Api key is Invalid</Alert>
                                }
                            <Stack
                                direction={"column"}
                                alignItems={"center"}
                                mt={2}
                                width={"100%"}
                            >
                                <Typography variant={"body1"} mt={1} mb={1} textAlign={"center"}>
                                    Enter Your OpenAI Api key
                                </Typography>
                                <CustomTextField
                                    fullWidth
                                    onChange={handleChange}
                                    value={apiKey}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack width={"100%"}>
                        <Divider />
                        <Stack
                            padding={2}
                            direction={"row"}
                            justifyContent={"flex-end"}
                            gap={1}
                        >
                            <Button
                                variant={"tonal"}
                                color={"inherit"}
                                size={"large"}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isSubmitting} size={"large"} onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        </Modal>
        {
            errorMessage &&  
            <Modal open={errorModalOpen} onClose={()=>{
                setErrorModalOpen(false);
                setApiKey('');
            }} sx={{
                marginLeft:'100px'
            }}>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    width: "500px",
                    borderRadius: "12px",

                }}
            >
                <Stack>
                    <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        padding={2}
                        paddingBottom={0}
                    >
                        <IconButton
                            variant={"outlined"}
                            color={"warning"}
                            sx={{ width: "75px", height: "75px" }}
                        >
                            {/* <ErrorFilledIcon width={50} height={50} color="red"/> */}
                            <ErrorIcon width={50} height={50} color="red"/>
                        </IconButton>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"center"} px={3} py={2}>
                        <Stack direction={"column"} alignItems={"center"}>
                            <Typography 
                             textAlign={'center'}
                             color={'red'} 
                             variant="body2" 
                             dangerouslySetInnerHTML={{ __html: urlify(errorMessage) }}
                            ></Typography>
                        </Stack>
                    </Stack>
                    <Stack width={"100%"}>
                        <Divider />
                        <Stack
                            padding={2}
                            direction={"row"}
                            justifyContent={"flex-end"}
                            gap={1}
                        >
                            <Button
                                variant={"tonal"}
                                color={"inherit"}
                                size={"large"}
                                onClick={()=>{
                                    setErrorModalOpen(false);
                                    setApiKey('');
                                }}
                            >
                                Close
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        </Modal>

        }
       </>
    );
};

export default OpenAIApiKeyPopup;