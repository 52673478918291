import React, { Fragment, useEffect, useState } from "react";
import {
    Button,
    CircularProgress,
    OpenInNewFilledIcon,
    RefreshIcon,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "convertupleads-theme";
import ConnectedFileListPagination from "./ConnectedFileListPagination";
import emailCampaignService from "../../../../../services/emailCampaign.service";
import { emailCampaignState } from "../../../../../state/features/emailCampaign/emailCampaignSelector";
import { useSelector } from "react-redux";
import StatusChip from "./StatusChip.tsx";
import {useImportSpreadsheetContext} from "./importSpreadsheetReducer.tsx";
import {CONNECTED_FILE_STEPS, SPREADSHEET_PROCESSING_STATUS} from "../../../../../helpers/constant/coreConstants.ts";
import mapCSVImportHelper from "./connect/mapping/mapCSVImportHelper.ts";
import {ICSVFile} from "../../../../../state/features/emailCampaign/emailCampaign.interface.ts";
import {IMapData} from "./connect/mapping/Mapping.tsx";
import {showNotification} from "../../../../../helpers/util/functions.tsx";
import {RotatingIcon} from "../../../BulkEmailStyle.tsx";
import Reconnection from "./connect/reconnection/Reconnection.tsx";
import {useSearchParams} from "react-router-dom";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "10px 16px",
}));

interface DataItem {
  uid: string;
  email: string;
  providerFileName: string;
  status: string;
  processingStatus: string;
  lastProcessedDate: string;
  worksheetInfo: {
      sheetId: number;
      title: string;
  };
  providerFileId: string;

}

interface RenderEachTableItemProps {
  selectedContact: string[];
  onSelectContact: (uid: string) => void;
  item: DataItem;
}

const toHuman = (str: string) => {
    if (str){
        return str.replace("_", " ");
    }
    return str;
};

const RenderEachTableItem: React.FC<RenderEachTableItemProps> = ({ item }) => {
    const [syncing, setSyncing] = useState(false);
    const [showReconnect, setShowReconnect] = useState(false);
    const {
        changeScreen,
        setSelectedConnectFile,
        setMappedRows,
        setUnMappedRows,
        setValuesForSubmit,
        setValuesForSelect
    } = useImportSpreadsheetContext();

    const reSyncSheet = async () => {
        try{
            if (item){
                setSyncing(true);
                const tokenResp = await emailCampaignService.processGoogleRefreshToken({ email: item.email });

                if (tokenResp?.success){
                    const detailsResp = await emailCampaignService.googleReadSpreadsheet({ email: item.email, spreadsheetId: item.providerFileId, worksheetName: item.worksheetInfo.title });
                    if (detailsResp?.success && detailsResp?.data && detailsResp?.data[0]){
                        const { mappedRows, actionNeededRows, submitData } = mapCSVImportHelper.mapData(detailsResp.data as unknown as ICSVFile<string | number>[][]);
                        const data: IMapData[] = Object.values(submitData);

                        setMappedRows(mappedRows);
                        setUnMappedRows(actionNeededRows);
                        setValuesForSubmit(data);
                        setValuesForSelect(data);
                        changeScreen(CONNECTED_FILE_STEPS.SYNC_FILE);
                        setSelectedConnectFile(item);
                    }else {
                        showNotification("error", "Empty spreadsheet. Try with different one");
                    }
                }else {
                    setShowReconnect(true);
                    showNotification("error", tokenResp.message || "Failed to get sheet data");
                }
            }
        }catch (err){
            showNotification("error", "Empty spreadsheet. Try with different one");
        }finally {
            setSyncing(false);
        }
    };

    const handleurl = (url: string) => {
        window.open(url);
    }

  return (
    <TableRow>
      <StyledTableCell >
         <Stack direction={'row'} gap={'8px'}>
         <Typography>{item?.providerFileName || " "}</Typography>
         <OpenInNewFilledIcon onClick={()=>handleurl(item.providerFileUrl)} cursor={'pointer'} fontSize="12px"/>
         </Stack>
          
      </StyledTableCell>
      <StyledTableCell>
          {item?.worksheetInfo?.title || " "}
      </StyledTableCell>
      <StyledTableCell>
          {item?.totalContactUploaded || 0}
      </StyledTableCell>
      <StyledTableCell align="center">
          <StatusChip status={toHuman(item?.status)}/>
      </StyledTableCell>
      <StyledTableCell align="center">
          <StatusChip status={toHuman(item?.processingStatus)}/>
      </StyledTableCell>
      <StyledTableCell>
          {item?.lastProcessedDate && (window as any).globalTimezoneConversionToDifferentTimezone(item?.lastProcessedDate)}
      </StyledTableCell>
        <StyledTableCell>
            <Stack direction={'row'} spacing={2}>
                {
                    (item.processingStatus === SPREADSHEET_PROCESSING_STATUS.PROCESSED ||
                    item.processingStatus === SPREADSHEET_PROCESSING_STATUS.NOT_PROCESSED) &&
                    (syncing ?
                        <Button sx={{
                            "&:focus": { backgroundColor: "primary", color: "primary" },
                        }}>
                            <RotatingIcon sx={{ color: "#fff" }} />
                            <Typography sx={{ ml: 1 }}>
                                Syncing
                            </Typography>
                        </Button> :
                        <Button sx={{
                            "&:focus": { backgroundColor: "primary", color: "primary" },
                        }} onClick={reSyncSheet}>
                            <RefreshIcon sx={{ color: "#fff" }} />
                            <Typography sx={{ ml: 1 }}>
                                Resync
                            </Typography>
                        </Button>)
                }
            </Stack>
        </StyledTableCell>
        <Reconnection
            info={item}
            email={item.email}
            open={showReconnect}
            onClose={()=>{ setShowReconnect(false); }}
            resetOnClose={false}
        />
    </TableRow>
  );
};

interface IProps {
    isRefreshed: boolean;
    setIsRefreshed: (state: boolean) => void;
    isLoading: boolean;
    setIsLoading: (state: boolean) => void;
}

const ConnectedFileList: React.FC<IProps> = ({ isRefreshed,setIsRefreshed, isLoading, setIsLoading }) => {
    const [searchParams] = useSearchParams();
    const {
        changeScreen,
        setMappedRows,
        setUnMappedRows,
        setValuesForSubmit,
        setValuesForSelect,
        setSelectedConnectFile,
    } = useImportSpreadsheetContext();
    const { selectedCampaign } = useSelector(emailCampaignState);
    const [fileList, setFileList] = useState([]);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        fetchFileListData().then();
    }, [page, limit]);

    useEffect(() => {
        if (isRefreshed) {
            fetchFileListData().then();
            setIsRefreshed(false);
        }
    }, [isRefreshed]);

    const fetchFileListData = (async () => {
        try {
            setIsLoading(true);
            const res = await emailCampaignService.getUserConnectedFiles({
                page: page + 1,
                limit,
                campaignUid: selectedCampaign?.uid,
            });

            if (res && res.success) {
                setFileList(res.data);

                if (searchParams.has('spreadsheet') && searchParams.has('state')){
                    const fileItem = res.data.find((item: DataItem)=>(item.uid === searchParams.get('state')));
                    if (fileItem){
                        await autoResync(fileItem);
                    }
                }

                if (page === 0) {
                    setCount(res.totalCount);
                }

                if (res.data.length === 0){
                    changeScreen(CONNECTED_FILE_STEPS.CONNECT_FILE);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    });

    const autoResync = async (item: DataItem) => {
        try{
            const detailsResp = await emailCampaignService.googleReadSpreadsheet({ email: searchParams.get('spreadsheet'), spreadsheetId: item.providerFileId, worksheetName: item.worksheetInfo.title });
            if (detailsResp?.success && detailsResp?.data && detailsResp?.data[0]){
                const { mappedRows, actionNeededRows, submitData } = mapCSVImportHelper.mapData(detailsResp.data as unknown as ICSVFile<string | number>[][]);
                const data: IMapData[] = Object.values(submitData);

                setMappedRows(mappedRows);
                setUnMappedRows(actionNeededRows);
                setValuesForSubmit(data);
                setValuesForSelect(data);
                changeScreen(CONNECTED_FILE_STEPS.SYNC_FILE);
                setSelectedConnectFile(item);
            }else {
                showNotification("error", "Empty spreadsheet. Try with different one");
            }
        }catch (err){
            showNotification("error", "Empty spreadsheet. Try with different one");
        }
    }

    if (isLoading){
        return (
            <Stack alignItems={"center"} justifyContent={"center"} height={655}>
                <CircularProgress />
            </Stack>
        );
    }

  return (
    <Fragment>
      <ConnectedFileListPagination setPage={setPage} page={page} count={count} limit={limit} setLimit={setLimit}/>
      <TableContainer>
        <Table variant={"bordered"} size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Stack direction="row" alignItems="center">
                   Sheet Name
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center">
                   Worksheet Name
                </Stack>
              </TableCell>
              <TableCell width="15%">
                 Total Contact Uploaded
              </TableCell>
              <TableCell align="center" width="10%">
                  Status
              </TableCell>
              <TableCell align="center" width="10%">
                 Processing Status
              </TableCell>
              <TableCell width="10%">Last Processed Date</TableCell>
              <TableCell width="10%">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {fileList.map((item, index) => (
              <RenderEachTableItem
                key={`${index}--table-item`}
                item ={item}
                selectedContact={[]}
                onSelectContact={() => {}}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default ConnectedFileList;
