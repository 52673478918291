import React, { Dispatch, SetStateAction, useState } from "react";
import {
  ArrowLeftIcon,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "convertupleads-theme";
import AddContactFilter from "./AddContactFilter";
import AddContactPagination from "./AddContactPagination";
import AddContactTable from "./AddContactTable";
import PopupAlert from "../../../../common/PopupAlert";
import {
  LEAD_ITEM,
  USER_ROLE_ADMIN,
  USER_ROLE_AGENT,
  USER_ROLE_OWNER,
} from "../../../../../helpers/constant/coreConstants";
import emailCampaignService from "../../../../../services/emailCampaign.service";
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../../state/features/emailCampaign/emailCampaignSelector";
import { getAccountData } from "../../../../../helpers/util/functions";

interface IProps {
  onBack: Dispatch<SetStateAction<string>>;
}

const AddFromContactList: React.FC<IProps> = ({ onBack }) => {
  const [searchValue, setSearchValue] = useState("");
  const [totalContacts, setTotalContacts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [confirmAddContact, setConfirmAddContact] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [filteredValue, setFilteredValue] = useState();
  const [isSelectAllContact, setIsSelectAllContact] = useState(false);
  const [isContactAvailable, setIsContactAvailable] = useState(false);

  const { selectedCampaign } = useSelector(emailCampaignState);

  const isContactSelected = selectedContacts?.length > 0 || isSelectAllContact;

  const handleSubmit = () => {
    setConfirmAddContact(true);
  };

  const handleConfirmSubmit = async () => {
    if (isSelectAllContact) {
      try {
        setIsAssigning(true);

        let resp = await emailCampaignService.assignAllContacts({
          search: searchValue,
          campaignUid: selectedCampaign?.uid as string,
          role: getAccountData("userIsOwner")
            ? USER_ROLE_OWNER
            : getAccountData("userIsAdmin")
            ? USER_ROLE_ADMIN
            : USER_ROLE_AGENT,
            ...filteredValue || {},
        });
        if (resp && resp.success) {
          setSelectedContacts([]);
          if ((window as any).showNotification) {
            (window as any).showNotification("success", resp.message);
          }
          onBack(LEAD_ITEM.CAMPAIGN_CONTACT_LIST);
        } else {
          if ((window as any).showNotification) {
            (window as any).showNotification("error", resp.message);
          }
        }
      } catch (err) {
        if ((window as any).showNotification) {
          (window as any).showNotification(
            "error",
            "Something went wrong! Try again later"
          );
        }
      } finally {
        setIsAssigning(false);
      }
    } else {
      try {
        setIsAssigning(true);

        let resp = await emailCampaignService.assignContact({
          contactIds: selectedContacts,
          campaignUid: selectedCampaign?.uid,
        });
        if (resp && resp.success) {
          setSelectedContacts([]);
          if ((window as any).showNotification) {
            (window as any).showNotification("success", resp.message);
          }
          onBack(LEAD_ITEM.CAMPAIGN_CONTACT_LIST);
        } else {
          if ((window as any).showNotification) {
            (window as any).showNotification("error", resp.message);
          }
        }
      } catch (err) {
        if ((window as any).showNotification) {
          (window as any).showNotification(
            "error",
            "Something went wrong! Try again later"
          );
        }
      } finally {
        setIsAssigning(false);
      }
    }
  };

  return (
    <Stack width={"100%"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
        p={1}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={1}
          sx={{ cursor: "pointer" }}
          onClick={() => onBack("")}
        >
          <Stack>
            <IconButton sx={{ ":focus": { bgcolor: "transparent" } }}>
              <ArrowLeftIcon color="text.secondary" />
            </IconButton>
          </Stack>
          <Stack>
            <Typography variant={"h6"} color={"text.primary"}>
              Add From Contact List
            </Typography>
            <Typography variant={"body2"} color={"text.secondary"} fontWeight={500}>
              {isSelectAllContact
                ? `${totalContacts} Contacts Selected`
                : `${selectedContacts?.length || 0} Selected`}
            </Typography>
          </Stack>
        </Stack>

        <Button
          disabled={!isContactAvailable || !isContactSelected || isAssigning}
          sx={{ ":focus": { bgcolor: "primary.main" } }}
          onClick={handleSubmit}
        >
          Add To Campaign
        </Button>
      </Stack>
      <Stack px={1}>
        <Paper elevation={0} variant="outlined" sx={{ borderRadius: "8px" }}>
          <AddContactFilter
            searchValue={searchValue}
            onSearch={(event) => setSearchValue(event.target.value)}
            onFilter={setFilteredValue}
            filteredValue={filteredValue}
          />
          <Divider light />

          <AddContactPagination
            currentPage={currentPage}
            perPage={perPage}
            totalPage={totalPage}
            setPerPage={setPerPage}
            setTotalPage={setTotalPage}
            setCurrentPage={setCurrentPage}
            totalContacts={totalContacts}
            isSelectAllContact={isSelectAllContact}
            setIsSelectAllContact={setIsSelectAllContact}
            isContactAvailable={isContactAvailable}
          />

          <AddContactTable
            currentPage={currentPage}
            perPage={perPage}
            setTotalPage={setTotalPage}
            searchValue={searchValue}
            setTotalContacts={setTotalContacts}
            selectedContacts={selectedContacts}
            onSelectContacts={setSelectedContacts}
            filteredValue={filteredValue}
            isSelectAllContact={isSelectAllContact}
            setIsSelectAllContact={setIsSelectAllContact}
            setIsContactAvailable={setIsContactAvailable}
          />
        </Paper>
      </Stack>
      <PopupAlert
        title={" "}
        description={
          "Contact add to campaign will process! It might take 2-3 minutes to complete."
        }
        submitText={"Ok"}
        open={confirmAddContact}
        onSubmit={() => {
          handleConfirmSubmit();
          setConfirmAddContact(false);
        }}
        onCancel={() => {
          setConfirmAddContact(false);
        }}
      />
      ;
    </Stack>
  );
};

export default AddFromContactList;
