import React, {useState} from "react";
import {
    AddIcon,
    Box,
    Button, ChevronDownIcon,
    Chip,
    FormControl,
    MenuItem, ModalWithHeader,
    Paper,
    Select,
    Stack,
    Typography
} from "convertupleads-theme";
import emailCampaignService from "../../../../../../../services/emailCampaign.service.ts";
import {useSelector} from "react-redux";
import {emailCampaignState} from "../../../../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import {useImportSpreadsheetContext} from "../../importSpreadsheetReducer.tsx";
import {CustomMenuItem} from "../../../../../BulkEmailStyle.tsx";
import AddMoreTagModal from "../../../../Settings/AddMoreTagModal.tsx";
import {CONNECTED_FILE_STEPS} from "../../../../../../../helpers/constant/coreConstants.ts";
import {showNotification} from "../../../../../../../helpers/util/functions.tsx";

const MenuProps = {
    PaperProps: {
        style: {
            paddingLeft: '8px',
            maxHeight: '220px'
        },
    },
};

const emptyObjToNull = (x: any) => {
    if (Object.keys(x).length === 0) {
        return  null;
    }

    return x;
};

const Details = () => {
    const { selectedCampaign } = useSelector(emailCampaignState);
    const [addMoreTagModal, setAddMoreTagModal] = useState(false);
    const {
        email,
        link,
        handleGoNextStep,
        handleGoPreviousStep,
        valuesForSubmit,
        extractFileId,
        fileName,
        worksheet,
        worksheets,
        resetImportData,
        additionalInfos,
        changeAdditionalInfos,
        tags,
        leadSources,
        pipelines,
        stages,
        handleSelectPipeline,
        setTags,
        reFormat,
        changeScreen
    } = useImportSpreadsheetContext();
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async () => {
        if (!additionalInfos.source_id){
            showNotification("error", "Lead source is required");
            return;
        }

        setSubmitting(true);
        try {
            let selectedWorksheetInfo =  worksheets.find((sheet: { title: string; sheetId: string; })=>(sheet.title === worksheet));

            const resp = await emailCampaignService.googleUploadSpreadsheet({
                campaignUid: selectedCampaign?.uid,
                email: email,
                providerFileId: extractFileId(link),
                providerFileName: fileName,
                providerFileUrl: link,
                additionalInfos: additionalInfos,
                worksheetInfo: selectedWorksheetInfo,
                mapping: reFormat(valuesForSubmit, "contact"),
                customMapping: emptyObjToNull(reFormat(valuesForSubmit, "custom")),
            });
            if (resp && resp?.success){
                resetImportData();
                showNotification("success", resp.message);
                changeScreen(CONNECTED_FILE_STEPS.CONNECTED_FILE_LIST);
            }
        }catch (err){
            showNotification("error", "Unable to save spreadsheet import data");
        }finally {
            setSubmitting(false);
        }
    };

    const filteredTags = additionalInfos.tag_ids ? tags.filter((tag: any) => (!additionalInfos.tag_ids.includes(tag.id))) : tags;

    const tagName = (tag: any) => {
        try{
            return tags.find((tagItem: any)=>(tagItem.id === tag)).name || tag;
        }catch (err){
            return tag;
        }
    };

    const updateTriggerSettingDetails = (value: string, setting: string, key: string, extraData: any) => {
        handleChangeTagSelect(value);
        setTags((prevState: any)=>([...prevState, extraData]));
    };

    const handleChangeTagSelect = (value: string) => {
        if (!additionalInfos.tag_ids){
            additionalInfos.tag_ids = [];
        }

        let oldTags = [...additionalInfos.tag_ids];
        oldTags.push(value);
        changeAdditionalInfos({ tag_ids: oldTags });
    };

    const handleDeleteTag = (index: number) => {
        let oldTags = [...additionalInfos.tag_ids];
        oldTags.splice(index, 1);
        changeAdditionalInfos({ tag_ids: oldTags });
    };

    return(
        <Stack spacing={3}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant={'h5'} color={'text.primary'}>
                    Details
                </Typography>
                <Stack direction={'row'} spacing={1.5}>
                    <Button variant={'tonal'} onClick={handleGoPreviousStep}>
                        Previous
                    </Button>
                    <Button disabled={submitting} onClick={handleSubmit}>
                        Complete
                    </Button>
                </Stack>
            </Stack>

            <Paper variant={"outlined"} sx={{ borderRadius: "8px", p: 3 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"} variant={"subtitle1"} color={"text.primary"}>
                        Add Contacts to Deal Pipelines
                    </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={2} mt={2}>
                    <FormControl fullWidth>
                        <Typography color={'text.secondary'} variant={'body2'} mb={1}>
                            Select Pipeline
                        </Typography>
                        <Select displayEmpty fullWidth value={additionalInfos?.pipeline_id || ""} onChange={(event: any)=>{
                            handleSelectPipeline(event.target.value);
                        }}>
                            <MenuItem value={""}>
                                Choose
                            </MenuItem>
                            {
                                pipelines.map((pipeline: any)=>(
                                    <MenuItem value={pipeline.id}>
                                        {pipeline.title}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <Typography color={'text.secondary'} variant={'body2'} mb={1}>
                            Select Stage
                        </Typography>
                        <Select disabled={!additionalInfos?.pipeline_id} displayEmpty fullWidth value={additionalInfos?.stage_id || ""} onChange={(event: any)=>{
                            changeAdditionalInfos({ stage_id: event.target.value });
                        }}>
                            <MenuItem value={""}>
                                Choose
                            </MenuItem>
                            {
                                stages.map((stage: any)=>(
                                    <MenuItem value={stage.id}>
                                        {stage.title}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Stack>
            </Paper>

            <Paper variant={"outlined"} sx={{ borderRadius: "8px", p: 3 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"} variant={"subtitle1"} color={"text.primary"}>
                        Lead Source *
                    </Typography>
                    <Box width={200}>
                        <FormControl fullWidth>
                            <Select displayEmpty fullWidth value={additionalInfos?.source_id || ""} onChange={(event: any)=>{
                                changeAdditionalInfos({ source_id: event.target.value });
                            }}>
                                <MenuItem value={""}>
                                    Choose
                                </MenuItem>
                                {
                                    leadSources.map((leadSource: any)=>(
                                        <MenuItem value={leadSource.id}>
                                            {leadSource.sourceTitle}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>
            </Paper>

            <Paper variant={"outlined"} sx={{ borderRadius: "8px", p: 3 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"} variant={"subtitle1"} color={"text.primary"}>
                        Tags
                    </Typography>

                    <Button onClick={() => { setAddMoreTagModal(true); }} variant={"text"} startIcon={<AddIcon />} sx={{ "&:focus": {  backgroundColor: "transparent" }}}>
                        Add Tags
                    </Button>
                </Stack>
                <Stack mt={2}>
                    <Select
                        onChange={(event: any)=>{handleChangeTagSelect(event.target.value);}}
                        MenuProps={MenuProps}
                        value={""}
                        fullWidth displayEmpty
                        IconComponent={ChevronDownIcon}
                    >
                        <CustomMenuItem value={""} disabled>
                            Choose
                        </CustomMenuItem>
                        {
                            filteredTags.map((item: { id: string; name: string; }) => (
                                <CustomMenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </CustomMenuItem>
                            ))
                        }
                    </Select>
                </Stack>
                <Stack flexWrap={"wrap"} gap={1} direction={"row"} mt={2}>
                    {
                        additionalInfos?.tag_ids?.map((tag: number, index: number)=>(
                            <Chip
                                label={tagName(tag)}
                                color={"default"}
                                size="xSmall"
                                sx={{ height: 22 }}
                                onDelete={() => {
                                    handleDeleteTag(index);
                                }}
                            />
                        ))
                    }
                </Stack>
            </Paper>

            <ModalWithHeader
                open={addMoreTagModal}
                onClose={() => { setAddMoreTagModal(false); }}
                title='Create New Tag'
            >
                <AddMoreTagModal
                    onClose={() => { setAddMoreTagModal(false); }}
                    updateTriggerSettingDetails={updateTriggerSettingDetails}
                    callbackField={"id"}
                />
            </ModalWithHeader>
        </Stack>
    );
}

export default Details;