import React, { useState } from "react";
import {
  Button,
  FilterIcon,
  InputAdornment,
  SearchIcon,
  Stack,
} from "convertupleads-theme";
import { CustomTextField } from "../../../BulkEmailStyle";
import AddContactFilterPopover from "./AddContactFilterPopover";
import { getAccountData } from "../../../../../helpers/util/functions";

interface IProps {
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  onFilter: any;
  filteredValue: any;
}

export interface ILeadUserContactSelectedFilters {
  marketingCampaignId: number | null;
  tagId: number | null;
  fileListId: number | null;
  stageId: number | null;
  leadSourceId: number | null;
  startDate: string | null;
  endDate: string | null;
}

interface IMarketingCampaigns {
  marketingCampaignId: number;
  title: string;
}
interface ITags {
  tagId: number;
  title: string;
}

interface IContactSourceTypes {
  leadSourceId: number;
  title: string;
}
interface IFileLists {
  fileListId: number;
  title: string;
}
interface IStages {
  stageId: number;
  title: string;
}

export interface ILeadUserContactFetchedFilterData {
  marketingCampaigns: IMarketingCampaigns[];
  tags: ITags[];
  contactSourceTypes: IContactSourceTypes[];
  fileLists: IFileLists[];
  stages: IStages[];
}

const AddContactFilter: React.FC<IProps> = ({
  searchValue,
  onSearch,
  onFilter,
}) => {
  const [sortBy, setSortBy] = React.useState<
    HTMLSpanElement | SVGSVGElement | null
  >(null);

  const [fetchedFilterData, setFetchedFilterData] =
    useState<ILeadUserContactFetchedFilterData | null>(null);

  const [selectedFilters, setSelectedFilters] =
    useState<ILeadUserContactSelectedFilters>({
      marketingCampaignId: null,
      tagId: null,
      fileListId: null,
      stageId: null,
      leadSourceId: null,
      startDate: null,
      endDate: null,
    });

  const handleSortBy = (
    event:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setSortBy(event.currentTarget);
  };

  const handleSortByClose = () => {
    setSortBy(null);
  };

  //   const openSortBy = Boolean(sortBy);
  //   const sortById = openSortBy ? "simple-popover" : undefined;

  //   const handleAddNewContact = () => {
  //     (window as any).showAddContactModal();
  //   };
  return (
    <Stack direction={"row"} justifyContent={"space-between"} p={1}>
      <CustomTextField
        type="search"
        placeholder={"Search Contacts"}
        onChange={onSearch}
        value={searchValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 20 }} />
            </InputAdornment>
          ),
        }}
      />

      {(getAccountData("userId") === 1 || getAccountData("userId") === 446) && (
        <Button
          variant={"outlined"}
          color={"inherit"}
          startIcon={<FilterIcon />}
          onClick={handleSortBy}
          sx={{ ":focus": { bgcolor: "transparent" } }}
        >
          Filters
        </Button>
      )}

      {sortBy && (
        <AddContactFilterPopover
          open={sortBy}
          onClose={handleSortByClose}
          fetchedFilterData={fetchedFilterData}
          setFetchedFilterData={setFetchedFilterData}
          onFilter={onFilter}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      )}

      {/* <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Button variant={'tonal'} color={'inherit'} startIcon={<SortIcon />} onClick={handleSortBy} sx={{':focus': { bgcolor: 'transparent' }}}>
                    Sort By
                </Button>

                // <AddContactFilterPopover filterItem={API_FILTER_AGENCY} />

                <Button variant={'tonal'} color={'primary'} startIcon={<AddIcon />} sx={{':focus': { bgcolor: 'primary.light' }}} onClick={handleAddNewContact}>
                    Create New
                </Button>

                
            </Stack> */}

      {/* <SortByPopOver sortById={sortById} openSortBy={openSortBy} sortBy={sortBy} handleClose={() => setSortBy(null)} /> */}
    </Stack>
  );
};

export default AddContactFilter;
