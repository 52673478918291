import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    ChevronDownIcon,
    PersonalizedIcon,
    AttachmentIcon,
    Stack,
    Typography,
    useTheme,
} from 'convertupleads-theme';
import TemplateLibraryModal from './templateLibraryModal/TemplateLibraryModal';
import PersonalizedVideoModal from './personilizedVideo/PersonalizedVideoModal';
import EditPersonalizedVideoModal from './personilizedVideo/EditPersonalizedVideoModal';
import PersonalizedPopover from '../../common/PersonalizedPopover';
import BasicEditorWithGif from '../../common/BasicEditorWithGif';
import { insertPersonalizedTag, showNotification } from '../../../helpers/util/functions.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../state/features/emailCampaign/emailCampaignSelector.ts';
import useDebounce from '../../../hooks/useDebounce.ts';
import {
    selectSequence,
    updateCampaignSequence,
    updateCampaignSequenceUploadedFiles, updateCampaignSequenceUploadedFilesSuccess
} from '../../../state/features/emailCampaign/emailCampaignSlice.ts';
import EachAttachedFile from "./EachAttachedFile.tsx";
import { getCookie } from "../../../helpers/Cookie.ts";
import axios, { AxiosError, AxiosProgressEvent } from 'axios';
import { ISequenceUploadedFile } from "../../../state/features/emailCampaign/emailCampaign.interface.ts";
import EachAttachedUploadingFile from "./EachAttachedUploadingFile.tsx";

export interface IUploadedFile {
    file: File;
    progress: number;
    status: 'uploading' | 'uploaded' | 'error';
}

const fileUploadUrl = `${process.env.REACT_APP_BACKEND_URL}/campaigns/sequences/upload-file`;

const CampaignSquenceEmailBodyTextEditor = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { selectedSequence } = useSelector(emailCampaignState);
    const bodyRef = useRef(null);
    const [openTemplateModal, setOpenTemplateModal] = useState(false);
    const [openPersonalizedVideoModal, setPersonalizedVideoModal] = useState(false);
    const [openEditPersonalizedVideoModal, setEditPersonalizedVideoModal] = useState(false);
    const [messageBody, setMessageBody] = useState('');
    const debouncedBody = useDebounce(messageBody, 500);
    const [openPersonalizedPopover, setOpenPersonalizedPopover] = useState<HTMLButtonElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [uploadingFiles, setUploadingFiles] = useState<IUploadedFile[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<ISequenceUploadedFile[]>([]);

    useEffect(() => {
        if (selectedSequence) {
            setMessageBody(selectedSequence.messageBody || "");
            setUploadedFiles(selectedSequence.uploadedFiles || []);
        }
    }, [selectedSequence]);

    useEffect(() => {
        if (selectedSequence && debouncedBody && selectedSequence.messageBody !== debouncedBody) {

            dispatch(updateCampaignSequence({ messageBody: debouncedBody, uid: selectedSequence.uid, index: selectedSequence.index }));


            // if (selectedSequence.messageSubject && selectedSequence.messageSubject !== '') {
            //     dispatch(updateCampaignSequence({ messageSubject: selectedSequence.messageSubject, messageBody: debouncedBody, uid: selectedSequence.uid, index: selectedSequence.index }));

            // } else {
            //     dispatch(selectSequence({ ...selectedSequence, ...{ messageBody: debouncedBody } }))

            // }
        }
    }, [debouncedBody]);

    const handlePersonalizePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenPersonalizedPopover(event.currentTarget);
    };
    const openPopover = Boolean(openPersonalizedPopover);
    const handleClose = () => {
        setOpenPersonalizedPopover(null);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        const allowedFileTypes = ['csv', 'txt', 'pdf', 'mp3', 'mp4', 'mov', 'wav', 'webm', '3gp', 'avi', 'doc', 'docx', 'odt', 'ppt', 'pptx', 'gif', 'png', 'jpg', 'jpeg', 'xls', 'xlsx', 'zip', 'tar', 'xz', '7z', 'rar', 'odp', 'odg'];
        const maxFileSizeInBytes = 3 * 1024 * 1024;
        const maxFiles = 5;
        let totalFileSize = 0;
        let fileCount = 0;

        uploadedFiles.forEach((file) => {
            totalFileSize += file.file_size;
            fileCount = fileCount + 1;
        });

        if (file) {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            if (fileExtension && allowedFileTypes.includes(fileExtension) && (file.size + totalFileSize) <= maxFileSizeInBytes && maxFiles >= (fileCount + 1)) {
                const newFile: IUploadedFile = {
                    file: file,
                    progress: 0,
                    status: 'uploading'
                };

                setUploadingFiles(prevFiles => [...prevFiles, newFile]);
                await uploadFiles(newFile, uploadingFiles);
            } else {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                if (maxFiles === fileCount) {
                    showNotification('error', 'File exceeds the limit. Max 5 files allowed.');
                    return;
                }
                if (fileExtension && !allowedFileTypes.includes(String(fileExtension))) {
                    showNotification('error', 'Invalid file type. Please select a valid file type.');
                    return;
                }
                if ((file.size + totalFileSize) > maxFileSizeInBytes) {
                    showNotification('error', 'File size exceeds the limit. Please select a file under 3MB.');
                    return;
                }
            }
        }
    };


    const uploadFiles = async (fileToUpload: IUploadedFile, prevFiles: IUploadedFile[]) => {
        const { file } = fileToUpload;
        const formData = new FormData();
        if (selectedSequence) {
            formData.append('campaignSequenceUid', selectedSequence.uid);
        }
        formData.append('qqFilename', file.name);
        formData.append('qqTotalFilesize', String(file.size));
        formData.append('qqFile', file);

        try {
            const response = await axios.post(fileUploadUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN ?? '')}`
                },
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                    if (progressEvent.total) {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        updateFileProgress(prevFiles.length, progress);
                    }
                }
            });

            if (response.status === 200) {
                if (response.data && response.data.success) {
                    setUploadedFiles((prevState) => ([...prevState, response.data.data]));
                    dispatch(updateCampaignSequenceUploadedFilesSuccess([...uploadedFiles, response.data.data]));
                    updateFileStatus(prevFiles.length, 'uploaded');
                } else {
                    showNotification('error', 'Unable to upload ' + file.name);
                    updateFileStatus(prevFiles.length, 'error');
                }
            } else {
                showNotification('error', 'Unable to upload ' + file.name);
                updateFileStatus(prevFiles.length, 'error');
            }
        } catch (error) {
            const axiosError = error as AxiosError;
            showNotification('error', 'Unable to upload ' + file.name);
            updateFileStatus(prevFiles.length, 'error');
            console.log(axiosError);
        } finally {
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const updateFileProgress = (index: number, progress: number) => {
        setUploadingFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].progress = progress;
            return updatedFiles;
        });
    };

    const updateFileStatus = (index: number, status: 'uploading' | 'uploaded' | 'error') => {
        setUploadingFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            if (status === 'error') {
                updatedFiles.splice(index, 1);
            } else {
                updatedFiles[index].status = status;
            }
            return updatedFiles;
        });
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDeleteUploadedFile = (index: number) => {
        let tempUploadedFiles = [...uploadedFiles];
        tempUploadedFiles.splice(index, 1);
        setUploadedFiles(tempUploadedFiles);

        if (selectedSequence)
            dispatch(updateCampaignSequenceUploadedFiles({ campaignSequenceUid: selectedSequence.uid, uploadedFiles: tempUploadedFiles }));
    };

    return (
        <>
            <Stack
                position={'relative'}
                marginY={2}
                sx={{
                    // border: `1px solid ${theme.palette.other.outlinedBorder}`,
                    borderRadius: '4px',
                    // background: theme.palette.other.outlinedBorder,
                    '& .tox-tinymce': {
                        border: `1px solid ${theme.palette.other.divider}`,
                        // borderRadius: '4px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                    },
                    '& .tox .tox-statusbar': {
                        borderTop: `1px solid ${theme.palette.other.divider}`
                    },
                    '& .tox .tox-toolbar__primary': {
                        background: 'unset',
                        borderBottom: `1px solid ${theme.palette.other.divider}`,
                    }


                }}
            >
                <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'space-between'} mb={1}>
                    <Typography variant='body2' color={'text.secondary'}>
                        Email Body
                    </Typography>
                    <Button
                        onClick={handlePersonalizePopover}
                        sx={{ background: theme.palette.other.divider, ':focus': { bgcolor: theme.palette.other.divider } }}
                        variant={'tonal'}
                        // size='small'
                        color={'inherit'}
                        startIcon={<PersonalizedIcon />}
                        endIcon={<ChevronDownIcon />}
                    >
                        Personalize
                    </Button>
                </Stack>
                <BasicEditorWithGif
                    ref={bodyRef}
                    body={messageBody}
                    onChangeBody={(text) => setMessageBody(text)}
                />
                {messageBody === "" ? <Typography variant='caption' color={'error'} py={.5}> Message Body is required </Typography> : ''}
                <Stack
                    direction={'row'}
                    spacing={1}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    padding={2}
                    sx={{
                        background: 'white',
                        // border: `1px solid ${theme.palette.other.outlinedBorder}`, 
                        border: `1px solid ${theme.palette.other.divider}`,
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                    }}
                >
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                    />
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Button
                            onClick={handleButtonClick}
                            variant='outlined'
                            startIcon={<AttachmentIcon />}
                            sx={{ ':focus': { bgcolor: 'transparent' } }}
                        >
                            Attach Files
                        </Button>
                        <Typography fontSize={'small'} fontStyle={'italic'} color={'error.main'} variant={'body2'}>
                            Max Size 3.00 MB, Max 5 Files
                        </Typography>
                    </Stack>
                </Stack>

                {(uploadedFiles[0] || uploadingFiles[0]) && (
                    <Stack sx={{ background: "white" }} padding={2}>
                        {uploadedFiles[0] && uploadedFiles.map((uploadedFile, index) => (

                            <EachAttachedFile
                                key={`attached-file-${index}`}
                                file={uploadedFile}
                                onDeleteUploadedFile={handleDeleteUploadedFile}
                                index={index}
                            />
                        ))}
                        {uploadingFiles[0] && uploadingFiles.map((uploadingFile, index) =>
                            uploadingFile.status === "uploading" ? (
                                <EachAttachedUploadingFile
                                    key={`uploading-file-${index}`}
                                    file={uploadingFile}
                                />
                            ) : null
                        )}
                    </Stack>
                )}

            </Stack>
            {openTemplateModal &&
                <TemplateLibraryModal open={openTemplateModal} setOpen={setOpenTemplateModal} />
            }
            {openPersonalizedVideoModal &&
                <PersonalizedVideoModal open={openPersonalizedVideoModal} setOpen={setPersonalizedVideoModal} />
            }
            {EditPersonalizedVideoModal &&
                <EditPersonalizedVideoModal open={openEditPersonalizedVideoModal} setOpen={setEditPersonalizedVideoModal} />
            }
            <PersonalizedPopover
                open={openPopover}
                id={'simple-popover'}
                onClose={handleClose}
                anchorEl={openPersonalizedPopover}
                onSelect={(value: string | null) => {
                    if (value) {
                        insertPersonalizedTag(value, bodyRef, (value) => {
                            setMessageBody(value);
                        });
                    }
                }}
            />
        </>
    );
};

export default CampaignSquenceEmailBodyTextEditor;
