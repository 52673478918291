import React, { Dispatch, SetStateAction } from 'react';
import { Button, Stack, useTheme } from 'convertupleads-theme';
import { EMAIL_SCHEDULE_TABS } from '../../../../helpers/constant/coreConstants';

interface Props {
    scheduleTab: number;
    setScheduleTab: Dispatch<SetStateAction<number>>;
}

const EmailScheduleTabs: React.FC<Props> = ({ scheduleTab, setScheduleTab }) => {
    const theme = useTheme();

    return (
        <Stack
            justifyContent={'space-between'}
            mt={1}
            padding={0.5}
            borderRadius={1}
            direction={'row'}
            sx={{ background: theme.palette.other.outlinedBorder }}
            gap={1}
        >
            <Button
                variant={scheduleTab === EMAIL_SCHEDULE_TABS.SAME_DAY ? 'contained' : 'tonal'}
                color={scheduleTab === EMAIL_SCHEDULE_TABS.SAME_DAY ? 'inheritWhite' : 'inherit'}
                fullWidth
                onClick={() => setScheduleTab(EMAIL_SCHEDULE_TABS.SAME_DAY)}
                sx={{'&:focus': {bgcolor: 'common.white'}}}
            >
                Same Day
            </Button>
            <Button
                variant={scheduleTab === EMAIL_SCHEDULE_TABS.LATER_DAY ? 'contained' : 'tonal'}
                color={scheduleTab === EMAIL_SCHEDULE_TABS.LATER_DAY ? 'inheritWhite' : 'inherit'}
                fullWidth
                onClick={() => setScheduleTab(EMAIL_SCHEDULE_TABS.LATER_DAY)}
                sx={{'&:focus': {bgcolor: 'common.white'}}}
            >
                Later Day
            </Button>
            <Button
                variant={scheduleTab === EMAIL_SCHEDULE_TABS.RECURRING ? 'contained' : 'tonal'}
                color={scheduleTab === EMAIL_SCHEDULE_TABS.RECURRING ? 'inheritWhite' : 'inherit'}
                fullWidth
                onClick={() => setScheduleTab(EMAIL_SCHEDULE_TABS.RECURRING)}
                sx={{'&:focus': {bgcolor: 'common.white'}}}
            >
                Recurring
            </Button>
        </Stack>
    );
};

export default EmailScheduleTabs;
