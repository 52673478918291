import { IconButton, IncomingSmsIcon, Stack, TriggerIcon, Typography, UserIcon, useTheme } from 'convertupleads-theme';


const RewritePopOver = () => {
    const theme = useTheme();
    return (
        <Stack width={284} padding={2}>
            <Stack borderRadius={1} sx={{ background: theme.palette.other.divider }}>
                <Stack direction={'row'} spacing={3} padding={2}>
                    <Stack textAlign={'left'}>
                        <Typography variant='body2' color={'text.secondary'}>
                            Overall Score
                        </Typography>
                        <Typography variant='body2' color={'text.secondary'}>
                            Ward
                        </Typography>
                        <Typography variant='body2' color={'text.secondary'}>
                            Read time
                        </Typography>
                    </Stack>
                    <Stack textAlign={'left'}>
                        <Typography variant='body2' color={'error'}>
                            {' '}
                            Poor
                        </Typography>
                        <Typography variant='body2' color={'text.secondary'}>
                            547
                        </Typography>
                        <Typography variant='body2' color={'text.secondary'}>
                            2min 30sec
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    direction={'row'}
                    padding={1}
                    alignItems={'center'}
                    spacing={2}
                    borderLeft={`4px solid #940015`}
                    borderRadius={1}
                    sx={{ background: '#fff', marginTop: '4px' }}
                >
                    <IconButton color={`error`}>
                        <IncomingSmsIcon />
                    </IconButton>
                    <Typography>Urgency</Typography>
                </Stack>
                <Stack
                    direction={'row'}
                    padding={1}
                    alignItems={'center'}
                    spacing={2}
                    borderLeft={`4px solid #ED0039`}
                    borderRadius={1}
                    sx={{ background: '#fff', marginTop: '4px' }}
                >
                    <IconButton color='error'>
                        <TriggerIcon />
                    </IconButton>
                    <Typography>Shady(9)</Typography>
                </Stack>
                <Stack
                    direction={'row'}
                    padding={1}
                    alignItems={'center'}
                    spacing={2}
                    borderLeft={`4px solid ${theme.palette.warning.main}`}
                    borderRadius={1}
                    sx={{ background: '#fff', marginTop: '4px' }}
                >
                    <IconButton color='warning'>
                        <UserIcon />
                    </IconButton>
                    <Typography>Overpromise(4)</Typography>
                </Stack>
                <Stack
                    direction={'row'}
                    padding={1}
                    alignItems={'center'}
                    spacing={2}
                    borderLeft={`4px solid #A352C2`}
                    borderRadius={1}
                    sx={{ background: '#fff', marginY: '4px' }}
                >
                    <IconButton color='secondary'>
                        <IncomingSmsIcon />
                    </IconButton>
                    <Typography>Unnatuural(4)</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default RewritePopOver;
