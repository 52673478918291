import React from 'react';
import {
    Box,
    Divider,
    List,
    PeopleFilledIcon,
    Stack,
    Typography,
    UploadFileFilledIcon,
    useTheme,
} from 'convertupleads-theme';
import LeadsSidebar from './LeadsSidebar';
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import { getAccountData } from "../../../../helpers/util/functions.tsx";
import { GoogleSpreadsheetIcon, PersonAddIcon } from "../../../../assets/Icons.tsx";
import { STEPS, STEPS_SUB } from "../../../../helpers/constant/coreConstants.ts";

interface IimportLeadsModalSidebar {
    handleSectionClick: (data: string) => void;
    activeSection: string;
    goSubStep: (step: string | number, subStep: string | number) => void;
}

const [firstName] = getAccountData("fullName")?.split(" ");
const ImportLeadsModalSidebar: React.FC<IimportLeadsModalSidebar> = ({ handleSectionClick, activeSection, goSubStep }) => {
    const theme = useTheme();
    return (
        <Stack
            justifyContent={'space-between'}
            width={310}
            height={'100%'}
            sx={{
                background: theme.palette.other.outlinedBorder,
                color: theme.palette.text.secondary,
                '&:hover': {
                    background: theme.palette.other.outlinedBorder,
                    color: theme.palette.text.secondary,
                },
                borderRight: `1px solid ${theme.palette.other.outlinedBorder}`,
            }}
        >
            <Box height={'calc(100vh - 227px)'} overflow={'hidden'}>
                <Stack>
                    <Typography variant={'h5'} sx={{ p: 2.5 }}>
                        Import Leads
                    </Typography>
                    <Divider light sx={{ pt: 1 }} />
                </Stack>

                <List component='div' aria-label='main mailbox folders' sx={{ pt: 3.2, paddingX: 2.5 }}>
                    <LeadsSidebar
                        icon={<PeopleFilledIcon color='primary' />}
                        title='Campaign Contact List'
                        handleClick={() => handleSectionClick('campaignContactList')}
                        selected={activeSection === 'campaignContactList'}
                    />
                    <LeadsSidebar
                        icon={<PersonAddIcon color='primary' />}
                        title='Add from Contact List'
                        handleClick={() => handleSectionClick('contactList')}
                        selected={activeSection === 'contactList'}
                    />
                    <LeadsSidebar
                        icon={<UploadFileFilledIcon color='primary' />}
                        title='Import CSV File'
                        // handleClick={() => {
                        //     if (getAccountData('userId') === 1 || getAccountData('userId') === 446) {
                        //         goSubStep(STEPS.STEP_LEADS, STEPS_SUB[STEPS.STEP_LEADS].CSV);
                        //     }else {
                        //         window.open(`/bulk-email/campaign/import/${selectedCampaign?.uid}`, '_blank');
                        //     }
                        // }}
                        handleClick={() => {
                            goSubStep(STEPS.STEP_LEADS, STEPS_SUB[STEPS.STEP_LEADS].CSV);
                        }}
                        selected={activeSection === 'csv'}
                    />
                    {
                        <LeadsSidebar
                            icon={<GoogleSpreadsheetIcon />}
                            title='Import From Google Sheets'
                            handleClick={() => { goSubStep(STEPS.STEP_LEADS, STEPS_SUB[STEPS.STEP_LEADS].SPREADSHEET); }}
                            selected={activeSection === 'googleSheet'}
                        />
                    }
                    {/* <LeadsSidebar
                        icon={<PersonAddIcon color='primary' />}
                        title='Add From Smart List'
                        handleClick={() => handleSectionClick('smartList')}
                        selected={activeSection === 'smartList'}
                    /> */}
                </List>
            </Box>

            <Stack>
                <Divider light />
                <Stack p={2.5}>
                    <Typography variant={'subtitle1'}>
                        <b>Hi, {firstName || getAccountData("fullName")}!</b>
                    </Typography>
                    <Typography variant={'body2'}>Having trouble importing leads into your campaign?</Typography>

                    <Typography variant={'body2'} fontWeight={600} mt={1}>If so, reach out to our team for help!</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ImportLeadsModalSidebar;
