import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "convertupleads-theme";
import store from "./state/store.ts";
import App from "./App";

window.renderBulkEmailModule = (containerId, history) => {
    ReactDOM.createRoot(document.getElementById(containerId)).render(
        <ThemeProvider>
            <Provider store={store}>
                <App history={history} />
            </Provider>
        </ThemeProvider>
    );
};

window.unmountBulkEmailModule = (containerId) => {
    ReactDOM.createRoot(document.getElementById(containerId)).unmount();
};

if (process.env.REACT_APP_PRODUCTION_MODE !== true && document.getElementById("BulkEmail-root")) {
    ReactDOM.createRoot(document.getElementById("BulkEmail-root")).render(
        <ThemeProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    );
}

