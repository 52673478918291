import React from 'react';
import {
    AIFilledIcon,
    AddIcon,
    ArrowDropDownFilledIcon,
    ArrowLeftIcon,
    Button,
    ButtonGroup,
    ChevronUpDownIcon,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    PersonalizedIcon,
    Popover,
    SmileEmojiIcon,
    Stack,
    Typography,
    useTheme,
} from 'convertupleads-theme';

interface EditVariationDrawerProps {
    state: boolean;
    toggleDrawer: () => void;
}

const EditVariationDrawer: React.FC<EditVariationDrawerProps> = ({ state, toggleDrawer }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const theme = useTheme();

    return (
        <div>
            <Drawer sx={{ position: 'relative' }} width={600} anchor={'right'} open={state} onClose={toggleDrawer}>
                <ButtonGroup
                    variant={'outlined'}
                    sx={{ width: '185px', position: 'absolute', right: 62, top: 13 }}
                    aria-label='contained button group'
                >
                    <Button color='secondary' startIcon={<AIFilledIcon />} size='small'>
                        Rewrite with AI
                    </Button>
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        aria-describedby={id}
                        onClick={handleClick}
                        sx={{ background: '#ffffff' }}
                    >
                        <ArrowDropDownFilledIcon color='secondary' />
                    </Button>
                </ButtonGroup>
                <Stack direction={'row'} alignItems={'center'} spacing={1} paddingX={3} paddingY={'19px'}>
                    <IconButton onClick={toggleDrawer} sx={{ padding: '0' }}>
                        <ArrowLeftIcon />
                    </IconButton>
                    <Typography variant={'subtitle1'}>Edit Variation1</Typography>
                </Stack>
                <Divider light />

                <Stack padding={3}>
                    <Stack
                        height={`calc(100vh-100px)`}
                        padding={3}
                        borderRadius={2}
                        sx={{ background: theme.palette.other.outlinedBorder }}
                    >
                        <Stack
                            direction={'row'}
                            paddingBottom={'6px'}
                            alignItems={'center'}
                            borderBottom={`1px solid ${theme.palette.other.divider}`}
                        >
                            {' '}
                            <Typography variant='body2' color={'text.disabled'}>
                                Title:
                            </Typography>
                            <Typography variant='body2' color={'text.primary'}>
                                &nbsp;Variation1
                            </Typography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            mt={'20px'}
                            paddingBottom={'6px'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            borderBottom={`1px solid ${theme.palette.other.divider}`}
                        >
                            <Stack direction={'row'}>
                                {' '}
                                <Typography variant='body2' color={'text.disabled'}>
                                    Subject:
                                </Typography>
                                <Typography variant='body2' color={'text.primary'}>
                                    &nbsp;Grow Your Reach with Email
                                </Typography>
                            </Stack>
                            <Stack direction={'row'}>
                                <IconButton>
                                    <SmileEmojiIcon sx={{ fontSize: '16px' }} />
                                </IconButton>
                                <IconButton>
                                    <PersonalizedIcon sx={{ fontSize: '16px' }} />
                                </IconButton>
                            </Stack>
                        </Stack>
                        <Stack mt={'20px'}>
                            <Typography color={'text.secondary'} variant='body2'>
                                Dear [Client{"'"}s Name],
                            </Typography>
                            <Typography color={'text.secondary'} variant='body2' mt={2}>
                                I hope this email finds you well. My name is [Your Name], and I represent [Your Company Name], a specialized
                                team of UX/UI designers dedicated to enhancing user experiences and driving business success. In today{"'"}s
                                competitive digital landscape, a seamless user experience can make all the difference in capturing and
                                retaining customers. Our team has a proven track record of transforming complex ideas into intuitive and
                                visually appealing interfaces. We understand the significance of creating designs that align with your
                                business goals while delivering a delightful experience for your users. Let{"'"}s schedule a brief call at
                                your convenience to explore the possibilities. Looking forward to the possibility of collaborating and
                                making your user experience truly exceptional. Best regards, [Your Name] [Your Title] [Your Contact
                                Information]
                            </Typography>
                            <Typography color={'text.secondary'} variant='body2' mt={2}>
                                Best regards,
                                <br /> [Your Name]
                                <br /> [Your Title]
                                <br /> [Your Contact Information]
                            </Typography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            position={'absolute'}
                            alignItems={'center'}
                            spacing={2}
                            left={50}
                            top={320}
                            height={30}
                            width={475}
                            borderRadius={1}
                            padding={1}
                            sx={{ background: 'white' }}
                        >
                            <Button
                                color='secondary'
                                sx={{
                                    '&:hover': {
                                        background: theme.palette.secondary.light,
                                    },
                                    background: theme.palette.secondary.light,
                                }}
                                startIcon={<AIFilledIcon color='secondary' />}
                                size='small'
                            >
                                <Typography color={'secondary'}>Ask AI</Typography>
                            </Button>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography color={'text.secondary'}>Normal</Typography>
                                <IconButton>
                                    <ChevronUpDownIcon />
                                </IconButton>
                            </Stack>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Typography color={'text.secondary'}>Seilac Light</Typography>
                                <IconButton>
                                    <ChevronUpDownIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Popover
                        sx={{ top: '10px' }}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <List component='div' aria-label='main mailbox folders'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AddIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography color={'text.secondary'} variant='body2' fontWeight={400}>
                                        Add more variations
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AIFilledIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography color={'text.secondary'} variant='body2' fontWeight={400}>
                                            Regenerate All
                                        </Typography>
                                    </Stack>
                                </ListItemText>
                            </ListItemButton>
                        </List>
                    </Popover>
                </Stack>

                <Stack position={'absolute'} bottom={0} sx={{ width: '100%' }}>
                    <Divider light />
                    <Stack direction={'row'} justifyContent={'end'} spacing={1} paddingX={3} paddingY={2}>
                        <Button variant={'tonal'} color={'inherit'} onClick={toggleDrawer} size='large'>
                            Cancel
                        </Button>

                        <Button variant={'contained'} size='large' sx={{ fontWeight: '500' }}>
                            Confirm
                        </Button>
                    </Stack>
                </Stack>
            </Drawer>
        </div>
    );
};

export default EditVariationDrawer;
