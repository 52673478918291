import React from "react";
import {
    Stack,
    Step,
    StepButton,
    Stepper,
} from "convertupleads-theme";
import {useImportCsvContext} from "../importCsvReducer.tsx";

const StepperSidebar: React.FC = () => {
    const { activeImportStep, stepList } = useImportCsvContext();

    return (
        <Stack spacing={2} width={250} p={2}>
            <Stepper activeStep={activeImportStep} orientation="vertical">
                {stepList?.map((step) => (
                    <Step key={step.label}>
                        <StepButton sx={{ "&:focus": { background: "transparent" }}}>{step.label}</StepButton>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}

export default StepperSidebar;