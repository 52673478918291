import React, { Dispatch, SetStateAction } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, ChevronDownIcon, Divider, List, ListItem, ListItemButton, ListItemText, Stack, Typography, useTheme } from 'convertupleads-theme';
import { CustomScrollbar } from '../../../common/CustomScrollBarStyle';



type EmailTemplate = {
    emailTemplateInfoId: string;
    title: string;
    subject: string;
    body: string;
};

type TemplateGroup = {
    title: string;
    templates: EmailTemplate[];
};

type TemplateGroups = TemplateGroup[];

interface IProps {
    emailTemplates: TemplateGroups;
    selectedTemplate: EmailTemplate;
    setSelectedTemplate: Dispatch<SetStateAction<EmailTemplate>>;
}


const TemplateLibraryLeftSide: React.FC<IProps> = ({ emailTemplates, selectedTemplate, setSelectedTemplate }) => {
    const theme = useTheme();

    return (
        <Stack width={300} sx={{ background: theme.palette.other.divider }}>
            <Typography padding={2} variant='h5' color={'text.primary'}>
                Templates
            </Typography>
            <Divider />

            <CustomScrollbar sx={{ maxHeight: 640 }}>
                <Stack padding={2}>

                    {
                        emailTemplates.map((template, index) => (
                            <Accordion
                                key={index}
                                defaultExpanded={selectedTemplate ? template.templates.some(item => item.emailTemplateInfoId === selectedTemplate.emailTemplateInfoId) : index === 0}
                                sx={{ bgcolor: 'transparent' }}>
                                <AccordionSummary
                                    expandIcon={
                                        <ChevronDownIcon fontSize="small" color={"text.tertiary"} />
                                    }
                                >
                                    <Typography
                                        variant={"button"}
                                        color={"text.secondary"}
                                        sx={{ textTransform: "normal" }}
                                    >
                                        {template.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <List
                                        component='div'
                                        aria-label='main mailbox folders'
                                        dense
                                    >
                                        {template.templates.map((item, i) => (
                                            <ListItem disablePadding key={i}>
                                                <ListItemButton
                                                    selected={selectedTemplate ? selectedTemplate === item : (index === 0 && i === 0)}
                                                    onClick={() => setSelectedTemplate(item)}
                                                >
                                                    <ListItemText primary={item.title} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </Stack>
            </CustomScrollbar>
        </Stack>
    );
};

export default TemplateLibraryLeftSide;
