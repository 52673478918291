import React, { Dispatch, SetStateAction } from 'react';
import { Button, Popover, Stack } from 'convertupleads-theme';
import SendEmailModal from './SendEmailModal';
import RewritePopOver from './RewritePopOver';
import { useSelector } from 'react-redux';
import { emailCampaignState } from '../../../state/features/emailCampaign/emailCampaignSelector';

interface EmailBodyFooterSectionProps {
    openModal: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
}

const CampaignSquenceEmailBodyFooter: React.FC<EmailBodyFooterSectionProps> = ({ openModal, setOpenModal }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const { selectedSequence } = useSelector(emailCampaignState);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const isSubjectEmpty = !selectedSequence?.messageSubject?.trim();
    const isBodyEmpty = !selectedSequence?.messageBody?.trim();

    return (
        <Stack direction={'row'} justifyContent={'end'} spacing={2} pb={3}>
            {/* <Button variant={'outlined'} size='large' onClick={handleOpenPopover} sx={{':focus': { bgcolor: 'transparent' }}}>
                Check Spam
            </Button> */}

            <Button
                variant={'contained'}
                size='large'
                onClick={() => {
                    setOpenModal(true);
                }}
                disabled={isSubjectEmpty || isBodyEmpty}
                sx={{ ':focus': { bgcolor: 'primary.main' } }}
            >
                Send Test Email
            </Button>
            <SendEmailModal open={openModal} setOpen={setOpenModal} />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <RewritePopOver />
            </Popover>
        </Stack>
    );
};

export default CampaignSquenceEmailBodyFooter;
