import React from "react";
import {Box, Paper, Stack} from "convertupleads-theme";
import Mapping from "./mapping/Mapping.tsx";
import StepperSidebar from "./StepperSidebar.tsx";
import Connection from "./connection/Connection.tsx";
import Configure from "./configure/Configure.tsx";
import {SPREADSHEET_IMPORT_STEPS} from "../../../../../../helpers/constant/coreConstants.ts";
import {useImportSpreadsheetContext} from "../importSpreadsheetReducer.tsx";
import Complete from "./complete/Complete.tsx";
import Details from "./details/Details.tsx";

const Connect = () => {
    const { activeImportStep } = useImportSpreadsheetContext();

    return(
        <Paper elevation={0} sx={{height: "calc(100vh - 312px)", m: 1, p: 2}}>
            <Stack direction={"row"} spacing={2} pt={0} pb={5} px={25}>
                <StepperSidebar/>
                <Box sx={{width: "60%"}}>
                    {
                        (()=>{
                            switch (activeImportStep){
                                case SPREADSHEET_IMPORT_STEPS.CONNECT:
                                    return <Connection/>;
                                case SPREADSHEET_IMPORT_STEPS.CONFIGURE:
                                    return <Configure/>;
                                case SPREADSHEET_IMPORT_STEPS.MAPPING:
                                    return <Mapping/>;
                                case SPREADSHEET_IMPORT_STEPS.DETAILS:
                                    return <Details/>;
                                case SPREADSHEET_IMPORT_STEPS.COMPLETE:
                                    return <Complete/>;
                            }
                        })()
                    }
                </Box>
            </Stack>
        </Paper>
    );
}

export default Connect;