import httpRequest from './api/httpRequest';
import { WithPagination } from '../interface/response.interface';
import { IFolder, IGetFoldersPayload } from '../state/features/campaignFolder/campaignFolder.interface';

class CampaignFolderService {
    getCampaignFolders(params: IGetFoldersPayload) {
        return httpRequest.get<WithPagination<IFolder[]>>('/campaigns/folders', params);
    }
    addCampaignFolder(params: IFolder) {
        return httpRequest.post<WithPagination<IFolder[]>>('/campaigns/folders', params);
    }
    updateCampaignFolder(params: IFolder) {
        return httpRequest.put<IFolder>(`/campaigns/folders/${params.uid}`, params);
    }
    deleteCampaignFolder(params: string) {
        return httpRequest.delete<string>(`/campaigns/folders/${params}`);
    }
}

export default new CampaignFolderService();
