import React, { useEffect } from 'react';
import CampaignsBody from './CampaignsBody';
import { Stack } from 'convertupleads-theme';
import SideBar from './sidebar/SideBar';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEmailCampaigns,
    getUserConnectedEmails,
    getUserEmailProvider,
    resetAllSelectedCampaigns,
    resetCloneCampaign,
    resetDeleteCampaign,
    resetUpdateCampaign
} from '../../../state/features/emailCampaign/emailCampaignSlice';
import { emailCampaignState } from '../../../state/features/emailCampaign/emailCampaignSelector';
import { campaignFolderState } from '../../../state/features/campaignFolder/campaignFolderSelector';

const Campaigns: React.FC = () => {
    const {
        isUpdateCampaignSuccess,
        isBulkUpdateCampaignSuccess,
        isDeleteCampaignSuccess,
        isCloneCampaignSuccess,
        pagination: { page, perPage },
        query
    } = useSelector(emailCampaignState);
    const { selectedFolder } = useSelector(campaignFolderState);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEmailCampaigns({ page, perPage, query, ...campaignStatus }));
        dispatch(getUserConnectedEmails());
        dispatch(getUserEmailProvider());
    }, []);

    useEffect(() => {

        if (isBulkUpdateCampaignSuccess === true || isDeleteCampaignSuccess === true) {
            dispatch(getEmailCampaigns({ page, perPage, query, ...campaignStatus }));
            dispatch(resetAllSelectedCampaigns());
        }
        else if (query !== undefined) {
            dispatch(getEmailCampaigns({ page:1, perPage, query, ...campaignStatus }));

        }

    }, [isBulkUpdateCampaignSuccess, query, isDeleteCampaignSuccess]);

    useEffect(() => {
        if (isUpdateCampaignSuccess === true) {
            dispatch(resetUpdateCampaign());
        }
        if (isCloneCampaignSuccess === true) {
            dispatch(resetCloneCampaign())
        }
        if (isDeleteCampaignSuccess === true) {
            dispatch(resetDeleteCampaign())
        }
    }, [isUpdateCampaignSuccess, isCloneCampaignSuccess, isDeleteCampaignSuccess])


    let campaignStatus: null | object = null;

    switch (selectedFolder) {
        case 'allCampaigns':
            campaignStatus = null;
            break;
        case 'favourite':
            campaignStatus = { isFavourite: 'YES' };
            break;
        case 'archive':
            campaignStatus = { status: 'ARCHIVE' };
            break;
        case 'trash':
            campaignStatus = { status: 'TRASH' };
            break;
        default:
            campaignStatus = { campaignFolderUid: selectedFolder };
    }

    return (
        <Stack direction={'row'} spacing={2}>
            <SideBar />
            <CampaignsBody />
        </Stack>
    );
};

export default Campaigns;
