import React, { useState, useRef, useEffect } from 'react';
import { AddIcon, Box, Button, Divider, Stack, Typography, useTheme } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../state/features/emailCampaign/emailCampaignSelector';
import {
    addCampaignSequence,
    deleteCampaignSequence,
    getCampaignSequenceStats,
    selectSequence
} from '../../state/features/emailCampaign/emailCampaignSlice';
import EachSequenceItem from './EachSequenceItem';
import CampaignScheduleModal from './emailCampaignBody/emaliSchedule/CampaignScheduleModal';
import { ICampaignSequence } from '../../state/features/emailCampaign/emailCampaign.interface';
import { CAMPAIGN_SEQUENCE_MESSAGE_TYPE, EMAIL_THREAD } from '../../helpers/constant/coreConstants';
import { CustomScrollbar } from '../common/CustomScrollBarStyle';
import CampaignSequenceSidebarSkeleton from './CampaignSequenceSidebarSkeleton';

const CampaignSequenceSideBar: React.FC = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { sequences, selectedCampaign, selectedSequence, isCampaignSequenceLoading } = useSelector(emailCampaignState);
    const [openCampaignScheduleModal, setCampaignScheduleModal] = useState<boolean>(false);
    const sequencesContainerRef = useRef<HTMLDivElement>(null); // Ref to sequences container

    useEffect(() => {
        // Scroll to the selected sequence when it changes
        if (sequencesContainerRef.current && selectedSequence !== null) {
            const selectedSequenceElement = sequencesContainerRef.current.querySelector(`[data-sequence-id="${selectedSequence.uid}"]`) as HTMLElement;
            if (selectedSequenceElement) {
                selectedSequenceElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [selectedSequence, sequences]);

    const handleCampaignScheduleModal = () => {
        setCampaignScheduleModal(true);
    };

    const handleSelectedSequence = (index: number, item: ICampaignSequence) => {
        let tempItem = { ...item };
        if (selectedCampaign && selectedCampaign.emailThreads === EMAIL_THREAD.SINGLE_THREAD) {
            tempItem.messageSubject = selectedCampaign.commonEmailSubject as string;
        }
        dispatch(getCampaignSequenceStats({ campaignSequenceUid: item.uid }));
        dispatch(selectSequence({ ...tempItem, index }));
    };

    const handleDeleteSequence = (index: number, item: ICampaignSequence) => {
        if (selectedCampaign) {
            dispatch(deleteCampaignSequence({ index: index, uid: item.uid }));
        }
    };

    const handleAddSequenceBetween = (index: number) => {
        if (selectedCampaign) {
            dispatch(addCampaignSequence({ index: index + 1, campaignUid: selectedCampaign.uid, messageType: CAMPAIGN_SEQUENCE_MESSAGE_TYPE.EMAIL }));
        }
    };

    const content = sequences?.map((item, i, arr) => (
        <div key={item.uid} data-sequence-id={item.uid}>
            <EachSequenceItem
                item={item}
                index={i}
                sequenceArray={arr}
                handleCampaignScheduleModal={handleCampaignScheduleModal}
                onSelectSequence={() => handleSelectedSequence(i, item)}
                onAddSequenceBetween={() => handleAddSequenceBetween(i)}
                onDelete={() => handleDeleteSequence(i, item)}
            />
        </div>
    ));

    const handleAddSequence = () => {
        if (selectedCampaign) {
            dispatch(addCampaignSequence({ index: sequences ? sequences.length : 1, campaignUid: selectedCampaign.uid, messageType: CAMPAIGN_SEQUENCE_MESSAGE_TYPE.EMAIL }));
        }
    };

    if (isCampaignSequenceLoading) {
        return <CampaignSequenceSidebarSkeleton />;
    }
    
    return (
        <Box
            width={'35%'}
            paddingRight={0}
            margin={3}
            overflow={'hidden'}
            position={'relative'}
            borderRadius={2}
            sx={{ background: theme.palette.other.outlinedBorder, minWidth: '350px' }}
        >
            <Stack>
                <Stack padding={3} pb={0} direction={'row'} sx={{ justifyContent: 'space-between' }} mb={2}>
                    <Typography variant='h6'>Sequences</Typography>
                    {(sequences !== null && sequences.length > 0) &&
                        <Button variant={'outlined'} startIcon={<AddIcon />} size='small' onClick={handleAddSequence} sx={{'&:focus': {background: 'transparent'}}}>
                            Add Step
                        </Button>
                    }
                </Stack>
                <Divider light />
                <CustomScrollbar sx={{ overflowY: 'auto', height: 'calc(100vh - 350px)', scrollBehavior: 'smooth' }} ref={sequencesContainerRef}>
                    <Stack paddingX={3}>
                        <Stack my={1}>
                            <Typography fontSize={12} color={'text.tertiary'} textAlign={'center'}>
                                Campaign Starts Here
                            </Typography>
                        </Stack>
                        {sequences !== null && content}

                        <Button variant='text' startIcon={<AddIcon color='primary' />} sx={{ mt: 2, '&:focus': {background: 'transparent'} }} onClick={handleAddSequence}>
                            Add step
                        </Button>
                    </Stack>
                </CustomScrollbar>

                <CampaignScheduleModal open={openCampaignScheduleModal} setOpen={setCampaignScheduleModal} />
            </Stack>
        </Box>
    );
};

export default CampaignSequenceSideBar;
