import React, { useEffect, useState } from "react";
import { Button, Divider, LoadingButton, ModalWithHeader, SaveIcon, Stack } from "convertupleads-theme";
import { CustomTextField } from "../../../bulkEmail/BulkEmailStyle";
import MultipleEmailVariationTextEditor from "./MultipleEmailVariationTextEditor";
import VariationEmailSubject from "./VariationEmailSubject";
import { ISequenceVariation } from "../../../../state/features/emailCampaign/emailCampaign.interface";
import { useDispatch, useSelector } from "react-redux";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector";
import { addSequenceVariation, resetAddSequenceVariation, resetDeleteSequenceVariation, resetUpdateSequenceVariation, updateSequenceVariation } from "../../../../state/features/emailCampaign/emailCampaignSlice";

interface IAddVariationModalProps {
  open: boolean;
  onClose: () => void;
  edit?: ISequenceVariation;
}
const AddOrEditVariationModal: React.FC<IAddVariationModalProps> = ({
  open,
  onClose,
  edit,
}) => {
  const [variantTitle, setVariantTitle] = useState(edit?.title || "");
  const [variantSubject, setVariantSubject] = useState(edit?.subject || "");
  const [variantBodyText, setVariantBodyText] = useState(edit?.body || "");

  const [variantTitleError, setVariantTitleError] = useState("");
  const [variantSubjectError, setVariantSubjectError] = useState("");
  const [variantBodyTextError, setVariantBodyTextError] = useState("");

  const { selectedSequence, isAddSequenceVariationsLoading, isAddSequenceVariationsSuccess, isUpdateSequenceVariationsLoading, isUpdateSequenceVariationsSuccess, isDeleteSequenceVariationsSuccess } = useSelector(emailCampaignState);

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const handleVariantTitle = (text: string) => {
    setVariantTitle(text);
    setVariantTitleError("");
  };

  const handleSubmit = () => {
    if (
      variantTitle === "" ||
      variantSubject === "" ||
      variantBodyText === ""
    ) {

      if(variantTitle === ""){
        setVariantTitleError("Variation Title is Required")
      }

      if(variantSubject === ""){
        setVariantSubjectError("Variation Subject is Required")
      }

      if(variantBodyText === ""){
        setVariantBodyTextError("Variation Body is Required")
      }

      return
    }

    if (edit) {
      // Update Variant
      dispatch(
        updateSequenceVariation({
          uid: edit?.uid as string,
          title: variantTitle,
          subject: variantSubject,
          body: variantBodyText,
        })
      );
      
    } else {
      // Add New Variant
      dispatch(
        addSequenceVariation({
          campaignSequenceUid: selectedSequence?.uid as string,
          title: variantTitle,
          subject: variantSubject,
          body: variantBodyText,
        })
      );

    }
  };

  useEffect(() => {
    if (isAddSequenceVariationsSuccess || isUpdateSequenceVariationsSuccess || isDeleteSequenceVariationsSuccess) {
        
        dispatch(resetAddSequenceVariation(undefined));
        dispatch(resetUpdateSequenceVariation(undefined));
        dispatch(resetDeleteSequenceVariation(undefined));
        if(!edit && isAddSequenceVariationsSuccess) {
          setVariantTitle("");
        }
        onClose();
    }
}, [isAddSequenceVariationsSuccess, isUpdateSequenceVariationsSuccess, isDeleteSequenceVariationsSuccess]);

const handleSubject = (editSubject: any) => {
  setVariantSubject(editSubject);
  setVariantSubjectError("")
}

const handleBody = (editSubject: any) => {
  setVariantBodyText(editSubject);
  setVariantBodyTextError("")
}
  return (
    <ModalWithHeader
      disableEnforceFocus
      title="Add Variation"
      width={1060}
      open={open}
      onClose={handleClose}
    >
      <>
        <Stack p={2} gap={2}>
          <Stack gap={0.5}>
            <CustomTextField
              label="Variation Title"
              size="small"
              value={variantTitle}
              fullWidth
              onChange={(e) => {handleVariantTitle(e.target.value) }}
              error={!!variantTitleError}
              helperText={variantTitleError}
            />
          </Stack>

          <VariationEmailSubject
            showError={!!variantSubjectError}
            editSubject={edit?.subject}
            onChange={handleSubject}
          />

          <Stack gap={0.5}>
            {/* <Typography color={"text.secondary"} variant={"body2"}>
              Variation text
            </Typography> */}
            <MultipleEmailVariationTextEditor
              showError={!!variantBodyTextError}
              editBody={edit?.body}
              onChange={handleBody}
            />
          </Stack>
        </Stack>

        <Divider light />
        <Stack direction={"row"} justifyContent={"flex-end"} p={2} spacing={2}>
          <Button
            variant="tonal"
            color={"inherit"}
            size="large"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            loading={isAddSequenceVariationsLoading || isUpdateSequenceVariationsLoading}
            startIcon={<SaveIcon />}
            // loading={true}
            position="start"
            sx={{'& .MuiButton-startIcon': {height: 20, width: 20}, '&:focus': {bgcolor: 'primary.main'}}}
          >
            <span>{edit ? "Update Variation" : "Add Variation"}</span>
          </LoadingButton>

          {/* <Button sx={{ '&:focus': {bgcolor: 'primary.main'}}} disabled={isAddSequenceVariationsLoading || isUpdateSequenceVariationsLoading} size="large" onClick={handleSubmit}>
            {edit ? "Update Variant" : "Add Variant"}
          </Button> */}
        </Stack>
      </>
    </ModalWithHeader>
  );
};

export default AddOrEditVariationModal;
