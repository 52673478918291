import React from "react";
import { Paper, Skeleton, Stack } from "convertupleads-theme";

const CampaignSequenceBodySkeleton: React.FC = () => {
  return (
    <Paper elevation={0} variant="outlined" sx={{ width: '100%' }}>
        <Stack gap={1} p={2} width={"100%"}>
            <Skeleton variant="rounded" width={"180px"} height={"30px"} />
            <Skeleton variant="rounded" width={"180px"} />
            <Skeleton variant="rounded" width={"100%"} height={"77px"} />
            <Skeleton variant="rounded" width={"100%"} height={"20px"} />
            <Stack gap={1} pt={2} direction={"row"} width={"100%"}>
                <Skeleton variant="rounded" width={"80%"} height={"38px"} />
                <Skeleton variant="rounded" width={"20%"} height={"38px"} />
            </Stack>
            <Skeleton variant="rounded" width={"100%"} height={"300px"} />
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                my={2}
                width={"100%"}
            >
                <Skeleton variant="rounded" width={"175px"} height={"38px"} />
                <Skeleton variant="rounded" width={"140px"} height={"38px"} />
            </Stack>

            <Skeleton variant="rounded" width={"100%"} height={"77px"} />
            <Skeleton variant="rounded" width={"100%"} height={"20px"} />
        </Stack>
    </Paper>
  );
};

export default CampaignSequenceBodySkeleton;
