/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Stack, Typography } from "convertupleads-theme";

const EmptyComponentV2: React.FC = () => {
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        background: "white",
        borderRadius: "8px",
        height: "calc(100vh - 224px)",
      }}
    >
      <img
        width={401}
        height={286}
        src={
          "https://d24gujj67p1uc1.cloudfront.net/assets/bulk-emails/images/releases/1.0.0/Empty Campaign.png"
        }
        alt="Empty Data Image"
      />
      <Stack>
        <Typography
          variant="h5"
          textAlign={"center"}
          fontWeight={500}
          color={"text.secondary"}
        >
          No data found
        </Typography>
      </Stack>
    </Stack>
  );
};

export default EmptyComponentV2;
