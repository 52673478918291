import React, { useState } from 'react';
import { Box, Button, Chip, DeleteModal, Divider, IconButton, MailFilledIcon, Paper, Select, Stack, Typography } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { addCampaignEmail, deleteCampaignEmail } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import EmailConnectPopup from "../../../common/EmailConnectPopup";
import { CustomMenuItem } from '../../BulkEmailStyle';

const MenuProps = {
    PaperProps: {
        style: {
            paddingLeft: '8px',
        },
    },
};

const EmailSender: React.FC = () => {
    const [deletingItemValue, setDeletingItemValue] = useState('');
    const { connectedEmails, selectedCampaign } = useSelector(emailCampaignState);
    const dispatch = useDispatch();
    const userEmails = connectedEmails.userConnectedEmails.map((email: { uid: string; email: string; }) => ({ value: email.uid, label: email.email }));
    const selectedEmails = connectedEmails.campaignConnectedEmails.map((email: { uid: string; userConnectedEmail: string; }) => ({ value: email.uid, label: email.userConnectedEmail }));
    const [showEmailConnectPopup, setShowEmailConnectPopup] = useState(false);

    const handleEmailSelect = (selectedOptions: any) => {
        if (selectedCampaign) {
            dispatch(addCampaignEmail({ campaignUid: selectedCampaign.uid as string, userConnectedEmailUid: selectedOptions.target.value }));
        }
    };

    const handleDelete = (uid: string) => {
        if (selectedCampaign) {
            dispatch(deleteCampaignEmail({ campaignUid: selectedCampaign.uid as string, connectedEmailUid: uid }));
        }
        setDeletingItemValue('');
    };

    const filteredEmail = userEmails
    .filter((item) => !selectedEmails.find((selected) => selected.label === item.label));

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: '0px 20px', mt: 2.5 }}>
            <Box width={960}>
                <Stack direction={'row'} justifyContent={'space-between'} spacing={1} sx={{ cursor: 'pointer', my: 2 }}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <IconButton variant={'rounded'} size={'medium'} color={'primary'} sx={{ backgroundColor: 'primary.light' }}>
                            <MailFilledIcon />
                        </IconButton>
                        <Stack flex={1}>
                            <Typography variant={'subtitle1'} color={'text.secondary'}>
                                <b>Email Accounts</b>
                            </Typography>
                            <Typography variant={'caption'} color={'text.secondary'}>
                                Select the email accounts you want to use for this campaign
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Typography variant='caption' color={'text.secondary'}>Need more emails?</Typography>
                        <Button sx={{fontSize: 12}} variant='noPadding' size='small' onClick={() => { setShowEmailConnectPopup(true); }}>Click Here</Button>
                    </Stack>
                </Stack>

                <Divider light />

                <Stack spacing={1} pt={2} pl={'48px'} pb={'18px'}>
                    <Select
                        value={0}
                        onChange={handleEmailSelect}
                        MenuProps={MenuProps}
                        fullWidth
                    >   
                        {filteredEmail.length > 0 ? (
                            <CustomMenuItem value={0} disabled>
                                Select email account(s)
                            </CustomMenuItem>
                            ) : (
                            <CustomMenuItem value={0} disabled>
                                No Email Available
                            </CustomMenuItem>
                        )}
  
                        {filteredEmail
                            ?.map((item) => (
                                <CustomMenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </CustomMenuItem>
                            ))}
                    </Select>

                    <Stack direction={'row'} gap={1} flexWrap={'wrap'}>
                        {selectedEmails.map((item, _, arr) => (
                            <Chip color='primary' key={item.value} label={item.label} onDelete={arr.length > 1 ? () => setDeletingItemValue(item.value) : undefined} size='small' sx={{ height: 22 }} />
                        ))}
                    </Stack>
                </Stack>
            </Box>

            <DeleteModal
                open={deletingItemValue !== ''}
                onClose={() => setDeletingItemValue('')}
                title='Are you sure, You want to delete this Email?'
                warningContent='Removing this sender, will impact the contacts already connected with this email'
                onConfirm={() => handleDelete(deletingItemValue)}
                sx={{
                    '& .MuiPaper-root.MuiPaper-elevation': { width: 400 },
                    '& .MuiButton-tonalInherit:focus': { bgcolor: 'other.outlinedBorder' },
                    '& .MuiButton-colorError:focus': { bgcolor: 'error.main' }
                }}
            />

            <EmailConnectPopup
                open={showEmailConnectPopup}
                email={''}
                isCloseable={true}
                onClose={() => { setShowEmailConnectPopup(false); }}
                state={selectedCampaign?.uid}
            />
        </Paper>
    );
};

export default EmailSender;