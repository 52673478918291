import React from 'react';
import { Button, CloseIcon, CopyIcon, Divider, IconButton, Stack, Tooltip, Typography, useTheme } from 'convertupleads-theme';
import { CustomScrollbar } from '../../../common/CustomScrollBarStyle';
import { useDispatch, useSelector } from 'react-redux';
import { updateCampaignSequence, updateEmailThread } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { EMAIL_THREAD } from '../../../../helpers/constant/coreConstants';
import { IEmailThreadPayload } from '../../../../state/features/emailCampaign/emailCampaign.interface';
import { CustomTextField } from '../../../bulkEmail/BulkEmailStyle';
import ReadOnlyEditor from '../../../common/ReadOnlyEditor';
import { showNotification } from '../../../../helpers/util/functions';

interface TemplateLibraryRightSideProps {
  selectedTemplate: { emailTemplateId: string; title: string; subject: string; body: string; };
  handleClose: () => void;
}

const TemplateLibraryRightSide: React.FC<TemplateLibraryRightSideProps> = ({ selectedTemplate, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { selectedSequence, selectedCampaign } = useSelector(emailCampaignState);

  const { subject, body, title } = selectedTemplate || {};

  const handleSubmit = () => {
    if (selectedSequence) {
      if (selectedCampaign?.emailThreads === EMAIL_THREAD.SINGLE_THREAD) {
        let payload: IEmailThreadPayload = {
          campaignUid: selectedCampaign?.uid as string,
          emailThreads: EMAIL_THREAD.SINGLE_THREAD,
          commonEmailSubject: subject
        };
        dispatch(updateEmailThread(payload));
        dispatch(updateCampaignSequence({ messageBody: body, uid: selectedSequence.uid, index: selectedSequence.index }));
      } else {
        dispatch(updateCampaignSequence({ messageSubject: subject, uid: selectedSequence.uid, index: selectedSequence.index }));
        dispatch(updateCampaignSequence({ messageBody: body, uid: selectedSequence.uid, index: selectedSequence.index }));
      }

      handleClose();
    }
  }

  const handleCopy = () => {
    const textToCopy = body;

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        showNotification('SUCCESS', 'Text copied to clipboard');
      })
      .catch(err => {
        showNotification('FAIL', 'Something went wrong');

        console.error('Failed to copy text: ', err);
      });

  };

  return (
    <Stack width={660}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        paddingX={2}
        sx={{ paddingY: "12px" }}
      >
        <Typography
          variant={"subtitle1"}
          color={"text.primary"}
          sx={{
            width: "500px",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: 'hidden',
            cursor: 'pointer'
          }}
        >
          {title}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ background: theme.palette.other.divider }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />

      <Stack height={"100%"} justifyContent={"space-between"}>
        <Stack padding={2}>
          <Stack direction={"row"} gap={1}>
            <CustomTextField
              value={subject}
              size={"small"}
              placeholder={"Email Subject"}
              fullWidth
              sx={{
                "& .MuiInputBase-input": { paddingBlock: "9px !important" },
              }}
              contentEditable={false}
            />
          </Stack>
          <Stack
            padding={2}
            borderRadius={2}
            mt={2}
            sx={{ background: theme.palette.other.outlinedBorder }}
          >
            {/* <CustomScrollbar sx={{ maxHeight: 465, "& p": { margin: 0 } }}> */}
            <ReadOnlyEditor
              body={body}
              height={470}
            />
            {/* </CustomScrollbar> */}
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"end"}
          spacing={1}
          padding={2}
        >
          <Button
            variant={"outlined"}
            startIcon={<CopyIcon />}
            size="large"
            sx={{ ":focus": { bgcolor: "transparent" } }}
            onClick={handleCopy}
          >
            Copy
          </Button>

          <Button
            variant={"contained"}
            size="large"
            sx={{ ":focus": { bgcolor: "primary.main" } }}
            onClick={handleSubmit}
          >
            Use Template
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TemplateLibraryRightSide;
