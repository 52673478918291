import { Chart2Icon, ListItemButton, ListItemIcon, ListItemText, Typography } from 'convertupleads-theme';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IEmailCampaign } from '../../../../state/features/emailCampaign/emailCampaign.interface';
interface IViewReportComponent {
    onClose?: () => void;
    campaign: IEmailCampaign;
}
const ViewReportComponent: React.FC<IViewReportComponent> = ({ campaign }) => {
    const navigate = useNavigate();
    return (
        <div>
            <ListItemButton onClick={() => navigate(`/bulk-email/report/${campaign?.uid}`, { replace: true })}>
                <ListItemIcon>
                    <Chart2Icon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText>
                    <Typography color={'text.primary'} variant='body2' fontWeight={400}>
                        View Report
                    </Typography>
                </ListItemText>
            </ListItemButton>
        </div>
    );
};

export default ViewReportComponent;
