import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CampaignIndex from "./components/bulkEmail/CampaignIndex.tsx";
import Report from './components/bulkEmail/newEmailCampaign/Analytics/Index.tsx';
import ShareCampaignLink from './components/bulkEmail/campaigns/campaignContent/ShareCampaignLink.tsx';
import NewEmailCampaignRedirect from './components/bulkEmail/campaigns/campaignContent/NewEmailCampaignRedirect.tsx';

const router = createBrowserRouter([
    {
        path: '/bulk-email',
        element: <CampaignIndex />,
    },
    {
        path: '/bulk-email/report',
        element: <Report />,
    },
    {
        path: '/bulk-email/report/:campaignUid',
        element: <Report />,
    },
    {
        path: '/bulk-email/copy/:campaignUid',
        element: <ShareCampaignLink />,
    },
    {
        path: '/bulk-email/new-campaign',
        element: <NewEmailCampaignRedirect />,
    },
    {
        path: '*',
        element: 'error page',
    },
]);

function App() {
    return (<RouterProvider router={router} />);
}

export default App;
