import React, {useEffect, useRef, useState} from "react";
import {
    CheckIcon,
    CloseIcon,
    IconButton,
    Paper,
    RecordIcon,
    Stack,
    StopIcon,
    Typography,
    UploadIcon
} from "convertupleads-theme";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import {showNotification} from "../../../../../helpers/util/functions.tsx";
import emailCampaignService from "../../../../../services/emailCampaign.service.ts";

const pad = (val: number) => {
    const string = val + "";
    if (string.length < 2) {
        return "0" + string;
    } else {
        return string;
    }
}

let totalSeconds = 0;

interface IProps {
    selectedAudioFileUri: string |null;
    handleSelectAudioFileUri: (audioUri: string) => void;
    handleSelectAudioFileType: (fileType: string) => void;
    uploadingServer: boolean;
    setUploadingServer: (val: boolean) => void;
}

const AudioRecorder: React.FC<IProps> = ({ selectedAudioFileUri, handleSelectAudioFileUri, uploadingServer, setUploadingServer, handleSelectAudioFileType }) => {
    const [recording, setRecording] = useState(false);
    const [minutes, setMinutes] = useState("00");
    const [seconds, setSeconds] = useState("00");
    const [intervalNode, setIntervalNode] = useState<NodeJS.Timeout | null>(null);
    const [recordedAudioUrl, setRecordedAudioUrl] = useState<string | null>(null);
    const recorderRef = useRef<RecordRTC | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const videoStreamRef = useRef<MediaStream | null>(null);
    const [isNewFile, setIsNewFile] = useState(false);
    const [audioFile, setAudioFile] = useState<File | null>(null);

    useEffect(() => {
        setRecordedAudioUrl(selectedAudioFileUri);
    }, [selectedAudioFileUri]);

    const calculateTime = () => {
        let interval = setInterval(() => {
            ++totalSeconds;
            setSeconds(pad(totalSeconds % 60));
            setMinutes(pad(parseInt(String(totalSeconds / 60))));

            if (totalSeconds > 180) {
                stopRecording();
            }
        }, 1000);

        setIntervalNode(interval);
    };

    const startRecording = () => {
        if (recordedAudioUrl){
            setRecordedAudioUrl("");
        }
        videoStreamRef.current = null;
        totalSeconds = 0;
        setMinutes("00");
        setSeconds("00");
        calculateTime();

        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            const recorder = new RecordRTC(stream, { type: "audio/webm" as "audio", mimeType: "audio/webm", recorderType: StereoAudioRecorder, audioBitsPerSecond: 128000 });
            recorderRef.current = recorder;
            recorder.startRecording();
            setRecording(true);
            videoStreamRef.current = stream;
        });

    };

    const stopRecording = () => {
        if (totalSeconds < 5) {
            showNotification("error","Audio duration must be between 5 seconds to 3 minutes");
            return;
        }
        clearInterval(intervalNode as NodeJS.Timeout);
        if (recorderRef.current) {
            recorderRef.current.stopRecording(async () => {
                const audioBlob = recorderRef.current?.getBlob();
                const audioUrl = URL.createObjectURL(audioBlob as Blob);
                setRecordedAudioUrl(audioUrl);
                setRecording(false);
                videoStreamRef.current?.getTracks().forEach((track) => (track.stop()));

                let mimeType = "audio/mp3";

                let file = new File([audioBlob as Blob], "RecordRTC-audio.mp3", {
                    type: mimeType
                });
                totalSeconds = 0;
                setIsNewFile(true);
                setAudioFile(file);
            });
        }
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        const allowedFileTypes = ["mp3", "wav", "m4a"];
        const maxFileSizeInBytes = 5 * 1024 * 1024;

        if (file) {
            const fileExtension = file.name.split(".").pop()?.toLowerCase();

            if (fileExtension && allowedFileTypes.includes(fileExtension) && file.size <= maxFileSizeInBytes) {
                const audioUrl = URL.createObjectURL(file);
                setRecordedAudioUrl(audioUrl);
                setIsNewFile(true);
                setAudioFile(file);
            } else {
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }

                if (fileExtension && !allowedFileTypes.includes(String(fileExtension))) {
                    showNotification("error", "Invalid file type. Please select a valid file type.");
                    return;
                }
                if (file.size > maxFileSizeInBytes) {
                    showNotification("error", "File size exceeds the limit. Please select a file under 5MB.");
                    return;
                }
            }
        }
    };

    const uploadAudioFile = async () => {
        try{
            if (audioFile){
                setUploadingServer(true);
                const formData = new FormData();
                formData.append("audioFile", audioFile);

                const resp = await emailCampaignService.uploadTriggerAudioFile(formData);

                if (resp && resp.success && resp.data){
                    handleSelectAudioFileUri(resp.data.file_url);
                    setIsNewFile(false);
                    handleSelectAudioFileType(audioFile.type);
                }
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        }catch (err){
            showNotification("error", "Unable to upload the audio file");
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }finally {
            setUploadingServer(false);
        }
    };

    const cleanFile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setRecordedAudioUrl("");
        setIsNewFile(false);
    }

  return(
      <Stack ml={"58px"} mt={3}>
          <Paper sx={{ padding: 2 }}>
              <Stack spacing={2}>
                  <Typography variant={"body2"}>Record or upload a audio</Typography>
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                      <Stack direction={"row"} spacing={3} sx={{ marginBottom: isNewFile ? "36px" : "0px" }}>
                          <Stack alignItems={"center"} spacing={1}>
                              <IconButton disabled={recording} onClick={startRecording} sx={{
                                  height: "50px",
                                  width: "50px",
                                  backgroundColor: "primary.light",
                                  "&:focus, &:disabled": {backgroundColor: "primary.light"}
                              }} color={"primary"}>
                                  <RecordIcon/>
                              </IconButton>
                              <Typography variant={"caption"} fontWeight={"bold"}>Record</Typography>
                          </Stack>
                          <Stack alignItems={"center"} spacing={1}>
                              <IconButton disabled={!recording} onClick={stopRecording} sx={{
                                  height: "50px",
                                  width: "50px",
                                  backgroundColor: "primary.light",
                                  "&:focus, &:disabled": {backgroundColor: "primary.light"}
                              }} color={"primary"}>
                                  <StopIcon/>
                              </IconButton>
                              <Typography variant={"caption"} fontWeight={"bold"}>Stop</Typography>
                          </Stack>
                          <Stack alignItems={"center"} spacing={1}>
                              <IconButton onClick={handleUploadClick} disabled={recording} sx={{
                                  height: "50px",
                                  width: "50px",
                                  backgroundColor: "primary.light",
                                  "&:focus, &:disabled": {backgroundColor: "primary.light"}
                              }} color={"primary"}>
                                  <UploadIcon/>
                              </IconButton>
                              <Typography variant={"caption"} fontWeight={"bold"}>Upload</Typography>
                          </Stack>
                      </Stack>
                      {
                          recording && !recordedAudioUrl &&
                          <Typography variant={"h4"} color={"red"}>{minutes}:{seconds}</Typography>
                      }
                      {
                          !recording && recordedAudioUrl &&
                          <Stack>
                              <audio style={{width: "440px", height: "50px",  marginBottom: isNewFile ? "0px" : "26px"}} controls src={recordedAudioUrl}></audio>
                              {
                                  isNewFile &&
                                  <Stack>
                                      <Typography variant={"caption"} textAlign={"center"} mt={1}>Are you happy with your audio?</Typography>
                                      <Stack direction={"row"} justifyContent={"center"} spacing={4}>
                                          <IconButton disabled={uploadingServer} onClick={uploadAudioFile}>
                                              <CheckIcon/>
                                          </IconButton>
                                          <IconButton disabled={uploadingServer} onClick={cleanFile}>
                                              <CloseIcon/>
                                          </IconButton>
                                      </Stack>
                                  </Stack>
                              }
                          </Stack>
                      }
                      <input
                          type="file"
                          ref={fileInputRef}
                          style={{display: "none"}}
                          onChange={handleFileChange}
                      />
                  </Stack>
                  <Typography variant={"caption"}>
                      Audio duration must be between 5 seconds to 3 minutes
                  </Typography>
                  {
                      uploadingServer &&
                      <Typography color={"red"} variant={"caption"}>
                          Wait while uploading the audio..
                      </Typography>
                  }
              </Stack>
          </Paper>
      </Stack>
  );
};

export default AudioRecorder;