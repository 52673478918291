import { useEffect, useRef } from "react";

const useDidUpdateEffect = (fn: () => void, inputs: any[]) => {
    const isMountingRef = useRef<boolean>(false);

    useEffect(() => {
        isMountingRef.current = true;
    }, []);

    useEffect(() => {
        if (!isMountingRef.current) {
            return fn();
        } else {
            isMountingRef.current = false;
        }
    }, inputs);
};

export default useDidUpdateEffect;