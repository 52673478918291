import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCampaign } from "../../../../state/features/emailCampaign/emailCampaignSlice";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "convertupleads-theme";

const NewEmailCampaignRedirect: React.FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isAddCampaignSuccess } = useSelector(emailCampaignState);

  useEffect(() => {
    dispatch(addCampaign({ title: "New Campaign" }));
  }, [dispatch]);

  if (isAddCampaignSuccess) {
    navigate("/bulk-email", { replace: true });
  }

  return (
    <Stack
      height={"calc(100vh - 100px)"}
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="h3" textAlign={"center"}>
        Creating new campaign. Please wait...
      </Typography>
    </Stack>
  );
};

export default NewEmailCampaignRedirect;
