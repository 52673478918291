import React, {useRef, DragEvent, useState} from 'react';
import {
    Paper,
    Stack,
    Typography
} from 'convertupleads-theme';
import {useImportCsvContext} from "../importCsvReducer.tsx";
import {UploadIcon} from "../../../../../../assets/Icons.tsx";

const Upload: React.FC = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const {
        handleGoNextStep,
        handleFileUpload
    } = useImportCsvContext();

    const handleClick = () => {
        inputRef.current?.click();
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const droppedFiles = Array.from(e.dataTransfer.files);
        handleFileUpload(droppedFiles);
    };

    return (
        <Stack spacing={4}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant={'h5'} color={'text.primary'}>
                    Upload
                </Typography>
            </Stack>

            <Paper elevation={0} variant='outlined' sx={{
                borderRadius: '8px',
                height: '150px',
                backgroundImage: `repeating-linear-gradient(0deg, #006DF5, #006DF5 10px, transparent 10px, transparent 15px),
                            repeating-linear-gradient(90deg, #006DF5, #006DF5 10px, transparent 10px, transparent 15px),
                            repeating-linear-gradient(180deg, #006DF5, #006DF5 10px, transparent 10px, transparent 15px),
                            repeating-linear-gradient(270deg, #006DF5, #006DF5 10px, transparent 10px, transparent 15px)`,
                borderImage: `repeating-linear-gradient(0deg, #006DF5, #006DF5 10px, transparent 10px, transparent 20px)`,
                backgroundPosition: `0 0, 0 0, 100% 0, 0 100%`,
                backgroundSize: `1.5px 100%,100% 1.5px,1.5px 100%,100% 1.5px`,
                backgroundRepeat: `no-repeat`,
                cursor: 'pointer',
                opacity: isDragging ? 0.8 : 1
            }} onClick={handleClick}  onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                {
                    isDragging ?
                        <Stack direction={'column'} width={'100%'} alignItems={'center'} height={'100%'}
                               justifyContent={'center'}>
                            <UploadIcon/>
                            <Typography color={'primary'} variant={'body2'} fontWeight={'bold'}>Drop the file here</Typography>
                        </Stack> :
                        <Stack direction={'column'} width={'100%'} alignItems={'center'} height={'100%'}
                               justifyContent={'center'}>
                            <UploadIcon/>
                            <input
                                ref={inputRef}
                                type="file"
                                accept=".csv"
                                style={{ display: 'none' }}
                                onChange={(event)=>{ handleFileUpload(event.target.files) }}
                            />
                            <Typography color={'primary'} variant={'body2'} fontWeight={'bold'}>Click here to upload or drag &
                                drop file here</Typography>
                            <Typography variant={'caption'}>only . csv files are accepted - Maximum 20,000 leads</Typography>
                        </Stack>
                }
            </Paper>
        </Stack>
    );
};

export default Upload;