import React from 'react';
import {
    AIFilledIcon,
    AddIcon,
    ArrowDropDownFilledIcon,
    Button,
    ButtonGroup,
    Checkbox,
    DeleteFilledIcon,
    Divider,
    DrawerWithHeader,
    EditFilledIcon,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Stack,
    Typography,
    useTheme,
} from 'convertupleads-theme';
import EditVariationDrawer from './EditVariationDrawer';

interface ReWriteDrawerProps {
    state: boolean;
    toggleDrawer: () => void;
}

const ReWriteDrawer: React.FC<ReWriteDrawerProps> = ({ state, toggleDrawer }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [state1, setState1] = React.useState<boolean>(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawerForRewiteWithAi = () => {
        setState1(() => !state1);
        console.log('-----------------', state1);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const theme = useTheme();
    return (
        <>
            <DrawerWithHeader
                sx={{ position: 'relative', zIndex: '1399' }}
                width={600}
                anchor={'right'}
                open={state}
                onClose={toggleDrawer}
                header={'Written By AI'}
            >
                <ButtonGroup
                    variant={'outlined'}
                    sx={{ width: '185px', position: 'absolute', right: 62, top: 14 }}
                    aria-label='contained button group'
                >
                    <Button color='secondary' startIcon={<AIFilledIcon />} size='small'>
                        Rewrite with AI
                    </Button>
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        aria-describedby={id}
                        onClick={handleClick}
                        sx={{ background: '#ffffff' }}
                    >
                        <ArrowDropDownFilledIcon color='secondary' />
                    </Button>
                </ButtonGroup>
                <Stack padding={2} gap={2}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Checkbox defaultChecked color='primary' size='small' sx={{ padding: 0 }} />
                        <Typography variant='body2' color={'text.secondary'}>
                            {' '}
                            Variations
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Checkbox defaultChecked color='primary' size='small' sx={{ padding: 0 }} />
                        <Stack width={500} padding={2} borderRadius={2} sx={{ background: theme.palette.secondary.light }}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <AIFilledIcon color='secondary' sx={{ fontSize: '18px' }} />
                                    <Typography variant='body2' color={'secondary'}>
                                        {' '}
                                        Variations
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <IconButton color={'secondary'} onClick={toggleDrawerForRewiteWithAi} sx={{ padding: 0 }}>
                                        <EditFilledIcon sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                    <IconButton color={'secondary'} sx={{ padding: 0 }}>
                                        <DeleteFilledIcon sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Stack width={'380px'}>
                                <Typography variant='body1' color={'text.primary'} mt={'10px'}>
                                    Grow Your Reach with Email
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color={'text.secondary'}
                                    sx={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textWrap: 'wrap',
                                    }}
                                >
                                    Dear [Customer] We hope this email finds you well. We are thrilled to present you with an exclusive
                                    offer that you won{"'"}t want to miss. For a limited time we are offering a special discount of [X]% off
                                    on our [product/service].
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Checkbox defaultChecked color='primary' size='small' sx={{ padding: 0 }} />
                        <Stack width={500} padding={2} borderRadius={2} sx={{ background: theme.palette.secondary.light }}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <AIFilledIcon color='secondary' sx={{ fontSize: '18px' }} />
                                    <Typography variant='body2' color={'secondary'}>
                                        {' '}
                                        Variations
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <IconButton color={'secondary'} onClick={toggleDrawerForRewiteWithAi} sx={{ padding: 0 }}>
                                        <EditFilledIcon sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                    <IconButton color={'secondary'} sx={{ padding: 0 }}>
                                        <DeleteFilledIcon sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Typography variant='body1' color={'text.primary'} mt={'10px'}>
                                Grow Your Reach with Email
                            </Typography>
                            <Typography
                                variant='body2'
                                color={'text.secondary'}
                                sx={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textWrap: 'wrap',
                                }}
                            >
                                Dear [Customer] We hope this email finds you well. We are thrilled to present you with an exclusive offer
                                that you won{"'"}t want to miss. For a limited time we are offering a special discount of [X]% off on our
                                [product/service].
                            </Typography>
                        </Stack>
                    </Stack>
                    <Popover
                        sx={{ top: '10px' }}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <List component='div' aria-label='main mailbox folders'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AddIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography color={'text.secondary'} variant='body2' fontWeight={400}>
                                        Add more variations
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AIFilledIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography color={'text.secondary'} variant='body2' fontWeight={400}>
                                            Regenerate All
                                        </Typography>
                                    </Stack>
                                </ListItemText>
                            </ListItemButton>
                        </List>
                    </Popover>
                </Stack>
                <EditVariationDrawer state={state1} toggleDrawer={toggleDrawerForRewiteWithAi} />

                <Stack position={'absolute'} bottom={0} sx={{ width: '100%' }}>
                    <Divider />
                    <Stack direction={'row'} justifyContent={'end'} spacing={1} paddingX={3} paddingY={2}>
                        <Button variant={'tonal'} color={'inherit'} onClick={toggleDrawer} size='large'>
                            Cancel
                        </Button>

                        <Button variant={'contained'} size='large' sx={{ fontWeight: '500' }}>
                            Confirm
                        </Button>
                    </Stack>
                </Stack>
            </DrawerWithHeader>
        </>
    );
};

export default ReWriteDrawer;
