import React from "react";
import {SvgIcon} from "convertupleads-theme";

export const PersonRemoveIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M14 8c0-2.21-1.79-4-4-4S6 5.79 6 8s1.79 4 4 4 4-1.79 4-4m3 2v2h6v-2zM2 18v2h16v-2c0-2.66-5.33-4-8-4s-8 1.34-8 4"></path>
    </SvgIcon>
);

export const PersonAddIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M13 8c0-2.21-1.79-4-4-4S5 5.79 5 8s1.79 4 4 4 4-1.79 4-4m2 2v2h3v3h2v-3h3v-2h-3V7h-2v3zM1 18v2h16v-2c0-2.66-5.33-4-8-4s-8 1.34-8 4"></path>
    </SvgIcon>
);

export const MailUnsubscribeIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M18.5 13c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5m2 4h-4v-1h4zm-6.95 0c-.02-.17-.05-.33-.05-.5 0-2.76 2.24-5 5-5 .92 0 1.76.26 2.5.69V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2zM12 10.5 5 7V5l7 3.5L19 5v2z"></path>
    </SvgIcon>
);

export const ThumbsUpIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"></path>
        <path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"></path>
    </SvgIcon>
);

interface IconsProps {
    height?: number;
    width?: number;
    color?: string;
    opacity?: number;
}

export const DaysIcon: React.FC<IconsProps> = ({
    height = 24,
    width = 24,
    color = '#006DF5'
}) => {
    return (
      <svg
        fill="none"
        height={height}
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="5" />
        <line x1="12" x2="12" y1="1" y2="3" />
        <line x1="12" x2="12" y1="21" y2="23" />
        <line x1="4.22" x2="5.64" y1="4.22" y2="5.64" />
        <line x1="18.36" x2="19.78" y1="18.36" y2="19.78" />
        <line x1="1" x2="3" y1="12" y2="12" />
        <line x1="21" x2="23" y1="12" y2="12" />
        <line x1="4.22" x2="5.64" y1="19.78" y2="18.36" />
        <line x1="18.36" x2="19.78" y1="5.64" y2="4.22" />
      </svg>
    );
};

export const IntervalTimeIcon: React.FC<IconsProps> = ({
    height = 24,
    width = 24,
    color = '#006DF5'
}) => {
    return (
        <svg width={height} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.24 7.76C15.07 6.59 13.54 6 12 6V12L7.76 16.24C10.1 18.58 13.9 18.58 16.25 16.24C18.59 13.9 18.59 10.1 16.24 7.76ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill={color}/>
        </svg>
    );
};

export const EmailCheckedIcon: React.FC<IconsProps> = ({
    height = 16,
    width = 16,
    color = '#A352C2'
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00065 12.6665C8.00065 10.0865 10.0873 7.99984 12.6673 7.99984C13.3873 7.99984 14.0607 8.1665 14.6673 8.45317V3.99984C14.6673 3.2665 14.0673 2.6665 13.334 2.6665H2.66732C1.93398 2.6665 1.33398 3.2665 1.33398 3.99984V11.9998C1.33398 12.7332 1.93398 13.3332 2.66732 13.3332H8.05398C8.02065 13.1132 8.00065 12.8932 8.00065 12.6665ZM2.66732 3.99984L8.00065 7.33317L13.334 3.99984V5.33317L8.00065 8.6665L2.66732 5.33317V3.99984ZM11.5607 14.6665L9.20065 12.3065L10.1407 11.3665L11.554 12.7798L14.3807 9.95317L15.334 10.8932L11.5607 14.6665Z" fill={color} />
        </svg>
    );
};

export const EmailMarkUnreadIcon: React.FC<IconsProps> = ({
    height = 16,
    width = 16,
    color = '#6258FF'
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.554 4.66683H10.8207L7.00065 2.66683L2.66732 4.9335V11.3335C1.93398 11.3335 1.33398 10.7335 1.33398 10.0002V4.78016C1.33398 4.42683 1.54732 4.0535 1.86732 3.88683L7.00065 1.3335L12.0273 3.88683C12.314 4.04016 12.514 4.3535 12.554 4.66683ZM13.334 5.3335H4.66732C3.93398 5.3335 3.33398 5.9335 3.33398 6.66683V12.6668C3.33398 13.4002 3.93398 14.0002 4.66732 14.0002H13.334C14.0673 14.0002 14.6673 13.4002 14.6673 12.6668V6.66683C14.6673 5.9335 14.0673 5.3335 13.334 5.3335ZM13.334 7.78016L9.00065 10.0002L4.66732 7.78016V6.66683L9.00065 8.88683L13.334 6.66683V7.78016Z" fill={color}/>
        </svg>
    );
};

export const ContactMailIcon: React.FC<IconsProps> = ({
    height = 16,
    width = 16,
    color = '#006DF5',
    opacity = .5,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3265_21935)">
                <path d="M13.625 5.5V4.875L11.75 6.125L9.875 4.875V5.5L11.75 6.75L13.625 5.5ZM14.25 2.375H1.75C1.0625 2.375 0.5 2.9375 0.5 3.625V12.375C0.5 13.0625 1.0625 13.625 1.75 13.625H14.25C14.9375 13.625 15.4938 13.0625 15.4938 12.375L15.5 3.625C15.5 2.9375 14.9375 2.375 14.25 2.375ZM5.5 4.25C6.5375 4.25 7.375 5.0875 7.375 6.125C7.375 7.1625 6.5375 8 5.5 8C4.4625 8 3.625 7.1625 3.625 6.125C3.625 5.0875 4.4625 4.25 5.5 4.25ZM9.25 11.75H1.75V11.125C1.75 9.875 4.25 9.1875 5.5 9.1875C6.75 9.1875 9.25 9.875 9.25 11.125V11.75ZM14.25 8H9.25V4.25H14.25V8Z" fill={color} fill-opacity={opacity} />
            </g>
            <defs>
                <clipPath id="clip0_3265_21935">
                    <rect width="15" height="15" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export const ScheduleSendIcon: React.FC<IconsProps> = ({
    height = 24,
    width = 24,
    color = '#006DF5'
}) => {
    return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 12.501H15V16.501L18 18.501L18.75 17.271L16.5 15.751V12.501ZM16 9.00098L2 3.00098V10.001L11 12.001L2 14.001V21.001L9.27 17.891C10.09 20.831 12.79 23.001 16 23.001C19.86 23.001 23 19.861 23 16.001C23 12.141 19.86 9.00098 16 9.00098ZM16 21.001C13.25 21.001 11.02 18.781 11 16.031V15.961C11.02 13.221 13.25 10.991 16 10.991C18.76 10.991 21 13.231 21 15.991C21 18.751 18.76 21.001 16 21.001Z" fill={color} fill-opacity="1"/>
    </svg>
    );
};

export const ScheduleSendCancelIcon: React.FC<IconsProps> = ({
    height = 24,
    width = 24,
    color = '#ED0039',
    opacity = .5
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 9.00098C16.08 9.00098 15.67 9.04098 15.26 9.11098L1.01 3.00098L1 10.001L10 12.001L1 14.001L1.01 21.001L9.08 17.541C9.59 21.191 12.71 24.001 16.5 24.001C20.64 24.001 24 20.641 24 16.501C24 12.361 20.64 9.00098 16.5 9.00098ZM16.5 22.001C13.47 22.001 11 19.531 11 16.501C11 13.471 13.47 11.001 16.5 11.001C19.53 11.001 22 13.471 22 16.501C22 19.531 19.53 22.001 16.5 22.001Z" fill={color} fill-opacity={opacity}/>
            <path d="M18.27 14.0308L16.5 15.7908L14.73 14.0308L14.03 14.7308L15.79 16.5008L14.03 18.2708L14.73 18.9708L16.5 17.2108L18.27 18.9708L18.97 18.2708L17.21 16.5008L18.97 14.7308L18.27 14.0308Z" fill={color} fill-opacity={opacity}/>
        </svg>
    );
};

export const AttachEmailIcon: React.FC<IconsProps> = ({
    height = 24,
    width = 24,
    color = '#ED0039',
    opacity = .5
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 10.001V4.00098C21 2.90098 20.1 2.00098 19 2.00098H3C1.9 2.00098 1.01 2.90098 1.01 4.00098L1 16.001C1 17.101 1.9 18.001 3 18.001H14V13.001C14 11.341 15.34 10.001 17 10.001H21ZM11 11.001L3 6.00098V4.00098L11 9.00098L19 4.00098V6.00098L11 11.001Z" fill={color} fill-opacity={opacity}/>
            <path d="M21 14.001V18.001C21 19.101 20.1 20.001 19 20.001C17.9 20.001 17 19.101 17 18.001V13.501C17 13.221 17.22 13.001 17.5 13.001C17.78 13.001 18 13.221 18 13.501V18.001H20V13.501C20 12.121 18.88 11.001 17.5 11.001C16.12 11.001 15 12.121 15 13.501V18.001C15 20.211 16.79 22.001 19 22.001C21.21 22.001 23 20.211 23 18.001V14.001H21Z" fill={color} fill-opacity={opacity}/>
        </svg>
    );
};

export const UnsubscribeEmailIcon: React.FC<IconsProps> = ({
    height = 24,
    width = 24,
    color = '#ED0039',
    opacity = .5
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 13.001C16.57 13.001 15 14.571 15 16.501C15 18.431 16.57 20.001 18.5 20.001C20.43 20.001 22 18.431 22 16.501C22 14.571 20.43 13.001 18.5 13.001ZM20.5 17.001H16.5V16.001H20.5V17.001ZM13.55 17.001C13.53 16.831 13.5 16.671 13.5 16.501C13.5 13.741 15.74 11.501 18.5 11.501C19.42 11.501 20.26 11.761 21 12.191V5.00098C21 3.90098 20.1 3.00098 19 3.00098H5C3.9 3.00098 3 3.90098 3 5.00098V15.001C3 16.101 3.9 17.001 5 17.001H13.55ZM12 10.501L5 7.00098V5.00098L12 8.50098L19 5.00098V7.00098L12 10.501Z" fill={color} fill-opacity={opacity} />
        </svg>
    );
};

export const PhoneInTalkIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#ED0039',
    opacity = .5
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 15.501C18.75 15.501 17.55 15.301 16.43 14.931C16.08 14.821 15.69 14.901 15.41 15.171L13.21 17.371C10.38 15.931 8.06 13.621 6.62 10.781L8.82 8.57098C9.1 8.31098 9.18 7.92098 9.07 7.57098C8.7 6.45098 8.5 5.25098 8.5 4.00098C8.5 3.45098 8.05 3.00098 7.5 3.00098H4C3.45 3.00098 3 3.45098 3 4.00098C3 13.391 10.61 21.001 20 21.001C20.55 21.001 21 20.551 21 20.001V16.501C21 15.951 20.55 15.501 20 15.501ZM19 12.001H21C21 7.03098 16.97 3.00098 12 3.00098V5.00098C15.87 5.00098 19 8.13098 19 12.001ZM15 12.001H17C17 9.24098 14.76 7.00098 12 7.00098V9.00098C13.66 9.00098 15 10.341 15 12.001Z" fill={color} fill-opacity={opacity}/>
        </svg>
    );
};

export const MarkEmailReadIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5',
    opacity = .5
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 19.001C12 15.131 15.13 12.001 19 12.001C20.08 12.001 21.09 12.251 22 12.681V6.00098C22 4.90098 21.1 4.00098 20 4.00098H4C2.9 4.00098 2 4.90098 2 6.00098V18.001C2 19.101 2.9 20.001 4 20.001H12.08C12.03 19.671 12 19.341 12 19.001ZM4 6.00098L12 11.001L20 6.00098V8.00098L12 13.001L4 8.00098V6.00098ZM17.34 22.001L13.8 18.461L15.21 17.051L17.33 19.171L21.57 14.931L23 16.341L17.34 22.001Z" fill={color} fill-opacity={opacity}/>
        </svg>

    );
};

export const MonetizationOnIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5',
    opacity = .5
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2.00098C6.48 2.00098 2 6.48098 2 12.001C2 17.521 6.48 22.001 12 22.001C17.52 22.001 22 17.521 22 12.001C22 6.48098 17.52 2.00098 12 2.00098ZM13.41 18.091V20.001H10.74V18.071C9.03 17.711 7.58 16.611 7.47 14.671H9.43C9.53 15.721 10.25 16.541 12.08 16.541C14.04 16.541 14.48 15.561 14.48 14.951C14.48 14.121 14.04 13.341 11.81 12.811C9.33 12.211 7.63 11.191 7.63 9.14098C7.63 7.42098 9.02 6.30098 10.74 5.93098V4.00098H13.41V5.95098C15.27 6.40098 16.2 7.81098 16.26 9.34098H14.3C14.25 8.23098 13.66 7.47098 12.08 7.47098C10.58 7.47098 9.68 8.15098 9.68 9.11098C9.68 9.95098 10.33 10.501 12.35 11.021C14.37 11.541 16.53 12.411 16.53 14.931C16.52 16.761 15.15 17.761 13.41 18.091Z" fill={color} fill-opacity={opacity}/>
        </svg>
    );
};

export const EmailFailedIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5',
    opacity = .5
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.102 10.377C10.2007 10.477 10.3344 10.5335 10.4741 10.5335H13.5264C13.666 10.5335 13.7998 10.477 13.8985 10.377L16.0567 8.19C16.1554 8.09 16.2111 7.9545 16.2111 7.813V4.72C16.2111 4.5785 16.1554 4.443 16.0567 4.343L13.8985 2.1565C13.7998 2.0565 13.666 2 13.5264 2H10.4741C10.3344 2 10.2007 2.0565 10.102 2.1565L7.9438 4.3435C7.84511 4.4435 7.78936 4.579 7.78936 4.7205V7.8135C7.78936 7.955 7.84511 8.0905 7.9438 8.1905L10.102 10.3775V10.377ZM11.4738 4.1335C11.4738 3.447 12.5262 3.4455 12.5262 4.1335V6.5335C12.5262 7.22 11.4738 7.2215 11.4738 6.5335V4.1335ZM11.4738 8.1335C11.4738 7.447 12.5262 7.4455 12.5262 8.1335V8.4C12.5262 9.0865 11.4738 9.088 11.4738 8.4V8.1335ZM15.3842 15.952L22 21.208V8.4C22 8.1065 21.7632 7.8665 21.4735 7.8665H17.2631V8.255C17.2631 8.3965 17.2074 8.532 17.1087 8.632L14.3341 11.4435C14.2355 11.5435 14.1017 11.6 13.9621 11.6H10.0379C9.89826 11.6 9.76454 11.5435 9.66585 11.4435L6.89132 8.632C6.79264 8.532 6.73688 8.3965 6.73688 8.255V7.8665H2.52648C2.23684 7.8665 2 8.1065 2 8.4V21.208L8.61584 15.952C9.15022 15.529 9.80105 16.3685 9.26569 16.792L2.70807 22H21.2924L14.7343 16.792C14.5054 16.6105 14.4659 16.2745 14.645 16.0425C14.8241 15.813 15.1557 15.7705 15.3847 15.952H15.3842ZM14.3687 15.2455C13.0202 16.418 10.9803 16.418 9.63181 15.2455L3.41564 9.872C3.19458 9.68 3.1709 9.344 3.35791 9.12C3.54738 8.896 3.87896 8.8695 4.10002 9.0615L10.3132 14.435C11.2897 15.2805 12.7108 15.2805 13.6868 14.435L19.8975 9.0615C20.121 8.8695 20.4526 8.896 20.6396 9.12C20.8291 9.344 20.803 9.68 20.5819 9.872L14.3687 15.2455Z" fill={color} fill-opacity={opacity} />
        </svg>
    );
};

export const NotificationActiveIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5',
    opacity = 1
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5793 4.08L6.1493 2.65C3.7493 4.48 2.1693 7.3 2.0293 10.5H4.0293C4.1793 7.85 5.5393 5.53 7.5793 4.08ZM19.9693 10.5H21.9693C21.8193 7.3 20.2393 4.48 17.8493 2.65L16.4293 4.08C18.4493 5.53 19.8193 7.85 19.9693 10.5ZM17.9993 11C17.9993 7.93 16.3593 5.36 13.4993 4.68V4C13.4993 3.17 12.8293 2.5 11.9993 2.5C11.1693 2.5 10.4993 3.17 10.4993 4V4.68C7.6293 5.36 5.9993 7.92 5.9993 11V16L3.9993 18V19H19.9993V18L17.9993 16V11ZM11.9993 22C12.1393 22 12.2693 21.99 12.3993 21.96C13.0493 21.82 13.5793 21.38 13.8393 20.78C13.9393 20.54 13.9893 20.28 13.9893 20H9.9893C9.9993 21.1 10.8893 22 11.9993 22Z" fill={color} fill-opacity={opacity} />
        </svg>
    );
};

export const PendingActionsIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5',
    opacity = 1
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 12C14.24 12 12 14.24 12 17C12 19.76 14.24 22 17 22C19.76 22 22 19.76 22 17C22 14.24 19.76 12 17 12ZM18.65 19.35L16.5 17.2V14H17.5V16.79L19.35 18.64L18.65 19.35ZM18 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H6C4.9 3 4 3.9 4 5V20C4 21.1 4.9 22 6 22H12.11C11.52 21.43 11.04 20.75 10.69 20H6V5H8V8H16V5H18V10.08C18.71 10.18 19.38 10.39 20 10.68V5C20 3.9 19.1 3 18 3ZM12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5Z" fill={color} fill-opacity={opacity} />
        </svg>
    );
};

export const CampaignIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5',
    opacity = 1
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 11V13H22V11H18ZM16 17.61C16.96 18.32 18.21 19.26 19.2 20C19.6 19.47 20 18.93 20.4 18.4C19.41 17.66 18.16 16.72 17.2 16C16.8 16.54 16.4 17.08 16 17.61ZM20.4 5.6C20 5.07 19.6 4.53 19.2 4C18.21 4.74 16.96 5.68 16 6.4C16.4 6.93 16.8 7.47 17.2 8C18.16 7.28 19.41 6.35 20.4 5.6ZM4 9C2.9 9 2 9.9 2 11V13C2 14.1 2.9 15 4 15H5V19H7V15H8L13 18V6L8 9H4ZM15.5 12C15.5 10.67 14.92 9.47 14 8.65V15.34C14.92 14.53 15.5 13.33 15.5 12Z" fill={color} fill-opacity={opacity}/>
        </svg>
    );
};

export const AbTestingIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5',
    opacity = 1
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.06664 14.6667H8.66664L7.86664 13.3333L7.06664 14.6667ZM19.5333 4H12.8666V2.66667C12.8666 2.26667 12.6 2 12.2 2C11.8 2 11.5333 2.26667 11.5333 2.66667V4H4.86664C3.7333 4 2.86664 4.86667 2.86664 6V18C2.86664 19.1333 3.7333 20 4.86664 20H11.5333V21.3333C11.5333 21.7333 11.8 22 12.2 22C12.6 22 12.8666 21.7333 12.8666 21.3333V20H19.5333C20.6666 20 21.5333 19.1333 21.5333 18V6C21.5333 4.86667 20.6666 4 19.5333 4ZM10.0666 6C10.4 6 10.7333 6.33333 10.7333 6.66667C10.7333 7.06667 10.4 7.33333 10.0666 7.33333C9.7333 7.33333 9.39997 7 9.39997 6.66667C9.39997 6.26667 9.7333 6 10.0666 6ZM7.9333 6C8.26664 6 8.59997 6.33333 8.59997 6.66667C8.59997 7.06667 8.26664 7.33333 7.9333 7.33333C7.5333 7.33333 7.26664 7 7.26664 6.66667C7.26664 6.26667 7.59997 6 7.9333 6ZM5.7333 6C6.06664 6 6.39997 6.33333 6.39997 6.66667C6.39997 7.06667 6.06664 7.33333 5.7333 7.33333C5.39997 7.33333 5.06664 7 5.06664 6.66667C5.06664 6.26667 5.39997 6 5.7333 6ZM10.5333 16.6C10.0187 16.9392 9.73331 16.5333 9.59997 16.3333L9.39997 16H6.3333L6.1333 16.3333C5.9333 16.6667 5.52122 16.7592 5.19997 16.6C4.85205 16.4275 4.79997 16 4.9333 15.6667L7.26664 11.6667C7.53331 11.2667 8.19997 11.2667 8.39997 11.6667L10.7333 15.6667C10.9333 16 10.8666 16.4 10.5333 16.6ZM12.8666 18.6667C12.8666 19.0667 12.6 19.3333 12.2 19.3333C11.8 19.3333 11.5333 19.0667 11.5333 18.6667V5.33333C11.5333 4.93333 11.8 4.66667 12.2 4.66667C12.6 4.66667 12.8666 4.93333 12.8666 5.33333V18.6667ZM17.2 16.6667H14.8666C14.4666 16.6667 14.2 16.4 14.2 16V12C14.2 11.6 14.4666 11.3333 14.8666 11.3333H16.5333C17.4666 11.3333 18.2 12.0667 18.2 13C18.2 13.2 18.1333 13.4 18.0666 13.6C18.5333 13.8667 18.8666 14.4 18.8666 15C18.8666 15.9333 18.1333 16.6667 17.2 16.6667ZM17.2 14.6667H15.5333V15.3333H17.2C17.4 15.3333 17.5333 15.2 17.5333 15C17.5333 14.8 17.4 14.6667 17.2 14.6667ZM16.8666 13C16.8666 12.8 16.7333 12.6667 16.5333 12.6667H15.5333V13.3333H16.5333C16.7333 13.3333 16.8666 13.2 16.8666 13Z" fill={color}/>
        </svg>

    );
};

export const StopEmailingIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5'
}) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.28034 2.21968C2.98745 1.92678 2.51257 1.92677 2.21968 2.21966C1.92678 2.51255 1.92677 2.98743 2.21966 3.28032L3.4698 4.53049C2.5846 5.11112 2 6.11229 2 7.25001V16.75L2.00514 16.9344C2.10075 18.6436 3.51697 20 5.25 20H18.75L18.9339 19.9949L20.7194 21.7805C21.0123 22.0734 21.4872 22.0734 21.7801 21.7805C22.073 21.4876 22.073 21.0127 21.7801 20.7198L3.28034 2.21968ZM17.439 18.5H5.25L5.10647 18.4942C4.20711 18.4212 3.5 17.6682 3.5 16.75V9.37401L11.6507 13.6637L11.7468 13.706C11.9431 13.7764 12.1619 13.7623 12.3493 13.6637L12.5154 13.5763L17.439 18.5ZM10.0825 11.1433L3.5 7.67901V7.25001L3.5058 7.10648C3.55977 6.44167 3.9853 5.88191 4.57445 5.63516L10.0825 11.1433Z" fill={color}/>
        <path d="M20.5 7.67801L14.1844 11.0026L15.2949 12.1131L20.5 9.37301V16.75L20.4942 16.8935C20.4843 17.0152 20.462 17.1334 20.4285 17.2468L21.5621 18.3804C21.8405 17.9012 22 17.3442 22 16.75V7.25001L21.9949 7.06559C21.8992 5.35646 20.483 4.00001 18.75 4.00001H7.18195L8.68192 5.50001H18.75L18.8935 5.50581C19.7929 5.57882 20.5 6.33184 20.5 7.25001V7.67801Z" fill={color}/>
    </svg>
);

export const ConnectEmailIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5'
}) => (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 128 160" fill={color}>
        <path d="M14.4,114c0-1.7-1.3-3-3-3H4.1c-1.5,0-2.8,1-3.1,2.4C0.7,115.3,2.1,117,4,117h7.4C13,117,14.4,115.7,14.4,114z"/>
        <path d="M104,89c6.9,0,13-5.6,13-12V14c0-7.2-5.8-13-13-13H24c-7.2,0-13,5.8-13,13v62c0,7.2,5.8,13,13,13h37v12.4  c-4.8,1.1-8.5,4.9-9.6,9.6H27c-1.7,0-3,1.3-3,3s1.3,3,3,3h24.4c1.4,5.7,6.5,10,12.6,10c6.1,0,11.3-4.3,12.6-10H124c1.7,0,3-1.3,3-3  s-1.3-3-3-3H76.6c-1.1-4.8-4.9-8.5-9.6-9.6V89H104z M71,114c0,3.9-3.1,7-7,7c-3.9,0-7-3.1-7-7s3.1-7,7-7C67.9,107,71,110.1,71,114z   M24,83c-3.9,0-7-3.1-7-7V14c0-3.9,3.1-7,7-7h80c3.9,0,7,3.1,7,7v63c0,2.8-3.1,6-7,6H24z"/>
        <path d="M98.3,26.9c0,0.1-5.1,6-11.9,11.8C77.6,46.1,69.9,50,64,50c-5.9,0-13.6-3.9-22.4-11.3c-6.9-5.8-11.9-11.7-11.9-11.8  c-1.1-1.3-3-1.4-4.2-0.4s-1.4,3-0.4,4.2C26,31.8,46.6,56,64,56c17.4,0,38-24.2,38.9-25.2c1.1-1.3,0.9-3.2-0.4-4.2  C101.3,25.5,99.4,25.6,98.3,26.9z"/>
    </svg>
);

export const ManageSearchIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5'
}) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 9H2V7H7V9ZM7 12H2V14H7V12ZM20.59 19L16.76 15.17C15.96 15.69 15.02 16 14 16C11.24 16 9 13.76 9 11C9 8.24 11.24 6 14 6C16.76 6 19 8.24 19 11C19 12.02 18.69 12.96 18.17 13.75L22 17.59L20.59 19ZM17 11C17 9.35 15.65 8 14 8C12.35 8 11 9.35 11 11C11 12.65 12.35 14 14 14C15.65 14 17 12.65 17 11ZM2 19H12V17H2V19Z" fill={color} />
    </svg>

);

export const GoogleSpreadsheetIcon:React.FC<IconsProps> = ({   width = 20,
    height = 20,
  }) => (
    <svg width={width} height={height} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.8676 20.785H2.13151C1.38672 20.785 0.777344 20.1756 0.777344 19.4308V2.278C0.777344 1.5332 1.38672 0.923828 2.13151 0.923828H10.2565L15.2218 5.88911V19.4308C15.2218 20.1756 14.6124 20.785 13.8676 20.785Z" fill="none" stroke="#006DF5" strokeWidth="2px"/>
        <path d="M10.2559 0.923828L15.2211 5.88911H10.2559V0.923828Z" fill="#002E75"/>
        <path d="M3.48633 8.71033V15.0298H12.5141V8.71033H3.48633ZM4.6148 9.8388H7.43599V11.3058H4.6148V9.8388ZM4.6148 12.4343H7.43599V13.9013H4.6148V12.4343ZM11.3856 13.9013H8.56446V12.4343H11.3856V13.9013ZM11.3856 11.3058H8.56446V9.8388H11.3856V11.3058Z" fill="#006DF5"/>
    </svg>
);

export const OpenAiIcon: React.FC<IconsProps> = ({
      width = 24,
      height = 24,
      color = '#006DF5'
}) => (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5" class="h-6 w-6" viewBox="-0.17090198558635983 0.482230148717937 41.14235318283891 40.0339509076386">
        <path d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813zM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496zM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744zM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237zm27.658 6.437l-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763zm-21.063 6.929l-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225zm1.829-3.943l4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18z" fill={color}/>
    </svg>
);

export const UploadIcon: React.FC<IconsProps> = ({
      width = 32,
      height = 33,
      color = '#006DF5'
}) => (
    <svg width={width} height={height} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.66602 27.1667H25.3327V24.5H6.66602V27.1667ZM6.66602 13.8333H11.9993V21.8333H19.9993V13.8333H25.3327L15.9993 4.5L6.66602 13.8333Z"
            fill={color}/>
    </svg>
);

export const FileIcon: React.FC<IconsProps> = ({
   width = 36,
   height = 37,
   color = '#949DB2'
}) => (
    <svg width={width} height={height} viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 3.5C7.35 3.5 6.015 4.85 6.015 6.5L6 30.5C6 32.15 7.335 33.5 8.985 33.5H27C28.65 33.5 30 32.15 30 30.5V12.5L21 3.5H9ZM19.5 14V5.75L27.75 14H19.5Z"
            fill={color}/>
    </svg>
);

export const DeleteIcon: React.FC<IconsProps> = ({
    width = 20,
    height = 21,
    color= '#949DB2'
}) => (
    <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.99935 16.3333C4.99935 17.25 5.74935 18 6.66602 18H13.3327C14.2493 18 14.9993 17.25 14.9993 16.3333V6.33333H4.99935V16.3333ZM15.8327 3.83333H12.916L12.0827 3H7.91602L7.08268 3.83333H4.16602V5.5H15.8327V3.83333Z"
            fill={color}/>
    </svg>
);

export const ErrorIcon: React.FC<IconsProps> = ({
    width = 24,
    height = 24,
    color = '#006DF5'
}) => (
<svg
    height={height}
    style={{ overflow: 'visible'}}
    viewBox="0 0 32 32"
    width={width}
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g id="Error_1_">
        <g id="Error">
          <circle cx="16" cy="16" id="BG" r="16" style={{ fill: '#D72828' }} />
          <path
            d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
            id="Exclamatory_x5F_Sign"
            style={{ fill: '#E6E6E6' }}
          />
        </g>
      </g>
    </g>
  </svg>
);
