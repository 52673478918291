import React, { createContext, useContext, ReactNode, useState, useEffect, Dispatch, SetStateAction } from "react";
import { IMapData, IMapping } from "./connect/mapping/Mapping.tsx";
import { CSV_FILE_STEPS, CSV_IMPORT_STEPS } from "../../../../../helpers/constant/coreConstants.ts";
import { showNotification } from "../../../../../helpers/util/functions.tsx";
import emailCampaignService from "../../../../../services/emailCampaign.service.ts";
import Papa from "papaparse";
import { ICSVFile } from "../../../../../state/features/emailCampaign/emailCampaign.interface.ts";
import mapCSVImportHelper from "./connect/mapping/mapCSVImportHelper.ts";
import { useSelector } from "react-redux";
import { emailCampaignState } from "../../../../../state/features/emailCampaign/emailCampaignSelector.ts";

interface ImportCsvContextType {
    activeStep?: number;
    setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
    handleGoNextStep: () => void;
    mappedRows: any[]; 
    unMappedRows: any[]; 
    handleMapColumn: (value: string, index: number) => void; 
    findSelectedValue: (index:number) => string; 
    handleGoPreviousStep: () => void;
    mappingColumns: any[]; 
    valuesForSubmit: any[]; 
    leadSources: any[]; 
    duplicateStrategy: number; 
    setDuplicateStrategy: Dispatch<SetStateAction<number>>
    leadSource: string; 
    setLeadSource: Dispatch<SetStateAction<string>> 
    handleSubmit: () => void; 
    isSubmitting: boolean;
    activeImportStep:number;
    handleFileUpload: (files: File | File[]| FileList | null) => void;
    screen:number;
    changeScreen:(mScreen: number)=>void;
    goToStep : (step: number) =>void;
    resetImportData:()=>void;
}

const ImportCsvContext = createContext<ImportCsvContextType | undefined>(undefined);

interface ImportCsvContextProviderProps {
    children: ReactNode;
}

const ImportCsvContextProvider: React.FC<ImportCsvContextProviderProps> = ({ children }) => {
    const { selectedCampaign } = useSelector(emailCampaignState);
    const [screen, setScreen] = useState<number>(CSV_FILE_STEPS.CSV_FILE_LIST);
    const [activeImportStep, setActiveImportStep] = useState<number>(CSV_IMPORT_STEPS.UPLOAD);
    const [valuesForSubmit, setValuesForSubmit] = useState<IMapData[]>([] as IMapData[]);
    const [valuesForSelect, setValuesForSelect] = useState<IMapData[]>([] as IMapData[]);
    const [mappedRows, setMappedRows] = useState<IMapping[]>([] as IMapping[]);
    const [unMappedRows, setUnMappedRows] = useState<IMapping[]>([] as IMapping[]);
    const [leadSources, setLeadSources] = useState([]);
    const [csvFile, setCsvFile] = useState<File>({} as File);
    const [leadSource, setLeadSource] = useState<string>("");
    const [duplicateStrategy, setDuplicateStrategy] = useState<number>(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [mappingColumns, setMappingColumns] = useState([
        {
            value: "first_name",
            label: "First Name",

        },
        {
            value: "last_name",
            label: "Last Name",
        },
        {
            value: "number",
            label: "Number",
        },
        {
            value: "email",
            label: "Email",
        },
        {
            value: "address",
            label: "Address",
        },
        {
            value: "city",
            label: "City",
        },
        {
            value: "state",
            label: "State",
        },
        {
            value: "zip",
            label: "Zip",
        },
        {
            value: "country",
            label: "Country",
        },
        {
            value: "url",
            label: "URL",
        },
        {
            value: "company_name",
            label: "Company Name",
        },
        {
            value: "birth_date",
            label: "Birth Date",
        },
        {
            value: "anniversary_date",
            label: "Anniversary Date",
        },
    ]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const customFieldsResp = await emailCampaignService.getContactCustomFields();
            if (customFieldsResp?.success && customFieldsResp?.data && Array.isArray(customFieldsResp.data)) {
                let data = customFieldsResp.data;

                let newCFields = [...mappingColumns];
                data.forEach((item: any) => {
                    newCFields.push({
                        label: item.title,
                        value: `custom_${item.id}`
                    });
                });
                setMappingColumns(newCFields);
            }
        } catch (err) {
            console.log(err)
        }

        try {
            const sourcesResp = await emailCampaignService.getContactSources();
            if (sourcesResp?.success) {
                setLeadSources(sourcesResp.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const stepList = [
        {
            label: "Upload",
        },
        {
            label: "Mapping",
        },
        {
            label: "Details",
        },
    ];

    const findSelectedValue = (index: number): string => {
        return valuesForSelect[index]?.data as string;
    };

    const getLabel = (value: string) => {
        try {
            return mappingColumns.find((mappingColumn) => (mappingColumn.value === value))?.label || 'this field';
        } catch (err) {
            return 'this field';
        }
    };

    const handleMapColumn = (value: string, index: number) => {
        let currentValue = valuesForSelect[index].data;

        if (value !== currentValue) {
            const oldValuesForSubmit = [...valuesForSubmit];
            const oldValuesForSelect: IMapData[] = [...valuesForSelect];

            if (value && value.split('_')[0] === "custom") {
                oldValuesForSelect[index] = { type: 'custom', map: index, data: value as string };
            } else {
                oldValuesForSelect[index] = { type: 'contact', map: index, data: value as string };
            }

           // oldValuesForSelect[index] = { data: value as string };
            const selectionCount = oldValuesForSelect.filter((field) => (field.data === value));

            if (selectionCount.length > 1 && value !== "email" && value !== "number" && value !== null && value !== undefined && value !== "") {
                showNotification("error", `Duplicate entry for ${getLabel(value)} is not allowed.`);
                return;
            }

            if (valuesForSubmit[index].data == null) {
                const filterUnmap = unMappedRows?.filter((item) => item.index != index);
                const filterMap = unMappedRows?.find((item) => item.index === index);
                setUnMappedRows(filterUnmap);
                setMappedRows((prevData) => [...prevData, filterMap as IMapping]);
            }

            if (value && value.split('_')[0] === "custom") {
                oldValuesForSubmit[index] = { type: 'custom', map: index, data: value.replace("custom_", "") as string };
            } else {
                oldValuesForSubmit[index] = { type: 'contact', map: index, data: value as string };
            }

            setValuesForSubmit(oldValuesForSubmit);
            setValuesForSelect(oldValuesForSelect);
        }
    };

    const handleGoNextStep = () => {
        setActiveImportStep((prevActiveImportStep) => prevActiveImportStep + 1);
    };

    const handleGoPreviousStep = () => {
        setActiveImportStep((prevActiveImportStep) => prevActiveImportStep - 1);
    };

    const goToStep = (step: number) => {
        setActiveImportStep(step);
    };

    const changeScreen = (mScreen: number) => {
        setScreen(mScreen);
    };

    const extractFileId = (url: string) => {
        const regex = /\/d\/([a-zA-Z0-9_-]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const resetImportData = () => {
        setMappedRows([]);
        setUnMappedRows([]);
        setValuesForSubmit([]);
    };

    const handleFileUpload = (file: File[]) => {
        const csvFile = file[0];
        if (csvFile?.type === 'text/csv') {
            setCsvFile(csvFile);
            Papa.parse(csvFile, {
                skipEmptyLines: true,
                complete: (result: Papa.ParseResult<ICSVFile<string | number>>) => {
                    const { mappedRows, actionNeededRows, submitData } = mapCSVImportHelper.mapData(
                        result.data as unknown as ICSVFile<string | number>[][]
                    );
                    const data: IMapData[] = Object.values(submitData);
                    setMappedRows(mappedRows);
                    setUnMappedRows(actionNeededRows);
                    setValuesForSubmit(data);
                    setValuesForSelect(data);
                    setActiveImportStep(CSV_IMPORT_STEPS.MAPPING);
                },
            });
        }
    };

    const handleSubmit = async () => {
        if (!leadSource) {
            showNotification("error", "Lead source is required");
            return;
        }
        try {
            setIsSubmitting(true);
            const formData = new FormData();
            formData.append('campaignUid', selectedCampaign?.uid);
            formData.append('file', csvFile);
            formData.append('sourceId', leadSource);
            formData.append('existingContactRule', String(duplicateStrategy));

            for (const [key, value] of Object.entries<{ type: string; map: string; data: any }>(valuesForSubmit)) {
                if (value.type === 'custom') {
                    formData.append(`customFields[${value.map}]`, value.data);
                } else {
                    formData.append(`requestColumns[${value.map}]`, value.data);
                }
            }

            const resp = await emailCampaignService.uploadContactCsvFile(formData);

            if (resp?.success) {
                setScreen(CSV_FILE_STEPS.CSV_FILE_LIST);
                showNotification("success", resp?.message);
            }
        } catch (err) {
            console.log(err)
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <ImportCsvContext.Provider
            value={{
                stepList,
                activeImportStep,
                handleGoNextStep,
                handleGoPreviousStep,
                screen,
                changeScreen,
                mappedRows,
                unMappedRows,
                valuesForSubmit,
                setMappedRows,
                setUnMappedRows,
                setValuesForSubmit,
                findSelectedValue,
                handleMapColumn,
                extractFileId,
                goToStep,
                resetImportData,
                mappingColumns,
                setMappingColumns,
                setValuesForSelect,
                leadSources,
                handleFileUpload,
                duplicateStrategy,
                setDuplicateStrategy,
                leadSource,
                setLeadSource,
                handleSubmit,
                isSubmitting
            }}
        >
            {children}
        </ImportCsvContext.Provider>
    );
};

export const useImportCsvContext = (): ImportCsvContextType => {
    const context = useContext(ImportCsvContext);

    if (!context) {
        throw new Error("useImportCsvContext must be used within a ImportCsvContextProvider");
    }

    return context;
};

export const withImportCsvContext = <P extends object>(Component: React.ComponentType<P>) => {
    const WithImportCsvContext: React.FC<P> = (props) => (
        <ImportCsvContextProvider>
            <Component {...props} />
        </ImportCsvContextProvider>
    );

    return WithImportCsvContext;
};