import React from 'react';
import { Button, Paper, Stack, Typography } from 'convertupleads-theme';

interface EachLeadItemProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    buttonText?: string;
    outlined?: boolean;
    handleOpen: () => void;
}

const EachLeadItem: React.FC<EachLeadItemProps> = ({icon, title, description, buttonText = 'Click here',
    outlined, handleOpen: onClick}) => {
    return (
        <Paper elevation={0} variant='outlined' sx={{ borderRadius: '8px', p: 2 }}>
            {/* <IconButton variant={'rounded'} color={'primary'} size={'medium'} sx={{ backgroundColor: 'primary.light', mb: 1 }}>
                {icon}
            </IconButton> */}
            <Stack mb={1}>{icon}</Stack>

            <Stack mb={2.5}>
                <Typography variant={'body1'} color={'text.primary'} fontWeight={600}>
                    {title}
                </Typography>
                <Typography variant={'caption'} color={'text.tertiary'}>
                    {description}
                </Typography>
            </Stack>

            <Button
                variant={outlined ? 'outlined' : 'contained'}
                size={'large'}
                onClick={onClick}
                sx={{ '&:hover': { background: 'primary.main', color: 'common.white' }, '&:focus': { background: 'transparent', color: 'primary.main' } }}
            >
                {buttonText}
            </Button>
        </Paper>
    );
};

export default EachLeadItem;