import React from 'react';
import { Stack, useTheme } from 'convertupleads-theme';
import { CustomScrollbar } from '../common/CustomScrollBarStyle';
import CampaignSequenceSideBar from './CampaignSequenceSideBar';
import CampaignSequenceBody from './emailCampaignBody/CampaignSequenceBody';

const CampaignSequence: React.FC<{
    showError: boolean;
    goStep: (step: number) => void;
}> = ({ showError, goStep }) => {
    const theme = useTheme();
    return (
        <Stack sx={{ background: theme.palette.common.white }}>
            <Stack direction={'row'} sx={{ background: theme.palette.common.white, height: 'calc(100vh - 209px)' }}>
                {' '}
                <CampaignSequenceSideBar />
                <CampaignSequenceBody goStep={goStep} showError={showError} />
            </Stack>
        </Stack>
    );
};

export default CampaignSequence;
