import React, { useState } from "react";

import {
    CheckCircleIcon,
    IconButton,
    MailFilledIcon,
    Paper,
    Stack,
    Typography,
    useTheme
} from "convertupleads-theme";
import {
    ContactMailIcon,
    EmailCheckedIcon,
    EmailFailedIcon,
    EmailMarkUnreadIcon,
    UnsubscribeEmailIcon,
} from "../../../../../assets/Icons";
import StatusListDrawer from "../../../../common/drawer/statusList/StatusListDrawer";
import { EVENT_TYPE, STATUS_TYPE } from "../../../../../helpers/constant/coreConstants";

interface IEmailStatus {
    campaignConnectedEmailId: number;
    userConnectedEmail: string;
    totalSend: number;
    totalOpen: number;
    totalClick: number;
    totalRespond: number;
    totalFailed: number;
    totalBounce: number;
    totalUnsubscribe: number;
  }
  
  interface IProps {
    emailStatus: IEmailStatus[];
    campaignUid: string;
    filterData?: any;
  }

const AnalyticsList: React.FC<IProps> = ({ campaignUid, emailStatus, filterData }) => {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [type, setType] = useState({
    statusType: "",
    eventType: "",
    campaignConnectedEmailId: 0,
    campaignUid,
  });

  const handleStatus = async (type: string, campaignConnectedEmailId: number) => {
    setOpenDrawer(true);
    setType({
      statusType: type,
      eventType: EVENT_TYPE.EMAIL,
      campaignConnectedEmailId,
      campaignUid,
    });
  };

  const drawerTitle =
    type.statusType === STATUS_TYPE.OPEN
      ? "Open List"
      : type.statusType === STATUS_TYPE.QUEUE
      ? "Queue List"
      : type.statusType === STATUS_TYPE.SEND
      ? "Sent List"
      : type.statusType === STATUS_TYPE.CLICK
      ? "Click List"
      : type.statusType === STATUS_TYPE.RESPOND
      ? "Respond List"
      : type.statusType === STATUS_TYPE.RESPOND
      ? "Respond List"
      : type.statusType === STATUS_TYPE.UNSUBSCRIBE
      ? "Unsubscribe List"
      : type.statusType === STATUS_TYPE.BOUNCE
      ? "Bounce List"
      : "Failed List";

  return (
    <Stack spacing={1} p={2}>
      {emailStatus?.map((item) => {
          const {
            campaignConnectedEmailId,
            userConnectedEmail,
            totalSend,
            totalOpen,
            totalClick,
            totalRespond,
            totalFailed,
            totalBounce,
            totalUnsubscribe,
          } = item || {};

          const calculatePercentageAverage = (number: number) => {
              try{
                  if (number !== undefined && number !== null && totalSend !== 0){
                      const result = (number / totalSend) * 100 || 0;
                      return parseFloat(result.toFixed(2));
                  }

                  return 0;
              }catch (err){
                  return 0;
              }
          };

        return (
        <Paper elevation={0} variant="outlined" sx={{ py: 1, px: 1, borderRadius: 1 }}>
          <Typography
            sx={{ cursor: "pointer" }}
            color="text.primary"
            variant="body1"
            fontWeight={500}
          >
            {userConnectedEmail}
          </Typography>

          <Stack direction={"row"} spacing={2} mt={2} mb={0.4}>
            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ cursor: "pointer" }} onClick={() => handleStatus(STATUS_TYPE.SEND, campaignConnectedEmailId)}>
              <IconButton
                sx={{
                  background: theme.palette.primary.light,
                  ':focus': {
                    background: theme.palette.primary.light
                  }
                }}
                size="small"
              >
                <MailFilledIcon color={"primary"} />
              </IconButton>

              <Typography fontSize={13} color="text.secondary">
                <b>{totalSend}</b> Emails Sent
              </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ cursor: "pointer" }} onClick={() => handleStatus(STATUS_TYPE.OPEN, campaignConnectedEmailId)}>
              <IconButton
                sx={{
                  background: theme.palette.primary.light,
                  ':focus': {
                    background: theme.palette.primary.light
                  }
                }}
                size="small"
              >
                <EmailMarkUnreadIcon
                  height={20}
                  width={20}
                  color={theme.palette.primary.main}
                />
              </IconButton>

              <Typography fontSize={13} color="text.secondary">
                <b>{calculatePercentageAverage(totalOpen)}%</b> Open Rate (
                {totalOpen || 0})
              </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ cursor: "pointer" }} onClick={() => handleStatus(STATUS_TYPE.CLICK, campaignConnectedEmailId)}>
              <IconButton
                sx={{
                  background: theme.palette.primary.light,
                  ':focus': {
                    background: theme.palette.primary.light
                  }
                }}
                size="small"
              >
                <EmailCheckedIcon
                  height={20}
                  width={20}
                  color={theme.palette.primary.main}
                />
              </IconButton>
              <Typography fontSize={13} color="text.secondary">
                <b>{calculatePercentageAverage(totalClick)}%</b> Click Rate (
                {totalClick || 0})
              </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ cursor: "pointer" }} onClick={() => handleStatus(STATUS_TYPE.RESPOND, campaignConnectedEmailId)}>
              <IconButton
                sx={{
                  background: theme.palette.primary.light,
                  ':focus': {
                    background: theme.palette.primary.light
                  }
                }}
                size="small"
              >
                <CheckCircleIcon color={"primary"} />
              </IconButton>

              <Typography fontSize={13} color="text.secondary">
                <b>{calculatePercentageAverage(totalRespond)}%</b> Reply Rate (
                {totalRespond || 0})
              </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ cursor: "pointer" }} onClick={() => handleStatus(STATUS_TYPE.FAILED, campaignConnectedEmailId)}>
              <IconButton
                sx={{
                  background: theme.palette.error.light,
                  ':focus': {
                    background: theme.palette.error.light
                  }
                }}
                size="small"
              >
                <EmailFailedIcon
                  height={20}
                  width={20}
                  color={theme.palette.error.main}
                  opacity={1}
                />
              </IconButton>
              <Typography fontSize={13} color="text.secondary">
                <b>{totalFailed || 0}</b> Failed
              </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ cursor: "pointer" }} onClick={() => handleStatus(STATUS_TYPE.BOUNCE, campaignConnectedEmailId)}>
              <IconButton
                sx={{
                  background: theme.palette.error.light,
                  ':focus': {
                    background: theme.palette.error.light
                  }
                }}
                size="small"
              >
                <ContactMailIcon
                  height={20}
                  width={20}
                  color={theme.palette.error.main}
                  opacity={1}
                />
              </IconButton>
              <Typography fontSize={13} color="text.secondary">
                <b>{totalBounce || 0}</b> Bounces
              </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ cursor: "pointer" }} onClick={() => handleStatus(STATUS_TYPE.UNSUBSCRIBE, campaignConnectedEmailId)}>
              <IconButton
                sx={{
                  background: theme.palette.error.light,
                  ':focus': {
                    background: theme.palette.error.light
                  }
                }}
                size="small"
              >
                <UnsubscribeEmailIcon
                  height={20}
                  width={20}
                  color={theme.palette.error.main}
                  opacity={1}
                />
              </IconButton>
              <Typography fontSize={13} color="text.secondary">
                <b>{totalUnsubscribe || 0}</b> Unsubscribes
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      )
      }
      )}

      <StatusListDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        drawerTitle={drawerTitle}
        type={type}
        width={1200}
        filterData={filterData}
      />
    </Stack>
  );
};

export default AnalyticsList;
