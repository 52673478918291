import React from 'react';
import { Stack, useTheme } from 'convertupleads-theme';
import GlobalScheduleComponent from './GlobalScheduleComponent';
import DaysScheduleComponent from './DaysScheduleComponent';
import TimingScheduleComponent from './TimingScheduleComponent';
import IntervalTimeScheduleComponent from './IntervalTimeScheduleComponent';

import { CustomScrollbar } from '../common/CustomScrollBarStyle';

const Schedule: React.FC = () => {
    const theme = useTheme();
    return (
        <CustomScrollbar sx={{ background: theme.palette.common.white, overflowY: 'scroll' }}>
            <Stack padding={'20px'} borderRadius={1} sx={{ height: 'calc(100vh - 209px)' }}>
                <Stack sx={{ width: '60%', mx: 'auto' }} spacing={2.5} paddingBottom={2}>
                    <GlobalScheduleComponent />
                    <DaysScheduleComponent />
                    <TimingScheduleComponent />
                    <IntervalTimeScheduleComponent />
                    {/* <SendDripScheduleComponent /> */}
                </Stack>
            </Stack>
        </CustomScrollbar>
    );
};

export default Schedule;
