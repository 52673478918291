import { Button, FilterListFilledIcon, List, ListItem, ListItemButton, ListItemText, Popover, useTheme } from 'convertupleads-theme';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailCampaigns } from '../../../../../state/features/emailCampaign/emailCampaignSlice';
import { campaignFolderState } from '../../../../../state/features/campaignFolder/campaignFolderSelector';
import { emailCampaignState } from '../../../../../state/features/emailCampaign/emailCampaignSelector';
import {STATUS_RUNNING} from "../../../../../helpers/constant/coreConstants.ts";

const CampaignStatusFilter: React.FC = () => {
    const theme = useTheme();
    const { selectedFolder } = useSelector(campaignFolderState);
    const { pagination: { page, perPage }, query } = useSelector(emailCampaignState);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectValue, setSelectValue] = useState('All');
    const dispatch = useDispatch();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleSelectData = (data: string) => {
        setSelectValue(data === 'PAUSE' ? 'Paused' : data);
        const campaignStatus = data === 'ALL' ? '' : data;
        dispatch(getEmailCampaigns({ page, perPage, query, status: campaignStatus, ...campaignStatusType }));
        handleClose();
    };

    let campaignStatusType: null | object = null;

    switch (selectedFolder) {
        case 'allCampaigns':
            campaignStatusType = null;
            break;
        case 'favourite':
            campaignStatusType = { isFavourite: 'YES' };
            break;
        case 'archive':
            campaignStatusType = { status: 'ARCHIVE' };
            break;
        case 'trash':
            campaignStatusType = { status: 'TRASH' };
            break;
        default:
            campaignStatusType = { campaignFolderUid: selectedFolder };
    }

    return (
        <div>
            {/* <DateRangePicker
                value={{ from: new Date(), to: new Date() }}
                dualMonth={true}
                onChange={(value) => {
                    console.log(value);
                }}
            /> */}

            <Button
                variant={'outlined'}
                startIcon={<FilterListFilledIcon />}
                onClick={handleClick}
                sx={{
                    width: '150px',
                    justifyContent: 'start',
                    color: `${theme.palette.action.active}`,
                    border: `1px solid ${theme.palette.action.active}`,
                    '&:hover': {
                        border: `1px solid ${theme.palette.action.active}`,
                    },
                    '&:focus': {
                        background: `transparent`,
                    },
                }}
            >
                {selectValue}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List component={'div'} sx={{ width: '134px' }}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleSelectData('ALL')}>
                            <ListItemText primary='All' />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleSelectData(STATUS_RUNNING)}>
                            <ListItemText primary='Running' />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleSelectData('PAUSE')}>
                            <ListItemText primary='Paused' />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>
        </div>
    );
};

export default CampaignStatusFilter;
