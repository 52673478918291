import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CopySuccessIcon,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "convertupleads-theme";
import { CustomTextField } from "../../bulkEmail/BulkEmailStyle.tsx";
import emailCampaignService from "../../../services/emailCampaign.service.ts";
import {
  isValidEmail,
  showNotification,
} from "../../../helpers/util/functions.tsx";
import Loading from "./Loading.tsx";
import { ConnectEmailIcon } from "../../../assets/Icons.tsx";

interface IProps {
  open: boolean;
  email: string;
  isCloseable: boolean;
  onClose: () => void;
  state?: string;
}

const personalEmailRequiredMessage =
  "You have to connect your personal email to continue";
const personalEmailOptionalMessage =
  "You can connect your personal email for better reach";

const EmailConnectPopup: React.FC<IProps> = ({
  open,
  email,
  onClose,
  isCloseable,
  state,
}) => {
  const theme = useTheme();
  const [confirmTextInput, setConfirmTextInput] = useState("");
  const [loaderText, setLoaderText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setConfirmTextInput(email);
  }, [email]);

  const initConnectNylas = async () => {
    if (confirmTextInput && !isValidEmail(confirmTextInput)) {
      showNotification("error", "Invalid email address");
      return;
    }

    try {
      let payload: { email: string; state?: string } = {
        email: confirmTextInput,
      };

      if (state) {
        payload.state = state;
      }

      const resp = await emailCampaignService.nylasConnect(payload);
      if (resp && resp.success && resp.data.redirect) {
        showNotification("success", "Please wait while redirecting...");
        setIsLoading(true);
        setLoaderText("Please wait while redirecting...");

        setTimeout(() => {
          window.open(resp.data.redirect, "_self");
        }, 2000);
      } else {
        setLoaderText("");
        setIsLoading(false);
        showNotification("error", "Unable to connect. Please try again later!");
      }
    } catch (err) {
      console.log(err);
      setLoaderText("");
      setIsLoading(false);
      showNotification("error", "Unable to connect. Please try again later!");
    }
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmTextInput(event.target.value);
  };

  return (
    <Backdrop open={open} sx={{ zIndex: 9999, userSelect: "none" }}>
      <Paper
        sx={{
          backgroundColor: theme.palette.background.paper,
          width: "600px",
          borderRadius: "12px",
        }}
      >
        {isLoading ? (
          <Stack>
            <Loading loaderText={loaderText} />
          </Stack>
        ) : (
          <Stack>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              padding={2}
              paddingBottom={0}
            >
              <IconButton
                variant={"outlined"}
                color={"warning"}
                sx={{ width: "75px", height: "75px" }}
              >
                <ConnectEmailIcon width={50} height={50} color={"orange"} />
              </IconButton>
            </Stack>
            <Stack direction={"row"} justifyContent={"center"} px={3} py={2}>
              <Stack direction={"column"} alignItems={"center"}>
                <Typography variant={"h5"} textAlign={"center"}>
                  Connect Personal Email
                </Typography>
                <Typography variant={"body1"} mt={1} textAlign={"center"}>
                  {isCloseable
                    ? personalEmailOptionalMessage
                    : personalEmailRequiredMessage}
                </Typography>

                <Stack
                  direction={"column"}
                  alignItems={"center"}
                  mt={2}
                  width={"100%"}
                >
                  <Typography variant={"body1"} mt={1} mb={1} textAlign={"center"}>
                    Sign in to your email account
                  </Typography>
                  <CustomTextField
                    disabled={email?true:false}
                    fullWidth
                    onChange={handleChange}
                    value={confirmTextInput}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack width={"100%"}>
              <Divider />
              <Stack
                padding={2}
                direction={"row"}
                justifyContent={"flex-end"}
                gap={1}
              >
                {isCloseable && (
                  <Button
                    variant={"tonal"}
                    color={"inherit"}
                    size={"large"}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                )}
                <Button size={"large"} onClick={initConnectNylas}>
                  Next
                </Button>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Paper>
    </Backdrop>
  );
};

export default EmailConnectPopup;
