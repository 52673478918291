// EmailThreadSettingsModal.js
import React, { useEffect, useState } from 'react';
import {
  IconButton, Divider, LayerOutlinedIcon, Paper, Modal,Box,Typography,Button,RadioGroup,FormControlLabel,Stack
} from 'convertupleads-theme';
import { CustomRadio } from '../../bulkEmail/BulkEmailStyle';
import { EMAIL_THREAD } from '../../../helpers/constant/coreConstants';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../state/features/emailCampaign/emailCampaignSelector';
import { getCampaignSequences, resetUpdateEmailThread, updateEmailThread } from '../../../state/features/emailCampaign/emailCampaignSlice';
import SingleThreadEmailSubject from '../../bulkEmail/newEmailCampaign/Settings/SingleThreadEmailSubject';
import { IEmailThreadPayload } from '../../../state/features/emailCampaign/emailCampaign.interface';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  borderRadius: 4,
  paddingTop: 4,
  paddingBottom: 1,
  paddingLeft: 4,
  paddingRight: 4,
  '&:focus-visible': {
    outline: 'none',
  },
};
interface IProps {
  open: boolean;
  handleClose: () => void;
}
const ThreadModal: React.FC<IProps> = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { selectedCampaign, isUpdateEmailThreadLoading, isUpdateEmailThreadSuccess } = useSelector(emailCampaignState);
  const [thread, setThread] = useState(EMAIL_THREAD.SINGLE_THREAD);
  const [messageSubject, setMessageSubject] = useState('');

  useEffect(() => {
    if (selectedCampaign) {
      setMessageSubject(selectedCampaign.commonEmailSubject || "");
      setThread(selectedCampaign.emailThreads);
    }
  }, []);

  useEffect(() => {
    if (isUpdateEmailThreadSuccess && !isUpdateEmailThreadLoading) {
      handleClose();
      setTimeout(() => {
        dispatch(resetUpdateEmailThread());
      }, 500);
    }

    if (isUpdateEmailThreadSuccess && thread === EMAIL_THREAD.MULTI_THREAD) {
      dispatch(getCampaignSequences({ campaignUid: selectedCampaign?.uid }));
    }
  }, [isUpdateEmailThreadLoading, isUpdateEmailThreadSuccess]);

  const handleSubmit = () => {
    if (selectedCampaign) {
      let payload: IEmailThreadPayload = {
        campaignUid: selectedCampaign.uid as string,
        emailThreads: thread,
      };

      if (thread === EMAIL_THREAD.SINGLE_THREAD) {
        payload.commonEmailSubject = messageSubject
      }
      
        dispatch(updateEmailThread(payload)); 
     
    }
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton variant={'rounded'} size={'medium'} color={'primary'} sx={{ backgroundColor: 'primary.light' }}>
            <LayerOutlinedIcon />
          </IconButton>
          <Stack direction="column">
            <Typography variant="h6" component="h2">
              Email Thread Settings
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
              Select your desired email thread style.
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={3} mt={3} pl={'10px'}>
          <RadioGroup value={thread} onChange={(event) => { setThread(event.target.value) }}>
            <Paper variant={'outlined'} elevation={0} sx={{ p: '20px', mt: 2.5 }}>
              <Stack>
                <FormControlLabel
                  value={EMAIL_THREAD.SINGLE_THREAD}
                  control={<CustomRadio size="small" color="primary" />}
                  label="Single Thread"
                  sx={{ color: 'black' }}
                />
                <Typography sx={{ mt: '2px', ml: '20px', mb: '8px', fontSize: '14px', color: '#9E9E9E' }}>
                  All emails in a campaign will use the same subject line.
                </Typography>
                {
                  thread === EMAIL_THREAD.SINGLE_THREAD &&
                  <SingleThreadEmailSubject messageSubject={messageSubject}
                    setMessageSubject={setMessageSubject} />
                }
              </Stack>
            </Paper>
            <Paper variant={'outlined'} elevation={0} sx={{ p: '20px', mt: 2.5 }}>
              <Stack>
                <FormControlLabel
                  value={EMAIL_THREAD.MULTI_THREAD}
                  control={<CustomRadio size="small" color="primary" />}
                  label="Multi-Thread"
                  sx={{ color: 'black' }}
                />
                <Typography sx={{ mt: '2px', ml: '20px', fontSize: '14px', color: '#9E9E9E' }}>
                  This option gives you the ability to create unique subject lines for each email in your campaign.
                </Typography>
              </Stack>
            </Paper>
          </RadioGroup>
        </Stack>


        <Stack mt={3} width={"100%"}>
          <Divider />
          <Stack padding={2} direction={"row"} justifyContent={"flex-end"} gap={1}>
            <Button
              onClick={() => handleClose()}
              variant={"tonal"}
              color={"inherit"}
              size={"large"}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '&:focus': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant={"contained"}
              color={"primary"}
              size={"large"}
              disabled={(thread === EMAIL_THREAD.SINGLE_THREAD ? !messageSubject : false) || isUpdateEmailThreadLoading}
              sx={{
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
                '&:focus': {
                  backgroundColor: 'primary.main',
                },
              }}
            >
              Save Changes
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ThreadModal;
