import { Skeleton, Stack } from "convertupleads-theme";
import React from "react";

const AddContactTableTableSkeleton: React.FC = () => {
  return (
    <>
      {Array.from({ length: 15 }).map((rowNum, i) => (
        <Stack direction={"row"} spacing={2} p={2}>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            width={"300px"}
            height={"20px"}
          ></Skeleton>
        </Stack>
      ))}
    </>
  );
};

export default AddContactTableTableSkeleton;
