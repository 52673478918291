import httpRequest from "./api/httpRequest";

class ReportService {
    getReportStatus(params: {campaignUid: string | null}) {
        return httpRequest.get('/campaigns/stats', params);
    }

    getReportStatusWithoutUid() {
        return httpRequest.get('/campaigns/stats');
    }
}

export default new ReportService();