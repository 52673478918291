import React from 'react';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    MenuItem, Paper,
    RadioGroup,
    Select,
    Stack,
    Typography
} from 'convertupleads-theme';
import {CustomRadio} from "../../../../BulkEmailStyle.tsx";
import {useImportCsvContext} from "../importCsvReducer.tsx";

const Details: React.FC = () => {
    const {
        handleGoNextStep,
        handleGoPreviousStep,
        leadSources,
        duplicateStrategy,
        setDuplicateStrategy,
        leadSource,
        setLeadSource,
        handleSubmit,
        isSubmitting
    } = useImportCsvContext();

    return (
        <Stack spacing={4}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant={'h5'} color={'text.primary'}>
                    Details
                </Typography>
                <Stack direction={'row'} spacing={1.5}>
                    <Button variant={'tonal'} onClick={handleGoPreviousStep}>
                        Previous
                    </Button>
                    <Button disabled={isSubmitting} onClick={handleSubmit}>
                        Complete
                    </Button>
                </Stack>
            </Stack>

            <Paper variant={"outlined"} sx={{ borderRadius: "8px", p: 3, mt: 2 }}>
                <Typography variant={'h6'} color={'text.primary'} mb={2}>
                    Duplicate Strategy
                </Typography>
                <RadioGroup name='quiz' value={duplicateStrategy}>
                    <FormControlLabel
                        onClick={() => { setDuplicateStrategy(1) }}
                        control={<CustomRadio size={'small'} color={'primary'} />}
                        checked={duplicateStrategy === 1}
                        label='Update existing contact with the data in the file, but only for fields that are empty.'
                    />
                    <FormControlLabel
                        sx={{ marginTop: 1 }}
                        onClick={() => { setDuplicateStrategy(2) }}
                        control={<CustomRadio size={'small'} color={'primary'} />}
                        checked={duplicateStrategy === 2}
                        label='Update existing contact with the data in the file, including fields that already have a value.'
                    />
                    <FormControlLabel
                        sx={{ marginTop: 1 }}
                        onClick={() => { setDuplicateStrategy(3) }}
                        control={<CustomRadio size={'small'} color={'primary'} />}
                        checked={duplicateStrategy === 3}
                        label='Don’t modify existing contact.'
                    />
                </RadioGroup>
            </Paper>
            <Paper variant={"outlined"} sx={{ borderRadius: "8px", p: 3, mt: 2 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"} variant={"subtitle1"} color={"text.primary"}>
                        Lead Source *
                    </Typography>
                    <Box width={200}>
                        <FormControl fullWidth>
                            <Select displayEmpty fullWidth value={leadSource} onChange={(event: any)=>{ setLeadSource(event.target.value) }}>
                                <MenuItem disabled value={""}>
                                    Choose
                                </MenuItem>
                                {
                                    leadSources.map((leadSource: any)=>(
                                        <MenuItem value={leadSource.id}>
                                            {leadSource.sourceTitle}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>
            </Paper>
        </Stack>
    );
};

export default Details;