import httpRequest from "./api/httpRequest";

// class ActivityLogsSevice{
//     export getActivityLogs async() {
//         return httpRequest.get("/campaigns/activity-logs");
//       }
//       export const hasMapIntegrationV2 = async (payload) => {
//         return HttpRequest.post(Api.hasMapIntegrationV2, payload);
//       };
// }
export const getActivityLogs = async (payload:any) => {
    return httpRequest.get('/campaigns/activity-logs', payload);
  };

