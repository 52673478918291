import { ListItemButton, ListItemIcon, ListItemText, SizeIcon, Typography } from 'convertupleads-theme';
import React, { useState } from 'react';
import CloneModal from '../CloneModal';
import { IEmailCampaign } from '../../../../../state/features/emailCampaign/emailCampaign.interface';

interface ICloneComponent {
    onClose: () => void;
    campaignUid: string;
    campaignTitle: string;
    campaign: IEmailCampaign;
}
const CloneComponent: React.FC<ICloneComponent> = ({ onClose, campaign, campaignUid, campaignTitle }) => {
    const [openCloneModal, setOpenCloneModal] = useState(false);
    const handleCloneModal = () => {
        setOpenCloneModal(true);
    };
    return (
        <div>
            <ListItemButton onClick={handleCloneModal}>
                <ListItemIcon>
                    <SizeIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText>
                    <Typography color={'text.primary'} variant='body2' fontWeight={400}>
                        Clone
                    </Typography>
                </ListItemText>
            </ListItemButton>
            <CloneModal campaign={campaign} campaignUid={campaignUid} open={openCloneModal} setOpen={setOpenCloneModal} campaignTitle={campaignTitle} onClose={onClose} />
        </div>
    );
};

export default CloneComponent;
