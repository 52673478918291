import React, { useState } from 'react';
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    IconButton,
    MoreVerticalIcon,
    Popover,
    List,
    EditFilledIcon,
    DeleteIcon,
    ModalWithHeader,
    DeleteModal,
    Tooltip,
} from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCampaignFolder, onSelectCampaignFolder } from '../../../../state/features/campaignFolder/campaignFolderSlice';
import AddOrEditFolderModal from '../../AddOrEditFolderModal';
import useBoolean from '../../../../hooks/useBoolean';
import { renderFolderIcon } from '../../../../helpers/util/functions';
import { IEachFolderItem } from '../../../../state/features/campaignFolder/campaignFolder.interface';
import { deselectAllCampaign, getEmailCampaignsByFolder } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { campaignFolderState } from '../../../../state/features/campaignFolder/campaignFolderSelector';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';

const EachFolderItem: React.FC<IEachFolderItem> = ({ folder, /* selectedFolder, */ showContent }) => {
    const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
    const {
        value: deleteModal,
        setTrue: setDeleteModalOpen,
        setFalse: setDeleteModalClose
    } = useBoolean();
    const { selectedFolder } = useSelector(campaignFolderState);
    const { query } = useSelector(emailCampaignState);

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const openPopover = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectedFolder = () => {
        dispatch(getEmailCampaignsByFolder({ page: 1, perPage: 10, campaignFolderUid: folder.uid, query }));
        dispatch(onSelectCampaignFolder(folder.uid as string));
        dispatch(deselectAllCampaign(undefined));
    };

    const handleModal = () => {
        setOpenAddFolderModal((prevState) => !prevState);
    };

    const handleEdit = () => {
        handleModal();
        handleClose();
    };

    const handleOpenDelete = () => {
        setDeleteModalOpen();
        handleClose();
    };

    const handleDelete = () => {
        dispatch(deleteCampaignFolder(folder.uid as string));
    };

    return (
        <>
            <ListItemButton key={folder.uid} selected={selectedFolder === folder.uid} onClick={handleSelectedFolder} alignItems='center'>
                <ListItemIcon>
                    <Tooltip title={showContent === 'none' ? `${folder.title}` : ''}>
                        {renderFolderIcon(folder?.folderIcon as string, folder.folderColor)}
                    </Tooltip>
                    {/* <FolderFilledIcon fontSize='small' sx={{ color: folder.folderColor }} /> */}
                </ListItemIcon>
                <ListItemText sx={{ display: showContent }}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography color={'text.primary'} variant='body2' fontWeight={500} minWidth={170}>
                            {folder.title}
                        </Typography>
                        <IconButton onClick={handleClick} sx={{ padding: 0, '&:focus': { background: 'transparent'} }}>
                            <MoreVerticalIcon />
                        </IconButton>
                    </Stack>
                </ListItemText>
            </ListItemButton>

            <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        style: { borderRadius: '12px' },
                    },
                }}
            >
                <List component='div' aria-label='main mailbox folders' dense>
                    <ListItemButton onClick={handleEdit}>
                        <ListItemIcon>
                            <EditFilledIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography color={'text.primary'} variant='body2' fontWeight={600}>
                                Edit Folder
                            </Typography>
                        </ListItemText>
                    </ListItemButton>

                    <ListItemButton onClick={handleOpenDelete}>
                        <ListItemIcon>
                            <DeleteIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography color={'text.primary'} variant='body2' fontWeight={600}>
                                Delete
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </List>
            </Popover>

            <ModalWithHeader open={openAddFolderModal} onClose={handleModal} title={folder ? 'Update Folder' : 'New Folder'}>
                <AddOrEditFolderModal edit={folder} onClose={handleModal} />
            </ModalWithHeader>

            <DeleteModal
                open={deleteModal}
                onClose={setDeleteModalClose}
                title='Are you sure, You want to delete this campaign Folder?'
                // warningContent='You are able to leave this page without saving. All changes will be lost.'
                onConfirm={handleDelete}
                sx={{
                    '& .MuiButton-tonalInherit:focus': { bgcolor: 'other.outlinedBorder' }, 
                    '& .MuiButton-colorError:focus': { bgcolor: 'error.main' }
                }}
            />
        </>
    );
};

export default EachFolderItem;
