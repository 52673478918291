import {
  ArrowLeftIcon,
  Button,
  FormControl,
  IconButton,
  Paper,
  Select,
  Stack,
  Typography,
  useTheme,
} from "convertupleads-theme";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LEAD_ITEM } from "../../../../helpers/constant/coreConstants";
import emailCampaignService from "../../../../services/emailCampaign.service";
import { ISmartListPayload } from "../../../../state/features/emailCampaign/emailCampaign.interface";
import { emailCampaignState } from "../../../../state/features/emailCampaign/emailCampaignSelector";
import { CustomMenuItem } from "../../BulkEmailStyle";
import PopupAlert from "../../../common/PopupAlert";
interface IProps {
  onBack: Dispatch<SetStateAction<string>>;
}
interface ISmartList {
  contactListId: number;
  title: string;
}


const SmartList: React.FC<IProps> = ({ onBack }) => {
  const [smartListData, setSmartListData] = useState<ISmartList[]>();
  const [selectedSmartListContact, setSelectedSmartListContacts] = useState<
    number | "default"
  >("default");
  const [confirmAddContact, setConfirmAddContact] = useState(false);

  const { selectedCampaign } = useSelector(emailCampaignState);

  const theme = useTheme();
  useEffect(() => {
    fetchSmartList();
  }, []);
  const fetchSmartList = async () => {
    try {
      const res = await emailCampaignService.getSmartContactList();

      if (res && res.success) {
        setSmartListData(res.data);
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = async () => {
    const payload: ISmartListPayload = {
      campaignUid: selectedCampaign?.uid as string,
      contactListId: selectedSmartListContact as number,
    };

    try {
      const res = await emailCampaignService.addContactsFromSmartList(payload);

      if (res && res.success) {
        (window as any)?.showNotification("success", res.message);
        onBack(LEAD_ITEM.CAMPAIGN_CONTACT_LIST);
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };


  return (
    <Stack p={2} width={"100%"}>
      <Stack mb={2} mt={1} direction={"row"} alignItems={"center"} spacing={1}>
        <IconButton
          sx={{ ":focus": { bgcolor: "transparent" } }}
          onClick={() => onBack("")}
        >
          <ArrowLeftIcon color="text.secondary" />
        </IconButton>
        <Stack>
          <Typography variant={"h5"} color={"text.primary"}>
            Add From Smart List
          </Typography>
          <Typography variant={"caption"}>
            Add contact from smart list
          </Typography>
        </Stack>
      </Stack>
      <Stack
        border={`1px solid ${theme.palette.other.outlinedBorderDarker}`}
        height={`calc(100vh - 300px)`}
        borderRadius={2}
        padding={2}
        alignItems={"center"}
      >
        <Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h5">Smart List</Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedSmartListContact === "default"}
              onClick={() => setConfirmAddContact(true)}
              sx={{ ":focus": { bgcolor: "primary.main" } }}
            >
              Import
            </Button>
          </Stack>
          <Paper
            sx={{ p: 4, display: "flex", justifyContent: "center", mt: 3 }}
          >
            <Stack direction={"row"} spacing={"64px"}>
              <Stack>
                <Typography variant="body1" color={"text.primary"}>
                  Add contacts from a Smart List
                </Typography>
                <Typography variant="body2" color={"text.secondary"}>
                  This option allows you to add contacts from an existing smart
                  list
                </Typography>
              </Stack>
              <FormControl sx={{ mt: 2, width: "250px" }}>
                <Select
                  value={selectedSmartListContact}
                  width={"100%"}
                  onChange={(e) =>
                    setSelectedSmartListContacts(e.target.value as number)
                  }
                >
                  <CustomMenuItem
                    disabled
                    color="text.primary"
                    value={"default"}
                  >
                    Select List
                  </CustomMenuItem>
                  {smartListData?.map((contact: ISmartList) => (
                    <CustomMenuItem
                      color="text.primary"
                      value={contact.contactListId}
                    >
                      {contact.title}
                    </CustomMenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Paper>
        </Stack>
      </Stack>

      <PopupAlert
        title={" "}
        description={
          "Contact add to campaign will process! It might take 2-3 minutes to complete."
        }
        submitText={"Ok"}
        open={confirmAddContact}
        onSubmit={() => {
          handleSubmit();
          setConfirmAddContact(false);
        }}
        onCancel={() => {
          setConfirmAddContact(false);
        }}
      />
    </Stack>
  );
};

export default SmartList;
