import { ArrowLeftIcon, Button, Stack, styled, Typography, useTheme } from 'convertupleads-theme';
import React, { useEffect, useState } from 'react';
import Leads from './Leads/Leads.tsx';
import StepperComponent from './StepperComponent.tsx';
import Settings from './Settings/Settings.tsx';
import Schedule from '../../schedule/Schedule.tsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    addCampaignEmail,
    deselectCampaign,
    getCampaignEmails,
    getCampaignSchedules,
    getCampaignSequences,
    getCampaignSettings,
    resetUpdateCampaignSequenceSchedule,
    updateCampaign,
} from '../../../state/features/emailCampaign/emailCampaignSlice.ts';

import { emailCampaignState } from '../../../state/features/emailCampaign/emailCampaignSelector.ts';
import {
    STATUS_ACTIVE,
    STATUS_PAUSE,
    STATUS_PAUSING,
    STATUS_RUNNING,
    STATUS_STARTING,
    STATUS_UPDATE,
    STEPS,
    STEPS_SUB
} from '../../../helpers/constant/coreConstants.ts';
import CampaignSequence from '../../emailCampaign/CampaignSequence.tsx';
import PopupAlert from '../../common/PopupAlert/index.tsx';
import { getAccountData, showNotification } from '../../../helpers/util/functions.tsx';
import LeadsV2 from './leadsV2/LeadsV2.tsx';
import { useSearchParams } from "react-router-dom";
import SpreadSheet from "./importContact/spreadSheet";
import Csv from "./importContact/csv";

const StyledButton = styled(Button)(({ theme }) => ({
    background: theme.palette.other.outlinedBorder,
    color: theme.palette.text.secondary,
    '&:hover, &:focus': {
        background: theme.palette.other.outlinedBorder,
        color: theme.palette.text.secondary,
    },
}));

const CAMPAIGN_CONTACT_ELASTIC_STATUS = process.env.REACT_APP_BULK_EMAIL_CAMPAIGN_CONTACT_LIST_ELASTIC_STATUS === 'TRUE' ? true : false;

const USER_CONTACT_ELASTIC_STATUS = process.env.REACT_APP_BULK_EMAIL_ADD_CONTACT_LIST_ELASTIC_STATUS === 'TRUE' ? true : false;

export const SelectedCampaignInfo: React.FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { selectedCampaign, lastUpdatedTime, isCampaignSequenceScheduleUpdatingSuccess } = useSelector(emailCampaignState);
    const [activeStep, setActiveStep] = useState<STEPS>(STEPS.STEP_SEQUENCE);
    const [activeSubStep, setActiveSubStep] = useState<number | null>(null);
    const [showError, setShowError] = useState<boolean>(false);

    const [isCampaignStatusStartAlertModal, setIsCampaignStatusStartAlertModal] = useState(false);
    const [isCampaignStatusPauseAlertModal, setIsCampaignStatusPauseAlertModal] = useState(false);
    const ELASTIC_STATUS = process.env.REACT_APP_BULK_EMAIL_ADD_CONTACT_LIST_ELASTIC_STATUS === "TRUE" ? true : false;

    useEffect(() => {
        if (searchParams.has('userConnectedEmailUid') && searchParams.has('campaignUid')) {
            dispatch(addCampaignEmail({ campaignUid: selectedCampaign?.uid as string, userConnectedEmailUid: searchParams.get('userConnectedEmailUid') }));
            setActiveStep(STEPS.STEP_SETTINGS);

            if (searchParams.has('userConnectedEmailUid')) {
                searchParams.delete('userConnectedEmailUid');
            }

            if (searchParams.has('campaignUid')) {
                searchParams.delete('campaignUid');
            }

            setSearchParams(searchParams);
        }

        if (searchParams.has('campaignUid') && searchParams.has('spreadsheet')) {
            setActiveStep(STEPS.STEP_LEADS);
            setActiveSubStep(STEPS_SUB[STEPS.STEP_LEADS].SPREADSHEET);
        }
    }, []);

    useEffect(() => {
        if (selectedCampaign) {
            dispatch(getCampaignSequences({ campaignUid: selectedCampaign.uid as string }));
            dispatch(getCampaignSettings({ uid: selectedCampaign.uid as string }));
            dispatch(getCampaignSchedules({ campaignUid: selectedCampaign.uid as string }));
            dispatch(getCampaignEmails({ uid: selectedCampaign.uid as string }));
        }
    }, [selectedCampaign?.uid]);

    useEffect(() => {
        if (selectedCampaign && isCampaignSequenceScheduleUpdatingSuccess) {
            dispatch(getCampaignSequences({ campaignUid: selectedCampaign.uid as string }));
            dispatch(resetUpdateCampaignSequenceSchedule());
        }
    }, [isCampaignSequenceScheduleUpdatingSuccess]);

    const handleNext = () => {
        if (activeStep === STEPS.STEP_SEQUENCE) {
            setActiveStep(STEPS.STEP_SCHEDULE);
        } else if (activeStep === STEPS.STEP_SCHEDULE) {
            setActiveStep(STEPS.STEP_LEADS);
        } else if (activeStep === STEPS.STEP_LEADS) {
            setActiveStep(STEPS.STEP_SETTINGS);
        }
    };
    const handleBack = () => {
        if (activeStep === STEPS.STEP_SCHEDULE) {
            setActiveStep(STEPS.STEP_SEQUENCE);
        } else if (activeStep === STEPS.STEP_LEADS) {
            setActiveStep(STEPS.STEP_SCHEDULE);
        } else if (activeStep === STEPS.STEP_SETTINGS) {
            setActiveStep(STEPS.STEP_LEADS);
        }
    };

    const goStep = (step: number) => {
        if (activeSubStep !== null) {
            setActiveSubStep(null);
        }
        setActiveStep(step);
    };

    const goSubStep = (step: number, subStep: number | null) => {
        setActiveStep(step);
        setActiveSubStep(subStep);
    };

    const handleCampaignStatus = () => {
        dispatch(updateCampaign({ uid: selectedCampaign?.uid as string, status: STATUS_UPDATE[selectedCampaign?.status as string], oldValue: selectedCampaign?.status }));
        dispatch(deselectCampaign());
    };

    const handleSaveAndClose = () => {
        if (selectedCampaign?.status === STATUS_STARTING || selectedCampaign?.status === STATUS_PAUSING) {
            showNotification('warning', 'You cannot update campaign status while a request is already pending');
            dispatch(deselectCampaign());
            return;
        }
        if (selectedCampaign?.status === STATUS_PAUSE || selectedCampaign?.status === STATUS_ACTIVE) {
            setIsCampaignStatusStartAlertModal(true);
        } else if (selectedCampaign?.status === STATUS_RUNNING) {
            dispatch(deselectCampaign());
        }
    }

    const handleClose = () => {
        dispatch(deselectCampaign());

        if (searchParams.has('spreadsheet')) {
            searchParams.delete('spreadsheet');
        }

        if (searchParams.has('campaignUid')) {
            searchParams.delete('campaignUid');
        }

        if (searchParams.has('state')) {
            searchParams.delete('state');
        }

        setSearchParams(searchParams);
    };

    return (
        <Stack sx={{ background: theme.palette.other.outlinedBorder }} spacing={2.5} p={2}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Button variant={'tonal'} size={'large'} color={'inherit'} startIcon={<ArrowLeftIcon />} onClick={handleClose} sx={{ ':focus': { background: 'transparent' } }} >
                    <b>{selectedCampaign?.title}</b>
                </Button>

                {lastUpdatedTime && <Typography variant='body2' color={'primary.main'} fontWeight={600} textAlign={'center'}> Last updated on - {lastUpdatedTime instanceof Date && lastUpdatedTime?.toLocaleTimeString()} </Typography>}

                <Stack direction={'row'} spacing={1.5}>
                    <StyledButton variant={'tonal'} size={'large'} onClick={handleBack} disabled={activeStep === STEPS.STEP_SEQUENCE}>
                        Previous
                    </StyledButton>
                    {activeStep !== STEPS.STEP_SETTINGS ? (
                        <Button variant={'contained'} size={'large'} color={'primary'} onClick={handleNext} sx={{ '&:focus': { bgcolor: 'primary.main' } }}>
                            Next
                        </Button>
                    ) : (
                        <Button variant={'contained'} size={'large'} color={'primary'} sx={{ ':focus': { bgcolor: 'primary.main' } }} onClick={handleSaveAndClose}>
                            Save & Close
                        </Button>
                    )}
                </Stack>
            </Stack>
            <StepperComponent goStep={goStep} activeStep={activeStep} />

            {
                activeStep === STEPS.STEP_SEQUENCE && (
                    <CampaignSequence goStep={goStep} showError={showError} />
                )
            }
            {activeStep === STEPS.STEP_SCHEDULE && <Schedule />}
            {/* {activeStep === STEPS.STEP_LEADS && <Leads />} */}
            {/* {(activeStep === STEPS.STEP_LEADS && activeSubStep === null) &&  <LeadsV2 goSubStep={goSubStep}/> } */}
            {(activeStep === STEPS.STEP_LEADS && activeSubStep === null) && (
                // ((getAccountData("userId") === 1 || getAccountData("userId") === 446) || (CAMPAIGN_CONTACT_ELASTIC_STATUS && USER_CONTACT_ELASTIC_STATUS)) ? <LeadsV2 goSubStep={goSubStep} /> : <Leads goSubStep={goSubStep} />
                (CAMPAIGN_CONTACT_ELASTIC_STATUS && USER_CONTACT_ELASTIC_STATUS) ? <LeadsV2 goSubStep={goSubStep} /> : <Leads goSubStep={goSubStep} />
            )}

            {(activeStep === STEPS.STEP_LEADS && activeSubStep === STEPS_SUB[STEPS.STEP_LEADS].SPREADSHEET) && <SpreadSheet goSubStep={goSubStep} />}
            {(activeStep === STEPS.STEP_LEADS && activeSubStep === STEPS_SUB[STEPS.STEP_LEADS].CSV) && <Csv goSubStep={goSubStep} />}
            {activeStep === STEPS.STEP_SETTINGS && <Settings onClose={() => dispatch(deselectCampaign())} />}

            <PopupAlert
                title={"Do you want to start this campaign?"}
                description={
                    <>
                        Click confirm to start your campaign now or <br />
                        save it as a draft to launch later.
                    </>
                }
                cancelText={"Save as Draft"}
                open={isCampaignStatusStartAlertModal}
                onSubmit={() => {
                    handleCampaignStatus();
                    setIsCampaignStatusStartAlertModal(false);
                }}
                onCancel={() => {
                    setIsCampaignStatusStartAlertModal(false);
                    dispatch(deselectCampaign());
                }}
            />

            <PopupAlert
                title={"Do you want to pause this campaign?"}
                description={"It will take a few minutes for your campaign to pause. You can restart your campaign at any time."}
                confirmText={"PAUSE CAMPAIGN"}
                open={isCampaignStatusPauseAlertModal}
                onSubmit={() => {
                    handleCampaignStatus();
                    setIsCampaignStatusPauseAlertModal(false);
                }}
                onCancel={() => {
                    setIsCampaignStatusPauseAlertModal(false);
                    dispatch(deselectCampaign());
                }}
            />
        </Stack >
    );
};
