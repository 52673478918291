import { DrawerWithHeader, Stack } from 'convertupleads-theme';
import React, { useState } from 'react';
import BasicActivityLog from '../campaignLeadContactList/BasicActivityLog';


interface IProps {
    open: boolean;
    onClose: () => void;
    drawerTitle?: string;
    width?: number;
    contactId:number
}

const ViewTimeLineDrawer: React.FC<IProps> = ({open, onClose, drawerTitle, width = 450,contactId}) => {

    return (
        <DrawerWithHeader
        open={open}
        anchor={"right"}
        header={`${drawerTitle} Timeline`}
        width={width}
        onClose={onClose}
      > 
        <Stack p={2}>
          <BasicActivityLog contactId={contactId}/>
        </Stack>
      </DrawerWithHeader>
    );
};

export default ViewTimeLineDrawer;