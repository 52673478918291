import React from "react";
import {
  Avatar,
  Badge,
  EditIcon,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "convertupleads-theme";

interface Props {
  activity: {
    userName: string;
    note: string;
    createdAt: string;
  };
}

const ActivityLogCard: React.FC<Props> = ({ activity }) => {
  const timezone=activity?.createdAt &&
    (window as any).globalTimezoneConversionToDifferentTimezone(
      activity.createdAt,
     "UTC",
     "",
    "from_now"
    )
    if(timezone>24){
        console.log('1day')
    }
  const theme = useTheme();
  return (
    <Paper
      variant={"outlined"}
      elevation={0}
      sx={{
        width: "98%",
        background: theme.palette.other.outlinedBorder,
        padding: "8px 20px 20px 20px",
      }}
    >
      <Stack spacing={0.5}>
        <Typography variant={"body1"} color={"text.primary"}>
          {activity?.note}
        </Typography>
        <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
          <Badge
            color={"primary"}
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={<EditIcon sx={{ fontSize: 9 }} />}
          >
            <Avatar
              size={"large"}
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
            />
          </Badge>
          <Stack>
            <Typography color={"text.secondary"} variant={"body2"}>
              {activity?.userName}
            </Typography>
            <Typography variant={"caption"}>
              {timezone}
              
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ActivityLogCard;
