import React from "react";
import {Paper, Skeleton, Stack} from "convertupleads-theme";

const AnalyticsRightSideSkeleton: React.FC = () => {
    return (
        <Stack  margin={3} borderRadius={2} width={"100%"} height={'700px'}>
            <Paper sx={{ padding: 2 }}>
                <Stack direction={'row'} width={'100%'} spacing={3}>
                    <Stack width={'100%'} height={'700px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} py={2}>
                            <Skeleton variant="rounded" width={'170px'} height={'35px'}/>
                            <Skeleton variant="rounded" width={'120px'} height={'35px'}/>
                        </Stack>
                        <Stack direction={'column'} spacing={1} py={6}>
                            <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
                            <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
                            <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
                            <Stack py={6}>
                                <Skeleton variant="rounded" width={'100%'} height={'350px'} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    );
};

export default AnalyticsRightSideSkeleton;