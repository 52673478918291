import React, { useEffect, useState } from 'react';
import { Button, Divider, ListItemButton, ListItemIcon, ListItemText, Modal, RestoreIcon, Stack, Typography } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../../state/features/emailCampaign/emailCampaignSelector';
import { updateCampaign } from '../../../../../state/features/emailCampaign/emailCampaignSlice';
import { IEmailCampaign } from '../../../../../state/features/emailCampaign/emailCampaign.interface';

interface IDeleteCampaign {
    onClose: () => void;
    campaign: IEmailCampaign;
}

const RestoreFromTrash: React.FC<IDeleteCampaign> = ({ onClose, campaign }) => {
    const [openRestoreModal, setOpenRestoreModal] = useState(false);
    const { isDeleteCampaignSuccess } = useSelector(emailCampaignState);
    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpenRestoreModal(true);
    };

    const handleOnConfirmForDeleteModal = () => {
        dispatch(updateCampaign({ uid: campaign.uid, status: 'PAUSE', oldValue: 'TRASH' }));
    };

    useEffect(() => {
        if (isDeleteCampaignSuccess) {
            setOpenRestoreModal(false);
            onClose();
        }
    }, [isDeleteCampaignSuccess]);
    return (
        <div>
            <ListItemButton onClick={handleDeleteModal}>
                <ListItemIcon>
                    <RestoreIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText>
                    <Typography color={'text.primary'} variant='body2' fontWeight={400}>
                        Restore
                    </Typography>
                </ListItemText>
            </ListItemButton>

            {/* <WarningModal
                open={openRestoreModal}
                onClose={() => setOpenRestoreModal(false)}
                title='Are you sure, You want to restore this campaign?'
                warningContent='You are able to leave this page without saving. All changes will be lost.'
                onConfirm={handleOnConfirmForDeleteModal}
            /> */}
            <Modal open={openRestoreModal} onClose={() => setOpenRestoreModal(false)} minWidth={200}>
                <Stack>
                    <Stack direction={'row'} justifyContent={'center'} p={4}>
                        <RestoreIcon sx={{ fontSize: '70px' }} color='primary' />
                    </Stack>
                    <Stack px={4} pb={2}>
                        <Typography textAlign={'center'} variant='h4' fontWeight={500}>
                            Are you sure, you want to restore this folder?
                        </Typography>
                    </Stack>
                    <Divider light />
                    <Stack p={2} spacing={2} direction={'row'} justifyContent={'end'}>
                        <Button variant='tonal' color={'inherit'} size='large' onClick={() => setOpenRestoreModal(false)}>
                            Cancel
                        </Button>
                        <Button variant='contained' size='large' onClick={handleOnConfirmForDeleteModal}>
                            Confirm
                        </Button>
                    </Stack>
                </Stack>
            </Modal>
        </div>
    );
};

export default RestoreFromTrash;
