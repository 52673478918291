import { Button, CloseBorderedIcon, DeleteIcon, Stack, Typography } from 'convertupleads-theme';
import React from 'react';

interface IProps {
    totalSelected: number;
}

const LeadContactListSelectedState: React.FC<IProps> = ({ totalSelected }) => {
    return (
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} height={56}>
            <Typography variant={'body2'} color={'text.tertiary'} sx={{ p: 2 }}>
                {totalSelected} selected
            </Typography>

            <Stack direction={'row'} alignItems={'center'} p={1} spacing={1} >
                <Button variant='tonal' color='inherit' startIcon={<CloseBorderedIcon />} sx={{':focus': { bgcolor: 'transparent' }}}>
                    Unsubscribe
                </Button>

                <Button variant='tonal' color='error' startIcon={<DeleteIcon />} sx={{':focus': { bgcolor: 'transparent' }}}>
                    Delete
                </Button>
            </Stack>
        </Stack>
    );
};

export default LeadContactListSelectedState;