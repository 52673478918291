import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    Button,
    Divider, FormControl, FormHelperText, InputLabel,
    Stack,
} from 'convertupleads-theme';
import {useDispatch, useSelector} from "react-redux";
import {CustomTextField} from "../../BulkEmailStyle.tsx";
import {clearCreateTagStates, createTag} from "../../../../state/features/emailCampaign/emailCampaignSlice.ts";
import {emailCampaignState} from "../../../../state/features/emailCampaign/emailCampaignSelector.ts";
import {CAMPAIGN_TRIGGER_SETTINGS} from "../../../../helpers/constant/coreConstants.ts";

interface IProps {
    onClose: () => void;
    updateTriggerSettingDetails: (value: string, setting: string, key: string, extraData: any) => void;
    callbackField?: string;
}

const AddMoreTagModal: React.FC<IProps> = ({ onClose, updateTriggerSettingDetails, callbackField = "uuid"}) => {
    const dispatch = useDispatch();
    const { isCreatingTag, isCreateTagSuccess, campaignTrigger } = useSelector(emailCampaignState);
    const [tagName, setTagName] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (!isCreatingTag && isCreateTagSuccess){
            if (campaignTrigger.tagList){
                let tag = campaignTrigger.tagList.at(-1);
                if (tag){
                    updateTriggerSettingDetails(tag[callbackField], CAMPAIGN_TRIGGER_SETTINGS.ADD_TAG_SETTINGS, callbackField, tag);
                }
            }
            onClose();
            dispatch(clearCreateTagStates());
        }
    }, [isCreatingTag, isCreateTagSuccess]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTagName(event.target.value);
    };

    const handleSubmit = () => {
        if (tagName.trim() === ""){
            setError("Tag name is required.");
            return;
        }

        if (tagName.trim() && tagName.length > 100){
            setError("Too long tag name. Keep it within 100 characters.")
            return;
        }

        dispatch(createTag({ tagName: tagName }));
    };

    return (
        <Stack padding={2} paddingBottom={0}>
            <FormControl fullWidth>
                <InputLabel sx={{ fontWeight: 600 }}>Tag Name</InputLabel>
                <CustomTextField value={tagName} onChange={handleChange}/>
                {
                    error &&
                    <FormHelperText error>
                        {error}
                    </FormHelperText>
                }
            </FormControl>
            <Stack width={'100%'} mt={3}>
                <Divider />
                <Stack py={2} direction={'row'} justifyContent={'flex-end'} gap={1}>
                    <Button variant={'tonal'} color={'inherit'} size='large' onClick={onClose}>
                        Cancel
                    </Button>
                    <Button disabled={isCreatingTag} size='large' onClick={handleSubmit}>
                        Save
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AddMoreTagModal;