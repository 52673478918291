import React, { useEffect, useState } from 'react';
import {
    Box,
    CustomSwitch,
    FormControlLabel,
    IconButton,
    Paper,
    Stack,
    Typography,
} from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector.ts';
import { updateCampaignSettings } from '../../../../state/features/emailCampaign/emailCampaignSlice.ts';
import {IUpdateCampaignSettings} from '../../../../state/features/emailCampaign/emailCampaign.interface.ts';
import {StopEmailingIcon} from '../../../../assets/Icons.tsx';

interface HardBounceStopProps {

}

const HardBounceStop: React.FC<HardBounceStopProps> = () => {
    const dispatch = useDispatch();
    const [toggleState, setToggleState] = useState(false);
    const { selectedCampaign, campaignSettings } = useSelector(emailCampaignState);

    useEffect(() => {
        if (campaignSettings && campaignSettings["STOP_EMAILING_ON_HARD_BOUNCE"]) {
            const status = campaignSettings["STOP_EMAILING_ON_HARD_BOUNCE"][0].status;
            setToggleState(status === "ACTIVE");
        }
    }, [campaignSettings]);

    const handleUpdate = (key: string, value: boolean | string, oldValue: boolean | string) => {
        if (selectedCampaign && selectedCampaign.uid && campaignSettings && campaignSettings["STOP_EMAILING_ON_HARD_BOUNCE"]) {
            const payload: IUpdateCampaignSettings = { campaignUid: selectedCampaign.uid, settingsTitle: "STOP_EMAILING_ON_HARD_BOUNCE" };

            if (key === "status") {
                setToggleState(String(value) === "ACTIVE");
                payload.status = String(value);
                payload.oldValue = String(oldValue);
                setToggleState((prev) => !prev)
            } else {
                payload.settingsDetails = { ...campaignSettings["STOP_EMAILING_ON_HARD_BOUNCE"][0].settingsDetails, [key]: value ? 'ACTIVE' : 'INACTIVE' };
                payload.oldValue = { ...campaignSettings["STOP_EMAILING_ON_HARD_BOUNCE"][0].settingsDetails, [key]: value ? 'INACTIVE' : 'ACTIVE' };
            }

            dispatch(updateCampaignSettings(payload));
        }
    };

    return (
        <Paper variant={'outlined'} elevation={0} sx={{ p: '0px 20px', mt: 2.5 }}>
            <Box width={960}>
                <Stack direction={'row'} justifyContent={'space-between'} spacing={1} sx={{ cursor: 'pointer' }} paddingY={2}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <IconButton variant={'rounded'} size={'medium'} color={'primary'} sx={{ backgroundColor: 'primary.light' }}>
                            <StopEmailingIcon/>
                        </IconButton>
                        <Stack flex={1}>
                            <Typography variant={'subtitle1'} color={'text.secondary'} sx={{textTransform: 'capitalize'}}>
                                <b>Stop emailing on hard bounce</b>
                            </Typography>
                            <Typography variant={'caption'} color={'text.secondary'}>
                                Automatically stop sending emails to contacts that hard bounce
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} spacing={1} alignItems={'center'} mr={"26px !important"}>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    color={"primary.main"}
                                    checked={toggleState}
                                    activeText={"On"}
                                    inactiveText={"Off"}
                                    onChange={()=>{
                                        handleUpdate("status", toggleState ? "INACTIVE" : "ACTIVE", toggleState ? "ACTIVE" : "INACTIVE");
                                    }}
                                />
                            }
                            label=''
                        />
                    </Stack>
                </Stack>
            </Box>
        </Paper>
    );
};

export default HardBounceStop;
