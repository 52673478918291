import React, { useEffect, useState } from 'react';
import { Box, Button, CheckIcon, Divider, InputLabel, LoadingButton, Paper, Stack, styled } from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { IFolder } from '../../state/features/campaignFolder/campaignFolder.interface';
import { campaignFolderState } from '../../state/features/campaignFolder/campaignFolderSelector';
import {
    addCampaignFolder,
    resetAddCampaignFolder,
    resetUpdateFolderSuccess,
    updateCampaignFolder,
} from '../../state/features/campaignFolder/campaignFolderSlice';
import { CustomScrollbar } from '../common/CustomScrollBarStyle';
import { CustomTextField } from "./BulkEmailStyle.tsx";
import FolderIconList from './EachFolderIcon';

const ColorOption = styled(Box)(({ theme }) => ({
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    '&:before': {
        content: 'none',
        position: 'absolute',
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: `2px solid ${theme.palette.common.white}`,
    },
    '&.folderColorSelected': {
        '&:before': { content: "''" },
    },
}));

const Colors = ['primary.main', 'secondary.main', 'success.main', 'warning.main', 'info.main', 'text.primary', 'text.secondary'];

interface ModalProps {
    onClose?: () => void;
    edit?: IFolder;
}

const AddOrEditFolderModal: React.FC<ModalProps> = ({ onClose = () => { }, edit }) => {
    const [selectedColor, setSelectedColor] = useState<string>(edit?.folderColor || 'primary.main');
    const [folderName, setFolderName] = useState<string>(edit?.title || '');
    const [iconName, setIconName] = useState<string>(edit?.folderIcon || 'FolderFilledIcon');
    const [folderNameError, setFolderNameError] = useState<string>('');

    const { isAdding, isAddCampaignFolderSuccess, isUpdating, isUpdateCampaignFolderSuccess } = useSelector(campaignFolderState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAddCampaignFolderSuccess || isUpdateCampaignFolderSuccess) {
            dispatch(resetAddCampaignFolder());
            dispatch(resetUpdateFolderSuccess());
            onClose();
        }
    }, [isAddCampaignFolderSuccess, isUpdateCampaignFolderSuccess]);

    const handleColor = (color: string): void => setSelectedColor(color);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.code === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = (): void => {
        const trimmedFolderName = folderName.trim();
        if (!trimmedFolderName) {
            setFolderNameError('Folder Name is Required');
            return;
        }
        if (edit) {
            dispatch(updateCampaignFolder({ uid: edit.uid, title: folderName, folderIcon: iconName, folderColor: selectedColor }))
        } else {
            dispatch(addCampaignFolder({ title: folderName, folderIcon: iconName, folderColor: selectedColor }))
        }
    };

    return (
        <>
            <Stack spacing={2} p={2}>
                <CustomTextField
                    label="Folder Name"
                    size="small"
                    value={folderName}
                    fullWidth
                    onChange={(e) => {
                        setFolderName(e.target.value);
                        setFolderNameError('');
                    }}
                    error={!!folderNameError}
                    helperText={folderNameError}
                    onKeyDown={handleKeyPress}
                />
                <Box>
                    <InputLabel>Folder Icon</InputLabel>
                    <Paper elevation={0} sx={{ borderRadius: '8px', bgcolor: 'other.outlinedBorder' }}>
                        <Stack direction="row" spacing={1} flexWrap="wrap">
                            <Box p={1} flexWrap="wrap" width={575}>
                                <CustomScrollbar>
                                    <Stack direction="row" spacing={2}>
                                        <FolderIconList selectedIcon={iconName} onSetIconName={setIconName} />
                                    </Stack>
                                </CustomScrollbar>
                            </Box>
                        </Stack>
                    </Paper>
                </Box>
                <Box>
                    <InputLabel>Icon Color</InputLabel>
                    <Stack direction="row" spacing={1}>
                        {Colors.map((color, index) => (
                            <ColorOption
                                key={index}
                                className={selectedColor === color ? 'folderColorSelected' : ''}
                                sx={{ bgcolor: color }}
                                onClick={() => handleColor(color)}
                            >
                                {selectedColor === color && <CheckIcon sx={{ color: 'common.white' }} />}
                            </ColorOption>
                        ))}
                    </Stack>
                </Box>
            </Stack>
            <Divider light />
            <Stack direction="row" spacing={2} justifyContent="flex-end" p={2}>
                <Button color="inherit" variant="tonal" onClick={onClose}>
                    Cancel
                </Button>

                <LoadingButton
                    onClick={handleSubmit}
                    variant="contained"
                    loading={isAdding || isUpdating}
                    // startIcon={<SaveIcon />}
                    position="start"
                    sx={{'& .MuiButton-startIcon': {height: 20, width: 20}, '&:focus': {bgcolor: 'primary.main'}}}
                >
                    <span>{edit ? 'Update Folder' : 'Save Folder'}</span>
                </LoadingButton>
            </Stack>
        </>
    );
};

export default AddOrEditFolderModal;
