import React, { useEffect, useState } from 'react';
import {
    Button,
    Divider,
    Stack,
    AddIcon,
    InputLabel,
    FormControl,
    MultiSelect,
    ModalWithHeader,
    LoadingButton
} from 'convertupleads-theme';
import { useDispatch, useSelector } from 'react-redux';
import { addCampaign, resetAddCampaign } from '../../../../state/features/emailCampaign/emailCampaignSlice';
import { emailCampaignState } from '../../../../state/features/emailCampaign/emailCampaignSelector';
import { CustomTextField } from "../../BulkEmailStyle.tsx";
import { IFolder } from "../../../../state/features/campaignFolder/campaignFolder.interface.ts";
import { campaignFolderState } from "../../../../state/features/campaignFolder/campaignFolderSelector.ts";
import AddOrEditFolderModal from "../../AddOrEditFolderModal.tsx";

interface INewEmailCampaignModalProps {
    closeCampaignCreateModal: () => void;
}
interface ISelectedFolders{
    value:string | undefined;
    label:string |undefined
}

const NewEmailCampaignModal: React.FC<INewEmailCampaignModalProps> = ({ closeCampaignCreateModal }) => {
    const dispatch = useDispatch();
    // const campaignName = useRef('');
    const [campaignName, setCampaignName] = useState<string>('');
    const [campaignNameError, setCampaignNameError] = useState('');
    const { campaignFolders, isAddCampaignFolderSuccess, isUpdateCampaignFolderSuccess } = useSelector(campaignFolderState);
    const { isAdding, isAddCampaignSuccess } = useSelector(emailCampaignState);
    const [selectedFolders, setSelectedFolders] = useState<ISelectedFolders[]>([]);
    const [openAddFolderModal, setOpenAddFolderModal] = useState(false);

 console.log(selectedFolders);
    useEffect(() => {
        if (isAddCampaignSuccess) {
            dispatch(resetAddCampaign());
            closeCampaignCreateModal();
        }
    }, [isAddCampaignSuccess]);

    useEffect(() => {
        if (isAddCampaignFolderSuccess || isUpdateCampaignFolderSuccess) {
            const newFolder = campaignFolders?.[0];
            setSelectedFolders((prevState=[]) => ([...prevState, { label: newFolder?.title, value: newFolder?.uid }]));
        }
    }, [isAddCampaignFolderSuccess, isUpdateCampaignFolderSuccess]);

    const multiSelectOptions = campaignFolders !== null ? campaignFolders.map((folder: IFolder) => {
        return { value: folder.uid, label: folder.title };
    }) : [];

    const selectedFolderUids = selectedFolders?.map((folder) => folder.value);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter') {
            handleSubmit()
        }
    }

    const handleModal = () => {
        setOpenAddFolderModal((prevState) => !prevState);
    };

    const handleSubmit = () => {
        const trimmedCampaignName = campaignName.trim();
        if (!trimmedCampaignName) {
            setCampaignNameError('Campaign Name is Required');
            return;
        }
        if(trimmedCampaignName.length < 3) {
            setCampaignNameError('Campaign Name need to be at least 3 characters long');
            return;
        }
        if(trimmedCampaignName.length > 191) {
            setCampaignNameError('Campaign Name need to be less than 191 characters long');
            return;
        }
        dispatch(addCampaign({ title: trimmedCampaignName, campaignFolderUids: selectedFolderUids }));
    };


    return (
        <Stack>
            <Stack padding={2}>
                <CustomTextField
                    id='outlined-basic'
                    size='small'
                    placeholder='Give your campaign a name'
                    label='Campaign Name'
                    onChange={(e) => {
                        setCampaignName(e.target.value);
                        setCampaignNameError('');
                    }}
                    error={!!campaignNameError}
                    helperText={campaignNameError}
                    onKeyDown={handleKeyPress}
                />
            </Stack>
            <Stack px={2} mb={2}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"}>
                    <InputLabel>Select Folder</InputLabel>
                    <Button
                        variant={"noPadding"}
                        size={"small"}
                        startIcon={<AddIcon />}
                        sx={{ mb: 0.5, "& .MuiButton-startIcon": { mr: 0 }, '&:focus': { background: 'transparent' } }}
                        onClick={handleModal}
                    >
                        Create
                    </Button>
                </Stack>
                <FormControl sx={{ "& input": { height: "unset !important" } }} fullWidth>
                    <MultiSelect
                        width='100%'
                        maxWidth='100%'
                        value={selectedFolders}
                        onChange={(value: any) => setSelectedFolders(value as { value: string | undefined; label: string }[])}
                        name='colors'
                        options={multiSelectOptions}
                        className='rounded'
                    />
                </FormControl>
            </Stack>
            <Divider />
            <Stack padding={2} direction={'row'} justifyContent={'flex-end'} gap={1}>
                <Button variant={'tonal'} color={'inherit'} size='large' onClick={closeCampaignCreateModal}>
                    Cancel
                </Button>
                {/* <Button size='large' disabled={isAdding || editorType !== EDITOR_TYPE.RICH_TEXT} onClick={handleSubmit}> */}
                {/* <Button size='large' disabled={isAdding} onClick={handleSubmit}>
                <Button size='large' disabled={isAdding} onClick={handleSubmit}>
                    Continue
                </Button> */}

                <LoadingButton
                    onClick={handleSubmit}
                    variant="contained"
                    loading={isAdding}
                    // startIcon={<SaveIcon />}
                    position="start"
                    sx={{ '& .MuiButton-startIcon': { height: 20, width: 20 }, '&:focus': { bgcolor: 'primary.main' } }}
                >
                    <span>Continue</span>
                </LoadingButton>
            </Stack>

            <ModalWithHeader open={openAddFolderModal} onClose={handleModal} title='New Folder'>
                <AddOrEditFolderModal onClose={handleModal} />
            </ModalWithHeader>
        </Stack>
    );
};

export default NewEmailCampaignModal;
